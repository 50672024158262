import React from 'react'
import styled from 'styled-components'
import { Divider } from 'antd'

import Breadcrumb from '../Breadcrumb'
import Text from '../Text'
import Space from '../Space'

const Container = styled.div`
  ${(props) => props.variant === 'sticky' ? 'position: sticky;' : ''}
  ${(props) => (props.variant === 'sticky' ? 'top: 0;' : '')}
  z-index: ${(props) => (props.variant === 'sticky' ? 1 : 0)};
  opacity: ${(props) => (props.variant === 'sticky' ? 0.75 : '')};
  width: 100%;
  background-color: white;
`

const InnerContainer = styled.div`
  padding: 20px 24px 24px;
`

const NavigationContainer = styled.div`
  width: 100%;
  padding: 0 24px;
`

const Main = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const Content = styled.div`
  width: 70%;
`

const NavigationTitle = styled(Text)`
  margin-right: @margin-lg;
`

const CustomDivider = styled(Divider)`
  margin: 0;
`

const ContentHeader = ({
  breadcrumbItems = {},
  children,
  buttons,
  navigation,
  navigationTitle,
  variant,
}) => {
  return (
    <Container variant={variant}>
      <InnerContainer>
        <Space direction='vertical' size={12}>
          <Breadcrumb items={breadcrumbItems} />
          <Main>
            <Content>{children}</Content>
            {buttons && <Space>{buttons()}</Space>}
          </Main>
        </Space>
      </InnerContainer>
      <CustomDivider />
      {navigation && (
        <>
          <NavigationContainer>
            {navigationTitle && (
              <NavigationTitle>{navigationTitle}</NavigationTitle>
            )}
            {navigation()}
          </NavigationContainer>
        </>
      )}
    </Container>
  )
};

export default ContentHeader
