import { WarningFilled } from "@ant-design/icons"
import { Button, Result } from "antd"
import difference from "lodash/difference"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import styled from "styled-components"
import DefaultButton from "~/components/common/DefaultButton"
import Loading from '~/components/common/Loading'
import PrimaryButton from "~/components/common/PrimaryButton"
import { fetchOrders } from "~/containers/Cart/actions"
import { colors } from "~/utils/colors"

const ValidateOrderPopUp = ({
  visible,
  setVisible,
  showOtherPaymentMethodsButton,
  payCartOnTheSpot,
  payCartByCB,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [payOnTheSpot, setPayOnTheSpot] = useState(false)
  const [isOrderChanged, setIsOrderChanged] = useState(false)
  const [unreservableDates, setUnreservableDates] = useState({})
  const [creditIsEnough, setCreditIsEnough] = useState(false)
  const {
    orders: {
      data: orders,
      validatedOrder,
      isValidating,
      isPaying,
      paymentData,
      paymentOnTheSpotSuccessful,
    },
  } = useSelector((state) => state.cart)

  const targetedOrder = orders?.find(order => order.alias === validatedOrder?.alias)
  const isOrganisationOnlinePaymentActive = validatedOrder && validatedOrder?.organisation_paiment_method !== null

  useEffect(
    () => {
      // if (!isOrganisationOnlinePaymentActive) {
      //   setPayOnTheSpot(true)
      // }

      if (
        targetedOrder?.amount !== validatedOrder?.amount
        || targetedOrder?.credit !== validatedOrder?.credit
      ) {
        const targetedAttendances = Object.keys(targetedOrder?.attendances)
        const validatedAttendances = Object.keys(validatedOrder?.attendances)
        const checkDifferenceDates = targetedAttendances.reduce((acc, cur) => {
          const childActivitiesAliases = Object.keys(targetedAttendances?.[cur])?.filter(activityAlias => activityAlias !== 'total')

          childActivitiesAliases.forEach(activityAlias => {
            const childName = targetedAttendances?.[cur]?.[activityAlias]?.child_name
            const activityName = targetedAttendances?.[cur]?.[activityAlias]?.parentActivity_name

            const differenceDates = difference(
              targetedAttendances?.[cur]?.[activityAlias]?.InscriptionDates,
              validatedAttendances?.[cur]?.[activityAlias]?.InscriptionDates,
            )
            const isDifferent = differenceDates.length

            if (isDifferent) {
              acc[`${childName} / ${activityName}`] = differenceDates
            }
          })

          return acc
        }, {})

        setIsOrderChanged(true)
        setUnreservableDates(checkDifferenceDates)
      }

      console.log({validatedOrder})
      if (validatedOrder?.total_amount === 0 && validatedOrder?.credit) {
        setCreditIsEnough(true)
      }
    },
    [validatedOrder],
  )

  useEffect(() => {
    if (paymentData?.redirect_url) {
      window.document.location = paymentData?.redirect_url
    }
  }, [paymentData?.redirect_url])

  console.log({paymentOnTheSpotSuccessful, targetedOrder})

  if (!visible) return null

  if (isValidating) {
    return (
      <>
        <Loading />
      </>
    )
  }

  if (paymentOnTheSpotSuccessful === targetedOrder?.alias) {
    return (
      <Overlay>
        <PopUp>
          <Result
            status="success"
            title="La commande a été passée avec succès"
            subTitle="Assurez-vous de rejoindre votre organisation pour finaliser votre paiement sur place."
            extra={[
              <Button
                type="primary"
                key="cart"
                onClick={() => {
                  setVisible(false)
                  setPayOnTheSpot(false)
                  dispatch(fetchOrders())
                }}
              >
                Vers le panier
              </Button>,
              <Button
                key="home"
                onClick={() => {
                  dispatch(fetchOrders())
                  navigate('/')
                }}
              >
                Vers l'accueil
              </Button>,
            ]}
          />
        </PopUp>
      </Overlay>
    )
  }

  if (payOnTheSpot) {
    return (
      <Overlay>
        <PopUp>
          <WarningFilled
            style={{
              fontSize: "70px",
              color: "#FFC53D",
              margin: "22px auto",
              width: "100%",
              justifyContent: "center",
            }}
          />
          <Text>
            En choisissant un autre moyen de paiement, vous devrez régler votre
            commande sur place afin que vos réservations soient validée.
          </Text>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <DefaultButton
              style={{
                width: "48%",
                height: "auto",
                margin: 0,
              }}
              size="large"
              onClick={() => {
                setVisible(false)
                setPayOnTheSpot(false)
                dispatch(fetchOrders())         
              }}
            >
              Annuler
            </DefaultButton>
            <PrimaryButton
              style={{
                width: "48%",
                height: "auto",
                margin: 0,
              }}
              size="large"
              onClick={payCartByCB}
              disabled={!isOrganisationOnlinePaymentActive}
            >
              Payer par Carte Bancaire
              {!isOrganisationOnlinePaymentActive && (
                <p style={{color: colors.cvLightRed, fontSize: 12, fontWeight: 700, marginBottom: 0}}>
                  non disponible pour cette organisation
                </p>
              )}
            </PrimaryButton>
          </div>
          <PrimaryButton
            size="large"
            style={{
              width: "100%",
              margin: 0,
              marginTop: 20,
            }}
            onClick={payCartOnTheSpot}
          >
            Confirmer le paiement sur place
          </PrimaryButton>
        </PopUp>
      </Overlay>
    )
  }

  if (creditIsEnough) {
    return (
      <Overlay>
        <PopUp>
          <WarningFilled
            style={{
              fontSize: "70px",
              color: "#FFC53D",
              margin: "22px auto",
              width: "100%",
              justifyContent: "center",
            }}
          />
          <Text>
            Vos avoirs couvrent la totalité de votre commande.
            <br />
            Une facture à 0,00 € va être éditée.
          </Text>
          
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <DefaultButton
              style={{
                width: "48%",
                height: "auto",
                margin: 0,
              }}
              size="large"
              onClick={() => {
                setVisible(false)
                setPayOnTheSpot(false)
                dispatch(fetchOrders())
              }}
            >
              Annuler
            </DefaultButton>
            <PrimaryButton
              style={{
                width: "48%",
                margin: 0,
              }}
              size="large"
              onClick={payCartByCB}
            >
              Confirmer
            </PrimaryButton>
          </div>
        </PopUp>
      </Overlay>
    )
  }
  
  return (
    <Overlay>
      <PopUp>
        <WarningFilled
          style={{
            fontSize: "70px",
            color: "#FFC53D",
            margin: "22px auto",
            width: "100%",
            justifyContent: "center",
          }}
        />
        <Text>
          <b>
            {isOrderChanged
              ? "Certaines réservations ne sont plus disponibles car elles sont maintenant hors délais de prévenance."
              : "Vos réservations seront validées après votre paiement."
            }
          </b>
        </Text>
        
        {isOrderChanged && (
          <Text>
            Les dates suivantes ne seronts pas réservées:
            <ul>
              {Object.keys(unreservableDates).map(line => (
                <li>
                  <b>
                    {line}
                  </b>
                  {unreservableDates?.[line]?.toString()}
                </li>
              ))}
            </ul>
          </Text>
        )}
        <Text>
          Avoir disponible: {validatedOrder?.credit} €
        </Text>
        <Text>
          <b>
            {isOrderChanged
              ? "Nouveau montant"
              : "Montant"
            } à régler : {validatedOrder?.total_amount} €
          </b>
        </Text>
        <PrimaryButton
          size="large"
          style={{
            width: "100%",
            height: "auto",
            margin: 0,
            marginTop: 30,
            marginBottom: 16,
          }}
          onClick={payCartByCB}
          disabled={!isOrganisationOnlinePaymentActive}
        >
          Payer par Carte Bancaire
          {!isOrganisationOnlinePaymentActive && (
            <p style={{color: colors.cvLightRed, fontSize: 12, fontWeight: 700, marginBottom: 0}}>
              non disponible pour cette organisation
            </p>
          )}
        </PrimaryButton>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DefaultButton
            style={{
              width: "48%",
              height: "auto",
              margin: 0,
            }}
            size="large"
            onClick={() => {
              setVisible(false)
              setPayOnTheSpot(false)
            }}
          >
            Annuler
          </DefaultButton>
          <PrimaryButton
            style={{
              width: "48%",
              height: "auto",
              margin: 0,
            }}
            size="large"
            onClick={() => showOtherPaymentMethodsButton && setPayOnTheSpot(true)}
            disabled={!showOtherPaymentMethodsButton}
          >
            Autres moyens de paiement
            {!showOtherPaymentMethodsButton && (
              <p style={{color: colors.cvLightRed, fontSize: 12, fontWeight: 700, marginBottom: 0}}>
                non disponible pour cette organisation
              </p>
            )}
          </PrimaryButton>
        </div>
      </PopUp>
    </Overlay>
  )
}

const Text = styled.p`
  font-size: 20px;
  line-height: 22px;
  padding-bottom: 12px;
  text-align: center;
  width: 100%;
`

const Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  // visibility: hidden;
  opacity: 1;
  z-index: 10;
`

const PopUp = styled.div`
  margin: 100px auto;
  padding: 2%;
  background: #fff;
  border-radius: 5px;
  width: 50%;
  position: relative;
  transition: all 5s ease-in-out;
`

export default ValidateOrderPopUp
