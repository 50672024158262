
import {
  handleActions,
} from '~/utils/redux-actions'

import {
  activitiesFetching,
  activitiesFetched,
  activitiesFetchError,

  activityDetailsFetching,
  activityDetailsFetched,
  activityDetailsFetchError,

  organisationsActivitiesFetching,
  organisationsActivitiesFetched,
  organisationsActivitiesFetchError,

  activitySlotsFetching,
  activitySlotsFetched,
  activitySlotsFetchError,

  subscriptionCreating,
  subscriptionCreated,
  subscriptionCreateError,

  subscriptionUpdating,
  subscriptionUpdated,
  subscriptionUpdateError,

} from './actions'

import { logout } from '~/containers/User/actions'

const defaultState = {
  loading: false,
  isCreatingSubscription: false,
  error: false,

  activity: {
    details: null,
    list: [],
    slots: [],
    loading: false,
    error: false,
  },

  organisationsActivities: {
    data: null,
    isLoading: false,
    error: false,
  }
}

export default handleActions(
  {
    // Logout
    [logout]: () => defaultState,

    // Fetch activities
    [activitiesFetching]: state => {
      state.activity.list = []
      state.activity.loading = true
      state.activity.error = false
    },
    [activitiesFetched]: (state, { payload }) => {
      state.activity.list = payload
      state.activity.loading = false
      state.activity.error = false
    },
    [activitiesFetchError]: state => {
      state.activity.list = []
      state.activity.loading = false
      state.activity.error = true
    },

    // Fetch activity details
    [activityDetailsFetching]: state => {
      state.activity.details = null
      state.activity.loading = true
      state.activity.error = false
    },
    [activityDetailsFetched]: (state, { payload }) => {
      state.activity.details = payload
      state.activity.loading = false
      state.activity.error = false
    },
    [activityDetailsFetchError]: state => {
      state.activity.details = null
      state.activity.loading = false
      state.activity.error = true
    },

    // Fetch organisations activities
    [organisationsActivitiesFetching]: state => {
      // state.organisationsActivities.data = null
      state.organisationsActivities.isLoading = true
      state.organisationsActivities.error = false
    },
    [organisationsActivitiesFetched]: (state, { payload }) => {
      state.organisationsActivities.data = payload
      state.organisationsActivities.isLoading = false
      state.organisationsActivities.error = false
    },
    [organisationsActivitiesFetchError]: state => {
      // state.organisationsActivities.data = null
      state.organisationsActivities.isLoading = false
      state.organisationsActivities.error = true
    },

    // Fetch activity slots
    [activitySlotsFetching]: state => {
      state.activity.slots = []
      state.activity.loading = true
      state.activity.error = false
    },
    [activitySlotsFetched]: (state, { payload }) => {
      state.activity.slots = payload
      state.activity.loading = false
      state.activity.error = false
    },
    [activitySlotsFetchError]: state => {
      state.activity.slots = []
      state.activity.loading = false
      state.activity.error = true
    },

    // Creating subscription
    [subscriptionCreating]: state => {
      state.isCreatingSubscription = true
      state.error = false
    },
    [subscriptionCreated]: state => {
      state.isCreatingSubscription = false
      state.error = false
    },
    [subscriptionCreateError]: state => {
      state.isCreatingSubscription = false
      state.error = true
    },

    // Updating subscription
    [subscriptionUpdating]: state => {
      state.isUpdatingSubscription = true
      state.error = false
    },
    [subscriptionUpdated]: state => {
      state.isUpdatingSubscription = false
      state.error = false
    },
    [subscriptionUpdateError]: state => {
      state.isUpdatingSubscription = false
      state.error = true
    },

  },
  defaultState,
)
