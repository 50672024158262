import { message } from "antd";
import { takeLatest, put, select, call } from "redux-saga/effects";
import dayjs from "dayjs";
import {jwtDecode} from "jwt-decode";
import axios from "~/lib/axios";
import {
  login,
  loginProcess,
  loginError,
  loginSuccess,

  // JWT
  setJwt,
  unsetJwt,
  checkTokenExpiration,
  fetchAccessToken,
  // Forgot Password
  forgotPassword,
  forgotPasswordProcess,
  forgotPasswordError,
  forgotPasswordSuccess,

  // reset password confirm
  resetPasswordConfirm,
  resetPasswordConfirmPending,
  resetPasswordConfirmError,
  resetPasswordConfirmSuccess,

  // reset Password
  resetPassword,
  resetPasswordError,
  resetPasswordProcess,
  resetPasswordSuccess,
  // logout
  logout,

  // User profile
  fetchUserProfile,
  fetchUserProfileError,
  fetchUserProfileProcess,
  fetchUserProfileSuccess,
  // update
  updateUserProfileSuccess,
  updateUserProfileProcess,
  updateUserProfileError,
  updateUserProfile,

  // user docs
  fetchUserDocuments,
  fetchUserDocumentsSuccess,
  fetchUserDocumentsProcess,
  fetchUserDocumentsError,
  // download file
  downloadFile,
  // userPhoto
  fetchUserPhoto,
  fetchUserPhotoSuccess,

  // updater User photo
  updateUserPhoto,
  updateUserPhotoProcess,
  updateUserPhotoSuccess,
  updateUserPhotoError,

  // change user relationship
  updateRelationship,
  updateRelationshipProcess,
  updateRelationshipSuccess,
  updateRelationshipError,
} from "./actions";
import {
  getAccessToken,
  getRefreshToken,
  getAuthUserInfos,
  getUserDocuments,
  getUserData,
} from "./selectors";

import * as api from "./api";
/**
 * fetchNewAccessToken
 */
export function* fetchNewAccessTokenSaga() {
  try {
    const refreshToken = yield select(getRefreshToken);
    const now = dayjs().unix();
    const decoded = jwtDecode(refreshToken);

    if (decoded.exp > now) {
      const response = yield call(api.fetchNewAccessToken, { refreshToken });
      yield put(
        setJwt({
          accessToken: response.data.access,
          refreshToken,
        })
      );
    } else {
      yield put(logout());
    }
  } catch (err) {
    console.log(err);
    // Logout
    yield put(logout());
  }
}

/**
 * checkJwtExpiration
 */
export function* checkJwtExpiration() {
  const accessToken = yield select(getAccessToken);

  if (accessToken) {
    const decoded = jwtDecode(accessToken);
    const now = dayjs().unix();
    if (decoded.exp < now) {
      yield put(fetchAccessToken());
    }
  } else {
    yield put(logout());
  }
}

/**
 * User profile sagas here
 */

export function* fetchUserProfileSaga() {
  try {
    yield* checkJwtExpiration();
    yield put(fetchUserProfileProcess());
    yield put(fetchUserDocuments({ url: null }));

    const authUserInfos = yield select(getAuthUserInfos);

    if (authUserInfos.email) {
      const response = yield call(api.fetchProfile, {
        email: authUserInfos.email,
      });

      yield put(fetchUserProfileSuccess(response.data));
    } else {
      yield put(fetchUserProfileError());
    }
  } catch (err) {
    console.log(err);
    yield put(fetchUserProfileError());
  }
}

export function* fetchUserDocumentsSaga({ payload: { url } }) {
  try {
    yield* checkJwtExpiration();
    yield put(fetchUserDocumentsProcess());
    let docs = [];
    if (url !== null) {
      docs = yield select(getUserDocuments);
    }

    const authUserInfos = yield select(getAuthUserInfos);

    const response = yield axios.get(
      url || `users/${authUserInfos.email}/documents/`
    );

    const newDocuments = [...docs, ...response.data.results];
    yield put(fetchUserDocumentsSuccess(newDocuments));

    // If more results
    if (response.data.next !== null) {
      yield put(fetchUserDocuments({ url: response.data.next }));
    }
  } catch (err) {
    console.log(err);
    yield put(fetchUserDocumentsError());
  }
}

function* updateUserProfileSaga({ payload }) {
  try {
    yield* checkJwtExpiration();
    yield put(updateUserProfileProcess());

    const authUserInfos = yield select(getAuthUserInfos);

    if (authUserInfos.email) {
      const response = yield axios.patch(`users/${authUserInfos.email}/`, {
        ...payload.user,
      });
      yield put(updateUserProfileSuccess(response.data));
      yield put(fetchUserPhoto());
      payload.onSuccess();
      message.success("Profil mis à jour");
    } else {
      
      yield put(updateUserProfileError());
      message.error("Erreur lors de la mise à jour de votre profil");
      yield put(logout());
    }
  } catch (err) {
    console.log(err);
    // errror toast
    message.error("Erreur lors de la mise à jour de votre profil");
    yield put(updateUserProfileError());
  }
}

function* updateUserPhotoSaga({ payload }) {
  try {
    yield* checkJwtExpiration();
    yield put(updateUserPhotoProcess());

    const authUserInfos = yield select(getAuthUserInfos);

    if (authUserInfos.email) {
      const response = yield axios.post(`users/${authUserInfos.email}/uploadPhoto/`, payload.formData);
      
      yield put(updateUserPhotoSuccess(response.photo));
      yield put(fetchUserPhoto());
      payload.onSuccess();
      message.success("Photo mis à jour");
    } else {
      
      yield put(updateUserPhotoError());
      message.error("Erreur lors de la mise à jour de votre photo de profile");
      yield put(logout());
    }
  } catch (err) {
    console.log(err);
    // errror toast
    message.error("Erreur lors de la mise à jour de votre photo de profil");
    yield put(updateUserPhotoError());
  }
}

function* updateRelationshipSaga({ payload }) {
  try {
    yield* checkJwtExpiration();
    yield put(updateRelationshipProcess());

    const authUserInfos = yield select(getAuthUserInfos);

    if (authUserInfos.email) {
      const response = yield axios.patch(`users/${authUserInfos.email}/children/${payload.child}/changeRelationchip/`, {
        relationchip: payload.relationship,
      });
      
      yield put(updateRelationshipSuccess(response.data));
      yield put(fetchUserProfile());
      payload.onSuccess();
      message.success("Relation mis à jour");
    } else {
      
      yield put(updateRelationshipError());
      message.error("Erreur lors de la mise à jour de la relation");
      yield put(logout());
    }
  } catch (err) {
    console.log(err);
    // errror toast
    message.error("Erreur lors de la mise à jour de la relation");
    yield put(updateRelationshipError());
  }
}

/**
 *
 * Auth sagas
 */
export function* loginSaga({ payload }) {
  const { email, password } = payload;
  yield put(loginProcess());
  try {
    const { data } = yield call(api.login, { email, password });
    const { roles, access, refresh, mustResetPassword } = data;

    yield put(
      loginSuccess({
        roles,
        mustResetPassword,
        user: {
          email,
          password: null,
        },
      })
    );

    // Save tokens
    yield put(setJwt({ accessToken: access, refreshToken: refresh }));
    yield put(fetchUserProfile());
    // fetch user Profile
  } catch (error) {
    // display toast
    console.log("error", error);
    yield put(
      loginError(
        "Erreur lors de la tentative de connexion, vérifiez vos identifiants"
      )
    );
    yield put(unsetJwt());
  }
}

/**
 *
 * @param {email/payload} param0
 */

export function* forgotPasswordSaga({ payload }) {
  try {
    yield put(forgotPasswordProcess());

    yield axios.post("auth/password/reset/", {
      ...payload,
    });
    yield put(forgotPasswordSuccess());
    // display toast success here
  } catch (err) {
    yield put(forgotPasswordError());
    // display toast error here
  }
}

/**
 * reset passwprd confirm
 */

export function* resetPasswordConfirmSaga({ payload }) {
  try {
    yield put(resetPasswordConfirmPending());
    yield call(api.resetPasswordConfirm, { ...payload });
    yield put(resetPasswordConfirmSuccess());
  } catch (error) {
    const { response } = error;
    
    if (response?.data.new_password[0]) {
      
      message.warning(response?.data.new_password[0]);
      
    }
    if (response?.data.new_password[1]) {
      
      message.warning(response?.data.new_password[1]);
    } else {
      message.error(
        "Votre lien à expiré, veuillez recommencer la procédure de réinitialisation"
      );
    }
    yield put(resetPasswordConfirmError());
  }
}

/**
 * reset password
 */

export function* resetPasswordSaga({
  payload: { oldPassword, newPassword, onSuccess },
}) {
  try {
    yield* checkJwtExpiration();
    yield put(resetPasswordProcess());
    const response = yield axios.put("users/resetpassword/", {
      old_password: oldPassword,
      new_password: newPassword,
    });

    const { access, refresh } = response.data;

    yield put(setJwt({ accessToken: access, refreshToken: refresh }));
    yield put(resetPasswordSuccess());
    onSuccess();
    message.success("Mot de passe modifié !");
  } catch (err) {
    message.error("Erreur lors du changement de mot de passe");
    yield put(resetPasswordError());
  }
}

// download file

function* downloadFileSaga({ payload: doc }) {
  try {
    const file = doc.file_url.split(".");

    const res = yield axios.get(doc.file_url, {
      responseType: "blob", // important
    });

    if (res && typeof window !== "undefined") {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${doc.name}.${file[file.length - 1]}`);
      document.body.appendChild(link);
      link.click();
    }
  } catch (error) {
    console.log("error", error);
  }
}

function* fetchUserPhotoSaga() {
  const { photo } = yield select(getUserData);
  try {
    const { data } = yield axios.get(photo, {
      responseType: "arraybuffer",
    });
    const binData = Buffer.from(data, "binary");
    const photoUrl = `data:image/${
      photo?.[photo?.length - 1]
    };base64,${binData.toString("base64")}`;
    yield put(fetchUserPhotoSuccess(photoUrl));
  } catch (error) {
    console.log("error", error);
  }
}
function* rootSaga() {
  yield takeLatest(login, loginSaga);
  yield takeLatest(fetchUserProfile, fetchUserProfileSaga);
  yield takeLatest(forgotPassword, forgotPasswordSaga);
  yield takeLatest(resetPasswordConfirm, resetPasswordConfirmSaga);
  yield takeLatest(resetPassword, resetPasswordSaga);
  yield takeLatest(checkTokenExpiration, checkJwtExpiration);
  yield takeLatest(fetchUserDocuments, fetchUserDocumentsSaga);
  yield takeLatest(updateUserProfile, updateUserProfileSaga);
  yield takeLatest(updateUserPhoto, updateUserPhotoSaga);
  yield takeLatest(updateRelationship, updateRelationshipSaga);
  yield takeLatest(downloadFile, downloadFileSaga);
  yield takeLatest(fetchUserPhoto, fetchUserPhotoSaga);
  yield takeLatest(fetchAccessToken, fetchNewAccessTokenSaga);
}

export default rootSaga;
