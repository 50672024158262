import React, { useEffect, useState } from "react"

import ContentHeader from "~/components/common/ContentHeader"
import Button from "~/components/common/Button"
import { PlusOutlined, EditOutlined } from "@ant-design/icons"
import { useLocation } from "react-router-dom"
import Menu, {
  MenuItem,
} from "~/components/common/ContentHeader/components/Menu"
import H1 from "~/components/common/H1"
import Container from "~/components/common/Container"
import { useSelector, useDispatch } from "react-redux"
import { Col, Row, Menu as AntdMenu } from "antd"
import HouseholdDetail from "../components/HouseholdDetail/index"
import { Title, SameHeightList } from "./components"

// Sagas
import { householdFetching } from "../actions"
import { fetchChildren } from "~/containers/Children/actions"

import Text from "~/components/common/Text"
import ChildrenCard from "~/components/common/ChildrenCard"
import AddChildCard from "~/components/common/AddchildCard"
import ParentCard from "~/components/common/ParentCard"
import AddChild from "./addChild"
import AddCard from "~/components/common/AddCard"
import Loading from '~/components/common/Loading'

import dayjs from "dayjs"
import { getSchoolLevel } from "~/constants"
import { MY_HOUSEHOLD } from "~/graphql/queries"
import { useQuery } from "@apollo/client"

const Members = () => {
  const {
    loading: isLoadingHousehold,
    error: householdError,
    data: householdData,
  } = useQuery(MY_HOUSEHOLD, {
    fetchPolicy: "no-cache",
  });

  const household = householdData?.myHousehold;
  const adults = household?.adults?.map(({user}) => user) ?? [];
  const children = household?.children ?? [];

  console.log({children})

  const dispatch = useDispatch()
  const location = useLocation()
  const [showEditDrawer, setShowEditDrawer] = useState(false)
  const [child, setChild] = useState(null)
  // const { children, isFetching } = useSelector((state) => state.children)
  // const parents = [];

  useEffect(() => {
    
    // dispatch(householdFetching());
    // dispatch(fetchChildren())

    if (location.search.includes('addNewChild')) {
      setShowEditDrawer(true)
    }    
  }, [])

  const menu = (child) => (
    <AntdMenu>
      <AntdMenu.Item
        onClick={() => {
          setChild(child)
          setShowEditDrawer(true)
        }}
      >
        Editer
      </AntdMenu.Item>
    </AntdMenu>
  )

  // let parents = []

  // if (children[0]) parents = children[0].relationships

  return (
    <>
      {isLoadingHousehold && (
        <Container>
          <Loading />
        </Container>
      )}
      {!isLoadingHousehold && (
        <>
          <Container>
            <Title>
              Adultes <span style={{fontSize: 14}}>({adults?.length ?? 0})</span>
            </Title>
            <SameHeightList>
              {adults?.map((adult, key) => (
                <ParentCard
                  key={key}
                  // menu={menu}
                  photo={adult?.profile?.photoUiUrl}
                  last_name={adult?.lastName ?? ""}
                  first_name={adult?.firstName ?? ""}
                  email={adult.email ?? ""}
                  mobile={
                    (adult?.profile?.mobile || adult?.profile?.phone)?.replace(/-/g, " ") ?? ""
                  }
                  alias={adult?.profile?.alias}
                  status={adult.status?.key === "A_2" ? 1 : 0}
                />
              ))}
            </SameHeightList>
          </Container>
          <Container>
            <Title>
              Enfants <span style={{fontSize: 14}}>({children?.length ?? 0})</span>
            </Title>
            <SameHeightList>
              {children?.map((child) => (
                <ChildrenCard
                  key={child?.alias}
                  menu={menu(child)}
                  photo={child?.photoUiUrl}
                  last_name={child.lastName}
                  first_name={child.firstName}
                  alias={child?.alias}
                  age={
                    child?.dateOfBirth
                      ? dayjs().diff(
                        child?.dateOfBirth,
                        "years",
                        false,
                      )
                      : null
                  }
                  gender={child?.gender?.key}
                  // onStatusChange={onStatusChange}
                  schoolClassName={child.schoolclass?.name}
                  schoolName={child?.school?.name}
                  schoolLevel={getSchoolLevel(child?.classtype?.key)?.label}
                />
              ))}
              <AddCard
                title="Ajouter un enfant"
                onClick={() => setShowEditDrawer(true)}
              />
            </SameHeightList>
          </Container>

          <AddChild
            title="Ajouter un enfant"
            data={child}
            setShowDrawer={setShowEditDrawer}
            showDrawer={showEditDrawer}
          ></AddChild>
        </>
      )}
    </>
  )
}

export default Members
