import React, { useEffect, useState, useRef } from "react"
import { FileImageOutlined } from "@ant-design/icons"
import Drawer from "~/components/common/Drawer"
import { Row, Col, Space, Avatar } from "antd"
import { useSelector, useDispatch } from "react-redux"
import {
  Form,
  Select,
  Input,
  Radio,
  Switch,
  InputNumber,
  Divider,
  Button,
  Upload,
  message,
  DatePicker,
} from "antd"
import Gender from "~/components/common/Gender"
import CustomProfilAvatar from "~/components/common/CustomProfilAvatar"
import Search from "~/components/common/Search"
import { childProfileUpdating, childPhotoUpdating,  } from '~/containers/Children/actions'
import {
  updateRelationship,
} from "~/containers/User/actions"

import { childStatus, parentMaritalStatus, allergies } from "~/constants"

import useErrors from "~/hooks/useErrors"
import Checkbox from "antd/lib/checkbox/Checkbox"

import dayjs from "dayjs"
import ImgCrop from "antd-img-crop"
import useImage from "~/hooks/useImage"
import Picture from "~/components/common/Picture"
import { registrationUpdating } from "~/containers/Registration/actions"

const { Option } = Select
const { TextArea } = Input

const EditChild = ({ child, showDrawer, setShowDrawer, title }) => {
  const {email} = useSelector((state) => state.user.auth.user)
  const [file, setFile] = useState(null)
  const { ErrorComponent, setErrors } = useErrors()
  const [form] = Form.useForm()
  const [selected, setSelected] = useState([])
  const [photoPermissionChecked, setPhotoPermissionChecked] = useState(false)
  const initialValues = useRef({})
  const dispatch = useDispatch()
  
  const parent = child.relationships?.find(r => r.email === email)

  const onFinish = (values) => {
    
    if (values.relationship && values.relationship !== parent.relationship_type) {
      dispatch(updateRelationship({
        child: child.alias,
        relationship: values.relationship,
        onSuccess: () => {},
      }))
    }

    if (file) {
      dispatch(childPhotoUpdating(
        child.alias,
        file,
      ))
    }
    
    child.registrations.forEach(reg => {
      const formData = new FormData()
      formData.append('organisationAlias', reg?.organisationAlias)
      formData.append('social_security', values?.social_security)
      formData.append('parents_marital_status', values?.parents_marital_status)

      dispatch(registrationUpdating(
        child?.alias,
        reg?.alias,
        formData,
        () => {},
      ))
    })

    const newData = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      date_of_birth: dayjs(values?.date_of_birth),
      gender: values?.gender,
      has_pai: values?.has_pai,
      // outdoor_activity_permission: values?.outdoor_activity_permission ?? 0,
      insurance: values?.insurance,
      date_of_birth: dayjs(values.date_of_birth).format('YYYY-MM-DD'),
      allergy: JSON.stringify(values.allergies),
      comment: values.comment,
      photo_permission: +photoPermissionChecked,
    }
    
    dispatch(childProfileUpdating(
      child.alias,
      newData,
      () => setShowDrawer(false),
    ))

  }

  const uploadProps = {
    fileList: [],
    beforeUpload: (file) => {
      setFile(file)
      return file
    },
  }
  
  useEffect(() => {
    if (child && child.allergy) {
      let defaultAllergy = JSON.parse(child?.allergy)
      setSelected(defaultAllergy)
      
    }

    if (child) {
      initialValues.current = {
        relationship: parent?.relationship_type,
        first_name: child?.first_name,
        last_name: child?.last_name,
        date_of_birth: dayjs(child?.date_of_birth),
        gender: child?.gender,
        has_pai: child?.has_pai,
        // outdoor_activity_permission: child?.outdoor_activity_permission ?? 0,
        // photo_permission: !!child?.photo_permission,
        insurance: child?.insurance || '',
        comment: child?.comment,
        social_security: child?.registrations?.[0]?.social_security,
        parents_marital_status: child?.temp_parents_marital_status,
      }

      setPhotoPermissionChecked(!!child?.photo_permission)
    }
  
  }, [child])

  const all = allergies.map((x) => {
    return { label: x, value: x }
  })

  // 

  return (
    <Drawer
      open={showDrawer}
      handleCloseDrawer={() => setShowDrawer(false)}
      width={636}
      title={title}
    >
      <Form
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues.current}
      >
        <Form.Item
          label="J’interviens en qualité de"
          name="relationship"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner une relation",
            },
          ]}
        >
          <Select placeholder="Sélectionner">
            <Option value={0}>Mère</Option>
            <Option value={1}>Père</Option>
            <Option value={2}>Tuteur</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Photo (.jpg maximum 1mb)"
          name="photo"
        >
          <ImgCrop rotate>
            <Upload {...uploadProps}>
              <Space style={{ paddingTop: 5 }}>
                {file ? (
                  <CustomProfilAvatar
                    file={file}
                  />
                ) : (
                  <Picture
                    src={child?.photo}
                    width={32}
                    height={32}
                  />
                )}
                <Button size="large" icon={<FileImageOutlined />}>
                  Choisir une image
                </Button>
              </Space>
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Form.Item
          label="Prénom"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner un prénom",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nom"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner un nom",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Date de naissance"
          name="date_of_birth"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner une date de naissance",
            },
          ]}
        >
          <DatePicker
            picker="date"
            format="DD-MM-YYYY"
            style={{ width: 280 }}
          />
        </Form.Item>
        <Form.Item
          label="Sexe"
          name="gender"
          rules={[
            {
              required: true,
              message:
                "Veuillez indiquer s'il s'agit d'une fille ou d'un garçon",
            },
          ]}
        >
          <Radio.Group>
            <Space>
              <Radio.Button value={1}>
                <Space size="small">
                  <Gender gender={1} $size={20} />
                  <span style={{ color: "#EB2F96" }}>Fille</span>
                </Space>
              </Radio.Button>
              <Radio.Button value={0}>
                <Space size="small">
                  <Gender gender={0} $size={20} />

                  <span style={{ color: "#1890FF" }}>Garçon</span>
                </Space>
              </Radio.Button>
            </Space>
          </Radio.Group>
        </Form.Item>

        <>
          <Form.Item
            label="Sécurité sociale"
            name="social_security"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner le type d'assurance",
              },
            ]}
          >
            <Radio.Group>
              <Space>
                <Radio.Button value={0}>Régime général</Radio.Button>
                <Radio.Button value={1}>MSA</Radio.Button>
                <Radio.Button value={2}>Autre</Radio.Button>
              </Space>
            </Radio.Group>
          </Form.Item>
        </>
        <Form.Item
          label="Statut marital des parents"
          name="parents_marital_status"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le Statut marital",
            },
          ]}
        >
          <Radio.Group>
            <Space>
              {parentMaritalStatus.map(({ label, value }, i) => (
                <Radio.Button value={value} key={i}>
                  {label}
                </Radio.Button>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="insurance" label="Nom et numéro de police d’assurance">
          <Input />
        </Form.Item>
        <Form.Item
          label="Restriction(s) alimentaire(s)"
          name="allergies"
        >
          <Search
            placeholder="Rechercher..."
            options={all}
            mode="multiple"
            defaultValue={selected}
            value={selected}
            onChange={setSelected}
          />
        </Form.Item>
        <Form.Item name="has_pai" valuePropName="checked">
          <Checkbox>Dispose d’un PAI (Projet d'Accueil Individualisé)</Checkbox>
        </Form.Item>
        {/* <Form.Item name="photo_permission" valuePropName="checked">
          <Space direction="horizontal">
            <Switch
              checked={photoPermissionChecked}
              onChange={val => setPhotoPermissionChecked(val)}
            /> 
            <span>Autorisé(e) à être pris(e) en photo</span>
          </Space>
        </Form.Item> */}
        {/* <Form.Item name="outdoor_activity_permission" valuePropName="checked">
          <Space direction="horizontal">
            <Switch /> Autorisé(e) à aller en sorties scolaires
          </Space>
        </Form.Item> */}
        {/* <Form.Item
          name="comment"
          label="Commentaire"
        >
          <TextArea rows={4} />
        </Form.Item> */}
        <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          style={{ width: "100%", marginTop: 20, marginBottom: 50 }}
          // onClick={() => setShowDrawer(false)}
        >
          Enregistrer
        </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default EditChild
