import React from "react"
import { Button } from "antd"
import styled from "styled-components"

const PrimaryButton = (props) => (
  <Button
    {...props}
    style={{
      background: "#1890FF",
      borderColor: "#1890FF",
      color: "#fff",
      margin: 10,
      ...props.style,
    }}
  ></Button>
)

export default PrimaryButton
