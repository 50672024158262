export const paymentMethod = (paymentHistory) => {
  const isSomePaymentByAvoir = paymentHistory?.some(
    (payment) => payment.method === 10,
  )
  const isSomePaymentByCB = paymentHistory?.some(
    (payment) => payment.method === 0,
  )
  const isSomePaymentOnSpot = paymentHistory?.some(
    (payment) => ![0, 10].includes(payment.method),
  )

  const isPaymentByCB = paymentHistory?.every(
    (payment) => payment.method === 0,
  )

  const isPaymentByAvoir = paymentHistory?.every(
    (payment) => payment.method === 10,
  )

  const isPaymentOnSpot = paymentHistory?.every(
    (payment) => ![0, 10].includes(payment.method),
  )

  switch (true) {
    case isSomePaymentByAvoir && isSomePaymentByCB:
      return 'Avoir et En ligne'
    case isSomePaymentByAvoir && isSomePaymentOnSpot:
      return 'Avoir et Autre'
    case isPaymentOnSpot:
      return '-'
    case isPaymentByCB:
      return 'En ligne'
    case isPaymentByAvoir:
      return 'Avoir'
    default:
      return null
  }
}

