import {
  all,
  takeLatest,
  put,
  select,
} from 'redux-saga/effects'

import axios from '~/lib/axios'

// redux
import { getAuthUserInfos } from '~/containers/User/selectors'
import { checkJwtExpiration } from '~/containers/User/sagas'
import { logout } from '~/containers/User/actions'
import {
  fetchBills,
  fetchBillsProcess,
  fetchBillsSuccess,
  fetchBillsError,

  // download pdf
  downloadPdf,
} from './actions'

/**
 * fetchBills
 */
export function* fetchBillsSaga({
  payload: {
    search = '',
    page = 1,
    pageSize = 1000,
  },
}) {
  try {
    yield* checkJwtExpiration()
    yield put(fetchBillsProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.get(`users/${authUserInfos.email}/bills/?page=${page}&page_size=${pageSize}&search=${search}`)
      yield put(fetchBillsSuccess(response.data))
    } else {
      yield put(fetchBillsError())
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    yield put(fetchBillsError())
  }
}

export function* downloadPdfSaga({ payload: { url, billName } }) {
  try {
    const res = yield axios.get(url, {
      responseType: 'blob', // important
    })

    if (typeof window !== 'undefined') {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl

      link.setAttribute('download', `${billName}.pdf`)
      document.body.appendChild(link)
      link.click()
    }
  } catch (error) {
    console.log('error while downloading')
  }
}
export default function* rootSaga() {
  yield all([
    takeLatest(fetchBills, fetchBillsSaga),
    takeLatest(downloadPdf, downloadPdfSaga),
  ])
}
