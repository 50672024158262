import styled from 'styled-components'

const PageHeader = styled.div`
display: flex;
margin-bottom: 10px;
justify-content: space-between;
align-items: center;
border-bottom : 1px solid rgb(226, 226, 226);
padding-bottom : 15px;
margin-bottom : 25px;
& > div > a {
  margin-left:10px
}

& > *{
    margin-bottom:0
}

`
export default PageHeader
