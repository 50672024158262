import styled, { css } from 'styled-components'
import { Button, Modal } from 'antd'
import Card from '~/components/common/Card'
import Image from '~components/common/Image'

export const ArrowIcon = styled.img`
  margin-right: 15px
`

const darkBackgroundCSS = css`
    background-color: #0f2d78;
    padding: 20px;
    margin: -20px -20px 10px -20px;
`

const lightColorCSS = css`
  color: @white-text-color;
`

const fadedLightColorCSS = css`
  color: fade(@white-text-color, 50);
`

export const RegistrationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => props.dark && darkBackgroundCSS};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  .anticon{
    font-size: 30px;
    ${props => props.dark && lightColorCSS};
  }
`

export const RegistrationCard = styled(Card)`
margin-top: 30px;
.organisation-city{
  line-height: 30px;
  margin-bottom: 0;
}
.organisation-new-subscription-button{
  float: right;
}
`

export const RegistrationStatusContainer = styled.div`
  font-size: 12px;
  color: @primary-color;
  margin-left: -5px;
`

export const RegistrationInfo = styled.div`
 display: flex;
 align-items: center;
 .text-container{
    margin-left: 20px;
  }
  .activity-name{
    margin-bottom: 5px;
    font-size: 16px;
    color: @dark-white-color;
    ${props => props.active && lightColorCSS};
  }
  & > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .school-info{
      color: @gray-current;
      margin-left: 15px;
      ${props => props.active && fadedLightColorCSS};
    }
    .reg-with-link{
      cursor: pointer;
    }
    .school-name{
      color: @dark-color;
      margin-bottom: 5px;
    }
    .child-class-name{
      color: @gray-color;
      font-size: 13px;
      margin-bottom: 0px;
    }
    .left-container{
      display: flex;
      align-items: center;
    }
    .reg-status{
      color: @gray-color;
      font-size: 13px;
      margin: 10px 0 0 -5px;
    }
    .renewal{
      float: right;
    }
  }
`

export const RegistrationDescription = styled.div`
  padding: 20px;
  padding-bottom: 10px;
  p {
    color: fade(#0f2d78, 60);
    font-size: 15px;
  }
`

export const RegistrationButtonContainer = styled.div`
  display: flex;
  justify-content: left;
  margin: 15px;
`

export const RegistrationBody = styled.div`
  > button{
    display: block;
    margin: 10px auto;
  }
  p {
    margin: 10px auto;
  }
  .preview {
    width: 100%;
    height: 100%;
  }
`
export const RegistrationFooter = styled.div`
  padding: 20px;
  padding-bottom: 0px;
  p {
    color: fade(#0f2d78, 60);
    font-size: 15px;
    margin-bottom: 10px;
  }
  .ant-tag{
    margin-bottom: 10px;
    margin-right: 5px;
  }
`
export const DocumentContainer = styled.div`
 .anticon{
   font-size: 20px;
   align-items: center;
 }
 & > button{
   margin-top: 5px;
   margin-left: 0px;
 }
`
export const CardModal = styled.div`
display: flex;
justify-content: space-around;
background: white;
box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
margin: auto;
width: content;
`
export const ViewModal = styled(Modal)`
display: flex;
justify-content: center;
align-item: center;
`

export const PreviewModal = styled(Image)`
display: flex;
justify-content: space-around;
height: content;
width: content;
`
export const CTA = styled(Button)`
display: flex;
text-align: center;
margin: 10px auto 5px;
`
