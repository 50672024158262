import React, { useState, useCallback, useEffect } from "react";

import { Avatar, Badge, Button, Collapse, Table } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import diffrence from "lodash/difference";
import Container, { MainContainer, MainContent } from "~/components/common/Container";
import H1 from "~/components/common/H1";
import Space from "~/components/common/Space";
import { Doc, MissingDoc } from "./components.js";
import TransmitDoc from "./TramsmitDoc";
import { useDispatch, useSelector } from "react-redux";
import { documentsFetching, householdFetching, missingCommonDocumentsFetching } from "~/containers/Foyer/actions.js";
import { LightText } from "~/containers/Foyer/Documents/components";
import { getDocumentType } from "~/constants/index.js";
import { userOrganisationsFetching } from "~/containers/Home/actions.js";
import Loading from '~/components/common/Loading';
import { childRegistrationFetching, fetchAllRegistrations, fetchRegistrationsDetails, } from "~/containers/Registration/actions.js";
import styled from "styled-components";
import dayjs from "dayjs";
import SchoolSVG from '~/assets/images/school.svg';
import { fetchChildren } from "~/containers/Children/actions.js";
import { useMemo } from "react";
import groupBy from "lodash/groupBy";
import uniqBy from "lodash/uniqBy";

const { Panel } = Collapse;

const ContainerWrapper = ({ children, ...props }) => {
  console.log({props});
  
  return (
    <MainContainer {...props}>
      <MainContent>{React.cloneElement(children, {...props})}</MainContent>
    </MainContainer>
  );
};

const Document = ({
  child,
  organisation,
}) => {
  console.log({child})
  const dispatch = useDispatch();
  const [showDrawer, setShowDrawer] = useState(false);
  const [targetedDocument, setTargetedDocument] = useState(null);
  const [addExistingDocument, setAddExistingDocument] = useState(true);
  const [activeKey, setActiveKey] = useState(['0']);

  const {data: householdData, loading: isLoadingHousehold} = useSelector((state) => state.household.household);
  const {data: documents, loading: isLoadingDocuments} = useSelector((state) => state.household.documents);

  const { userOrganisationsList } = useSelector(
    (state) => state.home.organisation
  );
  // const {
  //   children,
  //   isFetching: isFetchingChildren,
  // } = useSelector((state) => state.children);

  const { allRegistrations, isFetchingAllRegistrations } = useSelector(
    (state) => state.registration
  );

  const groupedDocumentsByNumber = useMemo(
    () => groupBy(documents, "type"),
    [documents]
  );

  console.log({groupedDocumentsByNumber, targetedDocument, documents});

  const columns = [
    {
      title: "Type",
      key: "description",
      // dataIndex: "",
      render: ({ description, docTypeDetails }) => {
        return <BoldText>{description || docTypeDetails?.description}</BoldText>;
      },
    },
    {
      title: "Nom",
      key: "name",
      dataIndex: "",
      render: (data) => {
        if (data?.name) {
          return <a href={data?.file_url + "?show_from_mobile=true"} target="_blank">{data?.name}</a>;
        }
      },
    },
    {
      title: "Enfant(s) concerné(s)",
      key: "name",
      dataIndex: "childrenData",
      render: (children) => {
        console.log({children});
        if (children?.first_name) {
          return(
            <span>
              {children?.first_name}
            </span>
          );
        }

        if (children?.length > 0) {
          return <span>{children?.map(child => child?.first_name)?.sort((a, b) => a?.localeCompare(b)).join(', ')}</span>;
        }
        
        return "-";
      },
    },
    {
      title: "Taille",
      key: "size",
      dataIndex: "",
      render: ({ file_size_label }) => {
        return <>{file_size_label}</>;
      },
    },
    {
      title: "Date d’ajout",
      key: "created_at",
      dataIndex: "",
      render: ({ created_at }) => {
        if (created_at) {
          return <>{dayjs(created_at).format('DD/MM/YYYY')}</>;
        }
      },
    },
    // {
    //   title: "Date d’échéance",
    //   key: "due_date",
    //   dataIndex: "",
    //   render: ({ due_date }) => {
    //     if (due_date) {
    //       return <>{dayjs(due_date).format('DD/MM/YYYY')}</>
    //     }
    //   },
    // },
    {
      title: "Statut",
      key: "statusDetails",
      dataIndex: "",
      render: ({ statusDetails }) => {
        return <Status code={statusDetails?.key}>{statusDetails?.value || 'À transmettre'}</Status>;
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => {
        if ([0, 2, 3, 4].includes(record?.statusDetails?.key) || !record?.statusDetails) {
          return (
            <div>
              <Button
                onClick={() => {
                  setShowDrawer(true);
                  setTargetedDocument(record);
                }}
                size="middle"
                type="link"
              >
                Transmettre
              </Button>
            </div>
          );
        }
      },
    },
  ];

  console.log({householdData, allRegistrations});

  useEffect(() => {
    dispatch(documentsFetching())
    // dispatch(userOrganisationsFetching())
    // dispatch(householdFetching())
  }, [])
  
  // useEffect(() => {
  //   dispatch(householdFetching());
  //   dispatch(documentsFetching());
  //   // dispatch(fetchChildren());
  // }, []);

  // useEffect(() => {
  //   if (householdData?.length) {
  //     dispatch(fetchRegistrationsDetails(
  //       householdData?.[0]?.organisation?.alias,
  //       // children?.map(child => child?.alias)
  //     ));
  //   }
  // }, [householdData]);

  // useEffect(() => {
  //   if (organisation) {
  //     // dispatch(missingCommonDocumentsFetching({
  //     //   organisation_alias: organisation?.alias,
  //     // }));

  //     dispatch(fetchRegistrationsDetails(
  //       // organisation?.alias,
  //       // children?.map(child => child?.alias)
  //     ));
  //   }
  // }, [organisation]);

  useEffect(() => {
    dispatch(fetchAllRegistrations());
  }, []);

  const groupedRegistrationsByOrganisation = useMemo(() => {
    return groupBy(allRegistrations, "organisationAlias");
  }, [allRegistrations]);

  const organisations = useMemo(() => {
    return Object.keys(groupedRegistrationsByOrganisation)?.sort((a, b) => groupedRegistrationsByOrganisation?.[a]?.[0]?.organisationName?.localeCompare(groupedRegistrationsByOrganisation?.[b]?.[0]?.organisationName));
  }, [groupedRegistrationsByOrganisation]);

  console.log({groupedRegistrationsByOrganisation, userOrganisationsList})

  if (isLoadingHousehold || isLoadingDocuments || isFetchingAllRegistrations) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  
  return (
    <CollapseWrapper>
      <Collapse
        defaultActiveKey={organisations?.map((_, index) => `${index}`)}
        expandIconPosition="right"
        bordered={false}
      >
        {organisations?.map((organisationAlias, index) => {
          const thisOrganisationRegistrations = groupedRegistrationsByOrganisation?.[organisationAlias];

          const thisOrganisationRegistrationsCommonDocuments = () => {
            if (!thisOrganisationRegistrations?.length) {
              return [];
            }

            const commonListOfDocuments = thisOrganisationRegistrations?.reduce((acc, registration) => {
              const commonDocuments = registration?.list_of_documents_details?.filter(item => item?.document?.type?.is_common)?.map(item => ({
                ...item,
                child: registration?.child,
                registrationAlias: registration?.alias,
                organisationName: registration?.organisationName,
                organisationAlias: registration?.organisationAlias,
              })) || [];

              return [...acc, ...commonDocuments];
            }, []);

            const commonMissingDocuments = thisOrganisationRegistrations?.reduce((acc, registration) => {
              const missingDocuments = registration?.missing_documents_details?.filter(item => item?.is_common)?.map(item => ({
                ...item,
                child: registration?.child,
                registrationAlias: registration?.alias,
                organisationName: registration?.organisationName,
                organisationAlias: registration?.organisationAlias,
              })) || [];

              return [...acc, ...missingDocuments];
            }, []);

            const commonDocuments = {commonListOfDocuments, commonMissingDocuments};

            return commonDocuments;
          };

          const grouppedCommonListOfDocuments = thisOrganisationRegistrationsCommonDocuments?.()?.commonListOfDocuments?.reduce((acc, item) => {
            const docAlias = item?.document?.alias;

            const sameValidatedDoc = thisOrganisationRegistrationsCommonDocuments?.()?.commonListOfDocuments?.filter(
              doc => doc?.document?.alias === docAlias && doc?.status?.key === 1
            );

            const thisSameValidatedOtherDocsOfSameType = thisOrganisationRegistrationsCommonDocuments?.()?.commonListOfDocuments?.filter(
              doc => doc?.document?.type?.number === item?.document?.type?.number && doc?.status?.key !== 1
            );
            
            console.log({sameValidatedDoc, thisSameValidatedOtherDocsOfSameType})
            // delete sameValidatedDoc?.child

            if (sameValidatedDoc?.length > 1) {
              const childrenWithSameValidatedDoc = thisOrganisationRegistrationsCommonDocuments?.()?.commonListOfDocuments
                ?.filter(
                  doc => doc?.document?.alias === docAlias && doc?.status?.key === 1
                )
                ?.map(doc => doc?.child)
                ?.flat();

              console.log({childrenWithSameValidatedDoc});
              
              if (thisSameValidatedOtherDocsOfSameType?.find(doc => doc?.child_alias === item?.child_alias)) {
                acc = [
                  ...acc.filter(doc => doc?.document?.alias !== docAlias || doc?.status?.key !== 1),
                  {
                    ...sameValidatedDoc[0],
                    childrenData: childrenWithSameValidatedDoc
                  },
                  item,
                ];
              } else {
                acc = [
                  ...acc.filter(doc => doc?.document?.alias !== docAlias || doc?.status?.key !== 1),
                  {
                    ...sameValidatedDoc[0],
                    childrenData: childrenWithSameValidatedDoc
                  },
                ];        
              }
            } else {
              console.log("here", {sameValidatedDoc, item});

              // if (!!sameValidatedDoc?.length) {
              //   acc = [...acc, ...sameValidatedDoc?.[0], item];
              // } else {
                acc = [...acc, item];
              // }      
            }

            return acc;
          }, []);

          const householdDocuments = [
            ...(thisOrganisationRegistrationsCommonDocuments?.()?.commonMissingDocuments?.map(doc => ({
              description: doc?.description,
              organisationName: doc?.organisationName,
              organisationAlias: doc?.organisationAlias,
              registrationAlias: doc?.registrationAlias,
              childrenData: doc?.child,
              number: doc?.number,
            })) || []),
            ...(grouppedCommonListOfDocuments?.map(item => ({
              description: item?.document?.type?.description,
              childrenData: item?.childrenData || item?.child,
              file_url: item?.document?.file_url,
              file_size_label: item?.document?.file_size_label,
              name: item?.document?.name,
              statusDetails: item?.status,
              created_at: item?.document?.created_at,
              number: item?.document?.type?.number,
              organisationName: item?.organisationName,
              organisationAlias: item?.organisationAlias,
              registrationAlias: item?.registrationAlias,
            })) || []),
          ];

          const sortedHouseholdDocuments = householdDocuments?.sort((a, b) => a?.number - b?.number);

          return (
            <ContainerWrapper>
              <Panel
                key={`${index}`}
                index={`${index}`}
                header={
                  <Space
                    style={{
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Space>
                      <Avatar size={42} src={thisOrganisationRegistrations?.[0]?.photo || SchoolSVG} />
                      <h3 style={{marginBottom: 0}}>
                        {thisOrganisationRegistrations?.[0]?.organisationName} / Documents
                      </h3>
                    </Space>
                    {!!thisOrganisationRegistrationsCommonDocuments()?.commonMissingDocuments?.length && (
                      <span>
                        <Badge
                          count={thisOrganisationRegistrationsCommonDocuments()?.commonMissingDocuments?.length}
                          style={{ marginRight: 5 }}
                        />
                        documents manquants
                      </span>
                    )}
                  </Space>
                }
              >
                <Table
                  columns={columns}
                  dataSource={sortedHouseholdDocuments}
                  loading={isLoadingHousehold}
                />
              </Panel>
            </ContainerWrapper>            
          );
        })}
        <TransmitDoc
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          handleCloseDrawer={() => {
            setShowDrawer(false);
            setTargetedDocument(null);
            setAddExistingDocument(true);
          }}
          setTargetedDocument={setTargetedDocument}
          targetedDocument={targetedDocument}
          documentsOfType={groupedDocumentsByNumber?.[targetedDocument?.number]}
          setAddExistingDocument={setAddExistingDocument}
          addExistingDocument={addExistingDocument}
          organisationAlias={householdData?.[0]?.organisation?.alias}
          child={child}
        />
      </Collapse>
    </CollapseWrapper>
  );
};

const CollapseWrapper = styled.div`
  .ant-collapse-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    
    .anticon-right {
      font-size: 32px;
    }
  }

  .ant-collapse-content {
    border-top: none;

    .ant-table-thead {
      th {
        text-transform: uppercase;
      }
    }
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 0;
  }
`;

const BoldText = styled.p`
  font-weight: 700;
  margin-bottom: 0;
`;

const getStatusColor = (code) => {
  if (code === 1) {
    return '#52C41A';
  }

  if (code === 2) {
    return '#FF5876';
  }

  if (code === 3) {
    return '#722ED1';
  }

  if (code === 4 || code === null || code === undefined) {
    return '#646464';
  }

  return '#F4C26D';
};

const Status = styled.span`
  color: ${({code}) => getStatusColor(code)};
  text-transform: capitalize;
`;

export default Document;
