import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Space, Tooltip, Badge } from "antd";
import { useTranslation } from "react-i18next";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import {
  fetchChildren,
  fetchChildDetails,
} from "~/containers/Children/actions";

import Menu, {
  MenuItem,
} from "~/components/common/ContentHeader/components/Menu";
import { PlusOutlined, EditOutlined, FrownFilled, CalendarOutlined, LeftOutlined, RightOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Routes, Route } from "react-router-dom";
import ContentHeader from "~/components/common/ContentHeader";
import Button from "~/components/common/Button";
import Link from "~/components/common/Link";
import ProfileChildrenCard from "~/components/common/ProfileChildrenCard";

import ChildRegistration from "./ChildRegistration";
import EditChild from "../Foyer/Members/editChild";
import ChildSante from "./ChildSante";
import ChildDocument from "./ChildDocument";
import Activity from "./Activity";
import OldActivity from "./ChildDetails/index";

// import { ChildCard } from './components'
import dayjs from "dayjs";
import ActivityIcon from "~/components/common/OrganisationPicture";
import H1 from "~/components/common/H1";
import H2 from "~/components/common/H2";
import { childRegistrationFetching } from "~/containers/Registration/actions";

const Children = () => {
  const user = useSelector((state) => state.user.userInfo.user);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  // 
  const { t } = useTranslation(["children", "common"]);

  // const { children, isFetching } = useSelector(state => state.children)
  const dispatch = useDispatch();

  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [hideFinishedActivities, setHideFinishedActivities] = useState(false);

  const {
    childDetails: child,
    isFetchingChildDetails,
    children,
    isFetching: isFetchingChildren,
  } = useSelector((state) => state.children);
  const {list: childRegistrations, loading: loadingChildRegistrations} = useSelector((state) => state.registration);

  const [selectedTab, setSelectedTab] = useState();
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    if (child?.alias) {
      dispatch(childRegistrationFetching(
        child?.alias,
        () => console.log('doneee')
      ));
    }
  }, [child?.alias]);
  
  const useChildCardTransform = (child) => {
    const birthdate = dayjs(child?.date_of_birth);
    // const age = Math.floor(
    //   dayjs.duration(dayjs().diff(birthdate)).as("years")
    // );
    const age = dayjs().diff(child?.date_of_birth, "years", false);

    return {
      alias: child?.alias,
      name: `${child?.first_name ?? ""} ${child?.last_name ?? ""}`,
      birthdate: birthdate.format("DD/MM/YYYY"),
      age,
      gender: child?.gender,
      schoolLevelName: `${child.school_level?.value ?? ""} ${
        child.school_class?.name ? `- ${child.school_class?.name}` : ``
      }`,
      schoolName: child?.school?.name ?? "",
      schoolAlias: child?.school_class?.alias,
      status: child?.status?.key,
      picture: child?.photo_url,
      households: child?.households,
    };
  };

  const childData = useChildCardTransform(child);

  const handleMenu = (e) => {
    setSelectedTab(e.key);
  };

  useEffect(() => {
    dispatch(fetchChildren());
    if (params.id) {
      // dispatch(childRegistrationFetching(params.id));
      const thisChild = children.find((ch) => ch.alias === params.id);
      
      dispatch(fetchChildDetails({ url: thisChild?.url }));
    }
  }, [params.id, children.length]);

  useEffect(() => {
    if (location.pathname.includes('/activity')) {
      setSelectedTab(["activity"]);
    } else if (location.pathname.includes('/sante')) {
      setSelectedTab(["sante"]);
    } else if (location.pathname.includes('/document')) {
      setSelectedTab(["document"]);
    } else {
      setSelectedTab(["foyer"]);
    }
  }, [location]);

  const prevMonth = () => {
    dayjs(selectedMonth).subtract(1, "months");
    setSelectedMonth(dayjs(selectedMonth).subtract(1, "months"));
  };

  const nextMonth = () => {
    setSelectedMonth(dayjs(selectedMonth).add(1, "months"));
  };

  const missingDocuments = childRegistrations?.reduce((acc, cur) => {
    acc = acc + cur?.missing_documents_details?.filter(doc => !doc?.is_common)?.length;

    return acc;
  }, 0) || 0;

  return (
    <>
      <ContentHeader
        variant="sticky"
        breadcrumbItems={[
          {
            name: "Foyer",
            path: "/foyer",
          },
          {
            name: child?.first_name + " " + child?.last_name,
            path: "/foyer",
          },
        ]}
        buttons={() => (
          <Space direction="vertical">
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                onClick={() => navigate(`/reservation/${child.alias}`)}
              >
                <CalendarOutlined />
                Inscrire à une activité
              </Button>
              <Button
                size="large"
                style={{ minWidth: 220 }}
                onClick={() => setShowDrawer(true)}
                icon={<EditOutlined />}
              >
                Éditer
              </Button>
            </Space>
            {child?.subscriptions?.length > 0 && (
              <Space
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Space>
                  <LeftOutlined onClick={() => prevMonth()} style={{ fontSize: 20 }} />
                  <H2
                    style={{
                      textTransform: "capitalize",
                      fontSize: "20px",
                      padding: "10px",
                      marginBottom: 0,
                    }}
                  >
                    {dayjs(selectedMonth).format("MMMM YYYY")}
                  </H2>
                  <RightOutlined onClick={() => nextMonth()} style={{ fontSize: 20 }} />
                </Space>
                <Space>
                  <Tooltip title={`${hideFinishedActivities ? 'Afficher' : 'Masquer'} les activités terminées`}>
                    {!hideFinishedActivities && (
                      <EyeInvisibleOutlined
                        style={{ fontSize: 25, cursor: 'pointer' }}
                        onClick={() => setHideFinishedActivities(state => !state)}
                      />
                    )}
                    {hideFinishedActivities && (
                      <EyeOutlined
                        style={{ fontSize: 25, cursor: 'pointer' }}
                        onClick={() => setHideFinishedActivities(state => !state)}              
                      />
                    )}
                  </Tooltip>
                  <Button size="large" onClick={() => setSelectedMonth(dayjs())}>
                    Aujourd’hui
                  </Button>
                </Space>
              </Space>
            )}
          </Space>
        )}
        // navigationTitle="Année scolaire :"
        navigation={() => (
          <Menu onClick={handleMenu} selectedKeys={selectedTab}>
            <MenuItem key="foyer">
              <Link to={`/children/${child.alias}`}>Inscriptions</Link>
            </MenuItem>
            <MenuItem key="activity">
              <Link to={`/children/${child.alias}/activity`}>
                Activités et réservations
              </Link>
            </MenuItem>
            <MenuItem key="sante">
              <Link to={`/children/${child.alias}/sante`}>Santé</Link>
            </MenuItem>
            <MenuItem key="document">
              <Link to={`/children/${child.alias}/document`}>
                <span>
                  Documents
                  <Badge
                    count={missingDocuments}
                    style={{ marginLeft: 5 }}
                  />
                </span>
              </Link>
            </MenuItem>
          </Menu>
        )}
      >
        <ProfileChildrenCard
          isLoading={isFetchingChildDetails}
          // onStatusChange={onStatusChange}
          {...childData}
        />
      </ContentHeader>
      <Routes>
        <Route
          path="/*"
          exact
          element={
            <ChildRegistration
              isFetchingChildDetails={isFetchingChildDetails}
              child={child}
            />
          }
        />
        <Route path="/activity" exact element={
          <Activity
            isFetchingChildDetails={isFetchingChildDetails}
            child={child}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            hideFinishedActivities={hideFinishedActivities}
            setHideFinishedActivities={setHideFinishedActivities}
          />
        } />
        <Route path="/old" exact element={<OldActivity />} />
        <Route path="/sante" exact element={<ChildSante child={child} />} />
        <Route path="/document" exact element={<ChildDocument child={child} />} />
      </Routes>
      <EditChild
        title="Éditer un enfant"
        child={child}
        setShowDrawer={setShowDrawer}
        showDrawer={showDrawer}
      ></EditChild>
    </>
  );
};

export default Children;
