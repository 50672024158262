import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import xor from "lodash/xor";
import Space from "~/components/common/Space";
import { DocumentTypes, getDocumentType } from "~/constants/index";
import { useParams } from "react-router-dom";
import {
  PhoneFilled,
  MailFilled,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Button, Upload, Divider, message, Drawer, Skeleton } from "antd";
import { CheckCircleFilled, CameraFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import groupBy from "lodash/groupBy";
import { addNewDocument, documentsFetching } from "~/containers/Foyer/actions";
import { fetchChildrenRegistrations } from "~/containers/Children/actions";
import { registrationDetailsFetching, registrationUpdating } from "~/containers/Registration/actions";
import { AddDocument } from "~/containers/Registration/Step/components/addDocument";

const Step3 = ({
  setCurrentStep,
  childAliases,
  children,
  selectedOrganisation,
}) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [selectedChildRegistration, setSelectedChildRegistration] = useState(null);
  const [addExistingDocument, setAddExistingDocument] = useState(true);

  const params = useParams();
  const dispatch = useDispatch();
  const { registrationDetails, registrationsDetails, isFetchingDetails } = useSelector(
    (state) => state.registration
  );
  const {
    data: documents,
  } = useSelector((state) => state.household.documents);
  
  const {
    child_alias: childAlias,
    registration_alias: registrationAlias,
  } = params;

  const allRegistrations = useMemo(
    () => registrationAlias && childAlias ? [registrationDetails] : registrationsDetails,
    [registrationDetails, registrationsDetails, registrationAlias, childAlias]
  );

  const organisationDocumentsToFill = useMemo(
    () => (selectedOrganisation?.documents_organisation || registrationDetails?.documents_organisation)
      ?.filter((document) => document?.status === 2),
    [selectedOrganisation, registrationDetails]
  );

  const missingDocuments = useMemo(() => registrationDetails?.missing_documents_details, [registrationDetails]);

  const listOfDocuments = useMemo(
    () => allRegistrations?.[0]?.organisation_list_of_documents_details, [allRegistrations],
  );

  const commonListOfDocuments = useMemo(
    () => listOfDocuments?.filter((doc) => doc?.is_common), [listOfDocuments]
  );

  const allChildrenPrivateListOfDocuments = useMemo(
    () => {
      if (allRegistrations) {
        return allRegistrations?.reduce((acc, registration) => {
          acc = [
            ...acc,
            {
              [registration?.child?.alias]: {
                registration,
                documents: registration?.organisation_list_of_documents_details
                  ?.filter((doc) => !doc?.is_common)
                  ?.filter(
                    (doc) => {
                      if (
                        doc?.number === 6 &&
                        registration?.parents_marital_status !== 3
                      ) {
                        return false;
                      }
              
                      return true;
                    })
              }
            }
          ];
              return acc;
            }, []);
      } else {
        return [];
      }
    },
    [allRegistrations]
  );

  const groupedDocumentsByNumber = useMemo(
    () => groupBy(documents, "type"),
    [documents]
  );

  useEffect(() => {
    if (registrationAlias && childAlias) {
      dispatch(
        registrationDetailsFetching(
          childAlias,
          registrationAlias,
        )
      );
    }
  }, [registrationAlias, childAlias]);

  useEffect(() => {
    dispatch(
      documentsFetching()
    );
  }, []);

  const givenCommonDocuments = useMemo(
    () => allRegistrations?.[0]?.list_of_documents?.filter((doc) => doc?.is_common),
    [allRegistrations],
  );

  const handleCloseDrawer = useCallback(() => {
    setSelectedDocumentType(null);
    setSelectedChildRegistration(null);
    setAddExistingDocument(true);
  }, []);

  const canProceed = useMemo(
    () => {
      const missingDocumentsCount = allRegistrations?.reduce((acc, reg) => {
        return acc + reg?.missing_documents?.length;
      }, 0);

      if (missingDocumentsCount) {
        return false;
      }
      return true;
    },
    [allRegistrations],
  );

  console.log({selectedDocumentType, registrationDetails, allChildrenPrivateListOfDocuments, commonListOfDocuments, allRegistrations});

  if (isFetchingDetails) {
    return (
      <Skeleton active />
    );
  }

  return (
    <>
      {!!organisationDocumentsToFill?.length && (
        <>
          <BTitle>Documents à remplir</BTitle>
          <FlexContainer>
            {organisationDocumentsToFill?.map((document) => (
              <a
                href={document?.link}
                target="_blank"
                style={{
                  marginLeft: 20,
                  marginBottom: 20,
                  border: "1px solid #E2E4E6",
                  padding: "5px 16px",
                  color: "#1E1E1E",
                }}
              >
                <DownloadOutlined style={{ marginRight: 5 }} />
                Télécharger: {document?.name}
              </a>
            ))}
          </FlexContainer>
        </>
      )}
      <BTitle>Transmettez vos pièces justificatives</BTitle>
      <FlexContainer>
        {commonListOfDocuments?.map((doc, key) => {
          const isGiven = givenCommonDocuments?.find(dc => dc?.doctype === String(doc?.number));

          console.log({isGiven, givenCommonDocuments, doc})
          
          return (
            <Document
              key={doc?.number}
              $isValidated={isGiven}
              onClick={() => {
                setSelectedDocumentType(doc);
                setSelectedChildRegistration(null);
              }}
            >
              {isGiven && (
                <ValidateIcon>
                  <CheckCircleFilled style={{ background: "white" }} />
                </ValidateIcon>
              )}
              <CircleBackground>
                <CameraFilled />
              </CircleBackground>
              <DocName>{doc.description}</DocName>
            </Document>
          );}
        )}
      </FlexContainer>

      {allChildrenPrivateListOfDocuments?.map((childDocs, key) => {
        const childRegistration = Object.values(childDocs)[0]?.registration;
        const childName = childRegistration?.child?.first_name;
        const childDocuments = Object.values(childDocs)[0].documents;

        return (
          <>
            <div key={key}>
              <ChildNameContainer>
                <ChildName>Documents de {childName}</ChildName>
                <Line />
              </ChildNameContainer>
              <FlexContainer>
                {childDocuments?.length === 0 && (
                  <div>Aucun document necessaire</div>
                )}
                {childDocuments?.map((doc, key) => {
                  const givenPrivateChildDocuments = childRegistration?.list_of_documents?.filter((doc) => !doc?.is_common);
                  const isGiven = givenPrivateChildDocuments?.find(dc => dc?.doctype === String(doc?.number));

                  return (
                    <Document
                      $isValidated={isGiven}
                      key={`${doc?.number}_${childName}`}
                      onClick={() => {
                        setSelectedDocumentType(doc);
                        setSelectedChildRegistration(childRegistration);
                      }}
                    >
                      {isGiven && (
                        <ValidateIcon>
                          <CheckCircleFilled style={{ background: "white" }} />
                        </ValidateIcon>
                      )}
                      <CircleBackground>
                        <CameraFilled />
                      </CircleBackground>
                      <DocName>{doc?.description}</DocName>
                    </Document>
                  );})}
              </FlexContainer>
            </div>
          </>
        );
      })}

      <div style={{ marginTop: 20 }}>
        <Button
          style={{ selfAlign: "center", width: 266, height: 40 }}
          size="large"
          type="primary"
          // disabled={!canProceed}
          onClick={() => {
            if (canProceed) {
              setCurrentStep(4);
            }
          }}
        >
          Etape suivante
        </Button>
      </div>

      <Drawer
        title={addExistingDocument ? "Choisir un document existant" : "Transmettre un document"}
        placement="right"
        onClose={handleCloseDrawer}
        open={!!selectedDocumentType}
        // open={true}
        width={400}
      >
        <AddDocument
          documentType={selectedDocumentType}
          documentsOfType={groupedDocumentsByNumber?.[selectedDocumentType?.number]}
          setAddExistingDocument={setAddExistingDocument}
          addExistingDocument={addExistingDocument}
          selectedChildRegistration={selectedChildRegistration}
          allRegistrations={allRegistrations}
          organisationAlias={selectedOrganisation?.alias || registrationDetails?.organisationAlias}
          handleCloseDrawer={handleCloseDrawer}
          chosenChildrenAliases={childAliases}
          allChildren={children}
        />
      </Drawer>
    </>
  );
};

const ChildName = styled.div`
  flex: 1;
  width: 100%;
  white-space: nowrap;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ChildNameContainer = styled.div`
  font-size: 20px;
  line-height: 28px;
  color: #0f2d78;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

const Line = styled.div`
  height: 2px;
  background: #e2e4e6;
  margin-top: 13px;
  margin-left: 16px;
  width: 100%;
`;

const CircleBackground = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: #eef1fe;
  font-size: 32px;
  color: #babfdd;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Document = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  border: 2px solid
    ${({ $isValidated }) => ($isValidated ? "#20cd8d" : "#E2E4E6")};
  width: 266px;
  height: 160px;
  margin: 24px 24px 24px 0;
  padding: 5px;
`;

const ValidateIcon = styled.div`
  margin-top: -40px;
  margin-right: -260px;
  overflow: hidden;
  font-size: 36px;
  color: #20cd8d;
  z-index: 10;
`;

const DocName = styled.span`
  font-size: 16px;
  color: @neutral-5;
  margin-top: 15px;
`;

const Doc = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #e2e4e6;
  width: 266px;
  height: 224px;
  margin: 24px;
`;

const IconContainer = styled.div`
  font-size: 20px;
  color: #2f4580;
  opacity: 1;
  display: inline;
  padding-right: 5px;
`;

const BTitle = styled.div`
  font-size:20px;
  line-height 28px;
  font-weight: 700;
  color: #2F4580;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
`;
const MTitle = styled.div`
  font-size:20px;
  line-height 28px;
  color: #2F4580;
  width: 100%;
  text-align: center;
`;

const STitle = styled.div`
  font-size:16px;
  line-height 24px;
  color: #2F4580;
  width: 100%;
  opacity: 0.5;
  font-weight: bold;
  margin-top: 24px;
`;

const Description = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2f4580;
  opacity: 0.5;
  margin-top: 32px;
`;

const Padding = styled.div`
  padding-right: 10%;
  padding-left: 10%;
  margin: 0 auto;
  max-width: 1000px;
`;

const Required = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: #2f4580;

  mix-blend-mode: normal;
  opacity: 0.5;
`;

export default Step3;
