import { all } from 'redux-saga/effects'

import UserSaga from '~/containers/User/sagas'
import HomeSaga from '~/containers/Home/sagas'
import ChildrenSaga from '~/containers/Children/sagas'
import BillSaga from '~/containers/Bills/sagas'
import RegistrationSaga from '~/containers/Registration/sagas'
import SubscriptionSaga from '~/containers/Subscription/sagas'
import HouseholdSaga from '~/containers/Foyer/sagas'
import ContactSaga from '~/containers/Foyer/Contacts/sagas'
import SepaSaga from '~/containers/User/UserProfile/sagas'
import OrgsSaga from '~/containers/User/UserProfile/sagas'
import CartSaga from '~/containers/Cart/sagas'

// ChD 20240314 code quality GitLab CI/CD procedure deployment

function* rootSaga() {
  yield all([
    UserSaga(),
    HomeSaga(),
    ChildrenSaga(),
    BillSaga(),
    RegistrationSaga(),
    SubscriptionSaga(),
    HouseholdSaga(),
    ContactSaga(),
    SepaSaga(),
    OrgsSaga(),
    CartSaga(),
  ])
}

export default rootSaga
