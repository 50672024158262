import styled from 'styled-components'
import {
  Layout,
} from 'antd'

export const Footer = styled(Layout.Footer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #EEF1FE;

  .link {
    font-size: x-small
  }
`
