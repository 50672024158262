module.exports = {
  "primary-color": "#1890ff",
  "accent-color": "#24dd98",
  "white-text-color": "#f5f5f5",
  "white-background-color": "#efefef",
  "dark-blue-color": "#0f2d78",
  "dark-color": "#232528",
  "black-color": "#333",
  "grey-color": "#999",
  "almost-white-background": "#f0f2f5",
  "neutral-4": "#646464",
  "light-violet": "#eef1fe",
  "body-background": "#eef1fe",
  "layout-header-background": "#0f2d78",
  "layout-header-height": "68px",
  "layout-body-background": "#eef1fe",
  "layout-footer-background": "#f0f2f5",
  "menu-dark-color": "white",
  "menu-dark-highlight-color": "#24dd98",
  "menu-dark-item-active-bg": "transparent",
  "item-hover-bg": "#e6f7ff",
  "avatar-bg": "#24dd98",
  "calendar-item-active-bg": "#efefef",
  "process-tail-color": "#999",
  "form-vertical-label-padding": "0 0 0",
  "screen-xs": "480px",
  "screen-xs-min": "480px",
  "screen-sm": "576px",
  "screen-sm-min": "576px",
  "screen-md": "768px",
  "screen-md-min": "768px",
  "screen-lg": "1024px",
  "screen-lg-min": "1024px",
  "screen-xl": "1200px",
  "screen-xl-min": "1200px",
  "screen-xxl": "1600px",
  "screen-xxl-min": "1600px"
};
