import {
  takeLatest,
  takeEvery,
  put,
  select,
  all,
  call,
} from "redux-saga/effects"
import axios, { backofficeAxios } from "~/lib/axios"
import {
  registrationDetailsFetching,
  registrationDetailsFetched,
  registrationDetailsFetchError,
  childRegistrationFetching,
  childRegistrationFetched,
  childRegistrationFetchError,
  registrationToOrganisationFetching,
  registrationToOrganisationFetched,
  registrationToOrganisationFetchError,
  registrationCreating,
  registrationCreated,
  registrationCreateError,
  registrationUpdating,
  registrationUpdated,
  registrationUpdateError,
  commentSubmitting,
  commentSubmitted,
  commentSubmitError,
  registrationSubmitting,
  registrationSubmitted,
  registrationSubmitError,
  organisationsByZipCodeFetching,
  organisationsByZipCodeFetched,
  organisationsByZipCodeFetchError,
  documentUploading,
  documentUploaded,
  documentUploadError,
  documentFetching,
  documentFetched,
  documentFetchError,
  documentsTypesFetching,
  documentsTypesFetched,
  documentsTypesFetchError,
  orgDocumentsFetching,
  orgDocumentsFetched,
  orgDocumentsFetchError,
  linkDocumentToRegistration,
  linkDocumentToRegistrationSuccess,
  linkDocumentToRegistrationError,
  fetchRegistrationsDetails,
  fetchRegistrationsDetailsSuccess,
  fetchRegistrationsDetailsError,
  fetchAllRegistrations,
  fetchAllRegistrationsSuccess,
  fetchAllRegistrationsError,
} from "./actions"

import { checkJwtExpiration } from "~/containers/User/sagas"
import {
  checkJwtProcess,
  logout,
  fetchUserDocuments,
} from "~/containers/User/actions"
import { getAuthUserInfos } from "~/containers/User/selectors"
import * as api from "./api"
import { fetchChildRegisterationSaga } from "~/containers/Children/sagas"

function* fetchRegistrationDetails({
  payload: { childAlias, registrationAlias, callback },
}) {
  try {
    yield put(checkJwtProcess())
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(registrationDetailsFetchError())
      yield put(logout())
      if (typeof callback === "function") callback(null)
    }
    const response = yield axios.get(
      `users/${userInfos.email}/children/${childAlias}/registrations/${registrationAlias}/`
    )

    yield put(registrationDetailsFetched(response.data))
    if (typeof callback === "function") callback(response.data)
  } catch (err) {
    if (typeof callback === "function") callback(null)

    yield put(registrationDetailsFetchError())
  }
}

export function* fetchChildRegistrations({ payload: { childAlias, callback } }) {
  try {

    console.log({childAlias })
    yield put(checkJwtProcess())
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(childRegistrationFetchError())
      yield put(logout())
      if (typeof callback === "function") callback(null)
    }

    const response = yield axios.get(
      `users/${userInfos.email}/children/${childAlias}/registrations/?page_size=1000`
    )

    if (typeof callback === "function") callback(response.data.results)
    yield put(childRegistrationFetched(response.data.results))
  } catch (err) {
    console.log(err)
    if (typeof callback === "function") callback(null)
    yield put(childRegistrationFetchError())
  }
}

function* fetchRegistrationToOrganisation({
  payload: { childAlias, organisationAlias, callback },
}) {
  try {
    yield put(checkJwtProcess())
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(registrationToOrganisationFetchError())
      yield put(logout())
      if (typeof callback === "function") callback(null)
    }

    let nextPage = 1
    do {
      const response = yield axios.get(
        `users/${userInfos.email}/children/${childAlias}/registrations/?page=${nextPage}`
      )

      const foundit = response.data.results.find(
        (element) => element.organisationAlias === organisationAlias
      )

      if (foundit) {
        if (typeof callback === "function") callback(foundit)
        yield put(registrationToOrganisationFetched(foundit))
        break
      }
      nextPage = response.data.next
    } while (nextPage)
  } catch (err) {
    // console.log(err)
    if (typeof callback === "function") callback(null)
    yield put(registrationToOrganisationFetchError())
  }
}

export function* createRegistration({
  payload: { childAlias, organisationAlias, data, callback },
}) {
  
  try {
    yield put(checkJwtProcess())
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(registrationCreateError())
      yield put(logout())
      if (typeof callback === "function") callback(null)
      return
    }

    let result = []

    if (Array.isArray(childAlias)) {
      
      for (let i = 0; i < childAlias.length; i += 1) {
      
      const response = yield axios.post(`users/${userInfos.email}/children/${childAlias?.[i]}/registrations/`, {
          organisationAlias,
          ...data,
        })
        
        if (response.data?.alias) {
          result.push({
            childAlias: childAlias?.[i],
            registrationAlias: response.data?.alias,        
          })
        }
      }
    } else {
      const response = yield axios.post(`users/${userInfos.email}/children/${childAlias}/registrations/`, {
        organisationAlias,
        ...data,
      })

      if (response.data?.alias) {
        result.push({
          childAlias: childAlias,
          registrationAlias: response.data?.alias,        
        })
      }
    }
    if (typeof callback === "function") callback(result)
    yield put(registrationCreated())
  } catch (err) {
    if (typeof callback === "function") callback(null)
    // console.log(err)
    yield put(registrationCreateError())
  }
}

export function* updateRegistration({
  payload: { childAlias, registrationAlias, data, callback },
}) {
  try {
    yield put(checkJwtProcess())
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(registrationUpdateError())
      yield put(logout())
      // if (typeof callback === 'function') callback(null)
    }
    const response = yield call(api.updateRegistration, {
      childAlias,
      registrationAlias,
      data,
      userInfos,
    })
    if (typeof callback === "function") callback(response.data)
    yield put(registrationUpdated(response.data))
    yield* fetchChildRegisterationSaga()
  } catch (err) {
    // if (typeof callback === 'function') callback(null)
    console.log(err)
    yield put(registrationUpdateError())
  }
}

function* submitComment({
  payload: { childAlias, registrationAlias, content },
}) {
  try {
    yield put(checkJwtProcess())

    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(commentSubmitError())
      yield put(logout())
    }
    yield axios.post(
      `users/${userInfos.email}/children/${childAlias}/registrations/${registrationAlias}/comments/`,
      {
        content,
      }
    )
    yield put(commentSubmitted())
  } catch (err) {
    console.log(err)
    yield put(commentSubmitError())
  }
}

export function* submitRegisteration({
  payload: { childAlias, registrationAlias, cb },
}) {
  try {
    yield put(checkJwtProcess())
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(registrationSubmitError())
      yield put(logout())
      return
    }
    yield call(api.submitRegisteration, {
      userInfos,
      childAlias,
      registrationAlias,
    })
    yield put(registrationSubmitted())
    if (typeof cb === "function") cb()
  } catch (err) {
    console.log(err)
    yield put(registrationSubmitError())
  }
}

function* fetchOrganisationsByZipCode({ payload: { zipCode, page } }) {
  try {
    yield put(checkJwtProcess())
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(organisationsByZipCodeFetchError())
    }

    let list = []
    let nextPage = 1
    do {
      const response = yield axios.get(
        `organisations/?zipcode=${zipCode}&page=${page || 1}`
      )
      list = list.concat(response.data.results)
      nextPage = response.data.next
    } while (nextPage)
    
    console.log({list})

    yield put(organisationsByZipCodeFetched(list))
  } catch (err) {
    console.log(err)
    yield put(organisationsByZipCodeFetchError())
  }
}

function* uploadDocument({ payload: { data, onSuccess, onFailure } }) {
  try {
    yield put(checkJwtProcess())
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(documentUploadError())
      yield put(logout())
      if (typeof onFailure === "function") onFailure()
    }

    const formData = new FormData()
    formData.append("type", data.type)
    formData.append("file", data.file)
    formData.append("child_alias", data.child_alias)
    formData.append("registration_alias", data.registration_alias)
    formData.append("name", data.name)
    formData.append("shared_with_other_users", data.shared_with_other_users)

    const response = yield axios.post(
      `users/${userInfos.email}/documents/`,
      formData
    )

    yield put(documentUploaded(response.data))
    yield put(fetchUserDocuments({ url: null }))
    if (typeof onSuccess === "function") onSuccess(response.data)
  } catch (err) {
    if (typeof onFailure === "function") onFailure()
    console.log(err)
    yield put(documentUploadError())
  }
}

function* fetchDocument({ payload: { documentAlias, onSuccess, onFailure } }) {
  try {
    yield put(checkJwtProcess())
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(documentFetchError())
      yield put(logout())
      if (typeof onFailure === "function") onFailure()
    }

    const response = yield axios.get(
      `users/${userInfos.email}/documents/${documentAlias}`
    )

    yield put(documentFetched(response.data))
    if (typeof onSuccess === "function") onSuccess(response.data)
  } catch (err) {
    if (typeof onFailure === "function") onFailure()
    console.log(err)
    yield put(documentFetchError())
  }
}

function* fetchDocumentsTypes() {
  try {
    yield put(checkJwtProcess())
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(documentsTypesFetchError())
      yield put(logout())
    }

    const response = yield backofficeAxios.get(
      `types/doctype/?page_size=1000`
    )
    
    yield put(documentsTypesFetched(response?.data?.results))
    // if (typeof onSuccess === "function") onSuccess(response.data);
  } catch (err) {
    // if (typeof onFailure === "function") onFailure();
    console.log(err)
    yield put(documentsTypesFetchError())
  }
}

function* fetchOrgDocuments({ payload: { orgAlias } }) {
  try {
    yield put(checkJwtProcess())
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(orgDocumentsFetchError())
      yield put(logout())
    }

    const response = yield backofficeAxios.get(
      `org/${orgAlias}/org_documents/`
    )
    
    yield put(orgDocumentsFetched(response?.data?.results))
    // if (typeof onSuccess === "function") onSuccess(response.data);
  } catch (err) {
    // if (typeof onFailure === "function") onFailure();
    console.log(err)
    yield put(orgDocumentsFetchError())
  }
}

function* linkDocumentToRegistrationSaga({
  payload: data,
}) {
  console.log({data})
  try {
    yield put(checkJwtProcess())

    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(linkDocumentToRegistrationError())
      yield put(logout())
    }
    yield axios.post(
      `users/${userInfos.email}/documents/link/`,
      {
        ...data,
      }
    )
    yield put(linkDocumentToRegistrationSuccess())
    yield put(fetchAllRegistrations())

    if (typeof data.callback === "function") data.callback()
  } catch (err) {
    console.log(err)
    yield put(linkDocumentToRegistrationError())
  }
}

function* fetchRegistrationsDetailsSaga({
  payload: data,
}) {
  console.log({data})
  try {
    yield put(checkJwtProcess())

    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(fetchRegistrationsDetailsError())
      yield put(logout())
    }
    const resp = yield axios.post(
      `users/${userInfos.email}/registrations/`,
      {
        ...data,
      }
    )

    console.log({resp}, '_____')
    yield put(fetchRegistrationsDetailsSuccess(Object.values(resp.data)))

    if (typeof data.callback === "function") data.callback()
  } catch (err) {
    console.log(err)
    yield put(fetchRegistrationsDetailsError())
  }
}

function* fetchAllRegistrationsSaga() {
  try {
    yield put(checkJwtProcess())

    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(fetchAllRegistrationsError())
      yield put(logout())
    }
    const resp = yield axios.post(
      `users/${userInfos.email}/registrations_global/`,
    )

    console.log({resp}, '_____')
    yield put(fetchAllRegistrationsSuccess(resp.data))

    // if (typeof data.callback === "function") data.callback()
  } catch (err) {
    console.log(err)
    yield put(fetchAllRegistrationsError())
  }
}

function* rootSaga() {
  yield all([
    takeLatest(registrationDetailsFetching, fetchRegistrationDetails),
    takeLatest(
      registrationToOrganisationFetching,
      fetchRegistrationToOrganisation
    ),
    takeLatest(registrationCreating, createRegistration),
    takeLatest(registrationUpdating, updateRegistration),
    takeLatest(commentSubmitting, submitComment),
    takeLatest(registrationSubmitting, submitRegisteration),
    takeLatest(organisationsByZipCodeFetching, fetchOrganisationsByZipCode),
    takeLatest(childRegistrationFetching, fetchChildRegistrations),
    takeEvery(documentUploading, uploadDocument),
    takeEvery(documentFetching, fetchDocument),
    takeEvery(documentsTypesFetching, fetchDocumentsTypes),
    takeEvery(orgDocumentsFetching, fetchOrgDocuments),
    takeLatest(checkJwtProcess, checkJwtExpiration),
    takeLatest(linkDocumentToRegistration, linkDocumentToRegistrationSaga),
    takeLatest(fetchRegistrationsDetails, fetchRegistrationsDetailsSaga),
    takeLatest(fetchAllRegistrations, fetchAllRegistrationsSaga),
  ])
}

export default rootSaga
