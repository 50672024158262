import React from 'react'
import PropTypes from 'prop-types'
import {
  Avatar,
  Button,
  Tag,
} from 'antd'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { getActivityStatus, getActivityStatusColor } from '~/utils/activity'

import DocumentTypes from '~/utils/documentsType'

import SchoolSVG from '~/assets/images/school.svg'
import ArrowDown from '~/assets/images/arrow-down.svg'
import Divider from '~/components/common/Divider'
import Dot from '~/components/common/Dot'
import Link from '~/components/common/Link'
import P from '~/components/common/P'

import {
  RegistrationCard,
  RegistrationHeader,
  RegistrationInfo,
  RegistrationBody,
  RegistrationFooter,
  RegistrationDescription,
  RegistrationButtonContainer,
  ArrowIcon,
} from './components'
import { RegistrationContainer } from '../index'
import UserDocument from './UserDocument'

const ExpendedChildRegistration = ({
  registered: reg,
  onActiveChange,
  onContinueRegistration,
}) => {
  const { childDetails: child } = useSelector(state => state.children)
  const navigate = useNavigate()
  const { t } = useTranslation('children')

  
  return (
    <RegistrationContainer>
      <RegistrationCard>
        <RegistrationHeader
          onClick={() => onActiveChange('')}
          dark
        >
          <RegistrationInfo active>
            <Avatar size={60} src={SchoolSVG} />
            <div className="text-container">
              <div className={((reg.status !== 1 && reg.missing_documents.length > 0) || reg.status === 4) ? 'school-info reg-with-link' : 'school-info'}>
                <P bold className="activity-name">{t('child.school-name')}</P>
                <Dot
                  align="center"
                  color={getActivityStatusColor(reg.status, reg.renewal_status)}
                />&nbsp;
                {getActivityStatus(
                  reg.status,
                  reg.renewal_status,
                  reg.missing_documents.length,
                )}
              </div>
            </div>
          </RegistrationInfo>
          <ArrowIcon src={ArrowDown} alt="" />
        </RegistrationHeader>
        {
          reg.status === 4
          && (
            <>
              <RegistrationButtonContainer>
                <Link to="#" onClick={() => navigate(`/registration/${child.alias}/${reg.alias}`)}>
                  <Button
                    type="primary"
                    onClick={() => onContinueRegistration(reg)}
                  >
                    {t('child.continue-registration')}
                  </Button>
                </Link>
              </RegistrationButtonContainer>
              <Divider />
            </>
          )
        }
        {
          reg.renewal_status === 0
          && (
            <>
              <RegistrationButtonContainer>
                <Link to="#" onClick={() => navigate(`/registration/${child.alias}/${reg.alias}`)}>
                  <Button
                    type="primary"
                    onClick={() => onContinueRegistration(reg)}
                  >
                    {t('child.nav.renewal-cta', { childFirstname: child.first_name })}
                  </Button>
                </Link>
              </RegistrationButtonContainer>
              <Divider />
            </>
          )
        }
        <RegistrationDescription>
          <RegistrationBody>
            {
              reg.list_of_documents?.length > 0 && (
                <>
                  <P bold align="center">{t('child.doc-list-label')}</P>
                  {
                    reg.list_of_documents?.map(doc => (
                      <UserDocument
                        key={doc.alias}
                        doc={doc}
                        reg={reg}
                      />
                    ))
                  }
                </>
              )
            }
            {
              ([0, 2, 4].includes(reg.status) && reg.missing_documents.length > 0)
              && (
                <>
                  <P bold align="center">{t('child.missing-doc-label')}</P>
                  {
                    reg.missing_documents?.map(item => (
                      <>
                        <Tag key={item}>
                          {DocumentTypes.find(elm => elm.code === String(item))?.label}
                        </Tag>
                      </>
                    ))
                  }
                </>
              )
            }
          </RegistrationBody>
        </RegistrationDescription>
        <Divider />
        <RegistrationFooter>
          {
            JSON.parse(child.allergy)?.length > 0
            && (
              <div>
                <P bold align="center">
                  {t('child.activites.restrictions')}
                </P>
                { JSON.parse(child.allergy)?.map(allergy => (
                  <Tag key={allergy}>{allergy}</Tag>
                ))}
              </div>
            )
            }
          <P bold align="center">
            {t('child.activites.alone-projects')}
          </P>

          <P size="small">
            {child.has_pai ? 'Oui' : 'Non'}
          </P>
        </RegistrationFooter>
      </RegistrationCard>
    </RegistrationContainer>
  )
}

ExpendedChildRegistration.propTypes = {
  registered: PropTypes.shape().isRequired,
  onActiveChange: PropTypes.func.isRequired,
  onContinueRegistration: PropTypes.func.isRequired,
}

export default ExpendedChildRegistration
