import { css } from 'styled-components'

export const centeredCSS = css`
  text-align: center;
`

export const boldCSS = css`
  font-weight: bold;
`

export const inlineCSS = css`
  display: inline-block;
`

export const uppercaseCSS = css`
  text-transform: uppercase;
`

export const sizes = {
  tiny: css`
    font-size: 9px;
    line-height: 11px;
  `,
  smaller: css`
    font-size: 10px;
    line-height: 13px;
  `,
  small: css`
    font-size: 12px;
    line-height: 13px;
  `,
  small2: css`
    font-size: 12px;
    line-height: 15px;
  `,
  medium: css`
    font-size: 14px;
    line-height: 18px;
  `,
  regular: css`
    font-size: 16px;
    line-height: 18px;
  `,
  big: css`
    font-size: 16px;
    line-height: 21px;
  `,
}
