import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import P from "~/components/common/P";
import Card from "~/components/common/Card";
import ActivityIcon from "~/components/common/ActivityIcon";
import { colors } from "~/utils/colors";
import dayjs from "dayjs";

// const SubTitle = styled(P)`
//   font-size: 16px;
//   font-style: italic;
//   color: ${colors.cvGrey};
//   text-align: center;
// `;
const ActivityContainer = styled.div`
  border: 1px solid #e2e4e6;
  border-radius: 4px;
  ${(props) =>
    props.isSubscribale
      ? "border-top: 8px solid  #20CD8D;"
      : "border-top: 8px solid  #6D7F9D;"}
  ${(props) => props.isSubscribale && "cursor: pointer;"}
  height: 300px;
  width: 266px;
  padding: 10px;
  margin: 12px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #646464;
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const ActivityTitle = styled(P)`
  color: #0f2d78;
  margin-bottom: 0;
  text-align: center;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const PhotoContainer = styled.div`
  margin: 0 auto;
  width: 105px;
  padding-top: 45px;
`;

const Activity = ({ activity, onChoose, isSubscribale }) => (
  <ActivityContainer
    onClick={isSubscribale ? onChoose : undefined}
    isSubscribale={isSubscribale}
  >
    <PhotoContainer>
      <ActivityIcon activityType={activity?.activityType?.key} size={105} />
    </PhotoContainer>
    <ContentContainer>
      <ActivityTitle bold size="big">
        {activity?.name}
      </ActivityTitle>
      {/* {alreadyRegistered && (
        <SubTitle>Votre enfant est déjà inscrit à cette activité</SubTitle>
      )} */}
      <Text>
        Du {dayjs(activity.startDate).format("DD/MM/YYYY")} au{" "}
        {dayjs(activity.endDate).format("DD/MM/YYYY")}
      </Text>
      <Text>
        De {activity.startTime} à {activity.endTime}
      </Text>
    </ContentContainer>
  </ActivityContainer>
);

Activity.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onChoose: PropTypes.func.isRequired,
  isSubscribale: PropTypes.bool,
};

Activity.defaultProps = {
  isSubscribale: false,
};
export default Activity;
