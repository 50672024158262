import {
  all,
  takeLatest, takeEvery,
  put,
  select,
} from 'redux-saga/effects'

import axios from '~/lib/axios'

// redux
import { getAuthUserInfos } from '~/containers/User/selectors'
import { checkJwtExpiration } from '~/containers/User/sagas'
import { logout } from '~/containers/User/actions'
import {
  fetchSepa,
  fetchSepaProcess,
  fetchSepaError,
  fetchSepaSuccess,

  fetchOrgs,
  fetchOrgsProcess,
  fetchOrgsError,
  fetchOrgsSuccess,
} from './actions'

/**
 * fetchSepa
 */
export function* fetchSepaSaga() {
  
  try {
    yield* checkJwtExpiration()
    yield put(fetchSepaProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.get(`users/${authUserInfos.email}/sepa/`)
      
      yield put(fetchSepaSuccess(response.data))
    } else {
      yield put(fetchSepaError())
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    yield put(fetchSepaError())
  }
}

export function* fetchOrgsSaga() {
  
  try {
    yield* checkJwtExpiration()
    yield put(fetchOrgsProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.get(`users/${authUserInfos.email}/organisations/`)
      
      yield put(fetchOrgsSuccess(response.data))
    } else {
      yield put(fetchOrgsError())
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    yield put(fetchOrgsError())
  }
}
/*
export function* createContactSaga({payload: { contact, callback }}) {
  
  try {
    yield* checkJwtExpiration()
    yield put(fetchSepaProcess())

    const authUserInfos = yield select(getAuthUserInfos)
    if (!authUserInfos.email) {
      yield put(createContactError());
      yield put(logout());
      return;
    }

      const response = yield axios.post(`users/${authUserInfos.email}/contacts/`, {...contact})
      console.log('response=', response)

    if (typeof callback === "function") callback(response.data);
    yield put(createContactSuccess());
  } catch (err) {
    //~ if (typeof callback === "function") callback(null);
    console.log(err);
    yield put(createContactError());
  }
}

//~ Update, contact include alias ?
export function* updateContactSaga({payload: { alias, contact, callback }}) {
  
  try {
    yield* checkJwtExpiration()
    //~ yield put(updateContactProcess())

    const authUserInfos = yield select(getAuthUserInfos)
    if (!authUserInfos.email) {
      yield put(updateContactError());
      yield put(logout());
      return;
    }

      const response = yield axios.put(`users/${authUserInfos.email}/contacts/${alias}/`, {...contact})

    if (typeof callback === "function") callback(response.data);
    yield put(updateContactSuccess());
    yield put(fetchSepaProcess())
  } catch (err) {
    //~ if (typeof callback === "function") callback(null);
    console.log(err);
    yield put(updateContactError());
  }
}

export function* deleteContactSaga({payload: { alias, callback }}) {
  
  try {
    yield* checkJwtExpiration()
    yield put(deleteContactProcess())

    const authUserInfos = yield select(getAuthUserInfos)
    if (!authUserInfos.email) {
      yield put(deleteContactError());
      yield put(logout());
      return;
    }

      const response = yield axios.delete(`users/${authUserInfos.email}/contacts/${alias}/`)

    if (typeof callback === "function") callback(response.data);
    yield put(deleteContactSuccess());
  } catch (err) {
    //~ if (typeof callback === "function") callback(null);
    console.log(err);
    yield put(deleteContactError());
  }
}
*/
export default function* rootSaga() {
  yield all([
    takeEvery(fetchSepa, fetchSepaSaga),
    takeLatest(fetchOrgs, fetchOrgsSaga),
    //~ takeLatest(createContact, createContactSaga),
    //~ takeLatest(updateContact, updateContactSaga),
    //~ takeLatest(deleteContact, deleteContactSaga),
  ])
}
