import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 24px;
  width: 100%;

  .ant-space-item {
    margin-bottom: 0 !important;
  }
`

