export const orderStatusCommande = [
  {
    label: 'Brouillon',
    value: 0,
    color: '#1890FF',
  },
  {
    label: 'Editée',
    value: 1,
    color: '#1890FF',
  },
  {
    label: 'En cours de paiement',
    value: 2,
    color: '#F4C26D',
  },
  {
    label: 'Impayée',
    value: 3,
    color: '#F4C26D',
  },
  {
    label: 'Annulé',
    value: 4,
    color: '#F4C26D',
  },
  {
    label: 'Payée',
    value: 5,
    color: '#24DD98',
  },
  {
    label: 'Abondonné',
    value: 6,
    color: '#FF5876',
  },
  {
    label: 'À payer sur place',
    value: 7,
    color: '#1890FF',
  },
  {
    label: 'Expiré',
    value: 8,
    color: '#FF5876',
  },
]

export const getOrderStatus = (value) => orderStatusCommande.find((order) => order.value === value)

