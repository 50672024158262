export default [
  {
    code: '0',
    label: 'Pièce d\'identité',
    common: false,
  },
  {
    code: '1',
    label: 'Livret de famille',
    common: false,
  },
  {
    code: '2',
    label: 'Justificatif de domicile',
    common: true,
  },
  {
    code: '3',
    label: 'Carnet de santé',
    common: false,
  },
  {
    code: '4',
    label: 'Avis d\'impôts',
    common: true,
  },
  {
    code: '5',
    label: 'Attestation CAF',
    common: true,
  },
  {
    code: '6',
    label: 'Jugement de divorce',
    common: true,
  },
  {
    code: '7',
    label: 'Fiche sanitaire',
    common: false,
  },
  {
    code: '8',
    label: 'Attestation d\'assurance',
    common: false,
  },
]
