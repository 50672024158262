import React, { useState, useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import Container from "~/components/common/Container"
import {
  Input,
  Form,
  Row,
  Col,
  Button,
  Divider,
  message,
  Upload,
  Space,
} from "antd"
import CustomProfilAvatar from "~/components/common/CustomProfilAvatar"
import {
  FileImageOutlined,
  PaperClipOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons"
import styled from "styled-components"
import { fetchSepa, fetchOrgs } from "./actions"
import Table from '~/components/common/Table'

const { Item } = Form

const data = [
  {
    title: "Mandat de prélèvement SEPA",
    file: "mandat-sepa.pdf",
    size: "100ko",
  },
]

const Bank = () => {
  const dispatch = useDispatch()
  //~ const { contacts, isFetchingContacts } = useSelector((state) => state.contacts);
  const { sepa, isFetchingSepa } = useSelector((state) => state.sepa)
  const { orgs, isFetchingOrgs } = useSelector((state) => state.orgs)
  const [file, setFile] = useState(null)
  const [BIC, setBIC] = useState("")
  const [IBAN, setIBAN] = useState("")
  // let data = null;

  const onStatusChange = () => {
    
  }

  useEffect(() => {
    
    dispatch(fetchSepa())
    dispatch(fetchOrgs())
  }, [])

  const getformatedOrgs = useCallback(() => {
    let data = orgs
    
	  //~ alert(orgs);

    return data
  }, [orgs]) //sepa

  const getformatedSepa = useCallback(() => {
    //~ let data = sepa;
    
    return sepa.results
  }, [sepa]) //sepa

  const columnsOrgs = [
    {
      //~ title: "",
      key: "alias",
      render: (_, record) => (
      <Button type="link" onClick={() => {}}
        disabled={!BIC.length || !IBAN.length}
        size="large"
        type="primary"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        Editer un mandat SEPA pour {record.name}, {record.city}
      </Button>
      ),
    },
  ]

  const columnsSepa = [
    {
      //~ title: "",
      key: "alias",
      render: (_, record) => (
	<div>
        <Row>
          <Col span={6}>
            <Item label="IBAN">
              <Input
                onChange={(v) => setIBAN(v.target.value)}
                value={record.iban}
              ></Input>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
	    <Item label="BIC / SWIFT">
              <Input
                onChange={(v) => setBIC(v.target.value)}
                value={record.bic}
              ></Input>
            </Item>
          </Col>
        </Row>
	</div>
      ),
    },
  ]

  const uploadProps = {
    fileList: [],
    beforeUpload: (file) => {
      if (file.type !== "image/jpeg") {
        message.error(`${file.name} n'est pas un fichier jpg`)
      }
      return file.type === "image/jpeg"
    },
    customRequest: async (e) => {
      setFile(e.file)
      if (e.file) {
        const dataImg = new FormData()
        dataImg.append("photo", e.file)
        const [error, response] = false
        // await uploadPhoto(dataImg, {
        //   path: 'children.photoUpload',
        //   params: {
        //     alias: data ?.child ?.alias,
        //   }
        // })

        if (error) {
          message.error(
            "Désolé. Un problème est survenu. Veuillez réessayer plus tard."
          )
        }
        if (response) {
          // registrationMutation(data)
          // update child data photo;
          message.success(
            `L'image du ${data?.child?.first_name}
            ${data?.child?.last_name} a été bien mise à jour`
          )
        }
      }
    },
  }

  return (
    <Container>
      <div>
        Renseignez vos coordonnées bancaires si votre organisation vous propose
        d’activer le prélèvement automatique.
      </div>
      <Form layout="vertical" size="large">
       <Table columns={columnsSepa} dataSource={getformatedSepa()}/>
      </Form>
      <div>
          <Table columns={columnsOrgs} dataSource={getformatedOrgs()}/>
      </div>
      <Divider></Divider>
      <div style={{ color: "red", paddingBottom: 16 }}>
        Attention, vous devez imprimer, puis signer le mandat avant de le
        retourner à votre organisation.
      </div>
      <div>
        Téléverser un mandat SEPA signé pour Mairie de Champignac (.pdf maximum
        5mb)
      </div>
      <Upload name="photo" {...uploadProps}>
        <Space style={{ paddingTop: 5 }}>
          {/* <CustomProfilAvatar file={file} /> */}

          <Button size="large" icon={<FileImageOutlined />}>
            Choisir un fichier
          </Button>
        </Space>
      </Upload>
      <div style={{ marginTop: 8, marginBottom: 8 }}>
        Mandat de prélèvement signé pour Mairie de Champignac
      </div>
      {data.map((d) => (
        <Doc data={d}></Doc>
      ))}
    </Container>
  )
}

export default Bank

export const Doc = ({ data }) => {
  return (
    <DocContainer>
      <DocImg></DocImg>
      <TextContainer>
        <DocTitle>{data.title}</DocTitle>
        <LightText>
          <PaperClipOutlined /> {data.file} ({data.size})
        </LightText>
        <Underline>Supprimer</Underline>
      </TextContainer>
    </DocContainer>
  )
}

const DocImg = styled.div`
  width: 112px;
  height: 112px;
  border: 1px solid lightgrey;
  border-radius: 2px;
`

const TextContainer = styled.div`
  padding-left: 16px;
  // padding-top: 14px;
  // vertical-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-item: center;
`

const DocTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const LightText = styled.div`
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 5px;
`

const DocContainer = styled.div`
  display: flex;
  align-item: center;
  // flex-direction:row;
  height: 112px;
`

const BoldText = styled.div`
  font-weight: bold;
`

const Underline = styled.div`
  text-decoration: underline;
  color: @neutral-4;
`
