
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import take from 'lodash/take'
import compact from 'lodash/compact'
import {
  DashboardOutlined,
  IdcardOutlined,
  CalendarOutlined,
  EuroOutlined,
  ShoppingCartOutlined,
  CommentOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'
import { Alert, Menu } from 'antd'
import config from '~/config'
import {
  Container, StyledMenu, CloseTrigger, OpenTrigger,
} from './components'

const { SubMenu, Item } = Menu

const StyledItem = styled(Item)`
  background-color: @body-background;
  color: white;
`

const StyledLink = styled(Link)`
  color: white;
  background-color: @body-background;
`

const Navigation = () => {
  const [collapsed, setCollapsed] = useState(false)
  const [selectedKeys, setSelectedKeys] = useState([])
  const [openKeys, setOpenKeys] = useState([])

  const location = useLocation()

  useEffect(() => {
    // split the current URL to select & open the right
    // menu items
    const splittedUrl = compact(location.pathname.split('/')).map(
      (path) => `/${path}`,
    )
    const keys = [];

    [...Array(splittedUrl.length)].forEach((_, index) => {
      keys.push(take(splittedUrl, index + 1).join(''))
    })
    setSelectedKeys(keys)
    setOpenKeys(keys)
  }, [location])

  const isSelected = (path) => {
    if (Array.isArray(path)) {
      if (
        path.some((pt) => location.pathname.includes(pt))
      ) {
        return '#20CD8D'
      }
    }

    if (location.pathname === path) {
      return '#20CD8D'
    }

    return '#fff'
  }

  let v_padding_top = 0
  { config.app.env && config.app.env !== 'prod' && ( v_padding_top = 40 ) };
  { config.app.env && config.app.env === 'prod' && ( v_padding_top = 30 ) };
  return (
    <Container
      collapsible
      defaultCollapsed={collapsed}
      onCollapse={setCollapsed}
      style={{ maxWidth: 190 }}
      width={200}
      trigger={collapsed ? <OpenTrigger /> : <CloseTrigger />}
    >
      <StyledMenu
        mode="inline"
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
        selectedKeys={selectedKeys}
        onSelect={(props) => setSelectedKeys(props.selectedKeys)}
        style={{ height: '100%', paddingTop:v_padding_top }}
      >
        {/* {config.app.env && config.app.env !== 'prod' && (
          <Alert
            type="warning"
            message={(
              <>
                Le PFW pointe vers{' '}
                <strong>{window.localStorage.getItem('api')}</strong>. Pour
                changer le serveur, Veuillez se déconnecter.
              </>
            )}
          />
        )} */}
        <StyledItem key="/dashboard" icon={<DashboardOutlined style={{ color: isSelected('/') }} />}>
          <StyledLink to="/" style={{ color: isSelected('/') }}>
            Actualités
          </StyledLink>
        </StyledItem>
        {/* <SubMenu
          style={{ color: "white" }}
          key="/family"
          title="Messagerie"
          icon={<CommentOutlined />}
        >
          <StyledItem key="/family/children">
            <StyledLink to="/family/children">Enfants</StyledLink>
          </StyledItem>
          <StyledItem key="/family/parents">
            <StyledLink to="/family/parents">Parents</StyledLink>
          </StyledItem>
          <StyledItem key="/family/home">
            <StyledLink to="/family/parents">Foyers</StyledLink>
          </StyledItem>
        </SubMenu> */}
        <StyledItem key="/foyer" icon={<IdcardOutlined style={{ color: isSelected(['/foyer', '/children']) }} />}>
          <StyledLink to="/foyer" style={{ color: isSelected(['/foyer', '/children']) }}>
            Foyer
          </StyledLink>
        </StyledItem>
        <StyledItem key="/allreservation" icon={<CalendarOutlined style={{ color: isSelected('/allreservation') }} />}>
          <StyledLink to="/allreservation" style={{ color: isSelected('/allreservation') }}>
            Réservations
          </StyledLink>
        </StyledItem>
        <StyledItem key="/commandes" icon={<ShoppingCartOutlined style={{ color: isSelected('/commandes') }} />}>
          <StyledLink to="/commandes" style={{ color: isSelected('/commandes') }}>
            Mes commandes
          </StyledLink>
        </StyledItem>
        <StyledItem key="/factures" icon={<EuroOutlined style={{ color: isSelected('/factures') }} />}>
          <StyledLink to="/factures" style={{ color: isSelected('/factures') }}>
            Mes factures
          </StyledLink>
        </StyledItem>
      </StyledMenu>
    </Container>
  )
}

export default Navigation
