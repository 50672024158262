import styled from 'styled-components'
import {
  centeredCSS,
  boldCSS,
  inlineCSS,
  uppercaseCSS,
} from '~/components/common/Typo'

const H1 = styled.h1`
  font-size: 22px;
  font-weight: bold;
  color: @black-blue-color;
  padding-bottom: 8px;
  // margin-bottom: 30px;
  // border-bottom: 1px solid rgb(226, 226, 226);

  ${props => props.centered && centeredCSS};
  ${props => props.bold && boldCSS};
  ${props => props.inline && inlineCSS};
  ${props => props.uppercase && uppercaseCSS};
`

export default H1
