import React from "react";
import { colors } from "~/utils/colors";
import dayjs from "dayjs";
import styled, { css } from "styled-components";
import { Tooltip } from "antd";
import { getactivitiesContainerLabel } from "~/constants";
import P from "~/components/common/P";
import { Modal, message, Button } from "antd"
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import config from "~/config";
import isBoolean from "lodash/isBoolean";

dayjs.extend(isSameOrAfter)

const Case = styled.div`
  border-right: 1px solid #969696;
  background: ${(props) => props.color};
  min-height: 30px;
  min-width: 30px;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  :last-child {
    border: none;
  };
  
`;

const Draw = styled.hr`
  background: ${(props) => props.color};
  height: 3px;
  width:100%;
  margin: 0;
  padding: 0;
  border: none;
`
const Dotted = styled.hr`
background: ${(props) => props.color};
height : 1px;
width:100%;
margin: 0;
padding: 0;
border: none;
`

/** &:hover,
  &:focus{
    border-color: ${(props) => props.isHoverable ? colors.cvOrange : 'none'};
    border:${(props) => props.isHoverable ? '1px solid #F4C26D' : 'none'};
  }
   ${(props) => props.isHoverable &&
  css`
    border: 0.1px #F4C26D;
  `}
  */

const cellWrapper = (
  a,
  sub,
  setSelectedCell,
  setIsHover,
  isHovered,
  hoveredCellContainerLabel,
  cart,
  child,
  billingModel,
  activityDetails,
  pg,
  currentMonthDates,
  organisationAllowCreditCreation,
  activityAllowCreditCreation,
) => {
  // CNN_cva1213
  // Tableau utilisé pour insérer les dates de gardes d'un enfant ainsi que son child_alias
  let jdg = []; //jours de gardes

  let potential_garde_weekend = [] //potentiel non garde le weekend

  let potential_nogarde_weekend = [] // potentiel garde le weekend
  
  // verification si le parent a un planning garde 
  if(pg && pg[child.alias] && pg[child.alias][0]["garde_type"]){
    pg[child.alias][0]['dates'].map((d)=>
    {
      jdg.push(d);
    })
  }
  if(pg && pg[child.alias] && pg[child.alias][0]["garde_type"] == 'personnalise'){
    
    currentMonthDates.map((z)=>{
      
      if (z.day() == 5) {
        if(!jdg.includes(z.format("YYYY-MM-DD")) && !jdg.includes((z.clone().add(3, 'days')).format("YYYY-MM-DD"))){
          
          potential_nogarde_weekend.push((z.clone().add(1, 'days')).format("YYYY-MM-DD"))
          potential_nogarde_weekend.push((z.clone().add(2, 'days')).format("YYYY-MM-DD"))
        }else{
          potential_garde_weekend.push((z.clone().add(1, 'days')).format("YYYY-MM-DD"))
          potential_garde_weekend.push((z.clone().add(2, 'days')).format("YYYY-MM-DD"))
        }
      }})
      
    }

  const isPrepaid = billingModel === 2;
  const isFinished = activityDetails?.status === 2;
  // console.log("sub.attendance", sub.attendance , a );
  let color;
  let line_color = "#6D7F9D"
 
  let currDay = null;
  if (sub.attendance) {
    if (sub.childs) {
      currDay = sub.childs?.[child?.alias]?.find((element) =>
        dayjs(element.date).isSame(a, "day")
      );
    } else {
      currDay = sub.attendance.find(
        (element) => dayjs(element.date).isSame(a, "day") && element
      );
    }
  }

  const onCartAttendance = sub.attendance.find(
    (element) =>
      dayjs(element.date).isSame(a, "day") &&
      element?.order &&
      element?.status === 0 &&
      element?.child_alias === child?.alias
  );

  let currOpen = null;

  if (sub.openingDays) {
    currOpen = sub.openingDays.find((element) =>
      dayjs(element.date).isSame(a, "day")
    );
  }

  // console.log("currDay" ,currDay , "currOpen" , currOpen , "onCartAttendance" , onCartAttendance , "isPrepaid" , isPrepaid , "isFinished" , isFinished)
  if (onCartAttendance) {
    return (
      <Case
        onClick={() => {
          if (!disableUX && pg && pg[child.alias] && pg[child.alias][0]["garde_type"] && !jdg.includes(a.format("YYYY-MM-DD")) && !child.multi_hh_not_my_day_attendance_auth) {
            message.info(`vous n'avez pas la garde de ${child.first_name} ce jour `)
            return
        }
          setSelectedCell({
            attendance: onCartAttendance,
            day: a,
            openDay: sub.openingDays,
            building_alias: sub.openingDays[0]?.building_alias,
            sub,
            order: onCartAttendance.order,
            child,
          });
        }}
        color={onCartAttendance?.is_due ? colors.cvPurple : colors.cvRed}
      ></Case>
    );
  }

  const attendanceIsWaitingForPaymentResult = sub.attendance.find(
    (element) =>
      dayjs(element.date).isSame(a, "day") &&
      element?.order &&
      element?.status === 1 &&
      element?.child_alias === child?.alias
  );

  if (attendanceIsWaitingForPaymentResult) {
    return (
      <Case
        onClick={() => {
          setSelectedCell({
            attendance: attendanceIsWaitingForPaymentResult,
            day: a,
            openDay: sub.openingDays,
            building_alias: sub.openingDays[0]?.building_alias,
            sub,
            order: attendanceIsWaitingForPaymentResult.order,
            child,
          });
        }}
        color={colors.cvLightPurple}
      ></Case>
    );
  }

  const isOnCredit = sub.attendance.find(
    (element) =>
      dayjs(element.date).isSame(a, "day") &&
      element?.child_alias === child?.alias &&
      element?.is_on_credit
  );

  if (isOnCredit) {
    return <Case color={colors.cvLime} />;
  }
  
  const canRequestCredit = sub.attendance.find(
    (element) =>
      dayjs(element.date).isSame(a, "day") &&
      element?.child_alias === child?.alias &&
      element?.can_request_credit
  );

  const creditAlreadyRequested = sub.attendance.find(
    (element) =>
      dayjs(element.date).isSame(a, "day") &&
      element?.child_alias === child?.alias &&
      element?.is_credit_requested
  );

  const disableUX = config.app.disableUX;

  if (
    isPrepaid
    && (canRequestCredit || creditAlreadyRequested)
    && (
      (!!activityAllowCreditCreation || (!isBoolean(activityAllowCreditCreation) && !!organisationAllowCreditCreation))
      || disableUX
    )
  ) {
    if (creditAlreadyRequested) {
      return (
        <Tooltip
          title={(
            <p style={{margin: 0, padding: 0}}>
              <span>
                ⚠️ vous avez fait une demande d'avoir il y a quelques minutes ... tant que l'avoir n'est pas émis vous pouvez néanmoins annuler votre demande.
              </span>
            </p>
          )}
          placement="top"
          color={colors.cvBluePill}
        >
          <Case
            color="#1890ff40"
            onClick={() => {
              setSelectedCell({
                attendance: creditAlreadyRequested,
                day: a,
                openDay: sub.openingDays,
                building_alias: sub.openingDays[0]?.building_alias,
                sub,
                order: creditAlreadyRequested.order,
                child,
              });
            }}
          />
        </Tooltip>
      );
    }

    return (
      <Tooltip
        title="Cette réservation payée peut-être annulée et donnera lieu à l'émission d'un avoir 30 minutes après votre dernière demande"
        placement="top"
        color={colors.cvBluePill}
      >
        <Case
          onClick={() => {
            setSelectedCell({
              attendance: canRequestCredit,
              day: a,
              openDay: sub.openingDays,
              building_alias: sub.openingDays[0]?.building_alias,
              sub,
              order: canRequestCredit.order,
              child,
            });
          }}
          color={colors.cvBluePill}
        />
      </Tooltip>
    );
  }

  const isUncancelableAttendance =
    ((dayjs(currDay?.date)).set({hour:activityDetails?.start_time?.split(":")[0],minute:activityDetails?.start_time?.split(":")[1],second:0,millisecond:0})) > dayjs() &&
    sub.attendance.find(
      (element) =>
        dayjs(element.date).isSame(a, "day") &&
        element?.order &&
        element?.status === 5 &&
        element?.child_alias === child?.alias
    );

  if (isUncancelableAttendance) {
    return (
      <Case
        onClick={() => {
          setSelectedCell({
            attendance: {...isUncancelableAttendance, can_request_credit: undefined},
            day: a,
            openDay: sub.openingDays,
            building_alias: sub.openingDays[0]?.building_alias,
            sub,
            order: isUncancelableAttendance.order,
            child,
            requestCredit: isUncancelableAttendance?.order_details?.status === 5 && isPrepaid,
          });
        }}
        color={colors.cvBlueGrey}
      ></Case>
    );
  }

  if (currDay) {
    /* console.log("currDay",currDay);
    console.log("currDay?.start_time",activityDetails?.start_time);
    console.log("currDay?.date",currDay?.date);
    console.log(((dayjs(currDay.date)).set({hour:activityDetails?.start_time?.split(":")[0],minute:activityDetails?.start_time?.split(":")[1],second:0,millisecond:0})));
    console.log("dayjs()",dayjs());
    console.log("currDay prev", dayjs(currDay.date).set({hour:dayjs( 
      activityDetails?.prevenance_date
    ).hour(),minute:dayjs(
      activityDetails?.prevenance_date
    ).minutes()})); */
    if (!currDay?.date) {
        if (!(
          dayjs(activityDetails?.update_after).isSame(a, "day") 
          && dayjs(activityDetails?.prevenance_date).isAfter(dayjs())
        )) {
            color = "#fff"
        } else {
            color = colors.cvBlueGreyLighter ;        
        }
        /* 20230407 ChD Ancienne version du code le jour même ... # ? bizarre 
        if (
          (
            (
              (dayjs(a)).set(
                {hour:activityDetails?.start_time?.split(":")[0],minute:activityDetails?.start_time?.split(":")[1],second:0,millisecond:0}
                )
            ) <= dayjs()
          ) || (
                dayjs(activityDetails?.update_after).isAfter(a) 
                || 
                  (
                    dayjs(activityDetails?.update_after).isSame(a, "day") 
                    && dayjs(activityDetails?.prevenance_date).isAfter(dayjs())
                  )
              )
        ) {
            color = "#fff";
        }*/
    } else {
      if (currDay.date && ((dayjs(currDay.date)).set({hour:activityDetails?.start_time?.split(":")[0],minute:activityDetails?.start_time?.split(":")[1],second:0,millisecond:0})) <= dayjs()) {
        if (currDay.was_there) {
          // present on a past date
          color = colors.cvGreen;
        } else if (currDay.is_listed && !currDay.was_there) {
          // registered but not present on a past date
          color = colors.cvOrange;
        }else if (dayjs().isAfter(dayjs(currDay.date).set({hour:dayjs(
          activityDetails?.prevenance_date
        ).hour(),minute:dayjs(
          activityDetails?.prevenance_date
        ).minute()})) ){
          color = "#fff";
        }else if (!currDay.is_listed && !currDay.was_there) {
          // not registered on a past date
          color = colors.cvBlueGreyLighter;
        }
      } else {
        // If activity is finished
        if (isFinished) {
          color = "#fff";
        } else {
          // If it is a present or future day
          if (currDay.is_listed && currDay.is_editable) {
            // if registered
            color = colors.cvBlue;
          } else if (currDay.is_listed && !currDay.is_editable) {
            color = colors.cvBlueGrey;
          } else if (dayjs() >  dayjs(currDay.date).set({hour:dayjs(
            activityDetails?.prevenance_date
          ).hour(),minute:dayjs(
            activityDetails?.prevenance_date
          ).minute()}) ){
            color = "#fff";
          }
          else {
            color = colors.cvBlueGreyLighter; // if not registered
          }
        }
      }
    }

    if (currDay.is_listed && currDay.is_editable) {
      return (
        <Tooltip
          title="Cette réservation peut-être annulée"
          placement="top"
          color={colors.cvBlue}
        >
          <Case
            onClick={() => {
              // @CNN CVA-1445
              if (!disableUX && pg && pg[child.alias] && pg[child.alias][0]["garde_type"] && !jdg.includes(a.format("YYYY-MM-DD")) && !child.multi_hh_not_my_day_attendance_auth) {
                  message.info(`vous n'avez pas la garde de ${child.first_name} ce jour `)
                  return
              }
              /*console.log("here",{
                attendance: currDay,
                day: a,
                openDay: sub.openingDays,
                building_alias: sub.openingDays[0]?.building_alias,
                sub,
                child,
              });*/
              console.log({isUncancelableAttendance, vv: dayjs(currDay?.date) > dayjs(), sub})
              setSelectedCell({
                attendance: currDay,
                day: a,
                openDay: sub.openingDays,
                building_alias: sub.openingDays[0]?.building_alias,
                sub,
                child,
              });
            }}
            color={color}
          >
            {pg && pg[child.alias] && pg[child.alias][0]["garde_type"] && pg[child.alias][0]["garde_type"] !== 'personnalise' && !jdg.includes(a.format("YYYY-MM-DD")) &&
            <Draw color={line_color}></Draw>}
            {pg && pg[child.alias] && pg[child.alias][0]["garde_type"] == 'personnalise'  && potential_nogarde_weekend.includes(a.format("YYYY-MM-DD")) &&
            <Dotted color={line_color}></Dotted>}
            {pg && pg[child.alias] && pg[child.alias][0]["garde_type"] == 'personnalise'  && !jdg.includes(a.format("YYYY-MM-DD")) && !potential_nogarde_weekend.includes(a.format("YYYY-MM-DD")) && !potential_garde_weekend.includes(a.format("YYYY-MM-DD")) &&
            <Draw color={line_color}></Draw>}
          </Case>
        </Tooltip>
      )
    }

    return (
      <Case
        onClick={() => {
          // @CNN CVA-1445
          if (!disableUX && pg && pg[child.alias] && pg[child.alias][0]["garde_type"] && !jdg.includes(a.format("YYYY-MM-DD")) && !child.multi_hh_not_my_day_attendance_auth) {
              message.info(`vous n'avez pas la garde de ${child.first_name} ce jour `)
              return
          }
          /*console.log("here",{
            attendance: currDay,
            day: a,
            openDay: sub.openingDays,
            building_alias: sub.openingDays[0]?.building_alias,
            sub,
            child,
          });*/
          console.log({isUncancelableAttendance, vv: dayjs(currDay?.date) > dayjs(), sub})
          setSelectedCell({
            attendance: currDay,
            day: a,
            openDay: sub.openingDays,
            building_alias: sub.openingDays[0]?.building_alias,
            sub,
            child,
          });
        }}
        color={color}
      >{pg && pg[child.alias] && pg[child.alias][0]["garde_type"] && pg[child.alias][0]["garde_type"] !== 'personnalise' && !jdg.includes(a.format("YYYY-MM-DD")) &&
      <Draw color={line_color}></Draw>}
      {pg && pg[child.alias] && pg[child.alias][0]["garde_type"] == 'personnalise'  && potential_nogarde_weekend.includes(a.format("YYYY-MM-DD")) &&
      <Dotted color={line_color}></Dotted>}
      {pg && pg[child.alias] && pg[child.alias][0]["garde_type"] == 'personnalise'  && !jdg.includes(a.format("YYYY-MM-DD")) && !potential_nogarde_weekend.includes(a.format("YYYY-MM-DD")) && !potential_garde_weekend.includes(a.format("YYYY-MM-DD")) &&
      <Draw color={line_color}></Draw>}
      
    </Case>
    );
  }
  // Open and date in Future

  /*console.log(activityDetails?.name,currOpen?.date,currOpen,activityDetails?.update_after, activityDetails?.opening_days?.includes(currOpen?.date),  dayjs(currOpen?.date).isSameOrAfter(
    dayjs(activityDetails?.update_after)
  ) ) */
  if (
    currOpen &&
    // dayjs(activityDetails?.prevenance_date).isSameOrAfter(dayjs()) &&
    activityDetails?.opening_days?.includes(currOpen.date) &&
    dayjs(currOpen.date).isSameOrAfter(
      dayjs(activityDetails?.update_after)
    ) &&
    !isFinished
  ) {
    if(activityDetails?.period_selection>1){
      return (
        <Tooltip
          title={
            <>
              {
               <p><span style={{fontWeight: 'bold'}}>{getactivitiesContainerLabel(activityDetails?.period_selection)} {hoveredCellContainerLabel} </span></p>
              }
            </>                       
  
            }
            placement="top"
            color="orange"
        >
        <Case
          onClick={() => {
            // @CNN CVA-1445
            if (!disableUX && pg && pg[child.alias] && pg[child.alias][0]["garde_type"] && !jdg.includes(a.format("YYYY-MM-DD")) && !child.multi_hh_not_my_day_attendance_auth) {
                message.info(`vous n'avez pas la garde de ${child.first_name} ce jour `)
                return
            }

            setSelectedCell({
              attendance: currDay,
              day: a,
              openDay: sub.openingDays,
              building_alias: sub.openingDays[0]?.building_alias,
              sub,
              child,
            });
          }}
          isHoverable={isHovered}
          onMouseEnter={() => {
            setIsHover({
              attendance: currDay,
              day: a,
              openDay: sub.openingDays,
              sub,
              child,
            });
          }}
          onMouseLeave={() => setIsHover(null)}
          color={colors.cvBlueGreyLighter}
        >
        {pg && pg[child.alias] && pg[child.alias][0]["garde_type"] && pg[child.alias][0]["garde_type"] !== 'personnalise' && !jdg.includes(a.format("YYYY-MM-DD")) &&
        <Draw color={line_color}></Draw>}
        {pg && pg[child.alias] && pg[child.alias][0]["garde_type"] == 'personnalise'  && !jdg.includes(a.format("YYYY-MM-DD")) &&
        <Draw color={line_color}></Draw>}
        {pg && pg[child.alias] && pg[child.alias][0]["garde_type"] == 'personnalise'  && !jdg.includes(a.format("YYYY-MM-DD")) && !potential_nogarde_weekend.includes(a.format("YYYY-MM-DD")) && !potential_garde_weekend.includes(a.format("YYYY-MM-DD")) &&
        <Draw color={line_color}></Draw>}
        </Case>
        </Tooltip>
      );
    } else {
      return(
        <Case

          onClick={() => {
            // @CNN CVA-1445
            if (!disableUX && pg && pg[child.alias] && pg[child.alias][0]["garde_type"] && !jdg.includes(a.format("YYYY-MM-DD")) && !child.multi_hh_not_my_day_attendance_auth) {
                  message.info(`vous n'avez pas la garde de ${child.first_name} ce jour `)
                return
            }
            setSelectedCell({
              attendance: currDay,
              day: a,
              openDay: sub.openingDays,
              building_alias: sub.openingDays[0]?.building_alias,
              sub,
              child,
            });
          }}
          // isHoverable={isHovered}
          // onMouseEnter={() => {
          //   setIsHover({
          //     attendance: currDay,
          //     day: a,
          //     openDay: sub.openingDays,
          //     sub,
          //     child,
          //   });
          // }}
          // onMouseLeave={() => setIsHover(null)}
          color={colors.cvBlueGreyLighter}
        >
      {pg && pg[child.alias] && pg[child.alias][0]["garde_type"] && pg[child.alias][0]["garde_type"] !== 'personnalise' && !jdg.includes(a.format("YYYY-MM-DD")) &&
      <Draw color={line_color}></Draw>}
      {pg && pg[child.alias] && pg[child.alias][0]["garde_type"] == 'personnalise'  && !jdg.includes(a.format("YYYY-MM-DD")) &&
      <Draw color={line_color}></Draw>}
      {pg && pg[child.alias] && pg[child.alias][0]["garde_type"] == 'personnalise'  && !jdg.includes(a.format("YYYY-MM-DD")) && !potential_nogarde_weekend.includes(a.format("YYYY-MM-DD")) && !potential_garde_weekend.includes(a.format("YYYY-MM-DD")) &&
      <Draw color={line_color}></Draw>}
        </Case>
      );      
    }
    
  }
  return (
    <Case
      onClick={() => {
        // @CNN CVA-1445
        if (!disableUX && pg && pg[child.alias] && pg[child.alias][0]["garde_type"] && !jdg.includes(a.format("YYYY-MM-DD")) && !child.multi_hh_not_my_day_attendance_auth) {
            message.info(`vous n'avez pas la garde de ${child.first_name} ce jour `)
            return
        }
        /* console.log("ok", {
          currOpen,
          vd: dayjs(currOpen.date).isSameOrAfter(dayjs()),
          ii: activityDetails?.opening_days?.includes(currOpen.date),
          ua: dayjs(currOpen.date).isSameOrAfter(
            dayjs(activityDetails?.update_after)
          ),
          isFinished,
        }); */

        setSelectedCell({
          attendance: currDay,
          day: a,
          openDay: sub.openingDays,
          building_alias: sub.openingDays[0]?.building_alias,
          sub,
          child,
        });
      }}
    >
    {pg && pg[child.alias] && pg[child.alias][0]["garde_type"] && pg[child.alias][0]["garde_type"] !== 'personnalise'  && !jdg.includes(a.format("YYYY-MM-DD")) &&
    <Draw color={line_color}></Draw>}
    {pg && pg[child.alias] && pg[child.alias][0]["garde_type"] == 'personnalise'  && potential_nogarde_weekend.includes(a.format("YYYY-MM-DD")) &&
      <Dotted color={line_color}></Dotted>}
    {pg && pg[child.alias] && pg[child.alias][0]["garde_type"] == 'personnalise'  && !jdg.includes(a.format("YYYY-MM-DD")) && !potential_nogarde_weekend.includes(a.format("YYYY-MM-DD")) && !potential_garde_weekend.includes(a.format("YYYY-MM-DD")) &&
      <Draw color={line_color}></Draw>}
    </Case>
  );
};

export default cellWrapper;
