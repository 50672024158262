import React, { useState } from "react";
import H1 from "~/components/common/H1";
import ContentHeader from "~/components/common/ContentHeader";
import Container, { MainContainer } from "~/components/common/Container";

import ActivityCalendar from "./ActivityCalendar";
import { Button, Space, Tooltip } from "antd";
import { CalendarOutlined, EyeInvisibleOutlined, EyeOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import H2 from "~/components/common/H2";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import ChooseChild from "~/containers/AllReservation/chooseChild";

const AllReservation = () => {
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [showActivityDetails, setShowActivityDetails] = useState(false);
  const [showAllActivitiesCalendar, setShowAllActivitiesCalendar] = useState(false);
  const [hideFinishedActivities, setHideFinishedActivities] = useState(false);

  const navigate = useNavigate();

  const prevMonth = () => {
    dayjs(selectedMonth).subtract(1, "months");
    setSelectedMonth(dayjs(selectedMonth).subtract(1, "months"));
  };

  const nextMonth = () => {
    setSelectedMonth(dayjs(selectedMonth).add(1, "months"));
  };

  return (
    <>
      <ContentHeader
        variant="sticky"
        breadcrumbItems={[
          {
            name: "Réservations",
            path: "/allreservation/",
          },
        ]}
        buttons={() => (
          <>
            <Space direction="horizontal" style={{ justifyContent: "space-between" }}>
              <Space direction="horizontal">
                <LeftOutlined onClick={() => prevMonth()} style={{ fontSize: 20 }} />
                <H2
                  style={{
                    textTransform: "capitalize",
                    fontSize: 20,
                    padding: 10,
                    marginBottom: 0,
                    fontWeight: 400,
                    width: 'max-content',
                  }}
                >
                  {dayjs(selectedMonth).format("MMMM YYYY")}
                </H2>
                <RightOutlined onClick={() => nextMonth()} style={{ fontSize: 20 }} />
              </Space>
              <Space
                direction="horizontal"
                style={{ justifyContent: "space-between", width: 390 }}
              >
                <Tooltip title={`${hideFinishedActivities ? 'Afficher' : 'Masquer'} les activités terminées`}>
                  {!hideFinishedActivities && (
                    <EyeInvisibleOutlined
                      style={{ fontSize: 25, cursor: 'pointer' }}
                      onClick={() => setHideFinishedActivities(state => !state)}
                    />
                  )}
                  {hideFinishedActivities && (
                    <EyeOutlined
                      style={{ fontSize: 25, cursor: 'pointer' }}
                      onClick={() => setHideFinishedActivities(state => !state)}              
                    />
                  )}
                </Tooltip>
                <Button size="large" onClick={() => setSelectedMonth(dayjs())}>
                  Aujourd’hui
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  onClick={() => navigate("/reservation")}
                >
                  <CalendarOutlined />
                  Inscrire à une activité
                </Button>
              </Space>
            </Space>
          </>
        )}
      >
        <H1
          style={{
            paddingBottom: 0,
            marginBottom: 0,
          }}
        >
          Réservations
        </H1>
        {showAllActivitiesCalendar && (
          <Button
            type="link"
            onClick={() => setShowAllActivitiesCalendar(false)}
            style={{
              padding: 0,
            }}
          >
            Retour
          </Button>
        )}
      </ContentHeader>
      <MainContainer>
        {showAllActivitiesCalendar ? (
          <ActivityCalendar
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            showActivityDetails={showActivityDetails}
            setShowActivityDetails={setShowActivityDetails}
            hideFinishedActivities={hideFinishedActivities}
          />
        ) : (
          <ChooseChild
            setShowAllActivitiesCalendar={setShowAllActivitiesCalendar}
          />
        )}
      </MainContainer>
    </>
  );
};

export default AllReservation;
