import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Small from './Small'
import Big from './Big'
import Loading from '~/components/common/Loading'

const ChildRegistration = ({
  registration,
  activeId,
  onActiveChange,
  onContinueRegistration,
}) => {
  const child = useSelector(state => state.children.childDetails)
  const registered = child?.registrations?.find(s => s.alias === registration.alias)
  const isActive = activeId === registered?.alias
  if (!registered) return <Loading text={false} />

  return (
    <div>
      {
        !isActive
          ? (
            <Small
              registered={registered}
              onActiveChange={onActiveChange}
            />
          )
          : (
            <Big
              registered={registered}
              onActiveChange={onActiveChange}
              onContinueRegistration={onContinueRegistration}
            />
          )
      }
    </div>
  )
}

ChildRegistration.propTypes = {
  registration: PropTypes.shape().isRequired,
  activeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onActiveChange: PropTypes.func.isRequired,
  onContinueRegistration: PropTypes.func.isRequired,
}

ChildRegistration.defaultProps = {
  activeId: null,
}
export default ChildRegistration
