import { ExclamationCircleOutlined, FrownOutlined } from "@ant-design/icons"
import { Avatar, Collapse, notification, Popover, Result, Tag } from "antd"
// import "antd/dist/antd.less"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import Countdown from "react-countdown"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import styled from "styled-components"
import SchoolSVG from '~/assets/images/school.svg'
import Container, { MainContainer, MainContent } from "~/components/common/Container"
import ContentHeader from "~/components/common/ContentHeader"
import DefaultButton from "~/components/common/DefaultButton"
import H1 from "~/components/common/H1"
import Loading from '~/components/common/Loading'
import PrimaryButton from "~/components/common/PrimaryButton"
import { deleteOrder, fetchOrders, payOrder, validateOrder } from "~/containers/Cart/actions"
import EmptyCartPopUp from "~/containers/Cart/popups/emptyCartPopUp"
import PaymentResultPopUp from "~/containers/Cart/popups/paymentResultPopUp"
import ValidateOrderPopUp from "~/containers/Cart/popups/validateOrderPopup"
import axios from "~/lib/axios"
import { colors } from "~/utils/colors"

dayjs.locale("fr")

const { Panel } = Collapse

const Cart = () => {
  const location = useLocation()
  const [showPaymentResultModal, setShowPaymentResultModal] = useState(false)

  const {
    orders: {
      isFetching: isFetchingOrders,
      data: orders,
      error: ordersError,
      isValidating: isValidatingOrders,
    },
  } = useSelector((state) => state.cart)

  const paymentStatus = location.search.includes('?payment_result=') && location.search.split('?payment_result=')?.[1]?.[0]

  console.log({paymentStatus, location})

  useEffect(() => {
    if (
      paymentStatus
      // && curOrderPayment
    ) {
      setShowPaymentResultModal(true)
    }
  }, [paymentStatus])

  return (
    <>
      <ContentHeader
        breadcrumbItems={[
          {
            name: "Panier",
            path: "/panier",
          },
        ]}
      >
        <H1>Panier</H1>
      </ContentHeader>
      {!isFetchingOrders && !orders?.length && (
        <Container>
          <Result
            icon={<FrownOutlined />}
            title="Votre panier est vide!"
          />
        </Container>
      )}
      {!isFetchingOrders && orders?.length > 0 && (
        <CollapseWrapper>
          <Collapse
            defaultActiveKey={['0']}
            expandIconPosition="right"
            bordered={false}
          >
            {orders.map((order, index) => (
              <ContainerWrapper>
                <OrgOrder
                  key={index}
                  index={index}
                  order={order}
                />
              </ContainerWrapper>
            ))}
          </Collapse>
        </CollapseWrapper>
      )}
      {isFetchingOrders && (
        <Container>
          <Loading />
        </Container>
      )}
      <PaymentResultPopUp
        showPaymentResultModal={showPaymentResultModal}
        paymentStatus={paymentStatus}
        setShowPaymentResultModal={setShowPaymentResultModal}
      />
    </>
  )
}

const OrgOrder = ({
  order,
  index,
  ...props
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const {
    alias: orderAlias,
    organisation_name: organisationName,
    organisation_alias: organisationAlias,
    attendances,
    amount: organisationOrderInitialAmount,
    total_amount: organisationOrderAmount,
    credit,
    deactivate_paiement_methods: deactivatePaimentMethods,
    expiration_duration: expirationDuration,
    expires_at: expiresAt,
    status,
  } = order || {}

  const expiresAtDate = new Date(expiresAt)
  // const organisationOrderAmount = organisationOrderInitialAmount - credit

  const [isValidateOrderModalVisible, setIsValidateOrderModalVisible] = useState(false)
  const [isEmptyCartModalVisible, setIsEmptyCartModalVisible] = useState(false)

  const userEmail = useSelector((state) => state.user.auth.user.email)

  const showOtherPaymentMethodsButton = !deactivatePaimentMethods

  // const payCartOnTheSpot = async () => {
  //   try {
  //     let response = await axios.post(`/users/${userEmail}/prepaidOrders/`, {})

  //     if (response?.data.alias) {
  //       setPaymentOnTheSpotSuccessful(true)
  //     }
  //   } catch (e) {
  //     console.warn({e})
  //   }
  // }

  const handleValidateOrder = (orderAlias) => {
    setIsValidateOrderModalVisible(true)
    dispatch(
      validateOrder({
        orderAlias,
        closeModal: () => setIsValidateOrderModalVisible(false),
      })
    )
  }

  const handleDeleteOrder = (orderAlias) => {
    dispatch(
      deleteOrder({
        orderAlias,
        redirecturl: location.pathname,
        closeModal: () => setIsEmptyCartModalVisible(false),
      })
    )
  }

  const handlePayOrder = (orderAlias) => {
    dispatch(
      payOrder({
        orderAlias,
        redirecturl: location.pathname,
        closeModal: () => setIsValidateOrderModalVisible(false),
      })
    )
  }

  const handlePayOrderOnTheSpot = (orderAlias) => {
    dispatch(
      payOrder({
        orderAlias,
        redirecturl: location.pathname,
        is_payment_on_spot: true,
      })
    )
  }
  const renderedCountdown = ({ days, hours, minutes, seconds, completed }) => {
    console.log({completed, days, hours})
    if (completed) {
      // dispatch(fetchOrders())

      return <span style={{color: colors.cvRed, fontWeight: 'bold'}}>+ que quelques s</span>
    } else {
      // Render a countdown
      return (
        <span style={{color: colors.cvRed, fontWeight: 'bold'}}>
          {`${!!days ? `${days}j ` : ''}${!!hours ? `${hours}h ` : ''}`}
          {('0'+minutes).slice(-2)}:{('0'+seconds).slice(-2)}
        </span>
      );
    }
  };

  return (
    <>
      <Panel
        {...props}
        key={index}
        header={
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '95%'}}>
            <div>
              <Avatar size={42} src={SchoolSVG} />
              <OrgName>
                Panier pour {organisationName} {status === 2 ? <Tag color="orange">En cours de paiement</Tag> : ''}
              </OrgName>
            </div>
            {expiresAtDate instanceof Date && !isNaN(expiresAtDate.valueOf()) && (
              <p style={{marginBottom: 0}}>
                Validité panier ({expirationDuration} min.) :{' '}
                <Countdown
                  date={expiresAtDate}
                  renderer={renderedCountdown}
                />
              </p>
            )}
          </div>
        }
      >
        <div>
          {Object.keys(attendances).map((childAlias) => {
            const child = attendances?.[childAlias]
            const anActivityAlias = Object.keys(child).find(item => item !== 'total')

            const childName = child?.[anActivityAlias]?.child_name
            const activities = child
            const childTotal = child?.total

            return (
              <div>
                <LabelContainer>
                  <FirstLabel>
                    {childName}
                  </FirstLabel>
                  <Label>Quantité</Label>
                  <Label>Prix unitaire</Label>
                  <Label>Montant</Label>
                </LabelContainer>
                {Object.keys(activities)?.filter(item => item !== 'total').map((activityAlias) => {
                  const activity = activities?.[activityAlias]

                  const childFirstName = childName?.split(" ")?.[0]

                  const presenceDatesWithSubscription = activity?.InscriptionDates                  
                  const presenceDatesWithoutSubscription = activity?.presenceWithNoInscriptionDates
                  const activityName = activity?.parentActivity_name
                  const activityQuantity = activity?.quantity
                  const activityAmount = activity?.amount
                  const childActivityTotal = activity?.total

                  return (
                    <Line>
                      <FirstCol>
                        {activityName}
                        <Popover
                          placement="right"
                          content={
                            <>
                              <div
                                style={{
                                  marginBottom: 20,
                                }}
                              >
                                <ExclamationCircleOutlined
                                  style={{
                                    marginRight: 6,
                                    fontSize: 18,
                                    color: '#F4C26D',
                                  }}
                                />
                                {organisationName} / {activityName} / {childFirstName}
                              </div>
                              <div>
                                Réservation: <span style={{color: '#969696'}}>{presenceDatesWithSubscription.length > 0 ? presenceDatesWithSubscription.toString() : '-'}</span>
                              </div>
                              <div>
                                Présence sans inscription: <span style={{color: '#969696'}}>{presenceDatesWithoutSubscription.length > 0 ? presenceDatesWithoutSubscription.toString() : '-'}</span>
                              </div>
                            </>
                          }
                          trigger="click"
                        >
                          <ExclamationCircleOutlined
                            style={{
                              marginLeft: 5,
                              fontSize: 12,
                              color: '#646464',
                            }}
                          />
                        </Popover>
                      </FirstCol>
                      <div>{activityQuantity}</div>
                      <div>
                        {activityAmount}{" "}
                        €
                      </div>
                      <div>
                        {childActivityTotal} €
                      </div>
                    </Line>
                  )
                })}
                <SubTotalContainer>
                  SOUS-TOTAL<Bold>{childTotal} €</Bold>
                </SubTotalContainer>
              </div>
            )
          })}
        </div>
      </Panel>
      <FlexEven>
        <div>
          <DefaultButton
            size="large"
            style={{
              background: "#F2F4F6",
              borderColor: "#F2F4F6",
              color: "#646464",
              margin: 10,
            }}
            onClick={() => setIsEmptyCartModalVisible(true)}
          >
            {status === 2 ? 'Abondonner le panier' : 'Vider le panier'}
          </DefaultButton>
          <PrimaryButton
            onClick={() => handleValidateOrder(orderAlias)}
            size="large"
          >
            {status === 2 ? 'Continuer le paiement' : 'Valider mon panier'}
          </PrimaryButton>
        </div>
        <TotalContainer>
          <PriceLabel>TOTAL</PriceLabel>
          <Bold>{organisationOrderInitialAmount} €</Bold>
          <PriceLabel>AVOIR</PriceLabel>
          <Bold>{credit} €</Bold>
          <PriceLabel>TOTAL A PAYER</PriceLabel>
          <Bold>{organisationOrderAmount} €</Bold>
        </TotalContainer>
      </FlexEven>
      <EmptyCartPopUp
        visible={isEmptyCartModalVisible}
        setVisible={setIsEmptyCartModalVisible}
        emptyCart={() => handleDeleteOrder(orderAlias)}
      />
      <ValidateOrderPopUp
        visible={isValidateOrderModalVisible}
        setVisible={setIsValidateOrderModalVisible}
        showOtherPaymentMethodsButton={showOtherPaymentMethodsButton}
        payCartOnTheSpot={() => handlePayOrderOnTheSpot(orderAlias)}
        payCartByCB={() => handlePayOrder(orderAlias)}
      />
    </>
  )
}

const formatPayCart = (cart, orgAlias) => {
  let child_subscriptions = [];
  for (let c of cart) {
    
    child_subscriptions.push({
      child_alias: c.childAlias,
      activityAlias: c.sub.parent_activity_alias, // parent_activity_alias
      activityBuildingAlias: c.activityAlias,
      dates: [c.date],
    })
  }
  return {
    child_subscriptions,
    organisation_alias: orgAlias,
  };
};

const ContainerWrapper = ({ children, ...props }) => (
  <MainContainer {...props}>
    <MainContent>{React.cloneElement(children, {...props})}</MainContent>
  </MainContainer>
)

const FlexEven = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

const Bold = styled.span`
  font-weight: bold;
  margin-left: 20px;
`

const SubTotalContainer = styled.div`
  border-top: 1px solid #e2e4e6;
  margin-top: 5px;
  padding-top: 5px;
  text-align: right;
  width: 100%;
  margin-bottom: 20px;
`

const LabelContainer = styled.div`
  border-bottom: 1px solid #e2e4e6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 5px;
  margin-bottom: 5px;
`

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
`

const Label = styled.div`
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: @neutral-4;
`

const FirstCol = styled.div`
  width: 200px;
`

const FirstLabel = styled.div`
  width: 200px;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: @neutral-4;
`

const TotalContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: end;
`

const PriceLabel = styled.span`
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  color: @neutral-4;
  margin-right: 16px;
  margin-left: 32px;
`

const CollapseWrapper = styled.div`
  .ant-collapse-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .ant-collapse-content {
    border-top: none;
  }

  .anticon-right {
    font-size: 32px;
  }
  
  .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 0;
  }
`

const OrgName = styled.span`
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  color: @neutral-5;
  font-size: 20px;
  margin-bottom: 0px;
  margin-left: 10px;
  padding-bottom: 0px;
`

export default Cart
