import styled, {
  css,
} from 'styled-components'
import {
  Button,
  Card,
  Tag,
} from 'antd'

const card = css`
  width: 266px;
  position: relative;
  text-align: center;
  border-radius: 4px;
  border-color: #e2e4e6;
  margin-bottom: 23px;
  .ant-card-body {
    padding: 0;
  }
`

export const MiniTag = styled(Tag)`
  position: absolute;
  left: 8px;
  top: 8px;
`

export const CustomCard = styled(Card)`
  margin-right: 24px;
  ${card};
  .ant-card-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
  }
`
export const Name = styled.span`
  color: @neutral-5;
  font-size: 20px;
  margin: 5px 0 0;
`
export const SubText = styled.span`
  color: @neutral-4;
  font-size: 16px;
  line-height: 24px;
`

export const CustomButton = styled(Button)`
  color: @neutral-4;
  border-color: #e2e4e6;
  margin-top: 8px;
`

export const Options = styled(Button)`
  color: @neutral-4;
  border-color: #e2e4e6;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 10px;
`

export const CustomSpace = styled.div`
  width: 100%;
  padding: 40px 0 36px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`

export const Footer = styled.div`
  margin-top: auto;
  padding-bottom: 12px;
  font-size: 12px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`
