import React, { useState, useCallback, useEffect, useRef } from "react"

import Container from "~/components/common/Container"
import useErrors from "~/hooks/useErrors"

import {
  Form,
  Select,
  Input,
  Radio,
  Switch,
  InputNumber,
  Divider,
  Button,
  Upload,
  message,
  DatePicker,
  Row,
  Col,
  Space,
} from "antd"
import Checkbox from "antd/lib/checkbox/Checkbox"
import { allergies } from "~/constants"
import Search from "~/components/common/Search"
import { childProfileUpdating } from "~/containers/Children/actions"
import { useDispatch } from "react-redux"
import { registrationUpdating } from "~/containers/Registration/actions"

const { TextArea } = Input

const ChildSante = ({
  child,
}) => {
  const { ErrorComponent, setErrors } = useErrors()
  const dispatch = useDispatch()
  const [selectedAllergies, setSelectedAllergies] = useState([])
  const [isChanged, setIsChanged] = useState()
  const [form] = Form.useForm()
  const initialValues = useRef(null)

  const allAllergies = allergies.map((x) => {
    return { label: x, value: x }
  })

  const onFinish = (values) => {
    if (isChanged) {
      const newData = {
        has_pai: values?.has_pai,
        insurance: values?.insurance,
        allergy: JSON.stringify(values.allergies),
        comment: values.comment,
      }

      child.registrations.forEach(reg => {
        const formData = new FormData()
        formData.append('organisationAlias', reg?.organisationAlias)
        formData.append('social_security', values?.social_security)

        dispatch(registrationUpdating(
          child?.alias,
          reg?.alias,
          formData,
          () => {},
        ))
      })

      dispatch(childProfileUpdating(
        child.alias,
        newData,
        () => {},
      ))
    }
  }

  if (child) {
    initialValues.current = {
      has_pai: child?.has_pai,
      insurance: child?.insurance,
      comment: child?.comment,
      allergies: JSON.parse(child?.allergy),
      social_security: child?.registrations?.[0]?.social_security,
    }
  }

  useEffect(() => {
    if (child?.allergy) {
      const defaultAllergy = JSON.parse(child?.allergy)
      setSelectedAllergies(defaultAllergy)
    }
  }, [child])

  return (
    <Container>
      <Row>
        <Col span={12}>
          <Form
            layout="vertical"
            size="large"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues.current}
            onFieldsChange={() => {
              setIsChanged(true)
            }}
          >
            <Form.Item
              label="Sécurité sociale"
              name="social_security"
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner un prénom",
                },
              ]}
            >
              <Radio.Group>
                <Space>
                  <Radio.Button value={0}>
                    <Space size="small">Régime général</Space>
                  </Radio.Button>
                  <Radio.Button value={1}>
                    <Space size="small">MSA</Space>
                  </Radio.Button>
                  <Radio.Button value={2}>
                    <Space size="small">Autre</Space>
                  </Radio.Button>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Nom et numéro de police d’assurance"
              name="insurance"
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner un prénom",
                },
              ]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label="Allergie(s)"
              name="allergies"
            >
              <Search
                placeholder="Rechercher..."
                options={allAllergies}
                mode="multiple"
                defaultValue={selectedAllergies}
                value={selectedAllergies}
                onChange={setSelectedAllergies}
              />
            </Form.Item>
            <Form.Item name="has_pai" valuePropName="checked">
              <Checkbox>Dispose d’un PAI (Projet d'Accueil Individualisé)</Checkbox>
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: 234, marginTop: 20, marginBottom: 50 }}
              disabled={!isChanged}
            >
              Enregistrer
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default ChildSante
