import React, { useCallback, useState } from "react";
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";
// components
import { Form, Input, Checkbox, Button, Divider, Select } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Link from "~/components/common/Link";
import Error from "~/components/common/Error";
import P from "~/components/common/P";
import LogoSrc from "~/assets/images/logo.png";
import { LoginCard } from "../components";
import { login } from "../actions";

import Layout from "~/components/auth/Layout";
import DoubleContainer from "~/components/auth/DoubleContainer";
import Title from "~/components/auth/Title/index";

import Logo from "~/components/common/Logo/index";
import Centered from "~/components/common/Centered/index";
import Space from "~/components/common/Space/index";
import config from "~/config";
import mainAxios from "~/lib/axios";

const { Password } = Input;

const ForgotPassword = styled(Link)`
  position: absolute;
  right: 0;
  top: -24px;
  color: @neutral-4;
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: @neutral-5;
    text-decoration: underline;
  }
`;

const FormItem = Form.Item;
console.log({ ddd: config.api.devUrls });
const LoginForm = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation("user");
  const { isLogin, error } = useSelector((state) => state.user.auth);
  const [clicked, setClicked] = useState(false);

  const initialValues = {
    remember: true,
    email: window.localStorage.getItem("email") ?? "",
    password: "",
  };

  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values) => {
      setClicked(true);
      // wipeCart()
      // window.localStorage.clear()
      values.email = values.email.trim();
      if (values.remember) {
        window.localStorage.setItem("email", values.email);
      }
      dispatch(login(values));
    },
    [login, dispatch]
  );

  const storedApiUrl = window.localStorage.getItem("api");

  return (
    <div style={{}}>
      {/* <div style={{ width: '720px', margin: 'auto', marginBottom: '20px' }}>
        <Logo
          src={LogoSrc}
          height={40}
        />
      </div> */}

      {/* <LoginCard>
        <div className="card-left">
          <Title
            bold
          >
            {t('login.header')}
          </Title>
          <Divider height="10px" />
          <P>
            {t('login.description')}
          </P>
        </div>
        <div className="card-right">
          <Form
            form={form}
            onFinish={handleSubmit}
            className="card-form"
            initialValues={initialValues}
          >
            {!!error && clicked && <Error error={error} />}
            <FormItem
              name="email"
              rules={[
                {
                  required: true,
                  message: t('login.email-placeholder'),
                  type: 'email',
                },
              ]}
            >
              <Input
                prefix={(
                  <UserOutlined
                    style={{ color: 'rgba(0,0,0,.25)' }}
                  />
                )}
                placeholder={t('login.email-placeholder')}
                className="card-form-input"
              />
            </FormItem>
            <FormItem
              rules={[
                {
                  required: true,
                  message: t('login.password-placeholder'),
                },
              ]}
              name="password"
            >
              <Input
                prefix={(
                  <LockOutlined
                    style={{ color: 'rgba(0,0,0,.25)' }}
                  />
                )}
                type="password"
                placeholder={t('login.password-placeholder')}
                className="card-form-input"
              />
            </FormItem>
            <FormItem
              name="remember"
              valuePropName="checked"
              className="remember-me"
            >
              <Checkbox>
                {t('login.checkbox')}
              </Checkbox>
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                className="card-form-button"
                loading={isLogin}
              >
                {t('login.cta')}
              </Button>
              <Link
                to="/forgotten-password"
                className="card-form-link"
              >
                {t('login.forget-password')}
              </Link>
            </FormItem>
          </Form>
        </div>
      </LoginCard> */}
      <Layout style={{ background: "red" }}>
        <DoubleContainer asset="/images/common/loginimage.jpg">
          {config.app.env && config.app.env !== "prod" && (
            <Form.Item label="Serveur">
              <Select
                defaultValue={storedApiUrl || config.api.devUrls[0]}
                onSelect={(value) => {
                  window.localStorage.setItem("api", value);
                  mainAxios.defaults.baseURL = value;
                }}
                style={{ width: "100%" }}
              >
                {config.api.devUrls?.map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Space direction="vertical" size="large">
            <Centered>
              <Logo width="175" height="56" />
            </Centered>
            <Centered>
              <Title>{t("login.title")}</Title>
            </Centered>
            {/* <ErrorComponent /> */}
            {!!error && clicked && <Error error={error} />}
            <Form
              size="large"
              layout="vertical"
              form={form}
              initialValues={initialValues}
              onFinish={handleSubmit}
            >
              <Form.Item
                // label="Adresse e-mail"
                label={t("login.email-label")}
                name="email"
                rules={[
                  {
                    required: true,
                    // type: 'email',
                    // message: 'Veuillez renseigner une adresse e-mail valide',
                    message: "Veuillez renseigner votre identifiant",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("login.password-label")}
                required
                style={{ position: "relative" }}
              >
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Veuillez renseigner votre mot de passe",
                    },
                  ]}
                  noStyle
                >
                  <Password />
                </Form.Item>
                <ForgotPassword
                  // to={() => ({
                  //   pathname: "/forgotten-password",
                  //   state: {
                  //     username: form.getFieldValue("username"),
                  //   },
                  // })}
                  to="/forgotten-password"
                >
                  Mot de passe oublié
                </ForgotPassword>
              </Form.Item>
              <FormItem
                name="remember"
                valuePropName="checked"
                className="remember-me"
              >
                <Checkbox>{t("login.checkbox")}</Checkbox>
              </FormItem>
              <Button
                type="primary"
                htmlType="submit"
                // loading={loading}
                block
              >
                {t("login.cta")}
              </Button>
            </Form>
          </Space>
        </DoubleContainer>
      </Layout>
    </div>
  );
};

LoginForm.propTypes = {};

export default LoginForm;
