import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const LogoComponent = ({
  width = 125,
  height = 40,
  ...props
}) => (
  <img
    {...props}
    width={width}
    height={height}
    src="/images/common/logoedited.png"
    alt="Cityviz logo"
  />
)

const Logo = styled(LogoComponent)`
`
Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

Logo.defaultProps = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default Logo
