import React, { useEffect, useState } from "react"
import { Routes, Route } from "react-router-dom"
// import "antd/dist/antd.less"
import ContentHeader from "~/components/common/ContentHeader"
import Button from "~/components/common/Button"
import { EditOutlined } from "@ant-design/icons"
import { Link, useLocation } from "react-router-dom"
import Menu, {
  MenuItem,
} from "~/components/common/ContentHeader/components/Menu"
import { useSelector, useDispatch } from "react-redux"
import H1 from "~/components/common/H1"

import ProfileParentCard from "~/components/common/ProfileParentCard"

import Profile from "./index"
import Bank from "./bank.js"
import EditUser from "./editUser"
import Invoice from "./invoice"
import Attestation from "./attestation"
import { householdFetching } from "~/containers/Foyer/actions"
import { fetchUserProfile } from "~/containers/User/actions"

const Foyer = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const user = useSelector((state) => state.user.userInfo.user)
  const userInfo = useSelector((state) => state.user.auth.user)
  const [showDrawer, setShowDrawer] = useState(false)
  const [child, setChild] = useState(null)
  const [selectedTab, setSelectedTab] = useState(["foyer"])
  const {data: householdData} = useSelector((state) => state.household.household)

  const handleMenu = (e) => {
    setSelectedTab(e.key)
  }

  useEffect(() => {
    dispatch(fetchUserProfile())
    dispatch(householdFetching())

    if (location.search.includes('openDrawer')) {
      setShowDrawer(true)
    }
  }, [])

  console.log({user})
  return (
    <>
      <ContentHeader
        breadcrumbItems={[
          {
            name: "Foyer",
            path: "/foyer",
          },
          {
            name: `${user?.first_name} ${user?.last_name}`,
            path: "/profile",
          },
        ]}
        buttons={() => (
          <>
            <Button       
              size="large"
              style={{ minWidth: 220 }}
              onClick={() => setShowDrawer(true)}
              icon={<EditOutlined />}
            >
              Éditer
            </Button>
          </>
        )}
        // navigationTitle="Année scolaire :"
        navigation={() => (
          <Menu onClick={handleMenu} selectedKeys={selectedTab} style={{ minWidth: 0, flex: "auto" }}>
            <MenuItem key="foyer">
              <Link to="/profile">Mon Profil</Link>
            </MenuItem>
            {/* <MenuItem key="contact">
              <Link to="/profile/bank">Coordonnées bancaires</Link>
            </MenuItem>
            <MenuItem key="factures">
              <Link to="/profile/invoice">Mes factures</Link>
            </MenuItem>
            <MenuItem key="document">
              <Link to="/profile/document">Mes attestations</Link>
            </MenuItem> */}
          </Menu>
        )}
      >
        <ProfileParentCard
          name={user.first_name + " " + user.last_name}
          email={userInfo.email}
          phone={user.mobile}
          picture={user.photo_url}
          isLoading={false}
        ></ProfileParentCard>
      </ContentHeader>
      <Routes>
        <Route path="/*" exact element={<Profile />} />
        <Route path="/bank" exact element={<Bank />} />
        <Route path="/invoice" exact element={<Invoice />} />
        <Route path="/document" exact element={<Attestation />} />
      </Routes>
      <EditUser
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        profile={user}
      ></EditUser>

      {/* <EditHousehold
        data={child}
        setShowDrawer={setShowDrawer}
        showDrawer={showDrawer}
      ></EditHousehold> */}
    </>
  )
}

export default Foyer
