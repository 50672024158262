import { takeLatest, takeEvery, put, select, all, call } from "redux-saga/effects";

import { message } from "antd";
import axios from "~/lib/axios";
import { getAuthUserInfos } from "~/containers/User/selectors";
import { getChildren } from "~/containers/Children/selectors";
import { checkJwtExpiration } from "~/containers/User/sagas";
import { fetchOrdersSaga } from "~/containers/Cart/sagas";
import { logout } from "~/containers/User/actions";
import BoySVG from "~/assets/images/boy.svg";
import GirlSVG from "~/assets/images/girl.svg";
import {
  fetchChildren,
  fetchChildrenProcess,
  fetchChildrenError,
  fetchChildrenSuccess,

  // Child details
  fetchChildDetails,
  fetchChildDetailsError,
  fetchChildDetailsSuccess,
  fetchChildDetailsProcess,

  // Selected Children details
  fetchSelectedChildrenDetails,
  fetchSelectedChildrenDetailsProcess,
  fetchSelectedChildrenDetailsSuccess,
  fetchSelectedChildrenDetailsError,

  // Children registrations
  fetchChildrenRegistrations,
  fetchChildrenRegistrationsProcess,
  fetchChildrenRegistrationsSuccess,
  fetchChildrenRegistrationsError,

  // child picture
  fetchChildPhotoSuccess,
  fetchChildPhotoError,
  fetchChildPhotoProcess,
  fetchChildRegisterations,
  fetchChildRegisterationsSuccess,
  // opening days
  fetchOpeningdays,
  fetchOpeningdaysSuccess,
  fetchOpeningdaysError,
  fetchOpeningdaysProcess,

  // children planning days
  fetchChildrengardeplanningdays,
  fetchChildrengardeplanningdaysProcess,
  fetchChildrengardeplanningdaysError,
  fetchChildrengardeplanningdaysSuccess,

  // opening days
  fetchAllOpeningdays,
  fetchAllOpeningdaysSuccess,
  fetchAllOpeningdaysError,
  fetchAllOpeningdaysProcess,

  // Child attendances
  fetchChildAttendances,
  fetchChildAttendancesSuccess,
  fetchChildAttendancesError,
  fetchChildAttendancesProcess,

  // attendances details
  fetchAttendancesDetails,
  fetchAttendancesDetailsError,
  fetchAttendancesDetailsProcess,
  fetchAttendancesDetailsSuccess,
  // create attendance
  createAttendance,
  createAttendanceError,
  createAttendanceProcess,
  createAttendanceSuccess,

  // create multiple attendances
  createMultipleAttendances,
  createMultipleAttendancesProcess,
  createMultipleAttendancesError,
  createMultipleAttendancesSuccess,

  // patch attendacne
  patchAttendance,
  patchAttendanceError,
  patchAttendanceProcess,
  patchAttendanceSuccess,

  // delete attendance
  deleteAttendance,
  deleteAttendanceSuccess,
  deleteAttendanceError,
  deleteAttendanceProcess,

  // Children attendances
  allChildrenAttendancesFetching,
  allChildrenAttendancesFetched,
  allChildrenAttendancesFetchError,

  // Updating child
  childProfileUpdating,
  childProfileUpdated,
  childProfileUpdateError,

  // Creating child
  childCreating,
  childCreated,
  childCreateError,

  // Updating child picture
  childPhotoUpdating,
  childPhotoUpdated,
  childPhotoUpdateError,

  // download document
  downloadDocument,

  // delete document
  deleteDocument,
  deleteDocumentSuccess,

  // fetch user document
  fetchDocument,
  fetchDocumentPending,
  fetchDocumentError,
  fetchDocumentSuccess,

  // create recurrent attendance
  createRecurrentAttendance,
  createRecurrentAttendanceProcess,
  createRecurrentAttendanceError,
  createRecurrentAttendanceSuccess,

  // create attendance by container
  createAttendanceByContainer,
  createAttendanceByContainerProcess,
  createAttendanceByContainerError,
  createAttendanceByContainerSuccess,

  // request credit
  requestCredit,
  requestCreditProcess,
  requestCreditError,
  requestCreditSuccess,

  // cancel credit request
  cancelCreditRequest,
  cancelCreditRequestProcess,
  cancelCreditRequestError,
  cancelCreditRequestSuccess,
} from "./actions";
import { getChild, getAttendances } from "./selectors";
import { func } from "prop-types";

function* childrenSaga() {
  try {
    yield* checkJwtExpiration();
    yield put(fetchChildrenProcess());

    const authUserInfos = yield select(getAuthUserInfos);

    if (authUserInfos.email) {
      const response = yield axios.get(
        `users/${authUserInfos.email}/children/`
      );
      /**
       * Must handle pagination
       */
      yield put(fetchChildrenSuccess(response.data.results));
    } else {
      yield put(fetchChildrenError());
      yield put(logout());
    }
  } catch (err) {
    console.log(err);
    yield put(fetchChildrenError());
    // yield put(handleErrorStatus(err.response.status))
  }
}

function* getChildRegistrations(userEmail, childAlias) {
  return yield axios.get(
    `users/${userEmail}/children/${childAlias}/registrations/`
  );
}

export function* fetchChildrenRegistrationsSaga() {
  try {
    yield* checkJwtExpiration();
    yield put(fetchChildrenRegistrationsProcess());

    const authUserInfos = yield select(getAuthUserInfos);

    if (authUserInfos.email) {
      const response = yield axios.get(
        `users/${authUserInfos.email}/children/`
      );
      /**
       * Must handle pagination
       */
      const result = {};
      const childrenAliases = response.data.results.map((child) => child.alias);

      for (let i = 0; i < childrenAliases.length; i += 1) {
        try {
          const response = yield axios.get(
            `users/${authUserInfos.email}/children/${childrenAliases[i]}/registrations/?page_size=1000`
          );

          result[childrenAliases[i]] = response.data.results;
        } catch (error) {}
      }

      // const result = childrenAliases.reduce((acc, cur) => {
      //   const registrations = call(getChildRegistrations, authUserInfos.email, cur);
      //
      //   acc[cur] = registrations?.data?.results

      //   return acc
      // }, {}))

      yield put(fetchChildrenRegistrationsSuccess(result));
    } else {
      yield put(fetchChildrenRegistrationsError());
      yield put(logout());
    }
  } catch (err) {
    console.log(err);
    yield put(fetchChildrenRegistrationsError());
    // yield put(handleErrorStatus(err.response.status))
  }
}

export function* fetchChildPictureSaga() {
  const child = yield select(getChild);
  let url;
  try {
    yield put(fetchChildPhotoProcess());
    if (child?.photo) {
      const res = yield axios.get(child?.photo, {
        responseType: "arraybuffer",
      });
      const binData = yield Buffer.from(res.data, "binary").toString("base64");
      url = `data:image/jpg;base64,${binData}`;
    } else {
      url = !child.gender ? BoySVG : GirlSVG;
    }
    yield put(fetchChildPhotoSuccess(url));
  } catch (error) {
    yield put(fetchChildPhotoError());
  }
}

export function* fetchChildDetailsSaga({ payload: { data, callback } }) {
  try {
    yield* checkJwtExpiration();
    yield put(fetchChildDetailsProcess());
    const authUserInfos = yield select(getAuthUserInfos);
    if (authUserInfos.email) {
      const response = yield axios.get(data.url);
      if (typeof callback === "function") callback(response.data);
      yield put(fetchChildDetailsSuccess(response.data));

      yield* fetchChildPictureSaga();
    } else {
      yield put(fetchChildDetailsError());
      yield put(logout());
    }
  } catch (err) {
    console.log(err);
    yield put(fetchChildDetailsError());
    // yield put(handleErrorStatus(err.response.status));
  }
}

export function* fetchSelectedChildrenDetailsSaga({
  payload: { data, callback },
}) {
  try {
    yield* checkJwtExpiration();
    yield put(fetchSelectedChildrenDetailsProcess());
    const authUserInfos = yield select(getAuthUserInfos);
    if (authUserInfos.email) {
      let result = [];
      for (let i = 0; i < data.length; i += 1) {
        const response = yield axios.get(data[i]);

        result.push(response.data);
      }

      if (typeof callback === "function") callback();
      yield put(fetchSelectedChildrenDetailsSuccess(result));
    } else {
      yield put(fetchSelectedChildrenDetailsError());
      yield put(logout());
    }
  } catch (err) {
    console.log(err);
    yield put(fetchSelectedChildrenDetailsError());
    // yield put(handleErrorStatus(err.response.status));
  }
}

export function* fetchChildRegisterationSaga() {
  const child = yield select(getChild);
  const user = yield select(getAuthUserInfos);

  try {
    if (!!child.alias) {
      const { data } = yield axios.get(
        `users/${user.email}/children/${child.alias}/registrations/`
      );
      yield put(fetchChildRegisterationsSuccess(data?.results));
    }
  } catch (error) {
    console.log("error", error);
  }
}

// function* fetchChildrenAllAttendancesSage

export function* fetchChildrenAttendancesSaga({
  payload: { month = undefined, year = undefined },
}) {
  //Here set refresh after cointainer reservation
  try {
    yield* checkJwtExpiration();
    const userInfos = yield select(getAuthUserInfos);
    if (!userInfos.email) {
      yield put(allChildrenAttendancesFetchError());
      yield put(logout());
    }

    const response = yield axios.get(
      `users/${userInfos.email}/allAttendances/?month=${month}&year=${year}`
    );

    yield put(allChildrenAttendancesFetched(response?.data));
  } catch (err) {
    console.log(err);
    yield put(allChildrenAttendancesFetchError());
  }
}

export function* fetchChildAttendancesSaga({
  payload: { childAlias, activityAlias, month },
}) {
  try {
    yield* checkJwtExpiration();
    yield put(fetchChildAttendancesProcess());

    const authUserInfos = yield select(getAuthUserInfos);
    const response = yield axios.get(
      `users/${authUserInfos.email}/children/${childAlias}/attendances/?month=${month}&activity=${activityAlias}`
    );

    yield put(fetchChildAttendancesSuccess(response.data));
  } catch (err) {
    yield put(fetchChildAttendancesError());
    message.error("Erreur lors du chargement du planning de votre enfant");
  }
}

export function* fetchChildAttendanceDetailsSaga({
  payload: { childAlias, alias },
}) {
  try {
    yield* checkJwtExpiration();
    yield put(fetchAttendancesDetailsProcess());

    const authUserInfos = yield select(getAuthUserInfos);

    const response = yield axios.get(
      `users/${authUserInfos.email}/children/${childAlias}/attendances/${alias}/`
    );
    yield put(fetchAttendancesDetailsSuccess(response.data));
  } catch (err) {
    yield put(fetchAttendancesDetailsError());
    message.error("Erreur lors du chargement de cette date");
  }
}
export function* fetchAllOpeningDaysSaga({ payload: { month, year, childAlias } }) {
  try {
    const authUserInfos = yield select(getAuthUserInfos);

    yield* checkJwtExpiration();

    if (!authUserInfos.email) {
      yield put(fetchAllOpeningdaysError());
    }

    yield put(fetchAllOpeningdaysProcess());

    const response = yield axios.get(
      `users/${authUserInfos.email}/parent_events/?month=${month}&year=${year}`
    );

    const mergedEvents = Object.entries(response.data).reduce((acc, cur) => {
      const mergedCur = cur?.[1].reduce((curAcc, curCur) => {
        const injectedOrgCur = curCur.map((item) => ({
          ...item,
          activity_alias: item?.parent_activity_alias,
          org_alias: cur?.[0],
        }));

        curAcc = [...curAcc, ...injectedOrgCur];

        return curAcc;
      }, []);

      acc = [...acc, ...mergedCur];

      return acc;
    }, []);

    yield put(fetchAllOpeningdaysSuccess(mergedEvents));
  } catch (error) {
    console.log({ error });
    yield put(fetchAllOpeningdaysError());
  }

  // const promises = [];
  // for (let i = 0; i < aliases.length; i += 1) {
  //   promises.push(
  //     axios.get(
  //       `organisations/${aliases[i].org_alias}/activities/${aliases[i].activity_alias}/events/?month=${month}`
  //     )
  //   );

  // const urls = [];
  // for (let i = 0; i < aliases.length; i += 1) {
  //   urls.push(
  //     `organisations/${aliases[i].org_alias}/activities/${aliases[i].activity_alias}/events/?month=${month}`
  //   );
  // }

  // axios.all(urls).then((res) =>

  // try {
  //   const response = yield axios.get(
  //     `organisations/${aliases[i].org_alias}/activities/${aliases[i].activity_alias}/events/?month=${month}`
  //   );
  //   responses.push(...response.data);
  //
  //
  // } catch (err) {
  //
  //   if (err.response.status !== 404) {
  //     message.error("Erreur lors du chargement des dates de l'activité");
  //   }

  // const res = yield Promise.allSettled(promises);

  //
  // if (res.length === aliases.length) {
  //   const responses = [];
  //   for (let pro of res) {
  //     if (pro.status === "fulfilled") {
  //       responses.push(...pro.value.data);
  //     }
  //   }
  //
  //   AllOpenDays = AllOpenDays.concat(
  //     responses?.map((item) => ({
  //       ...item,
  //       org_alias: aliases[i].org_alias,
  //       activity_alias: aliases[i].activity_alias,
  //     }))
  //   );
  //   yield put(fetchAllOpeningdaysSuccess(AllOpenDays));
  // }

  // yield put(fetchAllOpeningdaysError());
  // }
}

function* updateChildProfile({ payload: { childAlias, data, callback } }) {
  console.log({ childAlias, data });
  try {
    yield* checkJwtExpiration();
    const userInfos = yield select(getAuthUserInfos);
    if (!userInfos.email) {
      yield put(childProfileUpdateError());
      message.error("Une erreur inattendue s'est produite");
      yield put(logout());
      if (typeof callback === "function") callback(null);
    }
    const response = yield axios.patch(
      `users/${userInfos.email}/children/${childAlias}/`,
      {
        ...data,
      }
    );
    yield put(childProfileUpdated(response.data));
    if (typeof callback === "function") callback(response.data);
    message.success("Les informations ont été mises à jour avec succès");
  } catch (err) {
    console.log(err);
    if (typeof callback === "function") callback(null);
    yield put(childProfileUpdateError());
    message.error("Une erreur inattendue s'est produite");
  }
}

/** Get Opening days */
export function* fetchOpeningDaysSaga({
  payload: { organisationAlias, activityAlias, month },
}) {
  try {
    yield* checkJwtExpiration();
    yield put(fetchOpeningdaysProcess());

    const response = yield axios.get(
      `organisations/${organisationAlias}/activities/${activityAlias}/events/?month=${month}`
    );
    yield put(fetchOpeningdaysSuccess(response.data));
  } catch (err) {
    if (err.response.status !== 404) {
      message.error("Erreur lors du chargement des dates de l'activité");
    }
    yield put(fetchOpeningdaysError());
  }
}

/** Get Planning Garde Children Days */
export function* fetchChildrengardeplanningdaysSaga({
  payload: { month, year},
}) {
  try {
    yield* checkJwtExpiration();
    const userInfos = yield select(getAuthUserInfos);
    
    if (!userInfos.email) {
      yield put(fetchChildrengardeplanningdaysError());
    }

    yield put(fetchChildrengardeplanningdaysProcess());

    const response = yield axios.get(
      `users/${userInfos.email}/planning_garde/${month}/${year}`
    );

    //console.log(response.data)

    yield put(fetchChildrengardeplanningdaysSuccess(response.data));
  } catch (err) {
    console.log(err)
    yield put(fetchChildrengardeplanningdaysError());
  }
}
// /**
//  * createAttendance
//  */
export function* createAttendanceSaga({
  payload: { childAlias, date, activityAlias },
}) {
  try {
    yield* checkJwtExpiration();
    yield put(createAttendanceProcess());
    const authUserInfos = yield select(getAuthUserInfos);
    const data = {
      date,
      activity_alias: activityAlias,
    };
    const response = yield axios.post(
      `users/${authUserInfos.email}/children/${childAlias}/attendances/?channel=web`,
      {
        ...data,
      }
    );
    const attendances = yield select(getAttendances);
    const tempAttendances = [...attendances];
    tempAttendances.push(response.data);
    yield put(createAttendanceSuccess(tempAttendances));
    message.success("Modification effectuée");
  } catch (err) {
    //
    yield put(createAttendanceError());
    if (err.response && err.response.data.date) {
      message.error(
        err.response.data.date ||
          "Il est trop tard pour modifier l'inscription en ligne. Veuillez contacter votre Organisation"
      );
    } else {
      message.error("Erreur lors de l'ajout de cette date");
    }
  }
}

// /**
//  * createAttendance
//  */
export function* createMultipleAttendancesSaga({
  payload: { childrenAliases, dates, activityAlias, callback },
}) {
  try {
    yield* checkJwtExpiration();
    yield put(createMultipleAttendancesProcess());
    const authUserInfos = yield select(getAuthUserInfos);

    const attendances = yield select(getAttendances);

    let result = [];
    for (let i = 0; i < childrenAliases.length; i += 1) {
      for (let j = 0; j < dates.length; j += 1) {
        const attendanceExists = attendances.find(
          (att) =>
            att.activity_alias === activityAlias &&
            att.child_alias === childrenAliases?.[i] &&
            att.date === dates?.[j]
        );

        if (!attendanceExists) {
          const data = {
            date: dates?.[j],
            activity_alias: activityAlias,
          };
          const response = yield axios.post(
            `users/${authUserInfos.email}/children/${childrenAliases?.[i]}/attendances/?channel=web`,
            {
              ...data,
            }
          );

          result = [...result, response.data];
        }
      }
    }
    const tempAttendances = [...attendances, ...result];

    yield put(createMultipleAttendancesSuccess(tempAttendances));
    if (typeof callback === "function") {
      callback();
    }
    message.success("Modifications effectuées");
  } catch (err) {
    console.log("error", err);
    yield put(createMultipleAttendancesError());
    if (err.response && err.response.data.date) {
      message.error(
        err.response.data.date ||
          "Il est trop tard pour modifier l'inscription en ligne. Veuillez contacter votre mairie"
      );
    } else {
      message.error("Erreur lors de l'ajout de cette date");
    }
  }
}

export function* patchAttendanceSaga({
  payload: { childAlias, alias, present },
}) {
  try {
    yield* checkJwtExpiration();
    yield put(patchAttendanceProcess());

    const authUserInfos = yield select(getAuthUserInfos);
    const data = {
      present,
    };
    const response = yield axios.patch(
      `users/${authUserInfos.email}/children/${childAlias}/attendances/${alias}/`,
      {
        ...data,
      }
    );
    const attendances = yield select(getAttendances);
    const newAttendances = attendances.map((item) => {
      if (item.alias !== alias) {
        return item;
      }
      return response.data;
    });
    yield put(patchAttendanceSuccess(newAttendances));
    message.success("Modification effectuée");
  } catch (err) {
    //
    yield put(patchAttendanceError());
    message.error("Erreur lors de la modification de cette date");
  }
}

export function* deleteAttendanceSaga({
  payload: { childAlias, alias, month },
}) {
  const children = yield select(getChildren);
  const childrenAliases = children?.map((child) => child?.alias);

  try {
    yield* checkJwtExpiration();
    yield put(deleteAttendanceProcess());

    const authUserInfos = yield select(getAuthUserInfos);

    const response = yield axios.delete(
      `users/${authUserInfos.email}/children/${childAlias}/attendances/${alias}/`
    );

    message.success("Modification effectuée");
    yield* fetchChildrenAttendancesSaga({
      payload: {
        aliases: childrenAliases,
        month,
      },
    });
  } catch (err) {
    //
    yield put(deleteAttendanceError());
    message.error("Erreur lors de la modification de cette date");
  }
}

function* updateChildPhoto({ payload: { childAlias, photo, callback } }) {
  try {
    yield* checkJwtExpiration();
    const userInfos = yield select(getAuthUserInfos);
    if (!userInfos.email) {
      yield put(childPhotoUpdateError());
      yield put(logout());
    }
    const formData = new FormData();
    formData.append("photo", photo);

    yield axios.post(
      `users/${userInfos.email}/children/${childAlias}/uploadPhoto/`,
      formData,
      { headers: { "content-type": "multipart/form-data" } }
    );
    yield put(childPhotoUpdated());
    yield* fetchChildPictureSaga();
    if (typeof callback === "function") callback(childAlias);
  } catch (err) {
    //
    yield put(childPhotoUpdateError());
    if (typeof callback === "function") callback(childAlias);
  }
}

function* createChild({ payload: { child, photo, callback } }) {
  try {
    yield* checkJwtExpiration();
    const userInfos = yield select(getAuthUserInfos);
    if (!userInfos.email) {
      yield put(childCreateError());
      yield put(logout());
      if (typeof callback === "function") callback(null);
    }
    const response = yield axios.post(`users/${userInfos.email}/children/`, {
      ...child,
    });
    yield put(fetchChildren());
    yield put(childCreated(response.data.alias));
    if (photo)
      yield put(childPhotoUpdating(response.data.alias, photo, callback));
    else if (typeof callback === "function") callback(response.data.alias);
  } catch (err) {
    console.log(err);
    if (typeof callback === "function") callback(null);
    yield put(childCreateError());
  }
}

// download document

function* downloadDocumentSaga({ payload: doc }) {
  try {
    const file = doc.url.split(".");
    const res = yield axios.get(doc.url, {
      responseType: "blob", // important
    });
    if (res && typeof window !== "undefined") {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${doc.label}.${file[file.length - 1]}`);
      document.body.appendChild(link);
      link.click();
    }
  } catch (error) {
    console.log("error", error);
  }
}

// delete user document

function* deleteDocumentSaga({ payload: documents }) {
  try {
    const authUserInfos = yield select(getAuthUserInfos);

    yield axios.delete(`users/${authUserInfos.email}/documents/${documents}/`, {
      headers: { "content-type": "multipart/form-data" },
    });
    message.success("Le document à bien été supprimé");
    yield put(deleteDocumentSuccess(documents.alias));
  } catch (error) {
    console.log("error", error);
  }
}

// fetch document
export function* fetchDocumentSaga({ payload: url }) {
  try {
    yield put(fetchDocumentPending());

    const { data } = yield axios.get(url, {
      responseType: "arraybuffer",
    });
    const binData = Buffer.from(data, "binary");
    const documentUrl = `data:image/${
      url?.[url?.length - 1]
    };base64,${binData.toString("base64")}`;
    yield put(fetchDocumentSuccess(documentUrl));
  } catch (error) {
    console.log("error", error);
    yield put(fetchDocumentError());
  }
}

// /**
//  * createRecurrentAttendance
//  */
export function* createRecurrentAttendanceSaga({
  payload: { data, month, callback },
}) {
  try {
    yield* checkJwtExpiration();
    yield put(createRecurrentAttendanceProcess());
    const children = yield select(getChildren);
    const childrenAliases = children?.map((child) => child?.alias);
    const authUserInfos = yield select(getAuthUserInfos);
    const response = yield axios.post(
      `users/${authUserInfos.email}/attendances/recurrent/`,
      {
        ...data,
      }
    );

    const responseData = response?.data;

    yield put(createRecurrentAttendanceSuccess(responseData));
    console.log({responseData}, '------------');

    yield* fetchOrdersSaga();

    if (typeof callback === "function") callback();

    if (responseData?.msgs?.length === 1 && responseData?.errors?.length < 1) {
      message.success(responseData?.msgs[0], 10);

      return;
    } 
    
    if (responseData?.errors?.length === 1 && responseData?.msgs?.length < 1) {
      message.error(responseData?.errors[0], 10);

      return;
    }

    if (responseData?.errors?.length > 1 || responseData?.msgs?.length > 1) {
      message.success(responseData?.msg, 10);

      return;
    }

    // yield* fetchChildrenAttendancesSaga({
    //   payload: {
    //     aliases: childrenAliases,
    //     month,
    //   },
    // });
    // message.success("Réservation en récurrence effectuée");
  } catch (err) {
    console.log({err}, '******++++++');
    //
    yield put(createRecurrentAttendanceError());
    if (err.response && err.response.data.date) {
      message.error(
        err.response.data.date ||
          "Il est trop tard pour modifier l'inscription en ligne. Veuillez contacter votre Organisation"
      );
    } else {
      message.error("Erreur lors de l'ajout de cette date");
    }
  }
}

// createAttendanceByContainer
export function* createAttendanceByContainerSaga({
  payload: { data,childAlias, month, callback },
}) {
  try {
    yield* checkJwtExpiration();
    yield put(createAttendanceByContainerProcess());
    const children = yield select(getChildren);
    const childrenAliases = children?.map((child) => child?.alias);
    const authUserInfos = yield select(getAuthUserInfos);
    const response = yield axios.post(
      `users/${authUserInfos.email}/children/${childAlias}/attendances/`,
      {
        ...data,
      }
    );
    const responseData = response?.data;
   
    yield put(createAttendanceByContainerSuccess(response?.data));
    
    console.log({responseData}, '------------');

    yield* fetchOrdersSaga();

    if (typeof callback === "function") callback();

    if (responseData?.msgs?.length === 1) {
      message.success(responseData?.msgs[0], 10);

      return;
    } 
    
    if (responseData?.errors?.length === 1) {
      message.error(responseData?.errors[0], 10);

      return;
    }

    if (responseData?.errors?.length > 1 || responseData?.msgs?.length > 1) {
      message.success(responseData?.msg, 10);

      return;
    }

  } catch (err) {
    console.log({err}, '******++++++');

    yield put(createAttendanceByContainerError());
    if (err.response && err.response.data.date) {
      message.error(
        err.response.data.date ||
          "Il est trop tard pour modifier l'inscription en ligne. Veuillez contacter votre Organisation"
      );
    } else {
      message.error("Erreur lors de l'ajout de cette date");
    }
  }
}

function* requestCreditSaga({ payload: { attAlias, month, year } }) {
  try {
    yield* checkJwtExpiration();
    const userInfos = yield select(getAuthUserInfos);
    if (!userInfos.email) {
      yield put(requestCreditError());
      yield put(logout());
      if (typeof callback === "function") callback(null);
    }
    const response = yield axios.post(`users/${userInfos.email}/attendances/${attAlias}/creditRequest/`);

    const responseData = response?.data;
    console.log({response, responseData}, '------------');

    yield put(
      createRecurrentAttendanceSuccess({
        attendances: [responseData?.attendances],
        deleted_attendances: [attAlias],
      }),
    )

    yield put(requestCreditSuccess());
    // yield* fetchChildrenAttendancesSaga({
    //   payload: {
    //     month,
    //     year,
    //   },
    // });
    message.success(response?.data?.msg || "demande d'annulation prise en compte, un avoir sera émis sous 30 minutes ... tant que l'avoir n'est pas émis vous pouvez néanmoins annuler votre demande", 10);
  } catch (err) {
    console.log(err);
    yield put(requestCreditError());
    message.error(<><span dangerouslySetInnerHTML={{ __html: err?.response?.data?.msg}}></span></> || "... une erreur s'est produite, merci de recommencer votre demande", 10);
  }
}

function* cancelCreditRequestSaga({ payload: { attAlias, month, year } }) {
  try {
    yield* checkJwtExpiration();
    const userInfos = yield select(getAuthUserInfos);
    if (!userInfos.email) {
      yield put(cancelCreditRequestError());
      yield put(logout());
      if (typeof callback === "function") callback(null);
    }

    const response = yield axios.post(`users/${userInfos.email}/attendances/${attAlias}/creditRequestCancel/`);

    const responseData = response?.data;

    console.log({response, responseData}, '--------+++----');
    yield put(
      createRecurrentAttendanceSuccess({
        attendances: [responseData?.attendances],
        deleted_attendances: [attAlias],
      }),
    )
    yield put(cancelCreditRequestSuccess());
    // yield* fetchChildrenAttendancesSaga({
    //   payload: {
    //     month,
    //     year,
    //   },
    // });
    message.success(response?.data?.msg || "demande initiale d'avoir annulée", 10);
  } catch (err) {
    console.log(err);
    yield put(cancelCreditRequestError());
    message.error(err?.response?.data?.msg || "... une erreur s'est produite, merci de recommencer votre demande", 10);
  }
}

function* rootSaga() {
  yield all([
    takeLatest(fetchChildren, childrenSaga),
    takeLatest(fetchChildDetails, fetchChildDetailsSaga),
    takeLatest(fetchSelectedChildrenDetails, fetchSelectedChildrenDetailsSaga),
    takeLatest(fetchChildrenRegistrations, fetchChildrenRegistrationsSaga),
    takeLatest(allChildrenAttendancesFetching, fetchChildrenAttendancesSaga),
    takeLatest(fetchChildAttendances, fetchChildAttendancesSaga),
    takeLatest(fetchAttendancesDetails, fetchChildAttendanceDetailsSaga),
    takeLatest(createAttendance, createAttendanceSaga),
    takeLatest(createMultipleAttendances, createMultipleAttendancesSaga),
    takeLatest(patchAttendance, patchAttendanceSaga),
    takeLatest(deleteAttendance, deleteAttendanceSaga),
    takeLatest(fetchOpeningdays, fetchOpeningDaysSaga),
    takeLatest(fetchAllOpeningdays, fetchAllOpeningDaysSaga),
    takeLatest(fetchChildRegisterations, fetchChildRegisterationSaga),
    takeLatest(childProfileUpdating, updateChildProfile),
    takeLatest(childCreating, createChild),
    takeLatest(childPhotoUpdating, updateChildPhoto),
    takeLatest(downloadDocument, downloadDocumentSaga),
    takeLatest(deleteDocument, deleteDocumentSaga),
    takeLatest(fetchDocument, fetchDocumentSaga),
    takeLatest(createRecurrentAttendance, createRecurrentAttendanceSaga),
    takeLatest(fetchChildrengardeplanningdays, fetchChildrengardeplanningdaysSaga),
    takeLatest(createAttendanceByContainer, createAttendanceByContainerSaga),
    takeLatest(requestCredit, requestCreditSaga),
    takeLatest(cancelCreditRequest, cancelCreditRequestSaga),
  ]);
}

export default rootSaga;
