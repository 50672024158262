
import {
  handleActions,
} from '~/utils/redux-actions'

import {
  householdFetching,
  householdFetched,
  householdFetchError,

  // update household
  householdUpdating,
  householdUpdated,
  householdUpdateError,
  
  // fetch documents
  documentsFetching,
  documentsFetched,
  documentsFetchError,

  // fetch missing common documents
  missingCommonDocumentsFetching,
  missingCommonDocumentsFetched,
  missingCommonDocumentsFetchError,

  // add new document
  addNewDocument,
  addNewDocumentProcess,
  addNewDocumentSuccess,
  addNewDocumentError,
} from './actions'

import { logout } from '~/containers/User/actions'

const defaultState = {
  household: {
    data: [],
    loading: false,
    error: false,
    isUpdating: false,
  },
  documents: {
    data: [],
    missingCommonDocuments: [],
    loading: false,
    error: false,
    isUpdating: false,
    isUploading: false,
  }
}

export default handleActions(
  {
    // Logout
    [logout]: () => defaultState,

    // Household Fetching
    [householdFetching]: state => {
      state.household.data = []
      state.household.loading = true
      state.household.error = false
    },
    [householdFetched]: (state, { payload }) => {
      state.household.data = [...payload]
      state.household.loading = false
      state.household.error = false
    },
    [householdFetchError]: state => {
      state.household.data = []
      state.household.loading = false
      state.household.error = true
    },
    
    // Household Updating
    [householdUpdating]: state => {
      state.household.isUpdating = true
    },
    [householdUpdated]: (state) => {
      state.household.isUpdating = false
    },
    [householdUpdateError]: state => {
      state.household.isUpdating = false
    },

    // Documents Fetching
    [documentsFetching]: state => {
      // state.documents.data = []
      state.documents.loading = true
      state.documents.error = false
    },
    [documentsFetched]: (state, { payload }) => {
      state.documents.data = payload
      state.documents.loading = false
      state.documents.error = false
    },
    [documentsFetchError]: state => {
      state.documents.data = []
      state.documents.loading = false
      state.documents.error = true
    },

    // Missing Common Documents Fetching
    [missingCommonDocumentsFetching]: state => {
      state.documents.loading = true
      state.documents.error = false
    },
    [missingCommonDocumentsFetched]: (state, { payload }) => {
      state.documents.missingCommonDocuments = payload
      state.documents.loading = false
      state.documents.error = false
    },
    [missingCommonDocumentsFetchError]: state => {
      state.documents.missingCommonDocuments = []
      state.documents.loading = false
      state.documents.error = true
    },

    // Add new document
    [addNewDocument]: state => {
      state.documents.isUploading = true
    },
    [addNewDocumentProcess]: state => {
      state.documents.isUploading = true
    },
    [addNewDocumentSuccess]: (state, { payload }) => {
      // state.documents.data = [...state.documents.data, payload]
      state.documents.isUploading = false
    },
    [addNewDocumentError]: state => {
      state.documents.isUploading = false
    }
  },
  defaultState,
)
