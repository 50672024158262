import { createActions } from "redux-actions";

export const {
  fetchChildren,
  fetchChildrenProcess,
  fetchChildrenSuccess,
  fetchChildrenError,

  // Child details
  fetchChildDetails,
  fetchChildDetailsProcess,
  fetchChildDetailsSuccess,
  fetchChildDetailsError,

  // Selected Children details
  fetchSelectedChildrenDetails,
  fetchSelectedChildrenDetailsProcess,
  fetchSelectedChildrenDetailsSuccess,
  fetchSelectedChildrenDetailsError,

  // Children registrations
  fetchChildrenRegistrations,
  fetchChildrenRegistrationsProcess,
  fetchChildrenRegistrationsSuccess,
  fetchChildrenRegistrationsError,

  // child picture
  fetchChildPhoto,
  fetchChildPhotoSuccess,
  fetchChildPhotoProcess,
  fetchChildPhotoError,

  // child registeration
  fetchChildRegisterations,
  fetchChildRegisterationsSuccess,

  // fetch child attendence
  fetchChildAttendances,
  fetchChildAttendancesProcess,
  fetchChildAttendancesSuccess,
  fetchChildAttendancesError,

  // create attendance
  createAttendance,
  createAttendanceProcess,
  createAttendanceError,
  createAttendanceSuccess,

  // create multiple attendances
  createMultipleAttendances,
  createMultipleAttendancesProcess,
  createMultipleAttendancesError,
  createMultipleAttendancesSuccess,

  // update attendance
  patchAttendance,
  patchAttendanceSuccess,
  patchAttendanceError,
  patchAttendanceProcess,

  // delete attendance
  deleteAttendance,
  deleteAttendanceSuccess,
  deleteAttendanceError,
  deleteAttendanceProcess,

  // attencdance details
  fetchAttendancesDetails,
  fetchAttendancesDetailsProcess,
  fetchAttendancesDetailsSuccess,
  fetchAttendancesDetailsError,

  // fetch opening days
  fetchOpeningdays,
  fetchOpeningdaysProcess,
  fetchOpeningdaysError,
  fetchOpeningdaysSuccess,

  // fetch opening days
  fetchAllOpeningdays,
  fetchAllOpeningdaysProcess,
  fetchAllOpeningdaysError,
  fetchAllOpeningdaysSuccess,

  // fecth children planning garde
  fetchChildrengardeplanningdays,
  fetchChildrengardeplanningdaysProcess,
  fetchChildrengardeplanningdaysError,
  fetchChildrengardeplanningdaysSuccess,

  // fetch children attendance,
  allChildrenAttendancesFetch,
  allChildrenAttendancesFetching,
  allChildrenAttendancesFetched,
  allChildrenAttendancesFetchError,

  childProfileUpdating,
  childProfileUpdated,
  childProfileUpdateError,

  childCreating,
  childCreated,
  childCreateError,

  childPhotoUpdating,
  childPhotoUpdated,
  childPhotoUpdateError,

  // download user document
  downloadDocument,

  // delete user document
  deleteDocument,
  deleteDocumentSuccess,

  // fetch user document
  fetchDocument,
  fetchDocumentPending,
  fetchDocumentError,
  fetchDocumentSuccess,

  // create recurrent attendance
  createRecurrentAttendance,
  createRecurrentAttendanceProcess,
  createRecurrentAttendanceError,
  createRecurrentAttendanceSuccess,

  // create attendance by container
  createAttendanceByContainer,
  createAttendanceByContainerProcess,
  createAttendanceByContainerError,
  createAttendanceByContainerSuccess,

  // request credit
  requestCredit,
  requestCreditProcess,
  requestCreditError,
  requestCreditSuccess,

  // cancel credit request
  cancelCreditRequest,
  cancelCreditRequestProcess,
  cancelCreditRequestError,
  cancelCreditRequestSuccess,
} = createActions({
  FETCH_CHILDREN: () => {},
  FETCH_CHILDREN_PROCESS: () => {},
  FETCH_CHILDREN_SUCCESS: (children) => children,
  FETCH_CHILDREN_ERROR: () => {},

  // fetch Child Details
  FETCH_CHILD_DETAILS: (data, callback) => ({ data, callback }),
  FETCH_CHILD_DETAILS_PROCESS: () => {},
  FETCH_CHILD_DETAILS_ERROR: () => {},
  FETCH_CHILD_DETAILS_SUCCESS: (data) => data,

  // Selected Children details
  FETCH_SELECTED_CHILDREN_DETAILS: (data, callback) => ({ data, callback }),
  FETCH_SELECTED_CHILDREN_DETAILS_PROCESS: () => {},
  FETCH_SELECTED_CHILDREN_DETAILS_ERROR: () => {},
  FETCH_SELECTED_CHILDREN_DETAILS_SUCCESS: (children) => children,

  // Children registrations
  FETCH_CHILDREN_REGISTRATIONS: () => {},
  FETCH_CHILDREN_REGISTRATIONS_PROCESS: () => {},
  FETCH_CHILDREN_REGISTRATIONS_ERROR: () => {},
  FETCH_CHILDREN_REGISTRATIONS_SUCCESS: (children) => children,

  // fetch child public pic
  FETCH_CHILD_PHOTO: () => {},
  FETCH_CHILD_PHOTO_PROCESS: () => {},
  FETCH_CHILD_PHOTO_SUCCESS: (data) => data,
  FETCH_CHILD_PHOTO_ERROR: () => {},

  // child registeration
  FETCH_CHILD_REGISTERATIONS: (data) => data,
  FETCH_CHILD_REGISTERATIONS_SUCCESS: (data) => data,
  FETCH_CHILD_REGISTERATIONS_PROCESS: () => {},
  FETCH_CHILD_REGISTERATIONS_ERROR: () => {},

  FETCH_CHILD_ATTENDANCES: (data) => data,
  FETCH_CHILD_ATTENDANCES_PROCESS: () => {},
  FETCH_CHILD_ATTENDANCES_SUCCESS: (data) => data,
  FETCH_CHILD_ATTENDANCES_ERROR: (data) => data,

  FETCH_ATTENDANCES_DETAILS: (data) => data,
  FETCH_ATTENDANCE_DETAILS_PROCESS: () => {},
  FETCH_ATTENDANCE_DETAILS_SUCESS: (data) => data,
  FETCH_ATTENDANCE_DETAILS_ERROR: () => {},

  CREATE_ATTENDANCE: (data) => data,
  CREATE_ATTENDANCE_PROCESS: () => {},
  CREATE_ATTENDANCE_SUCCESS: (data) => data,
  CREATE_ATTENDANCE_ERROR: () => {},

  CREATE_MULTIPLE_ATTENDANCES: (data) => data,
  CREATE_MULTIPLE_ATTENDANCES_PROCESS: () => {},
  CREATE_MULTIPLE_ATTENDANCES_SUCCESS: (data) => data,
  CREATE_MULTIPLE_ATTENDANCES_ERROR: () => {},

  PATCH_ATTENDANCE: (data) => data,
  PATCH_ATTENDANCE_PROCESS: () => {},
  PATCH_ATTENDANCE_SUCCESS: (data) => data,
  PATCH_ATTENDANCE_ERROR: () => {},

  DELETE_ATTENDANCE: (data) => data,
  DELETE_ATTENDANCE_PROCESS: () => {},
  DELETE_ATTENDANCE_SUCCESS: (data) => data,
  DELETE_ATTENDANCE_ERROR: () => {},

  FETCH_OPENINGDAYS: (data) => data,
  FETCH_OPENINGDAYS_PROCESS: () => {},
  FETCH_OPENINGDAYS_SUCCESS: (data) => data,
  FETCH_OPENINGDAYS_ERROR: () => {},

  FETCH_ALL_OPENINGDAYS: (data) => data,
  FETCH_ALL_OPENINGDAYS_PROCESS: () => {},
  FETCH_ALL_OPENINGDAYS_SUCCESS: (data) => data,
  FETCH_ALL_OPENINGDAYS_ERROR: () => {},

  FETCH_CHILDRENGARDEPLANNINGDAYS: (data) => data,
  FETCH_CHILDRENGARDEPLANNINGDAYS_PROCESS: () => {},
  FETCH_CHILDRENGARDEPLANNINGDAYS_SUCCESS: (data) => data,
  FETCH_CHILDRENGARDEPLANNINGDAYS_ERROR: () => {},

  ALL_CHILDREN_ATTENDANCES_FETCH: (data) => data,
  ALL_CHILDREN_ATTENDANCES_FETCHING: (data) => data,
  ALL_CHILDREN_ATTENDANCES_FETCHED: (attendances) => attendances,
  ALL_CHILDREN_ATTENDANCES_FETCH_ERROR: () => ({}),

  CHILD_PROFILE_UPDATING: (childAlias, data, callback) => ({
    childAlias,
    data,
    callback,
  }),
  CHILD_PROFILE_UPDATED: (child) => child,
  CHILD_PROFILE_UPDATE_ERROR: () => ({}),

  CHILD_CREATING: (child, photo, callback) => ({
    child,
    photo,
    callback,
  }),
  CHILD_CREATED: () => ({}),
  CHILD_CREATE_ERROR: () => ({}),

  CHILD_PHOTO_UPDATING: (childAlias, photo, callback) => ({
    childAlias,
    photo,
    callback,
  }),
  CHILD_PHOTO_UPDATED: () => ({}),
  CHILD_PHOTO_UPDATE_ERROR: () => ({}),

  // download user Document
  DOWNLOAD_DOCUMENT: (data) => data,

  // delete user document
  DELETE_DOCUMENT: (data) => data,
  DELETE_DOCUMENT_SUCCESS: () => {},
  DELETE_DOCUMENT_ERROR: () => {},

  // fetch user Document
  FETCH_DOCUMENT: (data) => data,
  FETCH_DOCUMENT_PENDING: () => {},
  FETCH_DOCUMENT_ERROR: () => {},
  FETCH_DOCUMENT_SUCCESS: (data) => data,

  // 
  CREATE_RECURRENT_ATTENDANCE: (data) => data,
  CREATE_RECURRENT_ATTENDANCE_PROCESS: () => {},
  CREATE_RECURRENT_ATTENDANCE_SUCCESS: (data) => data,
  CREATE_RECURRENT_ATTENDANCE_ERROR: () => {},

  // create attendances by container
  CREATE_ATTENDANCE_BY_CONTAINER: (data) => data,
  CREATE_ATTENDANCE_BY_CONTAINER_PROCESS: () => {},
  CREATE_ATTENDANCE_BY_CONTAINER_SUCCESS: (data) => data,
  CREATE_ATTENDANCE_BY_CONTAINER_ERROR: () => {},

  // request credit
  REQUEST_CREDIT: (data) => data,
  REQUEST_CREDIT_PROCESS: () => {},
  REQUEST_CREDIT_SUCCESS: (data) => data,
  REQUEST_CREDIT_ERROR: () => {},

  // cancel credit request
  CANCEL_CREDIT_REQUEST: (data) => data,
  CANCEL_CREDIT_REQUEST_PROCESS: () => {},
  CANCEL_CREDIT_REQUEST_SUCCESS: (data) => data,
  CANCEL_CREDIT_REQUEST_ERROR: () => {},
});
