import React, { useCallback, useMemo, useState } from 'react';
import { CheckCircleFilled, FileImageOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Form, Space, Upload, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegistrationsDetails, linkDocumentToRegistration, registrationDetailsFetching } from '~/containers/Registration/actions';
import { addNewDocument, documentsFetching } from '~/containers/Foyer/actions';
import { useEffect } from 'react';

const SelectableDocument = ({ document, setDocumentToLink, selected }) => {
  // const fileName = document?.file_url?.split('/').pop()
  const fileName = document?.name;
  const fileSize = document?.file_size_label;
  // const fileExtension = document?.file_url?.split(".")?.pop();

  return (
    <Checkbox
      style={{ marginBottom: 16, display: "flex" }}
      checked={selected}
      onChange={e => setDocumentToLink(e.target.checked ? document.alias : null)}
    >
      <div>
        <p>
          {document.name}
        </p>
        <a href={document?.file_url + "?show_from_mobile=true"} target="_blank">
          <LinkOutlined /> {fileName} ({fileSize})
        </a>
        {/* <Space>
          <Form.Item
            label="Date d’échéance"
            name="due_date"
          // rules={[
          //   {
          //     required: !noDueDate,
          //     message: "Veuillez selectionner un statut",
          //   },
          // ]}
          >
            <DatePicker style={{ width: "100%" }} disabled />
          </Form.Item>
          <Form.Item style={{ marginTop: 32 }}>
            <Button
              disabled
            >
              Aucune
            </Button>
          </Form.Item>
        </Space> */}
      </div>
    </Checkbox>
  );
};

export const AddDocument = ({
  documentType,
  documentsOfType,
  setAddExistingDocument,
  addExistingDocument,
  selectedChildRegistration,
  allRegistrations,
  organisationAlias,
  handleCloseDrawer,
  chosenChildrenAliases,
  allChildren,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {linkingDocumentToRegistration} = useSelector(state => state.registration);
  const {isUploading} = useSelector((state) => state.household.documents);
  const [documentToLink, setDocumentToLink] = useState(null);
  const [file, setFile] = useState(null);

  const currentOrganisationAlias = organisationAlias || allRegistrations?.[0]?.organisationAlias;
  const childAlias = selectedChildRegistration?.child?.alias || allRegistrations?.[0]?.child?.alias;
  const registrationAlias = selectedChildRegistration?.alias || allRegistrations?.[0]?.alias;

  const handleLinkDocument = useCallback(() => {
    console.log({ documentToLink, registrationAlias, childAlias });

    if (!documentType?.is_common) {
      dispatch(linkDocumentToRegistration({
        document_alias: documentToLink,
        registration_alias: registrationAlias,
        callback: () => {
          message.success('Document lié avec succès');
          dispatch(
            registrationDetailsFetching(
              childAlias,
              registrationAlias,
            )
          );

          if (currentOrganisationAlias) {
            dispatch(
              fetchRegistrationsDetails(
                currentOrganisationAlias,
                chosenChildrenAliases,
              )
            );
          }
          handleCloseDrawer();
        }
      }));
    } else {
      dispatch(linkDocumentToRegistration({
        document_alias: documentToLink,
        organisation_alias: currentOrganisationAlias,
        callback: () => {
          message.success('Document lié avec succès');

          if (currentOrganisationAlias) {
            dispatch(
              fetchRegistrationsDetails(
                currentOrganisationAlias,
                chosenChildrenAliases,
              )
            );
          }

          dispatch(
            registrationDetailsFetching(
              childAlias,
              registrationAlias,
            )
          );

          handleCloseDrawer();
        }
      }));
    }

    dispatch(
      documentsFetching()
    );
  }, [documentType, documentToLink, registrationAlias, allRegistrations, currentOrganisationAlias, childAlias, handleCloseDrawer, chosenChildrenAliases]);

  const uploadProps = {
    fileList: [],
    beforeUpload: file => {
      if (!['application/pdf', 'image/jpeg'].includes(file.type)) {
        message.error(`${file.name} n'est pas un fichier jpg`);
      }
      return ['application/pdf', 'image/jpeg'].includes(file.type);
    },
    customRequest: async (e) => {
      setFile(e.file);
    }
  };

  const onFinish = (values) => {
    console.log({values, documentType});

    const file = values.file.file;
    console.log({values, file});
    if (!['image/jpeg', 'application/pdf'].includes(file.type)) {
      message.error(`${file.name} n'est pas un fichier jpg`);
      return ['image/jpeg', 'application/pdf'].includes(file.type);
    }
    
    const formData = new FormData();
    formData.append('file', file.originFileObj);
    formData.append('name', file.name);
    formData.append('type', documentType?.number);
    formData.append('shared_with_other_users', !!documentType?.is_common);
    formData.append('merge', false);
    // if (!noDueDate) {
      //   formData.append('due_date', dayjs(values?.due_date).format('YYYY-MM-DD'))
      // }

    if (childAlias) {
      formData.append('child_alias', childAlias);
    }
    // if (!noDueDate) {
    //   formData.append('due_date', dayjs(values?.due_date).format('YYYY-MM-DD'))
    // }
    if (registrationAlias) {
      formData.append('registration_alias', registrationAlias);
    }

    formData.append('organisation_alias', organisationAlias);
    // if (!documentType?.is_common) {
    //   if (childAlias) {
    //     formData.append('child_alias', childAlias);
    //   }
    //   if (registrationAlias) {
    //     formData.append('registration_alias', registrationAlias);
    //   }
    // }

    // if (currentOrganisationAlias) {
    //   formData.append('organisation_alias', currentOrganisationAlias);
    // }

    setFile(file.originFileObj);

    if (['image/jpeg', 'application/pdf'].includes(file.type)) {
      dispatch(
        addNewDocument({
          formData,
          childAlias: childAlias,
          onSuccess: () => {
            message.success('Document ajouté avec succès');

            dispatch(
              documentsFetching()
            );

            dispatch(
              registrationDetailsFetching(
                childAlias,
                registrationAlias,
              )
            );

            if (currentOrganisationAlias) {
              dispatch(
                fetchRegistrationsDetails(
                  currentOrganisationAlias,
                  chosenChildrenAliases,
                )
              );
            }
            handleCloseDrawer();
          },
        })
      );
    }
  };

  const filteredDocumentsOfType = useMemo(() => documentsOfType?.filter(doc => {
    if (
      (documentType?.is_common)
      // && allChildren?.map(ch => ch?.alias)?.includes(doc?.child_alias)
    ) {
      return true;
    } else if (childAlias && (doc?.child_alias === childAlias || doc?.children_aliases?.includes(childAlias))) {
      return true;
    }

    return false;
  }), [documentType, documentsOfType, childAlias, allChildren]);

  const childDocumentsOfType = useMemo(() => {
    if (!documentsOfType || !childAlias || !addExistingDocument) {
      return null;
    }

    return documentsOfType.filter(d => d.children_aliases?.includes(childAlias));
  }, [documentsOfType, childAlias, addExistingDocument]);

  console.log({ chosenChildrenAliases, documentToLink, allChildren, currentOrganisationAlias, documentsOfType, childDocumentsOfType, filteredDocumentsOfType, linkingDocumentToRegistration, selectedChildRegistration, allRegistrations, currentOrganisationAlias, registrationAlias, childAlias });

  useEffect(() => {
    if (filteredDocumentsOfType?.length === 0 || !filteredDocumentsOfType) {
      setAddExistingDocument(false);
    } else {
      setAddExistingDocument(true);
    }
  }, [filteredDocumentsOfType]);

  if (addExistingDocument) {
    return (
      <div>
        {filteredDocumentsOfType?.map(document => (
          <SelectableDocument
            key={document.alias}
            document={document}
            setDocumentToLink={setDocumentToLink}
            selected={documentToLink === document.alias || (!documentToLink && document?.children_aliases?.includes(childAlias) && document?.registration_aliases?.includes(registrationAlias))}
          />
        ))}
        <Space
          direction="vertical"
          style={{ width: "100%", marginTop: 32 }}
        >
          <Button
            type="primary"
            onClick={handleLinkDocument}
            block
            size="large"
            disabled={!documentToLink}
            loading={linkingDocumentToRegistration}
          >
            Valider
          </Button>
          <Button
            // type="primary"
            onClick={() => setAddExistingDocument(false)}
            block
            size="large"
          >
            Transmettre un nouveau document
          </Button>
        </Space>
      </div>
    );
  }

  return (
    <Form
      layout="vertical"
      size="large"
      form={form}
      onFinish={onFinish}
    >
      {/* <ErrorComponent /> */}
      <Form.Item
        label={`${documentType?.description} (.jpg ou pdf maximum 1mb) *`}
        rules={[
          {
            required: true,
            message: "Ce champ ne peut être vide"
          }
        ]}
        name="file"
      >
        <Upload
          {...uploadProps}
        >
          <Space style={{ paddingTop: 5 }}>
            <Button
              size="large"
              icon={<FileImageOutlined />}
            >
              Choisir un document
            </Button>
            {!!file && (
              <CheckCircleFilled style={{ color: '#52C41A', fontSize: 24 }} />
            )}
          </Space>
        </Upload>
      </Form.Item>
      {/* <Space>
        <Form.Item
          label="Date d’échéance"
          name="due_date"
        >
          <DatePicker
            placeholder="Sélectionner"
            picker="date"
            // defaultPickerValue={defaultDate}
            format="DD/MM/YYYY"
            style={{ width: 280 }}
            disabled
          />
        </Form.Item>
        <Button
          disabled
          style={{ marginTop: 11 }}
        >
          Aucune
        </Button>
      </Space> */}
      <Button
        type="primary"
        htmlType="submit"
        size="large"
        style={{ width: "100%", marginTop: 20, marginBottom: 50 }}
        loading={isUploading}
      >
        Valider
      </Button>
    </Form>
  );
};
