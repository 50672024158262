import styled from "styled-components"

const Title = styled.h1`
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
`

export default Title
