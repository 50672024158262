import React, { useCallback, useEffect, useRef, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Drawer from "~/components/common/Drawer";
import { Row, Col, Space } from "antd";
import {
  Form,
  Select,
  Input,
  Radio,
  Switch,
  InputNumber,
  Divider,
  Button,
  Upload,
  message,
  DatePicker,
  ConfigProvider,
} from "antd";
import Gender from "~/components/common/Gender";
import CustomProfilAvatar from "~/components/common/CustomProfilAvatar";
import Search from "~/components/common/Search";
import Loading from '~/components/common/Loading';

import { childStatus, parentMaritalStatus, allergies } from "~/constants";

import useErrors from "~/hooks/useErrors";
import Checkbox from "antd/lib/checkbox/Checkbox";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import locale from "antd/lib/locale/fr_FR";
import { StyledDay } from "~/containers/Children/Activity/component";
import { useDispatch, useSelector, useStore } from "react-redux";
import { allChildrenAttendancesFetching, createMultipleAttendances, createRecurrentAttendance, deleteAttendance, fetchChildAttendances } from "~/containers/Children/actions";
import mainAxios from "~/lib/axios";
import { getAllMondaysInDateRange } from "~/utils/getAllMondaysInDateRange";
import { element } from "prop-types";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

const EditActivity = ({
  data,
  showDrawer,
  setShowDrawer,
  organisationsActivitiesData,
  children,
  currentChild,
  activitiesBillingModel,
  addPaidAttendance,
  selectedMonth,
  removePaidAttendance,
  orders,
  allActivityAttendances,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedChildren, setSelectedChildren] = useState([]);
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [isPeriod, setIsPeriod] = useState(false);
  const [replaceOldSubscriptions, setReplaceOldSubscriptions] = useState(true);
  const [checkedDays, setCheckedDays] = useState([]);
  const initialValues = useRef(null);

  const userEmail = useSelector((state) => state.user.auth.user.email);

  const isCreatingReccurence = useSelector(
    (state) => state.children.attendance.isCreating
  );

  const organisationActivities = organisationsActivitiesData?.[data?.organisation_alias];
  const orderAlias = orders?.find(order => order.organisation_alias === data?.organisation_alias);
  const activityDetails = organisationActivities?.find(activity => activity?.actAlias === data?.parent_activity_alias);
  const periodSelection = activityDetails?.period_selection;
  const listOfChildren = children
    ?.filter(child => !!child.subscriptions.find(sub => sub?.parent_activity_alias === data?.parent_activity_alias))
    ?.map(child => ({
      label: `${child?.first_name} ${child?.last_name}`,
      value: child?.alias,
    }));

  const listOfWeeks = getAllMondaysInDateRange(activityDetails?.update_after, activityDetails?.end_date).reduce((acc, date) => {
    let datesOfWeek = '';

    if (activityDetails?.opening_days?.includes(date)) {
      datesOfWeek = date;
    }
    if (activityDetails?.opening_days?.includes(dayjs(date).add(1, 'days').format('YYYY-MM-DD'))) {
      datesOfWeek = `${datesOfWeek}__${dayjs(date).add(1, 'days').format('YYYY-MM-DD')}`;
    }
    if (activityDetails?.opening_days?.includes(dayjs(date).add(2, 'days').format('YYYY-MM-DD'))) {
      datesOfWeek = `${datesOfWeek}__${dayjs(date).add(2, 'days').format('YYYY-MM-DD')}`;
    }
    if (activityDetails?.opening_days?.includes(dayjs(date).add(3, 'days').format('YYYY-MM-DD'))) {
      datesOfWeek = `${datesOfWeek}__${dayjs(date).add(3, 'days').format('YYYY-MM-DD')}`;
    }
    if (activityDetails?.opening_days?.includes(dayjs(date).add(4, 'days').format('YYYY-MM-DD'))) {
      datesOfWeek = `${datesOfWeek}__${dayjs(date).add(4, 'days').format('YYYY-MM-DD')}`;
    }
    if (activityDetails?.opening_days?.includes(dayjs(date).add(5, 'days').format('YYYY-MM-DD'))) {
      datesOfWeek = `${datesOfWeek}__${dayjs(date).add(5, 'days').format('YYYY-MM-DD')}`;
    }
    if (activityDetails?.opening_days?.includes(dayjs(date).add(6, 'days').format('YYYY-MM-DD'))) {
      datesOfWeek = `${datesOfWeek}__${dayjs(date).add(6, 'days').format('YYYY-MM-DD')}`;
    }

    const splittedWeek = datesOfWeek.split('__');
    const from = dayjs(splittedWeek?.[0]).format('DD/MM');
    const to = dayjs(splittedWeek?.[splittedWeek.length - 1]).format('DD/MM');

    if (datesOfWeek !== '') {
      acc = [
        ...acc,
        {
          label: `du ${from} au ${to}`,
          value: datesOfWeek,
        }
      ];
    }

    return acc;
  }, []);

  const daysToWeeks = listOfWeeks?.map(item => item?.value);
  const isPrepaid = activitiesBillingModel?.[data?.organisation_alias]?.[activityDetails?.actAlias] === 2;

  useEffect(() => {
    if (currentChild?.alias) {
      setSelectedChildren([currentChild?.alias]);
    } else if (currentChild?.first_name === 'Tous') {
      
      const activityChildren = children
        ?.filter(child => child.subscriptions?.find(sub => sub.parent_activity_alias === activityDetails?.actAlias))
        ?.map(child => child?.alias);
      setSelectedChildren(activityChildren);
    }
  }, [currentChild?.first_name, children, activityDetails]);

  initialValues.current = {
    children: selectedChildren,
    dates: [],
  };

  form.setFieldsValue({
    children: selectedChildren,
  });

  const getDaysBetweenDates = (startDate, endDate) => {
    const now = startDate.clone();
    const dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('YYYY-MM-DD'));
        now.add(1, 'days');
    }
    return dates;
  };

  const onFinish = async (values) => {

    // const startDate = isPeriod ? values?.dates?.[0] : dayjs(activityDetails?.update_after)
    // const endDate = isPeriod ? values?.dates?.[1] : dayjs(activityDetails?.opening_days?.[activityDetails?.opening_days.length - 1])
    const selectionByWeek = periodSelection === 1;

    // const weeksToDaysList = values?.weeks?.reduce((acc, cur) => {
    //   const splittedWeek = cur?.split('__')?.filter((date) => !!date)
    //   acc = [...acc, ...splittedWeek]

    //   return acc
    // }, [])
    
    // const weekActivityAlias = data.openingDays.find(openingDay => dayjs(dateList?.[0])?.isSame(dayjs(openingDay?.date, 'YYYY-MM-DD')))?.activity
    const days = selectionByWeek
      ? []
      : checkedDays;

      console.log({data, cscs: values?.weeks});

    if (selectionByWeek) {
      const dates = values?.weeks?.reduce((acc, week) => {
        acc = [...acc, ...week?.split('__')?.filter((date) => !!date)];

        return acc;
      }, []);

      const weekActivityAlias = data.openingDays.find(openingDay => dayjs(dates?.[0])?.isSame(dayjs(openingDay?.date, 'YYYY-MM-DD')))?.activity;

      console.log({weekActivityAlias, dates});
      const recurrencedata = {
        children: initialValues.current.children,
        activity_alias: weekActivityAlias,
        from_now: false,
        days,
        dates,
        replace_existing: replaceOldSubscriptions,
      };
    
      dispatch(
        createRecurrentAttendance({
          data: recurrencedata,
          month: dayjs(selectedMonth).format("M"),
          callback: () => {
            form.resetFields();
            // setSelectedChildren([])
            setSelectedWeeks([]);
            setCheckedDays([]);
            setShowDrawer(false);
            // if (!isPeriod && (activityDetails?.update_after !== activityDetails?.opening_days?.[0])) {
            //   message.info("Certaines réservations n'ont pas été prises en compte, car elles sont hors délai.")
            // }
          },
        })
      );
    } else {
      const recurrencedata = {
        children: initialValues.current.children,
        activity_alias: data?.activity_alias,
        from_now: !isPeriod,
        days,
        replace_existing: replaceOldSubscriptions,
      };
  
      if (isPeriod) {
        recurrencedata.dates = [
          dayjs(values?.dates?.[0]).format('YYYY-MM-DD'),
          dayjs(values?.dates?.[1]).format('YYYY-MM-DD'),
        ];
      }
  
      dispatch(
        createRecurrentAttendance({
          data: recurrencedata,
          month: dayjs(selectedMonth).format("M"),
          callback: () => {
            form.resetFields();
            // setSelectedChildren([])
            setSelectedWeeks([]);
            setCheckedDays([]);
            setShowDrawer(false);
            // if (!isPeriod && (activityDetails?.update_after !== activityDetails?.opening_days?.[0])) {
            //   message.info("Certaines réservations n'ont pas été prises en compte, car elles sont hors délai.")
            // }
          },
        })
      );
    }
  };

  const handleCheckDay = (state, day) => {
    if (checkedDays.find(d => d === day)) {
      return state.filter(d => d !== day);
    }
    
    return [...state, day];
  };
  
  const handleCloseDrawer = useCallback(() => {
    setShowDrawer(false);
    form.resetFields();
    setSelectedChildren([]);
    setSelectedWeeks([]);
    setCheckedDays([]);
  }, []);

  return (
    <Drawer
      title="Récurrence de la réservation"
      open={showDrawer}
      handleCloseDrawer={handleCloseDrawer}
      width={636}
      destroyOnClose
    >
      {isCreatingReccurence ? (
        <Loading text="Réservations en cours ..." />
      ) : (
        <>
          <h3>
            <ExclamationCircleOutlined
              style={{ fontSize: 20, paddingRight: 10, color: "#F4C26D" }}
            />{" "}
            {data?.organisation_name} / {data?.parent_activity_name}
          </h3>
          <Form
            layout="vertical"
            size="large"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues.current}
          >
            <Form.Item
              label="Enfant(s)"
              name="children"
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner un(des) enfant(s)",
                },
              ]}
            >
              <Search
                placeholder="Rechercher..."
                options={listOfChildren}
                mode="multiple"
                defaultValue={selectedChildren}
                value={selectedChildren}
                onChange={setSelectedChildren}
              />
            </Form.Item>
            {periodSelection === 1 ? (
              <Form.Item
                label="Semaine(s)"
                name="weeks"
                rules={[
                  {
                    required: true,
                    message: "Veuillez renseigner des semaines",
                  },
                ]}
              >
                <Search
                  placeholder="Rechercher..."
                  options={listOfWeeks}
                  mode="multiple"
                  defaultValue={selectedWeeks}
                  value={selectedWeeks}
                  onChange={setSelectedWeeks}
                />
              </Form.Item>
            ) : (
              <>
                <Form.Item>
                  <Space direction="horizontal">
                    <Switch
                      checked={!isPeriod}
                      onChange={val => setIsPeriod(!val)}
                    /> 
                    <span>A partir de maintenant</span>
                  </Space>
                </Form.Item>
                <Form.Item>
                  <Space direction="horizontal">
                    <Switch
                      checked={isPeriod}
                      onChange={val => setIsPeriod(val)}
                    /> 
                    <span>Choisir une période</span>
                  </Space>
                </Form.Item>
                {isPeriod && (
                  <Form.Item
                    label="Période"
                    name="dates"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez renseigner une periode",
                      },
                    ]}
                  >
           
                      <RangePicker
                        disabledDate={current => current <= dayjs(activityDetails?.update_after) || current > dayjs(activityDetails?.opening_days?.[activityDetails?.opening_days.length - 1]).add(1, 'd')}
                        format="DD/MM/YYYY"
                      />
                  </Form.Item>
                )}
                <Form.Item
                  label="Jours de présence"
                >
                  <Space>
                    {activityDetails?.on_monday && (
                      <StyledDay
                        isSelected={checkedDays.find(d => d === 'on_monday')}
                        onClick={() => activityDetails?.on_monday && setCheckedDays(state => handleCheckDay(state, 'on_monday'))}
                        isDisabled={!activityDetails?.on_monday}
                      >
                        Lundi
                      </StyledDay>
                    )}
                    {activityDetails?.on_tuesday && (
                      <StyledDay
                        isSelected={checkedDays.find(d => d === 'on_tuesday')}
                        onClick={() => activityDetails?.on_tuesday && setCheckedDays(state => handleCheckDay(state, 'on_tuesday'))}
                        isDisabled={!activityDetails?.on_tuesday}
                      >
                        Mardi
                      </StyledDay>
                    )}
                    {activityDetails?.on_wednesday && (
                      <StyledDay
                        isSelected={checkedDays.find(d => d === 'on_wednesday')}
                        onClick={() => activityDetails?.on_wednesday && setCheckedDays(state => handleCheckDay(state, 'on_wednesday'))}
                        isDisabled={!activityDetails?.on_wednesday}
                      >
                        Mercredi
                      </StyledDay>
                    )}
                    {activityDetails?.on_thursday && (
                      <StyledDay
                        isSelected={checkedDays.find(d => d === 'on_thursday')}
                        onClick={() => activityDetails?.on_thursday && setCheckedDays(state => handleCheckDay(state, 'on_thursday'))}
                        isDisabled={!activityDetails?.on_thursday}
                      >
                        Jeudi
                      </StyledDay>
                    )}
                    {activityDetails?.on_friday && (
                      <StyledDay
                        isSelected={checkedDays.find(d => d === 'on_friday')}
                        onClick={() => activityDetails?.on_friday && setCheckedDays(state => handleCheckDay(state, 'on_friday'))}
                        isDisabled={!activityDetails?.on_friday}
                      >
                        Vendredi
                      </StyledDay>
                    )}
                    {activityDetails?.on_saturday && (
                      <StyledDay
                        isSelected={checkedDays.find(d => d === 'on_saturday')}
                        onClick={() => activityDetails?.on_saturday && setCheckedDays(state => handleCheckDay(state, 'on_saturday'))}
                        isDisabled={!activityDetails?.on_saturday}
                      >
                        Samedi
                      </StyledDay>
                    )}
                    {activityDetails?.on_sunday && (
                      <StyledDay
                        isSelected={checkedDays.find(d => d === 'on_sunday')}
                        onClick={() => activityDetails?.on_sunday && setCheckedDays(state => handleCheckDay(state, 'on_sunday'))}
                        isDisabled={!activityDetails?.on_sunday}
                      >
                        Dimanche
                      </StyledDay>
                    )}
                  </Space>
                </Form.Item>
              </>
            )}
            <Form.Item>
              <Space direction="horizontal">
                <Switch
                  checked={!replaceOldSubscriptions}
                  onChange={val => setReplaceOldSubscriptions(!val)}
                /> 
                <span>Ajouter les nouvelles réservations et garder les existantes</span>
              </Space>
            </Form.Item>
            <Form.Item>
              <Space direction="horizontal">
                <Switch
                  checked={replaceOldSubscriptions}
                  onChange={val => setReplaceOldSubscriptions(val)}
                /> 
                <span>Remplacer les réservations existantes (sauf celles déjà facturées)</span>
              </Space>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{ width: "100%", marginTop: 20, marginBottom: 50 }}
                // onClick={() => setShowDrawer(false)}
              >
                Valider les réservations
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </Drawer>
  );
};

export default EditActivity;
