
import { handleActions } from '~/utils/redux-actions'
import {
  fetchSepaProcess,
  fetchSepaSuccess,
  fetchSepaError,

  fetchOrgsProcess,
  fetchOrgsSuccess,
  fetchOrgsError,

  //~ // Creating contact
  //~ createContact,
  //~ createContactProcess,
  //~ createContactSuccess,
  //~ createContactError,

  //~ // Creating contact
  //~ deleteContact,
  //~ deleteContactProcess,
  //~ deleteContactSuccess,
  //~ deleteContactError,
} from './actions'

import { logout } from '~/containers/User/actions'

const defaultState = {
  isFetchingSepa: false,
  fetchError: false,
  fetchSuccess: false,

  isFetchingOrgs: false,
  fetchError: false,
  fetchSuccess: false,

  creatingError: false,
  isCreating: false,

  orgs: [],
}

const OrgsReducer = handleActions(
  {
    // Logout
    [logout]: () => defaultState,

    [fetchOrgsProcess]: state => {
      state.isFetchingOrgs = true
      state.fetchError = false
      state.fetchSuccess = false
    },
    [fetchOrgsError]: state => {
      state.isFetchingOrgs = false
      state.fetchError = true
      state.fetchSuccess = false
    },
    [fetchOrgsSuccess]: (state, { payload }) => {
      state.isFetchingOrgs = false
      state.fetchError = false
      state.fetchSuccess = true
      state.orgs = payload
	
    },
  },
  defaultState,
)

//~ export default SepaReducer
//~ export {SepaReducer, OrgsReducer}
export default OrgsReducer
//~ export default 