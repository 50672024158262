import React from "react"
import { Img, CustomSkeleton } from "./components"
import useImage from "~/hooks/useImage"

const Picture = ({ src, alt, width, ...props }) => {
  // const imgSrc = useImage(src)
  const imgSrc = src

  if (imgSrc) return <Img alt={alt} src={imgSrc} width={width} {...props} />

  if (!src) {
    return (
      <CustomSkeleton
        title={false}
        avatar={{
          size: parseInt(width, 10),
        }}
      />
    )
  }
  if (src || !imgSrc) {
    return (
      <CustomSkeleton
        active
        title={false}
        avatar={{
          size: parseInt(width, 10),
        }}
      />
    )
  }
}

export default Picture
