import React, { useCallback, useState, useEffect } from "react";
import {
  LeftOutlined,
  RightOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import dayjs from "dayjs";
import Space from "~/components/common/Space";
import H1 from "~/components/common/H1";
import { getCurrentMonthDates, firstAndLastDayOfMonth } from "./functions";
// import { data } from "./data";
import Drawer from "~/components/common/Drawer";
import { Modal, message, Button, Avatar, Collapse, Tooltip , Col, Divider, Row} from "antd";
import groupBy from "lodash/groupBy";

import {
  fetchChildren,
  fetchChildDetails,
  fetchSelectedChildrenDetails,
  requestCredit,
  cancelCreditRequest,
} from "~/containers/Children/actions";
import {
  Calendar,
  Horizontal,
  Border,
  DayContainer,
  DayLetter,
  DayNumber,
  Day,
  OrgContainer,
  ActivityWrapper,
  ActivityContainer,
  ActivityName,
  Case,
  Draw,
  Box,
  Line,
  Recurrent,
  Description,
  DescriptionContainer,
  DescriptionContainerBottom,
  Dot,
  DynamicPadding,
  DescWrapper,
  OrgActivitiesContainer,
  BadgePill,
} from "./components";
import {
  fetchChildAttendances,
  fetchOpeningdays,
  fetchChildrengardeplanningdays,
  fetchAllOpeningdays,
  allChildrenAttendancesFetch,
  allChildrenAttendancesFetching,
  patchAttendance,
  deleteAttendance,
  createAttendance,
} from "~/containers/Children/actions";
import { colors } from "~/utils/colors";
import { select } from "@redux-saga/core/effects";
import H2 from "~/components/common/H2";
import Form from "antd/lib/form/Form";
import EditActivity from "~/containers/Children/Activity/EditActivity";
import SubActivity from "~/containers/Children/Activity/SubActivity";
import ActivityDetail from "~/containers/Children/Activity/ActivityDetail";
import manageSelectedCell from "~/utils/manageSelectedCell";
import cellWrapper from "~/utils/cellWrapper";
import { organisationsActivitiesFetching } from "~/containers/Subscription/actions";
import { LoadingWrapper } from "~/containers/Children/Activity/component";
import Loading from "~/components/common/Loading";
import { userOrganisationsFetching } from "~/containers/Home/actions";
import {
  addPaidAttendance,
  removePaidAttendance,
  massDeleteAttendances,
} from "~/containers/Cart/actions";
import { 
  getActivitiesPeriodSelection,
  getOpeningPeriodSelectionLabel,
  getConfirmPeriodReservationLabel,
  getBadgeColor,
  getActivitiesPeriodSelectionActive 
} from "~/constants";
import { getContainerLabel } from "~/containers/Children/Activity/functions"; 
import isBoolean from "lodash/isBoolean";

const { confirm } = Modal;
const { Panel } = Collapse;

const ActivityCalendar = ({
  selectedMonth,
  setSelectedMonth,
  showActivityDetails,
  setShowActivityDetails,
  hideFinishedActivities,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const currentMonth = dayjs().format("MMMM YYYY");
  const currentMonthDates = getCurrentMonthDates(selectedMonth);
  const [selectedCell, setSelectedCell] = useState(null);
  const [isHover, setIsHover] = useState(null);
  const [ isHovered, setIsHovered] = useState(false);
  const [hoveredCellContainerLabel, setHoveredCellContainerLabel] = useState("");
  const [selectedChild, setSelectedChild] = useState({});
  const [markedDates, setMarkedDates] = useState({});
  const [showDrawer, setShowDrawer] = useState(false);
  const [showSubDrawer, setShowSubDrawer] = useState(false);
  const [recu, setRecu] = useState(null);
  // const [showDetail, setShowActivityDetails] = useState(false);
  const [activityDetails, setActivityDetails] = useState(null);

  const { isCreating, isFetching, isPatching, allfetchingOpeningDays } =
    useSelector((state) => state.children.attendance);

  const { data: orders } = useSelector((state) => state.cart.orders);
  const { isAddingAttendance, isRemovingAttendance } = useSelector((state) => state.cart);

  const {
    // childDetails: child,
    isFetchingChildDetails,
    children,
    isFetching: isFetchingChildren,
    attendance,
    selectedChildrenDetails,
  } = useSelector((state) => state.children);

  //

  // const { registrations, subscriptions } = child;

  useEffect(() => {
    //
    dispatch(fetchChildren());
    if (params.id) {
      // dispatch(childRegistrationFetching(params.id));
      const thisChild = children.find((ch) => ch.alias === params.id);
      dispatch(fetchChildDetails({ url: thisChild?.url }));
    }
  }, [params.id]);

  useEffect(() => {
    // if (!isCreating && !isPatching) {

    // if (!isFetchingChildDetails) {
      fetchAttendances();
      // fetchAttendances2();
    // }
    // }
  }, [
    // child,
    // subscriptions,
    // isFetchingChildren,
    selectedMonth,
  ]);

  useEffect(() => {
    if (children.length > 0) {
      const childrenDetailsUrls = children.map((child) => child.url);

      dispatch(
        fetchSelectedChildrenDetails(
          childrenDetailsUrls
          // () => {}
        )
      );
    }
  }, [children.length]);

  const allAttendances = useSelector(
    (state) => state.children.attendance.allChildrenAttendances
  );

  //
  //

  const openingDays = useSelector(
    (state) => state.children.attendance.allOpeningDays
  );

  const gardeplaningDays = useSelector(
    (state) => state.children.childrengardeplanningdays
  );
  
  //check si un planning garde existe 
  let child_a = []
  let pgd_exist;
  children.map((child) =>
    child_a.push(child['alias'])
  )
  child_a.map((cha) =>
    {if (gardeplaningDays[cha]){
      pgd_exist = gardeplaningDays[cha].some(obj => obj.hasOwnProperty('garde_type'))
      
    }})

  const {
    organisationsActivities: {
      data: organisationsActivitiesData,
      isLoading: isLoadingOrganisationsActivitiesData,
    },
  } = useSelector((state) => state.subscription);

  const { userOrganisationsList } = useSelector(
    (state) => state.home.organisation
  );
  // useEffect(() => {
  //   if (!selectedCell) return;
  //

  //   const day = selectedCell.day;
  //   const eventExists = !!selectedCell.openDay.find(
  //     (d) => d.date === day.format("YYYY-MM-DD")
  //   );

  //   const selectedDay = selectedCell.attendance;

  //   const childAlias = child.alias;
  //   const activityAlias = selectedCell.sub.parent_activity_alias;
  //   const alias = selectedCell.sub.activity_alias;
  //   const activityName = selectedCell.sub.parent_activity_name;

  //   if (!eventExists) {
  //     return;
  //   }

  //   if (dayjs(day.format("YYYY-MM-DD")).isBefore(dayjs(), "day")) {
  //     if (selectedDay && selectedDay.was_there === true) {
  //       message.success(`${child.first_name} était présent(e) ce jour-là`);
  //     } else if (selectedDay && selectedDay.is_listed === true) {
  //       message.warning(
  //         `${child.first_name} était inscrit(e), mais noté absent ce jour-là`
  //       );
  //     } else {
  //       message.info(`${child.first_name} n'était pas inscrit(e) ce jour-là`);
  //     }
  //   } else if (selectedDay) {
  //     // Patch attendance
  //     if (selectedDay.is_editable) {
  //       confirm({
  //         title: activityName,
  //         content: `Voulez-vous modifier la présence de ${
  //           child.first_name
  //         } le ${day.format("D")}/${day.format("MM")} ?`,
  //         onOk() {
  //           dispatch(
  //             patchAttendance({
  //               childAlias,
  //               alias: selectedDay.alias,
  //               present: !selectedDay.is_listed,
  //             })
  //           );
  //         },
  //         onCancel() {
  //
  //         },
  //       });
  //     } else {
  //       message.info("Cette date n'est pas modifiable");
  //     }
  //   } else {
  //     // Create attendance
  //     confirm({
  //       title: activityName,
  //       content: `Voulez-vous inscrire ${child.first_name} le ${day.format(
  //         "D"
  //       )}/${day.format("MM")} ?`,
  //       onOk() {
  //         dispatch(
  //           createAttendance({
  //             childAlias,
  //             date: day.format("YYYY-MM-DD"),
  //             activityAlias: alias,
  //           })
  //         );
  //       },
  //       onCancel() {
  //
  //       },
  //     });
  //   }
  // }, [selectedCell]);

  const allSubscriptions = selectedChildrenDetails?.reduce((acc, cur) => {
    if (cur?.subscriptions && cur?.subscriptions.length > 0) {
      const newSubscriptions = cur?.subscriptions?.map((sub) => ({
        ...sub,
        child_alias: cur?.alias,
      }));
      acc = [...acc, ...newSubscriptions];
    }

    return acc;
  }, []);

  const activitiesByOrganisations = allSubscriptions.reduce((acc, cur) => {
    const activities = allSubscriptions
      .filter((sub) => sub.organisation_alias === cur.organisation_alias)
      .filter((sub) => {
        if (hideFinishedActivities) {
          return organisationsActivitiesData?.[sub.organisation_alias]?.find(
            (act) =>
              act?.actAlias === sub?.parent_activity_alias && act.status !== 2
          );
        } else {
          return sub;
        }
      })
      .reduce((accAct, curAct) => {
        const filteredAttendances = allAttendances.filter(
          (att) => att.activity_alias === curAct.parent_activity_alias
        );
        const fiteredAllSubscriptions = allSubscriptions.filter(
          (sub) => sub.parent_activity_alias === curAct.parent_activity_alias
        );
        const childsAttendances = groupBy(filteredAttendances, "child_alias");
        const subscriptionsByChilds = groupBy(
          fiteredAllSubscriptions,
          "child_alias"
        );

        const allChilds = {
          ...subscriptionsByChilds,
          ...childsAttendances,
        };

        const curOpeningDays = openingDays?.filter(
          (day) => day?.activity_alias === curAct?.parent_activity_alias
        );
        const curAttendance = allAttendances?.filter(
          (att) => att?.activity_alias === curAct?.parent_activity_alias
        );
        accAct[curAct.parent_activity_alias] = {
          ...curAct,
          openingDays: curOpeningDays,
          attendance: curAttendance,
          childs: allChilds,
        };

        return accAct;
      }, {});

    acc[cur.organisation_alias] = acc[cur.organisation_alias]
      ? { ...acc[cur.organisation_alias] }
      : {};
    acc[cur.organisation_alias] = {
      ...acc[cur.organisation_alias],
      ...activities,
    };
    return acc;
  }, {});

  const organisationsHavingActivities = Object.keys(activitiesByOrganisations);

  const activitiesBillingModel = organisationsHavingActivities.reduce(
    (acc, cur) => {
      const activities = organisationsActivitiesData?.[cur];
      acc[cur] = acc[cur] ? [...acc[cur]] : [];

      Array.isArray(activities) &&
        activities.forEach((activity) => {
          acc[cur] = {
            ...acc[cur],
            [activity.actAlias]: activity?.paymentMethod,
          };
        });

      return acc;
    },
    {}
  );
  useEffect(() => {
    if(isHover){
      let hoveredCell = isHover?.openDay?.find((d) => dayjs(d.date).format("YYYY-MM-DD") === dayjs(isHover?.day).format("YYYY-MM-DD"));
      let label="";
      if(hoveredCell.container_label){
        label = getContainerLabel(hoveredCell.container_label);
        setHoveredCellContainerLabel(label);
        setIsHovered(true);
      }else{
        setHoveredCellContainerLabel(label);
        setIsHovered(false);
      }      
    }else{
      setIsHovered(false);
    }  
  }, [isHover]);

  useEffect(() => {
    manageSelectedCell(
      selectedCell,
      // child,
      dispatch,
      // patchAttendance,
      deleteAttendance,
      addPaidAttendance,
      removePaidAttendance,
      activitiesBillingModel,
      organisationsActivitiesData,
      allAttendances,
      orders,
      allSubscriptions,
      massDeleteAttendances,
      requestCreditAction,
      cancelCreditRequestAction,
      userOrganisationsList,
    );
  }, [selectedCell, userOrganisationsList]);

  // useEffect(() => {
  //   dispatch(organisationsActivitiesFetching(
  //     organisationsHavingActivities,
  //   ))
  // }, [organisationsHavingActivities.length])

  // useEffect(() => {
  //   let aliases = [];

  //
  //   //
  //   // if (!subscriptions) return;
  //   // for (let sub of subscriptions) {
  //   //   if (aliases.find((s) => sub.parent_activity_alias === s) === undefined) {
  //   //     aliases.push({
  //   //       org_alias: sub.organisation_alias,
  //   //       activity_alias: sub.activity_alias,
  //   //     });
  //   //   }
  //   // }
  //   dispatch(
  //     fetchAllOpeningdays({
  //       aliases,
  //       month: dayjs(selectedMonth).format("M"),
  //     })
  //   );
  // }, [selectedMonth]);

  // useEffect(() => {
  //   if (activitiesByOrganisations) {
  //     const aliases = Object.keys(activitiesByOrganisations).reduce((acc, cur) => {
  //       if (activitiesByOrganisations?.[cur]) {
  //         const activities = Object.keys(activitiesByOrganisations?.[cur]).map(actAlias => ({
  //           org_alias: cur,
  //           activity_alias: activitiesByOrganisations?.[cur]?.[actAlias]?.activity_alias,
  //         }))

  //         acc = [...acc, ...activities]
  //         return acc
  //       }
  //     }, [])

  //
  //     // dispatch(fetchAllOpeningdays({
  //     //   aliases,
  //     //   month: dayjs(selectedMonth).format("M"),
  //     // }))
  //   }
  // }, [activitiesByOrganisations, selectedMonth])

  useEffect(() => {
    // let aliases = [];

    //
    //
    // for (let sub of allSubscriptions) {
    //   if (aliases.find((s) => sub.parent_activity_alias === s) === undefined) {
    //     aliases.push({
    //       org_alias: sub.organisation_alias,
    //       activity_alias: sub.activity_alias,
    //     });
    //   }
    // }

    //
    dispatch(
      fetchAllOpeningdays({
        // aliases,
        month: dayjs(selectedMonth).format("M"),
        year: dayjs(selectedMonth).format("YYYY"),
      })
    );
  }, [selectedMonth]);

  useEffect(() => {
    //
    dispatch(
      fetchChildrengardeplanningdays({
        //,
        month: dayjs(selectedMonth).format("M"),
        year: dayjs(selectedMonth).format("YYYY"),   
      })
    );
  }, [selectedMonth]);

  const fetchAttendances = useCallback(() => {
    // const childAlias = child.alias;
    // if (!subscriptions || !subscriptions.length || !child.alias) return;
    //
    //
    // let aliases = [];

    //
    // for (let sub of subscriptions) {
    //   aliases.push({
    //     org_alias: sub.organisation_alias,
    //     activity_alias: sub.activity_alias,
    //   });
    // }

    let childAliases = [];
    for (let child of children) {
      childAliases.push(child.alias);
    }

    //

    dispatch(
      allChildrenAttendancesFetching({
        month: dayjs(selectedMonth).format("M"),
        year: dayjs(selectedMonth).format("YYYY"),
      })
    );
  }, [selectedMonth, isCreating, isPatching, children.length]); // deleted child

  //

  // let uniqueItems = [...new Set(state.alias)];
  // let org_data = [];
  // if (subscriptions) {
  //   for (let sub of subscriptions) {
  //     if (org_data.length === 0)
  //       org_data.push({ subs: [{ ...sub, childs: [], openingDays: [] }] });
  //     else {
  //       for (let i = 0; i < org_data.length; i++) {
  //         if (
  //           org_data[i].subs[0].org_alias === sub.org_alias &&
  //           org_data[i].subs.find(
  //             (s) => sub.parent_activity_alias === s.parent_activity_alias
  //           ) === undefined
  //         )
  //           org_data[i].subs.push({ ...sub, childs: [], openingDays: [] });
  //       }
  //     }
  //   }
  // }

  //

  // for (let org of org_data) {
  //   for (let sub of org.subs) {
  //     for (let att of allAttendances) {
  //       if (att.activity_alias === sub.parent_activity_alias) {
  //         let pushed = false;
  //         if (sub.childs) {
  //           for (let ch of sub.childs) {
  //             if (ch.alias === att.child_alias) {
  //               ch.attendance.push(att);
  //               pushed = true;
  //             }
  //           }
  //         }
  //         if (!pushed)
  //           sub.childs.push({
  //             ...children.find((ch) => ch.alias === att.child_alias),
  //             attendance: [att],
  //           });
  //       }
  //     }
  //     if (openingDays) {
  //       for (let open of openingDays) {
  //         if (open.activity === sub.activity_alias) {
  //           sub.openingDays.push(open);
  //         }
  //       }
  //     }
  //   }
  // }

  const reccurent = (sub, child) => {
    setRecu(sub);
    setSelectedChild(child);
    setShowDrawer(true);
  };

  useEffect(() => {
    dispatch(userOrganisationsFetching());
  }, []);

  const requestCreditAction = useCallback(
    (attAlias) => {
      dispatch(
        requestCredit({
          attAlias,
          month: dayjs(selectedMonth).format("M"),
          year: dayjs(selectedMonth).format("YYYY"),
        })
      );
    },
    [dispatch, selectedMonth]
  );

  const cancelCreditRequestAction = useCallback(
    (attAlias) => {
      dispatch(
        cancelCreditRequest({
          attAlias,
          month: dayjs(selectedMonth).format("M"),
          year: dayjs(selectedMonth).format("YYYY"),
        })
      );
    },
    [dispatch, selectedMonth]
  );

  firstAndLastDayOfMonth();

  return (
    <Calendar>
      {(isFetching ||
        isCreating ||
        isPatching ||
        allfetchingOpeningDays ||
        isAddingAttendance ||
        isRemovingAttendance ||
        isLoadingOrganisationsActivitiesData) && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
      <ActivityWrapper
        isLoading={
          isFetching ||
          isCreating ||
          isPatching ||
          allfetchingOpeningDays ||
          isAddingAttendance ||
          isRemovingAttendance ||
          isLoadingOrganisationsActivitiesData
        }
      >
        {organisationsHavingActivities.length > 0 &&
          organisationsHavingActivities
          ?.sort((a, b) => {
            const organisationDetailsA = userOrganisationsList?.find(
              (org) => org.alias === a
            );
            const organisationDetailsB = userOrganisationsList?.find(
              (org) => org.alias === b
            );
            const organisationNameA = organisationDetailsA?.name;
            const organisationNameB = organisationDetailsB?.name;

            return organisationNameA?.localeCompare(
              organisationNameB
            );
          })
          ?.map((orgAlias) => {
            // const orgActivitiesAliases = activitiesByOrganisations?.[orgAlias] && Object.keys(activitiesByOrganisations?.[orgAlias])
            const orgActivitiesAliases =
              organisationsActivitiesData?.[orgAlias] &&
              organisationsActivitiesData?.[orgAlias]?.map(
                (act) => act?.actAlias
              );

            const organisationDetails = userOrganisationsList?.find(
              (org) => org.alias === orgAlias
            );

            const organisationName = organisationDetails?.name;
            const organisationAllowCreditCreation = organisationDetails?.prepaid_allow_attendance_credit_by_hhself;

            const filteredOrgActivitiesAliases = orgActivitiesAliases
              ?.filter((activityAlias) => {
                const sub =
                  activitiesByOrganisations?.[orgAlias]?.[activityAlias];
                const childrenAliases = sub?.childs && Object.keys(sub?.childs);

                return childrenAliases?.length > 0;
              })
              ?.sort((a, b) => {
                const subA = activitiesByOrganisations?.[orgAlias]?.[a];
                const activityDetailsA = organisationsActivitiesData?.[
                  orgAlias
                ]?.find((act) => act?.actAlias === subA?.parent_activity_alias);
                const subB = activitiesByOrganisations?.[orgAlias]?.[b];
                const activityDetailsB = organisationsActivitiesData?.[
                  orgAlias
                ]?.find((act) => act?.actAlias === subB?.parent_activity_alias);

              //  console.log({activityDetailsA, activityDetailsB})
                return activityDetailsA?.start_time?.localeCompare(
                  activityDetailsB?.start_time
                );
              });

            return (
              <>
                {filteredOrgActivitiesAliases?.map((activityAlias, index) => {
                  const sub =
                    activitiesByOrganisations?.[orgAlias]?.[activityAlias];
                  const childrenAliases =
                    sub?.childs && Object.keys(sub?.childs);
                  const activityDetails = organisationsActivitiesData?.[
                    orgAlias
                  ]?.find(
                    (act) => act?.actAlias === sub?.parent_activity_alias
                  );

                  return (
                    <OrgActivitiesContainer key={activityDetails?.actAlias}>
                      <Collapse
                        defaultActiveKey={[index]}
                        expandIconPosition="right"
                        bordered={false}
                      >
                        <Panel
                          key={index}
                          header={
                            <Space
                              style={{
                                justifyContent: "space-between",
                                width: "calc(100% - 60px)",
                              }}
                            >
                              <Space>
                                <Avatar
                                  src={organisationDetails?.photo}
                                  size={53}
                                />
                                <Space direction="vertical" size={0}>
                                  <H2
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 400,
                                      marginBottom: 0,
                                    }}
                                  >
                                    {sub.organisation_name}
                                  </H2>
                                  <div>
                                    <ActivityName
                                      as="span"
                                      onClick={() => {
                                        const details =
                                          organisationsActivitiesData?.[
                                            orgAlias
                                          ]?.find(
                                            (act) =>
                                              act?.actAlias ===
                                              activityDetails?.actAlias
                                          );

                                        setShowActivityDetails(true);
                                        setActivityDetails({
                                          ...details,
                                          organisationName,
                                        });
                                      }}
                                      style={{
                                        borderRight: "none",
                                      }}
                                    >
                                      {sub.parent_activity_name}
                                    </ActivityName>
                                  </div>
                                </Space>
                              </Space>
                              <Space 
                                direction="vertical"
                                style={
                                  {
                                    alignItems:"center"
                                  }
                                }
                                size={0}
                              >
                                {activityDetails?.period_selection>1 && (                                                                        
                                  <BadgePill
                                    color={(activityDetails?.period_selection_active!=0)&&getBadgeColor(activityDetails?.period_selection)}
                                  >
                                    {getActivitiesPeriodSelectionActive(`${activityDetails?.period_selection}${activityDetails?.period_selection_active}`)}
                                  </BadgePill>
                                )}
                                     
                                {(activityDetails?.period_selection>1 && activityDetails?.notice?.all_open=='1') &&(
                                  <BadgePill
                                    color={colors.cvOrange}
                                  >
                                    {getOpeningPeriodSelectionLabel(activityDetails?.period_selection)}
                                  </BadgePill>
                                )}                                                          
                              </Space>
                              <Space
                                direction="vertical"
                                style={{
                                  alignItems: "flex-end",
                                }}
                                size={0}
                              >
                                <span>
                                  Du{" "}
                                  {dayjs(activityDetails?.start_date).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  au{" "}
                                  {dayjs(activityDetails?.end_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                                <span>
                                  de{" "}
                                  {dayjs(
                                    activityDetails?.start_time,
                                    "HH:mm:ss"
                                  ).format("HH:mm")}{" "}
                                  à{" "}
                                  {dayjs(
                                    activityDetails?.end_time,
                                    "HH:mm:ss"
                                  ).format("HH:mm")}
                                </span>
                              </Space>
                            </Space>
                          }
                        >
                          <Top
                            sub={sub}
                            index={index}
                            currentMonthDates={currentMonthDates}
                            organisationsActivitiesData={
                              organisationsActivitiesData
                            }
                            isLoadingOrganisationsActivitiesData={
                              isLoadingOrganisationsActivitiesData
                            }
                            setShowActivityDetails={setShowActivityDetails}
                            setActivityDetails={setActivityDetails}
                            showActivityDetails={showActivityDetails}
                            activityDetails={activityDetails}
                            userOrganisationsList={userOrganisationsList}
                          />
                          <OrgContainer>
                            <ActivityRow
                              setSelectedCell={setSelectedCell}
                              setIsHover={setIsHover}
                              isHover={false}
                              hoveredCellContainerLabel={hoveredCellContainerLabel}
                              setShowActivityDetails={setShowActivityDetails}
                              currentMonthDates={currentMonthDates}
                              child={{ first_name: "Tous" }}
                              sub={sub}
                              reccurent={reccurent}
                              activitiesBillingModel={activitiesBillingModel}
                              activityDetails={activityDetails}
                              orders={orders}
                              requestCreditAction={requestCreditAction}
                              organisationAllowCreditCreation={organisationAllowCreditCreation}
                              organisationDetails={organisationDetails}
                            />
                            {childrenAliases?.map((childAlias) => {
                              const currentChild = selectedChildrenDetails?.find(
                                (ch) => ch.alias === childAlias
                              );

                              const activityLocations = allSubscriptions
                                ?.filter(
                                  (subscription) =>
                                    subscription?.parent_activity_alias ===
                                      activityDetails?.actAlias &&
                                    subscription?.child_alias === childAlias
                                )
                                ?.map(
                                  (subscription) => subscription?.activity_name
                                );

                              return (
                                <ActivityRow
                                  setSelectedCell={setSelectedCell}
                                  setIsHover={setIsHover}
                                  isHover={isHovered}
                                  hoveredCellContainerLabel={hoveredCellContainerLabel}
                                  setShowActivityDetails={
                                    setShowActivityDetails
                                  }
                                  activityLocations={activityLocations}
                                  currentMonthDates={currentMonthDates}
                                  child={currentChild}
                                  sub={sub}
                                  reccurent={reccurent}
                                  activitiesBillingModel={
                                    activitiesBillingModel
                                  }
                                  activityDetails={activityDetails}
                                  gardeplaningDays={gardeplaningDays}
                                  orders={orders}
                                  requestCreditAction={requestCreditAction}
                                  organisationAllowCreditCreation={organisationAllowCreditCreation}
                                  organisationDetails={organisationDetails}
                                />
                              );
                            })}
                          </OrgContainer>
                          <TotalDescription 
                          pgd_exist={pgd_exist}
                          />
                        </Panel>
                      </Collapse>
                    </OrgActivitiesContainer>
                  );
                })}
                {showActivityDetails && (
                  <ActivityDetail
                    setShowActivityDetails={setShowActivityDetails}
                    activity={activityDetails}
                    isLoadingOrganisationsActivitiesData={
                      isLoadingOrganisationsActivitiesData
                    }
                  />
                )}
              </>
            );
          })}
      </ActivityWrapper>
      {/* {org_data.map((org) => (
        <>
          {org.subs.map((sub, index) => (
            <>
              <Top
                sub={sub}
                org={org}
                index={index}
                currentMonthDates={currentMonthDates}
              ></Top>
              <OrgContainer>
                <ActivityRow
                  setSelectedCell={setSelectedCell}
                  setShowActivityDetails={setShowActivityDetails}
                  currentMonthDates={currentMonthDates}
                  child={{ first_name: "Tous" }}
                  sub={sub}
                  reccurent={reccurent}
                ></ActivityRow>
                {sub?.childs?.map((child) => (
                  <ActivityRow
                    setSelectedCell={setSelectedCell}
                    setShowActivityDetails={setShowActivityDetails}
                    currentMonthDates={currentMonthDates}
                    child={child}
                    sub={sub}
                    reccurent={reccurent}
                  ></ActivityRow>
                ))}
              </OrgContainer>
            </>
          ))}
          <TotalDescription></TotalDescription>
        </>
      ))} */}
      <EditActivity
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        data={recu}
        organisationsActivitiesData={organisationsActivitiesData}
        children={selectedChildrenDetails}
        currentChild={selectedChild}
        activitiesBillingModel={activitiesBillingModel}
        addPaidAttendance={addPaidAttendance}
        selectedMonth={selectedMonth}
        removePaidAttendance={removePaidAttendance}
        orders={orders}
        allActivityAttendances={allAttendances}
      ></EditActivity>
      <SubActivity
        showDrawer={showSubDrawer}
        setShowDrawer={setShowSubDrawer}
        data={recu}
      ></SubActivity>
      {/* {showActivityDetails && (
        <ActivityDetail
          setShowActivityDetails={setShowActivityDetails}
          activityDetails={activityDetails}
          isLoadingOrganisationsActivitiesData={isLoadingOrganisationsActivitiesData}
        />
      )} */}
    </Calendar>
  );
};

const Top = ({
  index,
  currentMonthDates,
  sub,
  organisationsActivitiesData,
  isLoadingOrganisationsActivitiesData,
  setShowActivityDetails,
  setActivityDetails,
  showActivityDetails,
  activityDetails,
  userOrganisationsList,
}) => {
  return (
    <>
      <Horizontal
        direction="horizontal"
        style={{
          marginBottom: 5,
        }}
      >
        <Border />
        <DayContainer>
          {currentMonthDates.map((date) => {
            if (
              dayjs(dayjs().format("YYYY-MM-DD")).isSame(
                dayjs(date).format("YYYY-MM-DD"),
                "day"
              )
            ) {
              return (
                <Day color="#1890FF" textColor="white">
                  <DayLetter textColor="white">
                    {dayjs(date).format("dd")[0]}
                  </DayLetter>
                  <DayNumber textColor="white">
                    {dayjs(date).format("DD")}
                  </DayNumber>
                </Day>
              );
            }
            return (
              <Day>
                <DayLetter>{dayjs(date).format("dd")[0]}</DayLetter>
                <DayNumber>{dayjs(date).format("DD")}</DayNumber>
              </Day>
            );
          })}
        </DayContainer>
      </Horizontal>
    </>
  );
};

const ActivityRow = ({
  setShowActivityDetails,
  reccurent,
  sub,
  currentMonthDates,
  setSelectedCell,
  setIsHover,
  isHovered,
  hoveredCellContainerLabel,
  child,
  activitiesBillingModel,
  activityDetails,
  gardeplaningDays,
  orders,
  activityLocations,
  requestCreditAction,
  organisationAllowCreditCreation,
  organisationDetails,
}) => {
  const activityAllowCreditCreation = activityDetails?.prepaid_allow_attendance_credit_by_hhself;
  const isPrepaid = activitiesBillingModel?.[sub.organisation_alias]?.[sub.parent_activity_alias] === 2;

  return (
    <ActivityContainer>
      <ActivityName isAll={child?.first_name === "Tous"}>
        {child?.first_name === "Tous" ? (
          child?.first_name
        ) : (
          <Link to={`/children/${child?.alias}`}>{child?.first_name}</Link>
        )}
      </ActivityName>
      <DynamicPadding
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {child?.first_name !== "Tous" && (
          <Tooltip
            title={
              <>
                {(activityDetails?.period_selection>=1) ?(
                    (dayjs(activityDetails?.prevenance_date,"YYYY-MM-DDTHH:mm:SS").isBefore(dayjs("2050-01-01")))?(
                      <>
                        {
                          (activityDetails?.period_selection_active == 1 && activityDetails?.notice?.all_open=='0')?(
                            <>
                              <p>
                                  Activité réservable <span style={{fontWeight: 'bold'}}>{getActivitiesPeriodSelection(activityDetails?.period_selection)}</span>
                              </p> 
                              <p>{`Réservation avant le ${dayjs(
                                activityDetails?.prevenance_date
                              ).format(
                                "ddd DD MMM [à] HH[h]mm"
                              )} pour la prochaine activité du ${dayjs(
                                activityDetails?.update_after
                              ).format("ddd DD MMM")} à ${dayjs(
                                activityDetails?.start_time,
                                "HH:mm"
                              ).format("HH[h]mm")}`}</p>
                            </>
                            ):(activityDetails?.period_selection_active == 0 && activityDetails?.notice?.all_open =='1')?(
                              <>
                                <p>
                                  Activité gérée <span style={{fontWeight: 'bold'}}>{getActivitiesPeriodSelection(activityDetails?.period_selection)}</span> et
                                  réservable <span style={{fontWeight: 'bold'}}>{getOpeningPeriodSelectionLabel(activityDetails?.period_selection)}</span> 
                                </p>
                                <p>{`Réservation avant le ${dayjs(
                                  activityDetails?.prevenance_date
                                  ).format(
                                    "ddd DD MMM [à] HH[h]mm"
                                  )} pour ${(getConfirmPeriodReservationLabel(activityDetails?.period_selection)).split(/\s(.+)/)[1]} incluant la prochaine activité du ${dayjs(
                                    activityDetails?.update_after
                                  ).format("ddd DD MMM")} à ${dayjs(
                                    activityDetails?.start_time,
                                    "HH:mm"
                                  ).format("HH[h]mm")}`}</p>
                              </>
                            ):(activityDetails?.period_selection_active == 1 && activityDetails?.notice?.all_open =='1')?(
                              <>
                                <p>Activité réservable <span style={{fontWeight: 'bold'}}>{getActivitiesPeriodSelection(activityDetails?.period_selection)}</span> et &nbsp;
                                <span style={{fontWeight: 'bold'}}>{getOpeningPeriodSelectionLabel(activityDetails?.period_selection)}</span> </p>
                                <p>{`Réservation avant le ${dayjs(
                                    activityDetails?.prevenance_date
                                  ).format(
                                    "ddd DD MMM [à] HH[h]mm"
                                  )} pour ${(getConfirmPeriodReservationLabel(activityDetails?.period_selection)).split(/\s(.+)/)[1]} incluant la prochaine activité du ${dayjs(
                                    activityDetails?.update_after
                                  ).format("ddd DD MMM")} à ${dayjs(
                                    activityDetails?.start_time,
                                    "HH:mm"
                                  ).format("HH[h]mm")}`}</p>
                              </>
                            ):(activityDetails?.period_selection_active == 0 && activityDetails?.notice?.all_open =='0')?(
                              <p>{`Réservation avant le ${dayjs(
                                activityDetails?.prevenance_date
                              ).format(
                                "ddd DD MMM [à] HH[h]mm"
                              )} pour la prochaine activité du ${dayjs(
                                activityDetails?.update_after
                              ).format("ddd DD MMM")} à ${dayjs(
                                activityDetails?.start_time,
                                "HH:mm"
                              ).format("HH[h]mm")}`}</p>
                            ):<></> 
                        }
                        {/* {organisationAllowCreditCreation && !activityAllowCreditCreation && (
                          <p>
                            Votre organisation <strong>{organisationDetails?.name}</strong> n'autorise pas l'annulation en ligne de réservations déjà payées pour cette activité.
                          </p>
                        )}
                        {!organisationAllowCreditCreation && (
                          <p>
                            Votre organisation <strong>{organisationDetails?.name}</strong> n'autorise pas l'annulation en ligne de réservations déjà payées.
                          </p>
                        )} */}
                        {isPrepaid && (
                          <>
                            {isBoolean(activityAllowCreditCreation) && (
                              <p>
                                votre organisation <strong>{organisationDetails?.name}</strong> {activityAllowCreditCreation ? "autorise" : "n'autorise pas"} l'annulation en ligne de réservations déjà payées pour cette activité.
                              </p>
                            )}

                            {!isBoolean(activityAllowCreditCreation) && (
                              <p>
                                Votre organisation <strong>{organisationDetails?.name}</strong> {organisationAllowCreditCreation ? "autorise" : "n'autorise pas"} l'annulation en ligne de réservations déjà payées.
                              </p>
                            )}
                          </>
                        )}
                        <p style={{ marginBottom: 0 }}>
                            Lieu
                          {activityLocations?.length > 1
                              ? "x"
                              : ""}{" "}
                            d'inscription:
                        </p>
                        <ul>
                          {activityLocations?.map((location) => (
                            <li key={Math.random()}>
                              {location?.replace(
                                `${sub?.parent_activity_name} - `,
                                ""
                              )}
                            </li>
                          ))}
                        </ul>
                      </>
                      ):(
                        <p style={{ marginBottom: 0 }}>
                          Aucune date à venir n'est ouverte à la réservation
                        </p>
                      ) 
                ):(dayjs(activityDetails?.prevenance_date,"YYYY-MM-DDTHH:mm:SS").isBefore(dayjs("2050-01-01")
                  )?(
                    <>
                      <p>{`Réservation avant le ${dayjs(
                        activityDetails?.prevenance_date
                      ).format(
                        "ddd DD MMM [à] HH[h]mm"
                      )} pour la prochaine activité du ${dayjs(
                        activityDetails?.update_after
                      ).format("ddd DD MMM")} à ${dayjs(
                        activityDetails?.start_time,
                        "HH:mm"
                      ).format("HH[h]mm")}`}</p>
                      {/* {organisationAllowCreditCreation && !activityAllowCreditCreation && (
                        <p>
                          Votre organisation <strong>{organisationDetails?.name}</strong> n'autorise pas l'annulation en ligne de réservations déjà payées pour cette activité.
                        </p>
                      )}
                      {!organisationAllowCreditCreation && (
                        <p>
                          Votre organisation <strong>{organisationDetails?.name}</strong> n'autorise pas l'annulation en ligne de réservations déjà payées.
                        </p>
                      )} */}
                      
                      {isPrepaid && (
                        <>
                          {isBoolean(activityAllowCreditCreation) && (
                            <p>
                              votre organisation <strong>{organisationDetails?.name}</strong> {activityAllowCreditCreation ? "autorise" : "n'autorise pas"} l'annulation en ligne de réservations déjà payées pour cette activité.
                            </p>
                          )}

                          {!isBoolean(activityAllowCreditCreation) && (
                            <p>
                              Votre organisation <strong>{organisationDetails?.name}</strong> {organisationAllowCreditCreation ? "autorise" : "n'autorise pas"} l'annulation en ligne de réservations déjà payées.
                            </p>
                          )}
                        </>
                      )}
                      <p style={{ marginBottom: 0 }}>
                        Lieu
                        {activityLocations?.length > 1
                          ? "x"
                          : ""}{" "}
                        d'inscription:
                      </p>
                      <ul>
                        {activityLocations?.map((location) => (
                          <li key={Math.random()}>
                            {location?.replace(
                              `${sub?.parent_activity_name} - `,
                              ""
                            )}
                          </li>
                        ))}
                      </ul>
                  </>
                  ):(
                    <p style={{ marginBottom: 0 }}>
                      Aucune date à venir n'est ouverte à la réservation
                    </p>
                  )
                )}                                    
              </>
            }
            color="orange"
          >
            <InfoCircleOutlined style={{ fontSize: 20 }} />
          </Tooltip>
        )}
      </DynamicPadding>
      <DynamicPadding>
        <Recurrent isDisabled={activityDetails?.period_selection > 1 && activityDetails?.period_selection_active === 1} 
         onClick={() => reccurent(sub, child)}>
          <ReloadOutlined
            style={{
              fontSize: "20px",
              paddingTop: "5px",
            }}
          ></ReloadOutlined>
        </Recurrent>
      </DynamicPadding>
      {currentMonthDates.map((a) =>
        cellWrapper(
          a,
          sub,
          setSelectedCell,
          setIsHover,
          isHovered,
          hoveredCellContainerLabel,
          orders,
          child,
          activitiesBillingModel?.[sub.organisation_alias]?.[
            sub?.parent_activity_alias
          ],
          activityDetails,
          gardeplaningDays,
          currentMonthDates,
          organisationAllowCreditCreation,
          activityAllowCreditCreation,
        ),
      )}
      {/* <Cases
        currentMonthDates={currentMonthDates}
        sub={sub}
        setSelectedCell={setSelectedCell}
      ></Cases> */}
    </ActivityContainer>
  );
};

const TotalDescription = ({
  pgd_exist,
}) => {
  return (
    <>
    <DescriptionContainer>
      <DescWrapper>
        <Dot color="#FFCDD6"></Dot>
        <Description>Récurrence</Description>
      </DescWrapper>
      <DescWrapper>
        <Dot color="#E2E4E6"></Dot>
        <Description>Réservable</Description>
      </DescWrapper>
      <DescWrapper>
        <Dot color="#20CD8D"></Dot>
        <Description>Présent</Description>
      </DescWrapper>
      <DescWrapper>
        <Dot color="#F4C26D"></Dot>
        <Description>Absent</Description>
      </DescWrapper>
      <DescWrapper>
        <Dot color="#1890ff"></Dot>
        <Description>Annulable</Description>
      </DescWrapper>
      <DescWrapper>
        <Dot color="#6D7F9D"></Dot>
        <Description>Non annulable</Description>
      </DescWrapper>
      <DescWrapper>
        <Dot color="#FF5876"></Dot>
        <Description>Dans le panier</Description>
      </DescWrapper>
      <DescWrapper>
        <Dot color="#D3ADF7"></Dot>
        <Description>Attente retour paiement</Description>
      </DescWrapper>
      <DescWrapper>
        <Dot color="#722ED1"></Dot>
        <Description>Présence due</Description>
      </DescWrapper>
      <DescWrapper>
        <Dot color="#B7EB8F"></Dot>
        <Description>Remboursé</Description>
      </DescWrapper>
    </DescriptionContainer>

    <>{pgd_exist &&
        <Row justify="end">
          <Col span={0.5}>
            <Box><Draw color = "#6D7F9D"></Draw></Box>
          </Col>
          <Col span={4.5}> Je n'ai pas la garde de l'enfant</Col>
        </Row>}
    </>
  
    </>
  );
};

// determine the color of a case based on its status
const getCaseColor = (currDay, sub, colors) => {
  // If the day is in the past
  if (dayjs(currDay.date).set({ hour: sub?.start_time?.split(':')[0], minute: sub?.start_time?.split(':')[1], second: 0, millisecond: 0 }) <= dayjs()) {
    if (currDay.was_there) {
      return colors.cvGreen; // present on a past date
    } else if (currDay.is_listed && !currDay.was_there) {
      return colors.cvOrange; // registered but not present on a past date
    } else if (!currDay.is_listed && !currDay.was_there) {
      return colors.cvBlueGreyLight; // not registered on a past date
    }
  } else {
    // If it is a present or future day
    if (currDay.is_listed && currDay.is_editable) {
      return colors.cvBlue; // if registered
    } else if (currDay.is_listed && !currDay.is_editable) {
      return colors.cvBlueGrey; // if not editable
    } else {
      return colors.cvBlueGreyLight; // if not registered
    }
  }
};

// const handleOnMouseEnter = (currDay,a,sub, setIsHover) => {
//   setIsHover({
//     attendance: currDay,
//     day: a,
//     openDay: sub.openingDays,
//     sub,
//   });
// };
//   handle the onClick event
const handleOnClick = (currDay, a, sub, setSelectedCell) => {
  /*console.log('here', {
    attendance: currDay,
    day: a,
    openDay: sub.openingDays,
    sub,
  });*/

  setSelectedCell({
    attendance: currDay,
    day: a,
    openDay: sub.openingDays,
    sub,
  });
};

const Cases = ({ currentMonthDates, sub, setSelectedCell }) => {
  return currentMonthDates.map((a) => {
    let color;
    let currDay = null;

    if (sub.attendance) {
     //console.log('sub ', sub);
     // console.log('sub.attendance ', sub.attendance);

      if (sub.childs) {
        currDay = sub.childs?.[sub.child?.alias]?.find((element) =>
          dayjs(element.date).isSame(a, 'day')
        );
      } else {
        currDay = sub.attendance.find(
          (element) => dayjs(element.date).isSame(a, 'day') && element
        );
      }
    }

    let currOpen = sub.openingDays.find((element) =>
      dayjs(element.date).isSame(a, 'day')
    );

    if (currDay) {
      color = getCaseColor(currDay, sub, colors);

      return <Case onClick={() => handleOnClick(currDay, a, sub, setSelectedCell)} color={color}></Case>;
    }

    if (currOpen && dayjs(currOpen.date) >= dayjs()) {
      return (
        <Case
          onClick={() =>
            setSelectedCell({
              attendance: currDay,
              day: a,
              openDay: sub.openingDays,
              sub,
            })
          }
          color={colors.cvBlueGreyLight}
        ></Case>
      );
    }

    return <Case onClick={() => handleOnClick(currDay, a, sub, setSelectedCell)}></Case>;
  });
};

export default ActivityCalendar;
