import React, { useCallback, useEffect } from "react"
import styled from "styled-components"
import { Space, Dropdown, Divider, Tag } from "antd"
import { Link } from "react-router-dom"
// import {
//   mutate,
// } from 'hooks/useSWR'
// import api from 'lib/api'
import Picture from "~/components/common/Picture"
import Gender from "~/components/common/Gender"
import Text from "~/components/common/Text"
import StatusSelect from "~/components/common/StatusSelect"
import axios from "~/lib/axios"
import {
  CustomCard,
  Name,
  SubText,
  CustomButton,
  Options,
  CustomSpace,
  Footer,
  Content,
  MiniTag,
} from "./components"
import { childStatus } from "~/constants/index"
import { ExclamationOutlined } from "@ant-design/icons"
// import useApi from 'hooks/useApi'

const CustomSelect = styled(StatusSelect)`
  position: absolute;
  left: 8px;
  top: 8px;
`
const OrgCard = ({
  name,
  menu,
  photo,
  last_name,
  first_name,
  status,
  address,
  zip,
  email,
  phone,
}) => {
  
  // useEffect(() => {
  //   axios
  //     .get(registrationUrl)
  //     .then((res) => 
  //     .catch((e) => console.lor(e));
  // });

  // 
  /**
   * TODO: This is not the right Alias to use
   *  we need to retrieve the registrations alias
   */
  // const {
  //   request: changeStatus,
  // } = useApi(
  //   'registrations.patch',
  //   { alias },
  // )
  // const onStatusChange = useCallback(
  //   async status => {
  //     await changeStatus({ status })
  //     // revalidate the registrations list
  //     mutate(api.url('registrations.get'))
  //   },
  //   [changeStatus],
  // )

  // const status = 0;

  // 
  
  return (
    <CustomCard>
      <Content>
        <CustomSpace>
          <Picture
            width="100"
            height="100"
            src={photo}
            alt={`${first_name} ${last_name}`}
            style={{ margin: "0 auto" }}
          />
          <Space size="small">
            <Name>{name}</Name>
          </Space>
          <SubText>{address}</SubText>
          <SubText>{zip}</SubText>
          <SubText>{email}</SubText>
          <SubText>{phone}</SubText>
        </CustomSpace>
      </Content>
    </CustomCard>
  )
}

export default OrgCard
