import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { allergies, DocumentTypes } from "~/constants/index"
import { PhoneFilled, MailFilled } from "@ant-design/icons"
import {
  Button,
  Upload,
  Divider,
  Form,
  Input,
  Switch,
  Space,
  Radio,
  Checkbox,
  Spin,
} from "antd"
import { CheckCircleFilled, CameraFilled } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { householdFetching, householdUpdating } from "~/containers/Foyer/actions"
import Search from "~/components/common/Search"
import { childProfileUpdating, fetchSelectedChildrenDetails } from "~/containers/Children/actions"
import { commentSubmitting, registrationUpdating } from "~/containers/Registration/actions"

const Step4 = ({
  organisationAlias,
  setCurrentStep,
  childAliases,
  children,
  householdData,
  schooltripAuthorization,
  setSchooltripAuthorization,
  photoAuthorisation,
  setPhotoAutorisation,
  setSocialSecurity,
  selectedChildrenDetails,
  setSelectedAllergies,
  selectedAllergies,
  childrenRegistrations,
  orgRequiredInformation,
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const initialValues = useRef(null)
  // const [selectedChildrenDetails, setSelectedChildrenDetails] = useState([]);
  const [monthly_revenue_undeclared, setMonthlyRevenueUndeclared] = useState(!!householdData?.monthly_revenue_undeclared)
  
  const { registrationDetails, registrationsDetails, isFetchingDetails, loading: addingComment, isUpdating: isUpdatingRegistration } = useSelector(
    (state) => state.registration
  );

  const { isUpdating: isUpdatingHousehold } = useSelector(
    (state) => state.household.household
  );

  const { isUpdating: isUpdatingChilProfil } = useSelector(
    (state) => state.children
  );

  const isLoading = isUpdatingHousehold || isUpdatingChilProfil || isUpdatingRegistration || addingComment;

  console.log({isUpdatingHousehold, isUpdatingChilProfil, isUpdatingRegistration, addingComment, householdData, monthly_revenue_undeclared})

  let selectedChild = []

  const allAllergies = allergies.map((x) => {
    return { label: x, value: x }
  })

  for (let alias of childAliases) {
    selectedChild.push(children.find((child) => child.alias == alias))
  }

  if (householdData) {   
    initialValues.current = {
      caf_number: householdData.caf_number,
      monthly_revenue: householdData.monthly_revenue_undeclared ? null : householdData.monthly_revenue,
      monthly_revenue_undeclared: householdData.monthly_revenue_undeclared,
    }
  }

  if (selectedChildrenDetails.length > 0) {
    selectedChildrenDetails.forEach(child => {
      if (child) {
        const allergies = JSON.parse(child?.allergy)

        const childRegistration = registrationsDetails?.find(
          reg => reg.organisationAlias === organisationAlias && reg?.child?.alias === child?.alias
        ) || registrationDetails;
        
        initialValues.current = {
          ...initialValues.current,
          [`social_security__${child.alias}`]: childRegistration?.social_security,
          [`photo_authorization__${child.alias}`]: childRegistration?.photo_authorization,
          [`schooltrip_authorization__${child.alias}`]: childRegistration?.schooltrip_authorization,
          [`comment__${child.alias}`]: childRegistration?.comments?.[0]?.content,
          [`allergies__${child.alias}`]: allergies,
          [`insurance__${child.alias}`]: child?.insurance,
        }
      }
    })
  }  

  const onFinish = values => {
    console.log({values});

    dispatch(householdUpdating({
      household: householdData.alias,
      data: {
        caf_number: values.caf_number,
        monthly_revenue: !!values?.monthly_revenue_undeclared ? undefined : values.monthly_revenue,
        monthly_revenue_undeclared: !!values?.monthly_revenue_undeclared,
      },
      onSuccess: () => {}
    }))

    selectedChild.forEach(child => {
      const newData = {
        insurance: values?.[`insurance__${child.alias}`],
        allergy: values?.[`allergies__${child.alias}`] && JSON.stringify(values?.[`allergies__${child.alias}`]),
        comment: values?.[`comment__${child.alias}`],
      }
      
      dispatch(childProfileUpdating(
        child.alias,
        newData,
        () => {},
      ))

      const childRegistration = registrationsDetails?.find(
        reg => reg.organisationAlias === organisationAlias && reg?.child?.alias === child?.alias
      ) || registrationDetails;
      
      if (values?.[`comment__${child.alias}`]) {
        dispatch(commentSubmitting(
          child?.alias,
          childRegistration?.alias,
          values?.[`comment__${child.alias}`],
        ))
      }

      const social_security = values?.[`social_security__${child.alias}`]
      const photo_authorization = values?.[`photo_authorization__${child.alias}`]
      const schooltrip_authorization = values?.[`schooltrip_authorization__${child.alias}`]

      dispatch(
        registrationUpdating(
          child?.alias,
          childRegistration?.alias,
          {
            social_security,
            photo_authorization,
            schooltrip_authorization,
          },
          () => setCurrentStep(5)
        )
      )
    })
  }

  useEffect(() => {
    if(monthly_revenue_undeclared){
      initialValues.current.monthly_revenue= null
      console.log(initialValues.current)
    }    
  }, [monthly_revenue_undeclared])

  useEffect(() => {
    if (initialValues.current) {
      form.setFieldsValue(initialValues.current);
    }
  },
  []);

  useEffect(() => {
    if (initialValues.current?.monthly_revenue_undeclared) {
      setMonthlyRevenueUndeclared(initialValues.current?.monthly_revenue_undeclared)
    }
  }, [initialValues.current?.monthly_revenue_undeclared])

  console.log({initialValues: initialValues.current, monthly_revenue_undeclared})

  useEffect(() => {
    // add social security for each child
    children.forEach(child => {
      // const child = children?.[0]
      const childRegistration = registrationsDetails?.find(
        reg => reg.organisationAlias === organisationAlias && reg?.child?.alias === child?.alias
      ) || registrationDetails;

      // setSocialSecurity(state => ({
      //   ...state,
      //   [child.alias]: childRegistration?.social_security,
      // }))

      setSchooltripAuthorization(state => ({
        ...state,
        [child.alias]: childRegistration?.schooltrip_authorization,
      }))

      setPhotoAutorisation(state => ({
        ...state,
        [child.alias]: childRegistration?.photo_authorization,
      }))

      form.setFieldsValue({
        [`social_security__${child.alias}`]: childRegistration?.social_security,
        [`photo_authorization__${child.alias}`]: childRegistration?.photo_authorization,
        [`schooltrip_authorization__${child.alias}`]: childRegistration?.schooltrip_authorization,
      })
    })
  }, [registrationDetails, registrationsDetails, children])

  console.log({registrationsDetails, organisationAlias, orgRequiredInformation, children})

  return (
    <Spin spinning={isFetchingDetails}>
      <BTitle>Informations complémentaires</BTitle>
      <ChildNameContainer>
        <ChildName>Informations du Foyer</ChildName>
        <Line />
      </ChildNameContainer>
      <Form
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
        // initialValues={initialValues.current}
      >
        {orgRequiredInformation?.includes('billingHousehold.caf_number') && (
          <Form.Item
            label="Numéro CAF"
            name="caf_number"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner un numéro CAF",
              },
            ]}
          >
            <Input
              style={{
                maxWidth: 320,
              }}
            />
          </Form.Item>
        )}
        {orgRequiredInformation?.includes('billingHousehold.monthly_revenue') && (
          <Form.Item
            label="Quotient Familial"
            name="monthly_revenue"
            rules={[
              {
                required: false,
                message: "Veuillez renseigner votre revenue mensuel",
              },
            ]}
          >
            <Input  
              disabled={monthly_revenue_undeclared}
              style={{
                maxWidth: 320,
              }}
            />
          </Form.Item>
        )}
        {orgRequiredInformation?.includes('billingHousehold.monthly_revenue') && (
          <Form.Item 
            name="monthly_revenue_undeclared" 
            valuePropName="checked"
          >
            <Checkbox
              checked={monthly_revenue_undeclared}
              onChange={(e) => {
                console.log({e})
                setMonthlyRevenueUndeclared(e.target.checked)          
              }}
            >
              Je ne souhaite pas communiquer mon QF et serai donc facturé des tarifs maximums.
            </Checkbox>
          </Form.Item>  
        )}
        {!orgRequiredInformation?.includes('billingHousehold.caf_number') && !orgRequiredInformation?.includes('billingHousehold.monthly_revenue') && (
          <p>
            <i>Aucune information complémentaire n'est requise pour le foyer.</i>
          </p>
        )}
        {selectedChild.map((child) => (
          <>
            <ChildNameContainer>
              <ChildName>Informations de {child?.first_name}</ChildName>
              <Line />
            </ChildNameContainer>
            <Space direction="vertical">
              {orgRequiredInformation?.includes('registration.schooltrip_authorization') && (
                <Space style={{alignItems: "center"}}>
                  <Form.Item name={`schooltrip_authorization__${child?.alias}`} valuePropName="checked">
                    <Switch />
                  </Form.Item>
                  <span>Autorisation de participer aux sorties scolaires</span>
                </Space>
              )}
              {orgRequiredInformation?.includes('registration.photo_authorization') && (
                <Space style={{alignItems: "center"}}>
                  <Form.Item name={`photo_authorization__${child?.alias}`} valuePropName="checked">
                    <Switch />
                  </Form.Item>
                  <span>Autorisation de photographier</span>
                </Space>
              )}
            </Space>
            {orgRequiredInformation?.includes('registration.social_security') && (
              <Form.Item
                name={`social_security__${child?.alias}`}
                label="Régime général"
                rules={[
                  {
                    required: true,
                    message: "Veuillez renseigner un régime",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio.Button value={0}>
                    <Space>Régime général</Space>
                  </Radio.Button>
                  <Radio.Button value={1}>
                    <Space>MSA</Space>
                  </Radio.Button>
                  <Radio.Button value={2}>
                    <Space>Autre</Space>
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            )}
            {orgRequiredInformation?.includes('child.insurance') && (
              <Form.Item
                name={`insurance__${child?.alias}`}
                label="Nom et numéro de police d’assurance"
                rules={[
                  {
                    required: true,
                    message: "Veuillez renseigner le nom et le numéro de police d’assurance",
                  },
                ]}
              >
                <Input
                  style={{
                    maxWidth: 320,
                  }}
                />
              </Form.Item>
            )}
            {orgRequiredInformation?.includes('child.allergy') && (
              <Form.Item
                name={`allergies__${child?.alias}`}
                label="Restriction(s) alimentaire(s)"
              >
                <Search
                  placeholder="Rechercher..."
                  options={allAllergies}
                  mode="multiple"
                  // defaultValue={selectedAllergies?.[child?.alias]}
                  // value={selectedAllergies?.[child?.alias]}
                  // onChange={val => setSelectedAllergies(state => ({
                  //     ...state,
                  //     [child?.alias]: val,
                  // }))}
                  style={{
                    maxWidth: 320,
                  }}
                />
              </Form.Item>
            )}
            <Form.Item
              label="Commentaire"
              name={`comment__${child?.alias}`}
            >
              <Input.TextArea
                style={{
                  maxWidth: 590,
                }}
                rows={4}
              />
            </Form.Item>
          </>
        ))}

        <Form.Item>
            <Button
              style={{ selfAlign: "center", width: 266, height: 40 }}
              size="large"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Etape suivante
            </Button>
        </Form.Item>

      </Form>
    </Spin>
  )
}

const ChildName = styled.div`
  flex: 1;
  width: 100%;
  white-space: nowrap;
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const ChildNameContainer = styled.div`
  font-size: 20px;
  line-height: 28px;
  color: #0f2d78;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
`

const Line = styled.span`
  height: 2px;
  background: #e2e4e6;
  margin-top: 13px;
  margin-left: 16px;
  width: 100%;
`

const CircleBackground = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: #eef1fe;
  font-size: 32px;
  color: #babfdd;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SelectedDoc = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  border: 2px solid #20cd8d;
  width: 266px;
  height: 160px;
  margin: 24px;
`

const ValidateIcon = styled.div`
  margin-top: -40px;
  margin-right: -260px;
  overflow: hidden;
  font-size: 36px;
  color: #20cd8d;
  z-index: 10;
`

const DocName = styled.span`
  font-size: 16px;
  color: @neutral-5;
  margin-top: 15px;
`

const Doc = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #e2e4e6;
  width: 266px;
  height: 224px;
  margin: 24px;
`

const IconContainer = styled.div`
  font-size: 20px;
  color: #2f4580;
  opacity: 1;
  display: inline;
  padding-right: 5px;
`

const BTitle = styled.div`
  font-size:20px;
  line-height 28px;
  font-weight: 700;
  color: #2F4580;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
`
const MTitle = styled.div`
  font-size:20px;
  line-height 28px;
  color: #2F4580;
  width: 100%;
  text-align: center;
`

const STitle = styled.div`
  font-size:16px;
  line-height 24px;
  color: #2F4580;
  width: 100%;
  opacity: 0.5;
  font-weight: bold;
  margin-top: 24px;
`

const Description = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2f4580;
  opacity: 0.5;
  margin-top: 32px;
`

const Padding = styled.div`
  padding-right: 10%;
  padding-left: 10%;
  margin: 0 auto;
  max-width: 1000px;
`

const Required = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: #2f4580;

  mix-blend-mode: normal;
  opacity: 0.5;
`

export default Step4
