import styled from 'styled-components'
import H2 from '~/components/common/H2'

// left-side right-side  =>  new login-left / login-right, reset-left reset-right
// card-form => login-form / reset-form
// card-form-link => return login

export const Title = styled(H2)`
  color: #fff;
  font-size: 28px
`

export const Card = styled.div`
display: flex;
flex-direction: row;
width: 720px;
background: white;
border-radius: 10px;
box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
margin: auto;

.card-form {
  margin: 100px auto !important;
  width: 300px;
}

.card-form-button {
  width: 100%;
  background-color: #B8DDDD !important;
  border-color: #B8DDDD !important;
  color: #546775 !important;
}

.card-left {
  background: #B8DDDD;
  border-radius: 10px 0px 0px 10px;
  width: 250px;
  color: #546775;
  padding: 20px;
}

.card-right {
  margin-left: 40px;
}

.card-form-input {
  border-radius: 5px;
}

.card-form-link {
  display: block;
  margin-top: 10px;
  text-align: right
}

.card-right-block-msg {
  padding-top: 50px;
}

.card-right-content-msg {
  width: 80%;
  padding-bottom: 15px;
}

`

export const ResetCard = styled(Card)`
  .card-right-reset-msg {
    padding-bottom: 1Opx;
}
`

export const LoginCard = styled(Card)`
  .remember-me {
    margin-bottom: 10px;
  }
`
export const ForgottenCard = styled(Card)`
  .login-form-forgot {
    float: right;
  }

`
