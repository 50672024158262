
import styled from 'styled-components'

import Card from '~/components/common/Card'
import P from '~/components/common/P'
import Image from '~/components/common/Image'
import {
  CloseCircleOutlined,
} from '@ant-design/icons'
/* no-unused-vars
  FileImageOutlined,
*/

export const ModalContent = styled.div`
  border-left:solid 8px ${props => props.borderColor};
  padding:24px;
  margin: -24px;
  border-radius : 0 0 2px 2px;
`

export const ModalTitle = styled.div`
  border-left:solid 8px ${props => props.borderColor};
  padding:16px 24px;
  margin: -16px -24px;
  border-radius : 2px 2px 0 0;
  color: ${props => props.color};
  display:flex;
  & > div{
    margin-right:7px;
  }
`

export const FeedMessageContainer = styled(Card)`
  border-left: 14px solid  ${props => props.color};
  background-color: ${props => props.background};
  cursor:pointer;
  & > div {
    display:flex;
    padding-right:0px;
    align-items: flex-start;
    padding: 15px 0 15px 15px;
  }
`

export const RegistrationMessageContainer = styled(Card)`
  border-left: 14px solid  ${props => props.borderColor};
  background-color: ${props => props.background};
  cursor:pointer;
  & > div {
    display:flex;
    padding-right:0px;
    align-items: flex-start;
    padding: 15px 0 15px 15px;
  }
`

export const FeedMessageContent = styled.div`
  flex:1;
  padding-right:10px;
  border-radius : 8px 8px 0px 0px;
`

export const FeedMessageImage = styled(Image)`
  display: block;
  width:100%;
  margin: 0 auto 0px auto;
  border-radius : 8px 8px 0px 0px;
`

export const PostDetailModal = styled.div`
  // border-left:solid 23px ${props => props.borderColor};
  margin: -24px;
  border-radius: 8px 8px 8px 8px;
`

export const LeftBarContainer = styled.div`
  border-left:solid 23px ${props => props.borderColor};
  border-radius: 0px 0px 0px 8px;
`

export const CustomCloseCircleOutlined = styled(CloseCircleOutlined)`
  font-size: 24px;
  padding: 12px 10px;
  margin-right:-16px;
  margin-top:-8px;
  top:-8px;
  right:0;
  background-color: white;
  border-radius: 0px 8px;
`

export const FeedTitle = styled(P)`
  color : ${props => props.color};
  margin-bottom:10px;
  display: flex;
  align-items: center;
  & > div{
    margin-right:7px
  }
`

export const FeedContent = styled(P)`
  color: #0f2d78;
  margin-bottom:0;
`

export const ActionTitle = styled(P)`
  color: black;
  margin-bottom:15px;
  color: ${props => props.color};
`

export const InfoTitle = styled(P)`
  color: black;
  margin-bottom:10px;
`
export const InfoContent = styled(P)`
  color : #0f2d78;
  margin-bottom:10px;
`

export const ActionsContainer = styled.div`
  max-height : 330px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: -20px -15px;
`

export const ActionContainer = styled.div`
  padding: 0 15px;
  border-left: 8px solid ${props => props.color};
  cursor:pointer;
  border-bottom : 1px solid #E7E7E7;
  padding-top:15px;
  padding-bottom:10px;
`

export const StatusContainer = styled.span`
  display:inline-block;
  background-color: ${props => props.color};
  font-size: 11px;
  color: white;
  padding: 1px 5px;
  border-radius: 3px;
  margin-top: -4px;
  float:right
`

export const BillsTitle = styled(P)`
  color: black;
  margin-bottom:10px;
`
export const BillsContent = styled(P)`
  color : #0f2d78;
  margin-bottom:10px;
`

export const BillsHeading = styled(P)`
  color: black;
  margin-bottom:25px;
`

export const CityHallInfo = styled(P)`
  color: black;
  margin-bottom:6px;
`
