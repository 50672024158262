export const billingModels = [
  {
    label: 'Post-paiement',
    value: 0,
  },
  {
    label: 'Pré-paiement',
    value: 1,
  },
  {
    label: 'Avoir',
    value: 2,
  },
]

export const getBillingModel = (value) => billingModels.find((model) => model.value === value)

export const paymentMethods = [
  {
    code: 0,
    label: 'en ligne par CB',
  },
  {
    code: 1,
    label: 'prélèvement',
  },
  {
    code: 2,
    label: 'chèque',
  },
  {
    code: 3,
    label: 'Cesu',
  },
  {
    code: 4,
    label: 'espèces',
  },
  {
    code: 5,
    label: 'prélèvement PayFIP',
  },
  {
    code: 6,
    label: 'chèque(s) vacances',
  },
  {
    code: 7,
    label: 'autre',
  },
  {
    code: 8,
    label: 'bon(s) loisirs CAF',
  },
  {
    code: 9,
    label: 'trésorerie',
  },
  {
    code: 10,
    label: 'avoir',
  },
  {
    code: 11,
    label: 'trop-perçu',
  },
]

export const getPaymentMethod = (code) => paymentMethods.find((method) => method.code === code)
