import React from 'react'
import styled from 'styled-components'
import {
  useSelector,
} from 'react-redux'
import {
  Link,
} from 'react-router-dom'
import {
  Breadcrumb,
} from 'antd'

const { Item } = Breadcrumb

const Container = styled(Breadcrumb)`
  font-size: @font-size-base - 2px;

  &.ant-breadcrumb a {
    color: #969696;
  }
`

const BreadcrumbComponent = ({ items }) => {

  // const { name } = useSelector(state => state.user.organisation)

  return (
    <Container>
      <Item>
        <Link
          to="/dashboard"
        >
          {/* {name} */}
          Cityviz
        </Link>
      </Item>
      {items ?.map(item => (
        <Item
          key={item.name}
        >
          {(
            item.path
              ? (
                <Link
                  to={item.path}
                >
                  {item.name}
                </Link>
              ) : (
                item.name
              )
          )}
        </Item>
      ))}
    </Container>
  )
}

export default BreadcrumbComponent
