import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Menu, Dropdown, Avatar } from "antd"

import { useTranslation } from "react-i18next"
import { logout } from "~/containers/User/actions"
import Link from "~/components/common/Link"

import { Container } from "./components"
import Picture from "~/components/common/Picture"
import { DownOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

const UserComponent = () => {
  const { t } = useTranslation("common")
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.user.userInfo)
  const navigate = useNavigate()
  const logOut = useCallback(() => {
    dispatch(logout())
    navigate('/login')
  }, [dispatch])

  const dropdownMenu = (
    <Menu>
      <Menu.Item>
        <Link to="/profile">{t("header.nav.drop-down.profile")}</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/password_update">Modifier mon mot de passe</Link>
      </Menu.Item>
      <Menu.Item style={{ color: "#FF5876" }} onClick={logOut}>
        {t("header.nav.drop-down.logout")}
      </Menu.Item>
    </Menu>
  )

  const items = [
    {
      label: <Dropdown overlay={dropdownMenu} placement="bottomRight">
          <div
            style={{ fontWeight: 700, display: "flex", flexDirection: "row" }}
          >
            <StyledUserName>
              {user?.first_name} {user?.last_name}
            </StyledUserName>
            <span style={{
              paddingLeft: 12,
              paddingRight: 12,
              display: 'flex',
              alignItems: 'center',
            }}>
              <Picture
                width="40"
                height="40"
                src={user.photo_url}
                alt="[🙂]"
              />
            </span>
            <DownOutlined
              style={{ fontWeight: 700, fontSize: 14, paddingTop: 26 }}
            />
          </div>
        </Dropdown>,
    },
  ];

  return (
    <Container theme="dark" mode="horizontal" items={items} disabledOverflow="False"/>
  )
}

const StyledUserName = styled.p`
  color: white !important;
  transition: color 0.3s;
  margin: 0;

  &:hover {
    color: #1890ff !important;
  }
`

export default UserComponent
