import React from "react"
import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 560px;
  min-height: 660px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
`

export const Content = styled.div`
  width: 100%;
  padding: 14%;

  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const ContainerComponent = ({ children, ...props }) => (
  <Container {...props}>
    <Content>
      {children}
    </Content>
  </Container>
)

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100%;
  // max-width: 560px;
  min-height: 660px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
`

const MainContent = styled.div`
  width: 100%;
  padding: 24px;
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

export const MainContainerComponent = ({ children, ...props }) => (
  <MainContainer {...props}>
    <MainContent>
      {children}
    </MainContent>
  </MainContainer>
)

export default ContainerComponent
