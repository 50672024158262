import React from "react"

import Container from "~/components/common/Container"
import { Button } from "antd"
import styled from "styled-components"
import {
  PaperClipOutlined,
} from "@ant-design/icons"
/* no-unused-vars
  FileImageOutlined,
  CheckCircleOutlined,
*/

const data = [
  {
    title: "Attestation de paiement de frais de garde - 2019",
    file: "Attestation-2019.pdf",
    size: "100ko",
  },
]

const Attestation = () => {
  return (
    <Container>
      <div style={{ paddingBottom: 16 }}>
        Vous pouvez éditer ici une attestation de frais de garde pour l’année
        précédente. Elle reprend tous les frais de garde de vos enfants de moins
        de 6 ans.
      </div>
      <Button style={{ marginBottom: 16 }} type="primary" size="large">
        Editer une attestation de paiement de frais de garde - 2020
      </Button>
      {data.map((d) => (
        <Doc data={d}></Doc>
      ))}
    </Container>
  )
}

export default Attestation

export const Doc = ({ data }) => {
  return (
    <DocContainer>
      <DocImg></DocImg>
      <TextContainer>
        <DocTitle>{data.title}</DocTitle>
        <LightText>
          <PaperClipOutlined /> {data.file} ({data.size})
        </LightText>
        <Underline>Supprimer</Underline>
      </TextContainer>
    </DocContainer>
  )
}

const DocImg = styled.div`
  width: 112px;
  height: 112px;
  border: 1px solid lightgrey;
  border-radius: 2px;
`

const TextContainer = styled.div`
  padding-left: 16px;
  // padding-top: 14px;
  // vertical-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-item: center;
`

const DocTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const LightText = styled.div`
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 5px;
`

const DocContainer = styled.div`
  display: flex;
  align-item: center;
  // flex-direction:row;
  height: 112px;
`

/* no-unused-vars
const BoldText = styled.div`
  font-weight: bold;
`
*/

const Underline = styled.div`
  text-decoration: underline;
  color: @neutral-4;
`
