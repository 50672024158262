import styled, { css } from 'styled-components'
import Card from '~/components/common/Card'

export const ArrowIcon = styled.img`
  margin-right: 15px
`

const darkBackgroundCSS = css`
    background-color: #0f2d78;
    padding: 20px;
    margin: -20px -20px 10px -20px;
`

const lightColorCSS = css`
  color: @white-text-color;
`

const fadedLightColorCSS = css`
  color: fade(@white-text-color, 50);
`

export const ActivityHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => props.dark && darkBackgroundCSS};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  .anticon{
    font-size: 30px;
    ${props => props.dark && lightColorCSS};
  }
`

export const ActivityCard = styled(Card)`
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 20px;
  padding: 0px;
`

export const ActivityStatusContainer = styled.div`
  font-size: 12px;
  color: @primary-color;
  margin-left: -5px;
`

export const ActivityInfo = styled.div`
 display: flex;
 align-items: center;
 .text-container{
    margin-left: 20px;
  }
  .activity-name{
    margin-bottom: 5px;
    font-size: 16px;
    color: @dark-white-color;
    ${props => props.active && lightColorCSS};
  }
  .days{
    color: @gray-current;
    font-size: 13px;
    margin: 0px;
    ${props => props.active && fadedLightColorCSS};
  }
`

export const ActivityDescription = styled.div`
  padding: 20px;
  padding-bottom: 10px;
  p {
    color: fade(#0f2d78, 60);
    font-size: 15px;
  }

`

export const ActivityDaysContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  p > {
    margin-bottom: 0px;
    font-size: 12px;
    color: #20CD8D
  }
`

export const ActivityDay = styled.div`
  width: 100px;
  height: 90px;
  border-radius: 10px;
  background: white;
  border: 1px solid #20CD8D;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  position: relative;
`

export const ActivityDayDisabled = styled(ActivityDay)`
  background: #2a46781a;
  border: 1px solid #2a46781a;
`

export const ActivityBadge = styled.div`
  width: 20px;
  height: 20px;
  background: #20CD8D;
  color: white;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -10px;
`

export const ActivtiyBody = styled.div`
  > button{
    display: block;
    margin: 10px auto;
  }
`

