import React, { useState, useEffect, useCallback } from "react";
import {
  Breadcrumb,
  Button,
  Upload,
  Avatar,
  Row,
  Col,
  // Badge,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import config from "~/config";
import { getBase64 } from "~/utils/file";
import { classTypes } from "~/utils/typesClass";
import Link from "~/components/common/Link";
import Loading from "~/components/common/Loading";
import Divider from "~/components/common/Divider";
import H3 from "~/components/common/H3";
import PageHeader from "~/components/common/PageHeader";
import P from "~/components/common/P";

import {
  fetchChildRegisterations,
  fetchChildPhotoSuccess,
  fetchChildDetails,
} from "~/containers/Children/actions";

import SchoolSVG from "~/assets/images/school.svg";
import {
  ChildDetailsContainer,
  ChildData,
  ChildAvaterCard,
  ChildActiviy,
  ChildSchoolCard,
  RegistrationContainer,
  CTARegister,
  // PendingRegistration,
} from "./components";
import Activity from "./components/Activity";
import ChildRegistration from "./components/ChildRegistration";

const ChildDetails = () => {
  const {
    childDetails: child,
    isFetchingChildDetails,
    children,
    isFetching: isFetchingChildren,
  } = useSelector((state) => state.children);

  const { photoUrl, fetchingPhoto, registrations } = child || {};
  const { t } = useTranslation("children");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeId, setActiveId] = useState(null);
  // const [hidePendingRegistrations, setHidePendingRegistrations] = useState(true)
  /*
  const hasValidatedRegistration = registrations
  && registrations.find(element => element.status === 1)
  */
  // const pendingRegistration = registrations?.filter(item => item.status === 0)
  const { user, accessToken } = useSelector((state) => state.user.auth);

  // const fetchRegisterations = useCallback(async () => {
  //   dispatch(fetchChildRegisterations());
  // }, []);

  const updateChildImage = useCallback(
    async ({ file }) => {
      if (file.status === "done") {
        const url = await getBase64(file.originFileObj);
        dispatch(fetchChildPhotoSuccess(url));
      }
    },
    [dispatch]
  );

  const callToAction = (registration) => {
    if (registration.feedType === "subscription") return;
    switch (registration.status) {
      case 0:
      case 3:
        if (registration.missing_documents.length > 0) {
          navigate(`/registration/${child.alias}/${registration.alias}`);
        }
        break;
      case 4:
        navigate(`/registration/${child.alias}/${registration.alias}`);
        break;
      default:
        break;
    }
    if (registration.renewal_status === 0) {
      navigate(`/registration/${child.alias}/${registration.alias}`);
    }
  };

  useEffect(() => {
    if (!isFetchingChildren) {
      const data = children.find((ch) => ch.alias === id);
      if (data?.url) {
        dispatch(fetchChildDetails({ url: data?.url }));
      } else navigate("/children");
    }
  }, [isFetchingChildren, id]);

  // useEffect(() => {
  //   if (!isFetchingChildDetails) {
  //     fetchRegisterations();
  //   }
  // }, [isFetchingChildDetails]);

  if (isFetchingChildDetails) {
    return <Loading />;
  }

  const displayActivities = (registration) => {
    const displayedActivities = registration.subscriptions.reduce(
      (acc, val) => {
        const currentSub = acc.find(
          (item) => item.activityAlias === val.activityAlias
        );
        if (!currentSub) {
          const sub = JSON.parse(JSON.stringify(val));
          const timeslots = [];
          timeslots.push({
            start_date: val.activityStartDate,
            end_date: val.activityEndDate,
          });
          sub.timeslots = timeslots;
          acc.push(sub);
          return acc;
        }
        currentSub.timeslots.push({
          start_date: val.activityStartDate,
          end_date: val.activityEndDate,
          alias: val.alias,
        });
        const tab = acc.filter(
          (item) => item.activityAlias !== val.activityAlias
        );
        tab.push(currentSub);
        return tab;
      },
      []
    );

    return displayedActivities.map((sub) => (
      <div key={sub.alias}>
        <Activity
          activeId={activeId}
          onActiveChange={setActiveId}
          subscription={sub}
        />
      </div>
    ));
  };

  return (
    <ChildDetailsContainer>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/children">{t("child.nav.link")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {child.first_name} {child.last_name}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div>
          {/*
          hasValidatedRegistration && (
            <Link to={`/subscription/${child.alias}`}>
              <Button type="primary">Nouvelle inscription</Button>
            </Link>
          )
          */}
          <Link to={`/children/edit/${child.alias}`}>
            <Button type="primary">{t("child.nav.modify-profile")}</Button>
          </Link>
        </div>
      </PageHeader>

      <ChildData>
        <ChildAvaterCard>
          <Upload
            name="photo"
            headers={{
              Authorization: `Bearer ${accessToken}`,
            }}
            action={`${config.api.url}users/${user.email}/children/${child.alias}/uploadPhoto/`}
            onChange={updateChildImage}
          >
            {fetchingPhoto && <Loading />}
            {!fetchingPhoto && <Avatar src={photoUrl} size={100} />}
          </Upload>
          <P centered bold className="child-name">
            {child.first_name}
          </P>
        </ChildAvaterCard>
        <ChildActiviy>
          {child.school && (
            <ChildSchoolCard>
              <div className="left-container">
                <Avatar size={60} src={SchoolSVG} />
                <div className="school-info">
                  <P className="school-name">{child.school.name}</P>
                  <P className="child-class-name">
                    {t("child.school.class")}&nbsp;
                    {classTypes[child.classtype]}
                  </P>
                </div>
              </div>
            </ChildSchoolCard>
          )}

          {registrations?.map((reg) => (
            <RegistrationContainer key={reg.alias}>
              <>
                <div className="organisation-city">
                  <P bold inline>
                    {t("child.activites.title")}&nbsp;
                    {reg.organisationCity}
                  </P>
                  {reg.status === 1 && (
                    <Link
                      to="#"
                      className="organisation-new-subscription-button"
                      onClick={() =>
                        navigate(
                          `/subscription/${child.alias}/${reg.alias}`
                        )
                      }
                    >
                      <Button type="primary">
                        {t("child.nav.register-cta")}
                      </Button>
                    </Link>
                  )}
                </div>
                <ChildRegistration
                  onContinueRegistration={() => callToAction(reg)}
                  activeId={activeId}
                  onActiveChange={setActiveId}
                  registration={reg}
                />
                {displayActivities(reg)}
              </>
            </RegistrationContainer>
          ))}
          <Row gutter={[24, 12]} justify="center">
            <Col span={12}>
              <CTARegister
                onClick={() => navigate(`/registration/${child.alias}/`)}
              >
                <H3>{t("child.register-heading")}</H3>
              </CTARegister>
            </Col>
            <Col span="24">
              <Divider />
            </Col>
          </Row>
        </ChildActiviy>
      </ChildData>
    </ChildDetailsContainer>
  );
};

export default ChildDetails;
