import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import P from '~/components/common/P'
import Card from '~/components/common/Card'
import ActivityIcon from '~/components/common/ActivityIcon'
import { colors } from '~/utils/colors'

const SubTitle = styled(P)`
font-size: 13px;
position: absolute;
font-style: italic;
color: ${colors.cvGrey};
`
const ActivityContainer = styled(Card)`
  ${props => (props.alreadyRegistered ? 'border-left: 8px solid  #6D7F9D;' : 'border-left: 8px solid  #F4C26D;')}
  ${props => !props.alreadyRegistered && 'cursor: pointer;'}
  & > div {
    display:flex;
    align-items: center !important;
    padding-right:0px;
    align-items: flex-start;
    padding: 15px 0 15px 15px;
  }
`

const ContentConainer = styled.div`
  flex:1;
  padding-right:10px;
`

const ActivityTitle = styled(P)`
  color: #0f2d78;
  margin-bottom:0;
`
const PhotoContainer = styled.div`
  display: inline-block;
  overflow:hidden;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color:@accent-color;
  margin-right:-20px;
`

const Activity = ({ activity, onChoose, alreadyRegistered }) => (
  <ActivityContainer
    onClick={!alreadyRegistered ? onChoose : undefined}
    alreadyRegistered={alreadyRegistered}
  >
    <ContentConainer>
      <ActivityTitle bold size="big">
        {activity.name}
      </ActivityTitle>
      {alreadyRegistered && <SubTitle>Votre enfant est déjà inscrit à cette activité</SubTitle>}
    </ContentConainer>
    <PhotoContainer>
      <ActivityIcon activityType={activity.activity_type} size={85} />
    </PhotoContainer>
  </ActivityContainer>
)

Activity.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onChoose: PropTypes.func.isRequired,
  alreadyRegistered: PropTypes.bool,
}

Activity.defaultProps = {
  alreadyRegistered: false,
}
export default Activity
