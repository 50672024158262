import React from "react"
import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background-color: @light-blue-background;
 // background-color: var(--light-blue-background);
  overflow: hidden;
`

export const Content = styled.div`
  width: 100%;
  padding: 14%;

  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const ContainerComponent = ({ children, ...props }) => (
  <Container {...props}>
    <Content>{children}</Content>
  </Container>
)

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  // background-color: @light-blue-background;
  background-color: var(--light-blue-background);
  // overflow: auto;
  padding: 24px;
  // height: 69vh
`

export const MainContent = styled.div`
  width: 100%;
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  height: fit-content;
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

export const MainContainerComponent = ({ children, ...props }) => (
  <MainContainer {...props}>
    <MainContent>{children}</MainContent>
  </MainContainer>
)

export default MainContainerComponent
