import { Button, Form, Input, Radio, Select } from "antd"
import React from 'react'
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import Drawer from "~/components/common/Drawer"
import { civilities, relationTypes } from "~/constants"
import useErrors from "~/hooks/useErrors"
import { createContact, updateContact } from "./actions"

const AddContactDrawer = ({
  contact,
  showDrawer,
  setShowDrawer,
  setContact,
}) => {
  console.log({contact})
  
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const { ErrorComponent, setErrors } = useErrors()

  const onSubmit = (values) => {
	  
    if (contact?.alias) {
      dispatch(
        updateContact(
          contact.alias,
          values,
          (errors) => {
            console.log({errors})
            if(!errors) {
              setShowDrawer(false)
            }
            setErrors(errors)
          },
        )
      )
    } else {
      dispatch(
        createContact(
          values,
          (errors) => {
            if(!errors) {
              setShowDrawer(false)
            }
            setErrors(errors)
          },
        )
      )
    }
  }

  useEffect(() => {
    form.setFieldsValue(contact)
  }, [contact])
  
  return (
    <Drawer
      open={showDrawer}
      handleCloseDrawer={() => {
        setShowDrawer(false)
        setContact(null)
        form.resetFields()
      }}
      width={636}
      title="Ajouter / Éditer un contact autorisé"
    >
      <Form layout="vertical"
        size="large"
        form={form}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Civilité"
          name="civility"
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            }
          ]}
        >
          <Radio.Group>
            {civilities.map(({ label, value }, i) => (
              <Radio.Button
                value={value}
                key={i}
                style={{
                  marginBottom: 5,
                  marginRight: 5,
                }}
              >
                {label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Relation"
          name="relationship"
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            }
          ]}
        >
          <Radio.Group>
            {relationTypes.map(({ label, value }, i) => (
              <Radio.Button
                value={value}
                key={i}
                style={{
                  marginBottom: 5,
                  marginRight: 5,
                }}
              >
                {label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Nom"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            }
          ]}
        >
          <Input placeholder=""></Input>
        </Form.Item>
        <Form.Item
          label="Prénom"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            }
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="Tél. portable"
          name="mobile"
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            },
            {
              validateTrigger: 'onBlur',
              message: "⚠️  format invalide ... 📱 👉 06 (ou +336) ou 07 (ou +337) xx xx xx xx",
              validator: (_, value) => {
                console.log({value})
                if (/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[6-7](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/.test(value)) {
                  return Promise.resolve()
                } else {
                  return Promise.reject()
                }
              }
            }
          ]}
        >
          <Input placeholder=""></Input>
        </Form.Item>
        <Form.Item
          label="Téléphone"
          name="phone"
          rules={[
            {
              validateTrigger: 'onBlur',
              message: "⚠️  format invalide ... 📞 👉 01 à 05 (ou +331 à +335) xx xx xx xx",
              validator: (_, value) => {
                console.log({value})
                if (/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-5](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/.test(value) || !value) {
                  return Promise.resolve()
                } else {
                  return Promise.reject()
                }
              }
            }
          ]}
        >
          <Input placeholder=""></Input>
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          // rules={[
          //   {
          //     required: true,
          //     message: "Ce champ ne peut être vide"
          //   }
          // ]}
        >
          <Input placeholder=""></Input>
        </Form.Item>
        {/* <Form.Item
          label="Lien"
          name="link"
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            }
          ]}
        >
          <Radio.Group>
            <Space>
              {contactTypes.map(({ label, value }, i) => (
                <Radio.Button value={value} key={i}>
                  {label}
                </Radio.Button>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item> */}
        <ErrorComponent />
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          style={{ width: "100%", marginTop: 20 }}
          // onClick={() => setShowDrawer(false)}
        >
          Enregistrer
      </Button>
      </Form>
    </Drawer>
  )
}

export default AddContactDrawer