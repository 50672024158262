import { Button, Space } from "antd"
import React, { useMemo } from "react"
import Text from "~/components/common/Text"

export const useColumns = (onStatusChange, setShowDrawer) =>
  useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "",
        defaultSortOrder: "ascend",
        key: "profil",
        sorter: true,
        width: "14%",
        render: ({ date }) => {
          return <Text>{date}</Text>
        },
      },
      {
        title: "N° Facture",
        dataIndex: "",
        key: "households",
        sorter: false,
        width: "14%",
        render: ({ number }) => <Text>{number}</Text>,
      },
      {
        title: "destinataire(s)",
        dataIndex: "",
        key: "school_class",
        sorter: true,
        width: "14%",
        render: ({ dest }) => (
          <Space direction="vertical">
            {dest.map((d) => (
              <Text>{d}</Text>
            ))}
          </Space>
        ),
      },
      {
        title: "Type",
        dataIndex: "",
        key: "status",
        sorter: true,
        width: "14%",
        render: ({ type }) => <Text>{type}</Text>,
      },
      {
        title: "Montant",
        dataIndex: "",
        key: "status",
        sorter: true,
        width: "14%",
        render: ({ sum }) => <Text>{sum}</Text>,
      },
      {
        title: "État",
        dataIndex: "",
        key: "status",
        sorter: true,
        width: "14%",
        render: ({ status }) => <Text>{status}</Text>,
      },
      {
        title: "Action",
        dataIndex: "",
        key: "action",
        width: "14%",
        render: () => (
          <Space size="middle">
            <Button type="link" onClick={() => setShowDrawer(true)}>
              Télécharger
            </Button>
            <Button
              type="link"
              // onClick={() => handleSignup(data)}
            >
              Payer
            </Button>
            {/* <Link
            to={`/family/children/${data.registrationAlias}/edit`}
          > */}
            {/* Éditer
          </Link> */}
          </Space>
        ),
      },
    ],
    [onStatusChange]
  )
