import PropTypes from "prop-types"
import React, { useCallback, useState, useEffect } from "react"
import styled, { css } from "styled-components"
import dayjs from "dayjs"
import { Avatar } from "antd"
import { useSelector } from "react-redux"
import { CheckCircleFilled } from "@ant-design/icons"
import axios from "~/lib/axios"

import malePlaceholder from "~/assets/images/boy.svg"
import femalePlaceholder from "~/assets/images/girl.svg"

import Card from "~/components/common/Card"

// This components is used on list & child's details

const SelectedChild = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  border: 2px solid #20cd8d;
  width: 266px;
  height: 224px;
  margin: 24px;
`

const ValidateIcon = styled.div`
  margin-top: -50px;
  margin-right: -260px;
  overflow: hidden;
  font-size: 36px;
  color: #20cd8d;
  z-index: 10;
`

const Child = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #e2e4e6;
  width: 266px;
  height: 224px;
  margin: 24px;

  ${({$isDisabled}) => $isDisabled && css`
    opacity: 0.3;
  `}
`

const ChildName = styled.span`
  font-size: 20px;
  color: #1E1E1E;
  font-weight: 500;
  margin-top: 15px;
  line-height: 28px;
`

const ChildAge = styled.span`
  color: @neutral-4;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
`

const MainTitle = ({
  child,
  selected,
  isAlreadyRegistered,
  isNotRegisteredToOrg,
  registrationIsNotValidated,
  registrationIsOnRenewalForNextPeriod,
  organisationName,
}) => {
  const { accessToken } = useSelector((state) => state.user.auth)
  /* @ ChD 20231201
  const [imgUrl, setImgUrl] = useState("")

  const getImgSrc = useCallback(async () => {
    try {
      if (child?.photo) {
        const res = await axios.get(child.photo, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "arraybuffer",
        })
        const binData = await Buffer.from(res.data, "binary").toString("base64")
        setImgUrl(`data:image/jpg;base64,${binData}`)
        return
      }
      const url = !child.gender ? malePlaceholder : femalePlaceholder
      setImgUrl(url)
    } catch (error) {
      console.log(error)
      setImgUrl("")
    }
  }, [])

  useEffect(() => {
    getImgSrc()
  }, [])
  */

  const getChildAge = useCallback(() => {
    if (!child.date_of_birth) return ""
    const years = dayjs().diff(child.date_of_birth, "years", false)
    const months = dayjs().diff(child.date_of_birth, "months", false)
    const days = dayjs().diff(child.date_of_birth, "days", false)

    if (years > 0) return `${years} ans`
    if (years === 0 && months > 0) return `${months} mois`
    return `${days} jours`
  }, [])

  if (selected)
    return (
      <SelectedChild>
        <ValidateIcon>
          <CheckCircleFilled style={{ background: "white" }} />
        </ValidateIcon>
        <Avatar size={100} src={child.photo_url} />
        <ChildName>
          {child.first_name} {child.last_name}
        </ChildName>
        <ChildAge>{getChildAge()}</ChildAge>
      </SelectedChild>
    )
  return (
    <>
      <Child
        $isDisabled={isAlreadyRegistered || isNotRegisteredToOrg || registrationIsNotValidated || registrationIsOnRenewalForNextPeriod}
      >
        <Avatar size={100} src={child.photo_url} />
        <ChildName>
          {child.first_name} {child.last_name}
        </ChildName>
        <ChildAge>{getChildAge()}</ChildAge>
      </Child>
      {isAlreadyRegistered && (
        <p style={{textAlign: "center", color: "#20cd8d"}}>Déjà inscrit{child?.gender === 1 && 'e'}!</p>
      )}
      {isNotRegisteredToOrg && (
        <p style={{textAlign: "center", color: "#FF5876"}}>Non inscrit{child?.gender === 1 && 'e'} à {organisationName}!</p>
      )}
      {!isNotRegisteredToOrg && (registrationIsNotValidated || registrationIsOnRenewalForNextPeriod) && (
        <p style={{textAlign: "center", color: "#FF5876"}}>Inscription à {organisationName} <br />en attente de validation !</p>
      )}
    </>
  )
}

MainTitle.propTypes = {
  child: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default MainTitle
