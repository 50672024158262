import axios from '~/lib/axios';

export const login = async ({ email, password }) => (
  axios('users/login/', {
    method: 'POST',
    data: {
      username: email,
      password,
    },
  })
);

export const fetchProfile = async ({ email }) => axios.get(`users/${email}/`);

export const fetchNewAccessToken = ({ refreshToken }) => axios.post('token/refresh/', {
  refresh: refreshToken,
});

export const resetPasswordConfirm = ({
  uid,
  token,
  newPassword,
}) => axios.post('auth/users/reset_password_confirm/', {
  uid,
  token,
  new_password: newPassword,
});

export default {
  login,
  fetchProfile,
  fetchNewAccessToken,
  resetPasswordConfirm,
};
