import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import {
  Footer,
} from './components'
import packageInfo from '../../../../../package.json'

const FooterComponent = () => {
  const { t } = useTranslation('common')
  const year = dayjs().year()

  return (
    <Footer>
      <div>
        {t('footer.title', { year, appVersion: packageInfo.version })}
      </div>
      <div>
        <div>
          <a
            className="link"
            href={t('footer.aboutus-url')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.aboutus')}
          </a>
        </div>
      </div>

      {/*
      <div>
        <div>
          <a
            className="link"
            href={t('footer.cgu-url')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.cgu-title')}
          </a>
        </div>
        <div>
          <a
            className="link"
            href={t('footer.politique-protected-data-url')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.politique-protected-data-title')}
          </a>
        </div>
      </div>*/}
    </Footer>
  )
}

export default FooterComponent
