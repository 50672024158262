
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import H1 from '~/components/common/H1'
import Space from '~/components/common/Space'
import ContentHeader from '~/components/common/ContentHeader'
import MainContainer from '~/components/common/Container'
import FilterContainer from '~/components/common/FilterContainer'
import Table from '~/components/common/Table'
import { fetchAllOrders } from '~/containers/Cart/actions'
import { getOrderStatus } from '~/constants/orderStatusCommande'
import { useColumns } from './columns'

import { useQuery } from "@apollo/client";
import { ORDERS } from "../../graphql/queries";
import OrdersTable from '~/containers/Orders/ordersTable'

const Orders = () => {
  return (
    <>
      <ContentHeader
        breadcrumbItems={[
          {
            name: 'Mes Commandes',
            path: '/commandes',
          },
        ]}
      >
        <H1>Mes Commandes</H1>
      </ContentHeader>
      <OrdersTable />
    </>
  )
}

export default Orders

