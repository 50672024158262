import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import React, { useState, useEffect, useCallback } from "react"

import { Steps, Row, Col, message, Avatar } from "antd"
import { useTranslation } from "react-i18next"
import {
  activitiesFetching,
  activityDetailsFetching,
  activitySlotsFetching,
  activityAllSlotsFetching,
  subscriptionCreating,
} from "~/containers/Subscription/actions"

import { fetchChildDetails } from "~/containers/Children/actions"

import { childRegistrationFetching } from "~/containers/Registration/actions"

import H3 from "~/components/common/H3"
import PageHeader from "~/components/common/PageHeader"
import Card from "~/components/common/Card"
import Child from "~/components/common/Child"
import Loading from "~/components/common/Loading"
import Activity from "./components/Activity"
import ActivityDetails from "./components/ActivityDetails"
import Location from "./components/Location"
import Slot from "./components/Slot"
import ActivityCalendar from "./components/ActivityCalendar"

import {
  StepsContainer,
  StepContainer,
  ChildContainer,
  StepButtonContainer,
  StepButton,
} from "./components"

const { Step } = Steps
const nbrSteps = 6

const Subscription = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const {
    children,
    childDetails: child,
    loading,
  } = useSelector((state) => state.children)
  const {
    list: activitiesList,
    loading: isFetchingActivities,
    details: activityDetails,
    slots,
  } = useSelector((state) => state.subscription.activity)

  const { isCreatingSubscription } = useSelector((state) => state.subscription)
  const [currentStep, setCurrentStep] = useState(params.child_alias ? 1 : 0) // : 0
  const [skipLocation, setSkipLocation] = useState(false)
  const [skipSlot, setSkipSlot] = useState(false)
  const [canContinue, setCanContinue] = useState(!params.child_alias)
  const [canGoBack, setCanGoBack] = useState(true)
  const [chosenChildAlias, setChosenChildAlias] = useState(params.child_alias)
  const [chosenActivity, setChosenActivity] = useState(null)
  const [detailedActivityAlias, setDetailedActivityAlias] = useState([])
  const [chosenDays, setChosenDays] = useState({})
  const [chosenLocation, setLocation] = useState(null)
  const [stepToHide, setStepToHide] = useState([])
  const stepsList = [
    { id: "child", element: <Step id="child" key="child" title="Enfant" /> },
    {
      id: "activity",
      element: <Step id="activity" key="activity" title="Activité" />,
    },
    {
      id: "details",
      element: <Step key="details" description="Détails de l'activité" />,
    },
    { id: "location", element: <Step key="location" description="Lieu" /> },
    { id: "slots", element: <Step key="slots" description="Créneaux" /> },
    {
      id: "calendar",
      element: <Step key="calendar" description="Calendrier" />,
    },
  ]
  const currentRegistrationAlias = params.registration_alias

  const { t } = useTranslation("subscription")

  const submitSubscription = () => {
    const data = { activityAlias: chosenActivity.alias }
    if (chosenActivity.period_selection === 1)
      data.activityBuildingAlias = detailedActivityAlias
    else if (chosenLocation) {
      const activity = chosenActivity.activities.filter(
        (act) => act.building === chosenLocation.alias
      )[0]
      data.activityBuildingAlias = [activity.alias]
    }
    dispatch(
      subscriptionCreating(chosenChildAlias, { ...data, ...chosenDays }, () => {
        navigate(`/children/${chosenChildAlias}`)
        message.success("Inscription crée avec succès")
      })
    )
  }

  const goToStep = (direction) => {
    let step
    if (direction === "next") {
      step = currentStep + 1
      if (step === 3 && skipLocation) step += 1
      if (step === 4 && skipSlot) step += 1
    } else {
      if (currentStep === 1) {
        navigate(-1)
      }
      step = currentStep - 1
      if (step === 4 && skipSlot) step -= 1
      if (step === 3 && skipLocation) step -= 1
    }

    setCanContinue(true)
    setCanGoBack(step > 0)
    if (step === 0) {
      setChosenChildAlias(null)
      setCanContinue(false)
    } else if (step === 1) {
      setCanContinue(false)
    } else if (step === 3) {
      setCanContinue(false)
    } else if (step === 4) {
      setCanContinue(false)
    } else if (step === 5) {
      if (!skipSlot) {
        submitSubscription()
        return
      }
    } else if (step === 6) {
      submitSubscription()
    }

    if (step >= 0 && step < nbrSteps) setCurrentStep(step)
  }

  const fetchActivities = useCallback(
    async (alias) => dispatch(activitiesFetching(alias)),
    []
  )

  const onChooseChild = (ch) => {
    setChosenChildAlias(ch.alias)
    navigate(`/subscription/${ch.alias}`)
  }

  const isAlreadyRegistered = (activity) => {
    const sub = child.subscriptions.find(
      (item) =>
        item.parent_activity_alias === activity.alias && item.status === 1
    )
    if (sub) {
      return true
    }
    return false
  }

  const onChooseActivity = (activity) => {
    const isRegistred = !isAlreadyRegistered(activity)
    if (isRegistred) {
      goToStep("next")
      setSkipLocation(activity.building_choice !== 1)
      setSkipSlot(activity.period_selection !== 1)
      setChosenActivity(activity)
      dispatch(activityDetailsFetching(activity.organisation, activity.alias))
    } else {
      message.warning("Cet enfant est déja inscrit a cette activité.")
    }
  }

  const onChooseLocation = (location) => {
    goToStep("next")
    setLocation(location)
    dispatch(
      activitySlotsFetching(
        chosenActivity.organisation,
        chosenActivity.alias,
        location.alias
      )
    )
  }

  const onSelectSlot = (alias) => {
    if (detailedActivityAlias.includes(alias)) {
      setDetailedActivityAlias(
        detailedActivityAlias.filter((item) => item !== alias)
      )
    } else {
      setDetailedActivityAlias([alias, ...detailedActivityAlias])
    }
  }

  // To choose which organisation to use to fetch activities
  const getCurrentActiveRegistration = (registrations) => {
    if (currentRegistrationAlias) {
      return registrations.find(
        (item) => item.alias === currentRegistrationAlias && item.status === 1
      )
    }
    return registrations.find((item) => item.status === 1)
  }

  useEffect(() => {
    if (chosenActivity) {
      dispatch(
        activityAllSlotsFetching(
          chosenActivity.organisation,
          chosenActivity.alias
        )
      )
    }
  }, [chosenActivity])

  useEffect(() => {
    if (chosenChildAlias) {
      dispatch(
        childRegistrationFetching(chosenChildAlias, (registrations) => {
          const currentRegistration =
            getCurrentActiveRegistration(registrations)
          if (currentRegistration) {
            fetchActivities(currentRegistration.organisationAlias)
            if (currentStep === 0) {
              navigate(
                `/subscription/${chosenChildAlias}/${currentRegistration.alias}`
              )
              goToStep("next")
            }
          } else {
            message.warning("Cet enfant n'est pas encore inscrit")
            navigate("/subscription")
            setCurrentStep(0)
          }
        })
      )

      const data = children.find((ch) => ch.alias === chosenChildAlias)
      dispatch(fetchChildDetails({ url: data?.url }))
    }
  }, [chosenChildAlias])

  useEffect(() => {
    setCanContinue(detailedActivityAlias.length > 0)
  }, [detailedActivityAlias])

  useEffect(() => {
    let sToHide = []
    if (currentStep > 1) {
      if (skipSlot) {
        sToHide = [...sToHide, "slots"]
        sToHide = sToHide.filter((item) => item !== "calendar")
      } else {
        sToHide = sToHide.filter((item) => item !== "slots")
        sToHide = [...sToHide, "calendar"]
      }

      if (skipLocation) {
        sToHide = [...sToHide, "location"]
      } else {
        sToHide = sToHide.filter((item) => item !== "location")
      }
    } else {
      sToHide = ["location", "slots", "calendar"]
    }

    const elemChild = document.getElementById("child")
    const elemActivity = document.getElementById("activity")
    if (elemChild && elemActivity) {
      elemChild.style.display = "none"
      elemActivity.style.display = "none"
    }

    setStepToHide(sToHide)
  }, [skipLocation, skipSlot, currentStep])

  const showButtonText = () => {
    let text = t("next-step")
    if (currentStep > 3) text = t("register-cta")
    return text
  }

  return (
    <>
      <PageHeader>
        {chosenChildAlias ? (
          <Row align="middle" justify="space-between">
            <Avatar
              src={child.photoUrl}
              size={30}
              style={{ marginRight: 20 }}
            />
            <H3 as="h1">
              {t("header")} - {child.first_name} {child.last_name} à{" "}
              {activitiesList[0]?.organisationName}
            </H3>
          </Row>
        ) : (
          <H3 as="h1">{t("header")}</H3>
        )}
      </PageHeader>
      {currentStep > 1 && (
        <StepsContainer>
          <Row justify="center">
            <Col span={14}>
              <Steps current={currentStep} progressDot>
                {stepsList
                  .filter((item) => !stepToHide.includes(item.id))
                  .map((item) => item.element)}
              </Steps>
            </Col>
          </Row>
        </StepsContainer>
      )}
      <br />

      <Card>
        <StepContainer>
          {loading && <Loading />}

          {currentStep === 0 && (
            <Row gutter={[24, 24]} justify="center">
              {!loading &&
                children.map((ch) => (
                  <Col span={8} key={ch.alias}>
                    <ChildContainer onClick={() => onChooseChild(ch)}>
                      <Child child={ch} />
                    </ChildContainer>
                  </Col>
                ))}
            </Row>
          )}

          {currentStep === 1 && (
            <Row gutter={[24, 24]} justify="center">
              {isFetchingActivities && <Loading />}
              {!isFetchingActivities &&
                activitiesList.map((activity) => (
                  <Col span={8} key={activity.alias}>
                    <Activity
                      activity={activity}
                      onChoose={() => onChooseActivity(activity)}
                      alreadyRegistered={isAlreadyRegistered(activity)}
                    />
                  </Col>
                ))}
            </Row>
          )}

          {currentStep === 2 && (
            <Row gutter={[24, 24]} justify="center">
              lll
              {isFetchingActivities && <Loading />}
              {/* <Col span={24} offset={6}> */}
              {activityDetails && (
                <ActivityDetails activity={activityDetails} />
              )}
              {/* </Col> */}
            </Row>
          )}

          {currentStep === 3 && (
            <Row gutter={[24, 24]} justify="center">
              {activityDetails &&
                activityDetails.locations.map((location) => (
                  <Col span={8} key={location.alias}>
                    <Location
                      location={location}
                      onChoose={() => onChooseLocation(location)}
                    />
                  </Col>
                ))}
            </Row>
          )}

          {currentStep === 4 && (
            <Row gutter={[24, 24]} justify="center">
              {isFetchingActivities && <Loading />}
              {!isFetchingActivities &&
                slots
                  .filter((slot) => {
                    if (!slot.remaining_slots || slot.remaining_slots > 0) {
                      return slot
                    }
                    return null
                  })
                  .map((slot) => (
                    <Col span={8} key={slot.alias}>
                      <Slot
                        slot={slot}
                        onChoose={() => onSelectSlot(slot.alias)}
                        selected={detailedActivityAlias.includes(slot.alias)}
                      />
                    </Col>
                  ))}
              {!slots && <span>Aucune disponibilité</span>}
            </Row>
          )}

          {currentStep === 5 && (
            <Row>
              <Col span={10} offset={7}>
                <ActivityCalendar
                  activity={activityDetails}
                  onChoose={(data) => setChosenDays(data)}
                />
              </Col>
            </Row>
          )}
        </StepContainer>
        {currentStep > 0 && (
          <StepButtonContainer>
            <StepButton disabled={!canGoBack} onClick={() => goToStep("back")}>
              {t("prev-step")}
            </StepButton>
            <StepButton
              disabled={!canContinue}
              type="primary"
              onClick={() => goToStep("next")}
              loading={
                (currentStep === 5 && isFetchingActivities) ||
                isCreatingSubscription
              }
            >
              {showButtonText()}
            </StepButton>
          </StepButtonContainer>
        )}
      </Card>
    </>
  )
}

export default Subscription
