import styled from 'styled-components'

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  >p{
    margin-bottom: 3px;
    margin-left: 5px;
    font-size: 12px;
  }
`

export const StyledDate = styled.div`
  border-radius: 50%;
  background: ${props => props.color};
  width: 25px;
  height: 25px;
  text-align: center;
  color: ${props => (props.textColor ? props.textColor : 'white')};
  margin: auto;
  font-size: 12px;
  padding-top: 3px;
`

export const Container = styled.div`
  width: 450px; 
  margin-bottom: 20px;
  margin: auto;
`
export const CalendarHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;

  .anticon{
    font-size: 20px;
  }
`
