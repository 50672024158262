import {
  takeLatest,
  put,
  select,
  all,
} from 'redux-saga/effects'

// import { backofficeAxios as axios } from '~/lib/axios'
import axios from '~/lib/axios'

import { checkJwtExpiration } from '~/containers/User/sagas'
import {
  getAuthUserInfos,
} from '~/containers/User/selectors'

import {
  householdFetching,
  householdFetched,
  householdFetchError,

  // update household
  householdUpdating,
  householdUpdated,
  householdUpdateError,
  
  // fetch documents
  documentsFetching,
  documentsFetched,
  documentsFetchError,

  // fetch missing documents
  missingCommonDocumentsFetching,
  missingCommonDocumentsFetched,
  missingCommonDocumentsFetchError,

  // add new document
  addNewDocument,
  addNewDocumentProcess,
  addNewDocumentSuccess,
  addNewDocumentError,
} from './actions'
import { logout } from '~/containers/User/actions'
import { message } from 'antd'
import { fetchChildRegistrations } from '~/containers/Registration/sagas'
import { fetchAllRegistrations } from '~/containers/Registration/actions'

function* fetchHousehold() {
  try {
    yield* checkJwtExpiration()
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(householdFetchError())
      yield put(logout())
    }
    const response = yield axios.get(`users/${userInfos.email}/household/`)
    yield put(householdFetched(response.data.results))
  } catch (err) {
    console.log(err)
    yield put(householdFetchError())
  }
}

function* updateHouseholdSaga({payload}) {
  
  try {
    yield* checkJwtExpiration()
    // yield put(householdUpdating());

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.patch(`users/${authUserInfos.email}/household/${payload.household}/`, {
        ...payload.data,
      })
      
      yield put(householdUpdated(response.data))
      yield put(householdFetching())
      payload.onSuccess()
      message.success("Foyer mis à jour")
    } else {
      
      yield put(householdUpdateError())
      message.error("Erreur lors de la mise à jour du Foyer")
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    // errror toast
    message.error("Erreur lors de la mise à jour du Foyer")
    yield put(householdUpdateError())
  }
}

function* fetchDocuments() {
  try {
    yield* checkJwtExpiration()
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(documentsFetchError())
      yield put(logout())
    }
    const response = yield axios.get(`users/${userInfos.email}/documents/?page_size=1000`)
    
    yield put(documentsFetched(response.data.results))
  } catch (err) {
    console.log(err)
    yield put(documentsFetchError())
  }
}

function* fetchMissingCommonDocuments({payload}) {
  console.log({payload})
  try {
    yield* checkJwtExpiration()
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(missingCommonDocumentsFetchError())
      yield put(logout())
    }
    const response = yield axios.post(`users/${userInfos.email}/documents/missing_common_documents/`, payload)
    
    yield put(missingCommonDocumentsFetched(response?.data))
  } catch (err) {
    console.log(err)
    yield put(missingCommonDocumentsFetchError())
  }
}

function* addNewDocumentSaga({ payload }) {
  try {
    yield* checkJwtExpiration()
    yield put(addNewDocumentProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.post(`users/${authUserInfos.email}/documents/`, payload.formData);
      console.log({response})
      yield put(addNewDocumentSuccess(response.data));
      yield put(documentsFetching());
      payload.onSuccess(response.data);

      yield put(fetchAllRegistrations())

      message.success("Document ajouté!");

      if (payload?.childAlias) {
        console.log({payload})
        yield* fetchChildRegistrations({
          payload: {
            childAlias: payload?.childAlias,
          }
        })
      } else {
        yield* fetchHousehold()
      }
    } else {
      
      yield put(addNewDocumentError())
      message.error("Erreur lors de l'ajout de document")
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    // errror toast
    message.error("Erreur lors de l'ajout de document")
    yield put(addNewDocumentError())
  }
}

function* rootSaga() {
  yield takeLatest(householdFetching, fetchHousehold)
  yield takeLatest(householdUpdating, updateHouseholdSaga)
  yield takeLatest(documentsFetching, fetchDocuments)
  yield takeLatest(missingCommonDocumentsFetching, fetchMissingCommonDocuments)
  yield takeLatest(addNewDocument, addNewDocumentSaga)
}

export default rootSaga

