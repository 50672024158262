import styled from 'styled-components'
import { Divider } from 'antd'

const CustomDivider = styled(Divider)`
 height: 1px;
 background-color: #e2e2e2;
 margin: 5px 0px;
`

export default CustomDivider

