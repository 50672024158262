
import React, {
  useCallback,
} from 'react'
import styled from 'styled-components'
import {
  AutoComplete,
  Input,
  Select,
  Skeleton,
  Tooltip,
} from 'antd'
import debounce from 'lodash/debounce'
import Space from '~/components/common/Space'
import PrimaryButton from '~/components/common/PrimaryButton'
import {
  Container,
  RightContent,
  CustomSearch,
} from './components'

const { Option } = Select
const { Search } = Input

const FilterContainer = ({
  onPageSizeChange,
  defaultPageSize,
  onSearch,
  filterButtons,
  buttonsAction,
  isLoading = false,
  isFailed = false,
  isBills = false,
  className,
  ...props
}) => {
  const setSearchDebounced = useCallback(
    debounce(onSearch, 300),
    [onSearch],
  )

  const tooltipOrders = <span>Recherche par Organisation, Participants, Montant (ex 11.00)</span>
  const tooltipBills = <span>Recherche par Organisation, N° de Facture, Montant (ex 11.00)</span>

  return (
    <Container
      className={className} // enables styled components override
      {...props}
    >
      <Space
        size="middle"
      >
        {isLoading && (
          <>
            {/* {isFailed && (
            <Skeleton.Input
              style={{ width: 180 }}
              active
            />
            )} */}

            <Skeleton.Input
              style={{ width: 115 }}
              active
            />
            <Skeleton.Input
              style={{ width: 200 }}
              active
            />
          </>
        )}

        {/* {isFailed ? <PrimaryButton style={{ marginTop: 5 }}>
          Commander à nouveau</PrimaryButton> : '' } */}

        {!isLoading && (
          <>
            <Select
              defaultValue={defaultPageSize}
              onChange={onPageSizeChange}
              style={{ marginBottom: 5 }}
            >
              <Option
                value={10}
              >
                10 par page
              </Option>
              <Option
                value={25}
              >
                25 par page
              </Option>
              <Option
                value={100}
              >
                100 par page
              </Option>
            </Select>

            <Tooltip placement="right" title={isBills ? tooltipBills : tooltipOrders} overlayStyle={{ maxWidth: '250px' }}>
              <AutoComplete
                placeholder="Rechercher..."
                allowClear
                autoFocus
                // enterButton
                onSearch={setSearchDebounced}
                style={{ marginBottom: 5, width: '200px' }}
              />
            </Tooltip>
          </>
        )}
        {filterButtons && (
          <Space>
            {isLoading && (
              <>
                <Skeleton.Button
                  style={{ width: 100 }}
                  active
                />
                <Skeleton.Button
                  style={{ width: 130 }}
                  active
                />
                <Skeleton.Button
                  style={{ width: 100 }}
                  active
                />
                <Skeleton.Button
                  style={{ width: 70 }}
                  active
                />
                <Skeleton.Button
                  style={{ width: 70 }}
                  active
                />
                <Skeleton.Button
                  style={{ width: 70 }}
                  active
                />
              </>
            )}
            {!isLoading && filterButtons()}
          </Space>
        )}
      </Space>
      {buttonsAction && (
        <RightContent>
          {isLoading && (
            <Skeleton.Button
              style={{ width: 80, float: 'right' }}
              shape="default"
              active
            />
          )}
          {!isLoading && buttonsAction()}
        </RightContent>
      )}
    </Container>
  )
}

export default styled(FilterContainer)`` // enable custom styles override
