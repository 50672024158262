import React from "react"
import { Dropdown } from "antd"
import { Link } from "react-router-dom"
import Picture from "~/components/common/Picture"
import Text from "~/components/common/Text"
import {
  CustomCard,
  MiniTag,
  Name,
  Email,
  CustomButton,
  Options,
  CustomSpace,
} from "./components"
import { parentStatus } from "~/constants/index"
import { useSelector, useDispatch } from "react-redux"

const ParentCard = ({
  menu,
  photo,
  last_name,
  first_name,
  email,
  mobile,
  alias,
  status,
}) => {
  const userMail = useSelector((state) => state.user.auth.user.email)

  return (
    <CustomCard>
      {/* <MiniTag color={parentStatus[status]?.color}>
        {parentStatus[status]?.label}
      </MiniTag> */}
      {/* <Dropdown overlay={menu} trigger={["click"]}>
        <Options ghost>...</Options>
      </Dropdown> */}
      <CustomSpace>
        <Picture
          width="100"
          height="100"
          src={photo}
          alt={`${first_name} ${last_name}`}
          style={{ margin: "0 auto" }}
        />
        <Name>
          {first_name} {last_name}
        </Name>
        <Email href={`mailto:${email}`}>{email}</Email>

        <p style={{fontSize: 16}}>{mobile}</p>
        {email === userMail && (
          <Link to={`/profile`}>
            <CustomButton>Voir la fiche</CustomButton>
          </Link>
        )}
      </CustomSpace>
    </CustomCard>
  )
}

export default ParentCard
