import styled from 'styled-components'
import { Alert } from 'antd'

const CustomAlert = styled(Alert)`
 margin: 5px 0px;
 & > span{
    .link-underline{
        text-decoration: underline;
        color: inherit;
    }
 }
`

export default CustomAlert
