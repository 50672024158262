import styled, { css } from "styled-components"
import { Space } from "antd"

const card = css`
  max-width: 266px;
  min-width: 266px;
  position: relative;
  text-align: center;
  border-radius: 4px;
  border-color: #e2e4e6;
  margin-bottom: 23px;
  .ant-card-body {
    padding: 0;
  }
`
export const CustomCardDushed = styled(Space)`
  ${card};
  border: 2px dashed #e2e4e6;
  min-height: 266px;
  display: flex;
  flex: 1;
  height: 100%;

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100% !important;
    border: none;
    color: #969696;
  }
`
