import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "~/lib/axios";
import PageLoading from "~/components/common/PageLoading";
import { Button, Result } from "antd";
import styled from "styled-components";
import { colors } from "~/utils/colors";

const PaymentResult = () => {
  const location = useLocation();
  const idop = new URLSearchParams(location.search).get("idop");
  const env = new URLSearchParams(location.search).get("env");
  const [inexistantIdop, setInexistantIdop] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentResultData, setPaymentResultData] = useState(null);
  const [counter, setCounter] = useState(5);

  const fetchPaymentResult = async () => {
    setIsLoading(true);
    try {
      let value = "https://04.dev.cityviz.io/api/";

      if (env === "DEV01") {
        value = "https://01.dev.cityviz.io/api/";
      } else if (env === "DEV02") {
        value = "https://02.dev.cityviz.io/api/";
      } else if (env === "DEV03") {
        value = "https://03.dev.cityviz.io/api/";
      } else if (env === "DEV04") {
        value = "https://04.dev.cityviz.io/api/";
      } else if (env === "PRODUCTION") {
        value = "https://app.cityviz.io/api/";
      }
      axios.defaults.baseURL = value;
      const response = await axios.post("paymentStatus/", {
        idop,
      });
      console.log({ response });
      setIsLoading(false);
      setPaymentResultData(response?.data);
    } catch (error) {
      setIsLoading(false);
      setInexistantIdop(true);
      console.log({ error });
    }
  };

  useEffect(() => {
    if (idop) {
      fetchPaymentResult();
    }
  }, [idop]);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter]);

  useEffect(() => {
    if (
      paymentResultData?.redirect_url &&
      !paymentResultData?.online_payment_pending
    ) {
      setTimeout(() => {
        window.document.location = `${paymentResultData?.redirect_url}/?payment_result=${paymentResultData?.payment_result}`;
      }, 5000);
    }
  }, [paymentResultData]);

  console.log({ paymentResultData, idop });

  if (isLoading) {
    return <PageLoading />;
  }

  if (!idop || inexistantIdop) {
    return (
      <Container>
        <Result
          status="warning"
          title={
            <p>
              Vous êtes perdu ?
              <br />
              Merci de s'authentifier ou bien contacter l'administrateur
            </p>
          }
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => {
                window.document.location = "/login";
              }}
            >
              S'authentifier
            </Button>
          }
        />
      </Container>
    );
  }

  const paymentSuccess = paymentResultData?.payment_result === 0
  console.log({paymentResultData, paymentSuccess})

  return (
    <Container>
      <Result
        title={
          !paymentResultData?.online_payment_pending
            ? (
              <>
                {paymentSuccess
                  ? (
                    <p style={{color: colors.cvGreen, fontSize: 18, marginBottom: 0, fontWeight: 'bold'}}>
                      Paiement accepté
                    </p>
                  )
                  : (
                    <p style={{color: colors.cvRed, fontSize: 18, marginBottom: 0, fontWeight: 'bold'}}>
                      Paiement refusé
                    </p>
                  )
                }
              </>
            )
            : "Votre opération est en cours de traitement"
        }
        status={paymentSuccess ? 'success' : 'warning'}
        subTitle={
          paymentSuccess
            ? 
              <>
                <p style={{color: colors.cvGreen}}>
                  Merci d'avoir effectué cette opération
                </p>
                <p style={{textAlign: 'center'}}>
                  Redirection dans {counter} secondes
                  <br />
                  ou
                </p>
              </>
            : (
              <>
                <p style={{color: colors.cvRed}}>
                  merci d'effectuer de nouveau l'opération
                </p>
                <p style={{textAlign: 'center'}}>
                  Redirection dans {counter} secondes
                  <br />
                  ou
                </p>
              </>
            )
        }
        extra={
          paymentResultData?.online_payment_pending
            ? [
                <Button
                  type="primary"
                  key="console"
                  onClick={() => {
                    fetchPaymentResult();
                  }}
                >
                  Cliquez ici pour revérifier le status du paiement
                </Button>,
                <Button
                  type="primary"
                  key="console"
                  onClick={() => {
                    window.document.location = paymentResultData?.redirect_url;
                  }}
                >
                  Cliquez ici pour revenir à Cityviz
                </Button>,
              ]
            : [
                <Button
                  type="primary"
                  key="console"
                  onClick={() => {
                    window.document.location = `${paymentResultData?.redirect_url}/?payment_result=${paymentResultData?.payment_result}`;
                  }}
                >
                  Cliquer ici pour être redirigé
                </Button>,
              ]
        }
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  height: 100vh;
`;

export default PaymentResult;