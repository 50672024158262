

import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import MainContainer from '~/components/common/Container'
import ContentHeader from '~/components/common/ContentHeader'
import FilterContainer from '~/components/common/FilterContainer'
import Space from '~/components/common/Space'
import Table from '~/components/common/Table'
import H1 from '~/components/common/H1'
import { downloadPdf, fetchBills } from '~/containers/Bills/actions'
import { StatusCircles } from '~/containers/User/UserProfile/components/Styles/invoice'
import { useColumnsInvoices } from '~/containers/Bills/columns'
import { Container } from './components'

import { useQuery } from "@apollo/client";
import { BILLS } from "../../graphql/queries";
import BillsTable from '~/containers/Bills/components/table'

const Bills = () => {
  return (
    <>
      <ContentHeader
        breadcrumbItems={[
          {
            name: 'Mes Factures',
            path: '/factures',
          },
        ]}
      >
        <H1>Mes Factures</H1>
      </ContentHeader>
      <BillsTable />
    </>
  )
}

export default Bills
