import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { Table, Skeleton } from "antd"
import isFunction from "lodash/isFunction"
import random from "~/utils/random"

const useSkeletonColumns = (columns) =>
  useMemo(
    () =>
      columns.map((d, index) => ({
        ...d,
        dataIndex: `field${index}`,
        render: () => (
          <Skeleton.Input
            style={{ width: `${random(100, 200)}px` }}
            size="small"
            active
          />
        ),
      })),
    [columns]
  )

const useSkeletonSource = (pageSize) =>
  useMemo(() => [...Array(pageSize)].map((_, index) => ({ key: index })), [
    pageSize,
  ])

const CustomTable = styled(Table)`
  .ant-table-thead .ant-table-cell:not(.ant-table-selection-column) {
    color: @neutral-4;
    font-size: @font-size-xs;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
    margin-top: 24px;
  }

  /** don't know why this happen, the expand icon is displayed */
  .ant-table-row-expand-icon {
    display: none;
  }
`

const paginationDefaults = {
  hideOnSinglePage: true,
  showSizeChanger: false,
  showLessItems: true,
  showTotal: false,
}

// compute properties into one single pagination object
const usePaginationAttributes = (
  pagination = {},
  pageSize,
  total,
  currentPage
) =>
  useMemo(
    () => ({
      ...paginationDefaults,
      ...(pageSize != null ? { pageSize } : {}),
      ...(total != null ? { total } : {}),
      ...(currentPage != null ? { current: currentPage } : {}),
      ...pagination,
    }),
    [pagination, pageSize, total, currentPage]
  )

const TableComponent = ({
  isLoading,
  pageSize,
  currentPage,
  dataSource,
  columns,
  pagination,
  total,
  onRowSelectionChange = null,
  ...props
}) => {
  // 
  const skeletonColumns = useSkeletonColumns(columns)
  const skeletonSource = useSkeletonSource(pageSize)
  const paginationAttributes = usePaginationAttributes(
    pagination,
    pageSize,
    total,
    currentPage
  )
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const hasRowSelection = isFunction(onRowSelectionChange)

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  }
  // 

  useEffect(() => {
    if (hasRowSelection) {
      onRowSelectionChange(selectedRowKeys)
    }
  }, [hasRowSelection, onRowSelectionChange, selectedRowKeys])

  return (
    <CustomTable
      columns={isLoading ? skeletonColumns : columns}
      dataSource={isLoading ? skeletonSource : dataSource}
      pagination={paginationAttributes}
      rowSelection={hasRowSelection ? rowSelection : null}
      {...props}
    />
  )
}

export default TableComponent
