import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { MenuOutlined } from "@ant-design/icons"
import { logout } from "~/containers/User/actions"

import Link from "~/components/common/Link"
import { DrawerHeader, DrawerWrap, MenuDrawer, NavBarItem } from "./components"
import { useNavigate } from "react-router-dom"

const DrawerMenu = () => {
  const { t } = useTranslation("common")
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()
  const logOut = useCallback(() => {
    dispatch(logout())
    navigate('/login')
  }, [dispatch])
  const navigation = [
    {
      route: "/",
      text: t("header.nav.dashboard"),
    },
    {
      route: "/children",
      text: t("header.nav.children"),
    },
    {
      route: "/bills",
      text: t("header.nav.bills"),
    },
  ]

  const showDrawer = () => {
    setVisible(true)
  }
  const onClose = () => {
    setVisible(false)
  }

  return (
    <DrawerHeader>
      <MenuOutlined
        onClick={showDrawer}
        style={{ fontSize: "30px", paddingTop: "20px", color: "white" }}
      />
      <DrawerWrap
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
      >
        <MenuDrawer>
          <NavBarItem>
            <Link to="/password_update" onClick={onClose}>
              Modifier le mot de passe
            </Link>
          </NavBarItem>
          <NavBarItem>
            <Link to="/profile" onClick={onClose}>
              {t("header.nav.drop-down.profile")}
            </Link>
          </NavBarItem>
          <NavBarItem onClick={logOut}>
            {t("header.nav.drop-down.logout")}
          </NavBarItem>
        </MenuDrawer>
      </DrawerWrap>
    </DrawerHeader>
  )
}

export default DrawerMenu
