import React, { useCallback, useState, useEffect } from "react"
import {
  LeftOutlined,
  RightOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  CalendarOutlined,
} from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"
import Space from "~/components/common/Space"
import H1 from "~/components/common/H1"
import { getCurrentMonthDates, firstAndLastDayOfMonth } from "./functions"
import Drawer from "~/components/common/Drawer"
import { Modal, message, Button } from "antd"

import {
  fetchChildren,
  fetchChildDetails,
} from "~/containers/Children/actions"
import {
  Calendar,
  Horizontal,
  Border,
  DayContainer,
  DayLetter,
  DayNumber,
  Day,
  OrgContainer,
  ActivityContainer,
  ActivityName,
  Case,
  Recurrent,
  Description,
  DescriptionContainer,
  Dot,
  DynamicPadding,
  DescWrapper,
} from "./component"
import {
  fetchChildAttendances,
  fetchOpeningdays,
  fetchAllOpeningdays,
  allChildrenAttendancesFetch,
  allChildrenAttendancesFetching,
  patchAttendance,
  createAttendance,
} from "~/containers/Children/actions"
import { colors } from "~/utils/colors"
import { select } from "@redux-saga/core/effects"
import H2 from "~/components/common/H2"
import Form from "antd/lib/form/Form"
import EditActivity from "~/containers/Children/Activity/EditActivity"
import SubActivity from "~/containers/Children/Activity/SubActivity"
import ActivityDetail from "~/containers/Children/Activity/ActivityDetail"

const { confirm } = Modal

const ActivityCalendar = ({ activity, children }) => {
  const params = useParams()
  const dispatch = useDispatch()
  const currentMonth = dayjs().format("MMMM YYYY")
  const [selectedMonth, setSelectedMonth] = useState(dayjs())
  const currentMonthDates = getCurrentMonthDates(selectedMonth)
  const [selectedCell, setSelectedCell] = useState(null)
  const [markedDates, setMarkedDates] = useState({})
  const [showDrawer, setShowDrawer] = useState(false)
  const [showSubDrawer, setShowSubDrawer] = useState(false)
  const [recu, setRecu] = useState(null)
  const [showDetail, setShowDetail] = useState(false)
  
  let child = children[0]

  const { isCreating, isFetching, isPatching } = useSelector(
    (state) => state.children.attendance
  )

  const prevMonth = () => {
    dayjs(selectedMonth).subtract(1, "months")
    setSelectedMonth(dayjs(selectedMonth).subtract(1, "months"))
  }

  const nextMonth = () => {
    setSelectedMonth(dayjs(selectedMonth).add(1, "months"))
  }

  // const {
  //   childDetails: child,
  //   isFetchingChildDetails,
  //   children,
  //   isFetching: isFetchingChildren,
  //   attendance,
  // } = useSelector((state) => state.children);

  // 

  // const { registrations, subscriptions } = child;

  // useEffect(() => {
  //   // 
  //   // dispatch(fetchChildren());
  //   if (params.id) {
  //     // dispatch(childRegistrationFetching(params.id));
  //     const thisChild = children.find((ch) => ch.alias === params.id);
  //     dispatch(fetchChildDetails({ url: thisChild?.url }));
  //   }
  // }, [params.id]);

  useEffect(() => {
    // if (!isCreating && !isPatching) {
    
    // if (!isFetchingChildDetails) {
    // fetchAttendances();
    // fetchAttendances2();
    // }
    // }
  }, [
    // child,
    // subscriptions,
    selectedMonth,
    ,
    isCreating,
    isPatching,
  ])

  const allAttendances = useSelector(
    (state) => state.children.attendance.allChildrenAttendances
  )

  // 
  // 

  const openingDays = useSelector(
    (state) => state.children.attendance.allOpeningDays
  )

  useEffect(() => {
    if (!selectedCell) return

    const day = selectedCell.day
    const eventExists = !!selectedCell?.openDay?.find(
      (d) => d.date === day.format("YYYY-MM-DD")
    )

    const selectedDay = selectedCell?.attendance

    const childAlias = child.alias
    const activityAlias = selectedCell.sub.parent_activity_alias
    const alias = selectedCell.sub.activity_alias
    const activityName = selectedCell.sub.parent_activity_name

    if (!eventExists) {
      return
    }

    if (dayjs(day.format("YYYY-MM-DD")).isBefore(dayjs(), "day")) {
      if (selectedDay && selectedDay.was_there === true) {
        message.success(`${child.first_name} était présent(e) ce jour-là`)
      } else if (selectedDay && selectedDay.is_listed === true) {
        message.warning(
          `${child.first_name} était inscrit(e), mais noté absent ce jour-là`
        )
      } else {
        message.info(`${child.first_name} n'était pas inscrit(e) ce jour-là`)
      }
    } else if (selectedDay) {
      // Patch attendance
      if (selectedDay.is_editable) {
        confirm({
          title: activityName,
          content: `Voulez-vous modifier la présence de ${
            child.first_name
          } le ${day.format("D")}/${day.format("MM")} ?`,
          onOk() {
            dispatch(
              patchAttendance({
                childAlias,
                alias: selectedDay.alias,
                present: !selectedDay.is_listed,
              })
            )
          },
          onCancel() {
            
          },
        })
      } else {
        message.info("Cette date n'est pas modifiable")
      }
    } else {
      // Create attendance
      confirm({
        title: activityName,
        content: `Voulez-vous inscrire ${child.first_name} le ${day.format(
          "D"
        )}/${day.format("MM")} ?`,
        onOk() {
          dispatch(
            createAttendance({
              childAlias,
              date: day.format("YYYY-MM-DD"),
              activityAlias: alias,
            })
          )
        },
        onCancel() {
          
        },
      })
    }
  }, [selectedCell])

  // useEffect(() => {
  //   let aliases = [];

  //   
  //   
  //   for (let sub of subscriptions) {
  //     if (aliases.find((s) => sub.parent_activity_alias === s) === undefined) {
  //       aliases.push({
  //         org_alias: sub.organisation_alias,
  //         activity_alias: sub.activity_alias,
  //       });
  //     }
  //   }
  //   dispatch(
  //     fetchAllOpeningdays({
  //       aliases,
  //       month: dayjs(selectedMonth).format("M"),
  //     })
  //   );
  // }, [selectedMonth]);

  // const fetchAttendances = useCallback(() => {
  //   const childAlias = child.alias;
  //   if (!subscriptions.length || !child.alias) return;
  //   
  //   
  //   let aliases = [];

  //   
  //   for (let sub of subscriptions) {
  //     aliases.push({
  //       org_alias: sub.organisation_alias,
  //       activity_alias: sub.activity_alias,
  //     });
  //   }

  //   

  //   dispatch(
  //     allChildrenAttendancesFetching({
  //       aliases: [childAlias],
  //       // activityAlias: paAlias,
  //       month: dayjs(selectedMonth).format("M"),
  //     })
  //   );
  // }, [selectedMonth, isCreating, isPatching]); // deleted child

  // 

  // // let uniqueItems = [...new Set(state.alias)];
  // let org_data = [];

  // for (let sub of subscriptions) {
  //   if (org_data.length === 0)
  //     org_data.push({ subs: [{ ...sub, attendance: [], openingDays: [] }] });
  //   else {
  //     for (let i = 0; i < org_data.length; i++) {
  //       if (
  //         org_data[i].subs[0].org_alias === sub.org_alias &&
  //         org_data[i].subs.find(
  //           (s) => sub.parent_activity_alias === s.parent_activity_alias
  //         ) === undefined
  //       )
  //         org_data[i].subs.push({ ...sub, attendance: [], openingDays: [] });
  //     }
  //   }
  // }

  // 

  // for (let org of org_data) {
  //   for (let sub of org.subs) {
  //     for (let att of allAttendances) {
  //       if (att.activity_alias === sub.parent_activity_alias) {
  //         sub.attendance.push(att);
  //       }
  //     }
  //     if (openingDays) {
  //       for (let open of openingDays) {
  //         if (open.activity === sub.activity_alias) {
  //           sub.openingDays.push(open);
  //         }
  //       }
  //     }
  //   }
  // }

  const reccurent = (sub) => {
    // 
    // setRecu(sub);
    setShowDrawer(true)
  }

  // Now calculate and output the difference

  // 

  // 

  // 
  // 
  // 

  firstAndLastDayOfMonth()

  return (
    <Calendar>
      <Space direction="horizontal" style={{ justifyContent: "space-between" }}>
        <Space direction="horizontal">
          <LeftOutlined onClick={() => prevMonth()} style={{ fontSize: 20 }} />
          <H1
            style={{
              textTransform: "capitalize",
              fontSize: "20px",
              padding: "10px",
              marginBottom: 0,
            }}
          >
            {dayjs(selectedMonth).format("MMMM YYYY")}
          </H1>
          <RightOutlined onClick={() => nextMonth()} style={{ fontSize: 20 }} />
        </Space>
        <Space
          direction="horizontal"
          style={{ justifyContent: "space-between", width: 390 }}
        >
          {/* <EyeInvisibleOutlined style={{ fontSize: 25 }} /> */}
          <Button size="large" onClick={() => setSelectedMonth(dayjs())}>
            Aujourd’hui
          </Button>
          {/* <Button
            type="primary"
            htmlType="submit"
            size="large"
            onClick={() => setShowSubDrawer(true)}
          >
            <CalendarOutlined />
            Inscrire à une activité
          </Button> */}
        </Space>
      </Space>
      {/* {children.map((child, index) => ( */}
      <>
        <Horizontal direction="horizontal">
          <Border>{activity.name}</Border>
          {/* <DynamicPadding></DynamicPadding> */}

          <DayContainer>
            {currentMonthDates.map((date) => {
              if (
                dayjs(dayjs().format("YYYY-MM-DD")).isSame(
                  dayjs(date).format("YYYY-MM-DD"),
                  "day"
                )
              ) {
                // 
                // 
                //   "TRUE ??",
                //   dayjs().format("YYYY-MM-DD"),
                //   dayjs(date).format("YYYY-MM-DD")
                // );
                return (
                  <Day color="#1890FF" textColor="white">
                    <DayLetter textColor="white">
                      {dayjs(date).format("dd")[0]}
                    </DayLetter>
                    <DayNumber textColor="white">
                      {dayjs(date).format("DD")}
                    </DayNumber>
                  </Day>
                )
              }
              return (
                <Day>
                  <DayLetter>{dayjs(date).format("dd")[0]}</DayLetter>
                  <DayNumber>{dayjs(date).format("DD")}</DayNumber>
                </Day>
              )
            })}
          </DayContainer>
        </Horizontal>
        <OrgContainer>
          <ActivityContainer>
            <ActivityName
            //  onClick={() => setShowDetail(true)}
            >
              Tous
            </ActivityName>
            <DynamicPadding>
              <Recurrent onClick={() => reccurent()}>
                <ReloadOutlined
                  style={{
                    fontSize: "20px",
                    paddingTop: "5px",
                  }}
                ></ReloadOutlined>
              </Recurrent>
            </DynamicPadding>
            {currentMonthDates.map((a) => {
              // 
              // 
              let color
              const currDay = null
              // sub.attendance.find((element) =>
              //   dayjs(element.date).isSame(a, "day")
              // );
              let currOpen = activity.opening_days.find((element) =>
                dayjs(element.date).isSame(a, "day")
              )
              // 
              // if day is passed
              if (currDay) {
    if (((dayjs(currDay.date)).set({hour:activity?.start_time.split(":")[0],minute:activity?.start_time.split(":")[1],second:0,millisecond:0})) <= dayjs()) {
                  if (currDay.was_there) {
                    // present on a past date
                    color = colors.cvGreen
                  } else if (currDay.is_listed && !currDay.was_there) {
                    // registered but not present on a past date
                    color = colors.cvOrange
                  } else if (!currDay.is_listed && !currDay.was_there) {
                    // not registered on a past date
                    color = colors.cvBlueGreyLight
                  }
                } else {
                  // If it is a present or future day
                  if (currDay.is_listed && currDay.is_editable) {
                    // if registered
                    color = colors.cvBlue
                  } else if (currDay.is_listed && !currDay.is_editable) {
                    color = colors.cvBlueGrey
                  } else {
                    color = colors.cvBlueGreyLight // if not registered
                  }
                }
                return (
                  <Case
                    color={color}
                  ></Case>
                )
              }
              if (currOpen && dayjs(currOpen.date) > dayjs()) {
                return (
                  <Case
                    color={colors.cvBlueGreyLight}
                  ></Case>
                )
              }
              return (
                <Case
                ></Case>
              )
            })}
          </ActivityContainer>
          {children.map((child) => (
            <ActivityContainer>
              <ActivityName
              //  onClick={() => setShowDetail(true)}
              >
                {child.first_name}
              </ActivityName>
              <DynamicPadding>
                <Recurrent onClick={() => reccurent()}>
                  <ReloadOutlined
                    style={{
                      fontSize: "20px",
                      paddingTop: "5px",
                    }}
                  ></ReloadOutlined>
                </Recurrent>
              </DynamicPadding>
              {currentMonthDates.map((a) => {
                // 
                // 
                let color
                const currDay = null
                // sub.attendance.find((element) =>
                //   dayjs(element.date).isSame(a, "day")
                // );
                let currOpen = activity.opening_days.find((element) =>
                  dayjs(element.date).isSame(a, "day")
                )

                // 
                // 
                // if day is passed
                if (currDay) {
                  if (dayjs(currDay.date) < dayjs()) {
                    if (currDay.was_there) {
                      // present on a past date
                      color = colors.cvGreen
                    } else if (currDay.is_listed && !currDay.was_there) {
                      // registered but not present on a past date
                      color = colors.cvOrange
                    } else if (!currDay.is_listed && !currDay.was_there) {
                      // not registered on a past date
                      color = colors.cvBlueGreyLight
                    }
                  } else {
                    // If it is a present or future day
                    if (currDay.is_listed && currDay.is_editable) {
                      // if registered
                      color = colors.cvBlue
                    } else if (currDay.is_listed && !currDay.is_editable) {
                      color = colors.cvBlueGrey
                    } else {
                      color = colors.cvBlueGreyLight // if not registered
                    }
                  }
                  return (
                    <Case
                      color={color}
                    ></Case>
                  )
                }
                if (dayjs(currOpen).isAfter(dayjs())) {
                  return (
                    <Case
                      color={colors.cvBlueGreyLight}
                    ></Case>
                  )
                }
                return (
                  <Case
                  onClick={() =>{
                    setSelectedCell({
                      attendance: currDay,
                      day: a,
                      openDay: activity.opening_days,
                      sub: activity,
                    })}
                  }
                  ></Case>
                )
              })}
            </ActivityContainer>
          ))}
        </OrgContainer>
        <DescriptionContainer>
          <DescWrapper>
            <Dot color="#FFCDD6"></Dot>
            <Description>Réservation récurrente</Description>
          </DescWrapper>
          <DescWrapper>
            <Dot color="#2F4580"></Dot>
            <Description>Réservé, annulation possible</Description>
          </DescWrapper>
          <DescWrapper>
            <Dot color="#6D7F9D"></Dot>
            <Description>Réservation non annulable</Description>
          </DescWrapper>
          <DescWrapper>
            <Dot color="#E2E4E6"></Dot>
            <Description>Pas de réservation</Description>
          </DescWrapper>
          <DescWrapper>
            <Dot color="#20CD8D"></Dot>
            <Description>Présent</Description>
          </DescWrapper>
          <DescWrapper>
            <Dot color="#F4C26D"></Dot>
            <Description>Réservé mais absent</Description>
          </DescWrapper>
          <DescWrapper>
            <Dot color="#FF5876"></Dot>
            <Description>Paiement en attente</Description>
          </DescWrapper>
        </DescriptionContainer>
      </>
      {/* ))} */}
      <EditActivity
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        data={recu}
      ></EditActivity>
      <SubActivity
        showDrawer={showSubDrawer}
        setShowDrawer={setShowSubDrawer}
        data={recu}
      ></SubActivity>
      {showDetail && (
        <ActivityDetail setShowDetail={setShowDetail}></ActivityDetail>
      )}
    </Calendar>
  )
}

export default ActivityCalendar
