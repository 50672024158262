
import { colors } from '~/utils/colors';

export const getActivityStatusColor = (
  status,
  renewalStatus = undefined,
) => {
  if (renewalStatus !== undefined) {
    switch (renewalStatus) {
      case 0:
        return colors.cvBlue;
      case 1:
        return colors.cvOrange;
      case 2:
        return colors.cvRed;
      default:
        break;
    }
  }

  switch (status) {
    case 0:
      return colors.cvOrange;
    case 1:
      return colors.cvGreen;
    case 2:
      return colors.cvRed;
    case 3:
      return colors.cvOrange;
    case 4:
      return colors.cvBlue;
    default:
      return '';
  }
};

export const getActivityStatus = (status, renewalStatus = undefined, missingDocs = undefined) => {
  if (renewalStatus !== undefined) {
    switch (renewalStatus) {
      case 0:
        return 'Réinscription à envoyer';
      case 1:
        return 'Réinscription en attente';
      case 2:
        return 'Réinscription refusée';
      default:
        break;
    }
  }
  switch (status) {
    case 0:
      if (missingDocs && missingDocs > 0) {
        return 'Des documents sont manquants';
      }
      return 'En attente de validation';
    case 1:
      return 'Inscription validée';
    case 2:
      return 'Inscription rejetée';
    case 3:
      return 'Des documents sont manquants';
    case 4:
      return 'A envoyer';
    default:
      return '';
  }
};
