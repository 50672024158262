import { createActions } from 'redux-actions'

export const {
  fetchBills,
  fetchBillsProcess,
  fetchBillsError,
  fetchBillsSuccess,
  // download file
  downloadPdf,
} = createActions({
  FETCH_BILLS: (data) => data,
  FETCH_BILLS_PROCESS: () => {},
  FETCH_BILLS_SUCCESS: (bills) => bills,
  FETCH_BILLS_ERROR: () => {},

  // download file
  DOWNLOAD_PDF: (data) => data,
})
