import { CloseCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { Col, message, Row, Steps } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Container from "~/components/common/Container";
import ContentHeader from "~/components/common/ContentHeader";
import H1 from "~/components/common/H1";
import Loading from "~/components/common/Loading";
import OrgCard from "~/components/common/OrgCard";
import {
  fetchChildDetails,
  fetchChildren,
  fetchChildrenRegistrations,
  fetchSelectedChildrenDetails,
} from "~/containers/Children/actions";
import { childRegistrationFetching } from "~/containers/Registration/actions";
import {
  activitiesFetching,
  activityAllSlotsFetching,
  activityDetailsFetching,
  activitySlotsFetching,
  subscriptionCreating,
} from "~/containers/Subscription/actions";
import { userOrganisationsFetching } from "~/containers/Home/actions";
import { StyledSteps } from "./components";
import Activity from "./components/Activity";
import ActivityCalendar from "./components/ActivityCalendar";
import ActivityDetails from "./components/ActivityDetails";
import Slot from "./components/Slot";
import { TopStep3 } from "./components/Step3";
import { useQuery } from "@apollo/client";
import { ACTIVITY_DETAILS, ORGANISATION_PARENT_AVAILABLE_ACTIVITIES } from "~/graphql/queries";
import { useMemo } from "react";

const { Step } = Steps;
const nbrSteps = 6;

const childSelected = (ch, chosenChildAlias) => {
  return chosenChildAlias.find((child) => child === ch.alias);
};

const Subscription = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const {
    children,
    childDetails: child,
    loading,
    selectedChildrenDetails,
    childrenRegistrations,
  } = useSelector((state) => state.children);
  const {
    // list: activitiesList,
    loading: isFetchingActivities,
    // details: activityDetails,
    slots,
  } = useSelector((state) => state.subscription.activity);

  const {
    error,
    loading: orgLoading,
    userOrganisationsList,
  } = useSelector((state) => state.home.organisation);

  const { registrationDetails, list: childRegistrationsList } = useSelector(
    (state) => state.registration
  );
  //

  const { isCreatingSubscription } = useSelector((state) => state.subscription);
  const [currentStep, setCurrentStep] = useState(params.child_alias ? 1 : 0); // : 0
  const [skipLocation, setSkipLocation] = useState(false);
  const [skipSlot, setSkipSlot] = useState(false);
  const [canContinue, setCanContinue] = useState(!params.child_alias);
  const [canGoBack, setCanGoBack] = useState(true);
  const [chosenChildAlias, setChosenChildAlias] = useState([]);
  const [chosenActivity, setChosenActivity] = useState(null);
  const [detailedActivityAlias, setDetailedActivityAlias] = useState([]);
  const [chosenDays, setChosenDays] = useState({});
  const [chosenLocation, setLocation] = useState(null);
  const [stepToHide, setStepToHide] = useState([]);
  const [chosenOrganisationData, setChosenOrganisationData] = useState(null);

  const {
    loading: fetchingOrganisationActivities,
    error: errorOrganisationActivities,
    data: organisationActivities,
  } = useQuery(ORGANISATION_PARENT_AVAILABLE_ACTIVITIES, {
    fetchPolicy: "no-cache",
    variables: {
      orgAlias: chosenOrganisationData?.alias,
    },
    skip: !chosenOrganisationData?.alias,
  });

  const {
    loading: fetchingActivityDetails,
    error: errorActivityDetails,
    data: activityDetailsData,
  } = useQuery(ACTIVITY_DETAILS, {
    fetchPolicy: "no-cache",
    variables: {
      alias: chosenActivity?.alias,
    },
  });

  const activityDetails = useMemo(() => {
    if (activityDetailsData) {
      return activityDetailsData?.activity;
    }
    return null;
  }, [activityDetailsData]);

  const isHouseholdExternal = useMemo(() => {
    if (activityDetailsData) {
      return activityDetailsData?.myHousehold?.isExternal;
    }

    return null;
  }, [activityDetailsData])

  console.log({activityDetails, activityDetailsData, chosenActivity})

  const currentRegistrationAlias = params.registration_alias;

  const { t } = useTranslation("subscription");

  const submitSubscription = () => {
    const data = { activityAlias: chosenActivity.alias };
    if (chosenActivity?.periodSelection?.key === 1)
      data.activityBuildingAlias = detailedActivityAlias;
    else if (chosenLocation) {
      const activity = chosenActivity.activities.filter(
        (act) => act.building === chosenLocation.alias
      )[0];
      data.activityBuildingAlias = [activity.alias];
    }
    dispatch(
      subscriptionCreating(chosenChildAlias, { ...data, ...chosenDays }, () => {
        navigate(`/children/${chosenChildAlias}`);
        message.success("Inscription crée avec succès");
      })
    );
  };

  const goToStep = (direction) => {
    let step;
    if (direction === "next") {
      step = currentStep + 1;
      if (step === 3 && skipLocation) step += 1;
      if (step === 4 && skipSlot) step += 1;
    } else {
      if (currentStep === 1) {
        navigate(-1);
      }
      step = currentStep - 1;
      if (step === 4 && skipSlot) step -= 1;
      if (step === 3 && skipLocation) step -= 1;
    }

    setCanContinue(true);
    setCanGoBack(step > 0);
    if (step === 0) {
      setChosenChildAlias(null);
      setCanContinue(false);
    } else if (step === 1) {
      setCanContinue(false);
    } else if (step === 3) {
      setCanContinue(false);
    } else if (step === 4) {
      setCanContinue(false);
    } else if (step === 5) {
      if (!skipSlot) {
        submitSubscription();
        return;
      }
    } else if (step === 6) {
      submitSubscription();
    }

    if (step >= 0 && step < nbrSteps) setCurrentStep(step);
  };

  const fetchActivities = useCallback(
    async (alias) => dispatch(activitiesFetching(alias)),
    []
  );

  const chooseOrganisation = (organisation) => {
    // setChosenOrganisationAlias(organisation.alias)
    setChosenOrganisationData(organisation);
    setCurrentStep(1);
    // Create registration if it was not created yet
    // dispatch(
    //   registrationCreating(
    //     chosenChildAlias,
    //     organisation.alias,
    //     (registration1) => {
    //       if (!registration1) {
    //         // if the registration exists then fetch it
    //         dispatch(
    //           registrationToOrganisationFetching(
    //             chosenChildAlias,
    //             organisation.alias,
    //             (registration2) => {
    //               goToStep("next");
    //               setCurrentRegistrationAlias(registration2.alias);
    //               setMissingDocuments(registration2.missing_documents);
    //               // setFilledDocuments(registration2.list_of_documents)
    //               navigate(
    //                 `/registration/${chosenChildAlias}/${registration2.alias}`
    //               );
    //             }
    //           )
    //         );
    //         return;
    //       }

    //       goToStep("next");
    //       setCurrentRegistrationAlias(registration1.alias);
    //       setMissingDocuments(registration1.missing_documents);
    //       // setFilledDocuments(registration1.list_of_documents)
    //       navigate(
    //         `/registration/${chosenChildAlias}/${registration1.alias}`
    //       );
    //     }
    //   )
    // );
  };

  const onChooseChild = (ch) => {
    setChosenChildAlias(ch.alias);
    navigate(`/subscription/${ch.alias}`);
  };

  const isAlreadyRegistered = (activity) => {
    const subscribedChildrenToActivity = selectedChildrenDetails.reduce(
      (acc, cur) => {
        if (cur?.subscriptions) {
          const childSubscribed = cur?.subscriptions?.find(
            (sub) => sub.parent_activity_alias === activity.alias
          );

          if (!!childSubscribed) {
            acc = [...acc, cur];
          }
        }

        return acc;
      },
      []
    );

    // const subscribedChildrenToActivity = allChildrenSubscriptions.filter(sub => {
    //   return sub.parent_activity_alias === activity.alias && sub.status !== 0
    // })
    const registredChildrenToOrganisation =
      !!childrenRegistrations &&
      Object.keys(childrenRegistrations).filter((childAlias) => {
        return !!childrenRegistrations?.[childAlias]?.find(
          (childDetails) =>
            childDetails?.organisationAlias === chosenOrganisationData?.alias
        );
      });

    return (
      subscribedChildrenToActivity.length ===
      registredChildrenToOrganisation.length
    );
  };

  const onChooseActivity = (activity) => {
    console.log({ activity })
    const isRegistred = !isAlreadyRegistered(activity);
    if (isRegistred) {
      goToStep("next");
      setSkipLocation(activity?.buildingChoice?.key !== 1);
      setSkipSlot(activity?.periodSelection?.key !== 1);
      setChosenActivity(activity);
      // dispatch(activityDetailsFetching(activity?.organisation?.alias, activity?.alias));
    } else {
      message.warning("Cet enfant est déja inscrit a cette activité.");
    }
  };

  const onChooseLocation = (location) => {
    goToStep("next");
    setLocation(location);
    dispatch(
      activitySlotsFetching(
        chosenActivity.organisation,
        chosenActivity.alias,
        location.alias
      )
    );
  };

  const onSelectSlot = (alias) => {
    if (detailedActivityAlias.includes(alias)) {
      setDetailedActivityAlias(
        detailedActivityAlias.filter((item) => item !== alias)
      );
    } else {
      setDetailedActivityAlias([alias, ...detailedActivityAlias]);
    }
  };

  // To choose which organisation to use to fetch activities
  const getCurrentActiveRegistration = (registrations) => {
    if (currentRegistrationAlias) {
      return registrations.find(
        (item) => item.alias === currentRegistrationAlias && item.status === 1
      );
    }
    return registrations.find((item) => item.status === 1);
  };

  useEffect(() => {
    if (chosenActivity) {
      dispatch(
        activityAllSlotsFetching(
          chosenActivity?.organisation?.alias,
          chosenActivity?.alias
        )
      );
    }
  }, [chosenActivity]);

  console.log({ chosenChildAlias });

  // useEffect(() => {
  //   if (chosenChildAlias?.length) {
  //     dispatch(
  //       childRegistrationFetching(chosenChildAlias, (registrations) => {
  //         const currentRegistration =
  //           getCurrentActiveRegistration(registrations)
  //         if (currentRegistration) {
  //           fetchActivities(currentRegistration.organisationAlias)
  //           if (currentStep === 0) {
  //             navigate(
  //               `/subscription/${chosenChildAlias}/${currentRegistration.alias}`
  //             )
  //             goToStep("next")
  //           }
  //         } else {
  //           message.warning("Cet enfant n'est pas encore inscrit")
  //           navigate("/subscription")
  //           setCurrentStep(0)
  //         }
  //       })
  //     )

  //     const data = children.find((ch) => ch.alias === chosenChildAlias)
  //     dispatch(fetchChildDetails({ url: data?.url }))
  //   }
  // }, [chosenChildAlias])

  console.log({ params });

  useEffect(() => {
    if (params.childAlias) {
      dispatch(childRegistrationFetching(params.childAlias));
    }
  }, [params.childAlias]);

  useEffect(() => {
    // if (currentStep === 0) dispatch(userOrganisationsFetching())
    if (currentStep === 1 && chosenOrganisationData) {
      fetchActivities(chosenOrganisationData.alias);
    }
  }, [currentStep]);

  useEffect(() => {
    setCanContinue(detailedActivityAlias.length > 0);
  }, [detailedActivityAlias]);

  useEffect(() => {
    let sToHide = [];
    if (currentStep > 1) {
      if (skipSlot) {
        sToHide = [...sToHide, "slots"];
        sToHide = sToHide.filter((item) => item !== "calendar");
      } else {
        sToHide = sToHide.filter((item) => item !== "slots");
        sToHide = [...sToHide, "calendar"];
      }

      if (skipLocation) {
        sToHide = [...sToHide, "location"];
      } else {
        sToHide = sToHide.filter((item) => item !== "location");
      }
    } else {
      sToHide = ["location", "slots", "calendar"];
    }

    const elemChild = document.getElementById("child");
    const elemActivity = document.getElementById("activity");
    if (elemChild && elemActivity) {
      elemChild.style.display = "none";
      elemActivity.style.display = "none";
    }

    setStepToHide(sToHide);
  }, [skipLocation, skipSlot, currentStep]);

  const showButtonText = () => {
    let text = t("next-step");
    if (currentStep > 3) text = t("register-cta");
    return text;
  };

  useEffect(() => {
    if (params?.orgAlias) {
      const selectedOrganisation = userOrganisationsList.find(
        (org) => org.alias === params.orgAlias
      );

      chooseOrganisation(selectedOrganisation);
      fetchActivities(params?.orgAlias);
    }
  }, [params?.orgAlias, userOrganisationsList]);

  // useEffect(() => {
  //   if (params?.childAlias) {
  //     const selectedChild = children.find(child => child.alias === params.childAlias)

  //
  //     if (currentStep === 3 && chosenChildAlias.find((child) => child === selectedChild.alias)) {
  //       setChosenChildAlias(
  //         chosenChildAlias.filter((alias) => alias !== selectedChild.alias)
  //       );
  //     }
  //   }
  // }, [params?.childAlias, children, chosenChildAlias, currentStep])

  console.log({ children });
  useEffect(() => {
    if (children.length > 0) {
      const childrenDetailsUrls = children.map((child) => child.url);

      dispatch(
        fetchSelectedChildrenDetails(
          childrenDetailsUrls
          // () => {}
        )
      );
    }
  }, [children.length]);

  useEffect(() => {
    dispatch(fetchChildren());
    dispatch(userOrganisationsFetching());
    dispatch(fetchChildrenRegistrations());
  }, []);

  const childOrgsRegistrationsAliasesList = child?.registrations?.map(
    (reg) => reg?.organisationAlias
  );

  console.log({ userOrganisationsList, currentStep, orgLoading });
  return (
    <>
      <ContentHeader
        breadcrumbItems={[
          {
            name: "Incription",
            path: "/registration/",
          },
        ]}
        buttons={() => (
          <>
            <LeftCircleOutlined
              onClick={() => {
                goToStep();
              }}
              style={{ fontSize: 25, color: "#2F4580" }}
            />
            <CloseCircleOutlined
              onClick={() => {
                navigate(-1);
              }}
              style={{ fontSize: 25, color: "#2F4580" }}
            />
          </>
        )}
      >
        <H1>Inscription nouvelle activité</H1>
      </ContentHeader>
      {/* <br /> */}
      <Container>
        <StyledSteps progressDot current={currentStep}>
          <Step title="Choix organisation" />
          <Step title="Choix activité" />
          <Step title="Détails activité" />
          <Step title="Individus, lieux, Réservations, Validation" />
        </StyledSteps>
        <br />
        <br />
        {/* {currentStep > 1 && (
          <StepsContainer>
            <Row justify="center">
              <Col span={14}>
                <Steps current={currentStep} progressDot>
                  {stepsList
                    .filter((item) => !stepToHide.includes(item.id))
                    .map((item) => item.element)}
                </Steps>
              </Col>
            </Row>
          </StepsContainer>
        )} */}
        <br />

        {/* <StepContainer> */}
        {(loading || orgLoading) && <Loading />}

        {currentStep === 0 && (
          // <Row gutter={[24, 24]} justify="center">
          //   {!loading &&
          //     children.map((ch) => (
          //       <Col span={8} key={ch.alias}>
          //         <ChildContainer onClick={() => onChooseChild(ch)}>
          //           <Child child={ch} />
          //         </ChildContainer>
          //       </Col>
          //     ))}
          // </Row>
          <Row gutter={[24, 24]} justify="center">
            {userOrganisationsList
              // ?.filter(
              //   (item) => child && childOrgsRegistrationsAliasesList?.includes(item.alias)
              // )
              ?.map((organisation) => {
                return (
                  <div onClick={() => chooseOrganisation(organisation)}>
                    <OrgCard
                      name={organisation.name}
                      address={organisation.address}
                      zip={`${organisation.zip_code} ${organisation.city}`}
                      email={organisation.email}
                      phone={organisation.phone}
                      photo={organisation.photo}
                    ></OrgCard>
                  </div>
                );
              })}
          </Row>
        )}

        {currentStep === 1 && (
          <Row gutter={[24, 24]} justify="center">
            {fetchingOrganisationActivities && <Loading />}
            {!fetchingOrganisationActivities && !organisationActivities?.organisationParentAvailableActivities?.length && (
              <span>Aucune activité</span>
            )}
            {!fetchingOrganisationActivities && !!organisationActivities?.organisationParentAvailableActivities?.length &&
              organisationActivities?.organisationParentAvailableActivities?.map(({parentActivity: activity, isSubscribale}) => (
                // <Col span={8} key={activity.alias}>
                <Activity
                  activity={activity}
                  onChoose={() => onChooseActivity(activity)}
                  isSubscribale={isSubscribale}
                />
                // </Col>
              ))}
          </Row>
        )}

        {currentStep === 2 && (
          <>
            {fetchingActivityDetails && <Loading />}
            {!fetchingActivityDetails && activityDetails && (
              <ActivityDetails
                setCurrentStep={setCurrentStep}
                organisation={chosenOrganisationData}
                activity={activityDetails}
                isHouseholdExternal={isHouseholdExternal}
              />
            )}
          </>
        )}

        {currentStep === 4 && (
          <Row gutter={[24, 24]} justify="center">
            {isFetchingActivities && <Loading />}
            {!isFetchingActivities &&
              slots
                .filter((slot) => {
                  if (!slot.remaining_slots || slot.remaining_slots > 0) {
                    return slot;
                  }
                  return null;
                })
                .map((slot) => (
                  <Col span={8} key={slot.alias}>
                    <Slot
                      slot={slot}
                      onChoose={() => onSelectSlot(slot.alias)}
                      selected={detailedActivityAlias.includes(slot.alias)}
                    />
                  </Col>
                ))}
            {!slots && <span>Aucune disponibilité</span>}
          </Row>
        )}

        {currentStep === 5 && (
          <Row>
            <Col span={10} offset={7}>
              <ActivityCalendar
                activity={activityDetails}
                onChoose={(data) => setChosenDays(data)}
              />
            </Col>
          </Row>
        )}

        {currentStep === 3 && activityDetails && (
          <TopStep3
            chosenChildAlias={chosenChildAlias}
            setChosenChildAlias={setChosenChildAlias}
            children={selectedChildrenDetails}
            activityDetails={activityDetails}
            childrenRegistrations={childrenRegistrations}
            organisation={chosenOrganisationData}
            setCurrentStep={setCurrentStep}
            setChosenActivity={setChosenActivity}
          ></TopStep3>
        )}
      </Container>
      {/* {currentStep === 3 && activityDetails &&
        children.filter((ch) => childSelected(ch, chosenChildAlias)).length >
          0 && (
          <BotStep3
            chosenChildAlias={chosenChildAlias}
            children={children}
            activity={activityDetails}
          ></BotStep3>
        )} */}
    </>
  );
};

export default Subscription;
