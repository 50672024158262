import React from 'react'
import {
  Drawer,
} from 'antd'

import {
  CustomCloseCircleOutlined,
  Title
} from './components'

const CustomDrawer = ({
  children, 
  width=400,
  title,
  handleCloseDrawer,
  displayDrawer,
  ...props
}) => {

  return (
    <Drawer
      title={<Title>{title}</Title>}
      width={width}
      onClose={handleCloseDrawer}
      open={displayDrawer}
      closeIcon={
        <CustomCloseCircleOutlined />
      }
      headerStyle={{ borderBottom: 'none'}}
      {...props}
    >
      {children}
    </Drawer>
  )
}

export default CustomDrawer
