import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Divider } from 'antd';
import dayjs from 'dayjs'
import { startCase } from 'lodash'
import { BankOutlined, CalendarOutlined } from '@ant-design/icons'
import Text from '~/components/common/Text'
import { getBillingModel, getPaymentMethod } from '~/constants/paymentMethods'

export const ActionsContainer = styled.span`
  display: flex;
  flex-direction: row;
  .pay {
    display: inline-block;
    padding-left: 5px;
    margin-left: 5px;
    border-left: solid 1px @grey-color;
  }
`

const getAllStatus = (bill) => {

  if (bill?.type !== 2) {
    if (bill?.status === 4) {
      return 'Transféré au Trésor public'
    } if (bill?.status === 5) {
      return 'Prélèvement programmé'
    } if (bill?.bill_status?.bill_is_paid) {
      return 'Payé'
    }
    if(bill?.bill_status?.remaining_amount < 0){
      return `Trop perçu : ${Math.abs(bill?.bill_status?.remaining_amount.toString().replace('.', ','))} €`
    }
    if (bill?.bill_status?.remaining_amount) {
      return <span style={{color: '#FF5876'}}>À payer : {bill?.bill_status?.remaining_amount.toString().replace('.', ',')} €</span>
    }
    return '-'
  }
  // Avoir
  if (bill?.type === 2) {
    if (bill?.bill_status?.remaining_amount && bill?.bill_status?.amount_paid > 0) {
      return <span style={{color: '#24DD98'}}>Reste : {bill?.bill_status?.remaining_amount.toString().replace('.', ',')} €</span>
    }
    return '-'
  }

  return '-'
}
const getAllStatusGQ = (bill) => {

  if (bill?.type.key !== 2) {
    if (bill?.status.key === 4) {
      return 'Transféré au Trésor public'
    } if (bill?.status.key === 5) {
      return 'Prélèvement programmé'
    } if (bill?.billStatus?.billIsPaid) {
      return 'Payé'
    }
    if(bill?.billStatus?.remainingAmount < 0){
      return `Trop perçu : ${Math.abs(bill?.billStatus?.remainingAmount.toString().replace('.', ','))} €`
    }
    if (bill?.billStatus?.remainingAmount) {
      return <span style={{color: '#FF5876'}}>À payer : {bill?.billStatus?.remainingAmount.toString().replace('.', ',')} €</span>
    }
    return '-'
  }
  // Avoir
  if (bill?.type === 2) {
    if (bill?.billStatus?.remainingAmount && bill?.billStatus?.amountPaid > 0) {
      return <span style={{color: '#24DD98'}}>Reste : {bill?.bill_status?.remainingAmount.toString().replace('.', ',')} €</span>
    }
    return '-'
  }

  return '-'
}
const getPaymentStatus = (paiement) => {
  if (paiement?.some(item => item?.method === 0 && item?.paymentResult === 0)) {
    return ' ✅'
  } else if (paiement?.some(item => item?.method === 0 && item?.method === 1)) {
    return ' ❌'
  } else if (paiement?.some(item => item?.method === 0 && item?.method === 2)) {
    return ' 🚫'
  } else {
    return '- '
  }
}

const renderPaiementStatus = (method, payment_result) => {
  const paymentMethod = getPaymentMethod.find(item => item.code === method);
  if (paymentMethod) {
    return `${paymentMethod.label} ${
      payment_result === 0 ? '✅' : payment_result === 1 ? '❌' : payment_result === 2 ? '🚫' : ''
    }`;
  }
  return '';
};

export const useColumnsInvoices = (onStatusChange, setShowDrawer, handleDownloadPDF, search,accessToken) => useMemo(
  () => [
    {
      title: 'Date d\'émission',
      dataIndex: 'issueDate',
      key: 'issueDate',
      sorter: true,
      defaultSortOrder: 'descend',
      // sorter: search === '' ? (a, b) => dayjs(a.issueDate, 'DD MMMM YYYY').diff(dayjs(b.issueDate, 'DD MMMM YYYY')) : false,
      width: '14%',
      align: 'right',
      render: (issueDate) => <Text style={{ marginRight: '1rem' }}>{dayjs(issueDate, 'MM-DD-YYYY').format('D MMM YYYY')}</Text>,
    },
    {
      title: 'Organisation',
      dataIndex: 'household',
      key: 'organisation',
      sorter: true,
      // sorter: search === '' ? (a, b) => (a?.organisation.name && b?.organisation.name 
      //   ? a.organisation.name .localeCompare(b.organisation.name ) : 0)
      //   : false,
      width: '14%',
      render: (household) => (
        <Text>
          <span><BankOutlined /> {household.organisation.name}</span>
        </Text>
      ),
    },
    {
      title: 'N°',
      dataIndex: 'billNumber',
      key: 'billNumber',
      sorter: true,
      // sorter: search === '' ? (a, b) => (a && b
      //   ? a?.localeCompare?.(b) : 0)
      //   : false,
      width: '8%',
      align: 'left',
      render: (billNumber) => <Text>{billNumber}</Text>,
    },
    {
      title: 'Type',
      //dataIndex: 'type',
      key: 'type',
      sorter: true,
      // sorter: search === '' ? (a, b) => a.type.key - b.type.key : false,
      width: '10%',
      align: 'left',
      render: (data) => (
          <Text>
            {data?.type.value}
            {data?.type.key == 0 && (<p><CalendarOutlined /> {data?.train.name}</p>)}
          </Text>
      ),
    },
    {
      title: () => <span style={{ marginRight: '0.5rem' }}>Montant</span>,
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      sorter: true,
      // sorter: search === '' ? (a, b) => a - b : false,
      width: '10%',
      align: 'right',
      render: (totalAmount) => <Text><span style={{ marginRight: '2rem' }}>{totalAmount.toString().replace('.', ',')} €</span></Text>,
    },
    {
      title: 'État',
      // dataIndex: 'status',
      key: 'state',
      sorter: true,
      // sorter: search === '' ? (a, b) => {
      //   const stateA = getAllStatusGQ(a)
      //   const stateB = getAllStatusGQ(b)

      //   if (stateA < stateB) {
      //     return -1
      //   }
      //   if (stateA > stateB) {
      //     return 1
      //   }
      //   return 0
      // } : false,
      width: '12%',
      align: 'left',
      render: (data) =><Text>{getAllStatusGQ(data)}</Text>,
    },
    {
      title: 'Paiements',
      key: 'relatedPayments',
      sorter: false,
      // sorter: search === '' ? (a, b) => {
      //   const paymentAccepted= getPaymentStatus(a.relatedPayments);
      //   const paymentRefused = getPaymentStatus(b.relatedPayments);
      //   return paymentAccepted.localeCompare(paymentRefused);
      // } : false,
      width: '20%',
      align: 'left',
      render: ({type, status, relatedPayments, creditUsedInBills}) => (
        <>
          {/* check if the type is a credit (avoir), or if it's post Or pré payment
              - if it's a credit, we display the list of bills that have been paid with this credit
              - if it's a post or pré payment, we display the list of related payments (normal behavior)
          */}
          {type === 2 ? (
            <>
              {creditUsedInBills?.length ? creditUsedInBills?.map((item, index) => (
                <>
                  <Text style={{ fontSize: 'small' }} key={index}>
                    {index > 0 ? <Divider style={{margin: '10px 0'}} /> : ''}
                    {dayjs(item.issueDate, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH[h]mm")} : {item?.amount.toString().replace('.', ',')} €
                    <p>Facture n° {item?.billNumber}</p>
                  </Text>
                </>
              )) : '-'}
            </>
          ) : (
            <>
              {relatedPayments?.length && status.key !== 4 && status.key !== 5 ? relatedPayments?.map((item, index) => (
                <Text style={{ fontSize: 'small' }} key={index}>
                  {index > 0 ? <Divider style={{margin: '10px 0'}} /> : ''}
                    {(item?.method !== 1 && item?.method !== 9) ? dayjs(item.date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH[h]mm") : dayjs(item.date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY")} : {item?.amount.toString().replace('.', ',')} €
                  <p>
                    {/* en ligne par CB */}
                    {item?.method === 0 && getPaymentMethod(item?.method).label }{' '}  
                    {item?.method === 0 && item?.paymentResult === 0 && ' ✅'}
                    {item?.method === 0 && item?.paymentResult === 1 && ' ❌'}
                    {item?.method === 0 && item?.paymentResult === 2 && ' 🚫'}
                    {/* prélèvement */}
                    {item?.method === 1 && getPaymentMethod(item?.method).label }
                    {/* chèque */}
                    {item?.method === 2 && getPaymentMethod(item?.method).label }
                    {/* CESU */}
                    {item?.method === 3 && getPaymentMethod(item?.method).label }
                    {/* espèces */}
                    {item?.method === 4 && getPaymentMethod(item?.method).label }
                    {/* prélèvement PayFIP */}
                    {item?.method === 5 && getPaymentMethod(item?.method).label }
                    {/* chèques vacances */}
                    {item?.method === 6 && getPaymentMethod(item?.method).label }
                    {/* autre */}
                    {item?.method === 7 && getPaymentMethod(item?.method).label }
                    {/* bons loisirs CAF */}
                    {item?.method === 8 && getPaymentMethod(item?.method).label }
                    {/* trésorerie */}
                    {item?.method === 9 && getPaymentMethod(item?.method).label }
                    {/* Post-paiement || Pré-paiement ? Avoir : '' */}
                    {item?.method === 10 && item?.creditNumber && `Avoir n° ${item?.creditNumber}`}
                    {/* trop-perçu */}
                    {item?.method === 11 && getPaymentMethod(item?.method).label }
                  </p> 
                </Text>
              )) : '-'}
            </>
          )}
        </>
      ),
    },
    {
      title: 'Actions',
   //   dataIndex: 'action',
      key: 'action',
      align: 'left',
      render: (data) => (
        <ActionsContainer>
          <a
            href="#"
            onClick={() => handleDownloadPDF(
              accessToken,
              data.pdfUrl,
              data.billNumber,
            )}
          >
            Télécharger
          </a>
          {data?.type.key !== 2 && data?.status.key !== 4 && data?.status.key !== 5 && (
            <span className="pay">
              {data.billStatus && data.billStatus.billIsPaid ? (
                <span>Payée</span>
              ) : (
                <a
                  href={data.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Payer
                </a>
              )}
            </span>
              )}
        </ActionsContainer>
      ),
    },
  ],
  [onStatusChange, handleDownloadPDF, search],
)
