import React from "react"
import { Button } from "antd"
// no-unused-vars :: import { useHistory } from "react-router-dom"
import { PlusOutlined } from "@ant-design/icons"
import { CustomCardDushed } from "./components"

const AddCard = ({ title, onClick }) => {
  /* no-unused-vars
  const history = useHistory()
  */
  return (
    <CustomCardDushed>
      <Button
        size="large"
        onClick={onClick}
        icon={<PlusOutlined style={{ fontSize: 50, display: "block" }} />}
      >
        {title}
      </Button>
    </CustomCardDushed>
  )
}

export default AddCard
