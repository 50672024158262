import { createActions } from 'redux-actions'

export const {
  logIn: login,
  logInProcess: loginProcess,
  loginError,
  loginSuccess,
  // JWT
  setJwt,
  checkJwtProcess,
  unsetJwt,
  checkTokenExpiration,
  fetchAccessToken,
  // Forgot password
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordProcess,
  forgotPasswordError,
  // Reset Password
  resetPassword,
  resetPasswordError,
  resetPasswordProcess,
  resetPasswordSuccess,
  // Reset Password Confirm
  resetPasswordConfirm,
  resetPasswordConfirmSuccess,
  resetPasswordConfirmPending,
  resetPasswordConfirmError,
  // logout
  logout,
  // user profile
  fetchUserProfile,
  fetchUserProfileProcess,
  fetchUserProfileSuccess,
  fetchUserProfileError,
  // update
  updateUserProfile,
  updateUserProfileProcess,
  updateUserProfileSuccess,
  updateUserProfileError,
  // user docs
  fetchUserDocuments,
  fetchUserDocumentsProcess,
  fetchUserDocumentsSuccess,
  fetchUserDocumentsError,
  // download file
  downloadFile,
  // fetch user photo
  fetchUserPhoto,
  fetchUserPhotoSuccess,
  // update user photo
  updateUserPhoto,
  updateUserPhotoProcess,
  updateUserPhotoSuccess,
  updateUserPhotoError,
  // change user relationship
  updateRelationship,
  updateRelationshipProcess,
  updateRelationshipSuccess,
  updateRelationshipError,
} = createActions({
  LOG_IN: user => (user),
  LOG_IN_PROCESS: () => {},
  LOGIN_ERROR: err => err,
  LOGIN_SUCCESS: info => info,

  /**
   * JWT
   */
  CHECK_JWT_PROCESS: () => {},
  SET_JWT: tokens => tokens,
  UNSET_JWT: () => {},
  CHECK_TOKEN_EXPIRATION: () => {},
  FETCH_ACCESS_TOKEN: () => {},
  /**
   * Forgot password
   */
  FORGOT_PASSWORD: info => info,
  FORGOT_PASSWORD_PROCESS: () => {},
  FORGOT_PASSWORD_SUCCESS: () => {},
  FORGOT_PASSWORD_ERROR: () => {},

  /**
   * Reset Password Confirm
   */
  RESET_PASSWORD_CONFIRM: data => (data),
  RESET_PASSWORD_CONFIRM_PENDING: () => {},
  RESET_PASSWORD_CONFIRM_SUCCESS: data => (data),
  RESET_PASSWORD_CONFIRM_ERROR: error => error,

  /**
   * Password Reset
   */
  RESET_PASSWORD: data => (data),
  RESET_PASSWORD_PROCESS: () => {},
  RESET_PASSWORD_SUCCESS: data => (data),
  RESET_PASSWORD_ERROR: () => {},
  /**
   * Logout
   */
  LOGOUT: () => {},

  /**
   * User Profile
   */
  FETCH_USER_PROFILE: () => {},
  FETCH_USER_PROFILE_PROCESS: () => {},
  FETCH_USER_PROFILE_SUCCESS: profile => profile,
  FETCH_USER_PROFILE_ERROR: () => {},

  UPDATE_USER_PROFILE: user => (user),
  UPDATE_USER_PROFILE_PROCESS: () => {},
  UPDATE_USER_PROFILE_SUCCESS: data => data,
  UPDATE_USER_PROFILE_ERROR: () => {},

  // user docs
  FETCH_USER_DOCUMENTS: data => data,
  FETCH_USER_DOCUMENTS_PROCESS: () => {},
  FETCH_USER_DOCUMENTS_SUCCESS: data => data,
  FETCH_USER_DOCUMENTS_ERROR: () => {},

  // download file
  DOWNLOAD_FILE: data => data,

  //
  FETCH_USER_PHOTO: () => {},
  FETCH_USER_PHOTO_SUCCESS: data => data,

  //Update user photo
  UPDATE_USER_PHOTO: data => data,
  UPDATE_USER_PHOTO_PROCESS: () => {},
  UPDATE_USER_PHOTO_SUCCESS: data => data,
  UPDATE_USER_PHOTO_ERROR: () => {},

  //Update user relationship
  UPDATE_RELATIONSHIP: data => data,
  UPDATE_RELATIONSHIP_PROCESS: () => {},
  UPDATE_RELATIONSHIP_SUCCESS: data => data,
  UPDATE_RELATIONSHIP_ERROR: () => {},
})
