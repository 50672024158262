import React, { useCallback, useState } from "react"
import Container from "~/components/common/Container"
import FilterContainer from "~/components/common/FilterContainer"
import Space from "~/components/common/Space"
import Table from "~/components/common/Table"
import { useColumns } from "./columns"

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeysChildrenList: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    )
  },
}

const sortFields = {
  profil: "child__last_name",
  households:
    "billinghouseholdregistrations__household__adult_members_set__user__last_name",
  school_class: "schoolClass__name",
  status: "status",
}

const Invoice = () => {
  const [statusFilter, setStatusFilter] = useState(null)
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [selectionType] = useState("checkbox")
  const [, setRowSelection] = useState([])
  const [sort, setSort] = useState(sortFields["profil"])
  const [showDrawer, setShowDrawer] = useState(false)

  const onStatusChange = () => {
    
  }

  const columns = useColumns(onStatusChange, setShowDrawer)

  const onTableChange = useCallback((pagination, _, sorter, { action }) => {
    if (action === "paginate") {
      setPage(pagination.current)
    }
    if (action === "sort") {
      const field = sortFields[sorter.field]
      setSort(sorter.order === "descend" ? `-${field}` : field)
    }
  }, [])

  const dataSource = [
    {
      date: "20/09/2020",
      number: "FF20200900015A",
      dest: ["Annie Dupont", "Jean André"],
      type: "Période octobre 2020",
      sum: "75,00 €",
      status: "En attente de règlement",
    },
  ]

  return (
    <>
      <Container>
        <Space direction="vertical" size="large">
          <FilterContainer
            defaultPageSize={pageSize}
            onPageSizeChange={setPageSize}
            onSearch={setSearch}
          />
          <Table
            columns={columns}
            dataSource={dataSource}
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            onRowSelectionChange={setRowSelection}
            // isLoading={isLoading}
            onChange={onTableChange}
            total={0} // length
            currentPage={page}
            pageSize={pageSize}
          />
        </Space>
      </Container>
    </>
  )
}

export default Invoice
