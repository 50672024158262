
import { handleActions } from '~/utils/redux-actions'
import {
  fetchBillsProcess,
  fetchBillsSuccess,
  fetchBillsError,
} from './actions'

import { logout } from '~/containers/User/actions'

const defaultState = {
  isFetchingBills: false,
  fetchError: false,
  fetchSuccess: false,

  bills: [],
}

const BillsReducer = handleActions(
  {
    // Logout
    [logout]: () => defaultState,

    [fetchBillsProcess]: state => {
      state.isFetchingBills = true
      state.fetchError = false
      state.fetchSuccess = false
    },
    [fetchBillsError]: state => {
      state.isFetchingBills = false
      state.fetchError = true
      state.fetchSuccess = false
    },
    [fetchBillsSuccess]: (state, { payload }) => {
      state.isFetchingBills = false
      state.fetchError = false
      state.fetchSuccess = true
      state.bills = payload
    },
  },
  defaultState,
)

export default BillsReducer
