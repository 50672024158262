import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import filter from '~/utils/filter'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const SpinnerIcon = styled(filter(LoadingOutlined, ['size']))`
  ${props => props.size === 'large' && 'font-size: 40px'};
  ${props => props.size === 'normal' && 'font-size: 24px'};
  ${props => props.size === 'small' && 'font-size: 14px'};
`

const Component = ({
  indicator,
  size,
  text,
}) => {
  const { t } = useTranslation('common')

  const props = {}

  props.indicator = indicator || (
    <SpinnerIcon
      type="loading"
      size={size}
      spin
    />
  )

  if (text === null) {
    props.tip = t('loading')
  } else if (text) {
    props.tip = text
  }

  return (
    <Container>
      <Spin
        {...props}
      />
    </Container>
  )
}

Component.propTypes = {
  indicator: PropTypes.node,
  size: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
}

Component.defaultProps = {
  indicator: null,
  size: 'normal',
  text: null,
}

export default Component
