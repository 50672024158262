export const occurringDaysKey = [
  'on_monday',
  'on_tuesday',
  'on_wednesday',
  'on_thursday',
  'on_friday',
  'on_saturday',
  'on_sunday',
]

export const daysLabel = [
  {
    key: 'on_monday',
    label: 'Lu',
  },
  {
    key: 'on_tuesday',
    label: 'Ma',
  },
  {
    key: 'on_wednesday',
    label: 'Me',
  },
  {
    key: 'on_thursday',
    label: 'Je',
  },
  {
    key: 'on_friday',
    label: 'Ve',
  },
  {
    key: 'on_saturday',
    label: 'Sa',
  },
  {
    key: 'on_sunday',
    label: 'Di',
  },
]

export const days = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
