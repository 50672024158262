import { Button, Col, Divider, Form, Input, Row } from 'antd'
import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import Autocomplete from "react-google-autocomplete"
import { useDispatch, useSelector } from 'react-redux'
import Drawer from "~/components/common/Drawer"
import { householdUpdating } from '~/containers/Foyer/actions'
import useErrors from "~/hooks/useErrors"
import { isJson } from '~/utils/isJson'

const getValueFromGMAPComponents = (components, type) => {
  let value
  components.forEach((comp) => {
    if (comp.types.includes(type)) {
      value = comp.long_name
    }
  })
  return value
}

const EditHousehold = ({ data, showDrawer, setShowDrawer }) => {
  const dispatch = useDispatch()
  const initialValues = useRef(null)
  const [form] = Form.useForm()
  const { ErrorComponent, setErrors } = useErrors()
  const [addressObj, setAddressObj] = useState({})
  const {isUpdating} = useSelector((state) => state.household.household)

  const onFinish = useCallback(
    values => {
      
      dispatch(householdUpdating({
        household: data.alias,
        data: {
          address: JSON.stringify({
            "formatted_address": values.address + ' ' + values.street_number + ' ' + values.postal_code + ' ' + values.locality,
            "country": "",
            "locality": values.locality,
            "postal_code": values.postal_code,
            "route": values.address,
            "street_number": values.street_number,
            "lng": 0,
            "lat": 0,
          }),
          caf_number: values.caf_number,
          monthly_revenue: values.monthly_revenue,
        },
        onSuccess: () => setShowDrawer(false)
      }))
    },
    [data],
  )

  useEffect(() => {
    if (data) {
      const parsedAddress = isJson(data?.address) && JSON.parse(data?.address)
      initialValues.current = {
        address: parsedAddress?.route ?? '',
        street_number: parsedAddress?.street_number ?? '',
        postal_code: parsedAddress?.postal_code ?? '',
        locality: parsedAddress?.locality ?? '',
        caf_number: data?.caf_number,
        monthly_revenue: data?.monthly_revenue,
      }
    }
  }, [data])

  const handleAddressChange = useCallback(
    (data) => {
      // Reformat address object after updating
      const dataAddress = {
        formatted_address: data.formatted_address,
        street_number: getValueFromGMAPComponents(
          data.address_components,
          "street_number"
        ),
        route: getValueFromGMAPComponents(data.address_components, "route"),
        postal_code: getValueFromGMAPComponents(
          data.address_components,
          "postal_code"
        ),
        locality: getValueFromGMAPComponents(
          data.address_components,
          "locality"
        ),
        country: getValueFromGMAPComponents(data.address_components, "country"),
        lat: data.geometry.location.lat,
        lng: data.geometry.location.lat,
      }
      setAddressObj(dataAddress)
      form.setFieldsValue({
        address: (getValueFromGMAPComponents(
          data.address_components,
          "street_number"
        ) ?? '') + " " + (getValueFromGMAPComponents(data.address_components, "route") ?? ''),
        postal_code: getValueFromGMAPComponents(
          data.address_components,
          "postal_code"
        ),
        locality: getValueFromGMAPComponents(
          data.address_components,
          "locality"
        ),
      })
    },
    [form]
  )
  
  return (
    <Drawer
      open={showDrawer}
      handleCloseDrawer={() => setShowDrawer(false)}
      width={636}
      title="Éditer le foyer"
    >
      <Form layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues.current}
      >
        <ErrorComponent />
        <Form.Item
          label="Adresse"
          name="address"
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            }
          ]}
        >
          <Autocomplete
            types={["address"]}
            className="ant-input"
            onPlaceSelected={handleAddressChange}
          />
          {/* <Input></Input> */}
        </Form.Item>
        <Form.Item
          label="Complément d’adresse"
          name="street_number"
          // rules={[
          //   {
          //     required: true,
          //     message: "Ce champ ne peut être vide"
          //   }
          // ]}
        >
          <Input placeholder="Appartement, bâtiment, étage..."></Input>
        </Form.Item>
        <Row style={{ paddingTop: 10, marginBottom: 10 }}>
          <Col span={11}>
            <Form.Item
              label="Code postal"
              name="postal_code"
              rules={[
                {
                  required: true,
                  message: "Ce champ ne peut être vide"
                }
              ]}
            >
              <Input></Input>
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Ville"
              name="locality"
              rules={[
                {
                  required: true,
                  message: "Ce champ ne peut être vide"
                }
              ]}
            >
              <Input></Input>
            </Form.Item>
          </Col>
        </Row>
        {/* <Form.Item
        label=""
        name="foyer"
        rules={[
          {
            required: false,
            // message: "Ce champ ne peut être vide"
          }
        ]}
      >
        <Checkbox >Foyer hors commune</Checkbox>
      </Form.Item> */}
        <Divider></Divider>
        {/* <Form.Item
        label="Nombre d’enfant(s)"
        name="child_number"
        rules={[
          {
            required: true,
            message: "Ce champ ne peut être vide"
          }
        ]}
      >
        <InputNumber min={1} max={20} keyboard={true} defaultValue={1} />
      </Form.Item> */}
        <Form.Item
          label="Numéro CAF"
          name="caf_number"
          rules={[
            {
              required: false,
              message: "Ce champ ne peut être vide"
            }
          ]}
        >
          <Input></Input>
        </Form.Item>
        {/* <Form.Item
        label="Tranche de revenu"
        name="tranche"
        rules={[
          {
            required: true,
            message: "Ce champ ne peut être vide"
          }
        ]}
      >
        <Radio.Group
          defaultValue={tranche}
          value={tranche}
          optionType="button"
          buttonStyle="solid"
          options={[
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'C', value: 'C' },
            { label: 'D', value: 'D' },
            { label: 'E', value: 'E' },
            { label: 'F', value: 'F' },
            { label: 'G', value: 'G' },
          ]}
          onChange={(e) => { setTranche(e.target.value) }}
        />
      </Form.Item> */}
        <Form.Item
          label="Quotient familial (en €)"
          name="monthly_revenue"
          rules={[
            {
              required: false,
              message: "Ce champ ne peut être vide"
            }
          ]}
        >
          <Input></Input>
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          style={{ width: "100%", marginTop: 20 }}
          loading={isUpdating}
          // onClick={() => setShowDrawer(false)}
        >
          Enregistrer
        </Button>
      </Form>
    </Drawer>

  )
}

export default EditHousehold