import React, {
  useEffect,
} from 'react'
import {
  Button,
  Modal,
  Result,
} from "antd"
import {
  WarningOutlined,
} from '@ant-design/icons'
import {
  useNavigate,
} from 'react-router'

const PaymentResultPopUp = ({
  showPaymentResultModal,
  paymentStatus,
  setShowPaymentResultModal,
}) => {
  const navigate = useNavigate()

  const handleReturnToCart = () => {
    setShowPaymentResultModal(false)
    navigate('/cart')
  }

  const handleReturnToHome = () => {
    setShowPaymentResultModal(false)
    navigate('/')
  }

  console.log({paymentStatus})
  const isPaymentValidated = paymentStatus === "0"

  return (
    <Modal
    open={showPaymentResultModal}
      closable={false}
      footer={null}
      width="80%"
    >
      {isPaymentValidated ? (
        <Result
          status="success"
          title="Votre paiement et vos réservations sont validés !"
          extra={[
            <Button
              type="primary"
              key="cart"
              onClick={handleReturnToCart}
            >
              Revenir au panier
            </Button>,
            <Button
              key="home"
              onClick={handleReturnToHome}
            >
              Revenir à l’accueil de Cityviz
            </Button>,
          ]}
        />
      ) : (
        <Result
          icon={
            <WarningOutlined
              style={{
                color: '#F5222D',
              }}
            />
          }
          title="Votre paiement a été refusé !"
          subTitle={
            <p
              style={{
                color: '#1E1E1E',
              }}
            >
              Vous pouvez essayer à nouveau.
              <br />
              Si cela se repète, veuillez contacter votre organisation SVP.
            </p>
          }
          extra={[
            <Button
              type="primary"
              key="cart"
              onClick={handleReturnToCart}
            >
              Revenir au panier
            </Button>,
          ]}
        />
      )
    }
    </Modal>
  )
}

export default PaymentResultPopUp