import { css } from 'styled-components'

export default css`
  @font-face {
    font-family: 'Helvetica Neue LT Std';
    src: local('Helvetica Neue LT Std 45 Light'), local('HelveticaNeueLTStd-Lt'),
      url('/fonts/subset-HelveticaNeueLTStd-Lt.woff2') format('woff2'),
      url('/fonts/subset-HelveticaNeueLTStd-Lt.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }

  /* @font-face {
    font-family: 'Helvetica Neue LT Std';
    src: local('Helvetica Neue LT Std 46 Light Italic'), local('HelveticaNeueLTStd-LtIt'),
      url('/fonts/subset-HelveticaNeueLTStd-LtIt.woff2') format('woff2'),
      url('/fonts/subset-HelveticaNeueLTStd-LtIt.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: block;
  } */

  @font-face {
    font-family: 'Helvetica Neue LT Std';
    src: local('Helvetica Neue LT Std 55 Roman'), local('HelveticaNeueLTStd-Roman'),
      url('/fonts/subset-HelveticaNeueLTStd-Roman.woff2') format('woff2'),
      url('/fonts/subset-HelveticaNeueLTStd-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  /* @font-face {
    font-family: 'Helvetica Neue LT Std';
    src: local('Helvetica Neue LT Std 56 Italic'), local('HelveticaNeueLTStd-It'),
      url('/fonts/subset-HelveticaNeueLTStd-It.woff2') format('woff2'),
      url('/fonts/subset-HelveticaNeueLTStd-It.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: block;
  } */

  @font-face {
    font-family: 'Helvetica Neue LT Std';
    src: local('Helvetica Neue LT Std 65 Medium'), local('HelveticaNeueLTStd-Md'),
      url('/fonts/subset-HelveticaNeueLTStd-Md.woff2') format('woff2'),
      url('/fonts/subset-HelveticaNeueLTStd-Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Helvetica Neue LT Std';
    src: local('Helvetica Neue LT Std 75 Bold'), local('HelveticaNeueLTStd-Bd'),
      url('/fonts/subset-HelveticaNeueLTStd-Bd.woff2') format('woff2'),
      url('/fonts/subset-HelveticaNeueLTStd-Bd.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: block;
  }

  /* @font-face {
    font-family: 'Helvetica Neue LT Std';
    src: local('Helvetica Neue LT Std 66 Medium Italic'), local('HelveticaNeueLTStd-MdIt'),
      url('/fonts/subset-HelveticaNeueLTStd-MdIt.woff2') format('woff2'),
      url('/fonts/subset-HelveticaNeueLTStd-MdIt.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: block;
  } */
`
