import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { CheckOutlined } from '@ant-design/icons'

import { useTranslation } from 'react-i18next'
import { occurringDaysKey, daysLabel } from '~utils/days'
import ActivityIcon from '~/components/common/ActivityIcon'
import P from '~/components/common/P'
import Divider from '~/components/common/Divider'
import ArrowDown from '~/assets/images/arrow-down.svg'

import {
  ArrowIcon,
  ActivityCard,
  ActivityHeader,
  ActivityInfo,
  ActivityDescription,
  ActivityDay,
  ActivityDayDisabled,
  ActivityBadge,
  ActivtiyBody,
} from './components'
import ChildCalendar from '../Calendar'

const ExpandedActivity = ({
  onActiveChange,
  activity,
  subscription,
}) => {
  const { childDetails: child } = useSelector(state => state.children)
  const { t } = useTranslation('children')

  
  const getDaysOfPresence = () => {
    const activityDays = occurringDaysKey.filter(ocd => activity[ocd])
    const childSubscription = child.subscriptions.find(
      sub => sub.alias === activity.alias,
    )
    return activityDays.map(acd => {
      // Check if this day is true for child
      const d = childSubscription[acd]
      const dayLabel = daysLabel.find(dl => dl.key === acd)

      if (d === true) {
        return (
          <ActivityDay key={`day-of-presence-${dayLabel.label}`}>
            <ActivityBadge>
              <CheckOutlined />
            </ActivityBadge>
            <P>
              {dayLabel.label}
            </P>
          </ActivityDay>
        )
      }
      return (
        <ActivityDayDisabled
          key={`day-of-presence-${dayLabel.label}`}
        >
          <P>
            {dayLabel.label}
          </P>
        </ActivityDayDisabled>
      )
    })
  }

  useEffect(() => {
    getDaysOfPresence()
  }, [])

  return (
    <ActivityCard>
      <ActivityHeader
        onClick={() => onActiveChange('')}
        dark
      >
        <ActivityInfo active>
          <ActivityIcon active activityType={subscription.activityType} />
          <div className="text-container">
            <P bold className="activity-name">
              {activity.parent_activity_name}
            </P>
            <P align="center" className="days">
              {
                subscription?.activityPeriodSelection === 0 ? (
                  <span>{ occurringDaysKey.filter(d => activity[d]).length}&nbsp; {t('child.activites.days-a-week')}</span>) : (
                    <span>{t('child.activites.forfait')}</span>
                )
              }
            </P>
          </div>
        </ActivityInfo>
        <ArrowIcon src={ArrowDown} alt="" />
      </ActivityHeader>
      { activity.activityDescription && (
        <div>
          <ActivityDescription>
            <P bold>
              {t('child.activites.info')}
            </P>
            <P>
              {activity.activityDescription}
            </P>
          </ActivityDescription>
          <Divider />
        </div>
      )}

      <ActivityDescription>
        <P bold>
          {t('child.activites.presence')}
        </P>
        <ActivtiyBody>
          {subscription?.activityPeriodSelection === 0 && (
            <ChildCalendar
              activity={activity}
              subscription={subscription}
            />
          )}

        </ActivtiyBody>
      </ActivityDescription>
    </ActivityCard>
  )
}
ExpandedActivity.propTypes = {
  subscription: PropTypes.shape().isRequired,
  activity: PropTypes.shape().isRequired,
  onActiveChange: PropTypes.func.isRequired,
}
export default ExpandedActivity
