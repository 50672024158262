import styled from 'styled-components'
import {
  AutoComplete,
} from 'antd'

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: @neutral-0;
`

export const RightContent = styled.div`
  margin-left: auto;
`

export const CustomSearch = styled(AutoComplete)`
  width: 200px;
`
