import { CheckCircleFilled } from "@ant-design/icons";
import { Button, Checkbox, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { DocumentTypes } from "~/constants";
import {
  documentsTypesFetching,
  registrationSubmitting,
} from "~/containers/Registration/actions";
import { MY_FEEDS, MY_HOUSEHOLD } from "~/graphql/queries";
import apolloClient from "~/lib/apolloClient";
import requiredInformation from "~/utils/requiredInformation";

const Step5 = ({
  organisation,
  childAliases,
  children,
  childrenRegistrations,
  registrationSuccess,
  setRegistrationSuccess,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user.userInfo.user);
  const userInfo = useSelector((state) => state.user.auth.user);
  const [checked, setChecked] = useState({});
  const { data: documentsTypes } = useSelector(
    (state) => state.registration.documentsTypes
  );
  const { registrationsDetails, registrationDetails, loading: isSubmitingRegistration } = useSelector(
    (state) => state.registration
  );
  // const {
  //   required_information,
  //   required_documents_details,
  //   documents_organisation,
  // } = organisation || {};

  const required_documents_details = organisation?.required_documents_details
  const required_information = organisation?.required_information
  const documents_organisation = organisation?.documents_organisation
  //
  const orgDocumentsToValidate = documents_organisation?.filter(
    (doc) => doc?.status === 1
  );

  const isRenewRegistration = location?.search === "?renew_registration=1";

  const refetchQueries = useCallback(
    async () => {
      console.log('refetchQueries')
      await apolloClient.refetchQueries({
        include: [MY_FEEDS, MY_HOUSEHOLD],
      });
    },
    [apolloClient],
  );

  useEffect(() => {
    dispatch(documentsTypesFetching());
    // dispatch(orgDocumentsFetching(organisation?.alias))
  }, []);

  let selectedChild = [];

  for (let alias of childAliases) {
    selectedChild.push(children.find((child) => child.alias == alias));
  }

  const canSubmitRegistration =
    orgDocumentsToValidate?.length + 1 === Object.values(checked)?.length &&
    Object.values(checked)?.every((elm) => !!elm);

  const handleSubmitRegistration = () => {
    if (canSubmitRegistration) {
      childAliases.forEach((childAlias, index) => {
        const childRegistration = registrationsDetails?.find(
          reg => reg.organisationAlias === organisation.alias && reg?.child?.alias === childAlias
        ) || registrationDetails;

        dispatch(
          registrationSubmitting(childAlias, childRegistration?.alias, async () => {
            await refetchQueries();
            if (index === childAliases.length - 1) {
              setRegistrationSuccess(true);
            }
          })
        );
      });
    }
  };

  if (registrationSuccess) {
    return (
      <Padding>
        <Space
          direction="vertical"
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
          size={40}
        >
          <CheckCircleFilled style={{ fontSize: 70, color: "#20CD8D" }} />
          <div>
            <Bold>
              {isRenewRegistration ? "La réinscription" : "L'inscription"} de{" "}
              {selectedChild?.length === 0 ? "votre enfant" : "vos enfants"} à
              l’organisation {organisation?.name} est bien prise en compte !
            </Bold>
            <Bold>
              Votre dossier sera prochainement vérifié afin que vous puissiez
              inscrire{" "}
              {selectedChild?.length === 0 ? "votre enfant" : "vos enfants"} aux
              activités proposées.
            </Bold>
          </div>
          <Button
            style={{ selfAlign: "center", width: 266, height: 40 }}
            size="large"
            type="primary"
            onClick={() => navigate("/")}
          >
            Retour à l’accueil
          </Button>
        </Space>
      </Padding>
    );
  }

  return (
    <>
      <Bold>Résumé de l’inscription</Bold>
      <Text>Organisation : {organisation?.name}</Text>
      <Text>
        Individus :{" "}
        {selectedChild.map((child, i) => {
          if (i === selectedChild.length - 1)
            return <span>{child?.first_name + " " + child?.last_name}</span>;
          return (
            <span>{child?.first_name + " " + child?.last_name + ", "}</span>
          );
        })}
      </Text>
      <Text>
        Responsable : {user?.first_name} {user?.last_name}
      </Text>
      <Text>E-mail : {userInfo?.email}</Text>
      <Text>
        Documents :{" "}
        {required_documents_details?.map((doc) => doc?.description + ", ")}
      </Text>
      <Text>
        Informations :{" "}
        {required_information?.map(
          (info) => requiredInformation?.[info]?.label + ", "
        )}
      </Text>
      <CheckboxesContainer>
        {orgDocumentsToValidate?.map((doc) => (
          <Checkbox
            value={checked?.[doc?.link]}
            onChange={(e) =>
              setChecked((state) => ({
                ...state,
                [doc?.link]: e.target.checked,
              }))
            }
          >
            Je reconnais avoir pris connaissance du document{" "}
            <a href={doc?.link} target="_blank">
              {doc?.name}
            </a>{" "}
            et accepte ses conditions.
          </Checkbox>
        ))}
        <Checkbox
          value={checked?.agreeCommunication}
          onChange={(e) =>
            setChecked((state) => ({
              ...state,
              agreeCommunication: e.target.checked,
            }))
          }
        >
          J'accepte de communiquer ce dossier d’inscription à{" "}
          {organisation?.name}
        </Checkbox>
      </CheckboxesContainer>
      <div>
        <Button
          disabled={!canSubmitRegistration}
          style={{ selfAlign: "center", width: 266, height: 40 }}
          size="large"
          type="primary"
          onClick={handleSubmitRegistration}
          loading={isSubmitingRegistration}
        >
          Valider l’inscription
        </Button>
      </div>
    </>
  );
};

const Bold = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: @neutral-5;
  padding-bottom: 24px;
  text-align: center;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: @neutral-4;
`;

const Padding = styled.div`
  padding-right: 10%;
  padding-left: 10%;
  margin: 0 auto;
  max-width: 1000px;
`;

const CheckboxesContainer = styled.div`
  padding-bottom: 24px;
  padding-top: 24px;

  > label {
    display: flex;
    margin-left: 0 !important;
  }
`;

export default Step5;
