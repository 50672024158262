
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Alert from '~/components/common/Alert'
import { getFeedRegistrations } from '~/containers/Home/selectors'

const HeaderNotifications = () => {
  const feedMessages = useSelector(getFeedRegistrations)
  const { loading } = useSelector(state => state.home.feed)
  const { children } = useSelector(state => state.children)
  const { t } = useTranslation('home')
  
  if (loading) return null
  return (
    <>
      {feedMessages ?.map((message, index) => {
        if (message.renewal_status === 0) {
          const msgUrl = `/${message.feedType}/${message.childAlias}/${message.alias}`
          const msgContent = (
            <a
              className="link-underline"
              href={msgUrl}
            >
              {t('header-notifications.renewal-text',
                {
                  childFirstname: message.childFirstname,
                  organisationName: message.organisationCity,
                })}
            </a>
          )
          return (
            <Alert
              key={index}
              message={msgContent}
              type="warning"
              closable
            />
          )
        } if (message.status === 4) {
          const msgUrl = `/${message.feedType}/${message.childAlias}/${message.alias}`
          const msgContent = (
            <a
              className="link-underline"
              href={msgUrl}
            >
              {t('header-notifications.waiting-text',
                {
                  childFirstname: message.childFirstname,
                })}
            </a>
          )
          return (
            <Alert
              message={msgContent}
              type="warning"
              closable
            />
          )
        }
        if (message.status === 2) {
          const msgUrl = `/${message.feedType}/${message.childAlias}/${message.alias}`
          const msgContent = (
            <>
              <a
                className="link-underline"
                href={msgUrl}
              >
                {t('header-notifications.reject-text',
                  {
                    childFirstname: message.childFirstname,
                  })}
              </a>
            </>
          )
          return (
            <Alert
              message={msgContent}
              type="warning"
              closable
            />
          )
        }
        return null
      })}
    </>
  )
}

export default HeaderNotifications
