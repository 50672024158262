import AllergyList from '~/utils/allergyList'
import Gender from '~/utils/gender'
import { classTypes } from '~/utils/typesClass'

export default {
  'child.first_name': {
    label: 'Prénom',
    type: 'text',
  },
  'child.last_name': {
    label: 'Nom',
    type: 'text',
  },
  'child.date_of_birth': {
    label: 'Date de naissance',
    type: 'date',
  },
  'child.allergy': {
    label: 'Allergies',
    type: 'select',
    values: AllergyList,
    isObject: true,
    valueKey: 'label',
    isMultiple: true,
  },
  'child.photo': {
    label: 'Photo de l\'enfant',
    type: 'file.image',
  },
  'child.insurance': {
    label: 'Nom et numéro de la police d\'assurance',
    type: 'text',
  },
  'child.gender': {
    label: 'Sexe',
    type: 'select',
    values: Gender,
    isObject: true,
    valueKey: 'code',
  },
  'child.classtype': {
    label: 'Classtype',
    type: 'select',
    values: classTypes,
  },
  'user.profile.address': {
    label: 'Adresse',
    type: 'address',
  },
  'user.profile.mobile': {
    label: 'Mobile',
    type: 'text',
  },
  'user.profile.photo': {
    label: 'Photo du parent',
    type: 'file.image',
  },
  'registration.social_security': {
    label: 'Sécurité Sociale',
    type: 'text',
  },
  'registration.photo_authorization': {
    label: 'Autorisation de photographier',
    type: 'checkbox',
  },
  'registration.schooltrip_authorization': {
    label: 'Autorisation de participer aux sorties scolaires',
    type: 'checkbox',
  },
  'billingHousehold.monthly_revenue': {
    label: 'Coefficient CAF',
    type: 'text',
  },
  'billingHousehold.caf_number': {
    label: 'Numéro CAF',
    type: 'text',
  },
}
