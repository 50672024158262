import React, { useState, useEffect, useMemo } from "react"
import { useMediaQuery } from "react-responsive"
import { Menu, Spin } from "antd"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import Logo from "~/components/common/Logo"
import logoSrc from "~/assets/images/logo.png"
import Link from "~/components/common/Link"
import User from "./components/User"
import { NavBar, NavBarMenu, LoggedUser } from "./components"
import DrawerMenu from "./DrawerMenu"
import { LoadingOutlined, ShoppingCartOutlined } from "@ant-design/icons"
import { Badge } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { fetchCartPrices, fetchOrders } from "~/containers/Cart/actions"
import { organisationsActivitiesFetching } from "~/containers/Subscription/actions"
import { userOrganisationsFetching } from "~/containers/Home/actions"
import dayjs from "dayjs"

// import BurgerMenu from './BurgerMenu'

const NavigationComponent = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState([])
  const { t } = useTranslation("common")
  const { pathname } = useLocation()
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })
  const { userOrganisationsList } = useSelector(
    (state) => state.home.organisation
  )
  const {
    isFetching: isFetchingAllAttendances,
  } = useSelector(
    (state) => state.children.attendance
  )
  const {
    isFetching: isFetchingOrders,
    data: orders,
  } = useSelector(
    (state) => state.cart.orders
  )

  useEffect(() => {
    const orgsAliases = userOrganisationsList?.map(org => org?.alias)
    
    dispatch(organisationsActivitiesFetching(
      orgsAliases,
    ))
  }, [userOrganisationsList.length])

  useEffect(() => {
    
    dispatch(
      fetchOrders()
    )
  }, [])

  const numberOfattendances = useMemo(() => {
    return orders ? orders.reduce((acc, cur) => {
      Object.keys(cur.attendances).map((childAlias) => {
        const activities = cur.attendances?.[childAlias]
        
        return Object.keys(activities)?.filter(item => item !== 'total').map((activityAlias) => {
          const activity = activities?.[activityAlias]
          const activityQuantity = activity?.quantity

          acc += activityQuantity
        })
      })

      return acc
    }, 0) : 0
  }, [orders])

  return (
    <NavBar>
      <Logo src={logoSrc} />
      {isMobile ? (
        <DrawerMenu />
      ) : (
        <>
          <div
            style={{
              width: "100%",
              justifyContent: "flex-end",
              display: "flex",
              height: "100%",
            }}
          >
            <Link to="/cart">
              {/* {isFetchingAllAttendances || isFetchingOrders ? (
                <Badge
                  count={<Spin indicator={<LoadingOutlined style={{ fontSize: 18, color: '#ff4d4f' }} spin />} />}
                  style={{ marginTop: 20, borderColor: "black" }}
                >
                  <ShoppingCartOutlined
                    style={{ color: pathname.includes('/cart') ? "#20CD8D" : "white", fontSize: 25, marginTop: 20 }}
                  />
                </Badge>
              ) : (
                <>
                  {numberOfattendances === 0 ? (
                    <ShoppingCartOutlined
                      style={{ color: pathname.includes('/cart') ? "#20CD8D" : "white", fontSize: 25, marginTop: 20 }}
                    />
                  ) : ( */}
                    <Badge
                      count={numberOfattendances}
                      style={{ marginTop: 20, borderColor: "black" }}
                    >
                      <ShoppingCartOutlined
                        style={{ color: pathname.includes('/cart') ? "#20CD8D" : "white", fontSize: 25, marginTop: 20 }}
                      />
                    </Badge>
                  {/* )}
                </>
              )} */}
            </Link>
          </div>
          <LoggedUser>
            <User />
          </LoggedUser>
        </>
      )}
    </NavBar>
  )
}

export default NavigationComponent
