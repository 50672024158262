import React, { memo } from "react"
import { Link } from "react-router-dom"
import { Skeleton, Space } from "antd"
import { LinkOutlined } from "@ant-design/icons"

import H1 from "~/components/common/H1"
import Text from "~/components/common/Text"
import Picture from "~/components/common/Picture"
import Gender from "~/components/common/Gender"
import StatusSelect from "~/components/common/StatusSelect"

const ChildrenCard = ({
  isLoading,
  onStatusChange,
  name,
  picture,
  photo,
  birthdate,
  age,
  gender,
  schoolName,
  status,
  households,
  schoolLevelName,
  schoolAlias,
}) => {
  
  if (isLoading) {
    return (
      <Skeleton
        active
        title={false}
        avatar={{
          size: 120,
        }}
        paragraph={{
          rows: 4,
          width: [335, 250, 350, 300],
        }}
      />
    )
  }

  return (
    <Space>
      <Picture width="120" height="120" src={picture} alt={name} />
      <Space size={2} direction="vertical">
        <H1>
          <Space size="middle" align="center">
            {name}
            <Gender gender={gender} />
            {/* WARNING: using != instead of !== will ensure undefined is treated as null */}
            {status != null && (
              <StatusSelect
                defaultValue={status}
                onStatusChange={onStatusChange}
              />
            )}
          </Space>
        </H1>
        <Text size="big">
          {age} ans (<Text>né{gender === 1 && 'e'} le {birthdate}</Text>)
        </Text>

        {schoolAlias && schoolLevelName && schoolName && (
          <Text>
            <Link to={`/classes/${schoolAlias}`}>{schoolLevelName}</Link> (
            {schoolName})
          </Text>
        )}
        <Text size="big">
          {households?.map((household) => {
            return (
              <>
                <LinkOutlined
                  style={{ marginRight: "5px", fontSize: "12px" }}
                />
                Foyer{" "}
                <Link to={`/family/households/${household.alias}`}>
                  {household.name}
                </Link>
              </>
            )
          })}
        </Text>
      </Space>
    </Space>
  )
}

export default memo(ChildrenCard)
