import React from 'react'
import {
  Select,
} from 'antd'

// Enables case-insensitive filtering
const filterOptionFn = (
  inputValue,
  option,
) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1

// Fixes a bug which scrolls the window while you scroll the dropdown
const getPopupContainerFn = triggerNode => triggerNode.parentElement

const Search = ({
  allowClear = true,
  showSearch = true,
  showArrow = false,
  filterOption = filterOptionFn,
  getPopupContainer = getPopupContainerFn,
  ...props
}) => (
    <Select
      allowClear={allowClear}
      showSearch={showSearch}
      showArrow={showArrow}
      filterOption={filterOption}

      getPopupContainer={getPopupContainer}
      {...props}
    />
  )

export default Search
