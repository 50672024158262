import styled, { css } from "styled-components";
import { MainContent } from "~/components/common/Container";

export const Border = styled.div`
  // border: 1px solid red;
  width: 200px;
  min-width: 200px;
  text-align: center;
  vertical-align: text-bottom;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  color: @neutral-4;
  font-size: 12px;
`;

export const OrgContainer = styled.div`
  border: 0.5px solid #969696;
  border-radius: 8px;
  min-width: fit-content;
  overflow: hidden;
  margin-top: 5px;
`;

export const ActivityContainer = styled.div`
  display: flex;
  flex-direction: row;
  // width: fit-content;
  max-width: 100%;
  &:not(:first-child) {
    border-top: 1px solid #969696;
  }
  overflow: hidden;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.2;
    `}
`;

export const BadgePill = styled.div`
  font-size: 12px;
  //color: #ffff;
  fontWeight: 'bold';
  text-align: center;
  background-color:  ${(props) => props.color};
  border-radius: 5em;
  width: 100%;
  height: auto;
  margin: 1em;
`;

export const ActivityName = styled.div`
  padding-right: 2px;
  padding-left: 2px;
  text-align: center;
  width: 200px;
  min-width: 200px;
  min-height: 30px;
  /* line-height: 30px; */
  vertical-align: center;
  color: #1890ff;
  border-right: 1px solid #969696;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Case = styled.div`
  border-right: 1px solid #969696;
  background: ${(props) => props.color};
  min-height: 30px;
  min-width: 30px;
  max-width: 100%;
  width: 100%;
  width: 100%;
  :last-child {
    border: none;
  }
`;

export const Draw = styled.hr`
  background: ${(props) => props.color};
  height: 3px;
  min-width: 30px;
  width:45%;
  margin: 0;
  padding: 0;
  border: none;
`
export const Box = styled.div`
  border: 1px solid #969696;;
  min-height : 30px;
  min-width : 30px;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Recurrent = styled.div`
  text-align: center;
  background-color: #ffcdd6;
  min-height: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${({ isDisabled }) =>
  isDisabled &&
  css`
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  `}
`;

export const DynamicPadding = styled.div`
  // text-align: center;
  // background-color: #ffcdd6;
  min-height: 30px;
  /* height: 100%; */
  min-width: 30px;
`;

export const Day = styled.div`
  background: ${(props) => props.color};
  color: ${(props) => props.textColor};
  border-radius: 8px;
  display: flex;
  min-width: 20px;
  // max-width: 50px;
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  flex-direction: column;
  justify-content: space-around;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // width: 100%;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const DescriptionContainerRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  // width: 100%;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: ${(props) => props.color};
  margin-top: 10px;
  margin-right: 10px;
`;

export const Description = styled.div`
  font-size: 12px;
  margin-top: 5px;
  margin-right: 3px;
`;

export const DayLetter = styled.div`
  text-align: center;
  min-width: 20px;
  max-width: 50px;
  font-size: 12px;
  width: 100%;
  color: @neutral-4;
  color: ${(props) => props.textColor};
  text-transform: capitalize;
`;

export const DayNumber = styled.div`
  text-align: center;
  min-width: 20px;
  max-width: 50px;
  font-size: 9px;
  width: 100%;
  color: @neutral-4;
  color: ${(props) => props.textColor};
`;

export const DayContainer = styled.div`
  // border: 1px solid red;
  height: 40px;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 60px;
`;

export const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
`;

export const Calendar = styled.div`
  overflow: auto;
  max-width: 100%;
  width: 100%;
`;

export const DescWrapper = styled.div`
  display:flex;
  flex-direction-row;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
`;

export const StyledDay = styled.div`
  height: 40px;
  border: 1px solid #e2e4e6;
  padding: 9px 12px;
  color: #646464;
  cursor: pointer;
  font-size: 14px;

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid #1890ff;
      color: #1890ff;
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`;

export const OrgActivitiesContainer = styled(MainContent)`
  margin-bottom: 24px;
  min-width: fit-content;

  .ant-collapse-header {
    display: flex;
    align-items: center;
    padding: 0 !important;
  }

  .ant-collapse-content {
    border-top: none;
  }

  .anticon-right {
    font-size: 32px !important;
    top: 50% !important;
    transform: translateY(-50%);
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 0;
  }

  .ant-collapse-item {
    background-color: white;
    border-bottom: 0;
  }
`;
