import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal, message, Button } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import {
  createAttendance,
  createMultipleAttendances,
  createRecurrentAttendance,
  createAttendanceByContainer,
  requestCredit,
} from "~/containers/Children/actions";
import { getAllMondaysInDateRange } from "~/utils/getAllMondaysInDateRange";
import { getSubscriptionStatus, getConfirmPeriodReservationLabel } from "~/constants";
import updateLocale from 'dayjs/plugin/updateLocale'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBoolean from 'lodash/isBoolean';

dayjs.extend(isSameOrAfter)
dayjs.extend(updateLocale)

dayjs.updateLocale("fr", {
  weekdays: "sunday_monday_tuesday_wednesday_thursday_friday_saturday".split(
    "_"
  ),
  week: {
    dow: 1,
  },
});

const { confirm } = Modal;

let manageSelectedCell = (
  selectedCell,
  dispatch,
  // patchAttendance,
  deleteAttendance,
  addPaidAttendance,
  removePaidAttendance,
  activitiesBillingModel,
  organisationsActivitiesData,
  allAttendances,
  orders,
  allSubscriptions,
  massDeleteAttendances,
  requestCredit,
  cancelRequestCredit,
  userOrganisationsList,
) => {
  const child = selectedCell?.child;
  const organisationAlias = selectedCell?.sub?.organisation_alias;
  const activityAlias = selectedCell?.sub?.parent_activity_alias;
  const isPrepaid =
    activitiesBillingModel?.[organisationAlias]?.[activityAlias] === 2;
  const activityDetails = organisationsActivitiesData?.[
    organisationAlias
  ]?.find((activity) => activity.actAlias === activityAlias);

  const organisationAllowCreditCreation = userOrganisationsList?.find(
    (org) => org?.alias === organisationAlias
  )?.prepaid_allow_attendance_credit_by_hhself;

  const organisationName = userOrganisationsList?.find(
    (org) => org?.alias === organisationAlias
  )?.name;
  const activityAllowCreditCreation = activityDetails?.prepaid_allow_attendance_credit_by_hhself;

  console.log({
    child,
    selectedCell,
    activitiesBillingModel,
    orders,
    activityDetails,
    organisationAllowCreditCreation,
    activityAllowCreditCreation,
  });

  if (!selectedCell) return;

  /* 2022/09/30 CTRL fonctionnel porté par le BO, pose problème sur l'utilisation de dayjs(
 * action ChD
  if (dayjs(activityDetails?.prevenance_date).isBefore(dayjs())) {
    message.warning(
      `Non autorisé! L'activité ${selectedCell?.sub?.parent_activity_name} est hors délai de prévenance pour cette date!`
    );

    return;
  }
 */

  const paymentInitiated =
    isPrepaid &&
    orders?.find(
      (order) =>
        order?.status === 2 &&
        order?.organisation_alias === selectedCell?.sub?.organisation_alias
    );

  if (child?.first_name !== "Tous") {
    if (selectedCell?.sub?.childs) {
      const subStatus = allSubscriptions?.find(
        (sub) =>
          sub?.parent_activity_alias ===
            selectedCell?.sub?.parent_activity_alias &&
          sub?.child_alias === child?.alias
      )?.status;

      console.log({ subStatus });
      if (subStatus !== 1) {
        const statusLabel = getSubscriptionStatus(subStatus)?.label;

        message.warning(
          `Non autorisé! L'inscription de ${child?.first_name} à l'activité ${
            selectedCell?.sub?.parent_activity_name
          } est ${statusLabel || "indisponible"}`
        );

        return;
      }
    } else {
      if (selectedCell?.sub?.status !== 1) {
        const statusLabel = getSubscriptionStatus(
          selectedCell?.sub?.status
        )?.label;

        message.warning(
          `Non autorisé! L'inscription de ${child?.first_name} à l'activité ${
            selectedCell?.sub?.parent_activity_name
          } est ${statusLabel || "indisponible"}`
        );

        return;
      }
    }
  }

  if (selectedCell?.sub?.childs && child?.first_name === "Tous") {
    const subStatus = allSubscriptions?.find(
      (sub) =>
        sub?.parent_activity_alias ===
          selectedCell?.sub?.parent_activity_alias && sub?.status !== 1
    )?.status;
    const numberOfSubscriptions = allSubscriptions?.filter(
      (sub) =>
        sub?.parent_activity_alias === selectedCell?.sub?.parent_activity_alias
    );

    console.log({
      numberOfSubscriptions,
      lll: selectedCell?.sub?.childs,
      subStatus,
    });

    if (subStatus !== undefined) {
      const statusLabel = getSubscriptionStatus(subStatus)?.label;

      message.warning(
        `Non autorisé! L'inscription de l'un (ou plusieurs) enfants à l'activité ${
          selectedCell?.sub?.parent_activity_name
        } est ${statusLabel || "indisponible"}`
      );

      return;
    }

    if (
      numberOfSubscriptions?.length <
      Object.keys(selectedCell?.sub?.childs)?.length
    ) {
      message.warning(
        `Non autorisé! L'inscription de l'un (ou plusieurs) enfants à l'activité ${selectedCell?.sub?.parent_activity_name} est inexistante actuellement`
      );

      return;
    }
  }

  const day = selectedCell.day;
  const eventExists = !!selectedCell.openDay.find(
    (d) => d.date === day.format("YYYY-MM-DD")
  );

  const selectedDay = selectedCell.attendance;

  const childAlias = child?.alias;
  const weekActivityAlias = selectedCell.sub.openingDays.find((openingDay) =>
    day?.isSame(dayjs(openingDay?.date, "YYYY-MM-DD"))
  )?.activity;
  const alias = selectedCell.sub.activity_alias;
  const activityName = selectedCell.sub.parent_activity_name;
  const allChildrenAreRegistred =
    selectedCell?.sub.childs &&
    Object.values(selectedCell?.sub.childs).reduce((acc, cur) => {
      const isFound = cur.find(
        (att) =>
          att.activity_alias === selectedCell?.sub?.parent_activity_alias &&
          att.date === day.format("YYYY-MM-DD")
      );

      if (isFound) {
        acc = acc + 1;
      }

      return acc;
    }, 0) === Object.keys(selectedCell?.sub.childs).length;
  const isFinished = activityDetails?.status === 2;
  const periodSelection = activityDetails?.period_selection;
  const periodSelectionActive = activityDetails?.period_selection_active;
  const listOfWeeks = getAllMondaysInDateRange(
    activityDetails?.start_date,
    activityDetails?.end_date
  ).reduce((acc, date) => {
    let datesOfWeek = "";

    if (activityDetails?.opening_days?.includes(date)) {
      datesOfWeek = date;
    }
    if (
      activityDetails?.opening_days?.includes(
        dayjs(date).add(1, "days").format("YYYY-MM-DD")
      )
    ) {
      datesOfWeek = `${datesOfWeek}__${dayjs(date)
        .add(1, "days")
        .format("YYYY-MM-DD")}`;
    }
    if (
      activityDetails?.opening_days?.includes(
        dayjs(date).add(2, "days").format("YYYY-MM-DD")
      )
    ) {
      datesOfWeek = `${datesOfWeek}__${dayjs(date)
        .add(2, "days")
        .format("YYYY-MM-DD")}`;
    }
    if (
      activityDetails?.opening_days?.includes(
        dayjs(date).add(3, "days").format("YYYY-MM-DD")
      )
    ) {
      datesOfWeek = `${datesOfWeek}__${dayjs(date)
        .add(3, "days")
        .format("YYYY-MM-DD")}`;
    }
    if (
      activityDetails?.opening_days?.includes(
        dayjs(date).add(4, "days").format("YYYY-MM-DD")
      )
    ) {
      datesOfWeek = `${datesOfWeek}__${dayjs(date)
        .add(4, "days")
        .format("YYYY-MM-DD")}`;
    }
    if (
      activityDetails?.opening_days?.includes(
        dayjs(date).add(5, "days").format("YYYY-MM-DD")
      )
    ) {
      datesOfWeek = `${datesOfWeek}__${dayjs(date)
        .add(5, "days")
        .format("YYYY-MM-DD")}`;
    }
    if (
      activityDetails?.opening_days?.includes(
        dayjs(date).add(6, "days").format("YYYY-MM-DD")
      )
    ) {
      datesOfWeek = `${datesOfWeek}__${dayjs(date)
        .add(6, "days")
        .format("YYYY-MM-DD")}`;
    }

    if (datesOfWeek[0] === "_") {
      datesOfWeek = datesOfWeek.substring(2);
    }

    if (datesOfWeek !== "") {
      acc = [...acc, datesOfWeek];
    }

    return acc;
  }, []);
  const isUncancelableAttendance = selectedDay?.order &&
    selectedDay?.status === 5 &&
    selectedDay?.child_alias === child?.alias &&
    !selectedDay?.can_request_credit &&
    !selectedDay?.is_credit_requested

  if (isFinished) {
    message.warning(
      `Non autorisé! L'activité ${selectedCell?.sub?.parent_activity_name} est terminée`
    );

    return;
  }

  if (selectedDay && selectedDay.order && selectedDay.is_due) {
    message.warning(
      "Ce jour est une présence due, vous ne pouvez pas l'annuler"
    );
    return;
  }

  if (isUncancelableAttendance) {
    if (selectedCell?.requestCredit) {
      if (isBoolean(activityAllowCreditCreation) && !activityAllowCreditCreation) {
        message.info({
          content: (
            <p>
              votre organisation <strong>{organisationName}</strong> n'autorise pas l'annulation en ligne de réservations déjà payées pour cette activité.
            </p>
          ),
          icon: <InfoCircleOutlined style={{ color: "#FF5876" }} />,
        });
      } else if (!isBoolean(activityAllowCreditCreation) && !!organisationAllowCreditCreation) {
        message.info({
          content: (
            <p>
              votre organisation <strong>{organisationName}</strong> n'autorise pas l'annulation en ligne de réservations déjà payées.
            </p>
          ),
          icon: <InfoCircleOutlined style={{ color: "#FF5876" }} />,
        });
      }
    } else {
      message.warning("Cette date n'est pas modifiable");
    }
    return;
  }
  
  if (!eventExists && !selectedDay) {
    return;
  }

  const canRequestCredit = dayjs(selectedDay?.date).isSame(day, "day")
    && selectedDay?.child_alias === child?.alias
    && selectedDay?.can_request_credit
    && isPrepaid

  if (canRequestCredit) {
    confirm({
      title: activityName,
      content: (
        <div>
          <p
            style={{
              fontSize: 16,
              fontWeight: "bold",
            }}
          >
            Vous êtes sur le point de demander un avoir pour le {day.format("DD/MM")} pour {child.first_name}. Voulez-vous continuer?
          </p>
          <p>
            Cette activité étant en pré-paiement et ayant déjà payé la réservation, cette annulation donnera lieu à l'émission d'un avoir 30 minutes après votre dernière demande.
          </p>
        </div>
      ),
      onOk() {
        requestCredit(selectedDay?.alias)
      },
      onCancel() {},
    });

    return;
  }

  const isCreditAlreadyRequested = dayjs(selectedDay?.date).isSame(day, "day")
    && selectedDay?.child_alias === child?.alias
    && selectedDay?.is_credit_requested
    && isPrepaid

  if (isCreditAlreadyRequested) {
    confirm({
      title: activityName,
      content: (
        <div>
          <p
            style={{
              fontSize: 16,
              fontWeight: "bold",
            }}
          >
            Vous êtes sur le point de demander l'annulation d'un avoir en cours pour le {day.format("DD/MM")} pour {child.first_name}. Voulez-vous continuer?
          </p>
          <p>
            Cette activité étant en pré-paiement et ayant déjà payé la réservation, vous avez fait une demande d'avoir il y a quelques minutes ... tant que l'avoir n'est pas émis vous pouvez néanmoins annuler votre demande.
          </p>
        </div>
      ),
      onOk() {
        cancelRequestCredit(selectedDay?.alias)
      },
      onCancel() {},
    });

    return;
  }

  if (dayjs(day.format("YYYY-MM-DD")).isBefore(dayjs(), "day")) {
    if (selectedDay && selectedDay.was_there === true) {
      message.success(`${child.first_name} était présent(e) ce jour-là`);
    } else if (selectedDay && selectedDay.is_listed === true) {
      message.warning(
        `${child.first_name} était inscrit(e), mais noté absent ce jour-là`
      );
    } else {
      message.info(`${child.first_name} n'était pas inscrit(e) ce jour-là`);
    }
  } else if (
    (selectedDay && selectedDay?.is_listed) ||
    allChildrenAreRegistred ||
    selectedDay?.order
  ) {
    if (paymentInitiated) {
      message.error(
        "Vous ne pouvez pas effectuer cette réservation, vous avez une opération en cours de paiement sur cette organisation. Merci de la finaliser d`abord!"
      );

      return;
    }
    console.log({selectedCell});
    // Patch attendance
    if (selectedDay?.order && selectedDay?.status === 0) {
      confirm({
        title: activityName,
        content: (
          <div>
            {(periodSelection >= 1)?(
              (periodSelection > 1 && periodSelectionActive === 0 )?(
                <p
                  style={{
                    fontSize: 16,
                  }}
                >
                  Souhaitez-vous annuler la réservation de {child.first_name} le{" "}
                  {day.format("D")}/{day.format("MM")}?
                </p>
              ):(
                <p
                  style={{
                    fontSize: 16,
                  }}
                >
                  Souhaitez-vous annuler la réservation de {child.first_name} pour <span style={{fontWeight: 'bold'}}>{getConfirmPeriodReservationLabel(periodSelection)} incluant le{" "}</span>
                  {day.format("D")}/{day.format("MM")}?
                </p>
              )              
            ):(
              <p
              style={{
                fontSize: 16,
              }}
            >
              Souhaitez-vous annuler la réservation de {child.first_name} le{" "}
              {day.format("D")}/{day.format("MM")}?
            </p>
            )}
            
            {isPrepaid && (
              <p
                style={{
                  color: "#FF5876",
                  fontSize: 12,
                }}
              >
                Cette activité est en prépaiement, pour une réservation annulée,
                un avoir sera disponible dans votre panier de commande.
              </p>
            )}
          </div>
        ),
        onOk() {
          const orderAlias = orders.find(
            (order) => order?.organisation_alias === organisationAlias
          )?.alias;

          if (periodSelection > 1) {
            if (child?.first_name !== "Tous") {
              let targetAttendances;
              if(periodSelectionActive != 0){
                
                let targetedContainer = selectedCell?.openDay?.find((element) => dayjs(element.date).format("YYYY-MM-DD") === dayjs(selectedCell?.day).format("YYYY-MM-DD")
                );
                targetAttendances = {
                  container_alias: targetedContainer?.container_alias,
                  children_alias: [selectedCell?.child?.alias],
                  month: day.format("M"),
                };

              }else if(periodSelectionActive === 0 ) {
               
                let attendances = allAttendances?.filter(
                  (item) =>
                    dayjs(item.date).format("YYYY-MM-DD") === dayjs(selectedCell?.day).format("YYYY-MM-DD")&&
                    selectedCell?.sub?.parent_activity_alias === item?.activity_alias && 
                    item?.child_alias === selectedCell?.child?.alias 
                )?.map((item) => item?.alias);

                targetAttendances = {
                  attendances: attendances,
                  children_alias: [selectedCell?.child?.alias],
                  month: day.format("M"),
                };
              }
              dispatch(
                massDeleteAttendances(targetAttendances)
              );

              return;
            }
  
          }else if (periodSelection === 1) {
            if (child?.first_name !== "Tous") {
              const dates = listOfWeeks
                ?.find((week) => week.includes(day.format("YYYY-MM-DD")))
                ?.split("__");

              const itemsToRemove = allAttendances
                ?.filter(
                  (item) =>
                    item?.order === orderAlias &&
                    dates?.includes(item?.date) &&
                    item?.activity_alias ===
                      selectedCell?.sub?.parent_activity_alias &&
                    item?.child_alias === child?.alias
                )
                ?.map((item) => item?.alias);

              // console.log({
              //   allAttendances,
              //   itemsToRemove,
              //   orderAlias,
              //   orders,
              //   dates,
              //   day,
              //   listOfWeeks,
              //   selectedCell,
              //   ll: selectedCell?.sub?.activity_alias,
              //   child,
              // });

              dispatch(
                massDeleteAttendances({
                  attendances: itemsToRemove,
                  month: day.format("M"),
                })
              );

              return;
            }
          } else {
            dispatch(
              massDeleteAttendances({
                attendances: [selectedDay?.alias],
                month: day.format("M"),
              })
            );

            return;
          }
        },
        onCancel() {},
      });
    } else if (
      // !isPrepaid &&
      (selectedDay?.is_editable ||
        (allChildrenAreRegistred &&
          Object.values(selectedCell?.sub.childs)?.[0].find(
            (att) =>
              att.activity_alias === selectedCell?.sub?.parent_activity_alias &&
              att.date === day.format("YYYY-MM-DD")
          )?.is_editable))
    ) {
      confirm({
        title: activityName,
        content: (
          <div>
            {(periodSelection >= 1)?(
              (periodSelection > 1 && periodSelectionActive === 0 )?(
                <p
                  style={{
                    fontSize: 16,
                  }}
                >
                  Souhaitez-vous annuler la réservation de {child.first_name} le{" "}
                  {day.format("D")}/{day.format("MM")}?
                </p>
              ):(
                <p
                  style={{
                    fontSize: 16,
                  }}
                >
                  Souhaitez-vous annuler la réservation de {child.first_name} pour <span style={{fontWeight: 'bold'}}>{getConfirmPeriodReservationLabel(periodSelection)} incluant le{" "}</span>
                  {day.format("D")}/{day.format("MM")}?
                </p>
              )           
            ):(
            <p
              style={{
                fontSize: 16,
              }}
            >
              Souhaitez-vous annuler la réservation de {child.first_name} le{" "}
              {day.format("D")}/{day.format("MM")}?
            </p>
            )}            
            {isPrepaid && (
              <p
                style={{
                  color: "#FF5876",
                  fontSize: 12,
                }}
              >
                Cette activité est en prépaiement, pour une réservation annulée,
                un avoir sera disponible dans votre panier de commande.
              </p>
            )}
          </div>
        ),
        onOk() {
          if (isPrepaid) {
            if (child?.first_name === "Tous") {
              console.log({periodSelection});
              let targetAttendances;
              const childsAliases = selectedCell?.sub?.childs && Object.keys(selectedCell?.sub?.childs)?.filter(
                  (ch) =>
                    selectedCell?.sub?.childs?.[ch]?.find((att) =>
                      dayjs(att?.date).isSame(selectedCell?.day)
                    )?.is_listed
                );
              if (periodSelection > 1) {
                const orderAlias = orders.find(
                  (order) => order?.organisation_alias === organisationAlias
                )?.alias;

                if(periodSelectionActive != 0){

                  let targetedContainer = selectedCell?.openDay?.find((element) => dayjs(element.date).format("YYYY-MM-DD") === dayjs(selectedCell?.day).format("YYYY-MM-DD"));

                  targetAttendances = {
                    container_alias: targetedContainer?.container_alias,
                    children_alias: childsAliases,
                    month: day.format("M"),
                  };
                }else if(periodSelectionActive === 0 ) {

                  let itemsToRemove = allAttendances
                    ?.filter(
                      (item) =>
                        item?.order === orderAlias &&
                        item?.date === selectedCell?.day?.format("YYYY-MM-DD") &&
                        item?.activity_alias ===
                          selectedCell?.sub?.parent_activity_alias
                    )
                    ?.map((item) => item?.alias);

                    targetAttendances = {
                      attendances: itemsToRemove,
                      children_alias: childsAliases,
                      month: day.format("M"),
                    };
                }

                dispatch(
                  massDeleteAttendances(targetAttendances)
                );

                return;
              } else if (periodSelection === 1) {
                const dates = listOfWeeks
                  ?.find((week) =>
                    week.includes(selectedCell?.day.format("YYYY-MM-DD"))
                  )
                  ?.split("__");
                const targetAttendances = allAttendances?.filter(
                  (item) =>
                    dates?.includes(item.date) &&
                    selectedCell?.sub?.parent_activity_alias ===
                      item?.activity_alias
                )?.map((item) => item?.alias);

                console.log({targetAttendances}, '+++++++++');

                dispatch(
                  massDeleteAttendances({
                    attendances: targetAttendances,
                    month: day.format("M"),
                  })
                );
                return;
              } else {
                const orderAlias = orders.find(
                  (order) => order?.organisation_alias === organisationAlias
                )?.alias;
  
                const itemsToRemove = allAttendances
                  ?.filter(
                    (item) =>
                      item?.order === orderAlias &&
                      item?.date === selectedCell?.day?.format("YYYY-MM-DD") &&
                      item?.activity_alias ===
                        selectedCell?.sub?.parent_activity_alias
                  )
                  ?.map((item) => item?.alias);
  
                dispatch(
                  massDeleteAttendances({
                    attendances: itemsToRemove,
                    month: day.format("M"),
                  })
                );
  
                return;
              }
            }
          }
          if (allChildrenAreRegistred && child.first_name === "Tous") {
            const childsAliases = selectedCell?.sub?.childs && Object.keys(selectedCell?.sub?.childs)?.filter(
              (ch) =>
                selectedCell?.sub?.childs?.[ch]?.find((att) =>
                  dayjs(att?.date).isSame(selectedCell?.day)
                )?.is_listed
            );
            if (periodSelection > 1){
              let targetAttendances;
              if(periodSelectionActive != 0){
                let targetedContainer = selectedCell?.openDay?.find((element) => dayjs(element.date).format("YYYY-MM-DD") === dayjs(selectedCell?.day).format("YYYY-MM-DD")
                );

                targetAttendances = {
                  container_alias: targetedContainer?.container_alias,
                  children_alias: childsAliases,
                  month: day.format("M"),
                };

              }else if(periodSelectionActive === 0 ) {
               
                let attendances = allAttendances?.filter(
                  (item) =>
                    dayjs(item.date, "YYYY-MM-DD").isSame(selectedCell?.day) &&
                    selectedCell?.sub?.parent_activity_alias === item?.activity_alias 
                )?.map((item) => item?.alias);

                targetAttendances = {
                  attendances: attendances,
                  children_alias: childsAliases,
                  month: day.format("M"),
                };
              }

              //console.log({targetAttendances, periodSelectionActive, periodSelection, selectedCell, allAttendances}, '++**--*-----*+');
              dispatch(
                massDeleteAttendances(targetAttendances)
              ); 

            }else if (periodSelection === 1) {
              const dates = listOfWeeks
                ?.find((week) =>
                  week.includes(selectedCell?.day.format("YYYY-MM-DD"))
                )
                ?.split("__");
              const targetAttendances = allAttendances?.filter(
                (item) =>
                  dates?.includes(item.date) &&
                  selectedCell?.sub?.parent_activity_alias ===
                    item?.activity_alias
              )?.map((item) => item?.alias);
          
              dispatch(
                massDeleteAttendances({
                  attendances: targetAttendances,
                  month: day.format("M"),
                })
              );

            } else {
              const targetAttendances = allAttendances?.filter(
                (item) =>
                  dayjs(item.date).isSame(selectedCell?.day) &&
                  selectedCell?.sub?.parent_activity_alias ===
                    item?.activity_alias
              )?.map((item) => item?.alias);

              dispatch(
                massDeleteAttendances({
                  attendances: targetAttendances,
                  month: day.format("M"),
                })
              );

              return;
            }
          } else {
            if (periodSelection > 1) {              
              let targetAttendances;
              if(periodSelectionActive != 0){
                let targetedContainer = selectedCell?.openDay?.find((element) => dayjs(element.date).format("YYYY-MM-DD") === dayjs(selectedCell?.day).format("YYYY-MM-DD")
                );

                targetAttendances = {
                  container_alias: targetedContainer?.container_alias,
                  children_alias: [selectedCell?.child?.alias],
                  month: day.format("M"),
                };

              }else if(periodSelectionActive === 0 ) {
               
                let attendances = allAttendances?.filter(
                  (item) =>
                    dayjs(item.date).format("YYYY-MM-DD") === dayjs(selectedCell?.day).format("YYYY-MM-DD") &&
                    selectedCell?.sub?.parent_activity_alias === item?.activity_alias && 
                    item?.child_alias === selectedCell?.child?.alias 
                )?.map((item) => item?.alias);

                targetAttendances = {
                  attendances: attendances,
                  children_alias: selectedCell?.child?.alias,
                  month: day.format("M"),
                };
              }
              dispatch(
                massDeleteAttendances(targetAttendances)
              );

              return;

            }else if (periodSelection === 1) {
              const dates = listOfWeeks
                ?.find((week) => week.includes(selectedDay?.date))
                ?.split("__")
              const attendancesAliases = dates.reduce((acc, cur) => {
                const att = allAttendances?.find(
                  (item) =>
                    item.date === cur &&
                    selectedDay?.activity_alias === item?.activity_alias &&
                    selectedDay?.child_alias === item?.child_alias
                )
                if (att) {
                  acc = [...acc, att.alias];
                }
                return acc;
              }, []);

              dispatch(
                massDeleteAttendances({
                  attendances: attendancesAliases,
                  month: day.format("M"),
                })
              );

              return;

            } else {
              dispatch(
                massDeleteAttendances({
                  attendances: [selectedDay.alias],
                  month: day.format("M"),
                })
              );

              return;
            }
          }
        },
        onCancel() {},
      });
    } else {
      console.log('heeeeeeeeeeeeeeeeeeere', {activityAllowCreditCreation, organisationAllowCreditCreation})
      if (selectedCell?.requestCredit) {
        if (!!activityAllowCreditCreation) {
          message.info({
            content: (
              <p>
                votre organisation <strong>{organisationName?.name}</strong> n'autorise pas l'annulation en ligne de réservations déjà payées pour cette activité.
              </p>
            ),
            icon: <InfoCircleOutlined style={{ color: "#FF5876" }} />,
          });
        } else if (!isBoolean(activityAllowCreditCreation) && !!organisationAllowCreditCreation) {
          message.info({
            content: (
              <p>
                votre organisation <strong>{organisationName?.name}</strong> n'autorise pas l'annulation en ligne de réservations déjà payées.
              </p>
            ),
            icon: <InfoCircleOutlined style={{ color: "#FF5876" }} />,
          });
        }
      } else {
        message.info({
          content: "Cette date n'est pas modifiable",
          icon: <InfoCircleOutlined style={{ color: "#FF5876" }} />,
        });
      }
    }
  } else if (day.isSameOrAfter(dayjs(activityDetails?.update_after))) {
    if (paymentInitiated) {
      message.error(
        "Vous ne pouvez pas effectuer cette réservation, vous avez une opération en cours de paiement sur cette organisation. Merci de la finaliser d`abord!"
      );

      return;
    }
    // Create attendance
    confirm({
      title: activityName,
      content: (
        <div>
          {(periodSelection >= 1)?(
            (periodSelection > 1 && periodSelectionActive === 0 )?(
              <p
              style={{
                fontSize: 16,
              }}
              >
                Souhaitez-vous réserver le {day.format("D")}/{day.format("MM")} pour{" "}
                {child.first_name}?
              </p>
            ):(
              <p
                style={{
                  fontSize: 16,
                }}
              >
                Souhaitez-vous réserver <span style={{fontWeight: 'bold'}}>{getConfirmPeriodReservationLabel(periodSelection)} incluant le {day.format("D")}/{day.format("MM")}</span> pour{" "}
                {child.first_name}?
              </p>
            )
             
          ):(
            <p
            style={{
              fontSize: 16,
            }}
            >
              Souhaitez-vous réserver le {day.format("D")}/{day.format("MM")} pour{" "}
              {child.first_name}?
            </p>
          )}         
          {isPrepaid && (
            <p
              style={{
                color: "#FF5876",
                fontSize: 12,
              }}
            >
              Cette activité est en prépaiement, vous devez donc régler votre
              commande dans le panier afin de valider vos réservations.
            </p>
          )}
        </div>
      ),
      onOk() {
        if (isPrepaid) {
          if (child?.first_name === "Tous") {
            const childsAliases =
              selectedCell?.sub?.childs &&
              Object.keys(selectedCell?.sub?.childs)?.filter(
                (ch) =>
                  !selectedCell?.sub?.childs?.[ch]?.find((att) =>
                    dayjs(att?.date).isSame(selectedCell?.day)
                  )?.is_listed
              );
              
            // console.log({childsAliases});
            if(periodSelection > 1){
              let containerData;
              if(periodSelectionActive != 0){
                containerData = {
                  children: childsAliases,
                  activity_alias: weekActivityAlias,
                  container_alias: selectedCell?.openDay?.find((d) => dayjs(d.date).format("YYYY-MM-DD") === dayjs(selectedCell?.day).format("YYYY-MM-DD"))?.container_alias,
                  from_now: false,
                  days: [],
                  replace_existing: true,
                };

                //if(container_alias){
                dispatch(
                  createAttendanceByContainer({
                    data: containerData,
                    childAlias:[childsAliases],
                    month: dayjs(day).format("M"),
                  })
                );
              } else if(periodSelectionActive === 0) {
                containerData = {
                  children: childsAliases,
                  activity_alias: weekActivityAlias,
                  container_alias: selectedCell?.openDay?.find((d) => dayjs(d.date).format("YYYY-MM-DD") === dayjs(selectedCell?.day).format("YYYY-MM-DD"))?.container_alias,
                  from_now: false,
                  days: [`on_${dayjs(day).format("dddd").toLocaleLowerCase()}`],
                  replace_existing: true,
                  dates: [day.format("YYYY-MM-DD"), day.format("YYYY-MM-DD")],
                };

                dispatch(
                  createRecurrentAttendance({
                    data: containerData,
                    month: dayjs(day).format("M"),
                  })
                );
                }

            }else if(periodSelection === 1){
              const dates = listOfWeeks
                ?.find((week) => week.includes(day.format("YYYY-MM-DD")))
                ?.split("__");
  
              const recurrencedata = {
                children: childsAliases,
                activity_alias: weekActivityAlias,
                from_now: false,
                days: [],
                replace_existing: true,
                dates,
              };
  
              if (dates) {
                dispatch(
                  createRecurrentAttendance({
                    data: recurrencedata,
                    month: dayjs(day).format("M"),
                  })
                );
              }
            } else {
              const recurrencedata = {
                children: childsAliases,
                activity_alias: weekActivityAlias,
                from_now: false,
                days: [`on_${dayjs(day).format("dddd").toLocaleLowerCase()}`],
                replace_existing: true,
                dates: [day.format("YYYY-MM-DD"), day.format("YYYY-MM-DD")],
              };

              dispatch(
                createRecurrentAttendance({
                  data: recurrencedata,
                  month: dayjs(day).format("M"),
                })
              );
            }
          } else {

            if(periodSelection > 1){

              let containerData;
              if(periodSelectionActive != 0){
                containerData = {
                  children: [childAlias],
                  activity_alias: weekActivityAlias,
                  container_alias: selectedCell?.openDay?.find((d) => dayjs(d.date).format("YYYY-MM-DD") === dayjs(selectedCell?.day).format("YYYY-MM-DD"))?.container_alias,
                  from_now: false,
                  days: [],
                  replace_existing: true,
                };
              
                dispatch(
                  createAttendanceByContainer({
                    data: containerData,
                    childAlias:[childAlias],
                    month: dayjs(day).format("M"),
                  })
                );
              }else if(periodSelectionActive === 0){
                containerData = {
                  children: [childAlias],
                  activity_alias: weekActivityAlias,
                  container_alias: selectedCell?.openDay?.find((d) => dayjs(d.date).format("YYYY-MM-DD") === dayjs(selectedCell?.day).format("YYYY-MM-DD"))?.container_alias,
                  from_now: false,
                  days: [`on_${dayjs(day).format("dddd").toLocaleLowerCase()}`],
                  replace_existing: true,
                  dates: [day.format("YYYY-MM-DD"), day.format("YYYY-MM-DD")],
                };

                dispatch(
                  createRecurrentAttendance({
                    data: containerData,
                    month: dayjs(day).format("M"),
                  })
                );
              }

            }else if(periodSelection === 1){
              const dates = listOfWeeks
                ?.find((week) => week.includes(day.format("YYYY-MM-DD")))
                ?.split("__");

              const recurrencedata = {
                children: [childAlias],
                activity_alias: weekActivityAlias,
                from_now: false,
                days: [],
                replace_existing: true,
                dates,
              };

              if (dates) {
                dispatch(
                  createRecurrentAttendance({
                    data: recurrencedata,
                    month: dayjs(day).format("M"),
                  })
                );
              }
            } else {
           
              const recurrencedata = {
                children: [childAlias],
                activity_alias: alias,
                from_now: false,
                days: [`on_${dayjs(day).format("dddd").toLocaleLowerCase()}`],
                replace_existing: true,
                dates: [day.format("YYYY-MM-DD"), day.format("YYYY-MM-DD")],
              };

              dispatch(
                createRecurrentAttendance({
                  data: recurrencedata,
                  month: dayjs(day).format("M"),
                })
              );
            }
          }
        } else {
          const childrenAliases =
            selectedCell?.sub?.childs && Object.keys(selectedCell?.sub?.childs);
          if (selectedCell?.child?.first_name === "Tous" && childrenAliases) {
            const dates = listOfWeeks
              ?.find((week) => week.includes(day.format("YYYY-MM-DD")))
              ?.split("__");

              if(periodSelection > 1){
                let containerData;
                if(periodSelectionActive != 0){
                  containerData = {
                    children: childrenAliases,
                    activity_alias: weekActivityAlias,
                    container_alias: selectedCell?.openDay?.find((d) => dayjs(d.date).format("YYYY-MM-DD") === dayjs(selectedCell?.day).format("YYYY-MM-DD"))?.container_alias,
                    from_now: false,
                    days: [],
                    replace_existing: true,
                  };

                  dispatch(
                    createAttendanceByContainer({
                      data: containerData,
                      childAlias:childrenAliases,
                      month: dayjs(day).format("M"),
                    })
                  );
                }else if(periodSelectionActive === 0){

                  containerData = {
                    children: childrenAliases,
                    activity_alias: weekActivityAlias,
                    container_alias: selectedCell?.openDay?.find((d) => dayjs(d.date).format("YYYY-MM-DD") === dayjs(selectedCell?.day).format("YYYY-MM-DD"))?.container_alias,
                    from_now: false,
                    days: [`on_${dayjs(day).format("dddd").toLocaleLowerCase()}`],
                    replace_existing: true,
                    dates: [day.format("YYYY-MM-DD"), day.format("YYYY-MM-DD")],
                  };

                  dispatch(
                    createRecurrentAttendance({
                      data: containerData,
                      month: dayjs(day).format("M"),
                    })
                  );

                }       
  
              }else if(periodSelection === 1){
                const recurrencedata = {
                  children: childrenAliases,
                  activity_alias: weekActivityAlias,
                  from_now: false,
                  days: [],
                  replace_existing: true,
                  dates,
                };

              dispatch(
                createRecurrentAttendance({
                  data: recurrencedata,
                  month: dayjs(day).format("M"),
                })
              );
            } else {
              const recurrencedata = {
                children: childrenAliases,
                activity_alias: weekActivityAlias,
                from_now: false,
                days: [`on_${dayjs(day).format("dddd").toLocaleLowerCase()}`],
                replace_existing: true,
                dates: [day.format("YYYY-MM-DD"), day.format("YYYY-MM-DD")],
              };

              dispatch(
                createRecurrentAttendance({
                  data: recurrencedata,
                  month: dayjs(day).format("M"),
                })
              );
            }
          } else {
            if(periodSelection > 1){
              let containerData;
              if(periodSelectionActive != 0){
                containerData = {
                  children: [childAlias],
                  activity_alias: weekActivityAlias,
                  container_alias: selectedCell?.openDay?.find((d) => dayjs(d.date).format("YYYY-MM-DD") === dayjs(selectedCell?.day).format("YYYY-MM-DD"))?.container_alias,
                  from_now: false,
                  days: [],
                  replace_existing: true,
                };

                dispatch(
                  createAttendanceByContainer({
                    data: containerData,
                    childAlias:[childAlias],
                    month: dayjs(day).format("M"),
                  })
                );
              } else if(periodSelectionActive === 0 ){
                  containerData = {
                    children: [childAlias],
                    activity_alias: weekActivityAlias,
                    container_alias: selectedCell?.openDay?.find((d) => dayjs(d.date).format("YYYY-MM-DD") === dayjs(selectedCell?.day).format("YYYY-MM-DD"))?.container_alias,
                    from_now: false,
                    days: [`on_${dayjs(day).format("dddd").toLocaleLowerCase()}`],
                    replace_existing: true,
                    dates: [day.format("YYYY-MM-DD"), day.format("YYYY-MM-DD")],
                  };

                  dispatch(
                    createRecurrentAttendance({
                      data: containerData,
                      month: dayjs(day).format("M"),
                    })
                  );
              }    

            }else if(periodSelection === 1){
              const dates = listOfWeeks
                ?.find((week) => week.includes(day.format("YYYY-MM-DD")))
                ?.split("__");

                console.log({dates}, '//////');
              const recurrencedata = {
                children: [childAlias],
                activity_alias: weekActivityAlias,
                from_now: false,
                days: [],
                replace_existing: true,
                dates,
              };

              dispatch(
                createRecurrentAttendance({
                  data: recurrencedata,
                  month: dayjs(day).format("M"),
                })
              );
            } else {
              const recurrencedata = {
                children: [childAlias],
                activity_alias: alias,
                from_now: false,
                days: [`on_${dayjs(day).format("dddd").toLocaleLowerCase()}`],
                replace_existing: true,
                dates: [day.format("YYYY-MM-DD"), day.format("YYYY-MM-DD")],
              };

              dispatch(
                createRecurrentAttendance({
                  data: recurrencedata,
                  month: dayjs(day).format("M"),
                })
              );
            
            }
          }
        }
      },
      onCancel() {},
    });
  }
};

export default manageSelectedCell;
