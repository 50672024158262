import { combineReducers } from 'redux'
import user from '~/containers/User/store'
import home from '~/containers/Home/store'
import children from '~/containers/Children/store'
import bills from '~/containers/Bills/store'
import registration from '~/containers/Registration/store'
import subscription from '~/containers/Subscription/store'
import household from "~/containers/Foyer/store"
import contacts from '~/containers/Foyer/Contacts/store'
import sepa from '~/containers/User/UserProfile/storeSepa'
import orgs from '~/containers/User/UserProfile/storeOrgs'
//~ import {sepa, orgs} from '~/containers/User/UserProfile/store'
import cart from "~/containers/Cart/store"

export default combineReducers({
  user,
  home,
  children,
  bills,
  registration,
  subscription,
  household,
  contacts,
  sepa,
  orgs,
  cart,
})
