import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Form,
  Row,
  Col,
  Input,
  Checkbox,
  Select,
  DatePicker,
} from 'antd'
import dayjs from 'dayjs'
import locale from 'antd/lib/date-picker/locale/fr_FR'
import { useTranslation } from 'react-i18next'

import { registrationUpdating } from '~/containers/Registration/actions'
import RequiredInformation from '~/utils/requiredInformation'
import Image from '~/components/common/Image'
import PictoSVG from '~/assets/images/picto.svg'
import Autocomplete from 'react-google-autocomplete'

import {
  StepButtonContainer,
  StepButton,
  Preview,
} from './index'

export const StepButtonContainerCustom = styled(StepButtonContainer)`
  margin-bottom: -50px;
`

const NotRequiredInfo = ['child.allergy']

const getValueFromGMAPComponents = (components, type) => {
  let value
  components.forEach(comp => {
    if (comp.types.includes(type)) {
      value = comp.long_name
    }
  })
  return value
}

const MissingInformation = ({
  information,
  goToStep,
  childAlias,
  registrationAlias,
}) => {
  const dispatch = useDispatch()
  const { isUpdating } = useSelector(state => state.registration)
  const [form] = Form.useForm()
  const { t } = useTranslation('registration')
  const [photos, setPhotos] = useState({})
  const [addressObj, setAddressObj] = useState({})
  
  const handleAddressChange = useCallback(
    data => {
    // Reformat address object after updating
      const dataAddress = {
        formatted_address: data.formatted_address,
        street_number: getValueFromGMAPComponents(
          data.address_components,
          'street_number',
        ),
        route: getValueFromGMAPComponents(data.address_components, 'route'),
        postal_code: getValueFromGMAPComponents(
          data.address_components,
          'postal_code',
        ),
        locality: getValueFromGMAPComponents(
          data.address_components,
          'locality',
        ),
        country: getValueFromGMAPComponents(
          data.address_components,
          'country',
        ),
        lat: data.geometry.location.lat,
        lng: data.geometry.location.lat,
      }
      setAddressObj(dataAddress)
      form.setFieldsValue({
        'user.profile.address': data.formatted_address,
      })
    },
    [form],
  )

  const onSubmit = (values) => {
    const formData = new FormData()
    Object.keys(values).forEach(key => {
      const {
        type,
        isMultiple,
      } = RequiredInformation[key]
      switch (type) {
        case 'date':
          formData.append(key, dayjs(values[key] || undefined).format('YYYY-MM-DD'))
          break
        case 'file.image':
          formData.append(key, photos[key])
          break
        case 'checkbox':
          formData.append(key, values[key] || false)
          break
        case 'address':
          formData.append(key, JSON.stringify(addressObj))
          
          break
        case 'select':
          if (values[key]) {
            formData.append(key, values[key]
              && (isMultiple ? JSON.stringify(values[key]) : values[key]))
          }
          break
        default:
          formData.append(key, values[key])
          break
      }
    })
    // 
    for(var pair of formData.entries()) {
      
   }

    dispatch(registrationUpdating(
      childAlias,
      registrationAlias,
      formData,
      () => goToStep('next'),
    ))
  }

  const onChangePhoto = (event, name) => {
    const file = event.target.files[0]
    if (!file) return
    setPhotos({ ...photos, [name]: file })
  }
  const renderFormElement = (info) => {
    const {
      type,
      label,
      values,
      isObject,
      valueKey,
      isMultiple,
    } = RequiredInformation[info]

    let element = null
    
    switch (type) {
      case 'text':
        element = <Input placeholder={RequiredInformation[info].label} />
        break
      case 'address':
        element = <Autocomplete
        types={['address']}
        className="ant-input"
        onPlaceSelected={handleAddressChange} />
        break
      case 'select':
        element = (
          <Select
            placeholder={label}
            mode={isMultiple && 'multiple'}
          >
            {isObject && values.map(item => (
              <Select.Option
                key={item[valueKey]}
                value={item[valueKey]}
              >{item.label}
              </Select.Option>
            ))}

            {!isObject && values.map(item => (
              <Select.Option key={item} value={item}>{item}</Select.Option>
            ))}
          </Select>
        )
        break
      case 'date':
        element = (
          <DatePicker
            placeholder={RequiredInformation[info].label}
            mode="date"
            style={{ width: '100%' }}
            locale={locale}
          />
        )
        break
      case 'checkbox':
        element = <Checkbox>{RequiredInformation[info].label}</Checkbox>
        break
      case 'file.image':
        element = (
          <label htmlFor={`pic_${info}`}>
            <Preview>
              <Image src={photos[info] ? URL.createObjectURL(photos[info]) : PictoSVG} alt="" />
              <input type="file" id={`pic_${info}`} name={`pic_${info}`} onChange={(e) => onChangePhoto(e, info)} />
            </Preview>
          </label>
        )
        break
      default:
        element = null
        break
    }
    return element
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSubmit}
    >
      <Row>
        <Col span={8} offset={8}>
          {information.map(info => (
            <Form.Item
              label={RequiredInformation[info].type !== 'checkbox' && RequiredInformation[info].label}
              name={info}
              key={`elm_${info}`}
              rules={RequiredInformation[info].type !== 'checkbox'
              && !NotRequiredInfo.includes(info)
              && [{ required: true, message: 'Ce champ est obligatoire' }]}
              valuePropName={RequiredInformation[info].type === 'checkbox' ? 'checked' : 'value'}
            >
              {renderFormElement(info)}
            </Form.Item>

          ))}
        </Col>
      </Row>
      <Form.Item>
        <StepButtonContainerCustom>
          <StepButton
            disabled
            onClick={() => goToStep('back')}
          >
            {t('prev-step')}
          </StepButton>
          <StepButton
            type="primary"
            htmlType="submit"
            loading={isUpdating}
          >
            {t('continue-cta')}
          </StepButton>
        </StepButtonContainerCustom>
      </Form.Item>
    </Form>
  )
}

MissingInformation.propTypes = {
  childAlias: PropTypes.string.isRequired,
  registrationAlias: PropTypes.string.isRequired,
  information: PropTypes.arrayOf(PropTypes.string).isRequired,
  goToStep: PropTypes.func.isRequired,
}

export default MissingInformation
