import React, {
  useState,
  useCallback,
  useMemo,
  memo,
} from 'react'

import {
  StatusSelect,
} from './components'

import {
  childStatus,
} from '~/constants/index'

const StatusSelectComponent = ({
  defaultValue,
  onStatusChange,
  ...props
}) => {
  const [currentStatus, setCurrentStatus] = useState(defaultValue)
  const onChange = useCallback(
    value => {
      setCurrentStatus(value)
      onStatusChange(value)
    },
    [setCurrentStatus, onStatusChange],
  )

  const color = useMemo(
    () => childStatus.find(s => s.value === currentStatus) ?.color,
    [currentStatus],
  )

  return (
    <StatusSelect
      color={color}
      defaultValue={defaultValue}
      dropdownMatchSelectWidth={false}
      onChange={onChange}
      options={childStatus}
      {...props}
    />
  )
}

export default memo(StatusSelectComponent)
