import React from "react";
import { Modal } from "antd";
import axios from "axios";
import Cookies from "universal-cookie/es6";
import appConfig from "~/config";
import authToken from "~/utils/Auth";

// console.log({ feef: process.env.REACT_APP_ENV });

const apiUrl = () => {
  const storedApiUrl = window.localStorage.getItem("api");
  const listOfApiUrls = appConfig.api.devUrls;

  if (!appConfig.app.env || appConfig.app.env === "prod") {
    return appConfig.api.url;
  } else if (storedApiUrl) {
    return storedApiUrl;
  } else if (listOfApiUrls?.[0]) {
    return listOfApiUrls?.[0];
  } else {
    return appConfig.api.url;
  }
};

console.log({ ccccc: apiUrl() });

const mainAxios = axios.create({
  baseURL: (axios.defaults.baseURL = apiUrl()),
});

// Add a response interceptor
mainAxios.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  (response) => response,
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  (error) => Promise.reject(error)
);

mainAxios.interceptors.request.use(
  // Do something before request is sent
  async (config) => {
    const token = authToken.getToken();

    console.log({ token });

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: token && `Bearer ${token}`,
      },
    };
  },
  // Do something with request error
  (error) => Promise.reject(error)
);

export const backofficeAxios = axios.create({
  baseURL: (axios.defaults.baseURL = appConfig.api.backoffice_url),
});

// Add a response interceptor
backofficeAxios.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  (response) => response,
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  (error) => Promise.reject(error)
);

backofficeAxios.interceptors.request.use(
  // Do something before request is sent
  (config) => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: authToken.getToken() && `Bearer ${authToken.getToken()}`,
    },
  }),
  // Do something with request error
  (error) => Promise.reject(error)
);

export default mainAxios;
