
import {
  handleActions,
} from '~/utils/redux-actions'

import {
  registrationDetailsFetching,
  registrationDetailsFetched,
  registrationDetailsFetchError,

  childRegistrationFetching,
  childRegistrationFetched,
  childRegistrationFetchError,

  registrationToOrganisationFetching,
  registrationToOrganisationFetched,
  registrationToOrganisationFetchError,

  registrationCreating,
  registrationCreated,
  registrationCreateError,

  registrationUpdating,
  registrationUpdated,
  registrationUpdateError,

  commentSubmitting,
  commentSubmitted,
  commentSubmitError,

  registrationSubmitting,
  registrationSubmitted,
  registrationSubmitError,

  organisationsByZipCodeFetching,
  organisationsByZipCodeFetched,
  organisationsByZipCodeFetchError,

  clearOrganisationsByZipCode,

  documentsTypesFetching,
  documentsTypesFetched,
  documentsTypesFetchError,

  orgDocumentsFetching,
  orgDocumentsFetched,
  orgDocumentsFetchError,

  linkDocumentToRegistration,
  linkDocumentToRegistrationSuccess,
  linkDocumentToRegistrationError,

  fetchRegistrationsDetails,
  fetchRegistrationsDetailsSuccess,
  fetchRegistrationsDetailsError,

  fetchAllRegistrations,
  fetchAllRegistrationsSuccess,
  fetchAllRegistrationsError,
} from './actions'

import { logout } from '~/containers/User/actions'

const defaultState = {
  registrationDetails: null,
  registrationsDetails: null,
  isFetchingDetails: false,
  list: [],
  loading: false,
  error: false,
  isUpdating: false,
  errorUpdating: false,

  allRegistrations: null,
  isFetchingAllRegistrations: false,

  comment: {
    comments: [],
    loading: false,
    error: false,
  },

  organisation: {
    organisations: [],
    loading: false,
    error: false,
  },

  child: {
    alias: null,
    loading: false,
    error: false,
  },

  documentsTypes: {
    data: null,
    loading: false,
    error: false,
  },

  orgDocuments: {
    data: null,
    loading: false,
    error: false,
  },
  
  linkingDocumentToRegistration: false,
}

export default handleActions(
  {
    // Logout
    [logout]: () => defaultState,

    // Fetch registration details
    [registrationDetailsFetching]: state => {
      state.registrationDetails = null
      state.isFetchingDetails = true
      state.error = false
    },
    [registrationDetailsFetched]: (state, { payload }) => {
      state.registrationDetails = payload
      state.isFetchingDetails = false
      state.error = false
    },
    [registrationDetailsFetchError]: state => {
      state.registrationDetails = null
      state.isFetchingDetails = false
      state.error = true
    },

    // Fetch child registrations
    [childRegistrationFetching]: state => {
      state.loading = true
      state.error = false
    },
    [childRegistrationFetched]: (state, { payload }) => {
      state.list = payload
      state.loading = false
      state.error = false
    },
    [childRegistrationFetchError]: state => {
      state.list = []
      state.loading = false
      state.error = true
    },

    // Fetch registration details into an organisation
    [registrationToOrganisationFetching]: state => {
      state.loading = true
      state.error = false
    },
    [registrationToOrganisationFetched]: (state, { payload }) => {
      state.registrationDetails = payload
      state.loading = false
      state.error = false
    },
    [registrationToOrganisationFetchError]: state => {
      state.registrationDetails = {}
      state.loading = false
      state.error = true
    },

    // Creating registration
    [registrationCreating]: state => {
      state.loading = true
      state.error = false
    },
    [registrationCreated]: state => {
      state.loading = false
      state.error = false
    },
    [registrationCreateError]: state => {
      state.loading = false
      state.error = true
    },

    // Updating registration
    [registrationUpdating]: state => {
      state.isUpdating = true
      state.errorUpdating = false
    },
    [registrationUpdated]: (state, { payload }) => {
      state.registrationDetails = payload
      state.isUpdating = false
      state.errorUpdating = false
    },
    [registrationUpdateError]: state => {
      state.isUpdating = false
      state.errorUpdating = true
    },

    // Submitting registration
    [registrationSubmitting]: state => {
      state.loading = true
      state.error = false
    },
    [registrationSubmitted]: state => {
      state.loading = false
      state.error = false
    },
    [registrationSubmitError]: state => {
      state.loading = false
      state.error = true
    },

    // Submitting comment
    [commentSubmitting]: state => {
      state.comment.loading = true
      state.comment.error = false
    },
    [commentSubmitted]: state => {
      state.comment.loading = false
      state.comment.error = false
    },
    [commentSubmitError]: state => {
      state.comment.loading = false
      state.comment.error = true
    },

    // Fetch organisations by zip code
    [organisationsByZipCodeFetching]: state => {
      state.organisation.organisations = []
      state.organisation.loading = true
      state.organisation.error = false
    },
    [organisationsByZipCodeFetched]: (state, { payload }) => {
      
      state.organisation.organisations = [...payload]
      state.organisation.loading = false
      state.organisation.error = false
    },
    [organisationsByZipCodeFetchError]: state => {
      state.organisation.organisations = []
      state.organisation.loading = false
      state.organisation.error = true
    },

    // Clearing search list
    [clearOrganisationsByZipCode]: state => {
      state.organisation.organisations = []
    },

    [documentsTypesFetching]: state => {
      state.documentsTypes.data = []
      state.documentsTypes.loading = true
      state.documentsTypes.error = false
    },
    [documentsTypesFetched]: (state, { payload }) => {
      
      state.documentsTypes.data = [...payload]
      state.documentsTypes.loading = false
      state.documentsTypes.error = false
    },
    [documentsTypesFetchError]: state => {
      state.documentsTypes.data = []
      state.documentsTypes.loading = false
      state.documentsTypes.error = true
    },

    [orgDocumentsFetching]: state => {
      state.orgDocuments.data = []
      state.orgDocuments.loading = true
      state.orgDocuments.error = false
    },
    [orgDocumentsFetched]: (state, { payload }) => {
      
      state.orgDocuments.data = [...payload]
      state.orgDocuments.loading = false
      state.orgDocuments.error = false
    },
    [orgDocumentsFetchError]: state => {
      state.orgDocuments.data = []
      state.orgDocuments.loading = false
      state.orgDocuments.error = true
    },
    [linkDocumentToRegistration]: state => {
      state.linkingDocumentToRegistration = true
    },
    [linkDocumentToRegistrationSuccess]: state => {
      state.linkingDocumentToRegistration = false
    },
    [linkDocumentToRegistrationError]: state => {
      state.linkingDocumentToRegistration = false
    },
    [fetchRegistrationsDetails]: state => {
      state.registrationsDetails = null
      state.isFetchingDetails = true
      state.error = false
    },
    [fetchRegistrationsDetailsSuccess]: (state, { payload }) => {
      state.registrationsDetails = payload
      state.isFetchingDetails = false
      state.error = false
    },
    [fetchRegistrationsDetailsError]: state => {
      state.registrationsDetails = null
      state.isFetchingDetails = false
      state.error = true
    },
    [fetchAllRegistrations]: state => {
      state.allRegistrations = null
      state.isFetchingAllRegistrations = true
      state.error = false
    },
    [fetchAllRegistrationsSuccess]: (state, { payload }) => {
      state.allRegistrations = payload
      state.isFetchingAllRegistrations = false
      state.error = false
    },
    [fetchAllRegistrationsError]: state => {
      state.allRegistrations = null
      state.isFetchingAllRegistrations = false
      state.error = true
    },
  },
  defaultState,
)
