import styled from 'styled-components'

export const ContainerPaiements = styled.span`
  display: flex;
  align-items: center;
`

export const CirclePaiements = styled.span`
  display: inline-block;
  margin: 0 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
`

export const StatusCircles = styled.span`
  margin-top: -1.5rem; 
  color: #646464;
`

