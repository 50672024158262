import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import authToken from '~/utils/Auth'
import { fetchUserProfile } from '~/containers/User/actions'
import { Spin } from 'antd'
import { Container } from '~/components/common/Container'

const PrivateRoute = ({ children, ...rest }) => {
  const { isLogged, isLogin } = useSelector(state => state.user.auth)
  const token = authToken.getToken()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUserProfile())
  }, [token])

  if (isLogin) {
    return (
      <Container>
        <Spin delay={200} size="large" tip="Chargement" />
      </Container>
    );
  }

  return token || isLogged ? (
    children
  ) : (
    <Navigate
      replace
      to={{
        pathname: "/login",
        // state: { from: location },
      }}
    />
  );
}

export default PrivateRoute
