import styled from 'styled-components'
import {
  centeredCSS,
  boldCSS,
  inlineCSS,
  uppercaseCSS,
} from '~/components/common/Typo'

const H2 = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: @black-blue-color;

  ${props => props.centered && centeredCSS};
  ${props => props.bold && boldCSS};
  ${props => props.inline && inlineCSS};
  ${props => props.uppercase && uppercaseCSS};
`

export default H2
