import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  overflow: hidden;
  width: ${(props) => props.width};
`

const Header = styled.div`
  background-color:@accent-color;
  font-weight:bold;
  color:white;
  border-radius: 8px 8px 0 0;
  height:53px;
  text-align:center;
  padding: 15px;
  border-bottom: 1px solid @almost-white-background;
`
const Content = styled.div`
  padding: 20px 15px;
`
const Footer = styled.div`
  padding: 15px;
  text-align:center;
  border-radius:  0 0 8px 8px;
  border-top: 1px solid @almost-white-background;
  div > * {
    margin: 0 5px;
  }
`

const Component = ({
  children,
  header,
  footer,
  ...props
}) => (
  <Card {...props}>
    {header ? <Header>{header}</Header> : null}
    <Content>{children}</Content>
    {footer ? <Footer>{footer}</Footer> : null}
  </Card>
)

Component.propTypes = {
  width: PropTypes.string,
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

Component.defaultProps = {
  width: 'auto',
  header: null,
  footer: null,
  children: null,
}

export default Component
