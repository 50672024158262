import React from 'react'
import PropTypes from 'prop-types'

import { StyledDate } from './components'

const CalendarCell = ({
  date,
  markedDates,
}) => {
  const day = date.format('YYYY-MM-DD')
  if (markedDates[day]) {
    return (
      <StyledDate
        color={markedDates[day].selectedColor}
      >
        {date.format('D')}
      </StyledDate>
    )
  }

  return (
    <StyledDate
      color="white"
      textColor="grey"
    >
      {date.format('D')}
    </StyledDate>
  )
}

CalendarCell.propTypes = {
  date: PropTypes.shape().isRequired,
  markedDates: PropTypes.shape().isRequired,
}
export default CalendarCell
