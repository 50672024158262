import { createActions } from 'redux-actions'

export const {
  feedFetching,
  feedFetched,
  feedFetchError,

  postDetailsFetching,
  postDetailsFetched,
  postDetailsFetchError,

  userOrganisationsFetching,
  userOrganisationsFetched,
  userOrganisationsFetchError,

  userBillsFetching,
  userBillsFetched,
  userBillsFetchError,
} = createActions({
  FEED_FETCHING: (page, search, page_size) => ({ page, search, page_size }),
  FEED_FETCHED: feed => feed,
  FEED_FETCH_ERROR: () => ({}),

  POST_DETAILS_FETCHING: (organisationAlias, postAlias) => ({ organisationAlias, postAlias }),
  POST_DETAILS_FETCHED: organisation => organisation,
  POST_DETAILS_FETCH_ERROR: () => ({}),

  USER_ORGANISATIONS_FETCHING: () => ({}),
  USER_ORGANISATIONS_FETCHED: organisation => organisation,
  USER_ORGANISATIONS_FETCH_ERROR: () => ({}),

  USER_BILLS_FETCHING: () => ({}),
  USER_BILLS_FETCHED: bills => bills,
  USER_BILLS_FETCH_ERROR: () => ({}),
})
