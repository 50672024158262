import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Tag, Modal } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import Loading from '~/components/common/Loading'
import {
  CardModal,
  ViewModal,
  PreviewModal,
  CTA,
} from './components'

import {
  downloadDocument,
  deleteDocument,
  fetchDocument,
} from '~/containers/Children/actions'
import PdfPreview from '~/components/common/PdfPreview'

const UserDocument = ({ reg, doc }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { t } = useTranslation('children')
  const { documentUrl } = useSelector(state => state.children.childDetails)
  const [visible, setVisible] = useState(false)

  const isImage = () => {
    const docExtn = doc.url.split('.')
    const picExtensions = ['jpg', 'jpeg', 'png', 'bmp', 'gif']
    return picExtensions.includes(docExtn[docExtn.length - 1])
  }

  const refreshPage = () => {
    window.location.reload(pathname)
  }

  const handleFileDownload = useCallback(
    async () => {
      dispatch(downloadDocument(doc))
    },
    [],
  )

  const onDelete = useCallback(
    async () => {
      dispatch(deleteDocument(doc.alias))
    },
    [],
  )

  useEffect(() => {
    if (visible) dispatch(fetchDocument(doc.url))
  }, [visible])

  const showModal = () => {
    setVisible(true)
  }

  function confirm() {
    Modal.confirm({
      title: t('child.delete-document.validate'),
      content: <p>{t('child.delete-document.warning')} {doc.label}</p>,
      okText: t('child.delete-document.validate-cta'),
      cancelText: t('child.delete-document.cancel-cta'),
      okButtonProps: { type: 'danger' },
      onOk() {
        onDelete()
        refreshPage()
      },
      onCancel() {
      },
    })
  }

  return (
    <>
      <Tag
        onClick={() => showModal()}
      >
        {doc.label}
      </Tag>
      {
        reg.status === 1
          ? (
            <>
              <ViewModal
                closable
                title={doc.label}
                open={visible}
                onOk={confirm}
                onCancel={() => setVisible(false)}
                okButtonProps={
                  {
                    disabled: true,
                    type: 'danger',
                  }
                }
                cancelText={t('child.delete-document.close')}
                okText={t('child.delete-document.delete')}
              >
                {
                  documentUrl === null ? (
                    <Loading />
                  ) : (
                    <>
                      <CardModal>
                        {
                          isImage(doc) ? (
                            <PreviewModal
                              height="250px"
                              width="300px"
                              src={documentUrl}
                            />
                          )
                            : (
                              <PdfPreview
                                file={documentUrl}
                              />
                            )
                        }
                      </CardModal>
                      <CTA
                        type="primary"
                        onClick={() => handleFileDownload()}
                        icon={<DownloadOutlined />}
                      >
                        {t('child.delete-document.download')}
                      </CTA>
                    </>
                  )
                }
              </ViewModal>
            </>
          ) : (
            <>
              <ViewModal
                closable
                title={doc.label}
                open={visible}
                onOk={confirm}
                onCancel={() => setVisible(false)}
                okButtonProps={
                  {
                    type: 'danger',
                  }
                }
                cancelText={t('child.delete-document.close')}
                okText={t('child.delete-document.delete')}
              >
                {
                  documentUrl === null ? (
                    <Loading />
                  ) : (
                    <>
                      <CardModal>
                        {
                            isImage(doc) ? (
                              <PreviewModal
                                height="250px"
                                width="300px"
                                src={documentUrl}
                              />
                            )
                              : (<PdfPreview file={documentUrl} />)
                          }
                      </CardModal>
                      <CTA
                        type="primary"
                        onClick={() => handleFileDownload()}
                        icon={<DownloadOutlined />}
                      >
                        {t('child.delete-document.download')}
                      </CTA>
                    </>
                  )
                }
              </ViewModal>
            </>
          )
      }
    </>
  )
}

UserDocument.propTypes = {
  doc: PropTypes.shape().isRequired,
  reg: PropTypes.shape().isRequired,
}

export default UserDocument

