export const getJWT = state => state.user.auth.jwt
export const getResetToken = state => state.user.auth.resetToken

// Tokens
export const getAccessToken = state => state.user.auth.accessToken
export const getRefreshToken = state => state.user.auth.refreshToken

// roles
export const getUserRoles = state => state.user.auth.roles

// user
export const getAuthUserInfos = state => state.user.auth.user

export const getUserDocuments = state => state.user.userInfo.documents

export const getUserData = state => state.user.userInfo.user
