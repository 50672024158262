import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const LinkTemplate = ({
  children,
  to,
  ...props
}) => (
  <Link to={to} {...props}>
    {children}
  </Link>
)

LinkTemplate.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
LinkTemplate.defaultProps = {
  children: null,
}

const ContainerLinkWrapper = styled.div`
  cursor: pointer;

  &:focus {
    outline:0;
  }
`

export const ContainerLink = ({
  role,
  tabIndex,
  children,
  action,
  ...rest
}) => (
  <ContainerLinkWrapper
    role={role}
    tabIndex={tabIndex}
    onClick={() => {
      if (action) {
        action()
      }
    }}
    onKeyPress={e => {
      if (e.key === 'Enter' && action) {
        action()
      }
    }}
    {...rest}
  >
    {children}
  </ContainerLinkWrapper>
)

ContainerLink.propTypes = {
  role: PropTypes.string,
  tabIndex: PropTypes.string,
  action: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
}
ContainerLink.defaultProps = {
  role: 'link',
  tabIndex: '0',
}

const LinkComponent = styled(LinkTemplate)`
`

export default LinkComponent
