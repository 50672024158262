import styled from 'styled-components'
import {
  centeredCSS,
  boldCSS,
  inlineCSS,
  uppercaseCSS,
} from '~/components/common/Typo'

const H3 = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: #0f2d78;

  ${props => props.centered && centeredCSS};
  ${props => props.bold && boldCSS};
  ${props => props.inline && inlineCSS};
  ${props => props.uppercase && uppercaseCSS};
`

export default H3
