import styled, { css } from 'styled-components'

const noBorderCSS = css`
  border: none;
`

const StyledBullet = styled.div`
  display: inline-block;
  width: ${props => props.size || '22px'};
  height: ${props => props.size || '22px'};
  border-radius: 50%;
  background: ${props => props.color};
  border: 5px solid  ${props => props.borderColor};
  ${props => props.noBorder && noBorderCSS}
`

export default StyledBullet
