import React from 'react'
import styled from 'styled-components'

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons'

import {
  Layout,
  Menu,
  Button,
} from 'antd'

const { Sider } = Layout

export const Container = styled(Sider)`
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 10;
  overflow: auto;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: 40px;
  background: #0f2d78 !important;

  .ant-layout-sider-trigger {
    overflow: hidden;
    background: #0f2d78;
    width: 195px;
  }
`

export const StyledMenu = styled(Menu)`
background-color: #0f2d78;
border-right: 0px transparent solid;
.ant-menu-vertical-left {
  border-right: 0px transparent solid;
}
.ant-menu {
  background-color: transparent;
}
.ant-menu-item, .ant-menu-item a {
  color:white; 
  background-color: #0f2d78;
  max-width: 100%;
}
.ant-menu-submenu-arrow {
  color:white;
  background-color: transparent;
}
color:white;
  &:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;

    &:after {
      content: none;
    }
  }
`

export const CloseTrigger = () => (
  <Button
    block
    icon={<MenuFoldOutlined />}
    type="link"
    style={{ color: 'white', backgroundColor: 'transparent', width: 150 }}
  >
    Fermer le menu
  </Button>
)

export const OpenTrigger = () => (
  <Button
    block
    icon={<MenuUnfoldOutlined />}
    type="link"
    style={{ color: 'white', backgroundColor: '#0F2D78', width: 70 }}
  />
)
