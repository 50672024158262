import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'antd'

import { useTranslation } from 'react-i18next'
import { getActivityStatus, getActivityStatusColor } from '~/utils/activity'

import SchoolSVG from '~/assets/images/school.svg'

import P from '~/components/common/P'
import Dot from '~/components/common/Dot'
import ArrowUp from '~/assets/images/arrow-right.svg'

import {
  RegistrationContainer,
} from '../index'

import {
  RegistrationStatusContainer,
  RegistrationInfo,
  RegistrationCard,
  ArrowIcon,
  RegistrationHeader,
} from './components'

const CollapsedChildRegistration = ({ registered: reg, onActiveChange }) => {
  const { t } = useTranslation('children')
  return (
    <RegistrationContainer>
      <RegistrationCard>
        <RegistrationHeader onClick={() => onActiveChange(reg.alias)}>
          <RegistrationInfo>
            <div className="text-container">
              <Avatar size={60} src={SchoolSVG} />
              <div className={((reg.status !== 1 && reg.missing_documents.length > 0) || reg.status === 4) ? 'school-info reg-with-link' : 'school-info'}>
                <P className="school-name">{t('child.school-name')}</P>
                <RegistrationStatusContainer>
                  <Dot
                    color={getActivityStatusColor(reg.status,
                      reg.renewal_status)}
                  />&nbsp;
                  {getActivityStatus(reg.status,
                    reg.renewal_status, reg.missing_documents.length)}
                </RegistrationStatusContainer>
              </div>
            </div>
          </RegistrationInfo>
          <ArrowIcon src={ArrowUp} alt="" />
        </RegistrationHeader>
      </RegistrationCard>
    </RegistrationContainer>
  )
}

CollapsedChildRegistration.propTypes = {
  registered: PropTypes.shape().isRequired,
  onActiveChange: PropTypes.func.isRequired,
}
export default CollapsedChildRegistration
