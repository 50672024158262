import React from 'react'
import {
  Layout as AntLayout,
} from 'antd'

import CommonLayout from '../../common/Layout'
import Navigation from '../Navigation'

import {
  ContentContainer,
} from './components'

const Layout = ({ children }) => (
  <CommonLayout>
    <AntLayout>
      <Navigation />
      <ContentContainer>
        {children}
      </ContentContainer>
    </AntLayout>
  </CommonLayout>
)

export default Layout
