import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1120px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
`

const ContainerWithAsset = styled.div`
  display: flex;
  align-items: center;
  min-height: 660px;
  padding-left: 50%;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background: url(${(props) => props.asset}) no-repeat 30% 50%;
    background-size: cover;
  }
`

const Content = styled.div`
  width: 100%;
  padding: 14%;

  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const DoubleContainerComponent = ({ children, asset, ...props }) => (
  <Container {...props}>
    <ContainerWithAsset
      asset={asset}
    >
      <Content>
        {children}
      </Content>
    </ContainerWithAsset>
  </Container>
)

// DoubleContainerComponent.propTypes = {
//   children: PropTypes.any,
//   asset: PropTypes.any,
// }

DoubleContainerComponent.propTypes = {
  children: PropTypes.element,
  asset: PropTypes.element,
  width: PropTypes.number,
}

DoubleContainerComponent.defaultProps = {
  children: PropTypes.element,
  asset: PropTypes.element,
  width: PropTypes.number,
}

export default DoubleContainerComponent
