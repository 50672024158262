import styled, { 
  css
} from 'styled-components'

const sizes = ({
  xxl: css`
    font-size: @font-size-base + 6px;
  `,
  big: css`
    font-size: @font-size-base + 2px;
  `,
  regular: css`
    font-size: @font-size-base;
  `,
  small: css`
    font-size: @font-size-base - 2px;
  `
})

const colors = ({
  0: css`
    color: @neutral-0;
  `,
  1: css`
    color: @neutral-1;
  `,
  2: css`
    color: #e2e4e6;
  `,
  3: css`
    color: #969696;
  `,
  4: css`
    color: @neutral-4;
  `,
  5: css`
    color: @neutral-5;
  `,
})

const textTransformCSS = css`
  text-transform: ${props => props.transform};
`
const fontWeightCSS = css`
  font-weight: ${props => props.weight};
`

const displayCSS = css`
  display: ${props => props.display};
`

const Text = styled.span`
  ${props => sizes[props.size ?? 'regular']};
  ${props => props.transform ? textTransformCSS : ''};
  ${props => props.weight ? fontWeightCSS : ''};
  ${props => colors[props.neutral ?? '4']};
  ${props => props.display ? displayCSS : ''};
`

export default Text
