import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import {
  Layout as AntLayout,
} from 'antd'
import global from './styles/global'
import fonts from './styles/fonts'

const GlobalStyles = createGlobalStyle`
  ${fonts};
  ${global};
`

const Container = styled(AntLayout)`
  min-height: 100vh;
`

const Layout = ({ children, ...props }) => (
  <Container {...props}>
    <GlobalStyles />
    {children}
  </Container>
)

export default Layout
