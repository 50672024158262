import React, {
  useState,
} from 'react'
import {
  useDispatch,
} from 'react-redux'
import { message } from 'antd'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  CheckOutlined,
  FileTextOutlined,
} from '@ant-design/icons'

import {
  documentUploading,
} from '~/containers/Registration/actions'

import Loading from '~/components/common/Loading'
import Image from '~/components/common/Image'
import DocumentTypes from '~/utils/documentsType'
import AcceptedFiles from '~/utils/acceptedFiles'
import PictoSVG from '~/assets/images/picto.svg'

import {
  DocumentContainer,
  DocumentName,
  DocumentPreview,
  Filled,
  InputFile,
} from './index'

const ImageTypes = ['image/gif', 'image/jpeg', 'image/image/bmp', 'image/png']
const DocumentUploader = ({
  // documentAlias,
  type,
  filled,
  childAlias,
  registrationAlias,
  onUploadSuccess,
}) => {
  const { t } = useTranslation('registration')

  // Generate a unique id
  const ID_UPLOADER = Date.now()
  const dispatch = useDispatch()
  // States
  const [filePreview, setFilePreview] = useState(null)
  const [isFilledDocument, setIsFilledDocument] = useState(filled)
  const [loading, setLoading] = useState(false)

  // functions
  const displayDocumentType = () => DocumentTypes.find(elm => elm.code === String(type))

  const isAcceptedFile = (file) => {
    const { name } = file
    const lastDot = name.lastIndexOf('.')
    const ext = name.substring(lastDot + 1)
    if (AcceptedFiles.includes(ext)) return true
    return false
  }

  const onChangeFile = (event) => {
    const file = event.target.files[0]
    if (!file) return

    if (!isAcceptedFile(file)) {
      message.error(t('format-error'))
      return
    }
    setFilePreview(ImageTypes.includes(file.type) ? <Image src={URL.createObjectURL(file)} alt="" /> : <FileTextOutlined />)
    setLoading(true)
    dispatch(documentUploading(
      {
        type,
        file,
        child_alias: childAlias,
        registration_alias: registrationAlias,
        name: displayDocumentType()?.label,
        shared_with_other_users: displayDocumentType()?.common,
      },
      (response) => {
        setLoading(false)
        setIsFilledDocument(true)
        onUploadSuccess(type, response.alias)
      },
      () => setLoading(false),
    ))
  }

  return (
    <div>
      <label htmlFor={`document_${ID_UPLOADER}`}>
        <DocumentContainer filled={isFilledDocument}>
          <DocumentPreview>
            {loading && <Loading text="" />}
            {!filePreview && <Image src={PictoSVG} alt="" />}
            {filePreview}
          </DocumentPreview>
          <DocumentName>{displayDocumentType()?.label}</DocumentName>
          { isFilledDocument && <Filled><CheckOutlined /></Filled> }
        </DocumentContainer>
      </label>
      {!isFilledDocument && (
        <InputFile
          accept="application/pdf, image/*"
          type="file"
          id={`document_${ID_UPLOADER}`}
          onChange={onChangeFile}
        />
      )}
    </div>
  )
}

DocumentUploader.propTypes = {
  type: PropTypes.number,
  filled: PropTypes.bool,
  childAlias: PropTypes.string.isRequired,
  registrationAlias: PropTypes.string.isRequired,
  onUploadSuccess: PropTypes.func,
}

DocumentUploader.defaultProps = {
  type: null,
  filled: false,
  onUploadSuccess: undefined,
}
export default DocumentUploader
