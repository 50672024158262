import React, { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Divider,
  Form,
  Input,
} from 'antd'

import { LockOutlined } from '@ant-design/icons'
import { resetPasswordConfirm } from '~/containers/User/actions'
import Link from '~/components/common/Link'
import P from '~/components/common/P'
import Logo from '~components/common/Logo'
import LogoSrc from '~assets/images/logo.png'
import { ResetCard, Title } from '../components'

const ResetPasswordConfirm = () => {
  const [form] = Form.useForm()
  const { t } = useTranslation('user')
  const dispatch = useDispatch()
  const [clicked, setClicked] = useState(false)
  const { uid, token } = useParams()
  const {
    resetPasswordConfirmSuccess,
  } = useSelector(state => state.user.auth)

  const handleSubmit = useCallback(
    value => {
      setClicked(true)
      dispatch(resetPasswordConfirm({
        uid,
        token,
        newPassword: value.new_password,
      }))
    },
    [resetPasswordConfirm],
  )
  return (
    <div style={{ marginTop: '50px' }}>
      <div style={{ width: '720px', margin: 'auto', marginBottom: '20px' }}>
        <Logo
          src={LogoSrc}
          height={40}
        />
      </div>
      <ResetCard>
        <div className="card-left">
          <Title
            bold
          >
            {t('reset.header')}
          </Title>
          <Divider height="10px" />
          <P>
            {t('reset.sub-title')}
          </P>
        </div>
        <div className="card-right">
          {resetPasswordConfirmSuccess && clicked ? (
            <div className="card-right-block-msg">
              <div className="card-right-reset-msg">
                {t('reset.success-reset-password')}
              </div>
              <Link
                to="/login"
              >
                {t('reset.link')}
              </Link>
            </div>
          ) : (
            <Form
              form={form}
              onFinish={handleSubmit}
              className="card-form"
            >
              <Form.Item
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: t('reset.field'),
                  },
                ]}
                name="new_password"
              >
                <Input.Password
                  prefix={(
                    <LockOutlined
                      style={{ color: 'rgba(0,0,0,.25)' }}
                    />
                  )}
                  placeholder={t('reset.new-password')}
                  className="card-form-input"
                />
              </Form.Item>
              <Form.Item
                hasFeedback
                name="confirm"
                dependencies={['new_password']}
                rules={[
                  {
                    required: true,
                    message: t('reset.confirm-field'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_password') === value) {
                        return Promise.resolve()
                      }

                      return Promise.reject(t('reset.confirm-field'))
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={(
                    <LockOutlined
                      style={{ color: 'rgba(0,0,0,.25)' }}
                    />
                  )}
                  placeholder={t('reset.confirm-new-password')}
                  className="card-form-input"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="card-form-button"
                  onClick={resetPasswordConfirmSuccess}
                >
                  {t('reset.validate-cta')}
                </Button>
              </Form.Item>
              <Link
                to="/login"
                className="card-form-link"
              >
                {t('reset.link')}
              </Link>
            </Form>
          )}
        </div>
      </ResetCard>
    </div>
  )
}

export default ResetPasswordConfirm
