import { css } from 'styled-components'

export default css`
  body, html {
    height: 100%;
  }

  body {
    overflow: hidden;
  }

  #__next {
    min-height: 100%;
  }

  /*
    OVERRIDE: ANT
    Removes the large height on labels when
    using size="large" on Form component
  */
  .ant-form-large .ant-form-item-label > label {
    height: auto;
  }

  /*
    OVERRIDE: ANT
    Don't put margin on the last element of a form
  */
  .ant-form-item:last-child {
    margin-bottom: 0;
  }

  /** 
   React big calendar 
   Generated outside calendar  
  */
  .rbc-overlay {
    position: absolute;
    z-index: 5;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    padding: 10px;
    z-index: 5px;
    background-color: #fff;
    border-radius: 5px;
    padding: 7px;
    .rbc-event {
      border: 1px solid #B9DEFF;
      margin: 3px 0;
      padding: 8px;
      background-color: #E8F4FF;
      border-radius: 4px;
      color: #1890FF;
      cursor: pointer;
      span {
        display: block;
      }
    }
    
  }

  .ant-modal-confirm .ant-modal-body {
    padding: 24px;
  }

  .gleap-b {
    z-index: 10;
    position: fixed !important;
    top: 0 !important;
  }
`
