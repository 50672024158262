import styled from 'styled-components'
import {
  centeredCSS,
  boldCSS,
  inlineCSS,
  uppercaseCSS,
  sizes,
} from '~/components/common/Typo'

const P = styled.p`
  ${({ centered }) => centered && centeredCSS};
  ${({ bold }) => bold && boldCSS};
  ${({ inline }) => inline && inlineCSS};
  ${({ uppercase }) => uppercase && uppercaseCSS};
  ${({ size }) => (size && sizes[size]) || sizes.regular};
`

export default P
