import React, { useEffect, useState } from "react";
import { Routes, Route, Link, useLocation, } from "react-router-dom";
// import "antd/dist/antd.less";
import ContentHeader from "~/components/common/ContentHeader";
import Button from "~/components/common/Button";
import { PlusOutlined, EditOutlined, EllipsisOutlined } from "@ant-design/icons";
import Menu, {
  MenuItem,
} from "~/components/common/ContentHeader/components/Menu";
import { useSelector, useDispatch } from "react-redux";
import H1 from "~/components/common/H1";
import EditHousehold from "./editHousehold";
import { Row, Col, Divider, Popover, Badge } from "antd";
import styled from "styled-components";
import axios from "~/lib/axios";

import Members from "./Members";
import Contact from "./Contacts";
import Invoice from "./Invoice";
import Document from "./Documents";
import { documentsFetching, householdFetching } from "~/containers/Foyer/actions";
import { isJson } from "~/utils/isJson";
import { Container } from "~/components/common/Container";
import Loading from "~/components/common/Loading";
import { fetchRegistrationsDetails } from "~/containers/Registration/actions";

import OrderDetail from '~/containers/User/UserProfile/orderDetail';
// import Orders from './Orders';
import Bills from "~/containers/Bills";
import Orders from "~/containers/Orders";
import BillsTable from "~/containers/Bills/components/table";
import OrdersTable from "~/containers/Orders/ordersTable";
import { MY_HOUSEHOLD } from "~/graphql/queries";
import { useQuery } from "@apollo/client";

const Bold = styled.div`
  font-size: 16px;
  color: @neutral-5;
  font-weight: 500;
`;

const Normal = styled.div`
  font-size: 14px;
  color: @neutral-5;
`;
const RedSpan = styled.span`
  color: red;
`;

const GreenSpan = styled.span`
  color: green;
`;

const Foyer = () => {
  const user = useSelector((state) => state.user.userInfo.user);
  // const {data: householdData, loading: isLoadingHousehold} = useSelector((state) => state.household.household);
  const {data: documents} = useSelector((state) => state.household.documents);
  const { registrationsDetails, isFetchingDetails } = useSelector(
    (state) => state.registration
  );
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    loading: isLoadingHousehold,
    error: householdError,
    data: householdData,
  } = useQuery(MY_HOUSEHOLD, {
    fetchPolicy: "no-cache",
  });

  const household = householdData?.myHousehold;

  const filteredCommonDocuments = documents?.filter(doc => doc?.shared_with_other_users);

  console.log({documents, filteredCommonDocuments});
  const [showDrawer, setShowDrawer] = useState(false);
  const [child, setChild] = useState(null);
  const [selectedTab, setSelectedTab] = useState(["foyer"]);

  useEffect(() => {
    // dispatch(householdFetching());
    // dispatch(documentsFetching());
  }, []);

  const handleMenu = (e) => {
    setSelectedTab(e.key);
  };

  useEffect(() => {
    if (location.pathname.includes('/contacts')) {
      setSelectedTab(["contacts"]);
    } else if (location.pathname.includes('/commandes')) {
      setSelectedTab(['commandes']);
    } else if (location.pathname.includes('/factures')) {
      setSelectedTab(["factures"]);
    } else if (location.pathname.includes('/documents')) {
      setSelectedTab(["documents"]);
    } else {
      setSelectedTab(["foyer"]);
    }
  }, [location]);
  
  // useEffect(() => {
  //   if (householdData?.length) {
  //     dispatch(fetchRegistrationsDetails(
  //       householdData?.[0]?.organisation?.alias,
  //       // children?.map(child => child?.alias)
  //     ));
  //   }
  // }, [householdData]);

  const formattedAddress = household?.address?.formattedAddress;

  const commonMissingDocuments = household?.commonMissingDocuments?.length;

  console.log({household, householdData})

  // const missingDocuments = householdData?.reduce((acc, cur) => {
  //   const orgHouseholdAvailableDocuments = cur?.organisation?.available_documents?.filter(doc => doc?.docTypeDetails?.is_common)
  //   const orgHouseholdRequiredDocuments = cur?.organisation?.required_documents_details?.filter(doc => doc?.is_common && !orgHouseholdAvailableDocuments?.find(dc => dc?.docTypeDetails?.id === doc?.id))
  //   const householdDocuments = [...orgHouseholdAvailableDocuments, ...orgHouseholdRequiredDocuments]
  //   const missingDocuments = householdDocuments?.filter(doc => [2, 3, 4].includes(doc?.statusDetails?.number) || !doc?.created_at)?.length

  //   acc = acc + missingDocuments

  //   return acc
  // }, 0)

  if (isLoadingHousehold) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <>
      <ContentHeader
        breadcrumbItems={[
          {
            name: 'Foyer',
            path: '/foyer',
          },
        ]}
        buttons={() => (
          <>
            <Button
              size="large"
              style={{ minWidth: 220 }}
              onClick={() => setShowDrawer(true)}
              icon={<EditOutlined />}
              disabled
            >
              Éditer
            </Button>
            <Popover
              placement="bottomRight"
              content={(
                <>
                  <div>
                    <Link to="/registration" style={{ color: '#1E1E1E' }}>
                      Inscrire des personnes à une Organisation
                    </Link>
                  </div>
                  <div>
                    <Link to="/reservation" style={{ color: '#1E1E1E' }}>
                      Inscrire des personnes à une Activité
                    </Link>
                  </div>
                </>
              )}
              trigger="click"
            >
              <Button
                size="large"
                icon={<EllipsisOutlined />}
              />
            </Popover>
          </>
        )}
        // navigationTitle="Année scolaire :"
        navigation={() => (
          <Menu onClick={handleMenu} selectedKeys={selectedTab}>
            <MenuItem key="foyer">
              <Link to="/foyer">Membres</Link>
            </MenuItem>
            <MenuItem key="contacts">
              <Link to="/foyer/contacts">Contacts autorisés</Link>
            </MenuItem>
            <MenuItem key="commandes">
              {/* <Link to="/profile/commandes">Commandes du Foyer</Link> */}
              <Link to="/foyer/commandes">Commandes du Foyer</Link>
            </MenuItem>
            <MenuItem key="factures">
              <Link to="/foyer/factures">Factures du Foyer</Link>
            </MenuItem>
            <MenuItem key="documents">
              <Link to="/foyer/documents">
                <span>
                  Documents du Foyer
                  <Badge
                    count={commonMissingDocuments}
                    style={{ marginLeft: 5 }}
                  />
                </span>
              </Link>
            </MenuItem>
          </Menu>
        )}
      >
        <H1
          style={{
            fontSize: 24,
            paddingBottom: 0,
          }}
        >
          {user?.first_name} {user?.last_name}
        </H1>
        <Row style={{ height: 100 }}>
          <Col span={11}>
            <Bold>Adresse de facturation</Bold>
            <Normal>{formattedAddress}</Normal>
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: 100 }} />
          </Col>
          <Col span={12}>
            <Bold>Données CAF</Bold>
            <Normal>Numéro CAF: {household?.cafNumber ? `${household?.cafNumber}` : (
              <GreenSpan>non
                précisé
              </GreenSpan>
            )}
            </Normal>
            {/* <Normal>Quotient familial: { householdData?.[0]?.monthly_revenue ? { householdData?.[0]?.monthly_revenue
             * != 999999?  `${householdData?.[0]?.monthly_revenue} €` : <RedSpan>non communiqué</RedSpan> } :
             * <GreenSpan>non précisé</GreenSpan> }</Normal> */}
            <Normal>Quotient familial: {
              household?.monthlyRevenue ? [household?.monthlyRevenue
            !== 999999 ? `${household?.monthlyRevenue} €` : <RedSpan>non communiqué</RedSpan>] : <GreenSpan>non précisé</GreenSpan>
            }
            </Normal>
          </Col>
        </Row>
        {/* <HouseholdDetail
          name={householdFormatedData.name}
          mister={householdFormatedData.mister}
          madame={householdFormatedData.madame}
          adresse={householdFormatedData.adresse}
          balance={householdFormatedData.balance}
          caf_number={householdFormatedData.caf_number}
          monthly_revenue={householdFormatedData.monthly_revenue}
          revenueRange={householdFormatedData.revenueRange}
        /> */}
      </ContentHeader>
      <Routes>
        <Route path="/*" exact element={<Members />} />
        <Route path="/contacts" exact element={<Contact />} />
        <Route path="/commandes" exact element={<OrdersTable />} />
        <Route path="/commandes/:id" exact element={<OrderDetail />} />
        <Route path="/factures" exact element={<BillsTable />} />
        <Route path="/documents" exact element={<Document organisation={household?.organisation} />} />
        {/* <Route path="/activities/:alias/edit/" exact element={<ActivityEditPage />} /> */}
      </Routes>
      <EditHousehold
        data={householdData}
        setShowDrawer={setShowDrawer}
        showDrawer={showDrawer}
      />
    </>
  );
};

export default Foyer;
