import { createActions } from "redux-actions"

export const {
  activitiesFetching,
  activitiesFetched,
  activitiesFetchError,

  activityDetailsFetching,
  activityDetailsFetched,
  activityDetailsFetchError,

  organisationsActivitiesFetching,
  organisationsActivitiesFetched,
  organisationsActivitiesFetchError,

  activitySlotsFetching,
  activityAllSlotsFetching,
  activitySlotsFetched,
  activitySlotsFetchError,

  subscriptionCreating,
  subscriptionCreated,
  subscriptionCreateError,

  subscriptionUpdating,
  subscriptionUpdated,
  subscriptionUpdateError,

  userOrganisationsFetching,
  userOrganisationsFetched,
  userOrganisationsFetchError,
} = createActions({
  ACTIVITIES_FETCHING: (organisationAlias) => ({ organisationAlias }),
  ACTIVITIES_FETCHED: (activities) => activities,
  ACTIVITIES_FETCH_ERROR: () => ({}),

  ACTIVITY_DETAILS_FETCHING: (organisationAlias, activityAlias) => ({
    organisationAlias,
    activityAlias,
  }),
  ACTIVITY_DETAILS_FETCHED: (activities) => activities,
  ACTIVITY_DETAILS_FETCH_ERROR: () => ({}),

  ORGANISATIONS_ACTIVITIES_FETCHING: (
    organisationsAlias,
  ) => organisationsAlias,
  ORGANISATIONS_ACTIVITIES_FETCHED: data => data,
  ORGANISATIONS_ACTIVITIES_FETCH_ERROR: () => ({}),

  ACTIVITY_SLOTS_FETCHING: (
    organisationAlias,
    activityAlias,
    buildingAlias
  ) => ({ organisationAlias, activityAlias, buildingAlias }),
  ACTIVITY_ALL_SLOTS_FETCHING: (organisationAlias, activityAlias) => ({
    organisationAlias,
    activityAlias,
  }),
  ACTIVITY_SLOTS_FETCHED: (activities) => activities,
  ACTIVITY_SLOTS_FETCH_ERROR: () => ({}),

  SUBSCRIPTION_CREATING: (childAlias, data, callback) => ({
    childAlias,
    data,
    callback,
  }),
  SUBSCRIPTION_CREATED: () => ({}),
  SUBSCRIPTION_CREATE_ERROR: () => ({}),

  SUBSCRIPTION_UPDATING: (alias, childAlias, data, callback) => ({
    alias,
    childAlias,
    data,
    callback,
  }),
  SUBSCRIPTION_UPDATED: () => ({}),
  SUBSCRIPTION_UPDATE_ERROR: () => ({}),

  USER_ORGANISATIONS_FETCHING: () => ({}),
  USER_ORGANISATIONS_FETCHED: (organisation) => organisation,
  USER_ORGANISATIONS_FETCH_ERROR: () => ({}),
})
