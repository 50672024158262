import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import { Space, Dropdown, Divider, Tag, Avatar } from "antd";
import { Link } from "react-router-dom";
// import {
//   mutate,
// } from 'hooks/useSWR'
// import api from 'lib/api'
import Picture from "~/components/common/Picture";
import Gender from "~/components/common/Gender";
import Text from "~/components/common/Text";
import StatusSelect from "~/components/common/StatusSelect";
import { useSelector } from "react-redux";
import axios from "~/lib/axios";
import malePlaceholder from "~/assets/images/boy.svg";
import femalePlaceholder from "~/assets/images/girl.svg";

import {
  CustomCard,
  Name,
  SubText,
  CustomButton,
  Options,
  CustomSpace,
  Footer,
  Content,
  MiniTag,
} from "./components";
import { childStatus } from "~/constants/index";
// import useApi from 'hooks/useApi'

const CustomSelect = styled(StatusSelect)`
  position: absolute;
  left: 8px;
  top: 8px;
`;
const ChildrenCard = ({
  menu,
  photo,
  photoUrl,
  last_name,
  first_name,
  alias,
  age,
  gender,
  schoolClassName = "Classe de Mme Capelli",
  schoolName = "École élémentaire Charles Bouvard",
  schoolLevel = "CM1",
}) => {
  const { accessToken } = useSelector((state) => state.user.auth);
  const [imgUrl, setImgUrl] = useState("");
  /**
   * TODO: This is not the right Alias to use
   *  we need to retrieve the registrations alias
   */
  // const {
  //   request: changeStatus,
  // } = useApi(
  //   'registrations.patch',
  //   { alias },
  // )
  // const onStatusChange = useCallback(
  //   async status => {
  //     await changeStatus({ status })
  //     // revalidate the registrations list
  //     mutate(api.url('registrations.get'))
  //   },
  //   [changeStatus],
  // )

  // 
  // const getImgSrc = useCallback(async () => {
  //   if (photo) {
  //     const res = await axios.get(photo, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //       responseType: "arraybuffer",
  //     });
  //     const binData = await Buffer.from(res.data, "binary").toString("base64");
  //     setImgUrl(`data:image/jpg;base64,${binData}`);
  //     return;
  //   }
  //   const url = !gender ? malePlaceholder : femalePlaceholder;
  //   setImgUrl(url);
  // }, []);

  // useEffect(() => {
  //   getImgSrc();
  // }, []);

  const status = 1;

  return (
    <CustomCard>
      <Content>
        {/* <CustomSelect
          defaultValue={0}
          source={childStatus}
          // onStatusChange={onStatusChange}
          size="small"
        /> */}
        {/* <MiniTag color={childStatus[status]?.color}>
          {childStatus[status]?.label}
        </MiniTag> */}
        {/* {menu && (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Options>...</Options>
          </Dropdown>
        )} */}
        <CustomSpace>
          <Picture
            width="100"
            height="100"
            src={photo}
            alt={`${first_name} ${last_name}`}
            style={{ margin: "0 auto" }}
          />
          {/* <Avatar src={imgUrl} size={100} /> */}
          <Space size="small">
            <Name>
              {first_name} {last_name}
            </Name>
            <Gender gender={gender} />
          </Space>
          {age && <SubText>{age} ans</SubText>}

          <Link to={`/children/${alias}`}>
            <CustomButton>Voir la fiche</CustomButton>
          </Link>
        </CustomSpace>
      </Content>
      {/* {(schoolClassName || schoolName || schoolLevel) && (
        <Footer>
          <Divider style={{ margin: "0 0 16px 0" }} />
          <Link to={`/classes/${alias}`} style={{ display: "block" }}>
            {`${schoolLevel ?? ""} ${
              schoolClassName && schoolLevel
                ? "- " + schoolClassName
                : schoolClassName ?? ""
            }`}
          </Link>
          <Text size="small">{schoolName ?? ""}</Text>
        </Footer>
      )} */}
    </CustomCard>
  );
};

export default ChildrenCard;
