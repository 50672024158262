import {
  CheckCircleFilled,
  FileImageOutlined,
} from '@ant-design/icons';
import Drawer from "~/components/common/Drawer";
import { Row, Col, Space } from 'antd';
import { Form, Select, Input, Radio, Switch, InputNumber, Divider, Button, Upload, message, DatePicker } from "antd";
import Gender from '~/components/common/Gender';
import CustomProfilAvatar from '~/components/common/CustomProfilAvatar';
import {
  allergies, getDocumentType
} from '~/constants';
import { addNewDocument } from '~/containers/Foyer/actions';
import useErrors from "~/hooks/useErrors";

import Checkbox from 'antd/lib/checkbox/Checkbox';

import dayjs from "dayjs";
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';

const { Option } = Select;
const { TextArea } = Input;

const TransmitDoc = ({ child, targetedDocument, showDrawer, setShowDrawer }) => {
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [noDueDate, setNoDueDate] = useState(false);
  const { ErrorComponent, setErrors } = useErrors();
  const [form] = Form.useForm();

  let initialState = {};

  console.log({file, isChecked});

  const onFinish = (values) => {
    console.log({values});

    const file = values.file.file;
    console.log({values, file});
    if (!['image/jpeg', 'application/pdf'].includes(file.type)) {
      message.error(`${file.name} n'est pas un fichier jpg`);
      return ['image/jpeg', 'application/pdf'].includes(file.type);
    }
    
    const formData = new FormData();
    formData.append('merge', false);
    formData.append('file', file.originFileObj);
    formData.append('name', file.name);
    formData.append('type', typeof targetedDocument?.number === 'number' ? targetedDocument?.number : targetedDocument?.doctype);
    formData.append('shared_with_other_users', false);
    formData.append('child_alias', child?.alias);
    // if (!noDueDate) {
    //   formData.append('due_date', dayjs(values?.due_date).format('YYYY-MM-DD'))
    // }
    formData.append('registration_alias', targetedDocument?.registrationAlias);
    formData.append('organisation_alias', targetedDocument?.organisationAlias);
    setFile(file.originFileObj);

    if (['image/jpeg', 'application/pdf'].includes(file.type)) {
      dispatch(
        addNewDocument({
          formData,
          childAlias: child?.alias,
          onSuccess: () => {
            setFile(null);
            setIsChecked(false);
            form.resetFields();
            setShowDrawer(false);
          },
        })
      );
    }
  };

  const uploadProps = {
    fileList: [],
    beforeUpload: file => {
      if (!['application/pdf', 'image/jpeg'].includes(file.type)) {
        message.error(`${file.name} n'est pas un fichier jpg`);
      }
      return ['application/pdf', 'image/jpeg'].includes(file.type);
    },
    customRequest: async (e) => {
      setFile(e.file);
    }
  };

  return (
    <Drawer
      open={showDrawer}
      handleCloseDrawer={() => {
        setFile(null);
        setIsChecked(false);
        form.resetFields();
        setShowDrawer(false);
      }}
      width={636}
      title="Transmettez un document"
      destroyOnClose
    >
      <Form
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
        initialValues={initialState}
      >
        <ErrorComponent />
        <Form.Item
          label={`${targetedDocument?.description} (.jpg ou pdf maximum 1mb) *`}
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            }
          ]}
          name="file"
        >
          <Upload
            {...uploadProps}
          >
            <Space style={{ paddingTop: 5 }}>
              <Button
                size="large"
                icon={<FileImageOutlined />}
              >
                Choisir un document
              </Button>
              {!!file && (
                <CheckCircleFilled style={{ color: '#52C41A', fontSize: 24 }} />
              )}
            </Space>
          </Upload>
        </Form.Item>
        {/* <Space>
          <Form.Item
            label="Date d’échéance"
            name="due_date"
            rules={[
              {
                required: !noDueDate,
                message: "Veuillez renseigner une date d’échéance",
              },
            ]}
          >
            <DatePicker
              placeholder="Sélectionner"
              picker="date"
              // defaultPickerValue={defaultDate}
              format="DD/MM/YYYY"
              style={{ width: 280 }}
              disabled={noDueDate}
            />
          </Form.Item>
          <Button
            onClick={() => setNoDueDate(state => !state)}
            style={noDueDate ? {
              borderColor: '#40a9ff',
              color: '#40a9ff',
              marginTop: 11,
            } : {
              marginTop: 11,
            }}
          >
            Aucune
          </Button>
        </Space> */}
        <Form.Item
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            }
          ]}>
          <Checkbox onChange={e => setIsChecked(e.target.checked)}>
            J’accepte de communiquer ce document à {targetedDocument?.organisationName} *
          </Checkbox>
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          style={{ width: "100%", marginTop: 20, marginBottom: 50 }}
          disabled={!file || !isChecked}
        >
          Valider
      </Button>
      </Form>

    </Drawer >

  );
};

export default TransmitDoc;