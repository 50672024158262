import { Row, Col, Button } from "antd"
import React, { useEffect, useState } from "react"
import FeedMessages from "./components/FeedMessages/index"
import { useDispatch, useSelector } from "react-redux"
import { feedFetching } from "~/containers/Home/actions"
import Space from "~/components/common/Space/index"
import Layout from "~/components/auth/Layout"
import Container from "~/components/common/Container/index"
import { Pagination } from "antd"
import HeaderNotifications from "./components/HeaderNotifications"
import { Select, Input } from "antd"
import { getFeedMessagesCount } from "~/containers/Home/selectors"
import { fetchChildren } from "~/containers/Children/actions"
import { MY_FEEDS } from "~/graphql/queries"
import { useQuery } from "@apollo/client"

const { Option } = Select
const { Search } = Input

const Actualities = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState("")
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const feed = useSelector((state) => state.home.feed)
  const count = useSelector(getFeedMessagesCount)

  const {
    loading: loadingFeeds,
    error: errorFeeds,
    data: feedsData,
    // refetch,
  } = useQuery(MY_FEEDS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    variables: {
      // search,
      first: pageSize,
      skip: (page - 1) * pageSize,
      // sort,
    },
  });

  const feedPageInfos = {
    count: feedsData?.myFeedsPaginated?.count || 0,
    currentPage: feedsData?.myFeedsPaginated?.page || 0,
    allPages: feedsData?.myFeedsPaginated?.pages || 0,
  }
  
  // useEffect(() => {
  //   dispatch(feedFetching(page, search, pageSize))
  //   dispatch(fetchChildren())
    
  // }, [search, page, pageSize])

  return (
    <Container>
      <HeaderNotifications />
      <Space
        direction="horizontal"
        size="large"
        style={{
          width: "100%",
          justifyContent: "space-between",
          paddingBottom: 10,
          paddingTop: 20,
        }}
      >
        <h2>Actualités</h2>
        <Space>
          <Select
            defaultValue={10}
            style={{ width: 120 }}
            onChange={setPageSize}
          >
            <Option value={10}>10 par page</Option>
            <Option value={20}>20 par page</Option>
            <Option value={30}>30 par page</Option>
            <Option value={50}>50 par page</Option>
          </Select>
          <Search
            placeholder="Rechercher..."
            onSearch={(value) => setSearch(value)}
            style={{ width: 200 }}
          />
        </Space>
      </Space>

      <FeedMessages
        feedsData={feedsData}
        loading={loadingFeeds}
      />

      <Space style={{ float: "right", paddingTop: 10 }}>
        <Pagination
          total={feedPageInfos.count}
          showSizeChanger={false}
          onChange={setPage}
          current={page}
          pageSize={pageSize}
          defaultPageSize={10}
          defaultCurrent={1}
        />
      </Space>
    </Container>
  )
}

export default Actualities
