import React from "react"
import {
  Layout as AntLayout,
} from "antd"
import styled from "styled-components"

import CommonLayout from "~/components/common/Layout/index"

const {
  Content,
} = AntLayout

const Container = styled(CommonLayout)`
  background-color: #0f2d78;
`

const ContentContainer = styled(Content)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
`

const Layout = ({ children }) => (
  <Container>
    <ContentContainer>
      {children}
    </ContentContainer>
  </Container>
)

export default Layout
