

import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import dayjs from 'dayjs'
import Container from '~/components/common/Container'
import FilterContainer from '~/components/common/FilterContainer'
import Space from '~/components/common/Space'
import Table from '~/components/common/Table'
import { downloadPdf, fetchBills } from '~/containers/Bills/actions'
import { StatusCircles } from '~/containers/User/UserProfile/components/Styles/invoice'
import AddContact from './AddContactDrawer'
import { useColumnsInvoices } from './columns'

const sortFields = {
  profil: 'child__last_name',
  households: 'billinghouseholdregistrations__household__adult_members_set__user__last_name',
  school_class: 'schoolClass__name',
  status: 'status',
}

const Bills = () => {
  const dispatch = useDispatch()
  const [statusFilter, setStatusFilter] = useState(null)
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [selectionType] = useState('checkbox')
  const [setRowSelection] = useState([])
  const [sort, setSort] = useState(sortFields.profil)
  const [showDrawer, setShowDrawer] = useState(false)

  const { accessToken } = useSelector((state) => state.user.auth)
  const { bills, isFetchingBills } = useSelector((state) => state.bills)

  const onStatusChange = () => {}

  const handleDownloadPDF = useCallback(
    (token, url, billName) => {
      dispatch(
        downloadPdf({ token, url, billName }),
      )
    },
    [dispatch],
  )

  const columns = useColumnsInvoices(onStatusChange, setShowDrawer, handleDownloadPDF, search)

  const onTableChange = useCallback((pagination, _, sorter, { action }) => {
    if (action === 'paginate') {
      setPage(pagination.current)
    }
    if (action === 'sort') {
      const field = sortFields[sorter.field]
      setSort(sorter.order === 'descend' ? `-${field}` : field)
    }
  }, [])

  const dataSource = useMemo(() => bills?.results?.map((bill) => ({
    key: bill.alias,
    date: dayjs(bill.issue_date, 'MM-DD-YYYY').format('DD/MM/YYYY'),
    organisation: bill.organisation_name,
    billingNumber: bill.bill_number,
    type: bill?.type,
    period: bill?.period,
    montant: bill.total_amount,
    state: bill,
    paiement: bill?.related_payments,
    credit_used_in_bills: bill?.credit_used_in_bills,
    // ACTIONS
    url: bill.url,
    bill_pdf_url: bill.bill_pdf_url,
    token: accessToken,
    bill_status: bill.bill_status,
    status: bill?.status,
  })), [bills, accessToken])

  useEffect(() => {
    dispatch(fetchBills({
      search,
      page,
      pageSize,
    }))
  }, [search, page, pageSize, dispatch])

  return (
    <>
      <Container>
        <Space
          direction="vertical"
          size="large"
        >
          <FilterContainer
            defaultPageSize={pageSize}
            onPageSizeChange={setPageSize}
            onSearch={(value) => setSearch(value)}
            isBills
          />
          <Table
            columns={columns}
            dataSource={dataSource}
            isLoading={isFetchingBills}
            onChange={onTableChange}
            total={bills?.count}
            currentPage={page}
            pageSize={pageSize}
          />
        </Space>
        <StatusCircles>Statut de paiement : {' '}
          ✅ Accepté {' '}
          ❌ Annulé {' '}
          🚫 Refusé
        </StatusCircles>
      </Container>
      <AddContact showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
    </>
  )
}

export const ActionsContainer = styled.span`
  display: flex;
  flex-direction: row;
  .pay{
    display: inline-block;
    padding-left: 5px;
    margin-left: 5px;
    border-left: solid 1px @grey-color;
  }
`

export default Bills