import React from 'react'
import {
  CalendarOutlined,
  CheckOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { SlotContainer, SlotContent, SelecteddMark } from './index'

const Slot = ({ slot, onChoose, selected }) => (
  <SlotContainer onClick={onChoose} selected={selected}>
    <CalendarOutlined />
    <SlotContent>
      Du {dayjs(slot.start_date).format('DD/MM')} au {dayjs(slot.end_date).format('DD/MM')}
    </SlotContent>
    {selected && <SelecteddMark><CheckOutlined /></SelecteddMark>}
  </SlotContainer>
)

Slot.propTypes = {
  slot: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onChoose: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
}

export default Slot
