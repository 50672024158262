
import { logout } from '~/containers/User/actions'
import { handleActions } from '~/utils/redux-actions'
import {
  fetchContactsError,
  fetchContactsProcess,
  fetchContactsSuccess
} from './actions'

const defaultState = {
  isFetchingContacts: false,
  fetchError: false,
  fetchSuccess: false,

  creatingError: false,
  isCreating: false,

  contacts: [],
}

const ContactsReducer = handleActions(
  {
    // Logout
    [logout]: () => defaultState,

    [fetchContactsProcess]: state => {
      state.isFetchingContacts = true
      state.fetchError = false
      state.fetchSuccess = false
    },
    [fetchContactsError]: state => {
      state.isFetchingContacts = false
      state.fetchError = true
      state.fetchSuccess = false
    },
    [fetchContactsSuccess]: (state, { payload }) => {
      state.isFetchingContacts = false
      state.fetchError = false
      state.fetchSuccess = true
      state.contacts = payload
    },
  },
  defaultState,
)

export default ContactsReducer
