import React from "react"
import { Provider } from "react-redux"
import styled, { ThemeProvider } from "styled-components"
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom"
import theme from "./theme/antd.lessvars"
import { store } from "~/lib/redux"
import MainLayout from "~/containers/MainLayout"
import Login from "~/containers/User/Login"
import ForgottenPassword from "~/containers/User/ForgottenPassword"
import ResetPasswordConfirm from "~/containers/User/ResetPasswordConfirm"
import PrivateRoute from "~/utils/Auth/PrivateRoute"
import AuthRoute from "~/utils/Auth/AuthRoute"
import PasswordUpdate from "~/containers/PasswordUpdate/index"
import "antd/dist/reset.css";
import { Button, ConfigProvider, Result } from "antd"
import PaymentResult from "~/containers/PaymentResult"
import { defaultTheme } from "@ant-design/compatible"

// ChD 20240314 code quality GitLab CI/CD procedure deployment

const Reconnect = () => (
  <ReconnectContainer>
    <Result
      title={
        <p>
          Pour le bon fonctionnement de votre portail Cityviz, une reconnexion est nécessaire.
          <br />
          Merci pour votre compréhension
        </p>
      }
      extra={
        <Button
          type="primary"
          key="console"
        >
          Reconnexion
        </Button>
      }
    />
  </ReconnectContainer>
)

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ConfigProvider theme={{
          ...defaultTheme,
          hashed: false,
        }}>
          <Router>
            <Routes>
              <Route
                path="/login"
                element={
                  <AuthRoute>
                    <Login />
                  </AuthRoute>
                }
                exact
              />
              <Route path="/reconnect" exact element={<Reconnect />} />
              <Route path="/paymentResult" exact element={<PaymentResult />} />
              <Route
                path="/forgotten-password"
                element={
                  <AuthRoute>
                    <ForgottenPassword />
                  </AuthRoute>
                }
                exact
              />
              <Route
                path="/reset-password/:uid/:token"
                element={
                  <AuthRoute>
                    <ResetPasswordConfirm />
                  </AuthRoute>
                }
                exact
              />
              <Route
                path="/password_update"
                element={
                  <PrivateRoute>
                    <PasswordUpdate />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/*"
                element={
                  <PrivateRoute>
                    <MainLayout />
                  </PrivateRoute>
                }
                exact
              />
            </Routes>
          </Router>
        </ConfigProvider>
      </ThemeProvider>
    </Provider>
  )
}

const ReconnectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  height: 100vh;
`

export default App
