import React from "react"
import { Button } from "antd"
import styled from "styled-components"

const DefaultButton = (props) => (
  <Button
    {...props}
    style={{
      background: "#F2F4F6",
      borderColor: "#F2F4F6",
      color: "#646464",
      margin: 10,
      ...props.style,
    }}
  ></Button>
)

export default DefaultButton
