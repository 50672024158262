import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import Small from "./Small"
import Big from "./Big"
import Loading from "~/components/common/Loading"

const Activity = ({ subscription, activeId, onActiveChange }) => {
  const child = useSelector((state) => state.children.childDetails)
  const activity = child?.subscriptions?.find(
    (s) => s.alias === subscription.alias
  )
  const isActive = activeId === activity?.alias
  
  if (!activity) return <Loading text={false} />

  return (
    <div>
      {!isActive ? (
        <Small
          activity={activity}
          onActiveChange={onActiveChange}
          subscription={subscription}
        />
      ) : (
        <Big
          activity={activity}
          onActiveChange={onActiveChange}
          subscription={subscription}
        />
      )}
    </div>
  )
}

Activity.propTypes = {
  subscription: PropTypes.shape().isRequired,
  activeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onActiveChange: PropTypes.func.isRequired,
}

Activity.defaultProps = {
  activeId: null,
}
export default Activity
