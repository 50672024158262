import React from 'react'
import PropTypes from 'prop-types'
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons'

import P from '~/components/common/P'
import { CalendarHeader } from './components'

const Header = ({
  next,
  pervious,
  value,
}) => (
  <CalendarHeader>
    <CaretLeftFilled onClick={pervious} />
    <P>
      {value.format('MMM Y')}
    </P>
    <CaretRightFilled onClick={next} />
  </CalendarHeader>
)

Header.propTypes = {
  next: PropTypes.func.isRequired,
  pervious: PropTypes.func.isRequired,
  value: PropTypes.shape().isRequired,
}
export default Header
