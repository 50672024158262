import { FileImageOutlined } from "@ant-design/icons"
import { Button, Col, DatePicker, Divider, Flex, Form, Input, message, Radio, Row, Space, Spin, Upload } from "antd"
import ImgCrop from "antd-img-crop"
import dayjs from "dayjs"
import React, { useCallback, useEffect, useState } from "react"
import Autocomplete from "react-google-autocomplete"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import styled from "styled-components"
import CustomProfilAvatar from "~/components/common/CustomProfilAvatar"
import Drawer from "~/components/common/Drawer"
import { updateUserPhoto, updateUserProfile } from "~/containers/User/actions"
import useErrors from "~/hooks/useErrors"

const getValueFromGMAPComponents = (components, type) => {
  let value
  components.forEach((comp) => {
    if (comp.types.includes(type)) {
      value = comp.long_name
    }
  })
  return value
}

const EditUser = ({ data, showDrawer, setShowDrawer, profile }) => {
  const [file, setFile] = useState(null)
  const { ErrorComponent, setErrors } = useErrors()
  const [form] = Form.useForm()
  const [selected, setSelected] = useState([])
  const [addressObj, setAddressObj] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const {isUpdatingPhotoProfile} = useSelector((state) => state.user.userInfo)
  const showAddChildButton = location.search.includes('openDrawer')
  const [willAddNewChild, setWillAddNewChild] = useState(false)

  let initialState = {}

  const handleSubmit = useCallback(
    (values) => {
      const newData = {
        ...values,
        date_of_birth: dayjs(values.date_of_birth).format("YYYY-MM-DD"),
        address: JSON.stringify({
          route: values.route,
          street_number: values.street_number,
          postal_code: values.postal_code,
          locality: values.locality,
          country: "France",
          formatted_address: `${values.street_number} ${values.route}, ${values.postal_code} ${values.locality}, France`,
        }),
      }
      
      dispatch(
        updateUserProfile({
          user: newData,
          onSuccess: () => {
            setShowDrawer(false)

            if (willAddNewChild) {
              navigate('/foyer?addNewChild')
            }
          },
        })
      )
    },
    [addressObj, updateUserProfile, willAddNewChild, location]
  )

  console.log({profile})

  useEffect(() => {
    if (form && !!profile) {
      
      let address
      if (profile?.address) {
        try {
          address = JSON.parse(profile?.address)
        } catch (e) {
          console.log("error", e) // error in the above string (in this case, yes)!
          address = null
        }
      }

      console.log({address})
      form.setFieldsValue({
        civility: profile.civility,
        
        first_name: profile?.first_name ?? "",
        
        last_name: profile?.last_name ?? "",
        
        date_of_birth: dayjs(profile.date_of_birth || undefined),
        phone: profile?.phone ?? "",
        mobile: profile?.mobile ?? "",
        
        // address: address ? address.formatted_address : "",
        ...address,
      })

      // form.setFieldValue(address, handleAddressChange)
      
      if (address) setAddressObj(address)
      else setAddressObj({})
    }
  }, [form, profile])

  const defaultDate = dayjs().subtract(
    Number(process.env.REACT_APP_DEFAULT_CHILD_YEAR ?? 10),
    "years"
  )

  const onFinish = (values) => {
    
  }

  const onFinishFailed = (errorInfo) => {
    
  }

  const uploadProps = {
    fileList: [],
    beforeUpload: (file) => {
      if (file.type !== "image/jpeg") {
        message.error(`${file.name} n'est pas un fichier jpg`)
      }
      return file.type === "image/jpeg"
    },
    onChange(e) {
      
      const formData = new FormData()
      formData.append('photo', e.file.originFileObj)
      formData.append('name', 'photo')
      setFile(e.file.originFileObj)

      if (e.file.type === "image/jpeg") {
        dispatch(
          updateUserPhoto({
            formData,
            onSuccess: () => {},
          })
        )
      }
    },
  }

  // const handleAddressChange = useCallback(
  //   (data) => {
  //     // Reformat address object after updating
  //     const dataAddress = {
  //       formatted_address: data.formatted_address,
  //       street_number: getValueFromGMAPComponents(
  //         data.address_components,
  //         "street_number"
  //       ),
  //       route: getValueFromGMAPComponents(data.address_components, "route"),
  //       postal_code: getValueFromGMAPComponents(
  //         data.address_components,
  //         "postal_code"
  //       ),
  //       locality: getValueFromGMAPComponents(
  //         data.address_components,
  //         "locality"
  //       ),
  //       country: getValueFromGMAPComponents(data.address_components, "country"),
  //       lat: data.geometry.location.lat,
  //       lng: data.geometry.location.lat,
  //     }
  //     setAddressObj(dataAddress)
  //     form.setFieldsValue({
  //       address: (getValueFromGMAPComponents(
  //         data.address_components,
  //         "street_number"
  //       ) ?? '') + " " + (getValueFromGMAPComponents(data.address_components, "route") ?? ''),
  //       zip_code: getValueFromGMAPComponents(
  //         data.address_components,
  //         "postal_code"
  //       ),
  //       city: getValueFromGMAPComponents(
  //         data.address_components,
  //         "locality"
  //       ),
  //     })
  //   },
  //   [form]
  // )

  const handleAddressChange = useCallback(
    (data) => {
      console.log({ data });
      const address = {
        street_number: getValueFromGMAPComponents(
          data.address_components,
          "street_number"
        ),
        route: getValueFromGMAPComponents(data.address_components, "route"),
        postal_code: getValueFromGMAPComponents(
          data.address_components,
          "postal_code"
        ),
        locality: getValueFromGMAPComponents(
          data.address_components,
          "locality"
        ),
        country: getValueFromGMAPComponents(
          data.address_components,
          "country"
        ),
        lat: data.geometry.location.lat,
        lng: data.geometry.location.lat,
      }

      form.setFieldsValue(address);
      setAddressObj({
        ...address,
        formatted_address: data.formatted_address,
      });
    },
    [form]
  );
  
  return (
    <Drawer
      open={showDrawer}
      handleCloseDrawer={() => setShowDrawer(false)}
      width={636}
      title="Éditer mon profil"
      destroyOnClose
    >
      <Form
        layout="vertical"
        size="large"
        form={form}
        onFinish={handleSubmit}
        initialValues={initialState}
      >
        <Form.Item label="Photo (.jpg maximum 1mb)">
          <ImgCrop rotate>
            <Upload name="photo" {...uploadProps}>
              <Space style={{ paddingTop: 5 }}>
                {isUpdatingPhotoProfile ? (
                  <Spin />
                ) : (
                  <CustomProfilAvatar file={file} />
                )}

                <Button size="large" icon={<FileImageOutlined />}>
                  Choisir une image
                </Button>
              </Space>
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Form.Item
          label="Civilité"
          name="civility"
          rules={[
            {
              required: false,
              message: "Veuillez indiquer votre civilité",
            },
          ]}
        >
          <Radio.Group>
            <Space>
              <Radio.Button value={1}>
                <Space size="small">Madame</Space>
              </Radio.Button>
              <Radio.Button value={0}>
                <Space size="small">Monsieur</Space>
              </Radio.Button>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Prénom"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner un prénom",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nom"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner un nom",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Date de naissance"
          name="date_of_birth"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner une date de naissance",
            },
          ]}
        >
          <DatePicker
            placeholder="Date de naissance"
            picker="date"
            defaultPickerValue={defaultDate}
            format="DD/MM/YYYY"
            style={{ width: 280 }}
          />
        </Form.Item>
        <Form.Item
          label="Tél. portable"
          name="mobile"
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            },
            {
              validateTrigger: 'onBlur',
              message: "⚠️  format invalide ... 📱 👉 06 (ou +336) ou 07 (ou +337) xx xx xx xx",
              validator: (_, value) => {
                console.log({value})
                if (/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[6-7](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/.test(value)) {
                  return Promise.resolve()
                } else {
                  return Promise.reject()
                }
              }
            }
          ]}
        >
          <Input placeholder=""></Input>
        </Form.Item>
        
        <Form.Item
          label="Téléphone"
          name="phone"
          rules={[
            {
              validateTrigger: 'onBlur',
              message: "⚠️  format invalide ... 📞 👉 01 à 05 (ou +331 à +335) xx xx xx xx",
              validator: (_, value) => {
                console.log({value})
                if (/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-5](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/.test(value) || !value) {
                  return Promise.resolve()
                } else {
                  return Promise.reject()
                }
              }
            }
          ]}
        >
          <Input placeholder=""></Input>
        </Form.Item>
        <Divider></Divider>
        <H1>Adresse de mon foyer</H1>
        {/* <Form.Item
          label="Adresse"
          name="route"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner une adresse",
            },
          ]}
        >
          <Autocomplete
            types={["address"]}
            className="ant-input"
            onPlaceSelected={handleAddressChange}
            options={{
              types: ["address"],
              componentRestrictions: {
                country: "fr",
              },
            }}
          />
        </Form.Item>
        <Form.Item
          label="Complément d’adresse"
          name="complement_adresse"
          rules={[
            {
              required: false,
              message: "Veuillez renseigner une adresse",
            },
          ]}
        >
          <Input placeholder="Appartement, bâtiment, étage..." />
        </Form.Item>
        <Row style={{ paddingTop: 10, marginBottom: 10 }}>
          <Col span={9}>
            <Form.Item
              label="Code postal"
              name="zip_code"
              rules={[
                {
                  required: false,
                  message: "Ce champ ne peut être vide",
                },
              ]}
            >
              <Input></Input>
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={14}>
            <Form.Item
              label="Ville"
              name="city"
              rules={[
                {
                  required: false,
                  message: "Ce champ ne peut être vide",
                },
              ]}
            >
              <Input></Input>
            </Form.Item>
          </Col>
        </Row> */}

        <Form.Item
          style={{ width: "368px" }}
          label="Adresse"
          name={"route"}
          rules={[
            {
              required: true,
              message: "Veuillez renseigner une adresse",
            },
          ]}
        >
          <Autocomplete
            types={["address"]}
            className="ant-input"
            onPlaceSelected={handleAddressChange}
            options={{
              types: ["address"],
              componentRestrictions: {
                country: "fr",
              },
            }}
          />
        </Form.Item>

        <Form.Item
          style={{ width: "368px" }}
          label="Numéro"
          name={"street_number"}
        >
          <Input />
        </Form.Item>

        <Form.Item
          style={{ width: "368px" }}
          label="Complément d'adresse"
          name={"complement_adresse"}
        >
          <Input />
        </Form.Item>

        <Flex>
          <Form.Item
            style={{ width: "176px", paddingRight: 16 }}
            label="Code postale"
            name={"postal_code"}
            rules={[
              {
                required: true,
                message: "Veuillez renseigner votre code postale",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ width: "368px" }}
            label="Ville"
            name={"locality"}
            rules={[
              {
                required: true,
                message: "Veuillez renseigner la ville",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Flex>

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          style={{ width: "100%", marginTop: 20 }}
          // onClick={() => setShowDrawer(false)}
        >
          Enregistrer
        </Button>
        {showAddChildButton && (
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: "100%", marginTop: 20, marginBottom: 50 }}
            onClick={() => setWillAddNewChild(true)}
          >
            Enregistrer et ajouter un ou plusieurs enfants
          </Button>
        )}
      </Form>
    </Drawer>
  )
}

const H1 = styled.div`
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 24px;
  color: @neutral-5;
`

export default EditUser
