
import {
  takeLatest, put, select, all,
} from 'redux-saga/effects'
import { message } from 'antd'
import dayjs from 'dayjs'
import axios from '~/lib/axios'
import { getAuthUserInfos } from '~/containers/User/selectors'
import { checkJwtExpiration } from '~/containers/User/sagas'
import { logout } from '~/containers/User/actions'
import { fetchChildrenAttendancesSaga } from '~/containers/Children/sagas'
import { createRecurrentAttendanceSuccess } from '~/containers/Children/actions'
import {
  fetchCartPrices,
  fetchCartPricesProcess,
  fetchCartPricesSuccess,
  fetchCartPricesError,

  fetchPayments,
  fetchPaymentsProcess,
  fetchPaymentsSuccess,
  fetchPaymentsError,

  fetchAllOrders,
  fetchAllOrdersProcess,
  fetchAllOrdersSuccess,
  fetchAllOrdersError,

  fetchOrders,
  fetchOrdersProcess,
  fetchOrdersSuccess,
  fetchOrdersError,

  fetchOrderDetails,
  fetchOrderDetailsProcess,
  fetchOrderDetailsSuccess,
  fetchOrderDetailsError,

  validateOrder,
  validateOrderProcess,
  validateOrderSuccess,
  validateOrderError,

  deleteOrder,
  deleteOrderProcess,
  deleteOrderSuccess,
  deleteOrderError,

  payOrder,
  payOrderProcess,
  payOrderSuccess,
  payOrderError,

  payOrderOnTheSpotSuccess,

  addPaidAttendance,
  addPaidAttendanceProcess,
  addPaidAttendanceSuccess,
  addPaidAttendanceError,

  removePaidAttendance,
  removePaidAttendanceProcess,
  removePaidAttendanceSuccess,
  removePaidAttendanceError,

  massDeleteAttendances,
  massDeleteAttendancesProcess,
  massDeleteAttendancesSuccess,
  massDeleteAttendancesError,
} from './actions'

function* fetchCartPricesSaga({
  payload: items,
}) {
  try {
    yield* checkJwtExpiration()
    yield put(fetchCartPricesProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      let result = []

      for (let i = 0; i < items.length; i += 1) {
        const response = yield axios.get(
          `users/${authUserInfos.email}/children/${items[i].childAlias}/parent_activities/${items[i].parentActivityAlias}/`,
        )

        result = [...result, {
          comboalias: items[i].childAlias + items[i].parentActivityAlias,
          childAlias: items[i].childAlias,
          parent_activity_alias: items[i].parentActivityAlias,
          price: response.data.household_unit_price,
        }]
      }
      /**
       * Must handle pagination
       */
      yield put(fetchCartPricesSuccess(result))
    } else {
      yield put(fetchCartPricesError())
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    yield put(fetchCartPricesError())
  }
}

function* fetchPaymentsSaga() {
  try {
    yield* checkJwtExpiration()
    yield put(fetchPaymentsProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.get(
        `users/${authUserInfos.email}/prepaidPayment/?page_size=1000`,
      )

      /**
       * Must handle pagination
       */
      yield put(fetchPaymentsSuccess(response.data.results))
    } else {
      yield put(fetchPaymentsError())
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    yield put(fetchPaymentsError())
  }
}

export function* fetchAllOrdersSaga({
  payload: {
    pageSize = 1000,
    page = 1,
    search = '',
  },
}) {
  console.log({pageSize, page, search})
  try {
    yield* checkJwtExpiration()
    yield put(fetchAllOrdersProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.get(
        `users/${authUserInfos.email}/orders/?page_size=${pageSize}&page=${page}&search=${search}`,
      )

      yield put(fetchAllOrdersSuccess({
        data: response.data.results,
        count: response.data.count,
      }))
    } else {
      yield put(fetchAllOrdersError())
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    message.error('Erreur lors du chargement des commandes')
    yield put(fetchAllOrdersError())
  }
}

export function* fetchOrdersSaga() {
  try {
    yield* checkJwtExpiration()
    yield put(fetchOrdersProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.post(
        `users/${authUserInfos.email}/orders/current/`,
        {},
      )

      yield put(fetchOrdersSuccess(response.data))
    } else {
      yield put(fetchOrdersError())
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    message.error('Erreur lors du chargement du panier')
    yield put(fetchOrdersError())
  }
}

export function* fetchOrderDetailsSaga({
  payload: { orderAlias },
}) {
  try {
    yield* checkJwtExpiration()
    yield put(fetchOrderDetailsProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.get(
        `users/${authUserInfos.email}/orders/${orderAlias}/`,
      )
      console.log('Axios Call Sagas Cart', response)

      yield put(fetchOrderDetailsSuccess(response.data))
    } else {
      yield put(fetchOrderDetailsError())
      yield put(logout())
    }
  } catch (err) {
    console.log({ err })
    message.error('Erreur lors du chargement des commandes')
    yield put(fetchOrderDetailsError())
  }
}

function* validateOrderSaga({
  payload: { orderAlias , closeModal },
}) {
  try {
    yield* checkJwtExpiration()
    yield put(validateOrderProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.post(
        `users/${authUserInfos.email}/orders/${orderAlias}/validate/`,
        {},
      )

      if (response?.data?.msg) {
        if (typeof closeModal === 'function') {
          closeModal()
        }
        message.open({
          content: response?.data?.msg,
          duration: 30,
        })

        return
      }

      yield put(validateOrderSuccess(response.data))
    } else {
      yield put(validateOrderError())
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    message.error('Erreur lors de la validation du panier')
    yield put(validateOrderError())
  }
}

function* deleteOrderSaga({
  payload: { orderAlias , redirecturl, closeModal, },
}) {
  try {
    yield* checkJwtExpiration()
    yield put(deleteOrderProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.post(
        `users/${authUserInfos.email}/orders/${orderAlias}/forcedelete/`,
        {},
      )

      if (response?.data?.errors?.length) {
        if (typeof closeModal === 'function') {
          closeModal()
        }
        message.open({
          content: response?.data?.errors,
          duration: 15,
        })
      }

      yield put(deleteOrderSuccess(response.data))
      yield* fetchOrdersSaga()
    } else {
      yield put(deleteOrderError())
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    message.error('Erreur lors de la suppression du panier')
    yield put(deleteOrderError())
  }
}

function* payOrderSaga({
  payload: {
    orderAlias, redirecturl, is_payment_on_spot, closeModal,
  },
}) {
  try {
    yield* checkJwtExpiration()
    yield put(payOrderProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.post(
        `users/${authUserInfos.email}/orders/${orderAlias}/pay/`,
        {
          redirecturl,
          is_payment_on_spot,
        },
      )

      if (response?.data?.msg) {
        if (typeof closeModal === 'function') {
          closeModal()
        }
        message.open({
          content: response?.data?.msg,
          duration: 30,
        })

        return
      }

      yield put(payOrderSuccess(response?.data))
      // yield* fetchOrdersSaga()

      if (is_payment_on_spot) {
        console.log({ is_payment_on_spot, orderAlias })
        yield put(payOrderOnTheSpotSuccess(orderAlias))
      }
    } else {
      yield put(payOrderError())
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    message.error('Erreur lors de payment')
    yield put(payOrderError())
  }
}

function* addPaidAttendanceSaga({
  payload: {
    data,
    callback,
  },
}) {
  try {
    yield* checkJwtExpiration()
    yield put(addPaidAttendanceProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.post(
        `users/${authUserInfos.email}/orders/attendance/`,
        data,
      )

      yield put(addPaidAttendanceSuccess(response.data.results))

      if (typeof callback === 'function') {
        callback()
      }
      yield* fetchOrdersSaga()
      yield* fetchChildrenAttendancesSaga({
        payload: {
          month: dayjs(data?.dates?.[0]).format('M'),
        },
      })
    } else {
      yield put(addPaidAttendanceError())
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    message.error("Le délai est dépassé ou il n'y a plus de place disponible")
    yield put(addPaidAttendanceError())
  }
}

function* removePaidAttendanceSaga({
  payload: { attendanceAlias, month },
}) {
  try {
    yield* checkJwtExpiration()
    yield put(removePaidAttendanceProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.post(
        `users/${authUserInfos.email}/orders/attendance/${attendanceAlias}/delete/`,
      )

      yield put(removePaidAttendanceSuccess(response.data.results))
      yield* fetchOrdersSaga()
      yield* fetchChildrenAttendancesSaga({
        payload: {
          month,
        },
      })
    } else {
      yield put(removePaidAttendanceError())
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    message.error('Erreur lors de la suppression de la réservation')
    yield put(removePaidAttendanceError())
  }
}

function* massDeleteAttendancesSaga({
  payload: { attendances,container_alias,children_alias,month },
}) {
  try {
    yield* checkJwtExpiration()
    yield put(massDeleteAttendancesProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.post(
        `users/${authUserInfos.email}/attendances/massDelete/`,
        {attendances,container_alias,children_alias},
      )

      const responseData = response?.data

      yield put(massDeleteAttendancesSuccess(response.data.results))
      yield* fetchOrdersSaga()

      if (responseData?.deleted_attendances?.length) {
        yield put(
          createRecurrentAttendanceSuccess({
            attendances: [],
            deleted_attendances: responseData?.deleted_attendances,
          }),
        )
      }

      if (responseData?.msgs?.length === 1) {
        message.success(responseData?.msgs[0], 10)

        return
      }

      if (responseData?.errors?.length === 1) {
        message.error(responseData?.errors[0], 10)

        return
      }

      if (responseData?.errors?.length > 1 || responseData?.msgs?.length > 1) {
        message.success(responseData?.msg, 10)

        return
      }
      // yield* fetchChildrenAttendancesSaga({
      //   payload: {
      //     month,
      //   }
      // })
    } else {
      yield put(massDeleteAttendancesError())
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    message.error('Erreur lors de la suppression de la réservation')
    yield put(massDeleteAttendancesError())
  }
}

function* rootSaga() {
  yield all([
    takeLatest(fetchCartPrices, fetchCartPricesSaga),
    takeLatest(fetchPayments, fetchPaymentsSaga),
    takeLatest(fetchOrders, fetchOrdersSaga),
    takeLatest(fetchAllOrders, fetchAllOrdersSaga),
    takeLatest(fetchOrderDetails, fetchOrderDetailsSaga),
    takeLatest(validateOrder, validateOrderSaga),
    takeLatest(deleteOrder, deleteOrderSaga),
    takeLatest(payOrder, payOrderSaga),
    takeLatest(addPaidAttendance, addPaidAttendanceSaga),
    takeLatest(removePaidAttendance, removePaidAttendanceSaga),
    takeLatest(massDeleteAttendances, massDeleteAttendancesSaga),
  ])
}

export default rootSaga
