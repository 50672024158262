import { gql } from "@apollo/client";

export const ORDERS = gql`
  query (    
    $first: Int
    $skip: Int
    $sort: String
    $search: String 
    ) {
    orders(
      search: $search
      skip: $skip
      first: $first
      sort: $sort
    ){
      count
      page
      pages
      data {
        id
        alias 
        date
        createdAt
        status {
          key
          value
        }
        attendances {
          alias
          amount
          registration {
            child {
              alias
              firstName
              lastName
            }
          }
          childName
          childAlias
        }
        credits
        adults {
          firstName
          lastName
        }
        household{
          organisation{
            name
          }
        }
        expirationDuration
        expiresAt
        expiresAtLabel
        totalAmount
        totalAmountIncludingCredits
        totalAmountPlusCreds
        relatedPayments {
          alias
          amount
          method
          methodDetails {
            key
            value
          }
        }
      }
    }
  }
`;

export const BILLS = gql`
  query (
    $first: Int
    $skip: Int
    $search: String
    $sort: [String]
  ) {
    bills (
      first: $first
      skip: $skip
      search: $search
      sort: $sort
    ) {
      count
      page
      pages
      data {
        id
        train {
          alias
          startDate
          endDate
          name
        }
        billNumber
        type {
          key
          value
        }
        status {
          key
          value
        }
        creditUsedInBills {
          billNumber
          issueDate
          totalAmount
          amount
        }
        billStatus {
          paymentMethod
          escalation
          receiptNumber
          remainingAmount
          pendingSurplus
          amountPaid
          billIsPaid
        }
        pdfUrl
        url
        dueDate
        issueDate
        totalAmount
        relatedPayments {
          alias
          amount
          method
          date
          creditNumber
          paymentResult
          methodDetails {
            key
            value
          }
        }
        household {
          name
          alias
          organisation {
            id
            alias
            name
          }
          adults{
            user{
              email
            }
          }
        }
      }
    }
  }
`;

/* @ChD 20240913 ... non utilisé
export const AVAILABLE_ORGANISATIONS = gql`
  query (    
    $childAlias: UUID
    ) {
        query {
            availableOrganisations(childAlias:$childAlias){
                organisation {
                    name
                    canSubscribe
                    address 
                    photo
                    email
                }
               activities{
                   alias
                   canSubscribe
                   parentActivity {
                       name
                   }
                   building {
                       alias
                       name
                       address
                   }
               }
               parentActivities {
                   name
                   startDate
                   endDate
                   startTime
                   endTime
                   photo
                   canSubscribe
                   
               }
            }
        }
  }
`;
*/

export const ORGANISATION_PARENT_AVAILABLE_ACTIVITIES = gql`
  query (
    $orgAlias: UUID!
  ) {
    organisationParentAvailableActivities(
      orgAlias: $orgAlias
    ){
      parentActivity {
        alias
        name
        startDate
        endDate
        startTime
        endTime
        activityType {
          key
          value
        }
        buildingChoice {
          key
          value
        }
        periodSelection {
          key
          value
        }
        organisation {
          alias
        }
      }
      isSubscribale
    }
  }
`;

export const MY_FEEDS = gql`
  query (
    $first: Int
    $skip: Int
  ) {
    myChildren {
      alias
    }
    myHousehold {
      organisation {
        alias
      }
    }
    myFeedsPaginated (
      first: $first
      skip: $skip
    ) {
      count
      page
      pages
      data {
        alias
        feedType
        feedTypeBis
        organisation {
          alias
          name
        }
        title
        modifiedAt
        photo
        content
        activity {
          alias
          name
        }
        child {
          alias
          firstName
          lastName
          privateMissingDocuments
          registrationSet {
            alias
            organisation {
              alias
            }
          }
        }
        renewalStatus
        status
      }
    }
  }
`;

export const MY_HOUSEHOLD = gql`
  query {
    myHousehold {
      alias
      cafNumber
      monthlyRevenue
      commonMissingDocuments
      organisation {
        alias
        name
        photo
      }
      address {
        formattedAddress
        country
        locality
        postalCode
        route
        streetNumber
      }
      children {
        alias
        firstName
        lastName
        dateOfBirth
        photo
        photoUrl
        photoUiUrl
        schoolclass {
          name
        }
        school {
          name
        }
        classtype {
          key
          value
        }
        gender {
          key
          value
        }
      }
      adults {
        user {
          firstName
          lastName
          email
          profile {
            alias
            photo
            photoUiUrl
            phone
            mobile
            civility
            status
          }
        }
      }
    }
  }
`;

export const ACTIVITY_DETAILS = gql`
  query (
    $alias: UUID
  ) {
    myHousehold {
      isExternal
    }

    activity (
      alias: $alias
    ) {
      alias
      name
      activityType {
        key
        value
      }
      startDate
      startTime
      endDate
      endTime
      description
      photo
      email
      phone
      buildingChoice {
        key
        value
      }
      organisation {
        alias
        name
        photo
      }
      periodSelection {
        key
        value
      }
      activities {
        alias
        building {
          name
          alias
        }
      }
      buildings {
        alias
        name
      }
      pricePlan {
        alias
        name
        billingModel
        priceplanitemSet {
          category {
            name
            minValue
            maxValue
          }
          price
          pricingModelType
          pricingModelDetails
        }
        timeUnit
        period
        billingPeriod
      }
      pricePlanForExternal {
        alias
        name
        billingModel {
          key
          value
        }
        priceplanitemSet {
          category {
            name
            minValue
            maxValue
          }
          price
          pricingModelType
          pricingModelDetails
        }
        timeUnit
        period
        billingPeriod
      }
    }
  }
`;