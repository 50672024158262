import { takeLatest, put, select, all, call } from "redux-saga/effects";
import axios from "~/lib/axios";

import {
  activitiesFetching,
  activitiesFetched,
  activitiesFetchError,
  activityDetailsFetching,
  activityDetailsFetched,
  activityDetailsFetchError,

  organisationsActivitiesFetching,
  organisationsActivitiesFetched,
  organisationsActivitiesFetchError,

  activitySlotsFetching,
  activityAllSlotsFetching,
  activitySlotsFetched,
  activitySlotsFetchError,
  subscriptionCreating,
  subscriptionCreated,
  subscriptionCreateError,
  subscriptionUpdating,
  subscriptionUpdated,
  subscriptionUpdateError,
} from "./actions";
import { checkJwtExpiration } from "~/containers/User/sagas";
import { logout, checkJwtProcess } from "~/containers/User/actions";
import { getAccessToken, getAuthUserInfos } from "~/containers/User/selectors";
import * as api from "./api";
import { fetchSelectedChildrenDetailsSaga } from "~/containers/Children/sagas";
import { getChildren } from "~/containers/Children/selectors";

function* fetchActivities({ payload: { organisationAlias } }) {
  
  try {
    yield put(checkJwtProcess());
    const accessToken = yield select(getAccessToken);
    if (!accessToken) {
      yield put(activitiesFetchError());
      yield put(logout());
    }
    
    let res = yield axios.get(
      `organisations/${organisationAlias}/parent_activities/`
    );

    yield put(activitiesFetched(res.data.results));
  } catch (err) {
    console.log(err);
    yield put(activitiesFetchError());
  }
}

function* fetchActivityDetails({
  payload: { organisationAlias, activityAlias },
}) {
  try {
    yield put(checkJwtProcess());
    const accessToken = yield select(getAccessToken);
    if (!accessToken) {
      yield put(activityDetailsFetchError());
      yield put(logout());
    }
    const response = yield axios.get(
      `organisations/${organisationAlias}/parent_activities/${activityAlias}/`
    );
    yield put(activityDetailsFetched(response.data));
  } catch (err) {
    console.log(err);
    yield put(activityDetailsFetchError());
  }
}

export function* fetchOrganisationsActivities() {
  try {
    yield put(checkJwtProcess());
    const accessToken = yield select(getAccessToken);
    const userInfos = yield select(getAuthUserInfos);

    if (!accessToken || !userInfos.email) {
      yield put(organisationsActivitiesFetchError());
      yield put(logout());
    }
    
    // const results = {}
  
    // if (Array.isArray(organisationsAlias)) {
    //   for (let i = 0; i < organisationsAlias.length; i += 1) {
    //     const response = yield axios.get(
    //       `organisations/${organisationsAlias[i]}/parent_activities/`
    //     )

    //     results[organisationsAlias[i]] = response.data.results
    //   }
    // }
    const results = yield axios.get(
      `users/${userInfos.email}/parent_activities`
    );

    const filteredResults = Object.keys(results.data).reduce((acc, cur) => {
      acc[cur] = [...(acc[cur] || []), ...results.data?.[cur]?.filter(act => act.status !== 4)];

      return acc;
    }, {});
    
    yield put(organisationsActivitiesFetched(filteredResults));
  } catch (err) {
    console.log(err);
    yield put(organisationsActivitiesFetchError());
  }
}

function* fetchActivitySlots({
  payload: { organisationAlias, activityAlias, buildingAlias },
}) {
  try {
    yield put(checkJwtProcess());
    const accessToken = yield select(getAccessToken);
    if (!accessToken) {
      yield put(activitySlotsFetchError());
      yield put(logout());
    }
    const response = yield axios.get(
      `organisations/${organisationAlias}/parent_activities/${activityAlias}/activities/?building=${buildingAlias}`
    );
    yield put(activitySlotsFetched(response.data));
  } catch (err) {
    console.log(err);
    yield put(activitySlotsFetchError());
  }
}

function* fetchAllActivitySlots({
  payload: { organisationAlias, activityAlias },
}) {
  try {
    yield put(checkJwtProcess());
    const accessToken = yield select(getAccessToken);
    if (!accessToken) {
      yield put(activitySlotsFetchError());
      yield put(logout());
    }
    const response = yield axios.get(
      `organisations/${organisationAlias}/parent_activities/${activityAlias}/activities/`
    );
    yield put(activitySlotsFetched(response.data));
  } catch (err) {
    console.log(err);
    yield put(activitySlotsFetchError());
  }
}

export function* createSubscription({
  payload: { childAlias, data, callback },
}) {
  try {
    yield put(checkJwtProcess());
    const userInfos = yield select(getAuthUserInfos);
    if (!userInfos.email) {
      yield put(subscriptionCreateError());
      yield put(logout());
      return;
    }
    const response = yield call(api.createSubscription, {
      data,
      childAlias,
      userInfos,
    });
    if (typeof callback === "function") callback(response.data);
    yield put(subscriptionCreated());

    const children = yield select(getChildren);
    const childrenDetailsUrls = children.map((child) => child.url);

    console.log({childrenDetailsUrls});
    yield* fetchOrganisationsActivities();
    yield* fetchSelectedChildrenDetailsSaga({
      payload: {
        data: childrenDetailsUrls,
      }
    });
  } catch (err) {
    if (typeof callback === "function") callback(null);
    console.log(err);
    yield put(subscriptionCreateError());
  }
}

export function* updateSubscription({
  payload: { alias, childAlias, data, callback },
}) {
  try {
    yield put(checkJwtProcess());
    const userInfos = yield select(getAuthUserInfos);
    if (!userInfos.email) {
      yield put(subscriptionUpdateError());
      yield put(logout());
      return;
    }
    const response = yield call(api.updateSubscription, {
      alias,
      data,
      childAlias,
      userInfos,
    });
    if (typeof callback === "function") callback(response.data);
    yield put(subscriptionUpdated());
  } catch (err) {
    if (typeof callback === "function") callback(null);
    console.log(err);
    yield put(subscriptionUpdateError());
  }
}

function* rootSaga() {
  yield all([
    takeLatest(activitiesFetching, fetchActivities),
    takeLatest(activityDetailsFetching, fetchActivityDetails),
    takeLatest(organisationsActivitiesFetching, fetchOrganisationsActivities),
    takeLatest(activitySlotsFetching, fetchActivitySlots),
    takeLatest(activityAllSlotsFetching, fetchAllActivitySlots),
    takeLatest(subscriptionCreating, createSubscription),
    takeLatest(subscriptionUpdating, updateSubscription),
    takeLatest(checkJwtProcess, checkJwtExpiration),
  ]);
}

export default rootSaga;
