import { PlusOutlined } from "@ant-design/icons"
import { Button } from "antd"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Container from '~/components/common/Container'
import FilterContainer from '~/components/common/FilterContainer'
import Loading from '~/components/common/Loading'
import Space from '~/components/common/Space'
import Table from '~/components/common/Table'
//~ import axios from '~/lib/axios'
import { getCivility } from "~/constants"
import { deleteContact, fetchContacts } from "./actions"
import AddContact from "./AddContactDrawer"

//~ import {
  //~ useColumns,
//~ } from './columns'

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    
  },
}

const sortFields = {
  profil: 'child__last_name',
  households: 'billinghouseholdregistrations__household__adult_members_set__user__last_name',
  school_class: 'schoolClass__name',
  status: 'status',
}

const Contacts = () => {
  //~ 
  const { contacts, isFetchingContacts } = useSelector((state) => state.contacts)
  const { accessToken } = useSelector((state) => state.user.auth)
  const { t } = useTranslation(["common"])
  
  const [contact, setContact] = useState(null)
  const [statusFilter, setStatusFilter] = useState(null)
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [selectionType] = useState('checkbox')
  const [, setRowSelection] = useState([])
  const [sort, setSort] = useState(sortFields['profil'])
  const [showDrawer, setShowDrawer] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onStatusChange = () => {
    console.log("reload data")
  }

  useEffect(() => {
    dispatch(fetchContacts())
  }, [])
/*
  const columns = useColumns(
    onStatusChange,
    setShowDrawer
  );
*/  

  const onDeleteChange = (contact) => {
    //
    let flag =  window.confirm("Voulez-vous vraiment suppimer '"+contact.first_name+" "+contact.last_name+"' ?")
    
    if (flag) {
      dispatch(deleteContact(contact.alias))
      //~ history.push(`/foyer/contacts/?t=`+new Date());
      dispatch(fetchContacts())
    }
  }

  const columns = [
    {
      title: "Civilité",
      dataIndex: "civilityLabel",
      key: "civilityLabel",
    },
    {
      title: "Relation",
      dataIndex: "relationshipLabel",
      key: "relationshipLabel",
    },
    {
      title: "Prénom",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Nom",
      dataIndex: "last_name",
      key: "last_name",
    },
    // {
    //   title: "Fixe",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <div>
          <Button type="link" onClick={() => {setContact(record); setShowDrawer(true)}}>
            Éditer
          </Button>
          <Button type="link" onClick={() => {onDeleteChange(record)}}>
            Supprimer
          </Button>
        </div>
      ),
    },
  ]

  const onTableChange = useCallback(
    (pagination, _, sorter, { action }) => {
      if (action === 'paginate') {
        setPage(pagination.current)
      }
      if (action === 'sort') {
        const field = sortFields[sorter.field]
        setSort(
          sorter.order === 'descend' ? `-${field}` : field,
        )
      }
    },
    [],
  )

  const getformatedContacts = useCallback(() => {
    let data = []

    console.log({contacts})
    if (contacts) {
      data = contacts.map(
        contact => ({
          alias: contact.alias,
          first_name: contact.first_name,
          last_name: contact.last_name,
          mobile: contact.mobile,
          phone: contact.phone,
          email: contact.email,
          address: contact.address,
          relationship: contact.relationship?.key,
          relationshipLabel: contact.relationship?.value,
          civility: contact.civility,
          civilityLabel: getCivility(contact.civility)?.label,
        })
      )

      return data
    } else {
      return data
    }
  }, [contacts, accessToken])

  if (isFetchingContacts) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  return (
    <>
      <Container>
        <Space
          direction="vertical"
          size="large"
        >
          <FilterContainer

            buttonsAction={() => (
              <Space $toright>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setShowDrawer(true)}
                >
                  Ajouter un contact
                </Button>
              </Space>
            )}
            defaultPageSize={pageSize}
            onPageSizeChange={setPageSize}
            onSearch={setSearch}

          />
          <Table
            columns={columns}
            dataSource={getformatedContacts()}
            // rowSelection={{
            //   type: selectionType,
            //   ...rowSelection,
            // }}
            // onRowSelectionChange={setRowSelection}
            // isLoading={isLoading}
            onChange={onTableChange}
            total={0} // length
            currentPage={page}
            pageSize={pageSize}
          />
        </Space>
      </Container>
      <AddContact
        contact={contact}
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        setContact={setContact}
      />
    </>
  )
}

export default Contacts