export default [
  {
    code: 0,
    label: 'Gluten',
  },
  {
    code: 1,
    label: 'Crustacés',
  },
  {
    code: 2,
    label: 'Oeufs',
  },
  {
    code: 3,
    label: 'Poissons',
  },
  {
    code: 4,
    label: 'Soja',
  },
  {
    code: 5,
    label: 'Lait',
  },
  {
    code: 6,
    label: 'Fruits à coques',
  },
  {
    code: 7,
    label: 'Céleri',
  },
  {
    code: 8,
    label: 'Moutarde',
  },
  {
    code: 9,
    label: 'Graines de sésame',
  },
  {
    code: 10,
    label: 'Anhydrcodee sulfureux',
  },
  {
    code: 11,
    label: 'Lupin',
  },
  {
    code: 12,
    label: 'Mollusques',
  },
]
