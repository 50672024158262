import { createActions } from 'redux-actions'

export const {
  householdFetching,
  householdFetched,
  householdFetchError,

  // update household
  householdUpdating,
  householdUpdated,
  householdUpdateError,
  
  // fetch documents
  documentsFetching,
  documentsFetched,
  documentsFetchError,

  // fetch missing common documents
  missingCommonDocumentsFetching,
  missingCommonDocumentsFetched,
  missingCommonDocumentsFetchError,

  // add new document
  addNewDocument,
  addNewDocumentProcess,
  addNewDocumentSuccess,
  addNewDocumentError,
} = createActions({
  // fetch household
  HOUSEHOLD_FETCHING: () => ({}),
  HOUSEHOLD_FETCHED: data => data,
  HOUSEHOLD_FETCH_ERROR: () => ({}),
  
  // update household
  HOUSEHOLD_UPDATING: data => data,
  HOUSEHOLD_UPDATED: data => data,
  HOUSEHOLD_UPDATE_ERROR: () => {},

  // fetch documents
  DOCUMENTS_FETCHING: () => ({}),
  DOCUMENTS_FETCHED: data => data,
  DOCUMENTS_FETCH_ERROR: () => ({}),

  // fetch missing documents
  MISSING_COMMON_DOCUMENTS_FETCHING: data => (data),
  MISSING_COMMON_DOCUMENTS_FETCHED: data => data,
  MISSING_COMMON_DOCUMENTS_FETCH_ERROR: () => ({}),

  // add new document
  ADD_NEW_DOCUMENT: user => (user),
  ADD_NEW_DOCUMENT_PROCESS: () => {},
  ADD_NEW_DOCUMENT_SUCCESS: data => data,
  ADD_NEW_DOCUMENT_ERROR: () => {},
})
