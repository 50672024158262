export const colors = {
  blue: "#2F4580",
  lightblue: "#9aa8c1",
  deepblue: "#2A3D72",
  green: "#20CD8D",

  cvGreen: "#24DD98",
  cvLightGreen: "#a8ffde",
  cvLime: "#B7EB8F",
  cvGreenText: "#20CD8D",

  cvBlue: "#2F4580",
  cvLightBlue: "#5885FC",
  cvDeepBlue: "#2A3D72",
  cvBlueText: "#2F4580",
  cvBlueTextLight: "#9aa3bd",
  blueBackground: "#2e4382",

  cvBlueGrey: "#6D7F9D",
  cvBlueGreyLight: "#cad0dd",
  cvBlueGreyLighter: "#E2E4E6",
  cvBlueGreyUltraLight: "#F4F5F8",

  cvRed: "#FF5876",
  cvLightRed: "#fe9cae",

  cvPurple: "#722ED1",
  cvLightPurple: "#D3ADF7",

  cvbackground: "#E5E5E5",
  globalBackground: "#F1F3F6",

  cvOrange: "#FFB24D",
  cvLightOrange: "#fddda8",

  cvBluePink: "#80AEFF",

  cvLightGrey: "#DADFE6",
  cvGrey: "#6D7F9D",

  // new colors

  //BLUE
  darkBlue: "#2F4580",
  lightBlue: "#CCDAFF",
  //GREEN
  darkGreen: "#20CD8D",
  lightGreen: "#C7F2E2",
  //GREY
  darkGrey: "#6D7F9D",
  lightGrey: "#DADFE6",
  // ORANGE
  lightOrange: "#FFE4B7",
  darkOrange: "#F4C26D",
  // RED
  lightRed: "#FFCDD6",
  darkRed: "#FF5876",
  cvYellow: "#FCFC03",
  cvBluePill: "#1890FF",
};
