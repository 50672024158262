import styled from 'styled-components'

export const Container = styled.span`
  display: flex;
  flex-direction: row;
  .pay{
    display: inline-block;
    padding-left: 5px;
    margin-left: 5px;
    border-left: solid 1px @grey-color
  }
`
