import axios from '~/lib/axios'

export const createSubscription = async ({ childAlias, userInfos, data }) => axios.post(`users/${userInfos.email}/children/${childAlias}/subscriptions/`, {
  ...data,
})
export const updateSubscription = async ({
  alias, childAlias, userInfos, data,
}) => axios.patch(`users/${userInfos.email}/children/${childAlias}/subscriptions/${alias}/`, {
  ...data,
})
export default {
  createSubscription,
  updateSubscription,
}
