import React, { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Drawer from "~/components/common/Drawer";
import { Row, Col, Space } from "antd";
import {
  Form,
  Select,
  Input,
  Radio,
  Switch,
  InputNumber,
  Divider,
  Button,
  Upload,
  message,
  DatePicker,
  ConfigProvider,
} from "antd";
import Gender from "~/components/common/Gender";
import CustomProfilAvatar from "~/components/common/CustomProfilAvatar";
import Search from "~/components/common/Search";

import { childStatus, parentMaritalStatus, allergies } from "~/constants";

import useErrors from "~/hooks/useErrors";
import Checkbox from "antd/lib/checkbox/Checkbox";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import locale from "antd/lib/locale/fr_FR";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

const SubActivity = ({ data, showDrawer, setShowDrawer }) => {
  const [form] = Form.useForm();
  const [selected, setSelected] = useState([]);

  let initialState = {};

  const onFinish = (values) => {
    
  };

  return (
    <Drawer
      title="Inscrire à une activité"
      open={showDrawer}
      handleCloseDrawer={() => setShowDrawer(false)}
      width={636}
    >
      <h3>
        <ExclamationCircleOutlined
          style={{ fontSize: 20, paddingRight: 10, color: "#F4C26D" }}
        />{" "}
        {data?.organisation_name} / {data?.parent_activity_name}
      </h3>
      <Form
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
        initialValues={initialState}
      >
        <Form.Item
          label="Enfant(s)"
          name="households_alias"
          rules={[
            {
              required: false,
              message: "Veuillez renseigner un foyer",
            },
          ]}
        >
          <Search
            placeholder="Rechercher..."
            options={[]}
            mode="multiple"
            defaultValue={selected}
            value={selected}
            onChange={setSelected}
          />
        </Form.Item>
        <Form.Item>
          <Space direction="horizontal">
            <Switch defaultChecked /> A partir de maintenant
          </Space>
        </Form.Item>
        <Form.Item>
          <Space direction="horizontal">
            <Switch defaultChecked /> Choisir une période
          </Space>
        </Form.Item>
        <Form.Item label="Période" name="dates">
          <ConfigProvider locale={locale}>
            <RangePicker></RangePicker>
          </ConfigProvider>
        </Form.Item>
        <Form.Item
          label="Jours de présence"
          name={["registration", "parents_marital_status"]}
          rules={[
            {
              required: false,
              // message: "Veuillez renseigner le Statut marital",
            },
          ]}
        >
          {/* <Radio.Group> */}
          <Space>
            {/* {parentMaritalStatus.map(({ label, value }, i) => ( */}
            <Radio.Group onChange={() => {}} defaultValue={["a", "b"]}>
              <Radio.Button>Lundi</Radio.Button>
            </Radio.Group>
            <Radio.Group onChange={() => {}} defaultValue={["a", "b"]}>
              <Radio.Button>Mardi</Radio.Button>
            </Radio.Group>
            <Radio.Group onChange={() => {}} defaultValue={["a", "b"]}>
              <Radio.Button>Mercredi</Radio.Button>
            </Radio.Group>
            <Radio.Group onChange={() => {}} defaultValue={["a", "b"]}>
              <Radio.Button>Jeudi</Radio.Button>
            </Radio.Group>
            <Radio.Group onChange={() => {}} defaultValue={["a", "b"]}>
              <Radio.Button>Vendredi</Radio.Button>
            </Radio.Group>
            {/* ))} */}
          </Space>
          {/* </Radio.Group> */}
        </Form.Item>
        <Form.Item>
          <Space direction="horizontal">
            <Switch defaultChecked /> Ajouter les nouvelles réservations et
            garder les existantes
          </Space>
        </Form.Item>
        <Form.Item>
          <Space direction="horizontal">
            <Switch defaultChecked /> Remplacer les réservations existantes
            (sauf celles déjà facturées)
          </Space>
        </Form.Item>
      </Form>
      <Button
        type="primary"
        htmlType="submit"
        size="large"
        style={{ width: "100%", marginTop: 20, marginBottom: 50 }}
        onClick={() => setShowDrawer(false)}
      >
        Enregistrer
      </Button>
    </Drawer>
  );
};

export default SubActivity;
