import React, { useCallback, useState } from 'react'
import {
  Button,
  Divider,
  Form,
  Input,
  Typography,
  message
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { forgotPassword } from '~/containers/User/actions'

import Link from '~/components/common/Link'
import P from '~/components/common/P'
// import Logo from '~/components/common/Logo'
import LogoSrc from '~/assets/images/logo.png'
import Title from '~/components/auth/Title/index'

import Logo from '~/components/common/Logo/index'
import Centered from '~/components/common/Centered/index'
import Space from '~/components/common/Space/index'
import Layout from '~/components/auth/Layout'
import Container from '~/components/auth/Container/index'

import { ForgottenCard } from '../components'
import { FORGOT_PASSWORD } from '~/graphql/mutations'
import { anonymousApolloClient } from '~/lib/apolloClient'
import { useNavigate } from 'react-router-dom'
import config from "../../../config";
// https://github.com/jsardev/reaptcha
import Reaptcha from 'reaptcha';

const { Text } = Typography

const FormItem = Form.Item

const BackLink = styled(Link)`
  color: #646464;
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    text-decoration: none;
    color: @neutral-5;
  }
`

const StyledReaptcha = styled(Reaptcha)`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ForgottenPassword = (props) => {
  const { location } = props
  const [form] = Form.useForm()
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false)
  const navigate = useNavigate()
  const [isVerified, setIsVerified] = useState(false);

  const forgotPassword = async (variables) => {
    console.log({variables})
    try {
      setForgotPasswordLoading(true)
      const response = await anonymousApolloClient.mutate({
        mutation: FORGOT_PASSWORD,
        variables,
      })

      console.log({response})

      if (response?.data?.forgotPassword?.status === 200) {
        message.success(
          response?.data?.forgotPassword?.msg || "Un email vous a été envoyé avec le nouveau mot de passe.",
        )
        setForgotPasswordLoading(false)
        navigate('/login')
      } else {
        setForgotPasswordLoading(false)
        message.error(
          "Erreur lors de la réinitialisation de votre mot de passe"
        )
      }
    } catch (error) {
      console.log({errorjs: JSON.stringify(error, null, 2)})
      setForgotPasswordLoading(false)
      message.error(
        "Erreur lors de la réinitialisation de votre mot de passe"
      )
    }
  }

  const handleSubmit = useCallback(
    ({ email }) => {
      forgotPassword({ email })
    },
    [],
  )

  const onRecaptchaChange = useCallback(
    (value) => {
      console.log({ value });
      if (value) {
        setIsVerified(true);
      }
    },
    [setIsVerified]
  );

  return (
      <Layout>
        <Container>
          <Space
            direction="vertical"
            size="large"
          >
            <Centered>
              <Logo
                src="/images/common/logoedited.png"
                width="175"
                height="56"
              />
            </Centered>
            <Centered>
              <Title>
                Vous avez oublié votre mot de passe ?
            </Title>
            </Centered>
            <Centered>
              <Text
                align="center"
                style={{fontSize:"1.2em"}}
              >
                ... 🙂 nous allons vous aider
            </Text>
            </Centered>
            {/* <ErrorComponent /> */}
            <Form
              layout="vertical"
              size="large"
              form={form}
              onFinish={handleSubmit}
            >
                <StyledReaptcha
                  sitekey={config.app.recaptchaSiteKeyResetPassword}
                  onVerify={onRecaptchaChange}
                />
              <Form.Item
                label="👉 saisissez l'adresse email de votre compte"
                name="email"
                initialValue={location?.state?.username ?? window.localStorage.getItem('email') ?? ''}
                rules={[
                  {
                    required: true,
                    message: '🥲 veuillez saisir une adresse email',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={forgotPasswordLoading}
                block
                disabled={!isVerified}
              >
                Réinitialiser votre mot de passe
            </Button>
            </Form>
            <Centered>
              <BackLink
                to="/login"
              >
                Retour à la page de connexion
            </BackLink>
            </Centered>
          </Space>
        </Container>
      </Layout>
  )
}

export default ForgottenPassword
