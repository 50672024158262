
import { logout } from '~/containers/User/actions'
import { handleActions } from '~/utils/redux-actions'
import {
  fetchSepaError, fetchSepaProcess,
  fetchSepaSuccess
} from './actions'

const defaultState = {
  isFetchingSepa: false,
  fetchError: false,
  fetchSuccess: false,

  isFetchingOrgs: false,
  fetchError: false,
  fetchSuccess: false,

  creatingError: false,
  isCreating: false,

  sepa: [],
}

const SepaReducer = handleActions(
  {
    // Logout
    [logout]: () => defaultState,

    [fetchSepaProcess]: state => {
      state.isFetchingSepa = true
      state.fetchError = false
      state.fetchSuccess = false
    },
    [fetchSepaError]: state => {
      state.isFetchingSepa = false
      state.fetchError = true
      state.fetchSuccess = false
    },
    [fetchSepaSuccess]: (state, { payload }) => {
      state.isFetchingSepa = false
      state.fetchError = false
      state.fetchSuccess = true
      state.sepa = payload
	
    },
  },
  defaultState,
)

export default SepaReducer
//~ export {SepaReducer, OrgsReducer}
//~ export default OrgsReducer
//~ export default 