import React from 'react'
import PropTypes from 'prop-types'
import Image from '~/components/common/Image'
import MairieSVG from '~/assets/images/mairie.svg'
import {
  Location,
  LocationContainer,
  LocationAddress,
  LocationName,
} from './index'

const LocationComponent = ({ location, onChoose }) => (
  <LocationContainer onClick={onChoose}>
    <Image src={MairieSVG} alt="" />
    <Location>
      <LocationName bold regular>{location.name}</LocationName>
      <LocationAddress>{location.address}, {location.zip_code} {location.city}</LocationAddress>
    </Location>
  </LocationContainer>
)

LocationComponent.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onChoose: PropTypes.func.isRequired,
}

export default LocationComponent
