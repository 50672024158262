import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import config from '~/config'
import authToken from '~/utils/Auth'
const apiUrl = () => {
  const storedApiUrl = window.localStorage.getItem("api");
  const listOfApiUrls = config.api.devUrls;
  const baseUrl = process.env.REACT_APP_API_URL;

  if (!config.app.env || config.app.env === "prod") {
    return baseUrl;
  } else if (storedApiUrl) {
    return storedApiUrl;
  } else if (listOfApiUrls?.[0]) {
    return listOfApiUrls?.[0];
  } else {
    return baseUrl;
  }
};

const httpLink = createHttpLink({
  uri: new URL(config.graphql.entry, apiUrl()).href,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = authToken.getToken();
  console.log("TOKEEEN ",token)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `JWT ${token}` : "",
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default apolloClient;

export const anonymousApolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});
