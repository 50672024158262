import dayjs from "dayjs"

export const getFeedMessages = (state) =>
  state.home.feed.feedList.results.filter(
    (item) => !["registration", "subscription"].includes(item.feedType)
  )

export const getFeedMessagesCount = (state) => state.home.feed.feedList.count

export const getFeed = (state) => state.home.feed.feedList.results
export const getFeedRegistrations = (state) => {
  
  state.home.feed.feedList.results.filter(
    (item) => item.feedType === "registration"
  )
}

export const getUserOrganisations = (state) =>
  state.home.organisation.userOrganisationsList

export const getMyCityHall = (state) =>
  state.home.organisation.userOrganisationsList.length > 0
    ? state.home.organisation.userOrganisationsList[0]
    : null

export const getMyActions = (state) =>
  state.home.feed.feedList.results.filter((item) =>
    ["registration", "subscription"].includes(item.feedType)
  )

export const getLastPaidBill = (state) => {
  const filtred = state.home.bill.userBillsList.filter(
    (item) => item.bill_status.bill_is_paid
  )
  if (filtred.length === 0) return null
  return filtred.reduce((a, b) =>
    dayjs(a.due_date).isAfter(b.due_date) ? a : b
  )
}

export const getNextUnpaidBill = (state) => {
  const today = dayjs()
  const filtred = state.home.bill.userBillsList.filter(
    (item) => !item.bill_status.bill_is_paid
  )
  if (filtred.length === 0) return null
  const obj = filtred.reduce((a, b) => {
    if (dayjs(a.due_date).isBefore(today)) return b
    if (dayjs(b.due_date).isBefore(today)) return a
    return dayjs(a.due_date).isBefore(b.due_date) ? a : b
  })

  if (dayjs(obj.due_date).isBefore(today)) return null

  return obj
}
