import "dayjs/locale/fr";
import { colors } from "~/utils/colors";
import weekday from "dayjs/plugin/weekday";
import dayjs from "dayjs";

dayjs.extend(weekday);

export const DocumentTypes = [
  {
    code: "0",
    label: "Pièce d'identité",
    common: false,
  },
  {
    code: "1",
    label: "Livret de famille",
    common: false,
  },
  {
    code: "2",
    label: "Justificatif de domicile",
    common: true,
  },
  {
    code: "3",
    label: "Carnet de santé",
    common: false,
  },
  {
    code: "4",
    label: "Avis d'impôts",
    common: true,
  },
  {
    code: "5",
    label: "Attestation CAF",
    common: true,
  },
  {
    code: "6",
    label: "Jugement de divorce",
    common: false,
  },
  {
    code: "7",
    label: "Fiche sanitaire",
    common: false,
  },
  {
    code: "8",
    label: "Attestation d'assurance",
    common: false,
  },
  {
    code: "9",
    label: "Fiche d'inscription",
    common: false,
  },
];

export const getDocumentType = (type) =>
  DocumentTypes.find((d) => Number(d.code) === Number(type));

export const contactTypes = [
  {
    label: "Parent",
    value: 0,
  },
  {
    label: "Contact Autorisé",
    value: 1,
  }
];
  
export const relationTypes = [
  {
    label: "Oncle / Tante",
    value: "PS",
  },
  {
    label: "Parrain / Marraine",
    value: "GF",
  },
  {
    label: "Frère / Sœur",
    value: "BR",
  },
  {
    label: "Grand-père / Grand-mère",
    value: "GP",
  },
  {
    label: "Nourrice",
    value: "WN",
  },
  {
    label: "Baby-sitter",
    value: "BS",
  },
  {
    label: "Ami / Amie",
    value: "FR",
  },
];

export const getRelation = (type) =>
  relationTypes.find(r => r.value === type);
  
export const civilities = [
  {
    label: "Monsieur",
    value: "0",
  },
  {
    label: "Madame",
    value: "1",
  },
  {
    label: "Mademoiselle",
    value: "2",
  }
];

export const getCivility = (type) =>
  civilities.find(c => c.value === type);
  
export const schoolLevels = [
  {
    label: "PS",
    value: 0,
  },
  {
    label: "MS",
    value: 1,
  },
  {
    label: "GS",
    value: 2,
  },
  {
    label: "CP",
    value: 3,
  },
  {
    label: "CE1",
    value: 4,
  },
  {
    label: "CE2",
    value: 5,
  },
  {
    label: "CM1",
    value: 6,
  },
  {
    label: "CM2",
    value: 7,
  },
  {
    label: "6e",
    value: 8,
  },
  {
    label: "5e",
    value: 9,
  },
  {
    label: "4e",
    value: 10,
  },
  {
    label: "3e",
    value: 11,
  },
];

export const getSchoolLevel = (value) =>
  schoolLevels.find((s) => s.value === value);

export const childStatus = [
  {
    label: "En attente",
    value: 0,
    color: "orange",
  },
  {
    label: "Validée",
    value: 1,
    color: "green",
  },
  {
    label: "Refusée",
    value: 2,
    color: "red",
  },
  {
    label: "En préparation",
    value: 3,
    color: "purple",
  },
  {
    label: "Archivée",
    value: 4,
    color: "blue",
  },
];

export const messageStatus = [
  {
    label: "Archivé",
    value: 3,
    color: "purple",
  },
  {
    label: "Brouillon",
    value: 0,
    color: "orange",
  },
  {
    label: "Publié",
    value: 1,
    color: "green",
  },
];

export const getChildStatus = (value) =>
  childStatus.find((s) => s.value === value);

export const parentStatus = [
  {
    label: "Actif",
    value: 2,
    color: "green",
  },
  {
    label: "En Attente",
    value: 0,
    color: "orange",
  },
];

export const getParentStatus = (value) =>
  parentStatus.find((s) => s.value === value);

export const householdStatus = [
  {
    label: "Créditeur",
    value: 0,
  },
  {
    label: "Débiteur",
    value: 1,
  },
];

export const parentMaritalStatus = [
  {
    label: "Marié(e)",
    value: 0,
  },
  {
    label: "Séparé(e)",
    value: 1,
  },
  {
    label: "Parent seul",
    value: 2,
  },
  {
    label: "Divorcé(e)",
    value: 3,
  },
  {
    label: "En union libre",
    value: 4,
  },
  {
    label: "Pacsés",
    value: 5,
  },
  {
    label: "Veuf(ve)",
    value: 6,
  },
];

export const incomeRange = [
  {
    label: "A",
    value: 0,
  },
  {
    label: "B",
    value: 1,
  },
  {
    label: "C",
    value: 2,
  },
  {
    label: "D",
    value: 3,
  },
  {
    label: "F",
    value: 4,
  },
  {
    label: "G",
    value: 5,
  },
];

export const weekDays = [0, 1, 2, 3, 4].map((dow) =>
  dayjs().locale("fr").weekday(dow).format("dddd")
);

export const allergies = [
  "Gluten",
  "Crustacés",
  "Oeufs",
  "Poissons",
  "Soja",
  "Lait",
  "Fruits à coques",
  "Céleri",
  "Graines de sésame",
  "Anhydride sulfureux",
  "Lupin",
  "Mollusques",
];

export const openingWeekdays = [
  {
    value: "on_monday",
    label: "lundi",
  },
  {
    value: "on_tuesday",
    label: "mardi",
  },
  {
    value: "on_wednesday",
    label: "mercredi",
  },
  {
    value: "on_thursday",
    label: "jeudi",
  },
  {
    value: "on_friday",
    label: "vendredi",
  },
];

export const relationship = [
  {
    label: "Oncle / Tante",
    value: "PS",
  },
  {
    label: "Parrain / Marraine",
    value: "GF",
  },
  {
    label: "Frère / Sœur",
    value: "BR",
  },
  {
    label: "Grand-père / Grand-mère",
    value: "GP",
  },
  {
    label: "Nourrice",
    value: "WN",
  },
  {
    label: "Baby-sitter",
    value: "BS",
  },
  {
    label: "Ami / Amie",
    value: "FR",
  },
];

export const activitiesStatus = [
  {
    label: "Disponible",
    value: 1,
    color: "green",
  },
  {
    label: "Terminée",
    value: 2,
    color: "red",
  },
  {
    label: "Archivée",
    value: 3,
    color: "blue",
  },
  {
    label: "Brouillon",
    value: 0,
    color: "purple",
  },
];

export const facturationStatus = [
  {
    label: "En cours",
    value: 1,
  },
  {
    label: "Terminée",
    value: 2,
  },
  {
    label: "Archivée",
    value: 3,
  },
];

export const foyerFacturableStatus = [
  {
    label: "Prêt à être facturé",
    value: 1,
  },
  {
    label: "À corriger",
    value: 2,
  },
];

export const foyerStatus = [
  {
    label: "Prêt à être facturé",
    value: 1,
    color: "green",
  },
  {
    label: "Grille tarifaire incomplète",
    value: 2,
    color: "orange",
  },
];

export const getActivitiesStatus = (value) =>
  activitiesStatus.find((s) => s.value === value);

export const activitiesTypes = [
  {
    value: 1,
    label: "accueil du matin",
  },
  {
    value: 2,
    label: "garderie",
  },
  {
    value: 3,
    label: "etude",
  },
  {
    value: 0,
    label: "cantine",
  },
  {
    value: 4,
    label: "stage",
  },
  {
    value: 5,
    label: "centre de loisirs",
  },
  {
    value: 6,
    label: "ramassage scolaire",
  },
  {
    value: 7,
    label: "centre jeunesse",
  },
];

export const getActivitiesTypes = (value) =>
  activitiesTypes.find((s) => s.value === value);

export const activitiesPeriodSelection = [
  {
    value: 0,
    label: "A la journée",
  },
  {
    value: 1,
    label: "A la semaine",
  },
  {
    value: 2,
    label: "Au mois",
  },
  {
    value: 3,
    label: "Au trimestre",
  },
  {
    value: 4,
    label: "Au semestre",
  },
  {
    value: 5,
    label: "A l'année scolaire",
  },
  {
    value: 6,
    label: "A l'année civile",
  },
  {
    value: 7,
    label: "toute les dates de l'activité",
  },
  {
    value: 8,
    label: "A la semaine",
  },
];

export const getActivitiesPeriodSelection = (value) =>{
  let res = activitiesPeriodSelection.find((s) => s.value === value);
  return res.label;
};  

export const activitiesPeriodSelectionActive = [
  {
    value: '00',
    label: "A la journée",
  },
  {
    value: '10',
    label: "A la semaine",
  },
  {
    value: '20',
    label: "",
  },
  {
    value: '21',
    label: "Au mois",
  },
  {
    value: '30',
    label: "",
  },
  {
    value: '31',
    label: "Au trimestre",
  },
  {
    value: '40',
    label: "",
  },
  {
    value: '41',
    label: "Au semestre",
  },
  {
    value: '50',
    label: "",
  },
  {
    value: '51',
    label: "A l'année scolaire",
  },
  {
    value: '60',
    label: "",
  },
  {
    value: '61',
    label: "A l'année civile",
  },
  {
    value: '70',
    label: "",
  },
  {
    value: '71',
    label: "toute les dates de l'activité",
  },
  {
    value: '80',
    label: "",
  },
  {
    value: '81',
    label: "A la semaine",
  },
];

export const getActivitiesPeriodSelectionActive = (value) => {
  let res = activitiesPeriodSelectionActive.find((s) => s.value === value);
  return res.label;
};

export const activitiesContainerLabel = [
  {
    value: 0,
    label: "Jour",
  },
  {
    value: 1,
    label: "",
  },
  {
    value: 2,
    label: "Mois",
  },
  {
    value: 3,
    label: "Trimestre",
  },
  {
    value: 4,
    label: "Semestre",
  },
  {
    value: 5,
    label: "Année scolaire",
  },
  {
    value: 6,
    label: "Année civile",
  },
  {
    value: 7,
    label: "toute les dates de l'activité",
  },
  {
    value: 8,
    label: "Semaine",
  },
];

export const getactivitiesContainerLabel = (value) =>{
  let res = activitiesContainerLabel.find((s) => s.value === value);
  return res.label;
};  

export const openingPeriodSelectionLabel = [
  {
    value: 0,
    label: "non utilisé",
  },
  {
    value: 1,
    label: "uniquement pour la prochaine semaine ouverte à la réservation",
  },
  {
    value: 2,
    label: "uniquement pour le prochain mois ouvert à la réservation",
  },
  {
    value: 3,
    label: "uniquement pour le prochain trimestre ouvert à la réservation",
  },
  {
    value: 4,
    label: "uniquement pour le prochain semestre ouvert à la réservation",
  },
  {
    value: 5,
    label: "uniquement pour la prochaine année scolaire ouverte à la réservation",
  },
  {
    value: 6,
    label: "uniquement pour l'année civile ouverte à la réservation",
  },
  {
    value: 7,
    label: "uniquement pour toute l'activité ouverte à la réservation",
  },
  {
    value: 8,
    label: "uniquement pour la prochaine semaine ouverte à la réservation",
  },
];

export const getOpeningPeriodSelectionLabel = (value) => {
  let res = openingPeriodSelectionLabel.find((s) => s.value === value);
  return res.label;
}; 
export const periodSelectionBadegeColor = [
  {
    value: 0,
    color: undefined,
  },
  {
    value: 1,
    color: undefined,
  },
  {
    value: 2,
    color: colors.cvYellow,
  },
  {
    value: 3,
    color: colors.cvBluePill,
  },
  {
    value: 4,
    color: colors.cvBluePill,
  },
  {
    value: 5,
    color: colors.cvBluePill,
  },
  {
    value: 6,
    color: colors.cvBluePill,
  },
  {
    value: 7,
    color: colors.cvBluePill,
  },
  {
    value: 8,
    color: colors.cvGreen,
  },
];

export const getBadgeColor = (value) => {
  let res = periodSelectionBadegeColor.find((c) => c.value === value );
  return res.color;
};
export const confirmPeriodReservationLabel = [
  {
    value: 0,
    label: "Non utilisé",
  },
  {
    value: 1,
    label: "Toute la semaine",
  },
  {
    value: 2,
    label: "Tout le mois",
  },
  {
    value: 3,
    label: "Tout le trimestre",
  },
  {
    value: 4,
    label: "Tout le semestre",
  },
  {
    value: 5,
    label: "Toute l'année scolaire",
  },
  {
    value: 6,
    label: "Toute l'année civile",
  },
  {
    value: 7,
    label: "Toute les dates de l'activité",
  },
  {
    value: 8,
    label: "Toute la semaine",
  },
];
export const getConfirmPeriodReservationLabel = (value) => {
  let res = confirmPeriodReservationLabel.find((s) => s.value === value);
  return res.label;
};

export const activitiesPricePlan = [
  {
    value: 0,
    label: "Prix fixe",
  },
  {
    value: 1,
    label: "Prix proportionnel au nombre d'enfants",
  },
  {
    value: 2,
    label: "Prix proportionnel au nombre d'enfants et au revenu",
  },
];

export const getActivitiesPricePlan = (value) =>
  activitiesPricePlan.find((s) => s.value === value);

export const allDayWeek = [
  {
    value: "on_monday",
    label: "lundi",
  },
  {
    value: "on_tuesday",
    label: "mardi",
  },
  {
    value: "on_wednesday",
    label: "mercredi",
  },
  {
    value: "on_thursday",
    label: "jeudi",
  },
  {
    value: "on_friday",
    label: "vendredi",
  },
  {
    value: "on_saturday",
    label: "samedi",
  },
  {
    value: "on_sunday",
    label: "dimanche",
  },
];

export const SubscriptionsStatus = [
  {
    label: "En Attente",
    value: 0,
    color: "orange",
  },
  {
    label: "Validée",
    value: 1,
    color: "green",
  },
  {
    label: "Refusée",
    value: 2,
    color: "red",
  },
  {
    label: "Archivée",
    value: 3,
    color: "black",
  }
];

export const registrationsStatus = [
  {
    label: "En Attente",
    value: 0,
    color: "orange",
  },
  {
    label: "Validée",
    value: 1,
    color: "green",
  },
  {
    label: "Refusée",
    value: 2,
    color: "red",
  },
  {
    label: "Brouillon",
    value: 4,
    color: "blue",
  },
  {
    label: "Archivée",
    value: 5,
    color: "black",
  }
];

export const renewRegistrationsStatus = [
  {
    label: "Brouillon",
    value: 0,
  },
  {
    label: "En Attente",
    value: 1,
  },
  {
    label: "Refusée",
    value: 2,
  },
  {
    label: "Terminée",
    value: 3,
  },
  {
    label: "Non commencé",
    value: 4,
  }
];

export const activityBuildingSelection = [
  {
    value: 0,
    label: "A la validation de l'inscription",
  },
  {
    value: 1,
    label: "Choix de l'utilisateur",
  },
  {
    value: 2,
    label: "automatique en fonction de l'école",
  },
];

export const getActivityBuildingSelection = (value) =>
  activityBuildingSelection.find((s) => s.value === value);

export const activityBillingModel = [
  {
    value: 0,
    label: "A la présence",
  },
  {
    value: 1,
    label: "A l'inscription",
  },
  {
    value: 2,
    label: "Pré-payé",
  },
];

export const pricePlanBillingModel = [
  {
    value: 0,
    label: "Tarification à la journée",
  },
  {
    value: 1,
    label: "Tarification à l'heure",
  },
  {
    value: 2,
    label: "Tarification forfaitaire pour l'activité",
  },
];

export const SubscriptionStatus = [
  {
    value: 0,
    label: 'en attente',
  },
  {
    value: 1,
    label: 'validé',
  },
  {
    value: 2,
    label: 'rejeté',
  },
  {
    value: 3,
    label: 'en attente',
  },
];

export const getSubscriptionStatus = (value) =>
  SubscriptionStatus.find((s) => s.value === value);

export const periodSelection = [
  {
    label: 'A la journée',
    value: 0,
  },
  {
    // l'ancienne version
    label: 'A la semaine',
    value: 1,
  },
  {
    label: 'Au mois',
    value: 2,
  },
  {
    label: 'Au trimestre',
    value: 3,
  },
  {
    label: 'Au semestre',
    value: 4,
  },
  {
    label: 'A l\'année scolaire',
    value: 5,
  },
  {
    label: 'A l\'année civile',
    value: 6,
  },
  {
    label: 'Toutes les dates de l\'activité',
    value: 7,
  },
  {
    // la nouvelle version
    label: 'A la semaine',
    value: 8,
  }
];

export const getPeriodSelectionLabel = (value) => {
  return periodSelection.find((period) => period.value === value)?.label
}

export const billingPeriod = [
  {
    label: 'au premier jour de la période',
    value: 0,
  },
  {
    label: 'au jour médian de la période',
    value: 1,
  },
  {
    label: 'au dernier jour de la période',
    value: 2,
  },
  {
    label: 'à la 1ère réservation ou présence',
    value: 3,
  }
]

export const getBillingPeriodLabel = (value) => {
  return billingPeriod.find((period) => period.value === value)?.label
}