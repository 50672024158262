
class Auth {
    tokenKey = 'access'

    setToken = token => localStorage.setItem(this.tokenKey, token)

    getToken = () => localStorage.getItem(this.tokenKey)

    clearToken = () => localStorage.removeItem(this.tokenKey)
}

export default new Auth()
