import React, { memo } from "react"
import { Skeleton, Space, Tag } from "antd"
import { Link } from "react-router-dom"
import { MailOutlined, PhoneOutlined, LinkOutlined } from "@ant-design/icons"
import H1 from "~/components/common/H1"
import Text from "~/components/common/Text"
import Picture from "~/components/common/Picture"
import { Email } from "./components"
import { parentStatus } from "~/constants/index"

const ProfileParentCard = ({
  isLoading,
  name,
  email,
  phone,
  picture,
  status,
  householdAlias,
  householdName,
}) => {
  console.log({phone})
  if (isLoading) {
    return (
      <Skeleton
        active
        title={false}
        avatar={{
          size: 120,
        }}
        paragraph={{
          rows: 4,
          width: [335, 250, 350, 300],
        }}
      />
    )
  }

  return (
    <Space>
      <Picture width="120" height="120" src={picture} alt={name} />
      <Space size={2} direction="vertical">
        <H1>
          <Space size="middle" align="center">
            {name}
            {status === 2 && (
              <Tag
                color={parentStatus[0].color}
                style={{ padding: "6px 12px" }}
                key={status}
              >
                {parentStatus[0].label}
              </Tag>
            )}
            {status === 0 && (
              <Tag
                color={parentStatus[1].color}
                style={{ padding: "6px 12px" }}
                key={status}
              >
                {parentStatus[1].label}
              </Tag>
            )}
          </Space>
        </H1>
        {email && (
          <Email href={`mailto:${email}`}>
            <MailOutlined style={{ marginRight: "5px", fontSize: "12px" }} />
            {email}
          </Email>
        )}
        {phone && (
          <Text size="big">
            <PhoneOutlined style={{ marginRight: "5px", fontSize: "12px" }} />
            {phone}
          </Text>
        )}
        {/* <Text size="big">
          <LinkOutlined style={{ marginRight: "5px", fontSize: "12px" }} />
          Foyer{" "}
          <Link to={`/family/households/${householdAlias}`}>
            {householdName}
          </Link>
        </Text> */}
      </Space>
    </Space>
  )
}

export default memo(ProfileParentCard)
