import React from 'react'
import PropTypes from 'prop-types'

import { getActivityStatus, getActivityStatusColor } from '~/utils/activity'

import Dot from '~/components/common/Dot'
import ActivityIcon from '~/components/common/ActivityIcon'
import P from '~/components/common/P'
import ArrowUp from '~/assets/images/arrow-right.svg'

import {
  ActivityHeader,
  ActivityCard,
  ActivityStatusContainer,
  ActivityInfo,
  ArrowIcon,
} from './components'

const CollapsedActivity = ({
  onActiveChange,
  activity,
  subscription,
}) => (
  <ActivityCard>
    <ActivityHeader
      onClick={() => onActiveChange(activity.alias)}
    >
      <ActivityInfo>
        <ActivityIcon activityType={subscription.activityType} />
        <div className="text-container">
          <P bold className="activity-name">
            
            {activity?.parent_activity_name}
          </P>
          <ActivityStatusContainer>
            <Dot
              color={getActivityStatusColor(activity?.status)}
            />
            {getActivityStatus(activity?.status)}
          </ActivityStatusContainer>
        </div>
      </ActivityInfo>
      <ArrowIcon src={ArrowUp} alt="" />
    </ActivityHeader>
  </ActivityCard>
)

CollapsedActivity.propTypes = {
  subscription: PropTypes.shape().isRequired,
  activity: PropTypes.shape().isRequired,
  onActiveChange: PropTypes.func.isRequired,
}
export default CollapsedActivity
