
import { handleActions } from '~/utils/redux-actions'

import authToken from '~/utils/Auth'
import {
  loginProcess,
  loginError,
  loginSuccess,
  // JWT
  setJwt,
  unsetJwt,
  // Forgot Password
  forgotPasswordProcess,
  forgotPasswordError,
  forgotPasswordSuccess,
  // reset password
  resetPasswordError,
  resetPasswordProcess,
  resetPasswordSuccess,
  // Reset Password Confirm
  resetPasswordConfirmError,
  resetPasswordConfirmPending,
  resetPasswordConfirmSuccess,

  // logout
  logout,
  // user Profile
  fetchUserProfileProcess,
  fetchUserProfileSuccess,
  fetchUserProfileError,
  // update
  updateUserProfileProcess,
  updateUserProfileSuccess,
  updateUserProfileError,
  // user docs

  fetchUserDocumentsProcess,
  fetchUserDocumentsError,
  fetchUserDocumentsSuccess,

  fetchUserPhotoSuccess,

  // update user photo
  updateUserPhotoProcess,
  updateUserPhotoSuccess,
  updateUserPhotoError,

  // change user relationship
  // no-unused-vars :: updateRelationship,
  updateRelationshipProcess,
  updateRelationshipSuccess,
  updateRelationshipError,
} from './actions'
import Gleap from 'gleap'

const defaultState = {
  // user Auth
  auth: {
    userExists: false,
    mustResetPassword: false,

    error: false,
    success: false,

    displayPasswordField: false,

    isRegistering: false,
    registrationError: false,

    passwordResetting: false,
    passwordResetError: false,

    isLogin: false, // for showing spinner
    isLogged: false,

    user: {
      email: window.localStorage.getItem('email'),
      password: null,
    },
    roles: [],

    jwt: null,
    accessToken: window.localStorage.getItem('access') || null,
    refreshToken: null,

    isResettingPassword: false,
    resetSuccess: false,
    resetToken: null,
    resetPasswordSuccess: false,
    pushToken: null,

    resetPasswordConfirmPending: false,
    resetPasswordConfirmError: false,
    resetPasswordConfirmSuccess: false,

    isForgotPasswordProcess: false, // for showing spinner
    forgotPasswordError: false,
    forgotPasswordSuccess: false,
  },
  // User info
  userInfo: {
    isFetching: false,
    error: false,

    isUpdating: false, // profile info updating
    isUpdatingPhotoProfile: false, // photo profile info updating
    isUpdatingRelationship: false, // relationship info updating
    // isUpdated: false,
    updateError: false,

    user: {},
    data: {
      role: null,
      civility: null,
      first_name: null,
      last_name: null,
      birthdate: null,
      mobile: null,
      phone: null,
      address: null,
    },

    isDocumentsFetching: false,
    documentsFetchingError: false,
    documents: [],

    feed: [],
  },

}

export default handleActions({

  // LOGIN
  [loginProcess]: (state) => {
    state.auth.isLogin = true
    state.auth.error = false
  },
  [loginError]: (state, { payload }) => {
    state.auth.isLogin = false
    state.auth.succes = false
    state.auth.error = payload
  },
  [loginSuccess]: (state, { payload }) => {
    state.auth.isLogin = false
    state.auth.success = true
    state.auth.error = false
    state.auth.user = payload.user
    state.auth.roles = payload.roles
    state.auth.mustResetPassword = payload.mustResetPassword
  },

  // JWT
  [setJwt]: (state, { payload }) => {
    state.auth.accessToken = payload.accessToken
    state.auth.refreshToken = payload.refreshToken

    state.auth.isLogged = true
    state.auth.isLogin = false
    state.auth.resetSuccess = false
    state.auth.isResettingPassword = false

    authToken.setToken(payload.accessToken) // in case token expires during a user session
  },
  [unsetJwt]: state => {
    state.auth.jwt = null
    state.auth.accessToken = null
    state.auth.refreshToken = null
    state.auth.isLogged = false
    state.auth.resetSuccess = false
    state.auth.isResettingPassword = false
    state.auth.loggedAs = false
    authToken.clearToken()
  },

  // Forgot Password
  [forgotPasswordProcess]: state => {
    state.auth.isForgotPasswordProcess = true
    state.auth.forgotPasswordError = false
    state.auth.forgotPasswordSuccess = false
  },
  [forgotPasswordError]: state => {
    state.auth.isForgotPasswordProcess = false
    state.auth.forgotPasswordError = true
    state.auth.forgotPasswordSuccess = false
  },
  [forgotPasswordSuccess]: state => {
    state.auth.isForgotPasswordProcess = false
    state.auth.forgotPasswordError = false
    state.auth.forgotPasswordSuccess = true
  },
  // reset password
  [resetPasswordProcess]: (state) => {
    state.auth.passwordResetting = true
    state.auth.passwordResetError = false
  },
  [resetPasswordSuccess]: (state) => {
    state.auth.passwordResetting = false
    state.auth.passwordResetError = false
  },
  [resetPasswordError]: (state) => {
    state.auth.passwordResetting = false
    state.auth.passwordResetError = true
  },

  // Reset Password Confirm
  [resetPasswordConfirmPending]: state => {
    state.auth.resetPasswordConfirmPending = true
    state.auth.resetPasswordConfirmError = false
    state.auth.resetPasswordConfirmSuccess = false
  },
  [resetPasswordConfirmError]: state => {
    state.auth.resetPasswordConfirmPending = false
    state.auth.resetPasswordConfirmError = true
    state.auth.resetPasswordConfirmSuccess = false
  },
  [resetPasswordConfirmSuccess]: state => {
    state.auth.resetPasswordConfirmPending = false
    state.auth.resetPasswordConfirmError = false
    state.auth.resetPasswordConfirmSuccess = true
  },

  // logout
  [logout]: state => {
    state.auth = defaultState.auth
    state.userInfo = defaultState.userInfo
    authToken.clearToken()
    Gleap.showFeedbackButton(false)
    Gleap.clearIdentity()
  },
  // User Profile
  [fetchUserProfileProcess]: state => {
    state.userInfo.isFetching = true
    state.userInfo.error = false
  },
  [fetchUserProfileSuccess]: (state, { payload }) => {
    state.userInfo.isFetching = false
    state.userInfo.error = false
    state.userInfo.user = payload
  },
  [fetchUserProfileError]: state => {
    state.userInfo.isFetching = false
    state.userInfo.error = true
    state.userInfo.user = {}
  },
  [updateUserProfileProcess]: state => {
    state.userInfo.isUpdating = true
    state.userInfo.updateError = false
  },
  [updateUserProfileSuccess]: (state, { payload }) => {
    state.userInfo.isUpdating = false
    state.userInfo.updateError = false
    state.userInfo.user = payload
  },
  [updateUserProfileError]: state => {
    state.userInfo.isUpdating = false
    state.userInfo.updateError = true
  },

  // user docs

  [fetchUserDocumentsProcess]: state => {
    state.userInfo.isDocumentsFetching = true
    state.userInfo.documentsFetchingError = false
  },
  [fetchUserDocumentsError]: state => {
    state.userInfo.isDocumentsFetching = false
    state.userInfo.documentsFetchingError = true
  },
  [fetchUserDocumentsSuccess]: (state, { payload }) => {
    state.userInfo.isDocumentsFetching = false
    state.userInfo.documentsFetchingError = false
    state.userInfo.documents = payload
  },
  [fetchUserPhotoSuccess]: (state, { payload }) => {
    state.userInfo.user.photoUrl = payload
  },
  [updateUserPhotoProcess]: state => {
    state.userInfo.isUpdatingPhotoProfile = true
  },
  [updateUserPhotoSuccess]: state => {
    state.userInfo.isUpdatingPhotoProfile = false
  },
  [updateUserPhotoError]: state => {
    state.userInfo.isUpdatingPhotoProfile = false
  },
  [updateRelationshipProcess]: state => {
    state.userInfo.isUpdatingRelationship = true
  },
  [updateRelationshipSuccess]: state => {
    state.userInfo.isUpdatingRelationship = false
  },
  [updateRelationshipError]: state => {
    state.userInfo.isUpdatingRelationship = false
  },
  // [PURGE]: state => state
},
defaultState)
