import styled from 'styled-components'

export const Title = styled.h1`
  color: @neutral-5;
  font-size: 24px;
  margin-bottom: 14px;
  flex: 0 0 100%;
`
export const SameHeightList = styled.div`
  display: flex;
  flex-flow: wrap;
  height: 100%;
`

export const List = styled.div`
  padding-left: 24px;
`
