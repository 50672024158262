import { createElement } from 'react'

/**
 * Filter out props from styled elements to prevent warning
 */
const filter = (element, attrs) => (({ children, ...props }) => {
  attrs.map(propName => !!props[propName] && delete props[propName])
  return createElement(element, props, children)
})

export default filter
