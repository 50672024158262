import { Calendar, message, Modal } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Bullet from "~/components/common/Bullet";
// // local
import P from "~/components/common/P";
import {
  createAttendance, fetchChildAttendances,
  fetchOpeningdays,
  patchAttendance
} from "~/containers/Children/actions";
import {
  activityDetailsFetching,
  subscriptionUpdating
} from "~/containers/Subscription/actions";
import {
  RecuDay,
  RecuDays,
  RecurrenceContainer
} from "~/containers/Subscription/components";
import { colors } from "~/utils/colors";
import { daysLabel } from "~/utils/days";
import CalendarCell from "./Cell";
import { Container, LegendContainer } from "./components";
import CalendarHeader from "./Header";

const { confirm } = Modal;

/**
 * deport the function with dayjs to fetch currentMonth for selectedMonth
 * before state look like
 * const [selectedMonth, setSelectedMonth] = useState(dayjs().add(1, 'month').get('month'))
 * TOTO: REPLACE ALL dayjs LIBRARIE BY OTHER BECAUSE dayjs WAS DEPRECATED
 */

const monthNumber = dayjs().month();
const currentMonth = monthNumber + 1;
/**
 * ChildCalendar
 */
const ChildCalendar = ({ activity }) => {
  const [reccurentDays, setReccurentDays] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  // 
  const [currentDay, setCurrentDay] = useState(dayjs());
  const [markedDates, setMarkedDates] = useState({});
  const { t } = useTranslation("children");
  const dispatch = useDispatch();
  const { childDetails: child, attendance } = useSelector(
    (state) => state.children
  );
  const { activity: parentActivity } = useSelector(
    (state) => state.subscription
  );
  const {
    childAttendances: attendances,
    openingDays,
    isPatching,
    isCreating,
  } = attendance;

  const state = useSelector((state) => state);

  const onSelectRecurrent = (day) => {
    const obj = {};
    obj[day.key] = !day.active;
    const selected = reccurentDays;
    dispatch(
      subscriptionUpdating(activity.alias, child.alias, obj, (data) => {
        const newSelected = selected?.map((d) => {
          const a = {};
          a.key = d.key;
          a.active = data[d.key];
          a.label = d.label;
          return a;
        });
        setReccurentDays(newSelected);
        dispatch(
          fetchChildAttendances({
            childAlias: child.alias,
            activityAlias: activity.parent_activity_alias,
            month: selectedMonth,
          })
        );
      })
    );
  };
  const fetchAttendances = useCallback(() => {
    const childAlias = child.alias;
    const paAlias = activity.parent_activity_alias;
    const orgAlias = activity.organisation_alias;

    dispatch(
      fetchOpeningdays({
        organisationAlias: orgAlias,
        activityAlias: activity.activity_alias,
        month: selectedMonth,
      })
    );
    dispatch(
      fetchChildAttendances({
        childAlias,
        activityAlias: paAlias,
        month: selectedMonth,
      })
    );
  }, [child, activity, selectedMonth]);

  const getMarkedDates = useCallback(async () => {
    const newDates = {};
    openingDays.forEach((d) => {
      newDates[d.date] = {
        selected: true,
        marked: false,
        selectedColor: colors.cvBlueGreyLight,
      };
    });
    attendances.forEach((a) => {
      // Get right color
      let color;
      // if day is passed
      if (dayjs(a.date) < dayjs()) {
        if (a.was_there) {
          // present on a past date
          color = colors.cvGreen;
        } else if (a.is_listed && !a.was_there) {
          // registered but not present on a past date
          color = colors.cvOrange;
        } else if (!a.is_listed && !a.was_there) {
          // not registered on a past date
          color = colors.cvBlueGreyLight;
        }
      } else {
        // If it is a present or future day
        if (a.is_listed && a.is_editable) {
          // if registered
          color = colors.cvBlue;
        } else if (a.is_listed && !a.is_editable) {
          color = colors.cvBlueGrey;
        } else {
          color = colors.cvBlueGreyLight; // if not registered
        }
      }
      newDates[a.date] = {
        selected: true,
        marked: false,
        selectedColor: color,
      };
    });

    setMarkedDates(newDates);
  }, [attendances, openingDays, currentDay]);

  useEffect(() => {
    getMarkedDates();
  }, [attendances, openingDays]);

  const getParentActivity = useCallback(() => {
    dispatch(
      activityDetailsFetching(
        activity.organisation_alias,
        activity.parent_activity_alias
      )
    );
  }, [parentActivity]);

  useEffect(() => {
    getParentActivity();
  }, [activity]);

  useEffect(() => {
    if (parentActivity?.details) {
      const activityDays = daysLabel.filter(
        (day) => parentActivity.details[day.key]
      );
      const recDays = [];
      activityDays.forEach((day) => {
        recDays.push({
          key: day.key,
          active: activity[day.key],
          label: day.label,
        });
      });
      setReccurentDays(recDays);
    }
  }, [parentActivity]);

  useEffect(() => {
    if (!isCreating && !isPatching) {
      fetchAttendances();
    }
  }, [selectedMonth, isCreating, isPatching]);

  const handleOnSelectDay = useCallback(
    (d) => {
      // find selected day in attendances
      if (d.format("M") !== selectedMonth) {
        setCurrentDay(d);
        setSelectedMonth(dayjs(d).format("M"));
      }
      const eventExists = !!openingDays.find(
        (day) => day.date === d.format("YYYY-MM-DD")
      );

      const selectedDay = attendances.find(
        (day) => day.date === d.format("YYYY-MM-DD")
      );

      const childAlias = child.alias;
      const activityAlias = activity.activity_alias;

      if (!eventExists) {
        return;
      }

      if (dayjs(d.format("YYYY-MM-DD")).isBefore(dayjs(), "day")) {
        if (selectedDay && selectedDay.was_there === true) {
          message.success(`${child.first_name} était présent(e) ce jour-là`);
        } else if (selectedDay && selectedDay.is_listed === true) {
          message.warning(
            `${child.first_name} était inscrit(e), mais noté absent ce jour-là`
          );
        } else {
          message.info(`${child.first_name} n'était pas inscrit(e) ce jour-là`);
        }
      } else if (selectedDay) {
        // Patch attendance
        if (selectedDay.is_editable) {
          confirm({
            title: activity.parent_activity_name,
            content: `Voulez-vous modifier la présence de ${
              child.first_name
            } le ${d.format("D")}/${d.format("MM")} ?`,
            onOk() {
              dispatch(
                patchAttendance({
                  childAlias,
                  alias: selectedDay.alias,
                  present: !selectedDay.is_listed,
                })
              );
            },
            onCancel() {
              
            },
          });
        } else {
          message.info("Cette date n'est pas modifiable");
        }
      } else {
        // Create attendance
        confirm({
          title: activity.parent_activity_name,
          content: `Voulez-vous inscrire ${child.first_name} le ${d.format(
            "D"
          )}/${d.format("MM")} ?`,
          onOk() {
            dispatch(
              createAttendance({
                childAlias,
                date: d.format("YYYY-MM-DD"),
                activityAlias,
              })
            );
          },
          onCancel() {
            
          },
        });
      }
    },
    [openingDays, child, activity, dispatch]
  );

  const next = useCallback(() => {
    const newDate = dayjs(currentDay).add(1, "months");
    setCurrentDay(newDate);
    setSelectedMonth(dayjs(newDate).format("M"));
  }, [currentDay]);

  const pervious = useCallback(() => {
    const newDate = dayjs(currentDay).subtract(1, "months");
    setCurrentDay(newDate);
    setSelectedMonth(dayjs(newDate).format("M"));
  }, [currentDay]);

  return (
    <Container>
      <RecurrenceContainer>
        <P small>{t("recurrence")}</P>
        <RecuDays>
          {reccurentDays?.map((day) => (
            <RecuDay
              onClick={() => onSelectRecurrent(day)}
              key={day.key}
              isSelected={day.active}
            >
              {day.label}
            </RecuDay>
          ))}
        </RecuDays>
      </RecurrenceContainer>
      <Calendar
        value={currentDay}
        fullscreen={false}
        onSelect={handleOnSelectDay}
        headerRender={(props) => (
          <CalendarHeader {...props} next={next} pervious={pervious} />
        )}
        dateFullCellRender={(date) => (
          <CalendarCell date={date} markedDates={markedDates} />
        )}
      />

      <LegendContainer>
        <Bullet color={colors.cvBlue} noBorder size="12px" />
        <P>{t("child.calendar.p1")}</P>
      </LegendContainer>
      <LegendContainer>
        <Bullet color={colors.cvBlueGrey} noBorder size="12px" />
        <P>{t("child.calendar.p2")}</P>
      </LegendContainer>
      <LegendContainer>
        <Bullet color={colors.cvBlueGreyLight} noBorder size="12px" />
        <P>{t("child.calendar.p3")}</P>
      </LegendContainer>
      <LegendContainer>
        <Bullet color={colors.cvGreen} noBorder size="12px" />
        <P>{t("child.calendar.p4")}</P>
      </LegendContainer>
      <LegendContainer>
        <Bullet color={colors.cvOrange} noBorder size="12px" />
        <P>{t("child.calendar.p5")}</P>
      </LegendContainer>
    </Container>
  );
};
ChildCalendar.propTypes = {
  activity: PropTypes.shape().isRequired,
};
export default ChildCalendar;
