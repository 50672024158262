import React, { useState, useCallback, useEffect } from "react"

import { Avatar, Badge, Button, Collapse, Table } from "antd"
import { PlusOutlined, EditOutlined } from "@ant-design/icons"
import diffrence from "lodash/difference"
import Container, { MainContainer, MainContent } from "~/components/common/Container"
import H1 from "~/components/common/H1"
import Space from "~/components/common/Space"
import { Doc, MissingDoc } from "./components.js"
import TransmitDoc from "./TramsmitDoc"
import { useDispatch, useSelector } from "react-redux"
import { documentsFetching, householdFetching } from "~/containers/Foyer/actions.js"
import { LightText } from "~/containers/Foyer/Documents/components"
import { getDocumentType } from "~/constants/index.js"
import { userOrganisationsFetching } from "~/containers/Home/actions.js"
import Loading from '~/components/common/Loading'
import { childRegistrationFetching } from "~/containers/Registration/actions.js"
import styled from "styled-components"
import dayjs from "dayjs"
import SchoolSVG from '~/assets/images/school.svg'

const { Panel } = Collapse

const ContainerWrapper = ({ children, ...props }) => {
  console.log({props})
  
  return (
    <MainContainer {...props}>
      <MainContent>{React.cloneElement(children, {...props})}</MainContent>
    </MainContainer>
  )
}

const Document = ({
  child,
}) => {
  const dispatch = useDispatch()
  const [showDrawer, setShowDrawer] = useState(false)
  const [targetedDocument, setTargetedDocument] = useState(null)

  const {list: childRegistrations, loading: loadingChildRegistrations} = useSelector((state) => state.registration)

  const columns = [
    {
      title: "Type",
      key: "description",
      dataIndex: "",
      render: (data) => {
        return <BoldText>{data?.document?.type?.description || data?.description}</BoldText>
      },
    },
    {
      title: "Nom",
      key: "label",
      dataIndex: "",
      render: (data) => {
        if (data?.document?.name && data?.document?.file_url) {
          return <a href={data?.document?.file_url + "?show_from_mobile=true"} target="_blank">{data?.document?.name}</a>
        }
      },
    },
    {
      title: "Taille",
      key: "size",
      dataIndex: "",
      render: (data) => {
        if (data?.document?.file_size_label)
        return <>{data?.document?.file_size_label}</>;
      },
    },
    {
      title: "Date d’ajout",
      key: "creation_date",
      dataIndex: "",
      render: (data) => {
        if (data?.created_at) {
          return <>{dayjs(data?.created_at).format('DD/MM/YYYY')}</>
        }
      },
    },
    // {
    //   title: "Date d’échéance",
    //   key: "due_date",
    //   dataIndex: "",
    //   render: (data) => {
    //     if (data?.due_date) {
    //       return <>{dayjs(data?.due_date).format('DD/MM/YYYY')}</>
    //     }
    //   },
    // },
    {
      title: "Statut",
      key: "statusDetails",
      dataIndex: "",
      render: (data) => {
        return <Status code={data?.status?.key}>{data?.status?.value || 'À transmettre'}</Status>
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => {
        console.log({record})

        if ([2, 3, 4].includes(record?.status?.value) || !record?.status) {
          return (
            <div>
              <Button
                onClick={() => {
                  setShowDrawer(true)
                  setTargetedDocument(record)
                }}
                size="middle"
                type="link"
              >
                Transmettre
              </Button>
            </div>
          )
        }
      },
    },
  ]

  console.log({childRegistrations, targetedDocument})

  // useEffect(() => {
  //   dispatch(documentsFetching())
  //   dispatch(userOrganisationsFetching())
  //   dispatch(householdFetching())
  // }, [])
  
  useEffect(() => {
    dispatch(childRegistrationFetching(
      child?.alias,
      () => console.log('doneee')
    ))
  }, [child?.alias])
  
  if (loadingChildRegistrations) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }
  
  return (
    <CollapseWrapper>
      <Collapse
        defaultActiveKey={['0']}
        expandIconPosition="right"
        onChange={key => console.log({key})}
        bordered={false}
      >
        {childRegistrations?.map(registration => {
          const childRegistrationPrivateDocuments = registration?.list_of_documents_details
            ?.filter(doc => !doc?.document?.type?.is_common)
            ?.map(doc => ({
              ...doc,
              organisationName: registration?.organisationName,
              registrationAlias: registration?.alias,
              organisationAlias: registration?.organisationAlias,
            }));
          const childRegistrationPrivateMissingDocuments = registration?.missing_documents_details
            ?.filter(doc => !doc?.is_common)
            ?.map(doc => ({
              ...doc,
              organisationName: registration?.organisationName,
              registrationAlias: registration?.alias,
              organisationAlias: registration?.organisationAlias,
            }));

          const missingDocuments = childRegistrationPrivateMissingDocuments?.length

          console.log({childRegistrationPrivateDocuments, childRegistrationPrivateMissingDocuments, missingDocuments})

          return (
            <ContainerWrapper
              header={
                <Space
                  style={{
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Space>
                    <Avatar size={42} src={registration?.organisationPhoto || SchoolSVG} />
                    <h3 style={{marginBottom: 0}}>
                      {registration?.organisationName} / Documents : Périscolaire {registration?.school_year}
                    </h3>
                  </Space>
                  {!!missingDocuments && (
                    <span>
                      <Badge
                        count={missingDocuments}
                        style={{ marginRight: 5 }}
                      />
                      documents manquants
                    </span>
                  )}
                </Space>
              }
            >
              <Panel
                key="0"
              >
                <Table
                  columns={columns}
                  dataSource={[...childRegistrationPrivateDocuments, ...childRegistrationPrivateMissingDocuments]}
                  loading={loadingChildRegistrations}
                />
              </Panel>
            </ContainerWrapper>
          )
        })}
      <TransmitDoc
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        setTargetedDocument={setTargetedDocument}
        targetedDocument={targetedDocument}
        child={child}
      ></TransmitDoc>
      </Collapse>
    </CollapseWrapper>
  )
}

const CollapseWrapper = styled.div`
  .ant-collapse-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    
    .anticon-right {
      font-size: 32px;
    }
  }

  .ant-collapse-content {
    border-top: none;

    .ant-table-thead {
      th {
        text-transform: uppercase;
      }
    }
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 0;
  }
`

const BoldText = styled.p`
  font-weight: 700;
  margin-bottom: 0;
`

const getStatusColor = (code) => {
  if (code === 1) {
    return '#52C41A'
  }

  if (code === 2) {
    return '#FF5876'
  }

  if (code === 3) {
    return '#722ED1'
  }

  if (code === 4 || code === null || code === undefined) {
    return '#646464'
  }

  return '#F4C26D'
}

const Status = styled.span`
  color: ${({code}) => getStatusColor(code)};
  text-transform: capitalize;
`

export default Document
