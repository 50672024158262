import React, { useState, useEffect, useCallback } from 'react'
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import { connect, useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import dayjs from 'dayjs'
import uniqWith from 'lodash/uniqBy'
import { BankOutlined } from '@ant-design/icons'
import H1 from '~/components/common/H1'
import H2 from '~/components/common/H2'
import H3 from '~/components/common/H3'
import P from '~/components/common/P'
import PrimaryButton from '~/components/common/PrimaryButton'
import Space from '~/components/common/Space'
import Text from '~/components/common/Text/index'
import Container from '~/components/common/Container'
import ContentHeader from '~/components/common/ContentHeader'
import FilterContainer from '~/components/common/FilterContainer'
import Table from '~/components/common/Table'
import { colors } from '~/utils/colors'
import { fetchOrderDetails } from '~/containers/Cart/actions'
import { getOrderStatus } from '~/constants/orderStatusCommande'
import { useColumnsOrderDetail } from './components/Columns/columnsOrderDetail'
import { paymentMethod } from './paymentMethods'
// import { CustomHeader } from '../../../../../components'
import { Main } from './components/Styles/orderDetail'

const sortFields = {
  enfant: 'enfant',
  activité: 'activité',
  allPresences: 'allPresences',
  montant: 'montant',
}

const OrderDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState(sortFields.enfant)
  const [rowSelection, setRowSelection] = useState([])
  const [showDrawer, setShowDrawer] = useState(false)

  const { accessToken } = useSelector((state) => state.user.auth)
  const { data: order, isFetching: isFetchingOrderDetails } = useSelector(
    (state) => state.cart.currentOrder,
  )

  console.log('isFetching', isFetchingOrderDetails)
  console.log('order detail', order)

  const onStatusChange = () => {}

  const columns = useColumnsOrderDetail(onStatusChange, setShowDrawer)

  const onTableChange = useCallback((pagination, _, sorter, { action }) => {
    if (action === 'paginate') {
      setPage(pagination.current)
    }
    if (action === 'sort') {
      const field = sortFields[sorter.field]
      setSort(sorter.order === 'descend' ? `-${field}` : field)
    }
  }, [])

  useEffect(() => {
    dispatch(
      fetchOrderDetails({
        orderAlias: id,
      }),
    )
  }, [id])

  const organisationName = order?.organisation_name
  const organisationCredit = order?.total_used_credits
  const orderStatus = order?.status
  const isPaid = orderStatus === 5
  const isProcessing = [0, 1, 2, 7].includes(orderStatus)
  const isFailed = [3, 4, 6, 8].includes(orderStatus)
  const orderStatusColor = getOrderStatus(orderStatus)?.color
  const orderStatusLabel = getOrderStatus(orderStatus)?.label
  const paymentHistory = order?.related_payments
  const amountDetail = `${order?.total_amount_including_credits} €`
  const dateOrderDetail = uniqWith(paymentHistory, (a, b) => dayjs(a?.date).isSame(b?.date))
    .map((d) => dayjs(d.date).format('DD MMMM YYYY'))
  // si statut Annulé ou Expiré mettre la date expires_at en False
  const dateDetail = dateOrderDetail.length > 0 ? `${dateOrderDetail} / ` : `${dayjs(order?.expires_at).format('DD MMMM YYYY')} /`
  const childrens = order?.attendances || {}
  const childrenAliases = Object.keys(childrens)

  const dataSet = childrenAliases?.reduce((acc, cur) => {
    const childActivitiesAliases = Object.keys(order?.attendances?.[cur])?.filter(
      (item) => item !== 'total',
    )

    const childName = order?.attendances?.[cur]?.[childActivitiesAliases?.[0]]?.child_name.split(' ').reverse().join(' ')

    childActivitiesAliases?.map((activityAlias) => {
      const activity = order?.attendances?.[cur]?.[activityAlias]
      
      acc = [...acc, {
        childAlias: cur,
        childName: childName,
        activityAlias: activity?.parentActivity_alias,
        activityName: activity?.parentActivity_name,

        reservations: activity?.InscriptionDates
          ?.map((date) => dayjs(date).format('DD/MM/YYYY'))
          ?.sort((a, b) => dayjs(a, 'DD/MM/YYYY').diff(dayjs(b, 'DD/MM/YYYY')))
          ?.map((date) => dayjs(date, "DD/MM/YYYY").format('DD/MM'))
          ?.toString()
          ?.replaceAll(',', ', '),

        presenceWithoutReservation:activity?.presenceWithNoInscriptionDates
          ?.map((date) => dayjs(date).format('DD/MM/YYYY'))
          ?.sort((a, b) => dayjs(a, 'DD/MM/YYYY').diff(dayjs(b, 'DD/MM/YYYY')))
          ?.map((date) => dayjs(date, "DD/MM/YYYY").format('DD/MM'))
          ?.toString()
          ?.replaceAll(',', ', '),
        
        allPresences:activity?.InscriptionDates.concat(activity?.presenceWithNoInscriptionDates)
          ?.map((date) => dayjs(date).format('DD/MM/YYYY'))
          ?.sort((a, b) => dayjs(a, 'DD/MM/YYYY').diff(dayjs(b, 'DD/MM/YYYY')))
          ?.map((date) => dayjs(date, "DD/MM/YYYY").format('DD/MM'))
          ?.toString()
          ?.replaceAll(',', ', '),
        amount: activity?.total,
      }]
    })

    return acc
  }, [])

  console.log({ dataSet })

  const redirect = () => {
    let linkCommandes
    switch (location?.pathname) {
      case `/profile/commandes/${id}`:
        linkCommandes = <Link to="/profile/commandes">&#60; Commandes</Link>
        break
      case `/foyer/commandes/${id}`:
        linkCommandes = <Link to="/foyer/commandes">&#60; Commandes</Link>
        break
      default:
        linkCommandes = <Link to="/commandes">&#60; Commandes</Link>
        break
    }
    return linkCommandes
  }

  return (
    <>
      { location?.pathname === `/commandes/${id}` && (
      <ContentHeader
        breadcrumbItems={[
          {
            name: 'Mes Commandes',
            path: '/commandes',
          },
        ]}
      >
        <H1>Mes Commandes</H1>
      </ContentHeader>
      )}

      <Container>
        <Main>
          <Space direction="vertical" size={12}>

            {redirect()}

            <H1 style={{ paddingBottom: 0, marginBottom: 0 }}>
              Commande du {dateDetail} {amountDetail}
            </H1>

            <H2 style={{ paddingBottom: 0, marginBottom: 0 }}>
              <BankOutlined style={{ fontSize: '16px' }} /> {organisationName}
            </H2>
            <div>
              Statut : {' '}
              <span style={{ color: orderStatusColor }}>
                {orderStatusLabel}
              </span>
              {' '} / Paiement : {paymentMethod(paymentHistory)} / Avoir : {organisationCredit} €
            </div>
          </Space>
        </Main>
        
        <Table
          columns={columns}
          dataSource={dataSet}
          isLoading={isFetchingOrderDetails}
          onChange={onTableChange}
          total={0} // length
          currentPage={page}
          pageSize={pageSize}
          color={orderStatusColor}
          /* locale={{emptyText: "No Data"}} par défault
          * si attendances vide afficher le statut du Panier ex: Expiré
          */
          locale={{
            emptyText: (
              <span style={{ color: orderStatusColor }}>
                Panier {getOrderStatus(orderStatus)?.label}
              </span>),
          }}
        />
      </Container>
    </>
  )
}

export default OrderDetail

