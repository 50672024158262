import React from 'react'
import PropTypes from 'prop-types'
import Link from '~/components/common/Link'
import Image from '~/components/common/Image'

const Logo = ({
  src,
  alt,
  height,
  ...props
}) => (
  <Link to="/">
    <Image
      src={src}
      alt={alt}
      height={height}
      {...props}
    />
  </Link>
)

Logo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  height: PropTypes.number,
}
Logo.defaultProps = {
  alt: 'CityViz',
  height: 30,
}
export default Logo
