import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

const Component = ({ children }) => (
  <span>{dayjs(children).format('DD/MM/YYYY')}</span>
)

Component.propTypes = {
  children: PropTypes.string.isRequired,
}
export default Component
