import React from 'react'
import PropTypes from 'prop-types'
import dayjs from "dayjs"

import Bullet from '~/components/common/Bullet'
import OrganisationPhoto from '~/components/common/OrganisationPicture'
import { colors } from "~/utils/colors"

import {
  FeedContent,
  FeedMessageContainer,
  FeedMessageContent,
  FeedTitle,
  RegistrationMessageContainer,
} from '../index'
import ActivityIcon from "./ActivityIcon"
import config from '~/config'
import { useSelector } from 'react-redux'

const RegistrationMessage = ({ registration, organisationName }) => {

  let registrationInfos = {}
  let routeParams

  registrationInfos.activityType = 98
  switch (registration?.status) {
    case 0:
      if (registration?.child?.privateMissingDocuments?.length > 0) {
        routeParams = {
          registrationAlias: registration?.alias,
          childAlias: registration?.child?.alias
        }
      }

      registrationInfos = {
        postColors: {
          color: colors.darkOrange,
          borderColor: colors.lightOrange,
        },
        // bulletColor: 'orange',
        // borderColor: Colors.cvOrange,
        title: registration?.child?.privateMissingDocuments?.length > 0 ? `Dossier d'inscription incomplet à ${organisationName}` : `Dossier d'inscription en attente à ${organisationName}`,
        subtitle: registration?.child?.privateMissingDocuments?.length > 0 ? `Il manque des documents pour l'inscription de ${registration?.child?.firstName}` : `Vous serez bientôt tenu.e informé.e du traitement de votre demande d'inscription de ${registration?.child?.firstName}`,
        route: registration?.child?.privateMissingDocuments?.length > 0 ? 'HomeMissingDocuments' : 'FamilyChildDetails',
        // routeParams,
      }
      break
    case 4:
      routeParams = {
        registrationAlias: registration?.alias,
        childAlias: registration?.child?.alias
      }
      registrationInfos = {
        postColors: {
          color: colors.darkBlue,
          borderColor: colors.lightBlue,
        },
        // bulletColor: 'blue',
        // borderColor: Colors.cvBlue,
        status: 4,
        title: `Dossier d'inscription à ${organisationName} / ${" "}`,
        subtitle: `Finalisez l'inscription de ${registration?.child?.firstName} aux différents services de l'Organisation`,
        route: 'HomeMissingDocuments',
        routeParams,
      }
      break
    case 1:
      if (Number(registration?.renewalStatus) === 0) {
        registrationInfos = {
          // bulletColor: 'green',
          // borderColor: Colors.cvGreen,
          postColors: {
            color: colors.darkBlue,
            borderColor: colors.lightBlue,
          },
          title: `La réinscription à ${organisationName} est ouverte`,
          subtitle: `Vous pouvez réinscrire ${registration?.child?.firstName} aux différents services de l'Organisation`,
          route: 'FamilyChildDetails',
          // routeParams,
        }
      } else if(Number(registration?.renewalStatus) === 1){
        registrationInfos = {
          postColors: {
            color: colors.darkBlue,
            borderColor: colors.lightBlue,
          },
          title: `... en attente de validation`,
          subtitle: `La réinscription de ${registration?.child?.firstName} est en attente de validation ... 🙂 vous serez bientôt tenu.e informé.e du traitement de votre demande`,
          route: 'FamilyChildDetails',
          // routeParams,
        }
      }
      else if(Number(registration?.renewalStatus) === 2){
        registrationInfos = {
          postColors: {
            color: colors.darkRed,
            borderColor: colors.lightRed,
          },
          title: `Réinscription refusée`,
          subtitle: registration.organisationPhone
          ? ` la réinscription de ${registration?.child?.firstName} à ${registration?.organisation?.name} a été refusée ...  contactez les services de ${registration?.organisation?.name} au 📞 ${registration?.organisationPhone}`
          : ` la réinscription de ${registration?.child?.firstName} à ${registration?.organisation?.name} a été refusée ...  contactez les services de ${registration?.organisation?.name}`,
          route: 'FamilyChildDetails',
          // routeParams,
        }
      }
      else {
        registrationInfos = {
          // bulletColor: 'green',
          // borderColor: Colors.cvGreen,
          postColors: {
            color: colors.darkGreen,
            borderColor: colors.lightGreen,
          },
          title: `Dossier d'inscription validé à ${organisationName}`,
          subtitle: `Vous pouvez désormais procéder à l'inscription de ${registration?.child?.firstName} aux différents services de l'Organisation`,
          route: 'FamilyChildDetails',
          // routeParams,
        }
      }
      break
    case 2:
      registrationInfos = {
        // borderColor: Colors.cvRed,
        // bulletColor: 'red',
        postColors: {
          color: colors.darkRed,
          borderColor: colors.lightRed,
        },
        title: `Dossier d'inscription refusé à ${organisationName}`,
        subtitle: registration?.organisationPhone
        ? `L'inscription de ${registration?.child?.firstName} a été refusée. Veuillez prendre contact avec votre Organisation au 📞 ${registration?.organisationPhone}`
        : `L'inscription de ${registration?.child?.firstName} a été refusée. Veuillez prendre contact avec votre Organisation`
        ,
        route: 'FamilyChildDetails',
        // routeParams,
      }
      break
    case 3:
      if (registration?.child?.privateMissingDocuments?.length > 0) {
        routeParams = {
          registrationAlias: registration?.alias,
          childAlias: registration?.child?.alias
        }
      }

      registrationInfos = {
        // bulletColor: 'orange',
        // borderColor: Colors.cvOrange,
        postColors: {
          color: colors.darkOrange,
          borderColor: colors.lightOrange,
        },
        title: registration?.child?.privateMissingDocuments?.length > 0 ? `Dossier d'inscription incomplet à ${organisationName}` : `Dossier d'inscription incomplet à ${organisationName}`,
        subtitle: `L'inscription de ${registration?.child?.firstName} nécessite une intervention de votre part`,
        route: registration?.child?.privateMissingDocuments?.length > 0 ? 'HomeMissingDocuments' : 'FamilyChildDetails',
        // routeParams,
      }
      break
  }
  registrationInfos.activityType = 98

  return (
    <RegistrationMessageContainer {...registrationInfos.postColors}>
      <FeedMessageContent>
        <FeedTitle as="div" bold uppercase size="medium" {...registrationInfos.postColors}>
          <Bullet {...registrationInfos.postColors} />
          {registrationInfos.title} {(registrationInfos.status === 4 || Number(registration?.renewalStatus) === 0) && <div style={{ color: '#1890FF', paddingLeft: 5 }}>Continuer {'>>'} </div>}
        </FeedTitle>
        <div>
          {organisationName} le {dayjs(registration?.modifiedAt).format("DD/MM/YYYY")}
        </div>
        <FeedContent bold size="big">
          {registrationInfos.subtitle}
        </FeedContent>
      </FeedMessageContent>
      <OrganisationPhoto activityType={registrationInfos.activityType} />
      {/* <ActivityIcon width={85} height={85} activityType={registrationInfos.activityType}></ActivityIcon> */}
    </RegistrationMessageContainer>
  )
}

const SubscriptionMessage = ({ subscription, organisationName, userMail }) => {
  
  let routeParams = {
    child_alias: subscription?.child?.alias,
    child_url: `${config.api.url}users/${userMail}/children/${subscription?.child?.alias}`,
    activity_alias: subscription.activity?.alias,
  }

  let subscriptionContent = {}

  subscriptionContent = {
    title: `Inscription à ${subscription.organisation?.name}`,
    subtitle: `⚠️ ${subscription?.activity?.name} : L'inscription de ${subscription.child?.firstName} est invalide ... 🙂 contactez votre organisation`,
    color: 'red',
    borderColor: colors.lightRed,
    route: 'FamilyChildDetails',
    routeParams,
  }

  switch (subscription.status) {
    case 0:
      subscriptionContent = {
        title: `Activité : Une inscription en attente à ${organisationName}`,
        subtitle: `${subscription?.activity?.name} : L'inscription de ${subscription.child?.firstName} est en attente`,
        color: colors.darkOrange,
        borderColor: colors.lightOrange,
        route: 'FamilyChildDetails',
        routeParams,
      }
      break

    case 1:
      subscriptionContent = {
        title: `Activité : Une inscription validée à ${organisationName}`,
        subtitle: `${subscription?.activity?.name} : L'inscription de ${subscription.child?.firstName} est validée`,
        subtitle2: `Attention, n’oubliez pas de réserver des dates en cliquant ici`,
        color: colors.darkGreen,
        borderColor: colors.lightGreen,
        route: 'FamilyChildDetails',
        routeParams,
      }
      break

    case 2:
      subscriptionContent = {
        title: `Activité : Une inscription rejetée à ${organisationName}`,
        subtitle: subscription.organisationPhone
            ? `${subscription?.activity?.name} : L'inscription de ${subscription.child?.firstName} est rejetée. Veuillez contacter votre commune au 📞 ${subscription.organisationPhone}`
            : `${subscription?.activity?.name} : L'inscription de ${subscription.child?.firstName} est rejetée. Veuillez contacter votre commune`,
        color: colors.darkRed,
        borderColor: colors.lightRed,
        route: 'FamilyChildDetails',
        routeParams,
      }
      break

    case 3:
      subscriptionContent = {
        title: `Activité : Une inscription en attente de paiement à ${organisationName}`,
        subtitle: `${subscription?.activity?.name} : L'inscription de ${subscription.child?.firstName} est en attente de paiement`,
        color: colors.darkOrange,
        borderColor: colors.lightOrange,
        route: 'FamilyChildDetails',
        routeParams,
      }
      break
  }

  // if (this.state?.selectedChild && this.state?.selectedChild !== subscription?.childAlias) {
  //   return null;
  // }
  subscriptionContent.activityType = 98

  return (
    <RegistrationMessageContainer {...subscriptionContent}>
      <FeedMessageContent>
        <FeedTitle as="div" bold uppercase size="medium" {...subscriptionContent}>
          <Bullet {...subscriptionContent} />
          {/* {registrationInfos.title} {registrationInfos.status === 4 && <div style={{ color: '#1890FF', paddingLeft: 5 }}>Continuer >> </div>} */}
          {subscriptionContent.title}
        </FeedTitle>
        <div>
          {organisationName} le {dayjs(subscription.modifiedAt).format("DD/MM/YYYY")}
        </div>
        <FeedContent bold size="big">
          {subscriptionContent.subtitle}
        </FeedContent>
        <FeedContent bold size="big" style={{ color: "#FF5876"}} >
          {subscriptionContent.subtitle2}
        </FeedContent>
      </FeedMessageContent>
      <OrganisationPhoto activityType={subscriptionContent.activityType} />
      {/* <ActivityIcon width={85} height={85} activityType={registrationInfos.activityType}></ActivityIcon> */}
    </RegistrationMessageContainer>
  )
}

const ZeroChildrenMessage = ({ data, organisationName }) => {
  const routeParams = {
    // registrationAlias: registration?.alias,
    // childAlias: registration?.child?.alias
  }
  const infos = {
    postColors: {
      color: colors.darkOrange,
      borderColor: colors.lightOrange,
    },
    title: 'Compléter votre inscription /',
    subtitle: 'Vous pouvez, dès à présent, ajouter un ou plusieurs enfants',
    activityType: data?.activityType,
    route: '',
  }

  return (
    <RegistrationMessageContainer {...infos.postColors}>
      <FeedMessageContent>
        <FeedTitle as="div" bold uppercase size="medium" {...infos.postColors}>
          <Bullet {...infos.postColors} />
          {infos.title} <div style={{ color: '#1890FF', paddingLeft: 5 }}>Continuer {'>>'} </div>
        </FeedTitle>
        <FeedContent bold size="big">
          {infos.subtitle}
        </FeedContent>
      </FeedMessageContent>
      <OrganisationPhoto activityType={infos.activityType} />
      {/* <ActivityIcon width={85} height={85} activityType={infos.activityType}></ActivityIcon> */}
    </RegistrationMessageContainer>
  )
}

const FeedMessage = ({ feedData, organisationName, userMail }) => {
  const { postColors } = feedData || {}
  
  const isAdmin = !(feedData.feedType === "registration" || feedData.feedType === "subscription")// ? 'white' : "#EEF1FE";
  const isRegistration = feedData.feedType === "registration"
  const isSubscription = feedData.feedType === "subscription"
  const isZeroChildren = feedData.feedType === "zerochildren"
  
  if (!isSubscription && !isRegistration && !isZeroChildren) {
    return (
      <FeedMessageContainer {...postColors} background="#EEF1FE">
        <FeedMessageContent>
          <FeedTitle as="div" bold uppercase size="medium" {...postColors}>
            <Bullet {...postColors} />
            {!!feedData.feedTypeBis ? feedData.feedTypeBis : feedData.feedType}
          </FeedTitle>
          <div>
            {organisationName} le {dayjs(feedData.modifiedAt).format("DD/MM/YYYY")}
          </div>
          <FeedContent bold size="big">
            {feedData.title}
          </FeedContent>
        </FeedMessageContent>
        <OrganisationPhoto activityType={feedData.activityType} />
        {/* <ActivityIcon width={85} height={85} activityType={feedData.activityType}></ActivityIcon> */}
      </FeedMessageContainer>
    )
  }

  if (isRegistration) {
    return (
      <RegistrationMessage
        registration={feedData}
        organisationName={organisationName}
      />
    )
  }
  if (isSubscription) {
    return (
      <SubscriptionMessage
        subscription={feedData}
        organisationName={organisationName}
        userMail={userMail}
      />
    )
  }
  if (isZeroChildren) {
    return (
      <ZeroChildrenMessage
        data={feedData}
        organisationName={organisationName}
      />
    )
  }
}

FeedMessage.propTypes = {
  feedData: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

FeedMessage.defaultProps = {

}
export default FeedMessage
