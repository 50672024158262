// import React, {
//   useState,
//   useEffect,
// } from 'react'
// import { useSelector } from 'react-redux'
// import PropTypes from 'prop-types'
// import styled from 'styled-components'

// import { getUserOrganisations } from '~/containers/Home/selectors'
// import Image from '~/components/common/Image'
// import Loading from '~/components/common/Loading'

// import CityHallSVG from '~/assets/images/cityhall.svg'

// const OrganisationPhoto = ({org}) => {
//   const organisations = useSelector(getUserOrganisations)
//   const [photo, setPhoto] = useState(null)
//   useEffect(() => {
//     const org = organisations.find(element => element.alias === alias)
//     /*
//     /* If it is not is not among user's organisation,
//     /* we must fetch the photo using ORGANISATION DETAILS API
//     */
//     setPhoto(org?.photo ? org.photo : CityHallSVG)
//   }, [])
//   return (
//     <PhotoContainer>
//       {photo ? <Image src={photo} alt="" /> : <Loading />}
//     </PhotoContainer>
//   )
// }

// OrganisationPhoto.propTypes = {
//   alias: PropTypes.string.isRequired,
// }

// export default OrganisationPhoto

import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import Cantine from '~/assets/activities/cantine.svg'
import Centre from '~/assets/activities/centre.svg'
import Enfants from '~/assets/activities/enfants.svg'
import Etude from '~/assets/activities/etude.svg'
// Activity Icons
import Garderie from '~/assets/activities/garderie.svg'
import Mairie from '~/assets/activities/mairie.svg'
import Matin from '~/assets/activities/matin.svg'
import Ramassage from '~/assets/activities/ramassage.svg'
import School from '~/assets/activities/school.svg'
import Stage from '~/assets/activities/stage.svg'
import Image from '~/components/common/Image'
import Jeunesse from '~/assets/activities/jeunesse.svg'

/**
 * <ActivityIcon />
 * @param {object} props 
 */

const PhotoContainer = styled.div`
  display: inline-block;
  overflow:hidden;
  width: 85px;
  height: 85px;
  margin-right: ${({activityType}) => activityType === 33 ? '-10' : '-20'}px;
  img{
    height: 100%;
    width: 100%;
  }
`
/* 29/03/2028 ... un arrière-plan tout pas beau derrière les icônes des activités
  background-color: ${({activityType}) => activityType === 33 ? '#E2E4E6' : '#24dd98'};
  border-radius: 50%;
*/

const ActivityIcon = (props) => {
  
  const ACTIVITY_TYPES = [
    {
      type: 0,
      label: 'cantine',
      url: Cantine,
      icon:
        <img alt="[Cantine]" src={Cantine} width={props.width} height={props.height} style={{
          marginTop: -60,
          right: 10,
          position: 'absolute'
        }}></img>
    },
    {
      type: 1,
      label: 'accueil du matin',
      url: Matin,
      icon: <img alt="[Accueil du matin]" src={Matin} width={props.width} height={props.height} style={{
        marginTop: -60,
        right: 10,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 2,
      label: 'garderie',
      url: Garderie,
      icon: <img alt="[Garderie]" src={Garderie} width={props.width} height={props.height} style={{
        marginTop: -60,
        right: 10,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 3,
      label: 'etude',
      url: Etude,
      icon: <img alt="[Etude]" src={Etude} width={props.width} height={props.height} style={{
        marginTop: -60,
        right: 10,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 4,
      label: 'stage',
      url: Stage,
      icon: <img alt="[Stage]" src={Stage} width={props.width} height={props.height} style={{
        marginTop: -60,
        right: 10,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 5,
      label: 'centre de loisirs',
      url: Centre,
      icon: <img alt="[Centre de loisirs]" src={Centre} width={props.width} height={props.height} style={{
        marginTop: -60,
        right: 10,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 6,
      label: 'ramassage scolaire',
      url: Ramassage,
      icon: <img alt="[Ramassage scolaire]" src={Ramassage} width={props.width} height={props.height} style={{
        marginTop: -60,
        right: 10,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 7,
      label: 'centre jeunesse',
      url: Jeunesse,
      icon: <img alt="[Centre jeunesse]" src={Jeunesse} width={props.width} height={props.height} style={{
        marginTop: -60,
        right: 10,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 98,
      label: 'mairie',
      url: Mairie,
      icon: <img alt="[Mairie]" src={Mairie} width={props.width} height={props.height} style={{
        marginTop: -60,
        right: 10,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 99,
      label: 'école',
      url: School,
      icon: <img alt="[Ecole]" src={School} width={props.width} height={props.height} style={{
        marginTop: -60,
        right: 10,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 33,
      label: 'enfants',
      url: Enfants,
      icon: <img alt="[Enfants]" src={Enfants} width={props.width} height={props.height} style={{
        marginTop: -60,
        right: 10,
        position: 'absolute'
      }}></img>,
    },
  ]

  const _getActivityIcon = () => {
    const activity = ACTIVITY_TYPES.find((act) => act.type === props.activityType)
    
    if (activity) {
      return <PhotoContainer activityType={props?.activityType}>
        <Image src={activity.url} alt="" style={props?.activityType === 33 ? {
          marginLeft: -5,
        } : {}}/>
      </PhotoContainer>
    }
    return null
  }

  return (
    <React.Fragment>
      {_getActivityIcon()}
    </React.Fragment>
  )
}

ActivityIcon.propTypes = {
  activityType: PropTypes.number,
  style: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number,
}

// ActivityIcon.defaultProps = {
//   activityType, style, width, height,
// }

export default ActivityIcon

