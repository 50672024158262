import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import App from '~/App'
import '~/lib/i18n'
import PageLoading from '~/components/common/PageLoading'

import * as serviceWorker from './serviceWorker'
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./lib/apolloClient";
import { createRoot } from "react-dom/client";

if (module.hot) {
  module.hot.accept()
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Suspense fallback={(<PageLoading />)}>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </Suspense>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
