import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Divider, Form, Input, Typography as Text } from "antd"
import { useTranslation } from "react-i18next"
import { BrowserRouter as Router, useNavigate } from "react-router-dom"

import Title from "~/components/auth/Title/index"
import Logo from "~/components/common/Logo/index"
import Centered from "~/components/common/Centered/index"
import Space from "~/components/common/Space/index"
import Layout from "~/components/auth/Layout"
import Container from "~/components/auth/Container/index"
import { resetPassword } from "~/containers/User/actions"

const PasswordUpdate = () => {
  const { t } = useTranslation("user")
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const handleSubmit = useCallback((values) => {
    dispatch(
      resetPassword({
        oldPassword: values.password,
        newPassword: values.new_password,
        onSuccess: () => navigate("/profile"),
      })
    )
  }, [])

  return (
    <Layout>
      <Container>
        <Space direction="vertical" size="large">
          <Centered>
            <Logo src="/images/common/logoedited.png" width="175" height="56" />
          </Centered>
          <Centered>
            <Title style={{fontSize:"1.3em"}}>Modifier votre mot de passe ... bonne idée 😉</Title>
          </Centered>

          {/* <ErrorComponent /> */}
          <Form
            layout="vertical"
            size="large"
            form={form}
            onFinish={handleSubmit}
          >
            <Form.Item
              label="👉 votre ancien mot de passe"
              name="password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t("profile.change-password-form.password-error"),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="👉 votre nouveau mot de passe"
              hasFeedback
              name="new_password"
              rules={[
                {
                  required: true,
                  message: t("profile.change-password-form.password-error"),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="👉 votre nouveau mot de passe ... juste pour être sûr"
              hasFeedback
              name="confirm"
              dependencies={["new_password"]}
              rules={[
                {
                  required: true,
                  message: t("profile.change-password-form.password-error"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      t("profile.change-password-form.confirm-password-error")
                    )
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              // loading={isForgotPasswordProcess}
              block
            >
              Enregistrer le nouveau mot de passe
            </Button>
          </Form>
          <Centered>
            {/* <BackLink
              to="/login"
            >
              Retour à la page de connexion
        </BackLink> */}
          </Centered>
        </Space>
      </Container>
    </Layout>
  )
}

export default PasswordUpdate
