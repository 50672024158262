import dayjs from "dayjs";

export const firstAndLastDayOfMonth = () => {
  const startOfMonth = dayjs()
    .clone()
    .startOf("month")
    .format("YYYY-MM-DD hh:mm");
  const endOfMonth = dayjs().clone().endOf("month").format("YYYY-MM-DD hh:mm");
};

export const getCurrentMonthDates = (month) => {
  dayjs.locale("fr");
  return new Array(dayjs(month).daysInMonth())
    .fill(null)
    .map((x, i) => dayjs(month).startOf("month").add(i, "days"));
};

export const getContainerLabel = (label) => {
  let res = label.split("-")[1];
  return res;
};
