import styled, {
  css,
} from 'styled-components'
import {
  Card,
  Tag,
  Button,
  Space,
} from 'antd'

export const Title = styled.h1`
  color: @neutral-5;
  font-size: 20px;
  margin-bottom: 20px;
  flex: 0 0 100%;
`

export const Count = styled.span`
  color: @neutral-4;
  font-size: 14px;
`

const card = css`
  width: 266px;
  position: relative;
  text-align: center;
  border-radius: 4px;
  border-color: #e2e4e6;
  margin-bottom: 23px;
  .ant-card-body {
    padding: 0;
  }
`

export const CustomCard = styled(Card)`
  margin-right: 24px;

  ${card};
  .ant-card-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
  }
`
export const CustomCardDushed = styled(Space)`
  ${card};
  border: 2px dashed #e2e4e6;
  min-height: 344px;

  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border: none;
    color: #969696;
  }
`

export const MiniTag = styled(Tag)`
  position: absolute;
  left: 8px;
  top: 8px;
`

export const Name = styled.span`
  color: @neutral-5;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin: 5px 0 0;
`

export const Email = styled.a`
  color: @neutral-4;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  margin: 4px 0;
`

export const SubText = styled.span`
  color: @neutral-4;
  font-size: 16px;
  line-height: 24px;
`

export const CustomButton = styled(Button)`
  color: @neutral-4;
  border-color: #e2e4e6;
  margin-top: 8px;
`

export const Options = styled(Button)`
  color: @neutral-4;
  border-color: #e2e4e6;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 10px;
`

export const CustomSpace = styled.div`
  width: 100%;
  padding: 40px 0 36px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`

export const SameHeightList = styled.div`
  display: flex;
  flex-flow: wrap;
`
export const Footer = styled.div`
  margin-top: auto;
  padding-bottom: 12px;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
`
