import React, { useState, useEffect } from 'react';
import {
  Calendar,
  Button,
} from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import P from '~/components/common/P';
import { daysLabel } from '~/utils/days';

import {
  CalendarHeader,
  Container,
  RecuDay,
  RecuDays,
  RecurrenceContainer,
  StyledDate,
} from './index';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter)

const ActivityCalendar = ({ activity, onChoose }) => {
  const [currentDay, setCurrentDay] = useState(dayjs());
  const [selectedOpeningDays, setSelectedOpeningDays] = useState([]);
  const [selectedReccurentDays, setSelectedReccurentDays] = useState({});
  const [selectedReccurentDaysArray, setSelectedReccurentDaysArray] = useState([]);
  const { t } = useTranslation('subscription');
  const goPreviousMonth = () => {
    setCurrentDay(dayjs(currentDay).subtract(1, 'months'));
  };
  const goNextMonth = () => {
    setCurrentDay(dayjs(currentDay).add(1, 'months'));
  };

  const renderHeader = () => {
    const previousMonth = dayjs(currentDay).subtract(1, 'months');
    const canGoBack = dayjs(previousMonth).isSameOrAfter(dayjs(), 'month');
    return (
      <CalendarHeader>
        <Button
          type="link"
          onClick={goPreviousMonth}
          disabled={!canGoBack}
        >
          <CaretLeftOutlined />
        </Button>
        <span>{currentDay.format('MMMM')} {currentDay.format('YYYY')}</span>
        <Button type="link" onClick={goNextMonth}>
          <CaretRightOutlined />
        </Button>
      </CalendarHeader>
    );
  };

  const returnSelectedData = () => ({
    added_dates: selectedOpeningDays,
    ...selectedReccurentDays,
  });

  /*
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const recurentDaysToLabels = () => daysLabel
    .filter(item => Object.keys(selectedReccurentDays).includes(item.key))
    .map(item => item.label)

  const isRecurentPreselectedDate = (date) => {
    const dayChar = capitalize(date.format('dd'))
    return recurentDaysToLabels().includes(dayChar)
  }
  */
  const onSelectOpeningDay = (date) => {
    const formattedDate = date.format('YYYY-MM-DD');
    if (!activity.opening_days.includes(formattedDate)) return;
    // if (isRecurentPreselectedDate(date)) return
    if (selectedOpeningDays.includes(formattedDate)) {
      setSelectedOpeningDays(selectedOpeningDays.filter(item => item !== formattedDate));
    } else {
      setSelectedOpeningDays([formattedDate, ...selectedOpeningDays]);
    }
  };

  const enumerateDaysBetweenDates = () => {
    const dates = [];
    const currDate = dayjs(activity.start_date).startOf('day');
    const lastDate = dayjs(activity.end_date).startOf('day');
    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      dates.push(currDate.clone().toDate());
    }
    return dates;
  };

  const onSelectRecurrent = (day) => {
    const selected = selectedReccurentDays;
    if (day in selected) delete selected[day];
    else selected[day] = true;
    setSelectedReccurentDays(selected);
    setSelectedReccurentDaysArray(Object.keys(selected));

    // Add recurent related dates
    let dayLabel = daysLabel.find(item => item.key === day);
    dayLabel = dayLabel ? dayLabel.label.toLowerCase() : '';
    const datesList = enumerateDaysBetweenDates();
    let recurrentDatesList = selectedOpeningDays;

    // Clear all recurent dates for => day
    datesList.forEach(item => {
      const bdate = dayjs(item);
      const bdateFormat = bdate.format('YYYY-MM-DD');
      if (bdate.format('dd') === dayLabel) {
        recurrentDatesList = recurrentDatesList.filter(rd => rd !== bdateFormat);
      }
    });

    // Fill the recurent dates
    if (selected[day]) {
      datesList.forEach(item => {
        const bdate = dayjs(item);
        const bdateFormat = bdate.format('YYYY-MM-DD');
        if (bdate.format('dd') === dayLabel && activity.opening_days.includes(bdateFormat)) {
          if (!recurrentDatesList.find(od => od === bdateFormat)) {
            recurrentDatesList.push(bdateFormat);
          } else {
            recurrentDatesList = recurrentDatesList.filter(rd => rd !== bdateFormat);
          }
        }
      });
    }
    setSelectedOpeningDays([...recurrentDatesList]);
  };

  const dateCellRender = date => {
    const day = date.format('YYYY-MM-DD');
    if (activity.opening_days.includes(day)) {
      return (
        <StyledDate
          isOpeningDay
          isSelected={selectedOpeningDays.includes(date.format('YYYY-MM-DD'))}
        >
          {date.format('D')}
        </StyledDate>
      );
    }

    return (
      <StyledDate>
        {date.format('D')}
      </StyledDate>
    );
  };

  useEffect(() => {
    onChoose(returnSelectedData());
  }, [selectedOpeningDays, selectedReccurentDays]);

  return (
    <Container>
      <RecurrenceContainer>
        <P small>{t('recurrence')}</P>
        <RecuDays>
          {daysLabel.filter(day => activity[day.key])
            .map(day => (
              <RecuDay
                onClick={() => onSelectRecurrent(day.key)}
                key={day.key}
                isSelected={selectedReccurentDaysArray.includes(day.key)}
              >
                {day.label}
              </RecuDay>
            ))}
        </RecuDays>
      </RecurrenceContainer>
      <Calendar
        value={currentDay}
        fullscreen={false}
        headerRender={renderHeader}
        dateFullCellRender={dateCellRender}
        onSelect={onSelectOpeningDay}
      />
    </Container>
  );
};

ActivityCalendar.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onChoose: PropTypes.func.isRequired,
};

export default ActivityCalendar;
