import React, { useMemo } from "react";
import styled from "styled-components";
import { BankOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import Space from "~/components/common/Space";
import Table from "~/components/common/Table";
// no-unused-vars :: import { PhoneOutlined, MailOutlined } from "@ant-design/icons"
import { MailOutlined, PhoneOutlined } from "@ant-design/icons"
import { useColumns } from "./columns";
import Loading from '~/components/common/Loading';
import { ACTIVITY_DETAILS } from "~/graphql/queries";
import { useQuery } from "@apollo/client";
import { getBillingPeriodLabel, getPeriodSelectionLabel } from "~/constants";
import { colors } from "~/utils/colors";
import Text from "~/components/common/Text";
import dayjs from "dayjs"

const ActivityDetail = ({
  setShowActivityDetails,
  activity,
  isLoadingOrganisationsActivitiesData,
}) => {
  const {
    loading: fetchingActivityDetails,
    error: errorActivityDetails,
    data: activityDetailsData,
  } = useQuery(ACTIVITY_DETAILS, {
    fetchPolicy: "no-cache",
    variables: {
      alias: activity?.actAlias,
    },
  });

  console.log({activity}, "+++++")

  const activityDetails = useMemo(() => {
    if (activityDetailsData) {
      return activityDetailsData?.activity;
    }
    return null;
  }, [activityDetailsData]);

  const isHouseholdExternal = useMemo(() => {
    if (activityDetailsData) {
      return activityDetailsData?.myHousehold?.isExternal;
    }

    return null;
  }, [activityDetailsData])
  
  const orderedPricePlanForNormal = useMemo(() => {
    if (activityDetails) {
      return activityDetails?.pricePlan?.priceplanitemSet?.sort((a, b) => a.category.minValue - b.category.minValue);
    }

    return null;
  }, [activityDetails])

  const orderedPricePlanForExternal = useMemo(() => {
    if (activityDetails) {
      return activityDetails?.pricePlanForExternal?.priceplanitemSet?.sort((a, b) => a.category.minValue - b.category.minValue);
    }

    return null;
  }, [activityDetails])

  const pricePlanItems = useMemo(() => {
    if (isHouseholdExternal) {
      return orderedPricePlanForExternal;
    }

    return orderedPricePlanForNormal;
  }, [isHouseholdExternal, orderedPricePlanForExternal, orderedPricePlanForNormal])

  const pricePlanForNormalBillingModel = useMemo(() => {
    if (activityDetails) {
      return Number(activityDetails?.pricePlan?.billingModel?.replace("A_", ""));
    }
    return null;
  }, [activityDetails])

  const pricePlanForExternalBillingModel = useMemo(() => {
    if (activityDetails) {
      return activityDetails?.pricePlanForExternal?.billingModel?.key;
    }

    return null;
  }, [activityDetails])
  
  const pricePlanBillingModel = useMemo(() => {
    if (isHouseholdExternal) {
      return pricePlanForExternalBillingModel;
    }
    
    return pricePlanForNormalBillingModel;
  }, [isHouseholdExternal, pricePlanForExternalBillingModel, pricePlanForNormalBillingModel])

  const pricePlanForNormalBillingModelTimeUnit = useMemo(() => {
    if (pricePlanBillingModel === 1) {
      return activityDetails?.pricePlan?.timeUnit;
    }

    return null;
  }, [pricePlanBillingModel])

  const pricePlanForExternalBillingModelTimeUnit = useMemo(() => {
    if (pricePlanBillingModel === 1) {
      return activityDetails?.pricePlanForExternal?.timeUnit;
    }

    return null;
  }, [pricePlanBillingModel])

  const pricePlanBillingModelTimeUnit = useMemo(() => {
    if (isHouseholdExternal) {
      return pricePlanForExternalBillingModelTimeUnit;
    }

    return pricePlanForNormalBillingModelTimeUnit;
  }, [isHouseholdExternal, pricePlanForExternalBillingModelTimeUnit, pricePlanForNormalBillingModelTimeUnit])

  const pricePlanForNormalPeriod = useMemo(() => {
    if (activityDetails) {
      return activityDetails?.pricePlan?.period;
    }

    return null;
  }, [activityDetails])

  const pricePlanForExternalPeriod = useMemo(() => {
    if (activityDetails) {
      return activityDetails?.pricePlanForExternal?.period;
    }

    return null;
  }, [activityDetails])

  const pricePlanPeriod = useMemo(() => {
    if (isHouseholdExternal) {
      return pricePlanForExternalPeriod;
    }

    return pricePlanForNormalPeriod;
  }, [isHouseholdExternal, pricePlanForExternalPeriod, pricePlanForNormalPeriod])

  const billingPeriodForNormal = useMemo(() => {
    if (activityDetails) {
      return Number(activityDetails?.pricePlan?.billingPeriod?.replace("A_", ""));
    }

    return null;
  }, [activityDetails])

  const billingPeriodForExternal = useMemo(() => {
    if (activityDetails) {
      return activityDetails?.pricePlanForExternal?.billingPeriod;
    }

    return null;
  }, [activityDetails])

  const billingPeriod = useMemo(() => {
    if (isHouseholdExternal) {
      return billingPeriodForExternal;
    }

    return billingPeriodForNormal;
  }, [isHouseholdExternal, billingPeriodForExternal, billingPeriodForNormal])

  const periodSelection = useMemo(() => {
    if (activityDetails) {
      return activityDetails?.periodSelection?.key;
    }

    return null;
  }, [activityDetails])
  console.log({isHouseholdExternal, billingPeriod, billingPeriodAct: activityDetails?.pricePlan?.billingPeriod, pricePlanPeriod, pricePlanForNormalBillingModel, pricePlanForExternalBillingModel, pricePlanBillingModel, activityDetails, periodSelection}, "+++++")

  const columns = useColumns();

  if (isLoadingOrganisationsActivitiesData) {
    return (
      <>
        <FullBackground></FullBackground>
        <PopUp>
          <Loading />
        </PopUp>
      </>
    );
  }

  return (
    <>
      <FullBackground></FullBackground>
      <PopUp>
        <TopBar>
          <Cross onClick={() => setShowActivityDetails(false)}>
            <CloseCircleOutlined />
          </Cross>
        </TopBar>
        <Container>
          <BText><BankOutlined /> {activity?.organisationName} : {activity?.name}</BText>
          <br/>
          <Text>👉 activité ouverte du 📆 {dayjs(activity?.start_date, "YYYY-MM-DD").format("ddd D MMMM YYYY")} au{" "}
          {dayjs(activity?.end_date, "YYYY-MM-DD").format("ddd D MMMM YYYY")} de 🕞{" "}
          {activity?.start_time?.substring(0,5).replace(":","h")} à {activity?.end_time?.substring(0,5).replace(":","h")}</Text>
          {activity?.description && (
            <>
              <XLText>Informations</XLText>
              <SText>
                {activity?.description}
              </SText>
              
            </>
          )}
          <XLText>Tarifs</XLText>
          <Table
            columns={columns}
            dataSource={pricePlanItems || []}
            // rowSelection={{
            //   type: selectionType,
            //   ...rowSelection,
            // }}
            // onRowSelectionChange={setRowSelection}
            // isLoading={isLoading}
            // onChange={onTableChange}
            pageSize={6} // length
            isLoading={fetchingActivityDetails}
          />
        
          <div
            style={{
              width: "100%",
              padding: 30,
              marginTop: 20,
              backgroundColor: colors.cvBlueGreyLighter,
              borderRadius: 10,
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                color: colors.cvBlueText
              }}
            >
              {pricePlanBillingModel === 0 && (
                <>
                  Tarif
                <Text style={{color: colors.cvRed}}>*</Text> 👉 <span style={{fontWeight:400}}>appliqué par réservation ou présence</span>
                </>
              )}
              {pricePlanBillingModel === 1 && (
                <>
                  Tarif
                <Text style={{color: colors.cvRed}}>*</Text> 👉 <span style={{fontWeight:400}}>appliqué au temps passé
                avec un arrondi de {pricePlanBillingModelTimeUnit} minutes</span>
                </>
              )}
              {pricePlanBillingModel === 2 && (
                <>
                  {periodSelection === 0 && (
                    <>
                      Tarif
                      <Text style={{color: colors.cvRed}}>*</Text> 👉 <span style={{fontWeight:400}}>forfait appliqué pour toute l'activité et facturé à la 1ère réservation ou présence</span>
                    </>
                  )}
                  {periodSelection === 1 && (
                    <>
                      Tarif
                      <Text style={{color: colors.cvRed}}>*</Text> 👉 <span style={{fontWeight:400}}>forfait appliqué à
                      chaque semaine dès la 1ère réservation ou présence</span>
                    </>
                  )}
                  {periodSelection >= 2 && (
                    <>
                      Tarif
                      <Text style={{color: colors.cvRed}}>*</Text> 👉 
                      {!!periodSelection && !!billingPeriod && (<span style={{fontWeight:400}}> forfait appliqué{" "}
                      {pricePlanPeriod === "A_0" ? "à toute l'activité" : getPeriodSelectionLabel(periodSelection).toLocaleLowerCase()}
                      {" "}et facturé{" "}
                      {getBillingPeriodLabel(billingPeriod).toLocaleLowerCase()}</span>)}
                      {(!billingPeriod) && (<> forfait 🥲 <span style={{color:"red", fontWeight:"normal",}}>... votre organisation n'a pas encore précisé si le forfait s'applique à toute l'activité ou {getPeriodSelectionLabel(periodSelection).toLocaleLowerCase()} et quand il est facturé</span></>)}
                    </>
                  )}
                </>
              )}
            </Text>
          </div>
        </Container>
        {(activity?.phone || activity?.email) && (
          <>
          <Divider></Divider>
          <Footer>
            <XLText>Les contacts</XLText>
            <Space direction="horizontal">
              {activity?.email && (
                <Space direction="horizontal" style={{ paddingRight: 10 }}>
                  <MailOutlined />{activity?.email}
                </Space>
              )}

              {activity?.phone && (
                <Space direction="horizontal" style={{ paddingRight: 10 }}>
                  <PhoneOutlined />{activity?.phone}
                </Space>
              )}
            </Space>
          </Footer>
          </>
        )}
      </PopUp>
    </>
  );
};

const FullBackground = styled.div`
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: #1e1e1e;
  opacity: 0.9;
`;

const PopUp = styled.div`
  height: 90vh;
  width: 80vw;
  margin: 0 auto;
  position: fixed;
  z-index: 101;
  background: white;
  opacity: 1;
  top: 5vh;
  right: 0px;
  // bottom: 0px;
  left: 0px;
  border-radius: 8px;
  overflow: auto;
`;

const TopBar = styled.div`
  height: 85px;
  background: #2f4580;
`;

const Cross = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  font-size: 20px;
  cursor: pointer;
`;

const MText = styled.div`
  line-height: 22px;
  font-size: 14px;
`;

const SText = styled.div`
  line-height: 14px;
  font-size: 12px;
`;

const BText = styled.div`
  line-height: 28px;
  font-size: 20px;
  color: #2f4580;
`;

const XLText = styled.div`
  line-height: 52px;
  font-size: 16px;
  color: @neutral-5;
`;

const Container = styled.div`
  padding: 24px;
`;

const Footer = styled.div`
  padding-left: 24px;
  padding-bottom: 24px;
`;

export default ActivityDetail;
