import styled from "styled-components"
import { Menu } from "antd"

export const Container = styled(Menu)`
  line-height: 68px;
  margin-right: -64px;

  & .ant-menu-item {
    padding: 0px;
  }
`
