import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Divider } from 'antd';
import dayjs from 'dayjs'
import { startCase } from 'lodash'
import { BankOutlined } from '@ant-design/icons'
import Text from '~/components/common/Text'
import { getBillingModel, getPaymentMethod } from '~/constants/paymentMethods'

export const ActionsContainer = styled.span`
  display: flex;
  flex-direction: row;
  .pay {
    display: inline-block;
    padding-left: 5px;
    margin-left: 5px;
    border-left: solid 1px @grey-color;
  }
`

const getAllStatus = (bill) => {

  //return `${bill?.status}`
  if (bill?.type !== 2) {
    if (bill?.status === 4) {
      return 'Transféré au Trésor public'
    } if (bill?.status === 5) {
      return 'Prélèvement programmé'
    } if (bill?.bill_status?.bill_is_paid) {
      return 'Payé'
    }
    if(bill?.bill_status?.remaining_amount < 0){
      return `Trop perçu : ${Math.abs(bill?.bill_status?.remaining_amount.toString().replace('.', ','))} €`
    }
    if (bill?.bill_status?.remaining_amount) {
      return <span style={{color: '#FF5876'}}>À payer : {bill?.bill_status?.remaining_amount.toString().replace('.', ',')} €</span>
    }
    return '-'
  }
  // Avoir
  if (bill?.type === 2) {
    if (bill?.bill_status?.remaining_amount && bill?.bill_status?.amount_paid > 0) {
      return <span style={{color: '#24DD98'}}>Reste : {bill?.bill_status?.remaining_amount.toString().replace('.', ',')} €</span>
    }
    return '-'
  }

  return '-'
}

const getPaymentStatus = (paiement) => {
  if (paiement?.some(item => item?.method === 0 && item?.payment_result === 0)) {
    return ' ✅'
  } else if (paiement?.some(item => item?.method === 0 && item?.method === 1)) {
    return ' ❌'
  } else if (paiement?.some(item => item?.method === 0 && item?.method === 2)) {
    return ' 🚫'
  } else {
    return '- '
  }
}

export const useColumnsInvoices = (onStatusChange, setShowDrawer, handleDownloadPDF, search) => useMemo(
  () => [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: search === '' ? (a, b) => dayjs(a.date, 'DD MMMM YYYY').diff(dayjs(b.date, 'DD MMMM YYYY')) : false,
      width: '10%',
      align: 'left',
      render: (date) => <Text>{date}</Text>,
    },
    {
      title: 'Organisation',
      dataIndex: 'organisation',
      key: 'organisation',
      sorter: search === '' ? (a, b) => (a?.organisation && b?.organisation
        ? a.organisation.localeCompare(b.organisation) : 0)
        : false,
      width: '14%',
      render: (organisation) => (
        <Text>
          <span><BankOutlined /> {organisation}</span>
        </Text>
      ),
    },
    {
      title: 'N°',
      dataIndex: 'billingNumber',
      key: 'billingNumber',
      sorter: search === '' ? (a, b) => (a?.billingNumber && b?.billingNumber
        ? a.billingNumber.localeCompare(b.billingNumber) : 0)
        : false,
      width: '8%',
      align: 'left',
      render: (number) => <Text>{number}</Text>,
    },
    {
      title: 'Type',
      // dataIndex: 'type',
      key: 'type',
      sorter: search === '' ? (a, b) => a.type - b.type : false,
      width: '10%',
      align: 'left',
      render: (data) => (
          <Text>
            {getBillingModel(data?.type)?.label}
            <p>{startCase(dayjs(data?.period, 'MMMM YYYY').format('MMMM YYYY'))}</p>
          </Text>
      ),
    },
    {
      title: () => <span style={{ marginRight: '0.5rem' }}>Montant</span>,
      dataIndex: 'montant',
      key: 'montant',
      sorter: search === '' ? (a, b) => a.montant - b.montant : false,
      width: '10%',
      align: 'right',
      render: (montant) => <Text><span style={{ marginRight: '2rem' }}>{montant.toString().replace('.', ',')} €</span></Text>,
    },
    {
      title: 'État',
      dataIndex: 'state',
      key: 'state',
      sorter: search === '' ? (a, b) => {
        const stateA = getAllStatus(a.state)
        const stateB = getAllStatus(b.state)

        if (stateA < stateB) {
          return -1
        }
        if (stateA > stateB) {
          return 1
        }
        return 0
      } : false,
      width: '12%',
      align: 'left',
      render: (status) =><Text>{getAllStatus(status)}</Text>,
    },
    {
      title: 'Paiements',
      key: 'paiement',
      sorter: search === '' ? (a, b) => {
        const paymentAccepted= getPaymentStatus(a.paiement);
        const paymentRefused = getPaymentStatus(b.paiement);
        return paymentAccepted.localeCompare(paymentRefused);
      } : false,
      width: '20%',
      align: 'left',
      render: ({type, status, paiement, credit_used_in_bills}) => (
        <>
          {type === 2 ? (
            <>
              {credit_used_in_bills?.length ? credit_used_in_bills?.map((item, index) => (
                <>
                  <Text style={{ fontSize: 'small' }} key={index}>
                    {index > 0 ? <Divider style={{margin: '10px 0'}} /> : ''}
                    {dayjs(item.issue_date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH[h]mm")} : {item?.amount.toString().replace('.', ',')} €
                    <p>Facture n° {item?.bill_number}</p>
                  </Text>
                </>
              )) : '-'}
            </>
          ) : (
            <>
              {paiement?.length && status !== 4 && status !== 5 ? paiement?.map((item, index) => (
                <Text style={{ fontSize: 'small' }} key={index}>
                  {index > 0 ? <Divider style={{margin: '10px 0'}} /> : ''}
                    {(item?.method !== 1 && item?.method !== 9) ? dayjs(item.date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH[h]mm") : dayjs(item.date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY")} : {item?.amount.toString().replace('.', ',')} €
                    <p>
                    {/* en ligne par CB */}
                    {item?.method === 0 && getPaymentMethod(item?.method).label }{' '}  
                    {item?.method === 0 && item?.payment_result === 0 && ' ✅'}
                    {item?.method === 0 && item?.payment_result === 1 && ' ❌'}
                    {item?.method === 0 && item?.payment_result === 2 && ' 🚫'}
                    {/* prélèvement */}
                    {item?.method === 1 && getPaymentMethod(item?.method).label }
                    {/* chèque */}
                    {item?.method === 2 && getPaymentMethod(item?.method).label }
                    {/* CESU */}
                    {item?.method === 3 && getPaymentMethod(item?.method).label }
                    {/* espèces */}
                    {item?.method === 4 && getPaymentMethod(item?.method).label }
                    {/* prélèvement PayFIP */}
                    {item?.method === 5 && getPaymentMethod(item?.method).label }
                    {/* chèques vacances */}
                    {item?.method === 6 && getPaymentMethod(item?.method).label }
                    {/* autre */}
                    {item?.method === 7 && getPaymentMethod(item?.method).label }
                    {/* bons loisirs CAF */}
                    {item?.method === 8 && getPaymentMethod(item?.method).label }
                    {/* trésorerie */}
                    {item?.method === 9 && getPaymentMethod(item?.method).label }
                    {/* Post-paiement || Pré-paiement ? Avoir : '' */}
                    {item?.method === 10 && item?.credit_number && `Avoir n° ${item?.credit_number}`}
                    {/* trop-perçu */}
                    {item?.method === 11 && getPaymentMethod(item?.method).label }
                  </p> 
                </Text>
              )) : '-'}
            </>
          )}
        </>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      align: 'left',
      render: (_, record) => (
        <ActionsContainer>
          <a
            href="#"
            onClick={() => handleDownloadPDF(
              record.token,
              record.bill_pdf_url,
              record.billingNumber,
            )}
          >
            Télécharger
          </a>
          {record?.type !== 2 && record?.status !== 4 && record?.status !== 5 && (
            <span className="pay">
              {record.bill_status && record.bill_status.bill_is_paid ? (
                <span>Payée</span>
              ) : (
                <a
                  href={record.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {record.total_amount}
                  Payer
                </a>
              )}
            </span>
              )}
        </ActionsContainer>
      ),
    },
  ],
  [onStatusChange, handleDownloadPDF, search],
)

