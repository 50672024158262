import styled from "styled-components"
import { Layout, Menu, Drawer } from "antd"

export const NavBar = styled(Layout.Header)`
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 11;
  background-color: #0f2d78;
  overflow: hidden;
`

export const NavBarMenu = styled(Menu)`
  line-height: 68px;
  margin: 0 30px;
`

export const LoggedUser = styled.div`
  margin-left: auto;

  .ant-menu-light {
    background-color: #0f2d78;
    color: white;
    margin-left: 20px;
  }
`

export const NavBarItem = styled(Menu.Item)``

export const DrawerHeader = styled(Layout.Header)`
  display: flex;
  margin-left: auto;
  padding: 0;
`
export const DrawerWrap = styled(Drawer)`
  text-align: center;
  border-right: 0px;

}
`

export const MenuDrawer = styled(Menu)`
  display: flex;
  flex-direction: column;
  color: red;
  border: none;
`
