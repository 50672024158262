import styled from 'styled-components'
import {
  CloseCircleOutlined,
} from '@ant-design/icons'

export const CustomCloseCircleOutlined = styled(CloseCircleOutlined)`
  font-size: 24px;
  margin: 10px 3px;
`

export const Title = styled.h3`
  border-bottom: none;
  font-size: 20px;
  color: @neutral-5;
  margin-top: 16px;
`
