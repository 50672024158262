import React, { useCallback, useState, useEffect } from "react";
import {
  LeftOutlined,
  RightOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  CalendarOutlined,
  FrownOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Space from "~/components/common/Space";
import H1 from "~/components/common/H1";
import { getCurrentMonthDates, firstAndLastDayOfMonth } from "./functions";
import { data } from "./data";
import Drawer from "~/components/common/Drawer";
import {
  Modal,
  message,
  Button,
  Result,
  Tooltip,
  Avatar,
  Collapse,
  Spin,
  Col,
  Row,
} from "antd";
import Loading from "~/components/common/Loading";

import {
  fetchChildren,
  fetchChildDetails,
  fetchSelectedChildrenDetails,
  deleteAttendance,
  cancelCreditRequest,
} from "~/containers/Children/actions";
import {
  Calendar,
  Horizontal,
  Border,
  DayContainer,
  DayLetter,
  DayNumber,
  Day,
  OrgContainer,
  ActivityContainer,
  ActivityName,
  Box,
  Case,
  Draw,
  Recurrent,
  Description,
  DescriptionContainer,
  DescriptionContainerRight,
  Dot,
  DynamicPadding,
  DescWrapper,
  LoadingWrapper,
  OrgActivitiesContainer,
  BadgePill
} from "./component";
import {
  fetchChildAttendances,
  fetchOpeningdays,
  fetchChildrengardeplanningdays,
  fetchAllOpeningdays,
  allChildrenAttendancesFetch,
  allChildrenAttendancesFetching,
  patchAttendance,
  createAttendance,
  requestCredit
} from "~/containers/Children/actions";
import { select } from "@redux-saga/core/effects";
import H2 from "~/components/common/H2";
import Form from "antd/lib/form/Form";
import EditActivity from "~/containers/Children/Activity/EditActivity";
import SubActivity from "~/containers/Children/Activity/SubActivity";
import ActivityDetail from "~/containers/Children/Activity/ActivityDetail";
import manageSelectedCell from "~/utils/manageSelectedCell";
import cellWrapper from "~/utils/cellWrapper";
import { organisationsActivitiesFetching } from "~/containers/Subscription/actions";
import Centered from "~/components/common/Centered";
import { userOrganisationsFetching } from "~/containers/Home/actions";
import { Container, MainContent } from "~/components/common/Container";
import {
  addPaidAttendance,
  removePaidAttendance,
  massDeleteAttendances,
} from "~/containers/Cart/actions";

import { getActivitiesPeriodSelection,  getOpeningPeriodSelectionLabel, getConfirmPeriodReservationLabel, getBadgeColor } from "~/constants";
import { getContainerLabel } from "~/containers/Children/Activity/functions";
import isBoolean from "lodash/isBoolean";

const { Panel } = Collapse;

const ActivityCalendar = ({
  selectedMonth,
  setSelectedMonth,
  hideFinishedActivities,
  setHideFinishedActivities,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentMonth = dayjs().format("MMMM YYYY");
  const currentMonthDates = getCurrentMonthDates(selectedMonth);
  const [selectedCell, setSelectedCell] = useState(null);
  const [isHover,setIsHover] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredCellContainerLabel, setHoveredCellContainerLabel] = useState("");
  const [markedDates, setMarkedDates] = useState({});
  const [showDrawer, setShowDrawer] = useState(false);
  const [showSubDrawer, setShowSubDrawer] = useState(false);
  const [recu, setRecu] = useState(null);
  const [showActivityDetails, setShowActivityDetails] = useState(false);
  const [activityDetails, setActivityDetails] = useState(null);
  const [activitiesByOrganisations, setActivitiesByOrganisations] =
    useState(null);

  const { userOrganisationsList } = useSelector(
    (state) => state.home.organisation
  );

  const { isCreating, isFetching, isPatching } = useSelector(
    (state) => state.children.attendance
  );

  const {
    organisationsActivities: {
      data: organisationsActivitiesData,
      isLoading: isLoadingOrganisationsActivitiesData,
    },
  } = useSelector((state) => state.subscription);

  const { data: orders } = useSelector((state) => state.cart.orders);
  const { isRemovingAttendance } = useSelector((state) => state.cart);

  const {
    childDetails: child,
    isFetchingChildDetails,
    children,
    isFetching: isFetchingChildren,
    attendance,
    selectedChildrenDetails,
  } = useSelector((state) => state.children);

  //

  const { registrations, subscriptions } = child;

  useEffect(() => {
  //   //
    dispatch(fetchChildren());
        if (params.id) {
            // dispatch(childRegistrationFetching(params.id));
            const thisChild = children.find((ch) => ch.alias === params.id);
            dispatch(fetchChildDetails({ url: thisChild?.url }));
        }
  }, [params.id]);

  useEffect(() => {
    // if (!isCreating && !isPatching) {

    if (!isFetchingChildDetails) {
      dispatch(
        allChildrenAttendancesFetching({
          month: dayjs(selectedMonth).format("M"),
          year: dayjs(selectedMonth).format("YYYY"),
        })
      );
      // fetchAttendances();
      // fetchAttendances2();
    }
    // }
  }, [
    isFetchingChildDetails,
    // child,
    // subscriptions,
    // isFetchingChildren,
     selectedMonth,
    // isCreating,
    // isPatching,
  ]);

  const allAttendances = useSelector(
    (state) => state.children.attendance.allChildrenAttendances
  );
  
  // console.log("allAttendances", allAttendances);

  //
  //

  const { allOpeningDays: openingDays, allfetchingOpeningDays } = useSelector(
    (state) => state.children.attendance
  );

  /*const openingDays = useSelector(
    (state) => state.children.attendance.allOpeningDays
  );*/

  //a modifier , renvoi juste le garde planning de l'enfant actif
  const gardeplaningDays = useSelector(
    (state) => state.children.childrengardeplanningdays
  );

  //check si un planning garde existe
  
  let pgd_exist = null;
  if (gardeplaningDays[child.alias]){
    pgd_exist = gardeplaningDays[child.alias].some(obj => obj.hasOwnProperty('garde_type'));
  }

  const requestCreditAction = useCallback(
    (attAlias) => {
      dispatch(
        requestCredit({
          attAlias,
          month: dayjs(selectedMonth).format("M"),
          year: dayjs(selectedMonth).format("YYYY"),
        })
      );
    },
    [dispatch, selectedMonth]
  );

  const cancelCreditRequestAction = useCallback(
    (attAlias) => {
      dispatch(
        cancelCreditRequest({
          attAlias,
          month: dayjs(selectedMonth).format("M"),
          year: dayjs(selectedMonth).format("YYYY"),
        })
      );
    },
    [dispatch, selectedMonth]
  );

  useEffect(() => {
    if(isHover){
      let hoveredCell = isHover?.openDay?.find((d) => dayjs(d.date).format("YYYY-MM-DD") === dayjs(isHover?.day).format("YYYY-MM-DD"));
      let label="";
      if(hoveredCell.container_label){
        label = getContainerLabel(hoveredCell.container_label);
        setHoveredCellContainerLabel(label);
        setIsHovered(true);
      }else{
        setHoveredCellContainerLabel(label);
        setIsHovered(false);
      }      
    }else{
      setIsHovered(false);
    }

  }, [isHover]);

  useEffect(() => {
    manageSelectedCell(
      selectedCell,
      // child,
      dispatch,
      // patchAttendance,
      deleteAttendance,
      addPaidAttendance,
      removePaidAttendance,
      activitiesBillingModel,
      organisationsActivitiesData,
      allAttendances,
      orders,
      subscriptions,
      massDeleteAttendances,
      requestCreditAction,
      cancelCreditRequestAction,
      userOrganisationsList,
    );
  }, [selectedCell, userOrganisationsList]);

  useEffect(() => {
    // let aliases = [];

    //
    //
    // if (subscriptions) {
    //   for (let sub of subscriptions) {
    //     if (aliases.find((s) => sub.parent_activity_alias === s) === undefined) {
    //       aliases.push({
    //         org_alias: sub.organisation_alias,
    //         activity_alias: sub.activity_alias,
    //       });
    //     }
    //   }
    // }
    dispatch(
      fetchAllOpeningdays({
        // aliases,
        month: dayjs(selectedMonth).format("M"),
        year: dayjs(selectedMonth).format("YYYY"),
      })
    );
  }, [selectedMonth]);

  useEffect(() => {
    if (pgd_exist === null || pgd_exist) {
      dispatch(
        fetchChildrengardeplanningdays({
          month: dayjs(selectedMonth).format("M"),
          year: dayjs(selectedMonth).format("YYYY"),
        })
      );
    }
  }, [selectedMonth, pgd_exist]);

  // const fetchAttendances = useCallback(() => {
  //   const childAlias = child.alias;
  //   if (!subscriptions?.length || !child.alias) return;

  //   let aliases = [];

  //   for (let sub of subscriptions) {
  //     aliases.push({
  //       org_alias: sub.organisation_alias,
  //       activity_alias: sub.activity_alias,
  //     });
  //   }

  //   dispatch(
  //     allChildrenAttendancesFetching({
  //       month: dayjs(selectedMonth).format("M"),
  //       year: dayjs(selectedMonth).format("YYYY"),
  //     })
  //   );
  // }, [selectedMonth, child?.alias]); // deleted child

  //

  // let uniqueItems = [...new Set(state.alias)];
  // let org_data = [];

  useEffect(() => {
    setActivitiesByOrganisations(
      subscriptions?.reduce((acc, cur) => {
        acc[cur.organisation_alias] = acc[cur.organisation_alias]
          ? [...acc[cur.organisation_alias]]
          : [];

        if (hideFinishedActivities) {
          if (
            organisationsActivitiesData?.[cur?.organisation_alias]?.find(
              (act) =>
                act?.actAlias === cur?.parent_activity_alias &&
                act?.status !== 2
            )
          ) {
            const curOpeningDays = openingDays?.filter(
              (day) => day?.activity_alias === cur?.parent_activity_alias
            );
            const curAttendance = allAttendances?.filter(
              (att) =>
                att?.activity_alias === cur?.parent_activity_alias &&
                child?.alias === att?.child_alias
            );

            if (
              !acc[cur.organisation_alias]?.find(
                (sub) =>
                  sub.parent_activity_alias === cur?.parent_activity_alias
              )
            ) {
              acc[cur.organisation_alias] = [
                ...acc[cur.organisation_alias],
                {
                  ...cur,
                  attendance: curAttendance,
                  openingDays: curOpeningDays,
                },
              ];
            }
          }
        } else {
          const curOpeningDays = openingDays?.filter(
            (day) => day?.activity_alias === cur?.parent_activity_alias
          );
          const curAttendance = allAttendances?.filter(
            (att) =>
              att?.activity_alias === cur?.parent_activity_alias &&
              child?.alias === att?.child_alias
          );

          if (
            !acc[cur.organisation_alias]?.find(
              (sub) => sub.parent_activity_alias === cur?.parent_activity_alias
            )
          ) {
            acc[cur.organisation_alias] = [
              ...acc[cur.organisation_alias],
              {
                ...cur,
                attendance: curAttendance,
                openingDays: curOpeningDays,
              },
            ];
          }
        }
        return acc;
      }, {})
    );
  }, [
    subscriptions,
    openingDays,
    allAttendances,
    hideFinishedActivities,
    organisationsActivitiesData,
    selectedMonth,
  ]);

  // const activitiesByOrganisations = subscriptions?.reduce((acc, cur) => {
  //   const curOpeningDays = openingDays?.filter(day => day?.activity === cur?.activity_alias)
  //   const curAttendance = allAttendances?.filter(att => att?.activity_alias === cur?.parent_activity_alias)

  //   acc[cur.organisation_alias] = acc[cur.organisation_alias] ? [...acc[cur.organisation_alias]] : []
  //   acc[cur.organisation_alias] = [...acc[cur.organisation_alias], {
  //     ...cur,
  //     attendance: curAttendance,
  //     openingDays: curOpeningDays,
  //   }]
  //   return acc
  // }, {})

  const organisationsHavingActivities =
    activitiesByOrganisations && Object.keys(activitiesByOrganisations);

  useEffect(() => {
    dispatch(organisationsActivitiesFetching(
      organisationsHavingActivities,
    ))
  }, [organisationsHavingActivities?.length])

  const activitiesBillingModel = organisationsHavingActivities?.reduce(
    (acc, cur) => {
      const activities = organisationsActivitiesData?.[cur];
      acc[cur] = acc[cur] ? [...acc[cur]] : [];

      Array.isArray(activities) &&
        activities.forEach((activity) => {
          acc[cur] = {
            ...acc[cur],
            [activity.actAlias]: activity?.paymentMethod,
          };
        });

      return acc;
    },
    {}
  );

  // for (let sub of subscriptions) {
  //   if (org_data.length === 0)
  //     org_data.push({ subs: [{ ...sub, attendance: [], openingDays: [] }] });
  //   else {
  //     for (let i = 0; i < org_data.length; i++) {
  //       if (
  //         org_data[i].subs[0].org_alias === sub.org_alias &&
  //         org_data[i].subs.find(
  //           (s) => sub.parent_activity_alias === s.parent_activity_alias
  //         ) === undefined
  //       )
  //         org_data[i].subs.push({ ...sub, attendance: [], openingDays: [] });
  //     }
  //   }
  // }

  //

  // for (let org of org_data) {
  //   for (let sub of org.subs) {
  //     for (let att of allAttendances) {
  //       if (att.activity_alias === sub.parent_activity_alias) {
  //         sub.attendance.push(att);
  //       }
  //     }
  //     if (openingDays) {
  //       for (let open of openingDays) {
  //         if (open.activity === sub.activity_alias) {
  //           sub.openingDays.push(open);
  //         }
  //       }
  //     }
  //   }
  // }

  useEffect(() => {
    if (children.length > 0) {
      const childrenDetailsUrls = children.map((child) => child.url);

      dispatch(
        fetchSelectedChildrenDetails(
          childrenDetailsUrls
          // () => {}
        )
      );
    }
  }, [children.length]);

  const reccurent = (sub) => {
    setRecu(sub);
    setShowDrawer(true);
  };

  // Now calculate and output the difference

  // const standarizeOrgData = org_data.
  //

  //
  //
  //

  useEffect(() => {
    dispatch(userOrganisationsFetching());
  }, []);

  firstAndLastDayOfMonth();

  // Helper function to check if an activity is finished
const isActivityFinished = (activitiesData, orgId, activity) => {
  return !activitiesData?.[orgId]?.find(
    (act) => act.actAlias === activity.parent_activity_alias
  );
};

// Helper function to process an organisation's activities
const processOrganisationActivities = (
  activitiesByOrganisations,
  organisationsActivitiesData,
  orgId
) => {
  const curOrg = activitiesByOrganisations?.[orgId];

  return curOrg.reduce((acc, act) => {
    if (isActivityFinished(organisationsActivitiesData, orgId, act)) {
      return [...acc, act.parent_activity_alias];
    }
    return acc;
  }, []);
};

const finishedActivities = () => {
  // If the hideFinishedActivities flag is set, process the activities
  if (hideFinishedActivities) {
    return (
      organisationsActivitiesData &&
      organisationsHavingActivities?.reduce((acc, cur) => {
        const finishedActivitiesForOrg = processOrganisationActivities(
          activitiesByOrganisations,
          organisationsActivitiesData,
          cur
        );

        return [...acc, ...finishedActivitiesForOrg];
      }, [])
    );
  }

  // If the flag is not set, return an empty array
  return [];
};
// const noticeInfo=undefined;
// if (activityDetails?.notice['all_open']){
//   noticeInfo=(<p>réservable: </p><p>{getActivitiesPeriodSelectionLabel(activityDetails?.period_selection)}</p>
// }

  if (isLoadingOrganisationsActivitiesData) {
    return (
      <Container>
        <Centered>
          <Spin />
        </Centered>
      </Container>
    );
  }

  return (
    <Calendar>
      {subscriptions?.length === 0 && (
        <Result
          icon={<FrownOutlined />}
          title={`${child.first_name} n'est inscrit${
            child.gender === 1 ? "e" : ""
          } dans aucune activité!`}
          extra={
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              onClick={() => navigate(`/reservation/${child.alias}`)}
            >
              <CalendarOutlined />
              Inscrire à une activité
            </Button>
          }
        />
      )}
      {subscriptions?.length > 0 &&
        organisationsHavingActivities
        ?.sort((a, b) => {
          const organisationDetailsA = userOrganisationsList?.find(
            (org) => org.alias === a
          );
          const organisationDetailsB = userOrganisationsList?.find(
            (org) => org.alias === b
          );
          const organisationNameA = organisationDetailsA?.name;
          const organisationNameB = organisationDetailsB?.name;

          return organisationNameA?.localeCompare(
            organisationNameB
          );
        })?.map((orgAlias, index) => {
          const orgData = userOrganisationsList?.find(
            (org) => org.alias === orgAlias
          );

          const organisationAllowCreditCreation = orgData?.prepaid_allow_attendance_credit_by_hhself;

          return (
            <OrgActivitiesContainer>
              <Collapse
                defaultActiveKey={[index]}
                expandIconPosition="right"
                bordered={false}
              >
                <Panel
                  key={index}
                  header={
                    <Space
                      style={{
                        justifyContent: "space-between",
                        width: "calc(100% - 60px)",
                      }}
                    >
                      <Space>
                        <Avatar src={orgData?.photo} size={50} />
                        <H2
                          style={{
                            fontSize: 20,
                            fontWeight: 400,
                          }}
                        >
                          {
                            activitiesByOrganisations?.[orgAlias]?.[0]
                              ?.organisation_name
                          }
                        </H2>
                      </Space>
                    </Space>
                  }
                >
                  <Horizontal direction="horizontal">
                    <Border />
                    <DayContainer>
                      {currentMonthDates.map((date) => {
                        if (
                          dayjs(dayjs().format("YYYY-MM-DD")).isSame(
                            dayjs(date).format("YYYY-MM-DD"),
                            "day"
                          )
                        ) {
                          return (
                            <Day color="#1890FF" textColor="white">
                              <DayLetter textColor="white">
                                {dayjs(date).format("dd")[0]}
                              </DayLetter>
                              <DayNumber textColor="white">
                                {dayjs(date).format("DD")}
                              </DayNumber>
                            </Day>
                          );
                        }
                        return (
                          <Day>
                            <DayLetter>
                              {dayjs(date).format("dd")[0]}
                            </DayLetter>
                            <DayNumber>{dayjs(date).format("DD")}</DayNumber>
                          </Day>
                        );
                      })}
                    </DayContainer>
                  </Horizontal>
                  <OrgContainer>
                    {(isFetching ||
                      isCreating ||
                      isPatching ||
                      isRemovingAttendance ||
                      isLoadingOrganisationsActivitiesData ||
                      allfetchingOpeningDays
                    ) && (
                      <LoadingWrapper>
                        <Loading />
                      </LoadingWrapper>
                    )}
                    {activitiesByOrganisations?.[orgAlias]
                      ?.filter((sub) => {
                        const orgActivitiesAliases =
                          organisationsActivitiesData?.[orgAlias] &&
                          organisationsActivitiesData?.[orgAlias]?.map(
                            (act) => act?.actAlias
                          );

                        return orgActivitiesAliases?.includes(
                          sub?.parent_activity_alias
                        );
                      })
                      ?.sort((a, b) => {
                        const activityDetailsA = organisationsActivitiesData?.[
                          orgAlias
                        ]?.find(
                          (act) => act?.actAlias === a.parent_activity_alias
                        );
                        const activityDetailsB = organisationsActivitiesData?.[
                          orgAlias
                        ]?.find(
                          (act) => act?.actAlias === b.parent_activity_alias
                        );

                        return activityDetailsA?.start_time?.localeCompare(
                          activityDetailsB?.start_time
                        );
                      })
                      ?.map((sub) => {
                        const activityDetails = organisationsActivitiesData?.[
                          orgAlias
                        ]?.find(
                          (act) => act?.actAlias === sub.parent_activity_alias
                        );

                        const activityLocations = subscriptions
                          ?.filter(
                            (subscription) =>
                              subscription?.parent_activity_alias ===
                              activityDetails?.actAlias
                          )
                          ?.map((subscription) => subscription?.activity_name);

                        const activityBillingModel = activitiesBillingModel?.[
                          orgAlias
                        ]?.[sub?.parent_activity_alias];
                        const isPrepaid = activityBillingModel === 2;
                        
                        const activityAllowCreditCreation = activityDetails?.prepaid_allow_attendance_credit_by_hhself;

                        console.log({activityAllowCreditCreation, organisationAllowCreditCreation})

                        return (
                          <ActivityContainer
                            isLoading={
                              isFetching ||
                              isCreating ||
                              isPatching ||
                              isRemovingAttendance ||
                              isLoadingOrganisationsActivitiesData ||
                              allfetchingOpeningDays
                            }
                          >
                            <ActivityName
                              onClick={() => {
                                const organisationName =
                                  userOrganisationsList?.find(
                                    (org) => org.alias === orgAlias
                                  )?.name;

                                setShowActivityDetails(true);
                                setActivityDetails({
                                  ...activityDetails,
                                  organisationName,
                                });
                              }}
                              >
                                {sub.parent_activity_name}
                              </ActivityName>
                              <DynamicPadding
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip
                                  title={
                                    <>
                                      {(activityDetails?.period_selection>=1) ?(
                                          (dayjs(activityDetails?.prevenance_date,"YYYY-MM-DDTHH:mm:SS").isBefore(dayjs("2050-01-01")))?(
                                            <>
                                            {
                                              (activityDetails?.period_selection_active == 1 && activityDetails?.notice?.all_open=='0')?(
                                                <>
                                                  <p>
                                                     Activité réservable <span style={{fontWeight: 'bold'}}>{getActivitiesPeriodSelection(activityDetails?.period_selection)}</span>
                                                  </p> 
                                                  <p>{`Réservation avant le ${dayjs(
                                                    activityDetails?.prevenance_date
                                                  ).format(
                                                    "ddd DD MMM [à] HH[h]mm"
                                                  )} pour la prochaine activité du ${dayjs(
                                                    activityDetails?.update_after
                                                  ).format("ddd DD MMM")} à ${dayjs(
                                                    activityDetails?.start_time,
                                                    "HH:mm"
                                                  ).format("HH[h]mm")}`}</p>
                                                </>
                                                ):(activityDetails?.period_selection_active == 0 && activityDetails?.notice?.all_open =='1')?(
                                                  <>
                                                    <p>
                                                      Activité gérée <span style={{fontWeight: 'bold'}}>{getActivitiesPeriodSelection(activityDetails?.period_selection)}</span> et
                                                      réservable <span style={{fontWeight: 'bold'}}>{getOpeningPeriodSelectionLabel(activityDetails?.period_selection)}</span> 
                                                    </p>
                                                    <p>{`Réservation avant le ${dayjs(
                                                      activityDetails?.prevenance_date
                                                      ).format(
                                                        "ddd DD MMM [à] HH[h]mm"
                                                      )} pour ${(getConfirmPeriodReservationLabel(activityDetails?.period_selection)).split(/\s(.+)/)[1]} incluant la prochaine activité du ${dayjs(
                                                        activityDetails?.update_after
                                                      ).format("ddd DD MMM")} à ${dayjs(
                                                        activityDetails?.start_time,
                                                        "HH:mm"
                                                      ).format("HH[h]mm")}`}</p>
                                                  </>
                                                ):(activityDetails?.period_selection_active == 1 && activityDetails?.notice?.all_open =='1')?(
                                                  <>
                                                    <p>Activité réservable <span style={{fontWeight: 'bold'}}>{getActivitiesPeriodSelection(activityDetails?.period_selection)}</span> et&nbsp;
                                                    <span style={{fontWeight: 'bold'}}>{getOpeningPeriodSelectionLabel(activityDetails?.period_selection)}</span> </p>
                                                    <p>{`Réservation avant le ${dayjs(
                                                        activityDetails?.prevenance_date
                                                      ).format(
                                                        "ddd DD MMM [à] HH[h]mm"
                                                      )} pour ${(getConfirmPeriodReservationLabel(activityDetails?.period_selection)).split(/\s(.+)/)[1]} incluant la prochaine activité du ${dayjs(
                                                        activityDetails?.update_after
                                                      ).format("ddd DD MMM")} à ${dayjs(
                                                        activityDetails?.start_time,
                                                        "HH:mm"
                                                      ).format("HH[h]mm")}`}</p>
                                                  </>
                                                ):(activityDetails?.period_selection_active == 0 && activityDetails?.notice?.all_open =='0')?(
                                                  <p>{`Réservation avant le ${dayjs(
                                                    activityDetails?.prevenance_date
                                                  ).format(
                                                    "ddd DD MMM [à] HH[h]mm"
                                                  )} pour la prochaine activité du ${dayjs(
                                                    activityDetails?.update_after
                                                  ).format("ddd DD MMM")} à ${dayjs(
                                                    activityDetails?.start_time,
                                                    "HH:mm"
                                                  ).format("HH[h]mm")}`}</p>
                                                ):<></> 
                                            }

                                            {isPrepaid && (
                                              <>
                                                {isBoolean(activityAllowCreditCreation) && (
                                                  <p>
                                                    votre organisation <strong>{orgData?.name}</strong> {activityAllowCreditCreation ? "autorise" : "n'autorise pas"} l'annulation en ligne de réservations déjà payées pour cette activité.
                                                  </p>
                                                )}

                                                {!isBoolean(activityAllowCreditCreation) && (
                                                  <p>
                                                    Votre organisation <strong>{orgData?.name}</strong> {organisationAllowCreditCreation ? "autorise" : "n'autorise pas"} l'annulation en ligne de réservations déjà payées.
                                                  </p>
                                                )}
                                              </>
                                            )}

                                            <p style={{ marginBottom: 0 }}>
                                              Lieu
                                              {activityLocations?.length > 1
                                                  ? "x"
                                                  : ""}{" "}
                                                d'inscription:
                                            </p>
                                            <ul>
                                              {activityLocations?.map((location) => (
                                                <li key={Math.random()}>
                                                  {location?.replace(
                                                    `${sub?.parent_activity_name} - `,
                                                    ""
                                                  )}
                                                </li>
                                              ))}
                                            </ul>
                                          </>
                                            ):(
                                              <p style={{ marginBottom: 0 }}>
                                                Aucune date à venir n'est ouverte à la réservation
                                              </p>
                                            )
                                         ):(
                                          dayjs(activityDetails?.prevenance_date,"YYYY-MM-DDTHH:mm:SS").isBefore(dayjs("2050-01-01")
                                          ) ? (
                                          <>
                                            <p>{`Réservation avant le ${dayjs(
                                              activityDetails?.prevenance_date
                                            ).format(
                                              "ddd DD MMM [à] HH[h]mm"
                                            )} pour la prochaine activité du ${dayjs(
                                              activityDetails?.update_after
                                            ).format("ddd DD MMM")} à ${dayjs(
                                              activityDetails?.start_time,
                                              "HH:mm"
                                            ).format("HH[h]mm")}`}</p>
                                            
                                            {isPrepaid && (
                                              <>
                                                {isBoolean(activityAllowCreditCreation) && (
                                                  <p>
                                                    votre organisation <strong>{orgData?.name}</strong> {activityAllowCreditCreation ? "autorise" : "n'autorise pas"} l'annulation en ligne de réservations déjà payées pour cette activité.
                                                  </p>
                                                )}

                                                {!isBoolean(activityAllowCreditCreation) && (
                                                  <p>
                                                    Votre organisation <strong>{orgData?.name}</strong> {organisationAllowCreditCreation ? "autorise" : "n'autorise pas"} l'annulation en ligne de réservations déjà payées.
                                                  </p>
                                                )}
                                              </>
                                            )}

                                            <p style={{ marginBottom: 0 }}>
                                              Lieu
                                              {activityLocations?.length > 1
                                                ? "x"
                                                : ""}{" "}
                                              d'inscription:
                                            </p>
                                            <ul>
                                              {activityLocations?.map((location) => (
                                                <li key={Math.random()}>
                                                  {location?.replace(
                                                    `${sub?.parent_activity_name} - `,
                                                    ""
                                                  )}
                                                </li>
                                              ))}
                                            </ul>
                                        </>
                                        ) : (
                                            <p style={{ marginBottom: 0 }}>
                                              Aucune date à venir n'est ouverte à la réservation
                                            </p>
                                        )
                                        )}                                    
                                    </>
                                  }
                                  color="orange"
                                >
                                  <InfoCircleOutlined style={{ fontSize: 20 }} />
                                </Tooltip>
                              </DynamicPadding>
                              <DynamicPadding>
                                <Recurrent isDisabled={activityDetails?.period_selection > 1 && activityDetails?.period_selection_active === 1}
                                  onClick={() => reccurent(sub)}>
                                  <ReloadOutlined
                                    style={{
                                      fontSize: "20px",
                                      // paddingTop: "5px",
                                    }}
                                  ></ReloadOutlined>
                                </Recurrent>
                              </DynamicPadding>
                              {currentMonthDates.map((a) =>
                                cellWrapper(
                                  a,
                                  sub,
                                  setSelectedCell,
                                  setIsHover,
                                  isHovered,
                                  hoveredCellContainerLabel,
                                  orders,
                                  child,
                                  activityBillingModel,
                                  activityDetails,
                                  gardeplaningDays,
                                  currentMonthDates,
                                  organisationAllowCreditCreation,
                                  activityAllowCreditCreation,
                                )
                              )}
                            </ActivityContainer>
                        );
                      })}
                  </OrgContainer>
                  <DescriptionContainer>
                    <DescWrapper>
                      <Dot color="#FFCDD6"></Dot>
                      <Description>Récurrence</Description>
                    </DescWrapper>
                    <DescWrapper>
                      <Dot color="#E2E4E6"></Dot>
                      <Description>Réservable</Description>
                    </DescWrapper>
                    <DescWrapper>
                      <Dot color="#20CD8D"></Dot>
                      <Description>Présent</Description>
                    </DescWrapper>
                    <DescWrapper>
                      <Dot color="#F4C26D"></Dot>
                      <Description>Absent</Description>
                    </DescWrapper>
                    <DescWrapper>
                      <Dot color="#1890ff"></Dot>
                      <Description>Annulable</Description>
                    </DescWrapper>
                    <DescWrapper>
                      <Dot color="#6D7F9D"></Dot>
                      <Description>Non annulable</Description>
                    </DescWrapper>
                    <DescWrapper>
                      <Dot color="#FF5876"></Dot>
                      <Description>Dans le panier</Description>
                    </DescWrapper>
                    <DescWrapper>
                      <Dot color="#D3ADF7"></Dot>
                      <Description>Attente retour paiement</Description>
                    </DescWrapper>
                    <DescWrapper>
                      <Dot color="#722ED1"></Dot>
                      <Description>Présence due</Description>
                    </DescWrapper>
                    <DescWrapper>
                      <Dot color="#B7EB8F"></Dot>
                      <Description>Remboursé</Description>
                    </DescWrapper>
                  </DescriptionContainer>
                  <>{pgd_exist &&
                    <Row justify="end">
                      <Col span={0.5}>
                        <Box><Draw color = "#6D7F9D"></Draw></Box>
                      </Col>
                      <Col span={4.5}> Je n'ai pas la garde de l'enfant</Col>
                    </Row>}
                  </>
                </Panel>
              </Collapse>
            </OrgActivitiesContainer>
          );
        })}
      <EditActivity
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        data={recu}
        organisationsActivitiesData={organisationsActivitiesData}
        children={selectedChildrenDetails}
        currentChild={child}
        activitiesBillingModel={activitiesBillingModel}
        addPaidAttendance={addPaidAttendance}
        selectedMonth={selectedMonth}
        removePaidAttendance={removePaidAttendance}
        orders={orders}
        allActivityAttendances={allAttendances}
      />
      <SubActivity
        showDrawer={showSubDrawer}
        setShowDrawer={setShowSubDrawer}
        data={recu}
      />
      {showActivityDetails && (
        <ActivityDetail
          setShowActivityDetails={setShowActivityDetails}
          activity={activityDetails}
          isLoadingOrganisationsActivitiesData={
            isLoadingOrganisationsActivitiesData
          }
        />
      )} 
    </Calendar>
  );
};

export default ActivityCalendar;
