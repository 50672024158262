import { createActions } from 'redux-actions'

export const {
  fetchContacts,
  fetchContactsProcess,
  fetchContactsError,
  fetchContactsSuccess,

  createContact,
  createContactProcess,
  createContactError,
  createContactSuccess,

  updateContact,
  updateContactProcess,
  updateContactError,
  updateContactSuccess,

  deleteContact,
  deleteContactProcess,
  deleteContactError,
  deleteContactSuccess,
} = createActions({
  FETCH_CONTACTS: () => {},
  FETCH_CONTACTS_PROCESS: () => {},
  FETCH_CONTACTS_SUCCESS: contacts => contacts,
  FETCH_CONTACTS_ERROR: () => {},

  CREATE_CONTACT: (contact, callback) => ({
    contact,
    callback,
  }),
  CREATE_CONTACT_PROCESS: (contact, callback) => ({
    contact,
    callback,
  }),
  CREATE_CONTACT_ERROR: () => {},
  CREATE_CONTACT_SUCCESS: contacts => contacts,

  UPDATE_CONTACT: (alias, contact, callback) => ({
    alias,
    contact,
    callback,
  }),
  UPDATE_CONTACT_PROCESS: (contact, callback) => ({
    contact,
    callback,
  }),
  UPDATE_CONTACT_ERROR: () => {},
  UPDATE_CONTACT_SUCCESS: contacts => contacts,

  //~ DELETE_CONTACTS: () => {},
  DELETE_CONTACT: (alias, callback) => ({alias, callback}),
  DELETE_CONTACT_PROCESS: () => {},
  DELETE_CONTACT_SUCCESS: contacts => contacts,
  DELETE_CONTACT_ERROR: () => {},
})
