import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Space from '~/components/common/Space'
import MainContainer from '~/components/common/Container'
import FilterContainer from '~/components/common/FilterContainer'
import Table from '~/components/common/Table'
import { useColumns } from './columns'

import { useQuery } from "@apollo/client";
import { ORDERS } from "../../graphql/queries";

const sortFields = {
  dest: ['dest'],
  organisation: 'household__organisation__name',
  date: '-created_at',
  status: 'status',
  payment: 'payment',
  montant: 'montant',
  participants: 'participants',
  totalAmount: 'total_amount',
  totalAmountPlusCreds: 'total_amount_plus_creds',
  paymentMode: 'related_payments'
}

const OrdersTable = () => {
  const dispatch = useDispatch()
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [setRowSelection] = useState([])
  const [sort, setSort] = useState(sortFields.date)

  const [setShowDrawer] = useState(false)

  const onStatusChange = () => {}

  const columns = useColumns(onStatusChange, setShowDrawer, sort, setSort)

  const onTableChange = useCallback((pagination, _, sorter, { action }) => {
    console.log({ pagination, sorter, action })
    if (action === 'paginate') {
      setPage(pagination.current)
    }

    if (action === 'sort') {
      const field = sortFields[sorter.columnKey]?.replace('-', '')

      setSort(sorter.order === 'descend' ? `-${field}` : field)
    }
  }, [setSort])

  /*
  useEffect(() => {
    dispatch(fetchAllOrders({
      search,
      page,
      pageSize,
    }))
  }, [search, page, pageSize])

  */
  useEffect(() => {
    setPage(1);
  }, [search, pageSize]);

  const {
    loading: loadingOrders,
    errorOrders,
    data: ordersData,
    // refetch,
  } = useQuery(ORDERS, {
    fetchPolicy: "no-cache",
    variables: {
      search,
      first: pageSize,
      skip: (page - 1) * pageSize,
      sort,
    },
  });

  useEffect(()=> {
    if(sort){console.log("SORT", sort)}
  },[sort])

  return (
    <MainContainer>
      <Space direction="vertical" size="large">
        <FilterContainer
          defaultPageSize={pageSize}
          onPageSizeChange={setPageSize}
          onSearch={(value) => setSearch(value)}
        />
        <Table
          columns={columns}
          dataSource={ordersData?.orders?.data ?? []}
          isLoading={loadingOrders}
          onChange={onTableChange}
          total={ordersData?.orders?.count ?? 0} // length
          currentPage={page}
          pageSize={pageSize}
        />
      </Space>
    </MainContainer>
  )
}

export default OrdersTable
