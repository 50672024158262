
export default [
  {
    code: 0,
    label: 'Masculin',
  },
  {
    code: 1,
    label: 'Féminin',
  },
]
