import React from 'react'
import styled from 'styled-components'
import {
  Menu,
} from 'antd'

const StyledMenuItem = styled(Menu.Item)`
  .ant-menu-horizontal &.ant-menu-item {
    margin: 0;
  }

  &.ant-menu-item ~ &.ant-menu-item {
    margin-left: @margin-lg;
  }
`

export const MenuItem = ({ children, ...props }) => (
  <StyledMenuItem {...props}>
    {children}
  </StyledMenuItem>
)

const StyledMenu = styled(Menu)`
`

const MenuComponent = ({ children, ...props }) => (
  <StyledMenu
    mode="horizontal"
    {...props}
  >
    {children}
  </StyledMenu>
)

export default MenuComponent
