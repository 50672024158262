import React from 'react'
import PropTypes from 'prop-types'

import Space from './components'

const SpaceComponent = ({
  style,
  children,
  width,
  ...props
}) => (
  <Space
    style={{
      display: 'flex',
      ...(width ? { width } : {}),
      ...style,
    }}
    {...props}
  >
    {children}
  </Space>
)

SpaceComponent.propTypes = {
  children: PropTypes.element,
}

SpaceComponent.defaultProps = {
  children: PropTypes.element,
}

export default SpaceComponent
