import React from 'react'
import styled from 'styled-components'

import {
  WomanOutlined,
  ManOutlined,
} from '@ant-design/icons'

const Woman = styled(WomanOutlined)`
  vertical-align: ${props => props.$verticalAlign};
  svg {
    fill: #eb2f96;
    height: ${props => props.$size ? `${props.$size}px` : 'auto'};
    width: ${props => props.$size ? `${props.$size}px` : 'auto'};
  }
`

const Man = styled(ManOutlined)`
  vertical-align: ${props => props.$verticalAlign};

  svg {
    fill: #188fff;
    height: ${props => props.$size ? `${props.$size}px` : 'auto'};
    width: ${props => props.$size ? `${props.$size}px` : 'auto'};
  }
`

const Gender = ({
  gender,
  size = null,
  verticalAlign = "-4px",
}) => {
  if (Number.isInteger(gender)) {
    if (gender === 0) {
      return (
        <Man
          $size={size}
          $verticalAlign={verticalAlign}
        />
      )
    }

    if (gender === 1) {
      return (
        <Woman
          $size={size}
          $verticalAlign={verticalAlign}
        />
      )
    }
  }

  return null
}

export default Gender
