const config = {
  app: {
    google: {
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    },
    env: process.env.REACT_APP_ENV,
    disableUX: process.env.REACT_APP_DISABLE_UX === 'true',
    recaptchaSiteKeyResetPassword: process.env.REACT_APP_RECAPTCHA_SITE_KEY_RESET_PASSWORD,
  },
  api: {
    url: process.env.REACT_APP_API_URL,
    devUrls: JSON.parse(process.env.REACT_APP_API_DEV_URLS),
    backoffice_url: process.env.REACT_APP_BACKOFFICE_API_URL,
  },
  graphql: {
    entry: process.env.REACT_APP_GRAPHQL_ENTRY,
  },
};

export default config;
