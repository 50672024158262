import styled from 'styled-components'

const StyledBullet = styled.div`
  display: inline-block;
  margin: 0px 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${props => props.color};
`

export default StyledBullet
