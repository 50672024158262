import { WarningFilled } from "@ant-design/icons"
import React from "react"
import styled from "styled-components"
import DefaultButton from "~/components/common/DefaultButton"
import PrimaryButton from "~/components/common/PrimaryButton"

const EmptyCartPopUp = ({
  visible,
  setVisible,
  emptyCart,
}) => {
  if (!visible) return null
  
  return (
    <Overlay>
      <PopUp>
        <WarningFilled
          style={{
            fontSize: "70px",
            color: "#FFC53D",
            margin: "22px auto",
            width: "100%",
            justifyContent: "center",
          }}
        />
        <Text style={{ justifyContent: "center", width: "100%" }}>
          Vous allez vider le panier et annuler toutes les réservations !
        </Text>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DefaultButton
            style={{
              width: "48%",
              margin: 0,
            }}
            size="large"
            onClick={() => setVisible(false)}
          >
            Annuler
          </DefaultButton>
          <PrimaryButton
            style={{
              width: "48%",
              margin: 0,
            }}
            size="large"
            onClick={emptyCart}
          >
            Vider le panier
          </PrimaryButton>
        </div>
      </PopUp>
    </Overlay>
  )
}

const Text = styled.div`
  font-size: 20px;
  line-height: 28px;

  display: flex;
  align-items: center;
  text-align: center;
  padding-bottom: 22px;
`

const Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  // visibility: hidden;
  opacity: 1;
  z-index: 10;
`

const PopUp = styled.div`
  margin: 100px auto;
  padding: 2%;
  background: #fff;
  border-radius: 5px;
  width: 50%;
  position: relative;
  transition: all 5s ease-in-out;
`

export default EmptyCartPopUp
