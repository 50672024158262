import styled, { css } from "styled-components";
import { MainContent } from "~/components/common/Container";

export const Border = styled.div`
  // border: 1px solid red;
  width: 160px;
  min-width: 160px;
  text-align: center;
  vertical-align: text-bottom;
  line-height: 20px;
  padding-bottom: 5px;
  color: @neutral-4;
  font-size: 12px;
  margin-top: 5px;
`;

export const OrgContainer = styled.div`
  border: 0.5px solid #969696;
  border-radius: 8px;
  min-width: fit-content;
  overflow: hidden;
`;

export const ActivityContainer = styled.div`
  display: flex;
  flex-direction: row;
  // width: fit-content;
  max-width: 100%;
  &:not(:first-child) {
    border-top: 1px solid #969696;
  }
  overflow: hidden;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.2;
    `}
`;

export const ActivityWrapper = styled.div`
  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.2;
    `}
`;

export const ActivityName = styled.div`
  text-align: center;
  width: 160px;
  font-size: 18px;
  min-width: 160px;
  height: 30px;
  line-height: 30px;
  vertical-align: center;
  ${({ isAll }) =>
    !isAll &&
    css`
      color: #1890ff;
      cursor: pointer;
    `}
  border-right: 1px solid #969696;
`;

export const ICase = styled.div`
  border-right: 1px solid #969696;
  background: ${(props) => props.color};
  height: 30px;
  min-width: 30px;
  max-width: 100%;
  width: 100%;
  width: 100%;
  :last-child {
    border: none;
  }
`;

export const Case = styled.div`
  border-right: 1px solid #969696;
  background: ${(props) => props.color};
  min-height: 30px;
  min-width: 30px;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  :last-child {
    border: none;
  }
`;

export const Box = styled.div`
  border: 1px solid #969696;;
  min-height : 30px;
  min-width : 30px;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Draw = styled.hr`
  background: ${(props) => props.color};
  height: 3px;
  min-width: 30px;
  width:45%;
  margin: 0;
  padding: 0;
  border: none;
`;

const Line = styled.p`
  color: #FF5876,
  height: 5px,
  border: none,
`;

export const Recurrent = styled.div`
  text-align: center;
  background-color: #ffcdd6;
  height: 30px;
  ${({ isDisabled }) =>
  isDisabled &&
  css`
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  `}
`;

export const BadgePill = styled.div`
  font-size: 12px;
  //color: #ffff;
  fontWeight: 'bold';
  text-align: center;
  background-color:  ${(props) => props.color};
  border-radius: 5em;
  width: 100%;
  height: auto;
  margin: 1em;
`;

export const DynamicPadding = styled.div`
  // text-align: center;
  // background-color: #ffcdd6;
  height: 30px;
  min-width: 30px;
`;

export const Day = styled.div`
  background: ${(props) => props.color};
  color: ${(props) => props.textColor};
  border-radius: 8px;
  display: flex;
  min-width: 20px;
  // max-width: 50px;
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  flex-direction: column;
  justify-content: space-around;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // width: 100%;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const DescriptionContainerBottom = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: ${(props) => props.color};
  margin-top: 10px;
  margin-right: 10px;
`;

export const Description = styled.div`
  font-size: 12px;
  margin-top: 5px;
  margin-right: 3px;
`;

export const DayLetter = styled.div`
  text-align: center;
  min-width: 20px;
  max-width: 50px;
  font-size: 12px;
  width: 100%;
  color: @neutral-4;
  color: ${(props) => props.textColor};
  text-transform: capitalize;
`;

export const DayNumber = styled.div`
  text-align: center;
  min-width: 20px;
  max-width: 50px;
  font-size: 9px;
  width: 100%;
  color: @neutral-4;
  color: ${(props) => props.textColor};
`;

export const DayContainer = styled.div`
  // border: 1px solid red;
  height: 40px;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 60px;
`;

export const Horizontal = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
`;

export const Calendar = styled.div`
  overflow: auto;
  max-width: 100%;
  width: 100%;
`;

export const DescWrapper = styled.div`
  display:flex;
  flex-direction-row;
`;

export const OrgActivitiesContainer = styled(MainContent)`
  margin-bottom: 24px;
  min-width: fit-content;

  .ant-collapse-header {
    display: flex;
    align-items: center;
    padding: 0 !important;
  }

  .ant-collapse-content {
    border-top: none;
  }

  .anticon-right {
    font-size: 32px !important;
    top: 50% !important;
    transform: translateY(-50%);
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 0;
  }

  .ant-collapse-item {
    background-color: white;
    border-bottom: 0;
  }
`;
