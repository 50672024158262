import React, { useState, useCallback } from "react";

import { Button } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";

import Container, { MainContainer } from "~/components/common/Container";
// import TransmitDoc from "./TramsmitDoc";
import { Divider, Switch } from "antd";
import ActivityCalendar from "./ActivityCalendar";

import dayjs from "dayjs";
import "dayjs/locale/fr";

dayjs.locale("fr");

const Activity = ({
  selectedMonth,
  setSelectedMonth,
  hideFinishedActivities,
  setHideFinishedActivities,  
}) => {
  const [showDrawer, setShowDrawer] = useState(false);

  // 
  // const { registrations } = child;

  dayjs.locale("fr");
  // 

  return (
    <>
      <MainContainer>
        <ActivityCalendar
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          hideFinishedActivities={hideFinishedActivities}
          setHideFinishedActivities={setHideFinishedActivities}
        />
      </MainContainer>
    </>
  );
};

export default Activity;
