import React from 'react'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'

const MainLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
`

const LoaderIcon = styled(LoadingOutlined)`
  font-size: 70px;
  color: white
`

const Component = () => (
  <MainLoader>
    <LoaderIcon />
  </MainLoader>
)

export default Component
