import moment from "moment"

export const getAllMondaysInDateRange = (startDate, endDate) => {
  const start = moment(startDate)
  const end = moment(endDate)

  const arr = []
  // Get "next" monday
  let tmp = start.clone().day(1)
  if(tmp.isSame(start, 'd')){
    arr.push(tmp.format('YYYY-MM-DD'))
  }
  while(tmp.isBefore(end)){
    tmp.add(7, 'days')
    
    if (tmp.isBefore(end)) {
    	arr.push(tmp.format('YYYY-MM-DD'))
    }
  }

  return arr
}
