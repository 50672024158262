import { createActions } from "redux-actions"

export const {
  fetchCartPrices,
  fetchCartPricesProcess,
  fetchCartPricesSuccess,
  fetchCartPricesError,

  fetchPayments,
  fetchPaymentsProcess,
  fetchPaymentsSuccess,
  fetchPaymentsError,

  fetchAllOrders,
  fetchAllOrdersProcess,
  fetchAllOrdersSuccess,
  fetchAllOrdersError,

  fetchOrders,
  fetchOrdersProcess,
  fetchOrdersSuccess,
  fetchOrdersError,

  fetchOrderDetails,
  fetchOrderDetailsProcess,
  fetchOrderDetailsSuccess,
  fetchOrderDetailsError,

  validateOrder,
  validateOrderProcess,
  validateOrderSuccess,
  validateOrderError,

  deleteOrder,
  deleteOrderProcess,
  deleteOrderSuccess,
  deleteOrderError,

  payOrder,
  payOrderProcess,
  payOrderSuccess,
  payOrderError,

  payOrderOnTheSpotSuccess,

  addPaidAttendance,
  addPaidAttendanceProcess,
  addPaidAttendanceSuccess,
  addPaidAttendanceError,

  removePaidAttendance,
  removePaidAttendanceProcess,
  removePaidAttendanceSuccess,
  removePaidAttendanceError,

  massDeleteAttendances,
  massDeleteAttendancesProcess,
  massDeleteAttendancesSuccess,
  massDeleteAttendancesError,
} = createActions({
  FETCH_CART_PRICES: (items) => items,
  FETCH_CART_PRICES_PROCESS: () => {},
  FETCH_CART_PRICES_SUCCESS: (children) => children,
  FETCH_CART_PRICES_ERROR: () => {},

  FETCH_PAYMENTS: () => {},
  FETCH_PAYMENTS_PROCESS: () => {},
  FETCH_PAYMENTS_SUCCESS: (payments) => payments,
  FETCH_PAYMENTS_ERROR: () => {},

  FETCH_ALL_ORDERS: (data) => data,
  FETCH_ALL_ORDERS_PROCESS: () => {},
  FETCH_ALL_ORDERS_SUCCESS: (orders) => orders,
  FETCH_ALL_ORDERS_ERROR: () => {},

  FETCH_ORDERS: () => {},
  FETCH_ORDERS_PROCESS: () => {},
  FETCH_ORDERS_SUCCESS: (orders) => orders,
  FETCH_ORDERS_ERROR: () => {},

  FETCH_ORDER_DETAILS: (data) => data,
  FETCH_ORDER_DETAILS_PROCESS: () => {},
  FETCH_ORDER_DETAILS_SUCCESS: (orders) => orders,
  FETCH_ORDER_DETAILS_ERROR: () => {},

  VALIDATE_ORDER: (data) => data,
  VALIDATE_ORDER_PROCESS: () => {},
  VALIDATE_ORDER_SUCCESS: (orders) => orders,
  VALIDATE_ORDER_ERROR: () => {},

  DELETE_ORDER: (data) => data,
  DELETE_ORDER_PROCESS: () => {},
  DELETE_ORDER_SUCCESS: (orders) => orders,
  DELETE_ORDER_ERROR: () => {},

  PAY_ORDER: (data) => data,
  PAY_ORDER_PROCESS: () => {},
  PAY_ORDER_SUCCESS: (orders) => orders,
  PAY_ORDER_ERROR: () => {},

  PAY_ORDER_ON_THE_SPOT_SUCCESS: (orderAlias) => orderAlias,

  ADD_PAID_ATTENDANCE: (data) => data,
  ADD_PAID_ATTENDANCE_PROCESS: () => {},
  ADD_PAID_ATTENDANCE_SUCCESS: (orders) => orders,
  ADD_PAID_ATTENDANCE_ERROR: () => {},

  REMOVE_PAID_ATTENDANCE: (data) => data,
  REMOVE_PAID_ATTENDANCE_PROCESS: () => {},
  REMOVE_PAID_ATTENDANCE_SUCCESS: (orders) => orders,
  REMOVE_PAID_ATTENDANCE_ERROR: () => {},

  MASS_DELETE_ATTENDANCES: (data) => data,
  MASS_DELETE_ATTENDANCES_PROCESS: () => {},
  MASS_DELETE_ATTENDANCES_SUCCESS: (orders) => orders,
  MASS_DELETE_ATTENDANCES_ERROR: () => {},
})
