import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CloseCircleOutlined } from '@ant-design/icons'

const Alert = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 20px;
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid red;
  border-radius: 17px;
  .anticon{
      display: inline-block;
      color: red;
  }
`

const Error = styled.p`
  margin: 0 2px 0 11px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.35;
  color:red !important;
`

const Component = ({ error, addon, ...props }) => (
  <Alert {...props}>
    <CloseCircleOutlined />
    <Error>{error}</Error>
    {addon && addon}
  </Alert>
)

Component.propTypes = {
  error: PropTypes.string.isRequired,
  addon: PropTypes.element,
}
Component.defaultProps = {
  addon: null,
}

export default Component
