import styled from 'styled-components'
import {
  Skeleton,
} from 'antd'

export const Img = styled.img`
display: inline-block;
width: ${props => props?.width ?? '120px'};
height: ${props => props?.height ?? '120px'};
border-radius: 50%;
background-color: #e2e4e6;
`

export const CustomSkeleton = styled(Skeleton)`
  .ant-skeleton-header {
    padding: 0; 
    vertical-align: middle;
    display: flex;
    justify-content: center;
  }
  .ant-skeleton-content {
    display: none;
  }
`

