

import { handleActions } from '~/utils/redux-actions'

import { logout } from '~/containers/User/actions'
import {
  fetchCartPricesProcess,
  fetchCartPricesSuccess,
  fetchCartPricesError,

  fetchPaymentsProcess,
  fetchPaymentsSuccess,
  fetchPaymentsError,

  fetchAllOrdersProcess,
  fetchAllOrdersSuccess,
  fetchAllOrdersError,

  fetchOrdersProcess,
  fetchOrdersSuccess,
  fetchOrdersError,

  fetchOrderDetailsProcess,
  fetchOrderDetailsSuccess,
  fetchOrderDetailsError,

  validateOrderProcess,
  validateOrderSuccess,
  validateOrderError,

  deleteOrderProcess,
  deleteOrderSuccess,
  deleteOrderError,

  payOrderProcess,
  payOrderSuccess,
  payOrderError,

  payOrderOnTheSpotSuccess,

  addPaidAttendanceProcess,
  addPaidAttendanceSuccess,
  addPaidAttendanceError,

  removePaidAttendanceProcess,
  removePaidAttendanceSuccess,
  removePaidAttendanceError,

  massDeleteAttendancesProcess,
  massDeleteAttendancesSuccess,
  massDeleteAttendancesError,
} from './actions'

const defaultState = {
  prices: {
    isFetching: false,
    data: null,
    error: false,
  },
  payments: {
    isFetching: false,
    data: null,
    error: false,
  },
  orders: {
    isFetching: false,
    data: null,
    validatedOrder: null,
    error: false,
    isValidating: false,
    isPaying: false,
    paymentData: null,
    paymentOnTheSpotSuccessful: null,
  },
  currentOrder: {
    isFetching: false,
    data: null,
    error: false,
  },
  allOrders: {
    isFetching: false,
    error: false,
    data: null,
    count: null,
  },
  isAddingAttendance: false,
  isRemovingAttendance: false,
}

export default handleActions(
  {
    // Logout
    [logout]: () => defaultState,

    [fetchCartPricesProcess]: (state) => {
      state.prices.isFetching = true
      state.prices.error = false
    },
    [fetchCartPricesSuccess]: (state, { payload }) => {
      state.prices.isFetching = false
      state.prices.error = false
      state.prices.data = [...payload]
    },
    [fetchCartPricesError]: (state) => {
      state.prices.isFetching = false
      state.prices.error = true
    },

    [fetchPaymentsProcess]: (state) => {
      state.payments.isFetching = true
      state.payments.error = false
    },
    [fetchPaymentsSuccess]: (state, { payload }) => {
      state.payments.isFetching = false
      state.payments.error = false
      state.payments.data = [...payload]
    },
    [fetchPaymentsError]: (state) => {
      state.payments.isFetching = false
      state.payments.error = true
    },

    [fetchAllOrdersProcess]: (state) => {
      state.allOrders.isFetching = true
      state.allOrders.error = false
      state.allOrders.data = null
      state.allOrders.count = null
    },
    [fetchAllOrdersSuccess]: (state, { payload }) => {
      console.log({ payload, ddd: state.allOrders?.data})
      state.allOrders.isFetching = false
      state.allOrders.error = false
      state.allOrders.data = payload.data
      state.allOrders.count = payload.count
    },
    [fetchAllOrdersError]: (state) => {
      state.allOrders.isFetching = false
      state.allOrders.error = true
    },

    [fetchOrdersProcess]: (state) => {
      state.orders.isFetching = true
      state.orders.error = false
    },
    [fetchOrdersSuccess]: (state, { payload }) => {
      state.orders.isFetching = false
      state.orders.error = false
      state.orders.data = [...payload]
    },
    [fetchOrdersError]: (state) => {
      state.orders.isFetching = false
      state.orders.error = true
    },
    [fetchOrderDetailsProcess]: (state) => {
      state.currentOrder.isFetching = true
      state.currentOrder.error = false
    },
    [fetchOrderDetailsSuccess]: (state, { payload }) => {
      state.currentOrder.isFetching = false
      state.currentOrder.error = false
      state.currentOrder.data = payload
    },
    [fetchOrderDetailsError]: (state) => {
      state.currentOrder.isFetching = false
      state.currentOrder.error = true
    },
    [validateOrderProcess]: (state) => {
      state.orders.isValidating = true
    },
    [validateOrderSuccess]: (state, { payload }) => {
      state.orders.validatedOrder = payload
      state.orders.isValidating = false
    },
    [validateOrderError]: (state) => {
      state.orders.isValidating = false
    },

    [deleteOrderProcess]: (state) => {
      state.orders.isFetching = true
    },
    [deleteOrderSuccess]: (state) => {
      state.orders.isFetching = false
    },
    [deleteOrderError]: (state) => {
      state.orders.isFetching = false
    },

    [payOrderProcess]: (state) => {
      state.orders.isPaying = true
    },
    [payOrderSuccess]: (state, { payload }) => {
      state.orders.isPaying = false
      state.orders.paymentData = payload
    },
    [payOrderError]: (state) => {
      state.orders.isPaying = false
    },

    [payOrderOnTheSpotSuccess]: (state, { payload }) => {
      state.orders.paymentOnTheSpotSuccessful = payload
    },

    [addPaidAttendanceProcess]: (state) => {
      state.isAddingAttendance = true
    },
    [addPaidAttendanceSuccess]: (state, { payload }) => {
      state.isAddingAttendance = false
    },
    [addPaidAttendanceError]: (state) => {
      state.isAddingAttendance = false
    },

    [removePaidAttendanceProcess]: (state) => {
      state.isRemovingAttendance = true
    },
    [removePaidAttendanceSuccess]: (state, { payload }) => {
      state.isRemovingAttendance = false
    },
    [removePaidAttendanceError]: (state) => {
      state.isRemovingAttendance = false
    },

    [massDeleteAttendancesProcess]: (state) => {
      state.isRemovingAttendance = true
    },
    [massDeleteAttendancesSuccess]: (state, { payload }) => {
      state.isRemovingAttendance = false
    },
    [massDeleteAttendancesError]: (state) => {
      state.isRemovingAttendance = false
    },
  },
  defaultState,
)
