import { Col } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getFeedMessages, getFeed } from "~/containers/Home/selectors"
import FeedMessage from "./FeedMessage"
import Loading from "~/components/common/Loading"
import FeedMessageDetails from "./FeedMessageDetails"
import { colors } from "~/utils/colors"
import { useNavigate } from "react-router-dom"
import {
  Container,
  Content,
  MainContainer,
} from "~/components/common/Container"
// import "./modal.less"
import { userOrganisationsFetching } from "~/containers/Home/actions"
import {
  fetchChildrenRegistrations,
} from "~/containers/Children/actions"

const FeedMessages = ({
  feedsData,
  loading,
}) => {
  const dispatch = useDispatch()
  const [visibility, setVisibility] = useState(false)
  const [modalData, setModalData] = useState(null)
  const feedMessages = feedsData?.myFeedsPaginated?.data || []
  const children = feedsData?.myChildren || []
  const userOrganisationAlias = feedsData?.myHousehold?.organisation?.alias

  const userMail = useSelector((state) => state.user.auth.user.email)
  const navigate = useNavigate()

  const showDetails = (data) => {
    setVisibility(true)
    setModalData(data)
  }
  const hideDetails = () => {
    setVisibility(false)
    setModalData(null)
  }

  const getFeedColorCodes = (feedType) => {
    // 
    let postColors = {}
    switch (feedType) {
      case "Information":
        postColors = {
          color: colors.darkGrey,
          borderColor: colors.lightGrey,
        }
        break
      case "Actualité":
        postColors = {
          color: colors.darkBlue,
          borderColor: colors.lightBlue,
        }
        break
      case "Alerte":
        postColors = {
          color: colors.darkRed,
          borderColor: colors.lightRed,
        }
        break
      case "Evénement":
        postColors = {
          color: colors.darkGreen,
          borderColor: colors.lightGreen,
        }
        break
      case "ZeroChildren":
        postColors = {
          color: colors.cvOrange,
          borderColor: colors.cvLightOrange,
        }
        break
      default:
        postColors = {
          color: colors.darkGrey,
          borderColor: colors.lightGrey,
        }
    }
    return postColors
  }

  const action = (message, organisationName) => {
    
    let actionType = ''
    let msgUrl = ''
    const isRegistration = message.feedType === "registration"
    const isSubscription = message.feedType === "subscription"
    const isZeroChildren = message.feedType === "zerochildren"

    if (isZeroChildren) {
      navigate('/profile?openDrawer')
    }

    if (['registration', 'subscription'].includes(message.feedType)) {
      if (message.status === 0) {
        actionType = 'none'
      } else {
        actionType = 'redirect'
      }
    } else {
      actionType = 'modal'
    }

    if (actionType === 'none') {
      return
    }

    if (actionType === 'modal') {
      showDetails({
        ...message,
        postColors: getFeedColorCodes(message.feedTypeBis),
        organisationName,
        userOrganisationAlias,
      })
      return
    }

    if (actionType === 'redirect') {
      const registrationAlias = message?.child?.registrationSet?.find(
        registration => registration.organisation?.alias === message.organisation?.alias
      )?.alias

      if (message?.status === 1) {
        if (isRegistration) {
          if (Number(message?.renewalStatus) === 0) {
            msgUrl = `/registration/${message.child?.alias}/${registrationAlias}?renew_registration=1`
          } else {
            msgUrl = `/reservation/${message.child?.alias}/${message.organisation?.alias}`
          }
        }
  
        if (isSubscription) {
          msgUrl = `/children/${message?.child?.alias}/activity`
        }
      }

      if (message?.status !== 1) {
        if (isRegistration && message?.status === 4) {
          msgUrl = `/registration/${message?.child?.alias}/${registrationAlias}`
        }
      }
  
      navigate(msgUrl)
    }

    // if (actionType === 'redirect' && message?.status !== 1) {

    // }
    // if (!isSubscription && !isRegistration){
    //   showDetails({
    //     ...message,
    //     postColors: getFeedColorCodes(message.feedType),
    //     organisationName,
    //   });
    //   return;
    // }

    // 
    // if (isRegistration) {
    //   const registrationAlias = childrenRegistrations?.[message?.childAlias]?.find(
    //     registration => registration.organisationAlias === message.organisationAlias
    //   )?.alias

    //   if (message.renewal_status === 0) {
    //     msgUrl = `/registration/${message.childAlias}/${registrationAlias}?renew_registration=1`
    //   } else {
    //     if (message.status === 1) {
    //       msgUrl = `/reservation/${message.childAlias}/${message.organisationAlias}`
    //     } else {
    //       msgUrl = `/registration/${message.childAlias}/${registrationAlias}`
    //     }
    //   }
    
    //   
    //   history.push(msgUrl);
    // }
    // if (isSubscription) {
    //   const msgUrl = `/reservation/${message.childAlias}/${message.organisationAlias}`
    //   
    //   history.push(msgUrl);
    // }
  }

  // useEffect(() => {
  //   dispatch(userOrganisationsFetching())
  //   dispatch(fetchChildrenRegistrations())
  // }, [])

  console.log({ feedMessages, children })
  
  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          {children?.length === 0 && (
            <div
              onClick={() => action({
                feedType: 'zerochildren',
                feedTypeBis: 'zerochildren',
                activityType: 33,
                title: 'Vous pouvez, dès à présent, ajouter un ou plusieurs enfants',
                modified_at: new Date(),
              }, '')}
            >
              <FeedMessage
                feedData={{
                  feedType: 'zerochildren',
                  feedTypeBis: 'zerochildren',
                  activityType: 33,
                  title: 'Vous pouvez, dès à présent, ajouter un ou plusieurs enfants',
                  modified_at: new Date(),
                  postColors: getFeedColorCodes('ZeroChildren'),
                }}
                organisationName=''
              />
            </div>
          )}
          {feedMessages?.map((message) => {
            return (
              <Col
                style={{ paddingBottom: 10 }}
                span={24}
                key={message?.alias}
                onClick={() => action(message, message?.organisation?.name)}
              >
                <FeedMessage
                  feedData={{
                    ...message,
                    postColors: getFeedColorCodes(message?.feedTypeBis),
                  }}
                  organisationName={message?.organisation?.name}
                  userMail={userMail}
                />
              </Col>
            )
          })}
        </>
        )
      }
      <FeedMessageDetails
        className="FeedMessageDetails"
        visible={visibility}
        data={modalData}
        afterClose={hideDetails}
      />
    </>
  )
}

export default FeedMessages
