import React, {
  useMemo,
  useState,
} from 'react'

import {
  Alert,
} from 'antd'

const useErrors = () => {
  const [errors, setErrors] = useState([])

  const ErrorComponent = useMemo(
    () => () => (
      errors?.length
        ? (
          <Alert
            message="Erreur"
            description={
              errors?.map(msg => <div key={msg}>{msg}</div>)
            }
            type="error"
            showIcon
          />
        )
        : (<></>)
    ),
    [errors],
  )

  return {
    ErrorComponent,
    setErrors,
    errors,
    hasErrors: errors?.length > 0,
  }
}

export default useErrors
