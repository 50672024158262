import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import uniqBy from 'lodash/uniqBy'
import { BankOutlined } from '@ant-design/icons'
import Text from '~/components/common/Text'
import { colors } from '~/utils/colors'
import { getOrderStatus } from '~/constants/orderStatusCommande'
import { paymentMethod } from '~/containers/User/UserProfile/paymentMethods'

//{dayjs(order?.createdAt).format('DD MMMM YYYY').replace(/\b\w/g, (l) => l.toUpperCase())}
export const useColumns = (onStatusChange, setShowDrawer, search, sort) => useMemo(
  () => [
    {
      title: 'Date d\'émission',
      // dataIndex: 'date',
      key: 'date',
      sorter: true,
      // sorter: (a, b) => dayjs(a.date, 'DD MMMM YYYY').diff(dayjs(b.date, 'DD MMMM YYYY')),
      width: '14%',
      align: 'right',
      render: (order) => (
        <Link to={`/commandes/${order?.alias}`} style={{ marginRight: '1rem' }}>
          {dayjs(order?.createdAt).format('D MMM YYYY')}
        </Link>
      ),
    },
    {
      title: 'Organisation',
      dataIndex: 'household',
      key: 'organisation',
      sorter: true,
      // sorter: search !== '' ? (a, b) => (a?.household.organisation.name && b?.household.organisation.name
      //   ? a.household.organisation.name.localeCompare(b.household.organisation.name) : 0)
      //   : false,
      width: '14%',
      render: (household) => (
        <Text>
          <span><BankOutlined /> {household.organisation.name}</span>
        </Text>
      ),
    },
    {
      title: 'Participant(s)',
      dataIndex: 'attendances',
      key: 'attendances',
      // sorter: true,
      // sorter: search !== '' ? (a, b) => (a?.attendances && b?.attendances
      //   ? a.attendances[0]?.childName.localeCompare(b.attendances[0]?.childName) : 0)
      //   : false,
      width: '14%',
      render: (attendances) => (
        <Text color={colors.lightblue}>
          {attendances.length === 0 ? (
            <div>-</div>
          ) : (
            uniqBy(attendances, 'childName')
              .sort((a, b) => a.childName.localeCompare(b.childName))
              .map((child) => (
                <div key={child.childAlias}>
                  <Link to={`/children/${child.childAlias}`}>
                    {child.childName.split(' ').join(' ') ?? '-'}
                  </Link>
                </div>
              ))
          )}
        </Text>
      ),
    },
    {
      title: 'Montant',
      dataIndex: 'totalAmountPlusCreds',
      key: 'totalAmountPlusCreds',
      sorter: true,
      // sorter: search !== '' ? (a, b) => a.totalAmount - b.totalAmount : false,
      width: '14%',
      render: (totalAmountPlusCreds) => ( totalAmountPlusCreds == undefined ?  <Text>{`0 €`}</Text> : <Text>{`${totalAmountPlusCreds.toString().replace('.', ',')} €`}</Text> )
    },
    {
      title: 'Mode de paiement',
      dataIndex: 'relatedPayments',
      key: 'paymentMode',
      // sorter: true,
      // sorter: (a, b) => {
      //   let resultA = 0
      //   let resultB = 0

      //   if (a.relatedPayments && Array.isArray(a.relatedPayments) && a.relatedPayments.length > 0) {
      //     resultA = a.payment[0].method || 0
      //   }
      //   if (b.relatedPayments && Array.isArray(b.related_payments) && b.relatedPayments.length > 0) {
      //     resultB = b.payment[0].method || 0
      //   }

      //   return resultA - resultB
      // },
      width: '14%',
      render: (relatedPayments) => (
        <Text>{paymentMethod(relatedPayments)}</Text>
      ),
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      // sorter: (a, b) => a.status.key - b.status.key,
      width: '14%',
      render: (value) => (
        <span style={{ color: getOrderStatus(value.key)?.color }}>
          {getOrderStatus(value.key)?.label}
        </span>
      ),
    },
  ],
  [onStatusChange, setShowDrawer, search, sort],
)
