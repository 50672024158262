import styled from "styled-components"
import { Button, Steps } from "antd"
import Card from "~/components/common/Card"
import P from "~/components/common/P"

export const Container = styled(Card)``

export const StepsContainer = styled.div`
  .ant-steps-item-description {
    font-size: 16px;
  }

  .ant-steps-item:not(.ant-steps-item-wait) .ant-steps-item-description {
    color: @primary-color !important;
  }
`

export const RecurrenceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  p {
    margin: 0;
    color: #0f2d78;
  }
`
export const RecuDays = styled.div``

export const RecuDay = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 12px;
  background: #0f2d78;
  opacity: ${(props) => (props.isSelected ? "1" : "0.5")};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  color: white;
  cursor: pointer;
`

export const StyledDate = styled.div`
  border-radius: 50%;
  background: ${(props) => (props.isOpeningDay ? "#3e5387" : "white")};
  ${(props) =>
    props.isOpeningDay && `opacity : ${props.isSelected ? "1" : "0.5"};`}
  width: 25px;
  height: 25px;
  text-align: center;
  color: ${(props) => (props.isOpeningDay ? "white" : "gray")};
  margin: auto;
  font-size: 12px;
  padding-top: 3px;
  cursor: pointer;
`
export const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: #0f2d78;
  font-weight: bold;
  align-items: center;
  padding-bottom: 20px;
  button {
    color: #0f2d78;
  }
`

export const ActivityContainer = styled.div`
  border: 1px solid #e2e2e2;
  border-radius: 5px;
`

export const ContentConainer = styled.div`
  display: flex;
  padding: 57px 25px 25px;
  flex-direction: column;
`

export const DetailMainTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #0f2d78;
  margin-bottom: 8px;
`

export const DetailTitle = styled(DetailMainTitle)`
  font-size: 15px;
`

export const DetailContent = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: #0f2d78;
  opacity: 0.5;
  margin-bottom: 8px;
`

export const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  div:last-child {
    text-align: right;
  }
`

export const PhotoConainer = styled.div`
  height: 85px;
  background-color: #0f2d78;
  position: relative;
  & > img {
    width: 100%;
    height: 100%;
  }
  .ant-avatar {
    position: absolute;
    bottom: -32px;
    left: calc(50% - 47px);
  }
`

export const LocationContainer = styled(Card)`
  cursor: pointer;
  & > div {
    display: flex;
    align-items: center;
  }
  img {
    width: 80px;
  }
`

export const LocationName = styled(P)`
  color: #0f2d78;
  margin-bottom: 10px;
`

export const LocationAddress = styled(P)`
  color: #0f2d78;
  opacity: 0.5;
  margin-bottom: 0px;
`

export const Location = styled.div`
  padding-left: 10px;
`

export const SlotContainer = styled(Card)`
  position: relative;
  cursor: pointer;
  overflow: unset;
  & > div {
    padding: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    ${(props) => props.selected && "border: 1px solid #24DD98;"}
  }
  svg {
    font-size: 30px;
  }
`

export const SlotContent = styled.div`
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #0f2d78;
`

export const SelecteddMark = styled.div`
  position: absolute;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -12px;
  right: -10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: @accent-color;
  svg {
    font-size: 16px;
  }
`

export const ChildContainer = styled.div`
  cursor: pointer;
`

export const StepContainer = styled.div`
  padding: 30px 20px;
`

export const StepButtonContainer = styled.div`
  text-align: center;
`
export const StepButton = styled(Button)`
  margin: 20px 10px 10px 0;
`

export const StyledSteps = styled(Steps)`
  .ant-steps-item-tail{
    width: 95%;

    &::after {
      background-color: #6D7F9D !important;    
    }
  }

  .ant-steps-item-icon {
    width: 16px;
    height: 16px;
    margin-left: 55px;
  }

  .ant-steps-icon-dot {
    background: #6D7F9D !important;
  }

  .ant-steps-icon {
    top: -4px;
  }

  .ant-steps-item-title {
    color: #6D7F9D !important;
  }

  .ant-steps-item-finish {
    .ant-steps-item-tail::after {
      background-color: #20CD8D !important;    
    }
  }

  .ant-steps-item-finish, .ant-steps-item-active {
    .ant-steps-icon-dot {
      background: #20CD8D !important;
    }
    .ant-steps-item-icon {
      width: 16px;
      height: 16px;
    }
    .ant-steps-item-title {
      color: #20CD8D !important;
    }
  }

  .ant-steps-vertical {
    .ant-steps-item-tail {
      left: -5px;
    }
    .ant-steps-icon-dot {
      left: 0;
    }
  }
`
