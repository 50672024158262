import { createActions } from 'redux-actions'

export const {
  fetchSepa,
  fetchSepaProcess,
  fetchSepaError,
  fetchSepaSuccess,

  fetchOrgs,
  fetchOrgsProcess,
  fetchOrgsError,
  fetchOrgsSuccess,
} = createActions({
  FETCH_SEPA: () => {},
  FETCH_SEPA_PROCESS: () => {},
  FETCH_SEPA_SUCCESS: sepa => sepa,
  FETCH_SEPA_ERROR: () => {},

  FETCH_ORGS: () => {},
  FETCH_ORGS_PROCESS: () => {},
  FETCH_ORGS_SUCCESS: orgs => orgs,
  FETCH_ORGS_ERROR: () => {},
})
