import React, { useState, useEffect, useCallback } from "react"
import { Modal, Tag } from "antd"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { postDetailsFetching } from "~/containers/Home/actions"
import Loading from "~/components/common/Loading"
import HTMLTrans from "~/components/common/HTMLTrans"
// import Bullet from '~/components/common/Bullet'
import P from "~/components/common/P"
import styled from "styled-components"
import Bullet from "~/components/common/Bullet"
import Attachement from "~/components/common/Attachement"
import ActivityIcon from "./ActivityIcon"
import Stage from "~/assets/activities/stage.svg"
import dayjs from "dayjs"

import { CustomModal, Content, PostedBy, MessageTitle, Attachements, StyledMarkdown } from "./StyledComponents"

import {
  ModalTitle,
  ModalContent,
  FeedMessageImage,
  PostDetailModal,
  CustomCloseCircleOutlined,
  LeftBarContainer,
  FeedTitle,
} from "../index"
import defaultMessageImage from "~/assets/images/place-mairie.jpg"
import Markdown from "markdown-to-jsx"

const FeedMessageDetails = ({
  visible,
  data,
  ...props
}) => {
  const { t } = useTranslation("home")
  const [visibility, setVisibility] = useState(false)

  const { loading, postDetails } = useSelector(state => state.home.post)
  const home = useSelector(state => state.organisation)
  
  const dispatch = useDispatch()

  useEffect(() => {
    setVisibility(visible)
    if (data) dispatch(postDetailsFetching(data?.organisation?.alias || data?.userOrganisationAlias, data.alias))
  }, [visible, data])

  const handleClose = () => {
    setVisibility(false)
  }
  const { postColors, organisationName } = data || {}

  const JsxMarkdown = useCallback(() => {
    if (!postDetails?.content_markdown) return <></>

    const imagesVariables = postDetails?.imagesMapping?.reduce((acc, curr) => {
      acc[curr.variable] = curr.url

      return acc
    }, {})

    let formattedContentMarkdown = postDetails.content_markdown
    
    Object.keys(imagesVariables).forEach(variable => {
      formattedContentMarkdown = formattedContentMarkdown.replaceAll(
        variable,
        `![image](${imagesVariables[variable]})`
      )
    })

    console.log({vvv: postDetails.content_markdown, formattedContentMarkdown, imagesVariables})

    return (
      <StyledMarkdown>
        {formattedContentMarkdown}
      </StyledMarkdown>
    )
  }, [postDetails])

  console.log({data, postDetails})

  return (
    <CustomModal
      {...props}
      centered
      open={visibility}
      closeIcon={
        <CustomCloseCircleOutlined />
      }
      onCancel={handleClose}
      footer={null}
    >
      {loading && <Loading />}
      {!loading && data && (
        <>
          <PostDetailModal {...postColors}>
            <FeedMessageImage src={postDetails.photo || defaultMessageImage} />

            <LeftBarContainer {...postColors}>
              <ActivityIcon width={75} height={75} activityType={postDetails.activityType}/>
              <Content>
                <FeedTitle as="div" bold uppercase size="medium" {...postColors}>
                  <Bullet {...postColors} />
                  {postDetails.postType}
                </FeedTitle>
                <PostedBy>
                  {organisationName} le {dayjs(postDetails.modified_at).format("DD/MM/YYYY")}
                </PostedBy>
                <MessageTitle>
                  {postDetails.title}
                </MessageTitle>
                {/* <HTMLTrans>{`<div>${postDetails.content}</div>`}</HTMLTrans> */}
                {postDetails?.editorMode === 0 ? (
                  <div style={{ whiteSpace: "pre-wrap" }}>{postDetails.content}</div>
                ) : (
                  <JsxMarkdown />
                )}
              </Content>
              <Attachements>
                {!!postDetails ?.attachments ?.length && (
                  <>
                    {/* <br /> */}
                    {/* <P bold size="medium">{t('feed-message.attachements')} : </P> */}
                    {postDetails.attachments.map((a, index) =>
                      // <Tag key={a.alias}><a href={a.file_url} target="_blank">{a.name}</a></Tag>
                      <Attachement data={a} key={index} />
                    )}
                  </>
                )}
              </Attachements>
            </LeftBarContainer>
          </PostDetailModal>
        </>
      )}
    </CustomModal>
  )
}

FeedMessageDetails.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.object]),
}

FeedMessageDetails.defaultProps = {
  visible: false,
  data: null,
}

export default FeedMessageDetails

