import {
  all,
  takeLatest, takeEvery,
  put,
  select,
} from 'redux-saga/effects'

import axios from '~/lib/axios'

// redux
import { getAuthUserInfos } from '~/containers/User/selectors'
import { checkJwtExpiration } from '~/containers/User/sagas'
import { logout } from '~/containers/User/actions'
import {
  fetchContacts,
  fetchContactsProcess,
  fetchContactsSuccess,
  fetchContactsError,

  createContact,
  createContactProcess,
  createContactError,
  createContactSuccess,

  updateContact,
  updateContactProcess,
  updateContactError,
  updateContactSuccess,

  deleteContact,
  deleteContactProcess,
  deleteContactError,
  deleteContactSuccess,
} from './actions'

/**
 * fetchContacts
 */
export function* fetchContactsSaga() {
  
  try {
    yield* checkJwtExpiration()
    yield put(fetchContactsProcess())

    const authUserInfos = yield select(getAuthUserInfos)

    if (authUserInfos.email) {
      const response = yield axios.get(`users/${authUserInfos.email}/contacts/`)
      
      yield put(fetchContactsSuccess(response.data))
    } else {
      yield put(fetchContactsError())
      yield put(logout())
    }
  } catch (err) {
    console.log(err)
    yield put(fetchContactsError())
  }
}

export function* createContactSaga({payload: { contact, callback }}) {
  
  try {
    yield* checkJwtExpiration()
    yield put(fetchContactsProcess())

    const authUserInfos = yield select(getAuthUserInfos)
    if (!authUserInfos.email) {
      yield put(createContactError())
      yield put(logout())
      return
    }

      const response = yield axios.post(`users/${authUserInfos.email}/contacts/`, {...contact})

    console.log({response})
    if (typeof callback === "function") callback()
    yield put(createContactSuccess())
    yield* fetchContactsSaga()
  } catch (err) {
    //~ if (typeof callback === "function") callback(null);
    console.log({err})
    if (typeof callback === "function") callback(Object.values(err?.response?.data))
    yield put(createContactError())
  }
}

//~ Update, contact include alias ?
export function* updateContactSaga({payload: { alias, contact, callback }}) {
  
  try {
    yield* checkJwtExpiration()
    //~ yield put(updateContactProcess())

    const authUserInfos = yield select(getAuthUserInfos)
    if (!authUserInfos.email) {
      yield put(updateContactError())
      yield put(logout())
      return
    }

      const response = yield axios.put(`users/${authUserInfos.email}/contacts/${alias}/`, {...contact})

    if (typeof callback === "function") callback()
    yield put(updateContactSuccess())
    // yield put(fetchContactsProcess())
    yield* fetchContactsSaga()
  } catch (err) {
    //~ if (typeof callback === "function") callback(null);
    console.log({err})
    if (typeof callback === "function") callback(Object.values(err?.response?.data))
    yield put(updateContactError())
  }
}

export function* deleteContactSaga({payload: { alias, callback }}) {
  
  try {
    yield* checkJwtExpiration()
    yield put(deleteContactProcess())

    const authUserInfos = yield select(getAuthUserInfos)
    if (!authUserInfos.email) {
      yield put(deleteContactError())
      yield put(logout())
      return
    }

      const response = yield axios.delete(`users/${authUserInfos.email}/contacts/${alias}/`)

    if (typeof callback === "function") callback(response.data)
    yield put(deleteContactSuccess())
  } catch (err) {
    //~ if (typeof callback === "function") callback(null);
    console.log(err)
    yield put(deleteContactError())
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(fetchContacts, fetchContactsSaga),
    takeLatest(createContact, createContactSaga),
    takeLatest(updateContact, updateContactSaga),
    takeLatest(deleteContact, deleteContactSaga),
  ])
}
