import axios from '~/lib/axios'

export const submitRegisteration = ({ userInfos, childAlias, registrationAlias }) => (
  axios.post(`users/${userInfos.email}/children/${childAlias}/registrations/${registrationAlias}/submit/`)
)

export const createRegisteration = ({ userInfos, childAlias, data, organisationAlias }) => (
  axios.post(`users/${userInfos.email}/children/${childAlias}/registrations/`, {
    organisationAlias,
    ...data,
  })
)
export const updateRegistration = ({
  userInfos,
  childAlias,
  registrationAlias,
  data,
}) => {
  let customHeader = {}
  if (data instanceof FormData) {
    customHeader = { headers: { 'content-type': 'multipart/form-data' } }
  }
  return axios.patch(`users/${userInfos.email}/children/${childAlias}/registrations/${registrationAlias}/`,
    data,
    { ...customHeader })
}
export default {
  submitRegisteration,
  createRegisteration,
  updateRegistration,
}
