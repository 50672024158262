import PropTypes from 'prop-types'
import React, {
  useState,
  useEffect,
} from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Loading from '~/components/common/Loading'
import { CloseCircleFilled } from '@ant-design/icons'

const ErrorContainer = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: fade(@primary-color, 10%);
`

const ErrorIcon = styled(CloseCircleFilled)`
  font-size: 24px;
  color: @primary-color;
  margin-bottom: 10px;
`

const ImageComponent = ({
  src,
  alt,
  ...props
}) => {
  const { t } = useTranslation('common')
  const [error, setError] = useState(null)
  const [image, setImage] = useState(null)

  useEffect(() => {
    setImage(null)
    setError(null)

    const img = new Image()
    img.onload = () => {
      setImage(src)
    }
    img.onerror = err => {
      setError(err)
    }
    img.src = src
  }, [src])

  if (error) {
    return (
      <ErrorContainer>
        <div style={{ textAlign: 'center' }}>
          <ErrorIcon />
          <br />
          {t('image.error')}
        </div>
      </ErrorContainer>
    )
  }

  if (image) {
    return (
      <img
        src={src}
        alt={alt}
        {...props}
      />
    )
  }

  return <Loading text={false} />
}

ImageComponent.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
}

ImageComponent.defaultProps = {
  alt: '',
}

export default ImageComponent
