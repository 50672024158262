import { Button, Form, Select } from "antd"
import dayjs from "dayjs"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import AddCard from "~/components/common/AddCard"
import ChildrenCard from "~/components/common/ChildrenCard"
import Container from "~/components/common/Container"
import Drawer from "~/components/common/Drawer"
import H1 from "~/components/common/H1"
import { getSchoolLevel } from "~/constants"
import {
  fetchUserPhoto, updateRelationship
} from "~/containers/User/actions"
import { isJson } from "~/utils/isJson"
import duration from "dayjs/plugin/duration"

dayjs.extend(duration)

const Option = { Select }

const Profile = ({ history }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [lierUnEnfant, setLierUnEnfant] = useState(false)
  const { user, isUpdatingRelationship } = useSelector((state) => state.user.userInfo)
  const { children, isFetching } = useSelector((state) => state.children)
  
  const address = isJson(user.address) && JSON.parse(user.address)

  console.log({address})

  const getProfilePicture = useCallback(async () => {
    dispatch(fetchUserPhoto())
  }, [])

  useEffect(() => {
    getProfilePicture()
  }, [])

  const handleSubmit = useCallback(
    values => {
      
      dispatch(updateRelationship({
        child: values.child,
        relationship: values.relationship,
        onSuccess: () => setLierUnEnfant(false)
      }))
    },
    [],
  )

  return (
    <div>
      <Container>
        <NameContainer>
          {user.civility === 0 ? "Monsieur " : "Madame "}
          {user.first_name} {user.last_name}
        </NameContainer>
        <div>
          <Bold>Date de naissance :</Bold> {user.date_of_birth && dayjs(user.date_of_birth).format("DD/MM/YYYY")}
        </div>
        <div>
          <Bold>Téléphone Mobile :</Bold> {user.mobile}
        </div>
        <div>
          <Bold>Téléphone Fixe :</Bold> {user.phone}
        </div>
        <div>
          <Bold>Adresse :</Bold> {address?.formatted_address}
        </div>
      </Container>
      <Container>
        <H1>Je suis responsable légal de :</H1>
        <SameHeightList>
          {children?.map((child) => (
            <ChildrenCard
              key={child.alias}
              menu={null}
              photo={child.photo}
              last_name={child.last_name}
              first_name={child.first_name}
              alias={child.alias}
              age={
                child.date_of_birth
                  ? Math.ceil(
                      dayjs
                        .duration(dayjs().diff(child.date_of_birth))
                        .as("years")
                    )
                  : null
              }
              gender={child.gender}
              schoolClassName={child.school_class?.name}
              schoolName={child?.school?.name}
              schoolLevel={getSchoolLevel(child.classtype)?.label}
            />
          ))}
          <AddCard
            title="Lier un enfant"
            onClick={() => {
              setLierUnEnfant(true)
            }}
          ></AddCard>
          {/* <AddChildCard onClick={() => setShowEditDrawer(true)} /> */}
        </SameHeightList>
      </Container>
      <Drawer
        open={lierUnEnfant}
        handleCloseDrawer={() => setLierUnEnfant(false)}
        title="Lier un enfant"
      >
        <Form
          layout="vertical"
          size="large"
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item
            label="J’interviens en qualité de"
            name="relationship"
            rules={[{ required: true, message: "Selectionnez une relation !" }]}
          >
            <Select placeholder="Sélectionner">
              <Option value={0}>Mère</Option>
              <Option value={1}>Père</Option>
              <Option value={2}>Tuteur</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Enfant de mon foyer"
            name="child"
            rules={[{ required: true, message: "Selectionnez un enfant !" }]}
          >
            <Select placeholder="Sélectionner">
              {children.map(child => (
                <Option
                  key={child.alias}
                  value={child.alias}
                >
                  {child.first_name} {child.last_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: "100%", marginTop: 20, marginBottom: 50 }}
            loading={isUpdatingRelationship}
            // onClick={() => setLierUnEnfant(false)}
          >
            Enregistrer
          </Button>
        </Form>
      </Drawer>
    </div>
  )
}
export default Profile

const NameContainer = styled.div`
  font-size: 20px;
  line-height: 28px;
  color: @neutral-5;
  margin-bottom: 10px;
`

const Bold = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: @neutral-5;
`

const SameHeightList = styled.div`
  display: flex;
  flex-flow: wrap;
`
