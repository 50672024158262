import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Image from '~/components/common/Image'
import ActivityIcon from '~/components/common/ActivityIcon'
import Divider from '~/components/common/Divider'
import Date from '~/components/common/Date'
import Href from '~/components/common/Href'

import {
  ActivityContainer,
  PhotoConainer,
  ContentConainer,
  DetailMainTitle,
  DetailContent,
  DetailContainer,
  DetailTitle,
} from './index'

const ActivityDetails = ({ activity }) => {
  const { t } = useTranslation('subscription')
  return (
    <ActivityContainer>
      <PhotoConainer>
        {activity.photo && <Image src={activity.photo} alt="" />}
        <ActivityIcon activityType={activity.activity_type} size={90} />
      </PhotoConainer>

      <ContentConainer>
        <DetailMainTitle>{activity.name}</DetailMainTitle>
        <DetailContent>{activity.description}</DetailContent>
        <Divider />
        <DetailContainer>
          <DetailTitle>{t('activity-details.start_date')}</DetailTitle>
          <DetailContent><Date>{activity.start_date}</Date></DetailContent>
        </DetailContainer>

        <DetailContainer>
          <DetailTitle>{t('activity-details.end_date')}</DetailTitle>
          <DetailContent><Date>{activity.end_date}</Date></DetailContent>
        </DetailContainer>

        <DetailContainer>
          <DetailTitle>{t('activity-details.start_time')}</DetailTitle>
          <DetailContent>{activity.start_time} à {activity.end_time}</DetailContent>
        </DetailContainer>

        <Divider />
        <DetailContainer>
          <DetailTitle>{t('activity-details.town_hall')}</DetailTitle>
          <DetailContent>{activity.organisationName}</DetailContent>
        </DetailContainer>
        {activity.phone && (
        <DetailContainer>
          <DetailTitle>{t('activity-details.phone')}</DetailTitle>
          <DetailContent><Href type="phone">{activity.phone}</Href></DetailContent>
        </DetailContainer>
        )}
        {activity.email && (
        <DetailContainer>
          <DetailTitle>{t('activity-details.email')}</DetailTitle>
          <DetailContent><Href type="email">{activity.email}</Href></DetailContent>
        </DetailContainer>
        )}
        <DetailContainer>
          <DetailTitle>{t('activity-details.address')}</DetailTitle>
          <DetailContent>{activity.organisationAddress.formatted_address}</DetailContent>
        </DetailContainer>
      </ContentConainer>
    </ActivityContainer>
  )
}

ActivityDetails.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default ActivityDetails
