import React from "react"
import styled from 'styled-components'
import {
  FileImageOutlined,
  RightOutlined,
} from '@ant-design/icons'

const DisplayFlexRow = styled.div`
  display: flex;
  flexDirection: row;
  padding:20px;
  border-top: 1px solid grey;
`

const CustomFileImageOutlined = styled(FileImageOutlined)`
  font-size: 32px;
  color: #2F4580;
`

const CustomRightOutlined = styled(RightOutlined)`
  font-size: 32px;
  color: #2F4580;
`

const AttachementText = styled.div`
  padding-right: 10px;
  padding-left: 10px;
  font-size: 16px;
  line-height: 32px;
  color:#2F4580;
`

const Attachement = (data) => {
  
  return (
    <a href={data.data.file_url} target="_blank">
      <DisplayFlexRow>
        <CustomFileImageOutlined />

        <AttachementText>

          {data.data.name}
        </AttachementText>
        <CustomRightOutlined />
      </DisplayFlexRow>
    </a>
  )
}

export default Attachement