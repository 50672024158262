import styled from 'styled-components'
import Card from '~/components/common/Card'

export const ChildDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ChildData = styled.div`
    display: flex;
    width: 100%;
    margin-top: 10px;
`

export const ChildAvaterCard = styled(Card)`
  & > div{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 padding: 0px 30px;
 max-width: 180px;
 max-height: 180px;

 .child-name{
   color: #0f2d78;
   margin-top: 10px;
 }
`

export const ChildActiviy = styled.div`
  width: 100%;
  margin-left: 20px;
`

export const ChildSchoolCard = styled(Card)`
  & > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .school-info{
      margin-left: 15px;
    }
    .reg-with-link{
      cursor: pointer;
    }
    .school-name{
      color: @dark-color;
      margin-bottom: 5px;
    }
    .child-class-name{
      color: @gray-color;
      font-size: 13px;
      margin-bottom: 0px;
    }
    .left-container{
      display: flex;
      align-items: center;
    }
    .reg-status{
      color: @gray-color;
      font-size: 13px;
      margin: 10px 0 0 -5px;
    }
    .renewal{
      float: right;
    }
  }
`

export const RegistrationContainer = styled.div`
  margin-top: 30px;

  .organisation-city{
    line-height: 30px;
    margin-bottom: 0;
  }

  .organisation-new-subscription-button{
    float: right;
  }
`

export const CTARegister = styled(Card)`
  cursor:pointer;
  border-left: 8px solid #7FB2F5;
  margin-top: 15px;
  & > div *{
    margin: 0;
    text-align: center;
  }
  &:hover{
    opacity: 0.9
  }
`
