import { Avatar, Space, Spin } from "antd";
import dayjs from "dayjs";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Centered from '~/components/common/Centered';
import { MainContent } from '~/components/common/Container';
import Gender from "~/components/common/Gender";
import H2 from "~/components/common/H2";
import { fetchChildren } from '~/containers/Children/actions';

const getChildAge = (dateOfBirth) => {
  if (!dateOfBirth) return "";
  const years = dayjs().diff(dateOfBirth, "years", false);
  const months = dayjs().diff(dateOfBirth, "months", false);
  const days = dayjs().diff(dateOfBirth, "days", false);

  if (years > 0) return `${years} ans`;
  if (years === 0 && months > 0) return `${months} mois`;
  return `${days} jours`;
};

const ChooseChild = ({
  setShowAllActivitiesCalendar,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isFetchingChildDetails,
    children,
    isFetching: isFetchingChildren,
    attendance,
    selectedChildrenDetails,
  } = useSelector((state) => state.children);

  useEffect(() => {
    dispatch(fetchChildren());
  }, []);

  if (isFetchingChildren) {
    return (
      <MainContent>
        <Centered>
          <Spin
            tip='Chargement ..'
          />
        </Centered>
      </MainContent>
    );
  }

  return (
    <MainContent>
      <Space
        size={24}
        style={{
          flexFlow: 'wrap',
        }}
      >
        <Card
          onClick={() => {
            setShowAllActivitiesCalendar(true);
          }}
        >
          <Space
            size={-40}
          >
            <Avatar
              src={children?.[0]?.photo}
              size={100}
            />
            <Avatar
              src={children?.[1]?.photo}
              size={100}
            />
          </Space>
          <H2>
            TOUS
          </H2>
        </Card>
        {children?.map(child => (
          <Card
            onClick={() => {
              navigate(`/children/${child?.alias}/activity`);
            }}
          >
            <Avatar
              src={child?.photo}
              size={100}
            />
            <Space>
              <H2
                style={{
                  marginBottom: 0,
                }}
              >
                {child?.first_name}
                {' '}
                {child?.last_name}
              </H2>
              <Gender gender={child?.gender} />
            </Space>
            <span>
              {getChildAge(child?.date_of_birth)}
            </span>
          </Card>
        ))}
      </Space>
    </MainContent>
  );
};

const Card = styled.div`
  width: 266px;
  height: 224px;
  border-radius: 4px;
  border: 1px solid #969696;
  display: flex;
  flex-direction: column  ;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  cursor: pointer;

  &:hover {
    background-color: #EEF1FE;
  }
`;

export default ChooseChild;

