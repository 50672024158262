
import { handleActions } from "~/utils/redux-actions"

import {
  feedFetching,
  feedFetched,
  feedFetchError,
  postDetailsFetching,
  postDetailsFetched,
  postDetailsFetchError,
  userOrganisationsFetching,
  userOrganisationsFetched,
  userOrganisationsFetchError,
  userBillsFetching,
  userBillsFetched,
  userBillsFetchError,
} from "./actions"

import { logout } from "~/containers/User/actions"

const defaultState = {
  feed: {
    feedList: { results: [] },
    loading: false,
    error: false,
  },
  post: {
    postDetails: {},
    loading: false,
    error: false,
  },
  organisation: {
    userOrganisationsList: [],
    loading: false,
    error: false,
  },
  bill: {
    userBillsList: [],
    loading: false,
    error: false,
  },
}

export default handleActions(
  {
    // Logout
    [logout]: () => defaultState,

    // User Feed
    [feedFetching]: (state) => {
      state.feed.feedList = { results: [] }
      state.feed.loading = true
      state.feed.error = false
    },
    [feedFetched]: (state, { payload }) => {
      state.feed.feedList = { ...payload }
      state.feed.loading = false
      state.feed.error = false
    },
    [feedFetchError]: (state) => {
      state.feed.feedList = { results: [] }
      state.feed.loading = false
      state.feed.error = true
    },

    // Post Feed
    [postDetailsFetching]: (state) => {
      state.post.loading = true
      state.post.error = false
    },
    [postDetailsFetched]: (state, { payload }) => {
      state.post.postDetails = payload
      state.post.loading = false
      state.post.error = false
    },
    [postDetailsFetchError]: (state) => {
      state.post.postDetails = {}
      state.post.loading = false
      state.post.error = true
    },

    // User's Organisations list
    [userOrganisationsFetching]: (state) => {
      state.organisation.loading = true
      state.organisation.error = false
    },
    [userOrganisationsFetched]: (state, { payload }) => {
      state.organisation.userOrganisationsList = [...payload]
      state.organisation.loading = false
      state.organisation.error = false
    },
    [userOrganisationsFetchError]: (state) => {
      state.organisation.userOrganisationsList = []
      state.organisation.loading = false
      state.organisation.error = true
    },

    // User's Bills list
    [userBillsFetching]: (state) => {
      state.bill.loading = true
      state.bill.error = false
    },
    [userBillsFetched]: (state, { payload }) => {
      state.bill.userBillsList = [...payload]
      state.bill.loading = false
      state.bill.error = false
    },
    [userBillsFetchError]: (state) => {
      state.bill.userBillsList = []
      state.bill.loading = false
      state.bill.error = true
    },
  },
  defaultState
)
