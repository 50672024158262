import styled from 'styled-components'

export const Title = styled.h1`
  color: @neutral-5;
  font-size: 20px;
  margin-bottom: 20px;
  flex: 0 0 100%;
`
export const SameHeightList = styled.div`
  display: flex;
  flex-flow: wrap;
`
