import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Text from '~/components/common/Text'



export const useColumnsOrderDetail = (onStatusChange, setShowDrawer) => useMemo(
  () => [
    {
      title: 'Enfant',
      // dataIndex:"enfant",
      key:"enfant",
      // sorter: true,
      width: '14%',
      render: ({ childName, childAlias }) => (
        <Link to={`/children/${childAlias}`}>{childName}</Link>
      ),
    },
    {
      title: 'Activité',
      // dataIndex:"activité",
      key:"activité",
      // sorter: (a, b) => a.activityName?.length - b.activityName?.length,
      width: '14%',
      render: ({ activityName, activityAlias }) => (
        <Link to={`/children/${activityAlias}/activity`}>{activityName}</Link>
      ),
    },
    {
      title:"Réservation(s)",
      dataIndex: 'allPresences',
      key: 'allPresences',
      // sorter: true,
      width: '33%',
      render: (allPresences) => <Text className={{ color: 'red' }}>{allPresences}</Text>,
    },
    {
      title: () => <span style={{ marginLeft: '8rem' }}>Montant</span>,
      dataIndex: 'amount',
      // dataIndex: 'montant',
      key: 'amount',
      // sorter: (a, b) => a.amount - b.amount,
      width: '14%',
      align: 'center',
      render: (amount) => <Text style={{ marginLeft: '4.3rem' }}>{`${amount} €`}</Text>,
    },
  ],
  [onStatusChange, setShowDrawer],
)

