import styled, { css } from "styled-components"
import { Button, Input, Steps } from "antd"
import Card from "~/components/common/Card"
import P from "~/components/common/P"

export const SelectChildTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #2f4580;
  margin-bottom: 30px;
`

export const StepContainer = styled.div`
  padding: 30px 20px;
`
export const ChildContainer = styled.div`
  cursor: pointer;
`
export const StepButtonContainer = styled.div`
  text-align: center;
`
export const StepButton = styled(Button)`
  margin: 20px 10px 10px 0;
`
export const ZipCodeInput = styled(Input)`
  display: block;
  max-width: 300px;
  margin: 20px auto 30px auto;
  text-align: center;
`

export const Preview = styled.div`
  width: 100px;
  height: 100px;
  border-radius:50%;
  overflow:hidden;
  position:relative;
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto 20px auto;
  cursor:pointer;
  img{
    width: 100%;
    object-fit: cover;
  }
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}
`
export const DocumentContainer = styled(Card)`
  position: relative;
  cursor: pointer;
  overflow: unset;
  & > div {
    padding: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    ${(props) => props.filled && "border: 1px solid #24DD98;"}
  }
`

export const DocumentName = styled.div`
  font-weight: bold;
  padding: 0 16px;
`

export const DocumentPreview = styled.div`
  width:64px;
  height:64px;
  border-radius:50%;
  overflow:hidden;
  position:relative;
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  & > div{
    position:absolute;
  }
  img {
    width:100%
  }
  .anticon-file-text svg{
    font-size: 30px;
  }
}
`

export const InputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

export const Filled = styled.div`
  position: absolute;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -12px;
  right: -10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: @accent-color;
`

export const OrganisationContainer = styled(Card)`
  border-left: 8px solid #f4c26d;
  cursor: pointer;
  & > div {
    display: flex;
    align-items: center !important;
    padding-right: 0px;
    align-items: flex-start;
    padding: 15px 0 15px 15px;
  }
`

export const OrgainsationContent = styled.div`
  flex: 1;
  padding-right: 10px;
`

export const OrganisationTitle = styled(P)`
  color: #0f2d78;
  margin-bottom: 0;
`

export const RecapElementContainer = styled.div`
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #0f2d78;
`

export const RecapElementTitle = styled.div`
  color: @grey-color;
  font-size: 15px;
  text-transform: capitalize;
`

export const RecapElementContent = styled.div`
  color: #0f2d78;
  font-weight: bolder;
  font-size: 15px;
`

export const StyledSteps = styled(Steps)`
  .ant-steps-item-tail{
    width: 95%;

    &::after {
      background-color: #6D7F9D !important;    
    }
  }

  .ant-steps-item-icon {
    width: 16px !important;
    height: 16px !important;
    margin-left: 60px;
  }

  .ant-steps-icon-dot {
    background: #6D7F9D !important;
  }

  .ant-steps-icon {
    top: -4px;
  }

  .ant-steps-item-title {
    color: #6D7F9D !important;
  }

  .ant-steps-item-finish {
    .ant-steps-item-tail::after {
      background-color: #20CD8D !important;    
    }
  }

  .ant-steps-item-finish, .ant-steps-item-active {
    .ant-steps-icon-dot {
      background: #20CD8D !important;
    }
    .ant-steps-item-icon {
      width: 16px !important;
      height: 16px !important;
    }
    .ant-steps-item-title {
      color: #20CD8D !important;
    }
  }

  .ant-steps-vertical {
    .ant-steps-item-tail {
      left: -5px;
    }
    .ant-steps-icon-dot {
      left: 0;
    }
  }
`
