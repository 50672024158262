import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import { Document, Page } from 'react-pdf/dist/esm/index.js'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Document, Page } from 'react-pdf'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
    .react-pdf {
      &__Document {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__Page {
        width: calc(~"50% - 2em");
        box-shadow: 0 0 8px rgba(0, 0, 0, .5);
        
        canvas {
          max-width: 100%;
          height: auto !important;
        }
      }
    }
`

const PdfPreview = ({ file, ...props }) => {
  const [numPages, setNumPages] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)

  const onLoadSuccess = () => {
    setNumPages(numPages)
  }

  const handleClickPrev = () => {
    setPageNumber(pageNumber - 1)
  }
  const handleClickNext = () => {
    setPageNumber(pageNumber + 1)
  }

  return (
    <Card>
      <Document
        {...props}
        file={file}
        onLoadSuccess={onLoadSuccess}
        onLoadError={console.error}
      >
        <Page pageNumber={pageNumber} />
        {
        numPages > 1
        && (
          <div>
            <button
              type="button"
              disabled={pageNumber === 1}
              onClick={handleClickPrev}
            >
              <LeftOutlined /> {pageNumber}
            </button>
            <button
              type="button"
              disabled={pageNumber === numPages}
              onClick={handleClickNext}
            >
              {numPages} <RightOutlined />
            </button>
          </div>
        )
      }
      </Document>
    </Card>
  )
}

PdfPreview.propTypes = {
  file: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),

}

PdfPreview.defaultProps = {
  file: null,
}
export default PdfPreview
