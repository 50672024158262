import { Button, Form, Input, Radio, Select } from "antd"
import React from 'react'
import Drawer from "~/components/common/Drawer"
import Space from "~/components/common/Space"
import useErrors from "~/hooks/useErrors"

const { Option } = Select

const AddContactDrawer = ({ showDrawer, setShowDrawer }) => {

  let initialState = {}
  const [form] = Form.useForm()
  const { ErrorComponent, setErrors } = useErrors()

  const onFinish = (values) => {
    
  }

  const onFinishFailed = (errorInfo) => {
    
  }

  return (
    <Drawer
      open={showDrawer}
      handleCloseDrawer={() => setShowDrawer(false)}
      width={636}
      title="Ajouter / Éditer un contact autorisé"
    >
      <Form layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialState}
      >
        <ErrorComponent />
        <Form.Item
          label="Tranche de revenu"
          name="tranche"
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            }
          ]}
        >
          <Radio.Group>
            <Space>
              <Radio.Button
                value={0}
              >
                Madame
                  </Radio.Button>
              <Radio.Button
                value={1}
              >
                Monsieur
                  </Radio.Button>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Prénom"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            }
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="Nom"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            }
          ]}
        >
          <Input placeholder=""></Input>
        </Form.Item>
        <Form.Item
          label="Téléphone"
          name="tel"
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            }
          ]}
        >
          <Input placeholder=""></Input>
        </Form.Item>
        <Form.Item
          label="Adresse e-mail"
          name="email"
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            }
          ]}
        >
          <Input placeholder=""></Input>
        </Form.Item>
        <Form.Item
          label="Lien"
          name="link"
          rules={[
            {
              required: true,
              message: "Ce champ ne peut être vide"
            }
          ]}
        >
          <Select>
            <Option>a</Option>
            <Option>a</Option>
            <Option>a</Option>
            <Option>a</Option>
            <Option>a</Option>
            <Option>a</Option>
          </Select>
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          style={{ width: "100%", marginTop: 20 }}
          onClick={() => setShowDrawer(false)}
        >
          Enregistrer
      </Button>
      </Form>
    </Drawer>
  )
}

export default AddContactDrawer