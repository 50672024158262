import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation ($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      success
      errors
      unarchiving
      token
      refreshToken
      user {
        id
        username
        email
        isStaff
        isActive
      }
    }
  }
`;

export const ADD_CHILDREN_TO_CLASS = gql`
  mutation ($children: [UUID]!, $classAlias: UUID!) {
    addChildrenToClass(classAlias: $classAlias, children: $children) {
      schoolClass {
        alias
      }
    }
  }
`;

export const REMOVE_CHILDREN_FROM_CLASS = gql`
  mutation ($children: [UUID]!, $classAlias: UUID!) {
    removeChildrenFromClass(classAlias: $classAlias, children: $children) {
      schoolClass {
        alias
      }
    }
  }
`;

export const CREATE_SCHOOL_CLASS = gql`
  mutation ($school: UUID!, $name: String!) {
    createSchoolClass(school: $school, name: $name) {
      schoolClass {
        alias
      }
    }
  }
`;

export const UPDATE_SCHOOL_CLASS = gql`
  mutation ($classAlias: UUID!, $name: String!, $school: UUID!) {
    updateSchoolClass(classAlias: $classAlias, name: $name, school: $school) {
      schoolClass {
        alias
      }
    }
  }
`;

export const DELETE_SCHOOL_CLASS = gql`
  mutation ($classes: [UUID]!) {
    deleteSchoolClasses(classes: $classes) {
      success
    }
  }
`;

export const DELETE_REGULARIZATION = gql`
  mutation ($regularization: ID!) {
    deleteBillRegularization(id: $regularization) {
      ok
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation (
    $email: String!
  ) {
    forgotPassword(
      email: $email,
      channel: "PFW"
    ){
      status
      msg
    }
  }
`;
