import {
  createStore,
  applyMiddleware,
} from 'redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'

import reducers from '~/reducers'
import rootSaga from '~/sagas'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [thunk, sagaMiddleware]

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middlewares)),
)

sagaMiddleware.run(rootSaga)

export default store
