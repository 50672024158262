import { createActions } from 'redux-actions'

export const {
  registrationDetailsFetching,
  registrationDetailsFetched,
  registrationDetailsFetchError,

  childRegistrationFetching,
  childRegistrationFetched,
  childRegistrationFetchError,

  registrationToOrganisationFetching,
  registrationToOrganisationFetched,
  registrationToOrganisationFetchError,

  registrationCreating,
  registrationCreated,
  registrationCreateError,

  registrationUpdating,
  registrationUpdated,
  registrationUpdateError,

  commentSubmitting,
  commentSubmitted,
  commentSubmitError,

  registrationSubmitting,
  registrationSubmitted,
  registrationSubmitError,

  organisationsByZipCodeFetching,
  organisationsByZipCodeFetched,
  organisationsByZipCodeFetchError,

  clearOrganisationsByZipCode,

  documentUploading,
  documentUploaded,
  documentUploadError,

  documentFetching,
  documentFetched,
  documentFetchError,

  documentsTypesFetching,
  documentsTypesFetched,
  documentsTypesFetchError,

  orgDocumentsFetching,
  orgDocumentsFetched,
  orgDocumentsFetchError,

  linkDocumentToRegistration,
  linkDocumentToRegistrationSuccess,
  linkDocumentToRegistrationError,

  fetchRegistrationsDetails,
  fetchRegistrationsDetailsSuccess,
  fetchRegistrationsDetailsError,

  fetchAllRegistrations,
  fetchAllRegistrationsSuccess,
  fetchAllRegistrationsError,
} = createActions({
  REGISTRATION_DETAILS_FETCHING: (
    childAlias,
    registrationAlias,
    callback,
  ) => ({
    childAlias,
    registrationAlias,
    callback,
  }),
  REGISTRATION_DETAILS_FETCHED: registration => registration,
  REGISTRATION_DETAILS_FETCH_ERROR: () => ({}),

  //
  CHILD_REGISTRATION_FETCHING: (childAlias, callback) => ({ childAlias, callback }),
  CHILD_REGISTRATION_FETCHED: registrations => registrations,
  CHILD_REGISTRATION_FETCH_ERROR: () => ({}),

  //
  REGISTRATION_TO_ORGANISATION_FETCHING: (
    childAlias,
    organisationAlias,
    callback,
  ) => ({
    childAlias,
    organisationAlias,
    callback,
  }),
  REGISTRATION_TO_ORGANISATION_FETCHED: registration => registration,
  REGISTRATION_TO_ORGANISATION_FETCH_ERROR: () => ({}),

  REGISTRATION_CREATING: (
    childAlias,
    organisationAlias,
    data,
    callback,
  ) => ({
    childAlias,
    organisationAlias,
    data,
    callback,
  }),
  REGISTRATION_CREATED: () => ({}),
  REGISTRATION_CREATE_ERROR: () => ({}),

  REGISTRATION_UPDATING: (
    childAlias,
    registrationAlias,
    data,
    callback,
  ) => ({
    childAlias,
    registrationAlias,
    data,
    callback,
  }),
  REGISTRATION_UPDATED: registration => registration,
  REGISTRATION_UPDATE_ERROR: () => ({}),

  COMMENT_SUBMITTING: (
    childAlias,
    registrationAlias,
    content,
  ) => ({
    childAlias,
    registrationAlias,
    content,
  }),
  COMMENT_SUBMITTED: () => ({}),
  COMMENT_SUBMIT_ERROR: () => ({}),

  REGISTRATION_SUBMITTING: (
    childAlias,
    registrationAlias,
    cb,
  ) => ({
    childAlias,
    registrationAlias,
    cb,
  }),
  REGISTRATION_SUBMITTED: () => ({}),
  REGISTRATION_SUBMIT_ERROR: () => ({}),

  ORGANISATIONS_BY_ZIP_CODE_FETCHING: (
    zipCode,
    page,
  ) => ({
    zipCode,
    page,
  }),
  ORGANISATIONS_BY_ZIP_CODE_FETCHED: organisations => organisations,
  ORGANISATIONS_BY_ZIP_CODE_FETCH_ERROR: () => ({}),

  CLEAR_ORGANISATIONS_BY_ZIP_CODE: () => {},

  DOCUMENT_UPLOADING: (
    data,
    onSuccess,
    onFailure,
  ) => ({
    data,
    onSuccess,
    onFailure,
  }),
  DOCUMENT_UPLOADED: () => ({}),
  DOCUMENT_UPLOAD_ERROR: () => ({}),

  DOCUMENT_FETCHING: (
    documentAlias,
    onSuccess,
    onFailure,
  ) => ({
    documentAlias,
    onSuccess,
    onFailure,
  }),
  DOCUMENT_FETCHED: () => ({}),
  DOCUMENT_FETCH_ERROR: () => ({}),

  DOCUMENTS_TYPES_FETCHING: () => ({}),
  DOCUMENTS_TYPES_FETCHED: (documentsTypes) => documentsTypes,
  DOCUMENTS_TYPES_FETCH_ERROR: () => ({}),

  ORG_DOCUMENTS_FETCHING: (orgAlias) => ({orgAlias}),
  ORG_DOCUMENTS_FETCHED: (orgDocuments) => orgDocuments,
  ORG_DOCUMENTS_FETCH_ERROR: () => ({}),

  LINK_DOCUMENT_TO_REGISTRATION: (data) => ({...data}),
  LINK_DOCUMENT_TO_REGISTRATION_SUCCESS: result => result,
  LINK_DOCUMENT_TO_REGISTRATION_ERROR: () => ({}),

  FETCH_REGISTRATIONS_DETAILS: (orgAlias, children) => ({orgAlias, children}),
  FETCH_REGISTRATIONS_DETAILS_SUCCESS: (registrationsDetails) => registrationsDetails,
  FETCH_REGISTRATIONS_DETAILS_ERROR: () => ({}),

  FETCH_ALL_REGISTRATIONS: () => ({}),
  FETCH_ALL_REGISTRATIONS_SUCCESS: (registrationsDocuments) => registrationsDocuments,
  FETCH_ALL_REGISTRATIONS_ERROR: () => ({}),
})
