import React from 'react'
import PropTypes from 'prop-types'

const Component = ({ type, children }) => (
  <a href={`${(type === 'phone' && 'tel:') || (type === 'email' && 'mailto:') || ''}${children}`}>
    {children}
  </a>
)

Component.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.string,
}

Component.defaultProps = {
  type: '',
}

export default Component
