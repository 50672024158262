import React, {
  useState,
  useEffect,
} from 'react'
import {
  Avatar,
} from 'antd'
import { 
  UserOutlined,
} from '@ant-design/icons'

const ProfilAvatar = ({ file }) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState('')

  useEffect(() => {
    if(file) {
      
      let reader = new FileReader()
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }, [file])

  if (imagePreviewUrl) {
    return (
      <Avatar
        src={imagePreviewUrl}
      />
    )
  }
  return (
    <Avatar
      icon={<UserOutlined />}
    />
  )
}

export default ProfilAvatar
