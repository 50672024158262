import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import AddCard from "~/components/common/AddCard"
import Container from "~/components/common/Container"
import Loading from '~/components/common/Loading'
import OrgCard from "~/components/common/OrgCard"
import Text from "~/components/common/Text"
import {
  fetchChildRegisterations
} from "~/containers/Children/actions"
import mainAxios from "~/lib/axios"
import { SameHeightList, Title } from "../components"

const ChildRegistration = ({ isFetchingChildDetails, child }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [organisationsDetails, setOrganisationsDetails] = useState([])

  // 
  const fetchRegisterations = useCallback(async () => {
    // 
    dispatch(fetchChildRegisterations())
  }, [])

  const { photoUrl, fetchingPhoto, registrations } = child || {}

  // const {
  //   organisations,
  // } = useSelector((state) => state.registration.organisation);

  const getOrganisationsInfos = useCallback(async () => {
    
    if (registrations?.length > 0) {
      registrations.forEach(async reg => {
        const res = await mainAxios.get(
          `/organisations/${reg?.organisationAlias}/`
        )
        setOrganisationsDetails(state => [...state, res.data])
        return
      })
    }
  }, [registrations?.length])

  useEffect(() => {
    
    // dispatch(userOrganisationsFetching());
    getOrganisationsInfos()

    return () => {
      setOrganisationsDetails([])
    }
  }, [registrations?.length])

  useEffect(() => {
    if (!isFetchingChildDetails) {
      
      fetchRegisterations()
    }
  }, [isFetchingChildDetails])

  if (isFetchingChildDetails) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  return (
    <Container>
      <Title>
        Inscription <Text>({organisationsDetails?.length ?? 0})</Text>
      </Title>
      <SameHeightList>
        {organisationsDetails.map((reg) => (
          <OrgCard {...reg}></OrgCard>
        ))}
        <div
          onClick={() => {
            
            navigate("/registration/")
          }}
          style={{
            width: 266,
            marginBottom: 23,
          }}
        >
          <AddCard title="Inscrire à une organisation"></AddCard>
        </div>
      </SameHeightList>
    </Container>
  )
}

export default ChildRegistration
