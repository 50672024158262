import React, { useEffect } from "react";
import styled from "styled-components";
import Space from "~/components/common/Space";
import { DocumentTypes } from "~/constants/index";
import RequiredInformation from "~/utils/requiredInformation";
import { PhoneFilled, MailFilled } from "@ant-design/icons";
import { Button } from "antd";
import {
  documentsTypesFetching,
  orgDocumentsFetching,
} from "~/containers/Registration/actions";
import { useDispatch, useSelector } from "react-redux";

const Step1 = ({ organisation, setCurrentStep }) => {
  const dispatch = useDispatch();
  const { data: documentsTypes } = useSelector(
    (state) => state.registration.documentsTypes
  );
  // const {
  //   data: orgDocuments,
  // } = useSelector((state) => state.registration.orgDocuments);

  useEffect(() => {
    dispatch(documentsTypesFetching());
    dispatch(orgDocumentsFetching(organisation?.alias));
  }, []);

  const normalOrgDocuments = organisation?.documents_organisation?.filter(
    (doc) => doc?.status === 0
  );

  return (
    <Padding>
      <BTitle>Inscription aux différents services</BTitle>
      <MTitle>{organisation.name}</MTitle>
      <Description>
        Pour pouvoir inscrire des personnes aux services, nous avons besoin de
        recueillir des informations les concernant.
      </Description>
      {normalOrgDocuments?.length > 0 && (
        <>
          <STitle>Documents mis à disposition:</STitle>
          {normalOrgDocuments?.map((doc) => (
            <Required>
              <a href={doc?.link} target="_blank">
                * {doc?.name} - Télécharge le document
              </a>
            </Required>
          ))}
        </>
      )}
      <STitle>Les documents demandées :</STitle>
      {organisation?.required_documents_details.map((document) => {
        return (
          <Required>
            *{" "}
            {`${document?.description}${
              document?.number === 6 ? " (Si applicable)" : ""
            }`}
          </Required>
        );
      })}
      <STitle>Les informations demandées :</STitle>
      {organisation?.required_information.map((required) => {
        return <Required>* {RequiredInformation?.[required]?.label}</Required>;
      })}
      <STitle>Les contacts :</STitle>
      <Required>
        <IconContainer>
          <PhoneFilled style={{ color: "#2f4580", opacity: 1 }} rotate={90} />
        </IconContainer>
        {organisation.phone ?? "Inconnu"}
      </Required>
      <Required>
        <IconContainer>@</IconContainer>
        {organisation.email ?? "Inconnu"}
      </Required>
      <Required>
        <IconContainer>
          <MailFilled />
        </IconContainer>
        <span>{organisation.name}</span>
        <div style={{ marginLeft: 25 }}>{organisation.address}</div>
        <div style={{ marginLeft: 25 }}>
          {organisation.zip_code} {organisation.city}
        </div>
      </Required>
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <Button
          style={{ selfAlign: "center", width: 266, height: 40 }}
          size="large"
          type="primary"
          onClick={() => {
            setCurrentStep(2);
            // dispatch(fetchChildrenRegistrations())
          }}
        >
          Etape suivante
        </Button>
      </div>
    </Padding>
  );
};

const IconContainer = styled.div`
  font-size: 20px;
  color: #2f4580;
  opacity: 1;
  display: inline;
  padding-right: 5px;
`;

const BTitle = styled.div`
  font-size:20px;
  line-height 28px;
  font-weight: 700;
  color: #2F4580;
  width: 100%;
  text-align: center;
`;
const MTitle = styled.div`
  font-size:20px;
  line-height 28px;
  color: #2F4580;
  width: 100%;
  text-align: center;
`;

const STitle = styled.div`
  font-size:16px;
  line-height 24px;
  color: #2F4580;
  width: 100%;
  opacity: 0.5;
  font-weight: bold;
  margin-top: 24px;
`;

const Description = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2f4580;
  opacity: 0.5;
  margin-top: 32px;
`;

const Padding = styled.div`
  padding-right: 10%;
  padding-left: 10%;
  margin: 0 auto;
  max-width: 1000px;
`;

const Required = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: #2f458080;

  mix-blend-mode: normal;

  > a {
    color: #1890ff;
  }
`;

export default Step1;
