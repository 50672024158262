import React from "react"
import PropTypes from "prop-types"
import { Avatar } from "antd"

import cantineSrc from "~/assets/images/cantine.svg"
import mantinSrc from "~/assets/images/matin.svg"
import garderieSrc from "~/assets/images/garderie.svg"
import etudeSrc from "~/assets/images/etude.svg"
import stageSrc from "~/assets/images/stage.svg"
import centreSrc from "~/assets/images/centre.svg"
import ramassageSrc from "~/assets/images/ramassage.svg"
import mairieSrc from "~/assets/images/mairie.svg"
import schoolSrc from "~/assets/images/school.svg"
import enfantsSrc from "~/assets/activities/enfants.svg"
import jeunesseSrc from "~/assets/activities/jeunesse.svg"
/**
 * <ActivityIcon />
 * @param {object} props
 */
const ActivityIcon = ({
  activityType,
  size,
}) => {
  const ACTIVITY_TYPES = [
    {
      type: 0,
      label: "cantine",
      src: cantineSrc,
    },
    {
      type: 1,
      label: "accueil du matin",
      src: mantinSrc,
    },
    {
      type: 2,
      label: "garderie",
      src: garderieSrc,
    },
    {
      type: 3,
      label: "etude",
      src: etudeSrc,
    },
    {
      type: 4,
      label: "stage",
      src: stageSrc,
    },
    {
      type: 5,
      label: "centre de loisirs",
      src: centreSrc,
    },
    {
      type: 6,
      label: "ramassage scolaire",
      src: ramassageSrc,
    },
    {
      type: 7,
      label: "centre jeunesse",
      src: jeunesseSrc,
    },
    {
      type: 98,
      label: "mairie",
      src: mairieSrc,
    },
    {
      type: 99,
      label: "école",
      src: schoolSrc,
    },
    {
      type: 33,
      label: "enfants",
      src: enfantsSrc,
    },
  ]

  const getActivityIcon = () => {
    const activity = ACTIVITY_TYPES.find(
      act => act.type === activityType,
    )
    return activity?.src
  }

  return (
    <Avatar
      src={getActivityIcon()}
      size={size}
    />
  )
}

ActivityIcon.propTypes = {
  activityType: PropTypes.number,
  size: PropTypes.number,
  // style: PropTypes.shape(),
  // width: PropTypes.number,
  // height: PropTypes.number,
}

ActivityIcon.defaultProps = {
  activityType: null,
  size: 50,
  // style: null,
  // width: null,
  // height: null,
}

export default ActivityIcon
