import { CheckCircleFilled } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, message, Row, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Centered from "~/components/common/Centered";
import Child from "~/components/common/Child";
import Container from "~/components/common/Container";
import {
  activitiesFetching,
  activityDetailsFetching,
  activitySlotsFetching,
  activityAllSlotsFetching,
  subscriptionCreating,
} from "~/containers/Subscription/actions";
import ActivityChildCalendar from "./ActivityChildCalendar";
import {
  fetchChildrenRegistrations,
  fetchSelectedChildrenDetails,
} from "~/containers/Children/actions";
import dayjs from "dayjs";
import { useApolloClient } from "@apollo/client";
import { BILLS, MY_FEEDS, ORDERS, ORGANISATION_PARENT_AVAILABLE_ACTIVITIES } from "~/graphql/queries";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter)

const childSelected = (ch, chosenChildAlias) => {
  return chosenChildAlias.find((child) => child === ch.alias);
};

export const TopStep3 = ({
  children,
  activityDetails,
  chosenChildAlias,
  setChosenChildAlias,
  childrenRegistrations,
  organisation,
  setChosenActivity,
  setCurrentStep,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [subscriptionSuccessful, setSubscriptionSuccessful] = useState(false);
  const {
    // children,
    isFetching: isFetchingChildren,
  } = useSelector((state) => state.children);

  const apolloClient = useApolloClient();

  const { isCreatingSubscription } = useSelector((state) => state.subscription);

  console.log({ isCreatingSubscription });
  const onChooseChild = (ch) => {
    if (chosenChildAlias.find((child) => child === ch.alias)) {
      setChosenChildAlias(
        chosenChildAlias.filter((alias) => alias != ch.alias)
      );
    } else {
      setChosenChildAlias([...chosenChildAlias, ch.alias]);
    }
  };

  let Options = activityDetails.activities.map((activity, i) => (
    <Select.Option value={activity?.alias}>{activity?.building?.name}</Select.Option>
  ));

  useEffect(() => {
    if (params?.childAlias && activityDetails) {
      const selectedChild = children.find((child) => {
        const isAlreadySubscribed = !!child?.subscriptions?.find(
          (sub) => sub.parent_activity_alias === activityDetails?.alias
        );
        const registrationIsNotValidated = !!childrenRegistrations?.[
          child?.alias
        ]?.find(
          (registration) =>
            registration?.organisationAlias === activityDetails?.organisation?.alias &&
            registration?.status !== 1
        );
        const registrationIsOnRenewalForNextPeriod =
          !!childrenRegistrations?.[child?.alias]?.find(
            (registration) =>
              registration?.organisationAlias ===
                activityDetails?.organisation?.alias &&
              [0, 1, 2].includes(registration?.renewal_status)
          ) &&
          dayjs(activityDetails?.startDate).isSameOrAfter(
            dayjs(`${dayjs().year()}-09-01`)
          );
        const isRegisteredToOrg = !!childrenRegistrations?.[child?.alias]?.find(
          (registration) =>
            registration?.organisationAlias === activityDetails?.organisation?.alias
        );

        if (
          child.alias === params.childAlias &&
          isRegisteredToOrg &&
          !isAlreadySubscribed &&
          !registrationIsNotValidated &&
          !registrationIsOnRenewalForNextPeriod
        ) {
          return child;
        }
      });

      onChooseChild(selectedChild || []);

      // dispatch(activityDetailsFetching(activityDetails.organisation, activityDetails.alias));
    }
  }, [params?.childAlias, children, activityDetails]);

  const submitSubscription = (values) => {
    if (isChecked) {
      chosenChildAlias.forEach((childAlias, index) => {
        const data = {
          activityAlias: activityDetails?.alias,
        };
        if (activityDetails?.buildingChoice?.key === 1) {
          data.activityBuildingAlias = [values?.[`activityBuildingAlias__${childAlias}`]];
        }

        if (activityDetails?.buildingChoice?.key === 0) {
          data.status = 0;
        }

        dispatch(
          subscriptionCreating(
            childAlias,
            {
              ...data,
            },
            (resp) => {
              if (resp) {
                message.success(
                  "L'inscription à l'activité est crée avec succès!"
                );
                setSubscriptionSuccessful(true);
              } else {
                message.error("Une erreur inattendue s'est produite!");
              }

              // if (index === chosenChildAlias.length - 1 && resp) {
              //   if (activityDetails?.building_choice === 0) {
              //     history.push(`/`)
              //   } else {
              //     history.push(`/allreservation`)
              //   }
              // }
            }
          )
        );
      });
    }
  };

  const validation = () => {
    if (isChecked) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("Svp acceptez la communication de ces informations!")
    );
  };

  const refetchQueries = async () => {
    await apolloClient.refetchQueries({
      include: [ORGANISATION_PARENT_AVAILABLE_ACTIVITIES, MY_FEEDS, BILLS, ORDERS],
    });
  };

  if (subscriptionSuccessful) {
    return (
      <Padding>
        <Space
          direction="vertical"
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
          size={40}
        >
          <CheckCircleFilled style={{ fontSize: 70, color: "#20CD8D" }} />
          <div>
            <Bold>
              L’inscription de{" "}
              {chosenChildAlias?.length === 0 ? "votre enfant" : "vos enfants"}{" "}
              à l’activité {activityDetails?.name} de l’organisation{" "}
              {organisation?.name} est bien prise en compte !
            </Bold>
            <Bold style={{ color: "#FF5876"}}>
              N’oubliez pas de procéder aux réservations des dates souhaités.
            </Bold>
            <Bold>
              <u>Attention</u>, dans certains cas, votre organisation doit
              valider l’inscription avant que vous ne puissiez réserver.
            </Bold>
          </div>
          <Space
            style={{
              justifyContent: "center",
            }}
          >
            <Button
              style={{ selfAlign: "center", width: 240, height: 40 }}
              size="large"
              type="primary"
              onClick={() => {
                refetchQueries();
                setChosenChildAlias([]);
                setSubscriptionSuccessful(false);
                setCurrentStep(1);
                setChosenActivity(null);
              }}
            >
              Inscrire à une autre activité
            </Button>
            <Button
              style={{ selfAlign: "center", width: 240, height: 40 }}
              size="large"
              type="primary"
              onClick={() => {
                refetchQueries();
                navigate("/allreservation");
              }}
            >
              Réserver
            </Button>
          </Space>
        </Space>
      </Padding>
    );
  }

  return (
    <div>
      <SelectChildTitle>Qui souhaitez-vous inscrire ?</SelectChildTitle>
      {/* <Row gutter={[24, 24]}> */}
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {children.map((ch) => {
          const isAlreadyRegistered = !!ch.subscriptions?.find(
            (sub) => sub?.parent_activity_alias === activityDetails?.alias
          );
          const isRegisteredToOrg = !!childrenRegistrations?.[ch?.alias]?.find(
            (registration) =>
              registration?.organisationAlias === activityDetails?.organisation?.alias
          );
          const registrationIsNotValidated = !!childrenRegistrations?.[
            ch?.alias
          ]?.find(
            (registration) =>
              registration?.organisationAlias ===
                activityDetails?.organisation?.alias && registration?.status !== 1
          );
          const registrationIsOnRenewalForNextPeriod =
            !!childrenRegistrations?.[ch?.alias]?.find(
              (registration) =>
                registration?.organisationAlias ===
                  activityDetails?.organisation?.alias &&
                [0, 1, 2].includes(registration?.renewal_status)
            ) &&
            dayjs(activityDetails?.start_date, "YYYY-MM-DD").isSameOrAfter(
              dayjs(`${dayjs().year()}-09-01`, "YYYY-MM-DD")
            );

          console.log({
            ch,
            registrationIsOnRenewalForNextPeriod,
            activityDetails,
            dd: dayjs(activityDetails?.start_date, "YYYY-MM-DD").isSameOrAfter(
              dayjs(`${dayjs().year()}-09-01`, "YYYY-MM-DD")
            ),
            childrenRegistrations,
            lll: childrenRegistrations?.[ch?.alias]?.find(
              (registration) =>
                registration?.organisationAlias ===
                activityDetails?.organisation?.alias
            ),
          });

          return (
            <ChildContainer
              onClick={() => {
                if (
                  !isAlreadyRegistered &&
                  isRegisteredToOrg &&
                  !registrationIsNotValidated &&
                  !registrationIsOnRenewalForNextPeriod
                ) {
                  onChooseChild(ch);
                }
              }}
            >
              <Child
                child={ch}
                selected={childSelected(ch, chosenChildAlias)}
                organisationName={organisation?.name}
                isAlreadyRegistered={isAlreadyRegistered}
                isNotRegisteredToOrg={!isRegisteredToOrg}
                registrationIsNotValidated={registrationIsNotValidated}
                registrationIsOnRenewalForNextPeriod={
                  registrationIsOnRenewalForNextPeriod
                }
              />
            </ChildContainer>
          );
        })}
      </div>
      {/* </Row> */}
      <Form form={form} onFinish={submitSubscription}>
        {activityDetails?.buildingChoice?.key === 1 && (
          <Row>
            <Col span={8}>
              {children.map((ch) => {
                if (childSelected(ch, chosenChildAlias)) {
                  return (
                    <div>
                      <Form.Item
                        style={{ display: "block" }}
                        label={`Choix du lieu pour ${ch.first_name}`}
                        name={`activityBuildingAlias__${ch?.alias}`}
                        rules={[
                          {
                            required: true,
                            message: "Selectionner svp!",
                          },
                        ]}
                      >
                        <Select placeholder="Selectionner">{Options}</Select>
                      </Form.Item>
                    </div>
                  );
                }
              })}
            </Col>
          </Row>
        )}
        {chosenChildAlias.length > 0 && (
          <>
            <Centered
              style={{
                marginTop: 40,
              }}
            >
              <Form.Item
                name="agreement"
                rules={[
                  {
                    required: true,
                    validator: validation,
                  },
                ]}
              >
                <Checkbox onChange={(e) => setIsChecked(e.target.checked)}>
                  J’accepte de communiquer ces informations à{" "}
                  {activityDetails?.organisation?.name}
                </Checkbox>
              </Form.Item>
            </Centered>
            <Centered>
              <Button
                type="primary"
                size="large"
                style={{ minWidth: 220 }}
                htmlType="submit"
                loading={isCreatingSubscription}
                disabled={isCreatingSubscription}
                // onClick={() => setShowDrawer(true)}
              >
                Finaliser l’inscription
              </Button>
            </Centered>
          </>
        )}
      </Form>
    </div>
  );
};

export const BotStep3 = ({ children, activity, chosenChildAlias }) => {
  const [chosenDays, setChosenDays] = useState({});
  const dispatch = useDispatch();
  const {
    list: activitiesList,
    loading: isFetchingActivities,
    details: activityDetails,
    slots,
  } = useSelector((state) => state.subscription.activity);

  // useEffect(() => {
  //
  //   dispatch(activityDetailsFetching(activity?.organisation, activity?.alias));
  // }, [activity]);

  return (
    <Container>
      <SelectChildTitle>Choisissez vos réservations</SelectChildTitle>

      {activity && (
        <>
          <ActivityChildCalendar
            activity={activity}
            children={children.filter((ch) =>
              childSelected(ch, chosenChildAlias)
            )}
            chosenChildAlias={chosenChildAlias}
          ></ActivityChildCalendar>
          {/* <ActivityCalendar
            activity={activity}
            onChoose={(data) => setChosenDays(data)}
          /> */}
        </>
      )}
    </Container>
  );
};

export const SelectChildTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #2f4580;
  margin-bottom: 30px;
`;

export const ChildContainer = styled.div`
  cursor: pointer;
`;

const Bold = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: @neutral-5;
  padding-bottom: 24px;
  text-align: center;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: @neutral-4;
`;

const Padding = styled.div`
  padding-right: 10%;
  padding-left: 10%;
  margin: 0 auto;
  max-width: 1000px;
`;
