
import styled from "styled-components"
import { Modal } from "antd"

import {
  CloseCircleOutlined,
  FileImageOutlined,
  RightOutlined,
} from '@ant-design/icons'
import Markdown from "markdown-to-jsx"

export const CustomModal = styled(Modal)`
  border-radius : 8px;
  overflow: hidden ;
`

export const MessageTitle = styled.div`
  color:#2F4580;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  padding-bottom:20px;
`

export const PostType = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  padding-bottom:4px;

  letter-spacing: 0.05em;
  text-transform: uppercase;

  /* Untitled/Color 3 */

  color: #6D7F9D;
`

export const Circle = styled.div`
  bakground: ${props => props.borderColor};
  width: 16px;
  height: 16px;
  border: 5px solid grey;
  border-radius:50%;
`

export const PostedBy = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;

  /* Neutral / 4 */

  color: #646464;
`

export const Attachements = styled.div`
`

export const Content = styled.div`
  padding:20px;
`

export const StyledMarkdown = styled(Markdown)`
  img {
    max-width: 100%;
    height: auto;
  }
`
