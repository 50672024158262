
import { handleActions } from "~/utils/redux-actions";

import {
  fetchChildrenProcess,
  fetchChildrenSuccess,
  fetchChildrenError,

  // child details
  fetchChildDetailsError,
  fetchChildDetailsSuccess,
  fetchChildDetailsProcess,

  // Selected Children details
  fetchSelectedChildrenDetailsProcess,
  fetchSelectedChildrenDetailsSuccess,
  fetchSelectedChildrenDetailsError,

  // Children registrations
  fetchChildrenRegistrationsProcess,
  fetchChildrenRegistrationsSuccess,
  fetchChildrenRegistrationsError,

  // fetch child photo
  fetchChildPhotoSuccess,
  fetchChildPhotoProcess,
  fetchChildPhotoError,

  // child Registeration
  fetchChildRegisterationsSuccess,
  // children attendances
  // fetch child attendence
  fetchChildAttendancesProcess,
  fetchChildAttendancesSuccess,
  fetchChildAttendancesError,

  // create attendance
  createAttendanceProcess,
  createAttendanceError,
  createAttendanceSuccess,

  // create attendance
  createMultipleAttendancesProcess,
  createMultipleAttendancesError,
  createMultipleAttendancesSuccess,

  // update attendance
  patchAttendanceSuccess,
  patchAttendanceError,
  patchAttendanceProcess,

  // attencdance details
  fetchAttendancesDetailsProcess,
  fetchAttendancesDetailsSuccess,
  fetchAttendancesDetailsError,

  // fetch Opening days
  fetchOpeningdaysError,
  fetchOpeningdaysSuccess,
  fetchOpeningdaysProcess,

  // fetch opening days
  fetchAllOpeningdays,
  fetchAllOpeningdaysProcess,
  fetchAllOpeningdaysError,
  fetchAllOpeningdaysSuccess,

  // fetch planning garde
  fetchChildrengardeplanningdays,
  fetchChildrengardeplanningdaysProcess,
  fetchChildrengardeplanningdaysError,
  fetchChildrengardeplanningdaysSuccess,

  // children attendences
  allChildrenAttendancesFetching,
  allChildrenAttendancesFetched,
  allChildrenAttendancesFetchError,

  // Update child profile
  childProfileUpdating,
  childProfileUpdated,
  childProfileUpdateError,

  // Creating child
  childCreating,
  childCreated,
  childCreateError,

  // updating child picture
  childPhotoUpdating,
  childPhotoUpdated,
  childPhotoUpdateError,

  // fetch user document
  fetchDocumentPending,
  fetchDocumentError,
  fetchDocumentSuccess,

  // create recurrent attendance
  createRecurrentAttendanceProcess,
  createRecurrentAttendanceError,
  createRecurrentAttendanceSuccess,

  // create attendance by container
  createAttendanceByContainerProcess,
  createAttendanceByContainerError,
  createAttendanceByContainerSuccess,

  // request credit
  requestCreditProcess,
  requestCreditError,
  requestCreditSuccess,

  // cancel credit request
  cancelCreditRequestProcess,
  cancelCreditRequestError,
  cancelCreditRequestSuccess,
} from "./actions";

import { logout } from "~/containers/User/actions";

const defaultState = {
  children: [],
  isFetching: false,
  fetchingError: false,
  updatingError: false,
  isUpdating: false,
  creatingError: false,
  isCreating: false,
  isFetchingChildDetails: false,
  childDetails: {
    alias: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: null,
    has_pai: false,
    classtype: null,
    allergy: null,
    photo: null, // protected
    photoUrl: null, // public,
    documentUrl: null,
    school: null,
    relationships: null,
    subscriptions: null,
    registrations: null,
    fetchingPhoto: false,
    fetchingPhotoError: false,
  },
  selectedChildrenDetails: [],
  childrenRegistrations: {},
  fetchingChildDetailsError: false,
  childrengardeplanningdays: [],
  fetchingChildrengardeplanningdays: false,
  //childrengardeplanningdaysError: false,
  attendance: {
    alias:"",
    error: false,
    isFetching: false,
    isPatching: false,
    isCreating: false,
    allChildrenAttendances: [],
    childAttendances: [],
    allOpeningDays: [],
    openingDays: [],
    fetchingOpeningDays: false,
    allfetchingOpeningDays: false,
    openingDaysError: false,
    url:null,
    selectedAttendance: null,
    isRequestingCredit: false,
  },
};

export default handleActions(
  {
    // Logout
    [logout]: () => defaultState,

    [fetchChildrenProcess]: (state) => {
      state.isFetching = true;
      state.fetchingError = false;
    },
    [fetchChildrenSuccess]: (state, { payload }) => {
      state.isFetching = false;
      state.fetchingError = false;
      state.children = [...payload];
    },
    [fetchChildrenError]: (state) => {
      state.isFetching = false;
      state.fetchingError = true;
    },

    [fetchSelectedChildrenDetailsProcess]: (state) => {
      state.isFetching = true;
      state.fetchingError = false;
    },
    [fetchSelectedChildrenDetailsSuccess]: (state, { payload }) => {
      state.isFetching = false;
      state.fetchingError = false;
      state.selectedChildrenDetails = [...payload];
    },
    [fetchSelectedChildrenDetailsError]: (state) => {
      state.isFetching = false;
      state.fetchingError = true;
    },
    
    [fetchChildrenRegistrationsProcess]: (state) => {
      state.isFetching = true;
      state.fetchingError = false;
    },
    [fetchChildrenRegistrationsSuccess]: (state, { payload }) => {
      
      state.isFetching = false;
      state.fetchingError = false;
      state.childrenRegistrations = payload;
    },
    [fetchChildrenRegistrationsError]: (state) => {
      state.isFetching = false;
      state.fetchingError = true;
    },

    // Fetching child attendances
    [allChildrenAttendancesFetching]: (state) => {
      state.attendance.isFetching = true;
      state.attendance.error = false;
    },

    [allChildrenAttendancesFetched]: (state, { payload }) => {
      state.attendance.allChildrenAttendances = [...payload];
      state.attendance.isFetching = false;
      state.attendance.error = false;
    },
    [allChildrenAttendancesFetchError]: (state) => {
      // state.attendance.allChildrenAttendances = [];
      state.attendance.isFetching = false;
      state.attendance.error = true;
    },

    // child Details
    [fetchChildDetailsProcess]: (state) => {
      state.isFetchingChildDetails = true;
      state.fetchChildDetailsError = false;
    },
    [fetchChildDetailsSuccess]: (state, { payload }) => {
      state.isFetchingChildDetails = false;
      state.childDetails = {
        ...state.childDetails,
        ...payload,
      };
      state.fetchChildDetailsError = false;
    },
    [fetchChildDetailsError]: (state) => {
      state.isFetchingChildDetails = false;
      state.fetchChildDetailsError = true;
    },
    [fetchChildRegisterationsSuccess]: (state, { payload }) => {
      state.childDetails.registrations = payload;
    },

    [fetchChildPhotoProcess]: (state) => {
      state.childDetails.fetchingPhotoError = false;
      state.childDetails.fetchingPhoto = true;
    },

    [fetchChildPhotoError]: (state) => {
      state.childDetails.fetchingPhotoError = true;
      state.childDetails.fetchingPhoto = false;
    },

    [fetchChildPhotoSuccess]: (state, { payload }) => {
      state.childDetails.photoUrl = payload;
      state.childDetails.fetchingPhotoError = false;
      state.childDetails.fetchingPhoto = false;
    },

    // Update child profile
    [childProfileUpdating]: (state) => {
      state.isUpdating = true;
      state.updatingError = false;
    },
    [childProfileUpdated]: (state, { payload }) => {
      state.childDetails = { ...state.childDetails, ...payload };
      state.isUpdating = false;
      state.updatingError = false;
    },

    [childProfileUpdateError]: (state) => {
      state.isUpdating = false;
      state.updatingError = true;
      // fetch child attendence
    },
    [fetchChildAttendancesProcess]: (state) => {
      state.attendance.isFetching = true;
      state.attendance.error = false;
    },
    [fetchChildAttendancesSuccess]: (state, { payload }) => {
      state.attendance.childAttendances = [...payload];
      state.attendance.isFetching = false;
      state.attendance.error = false;
    },
    [fetchChildAttendancesError]: (state) => {
      state.attendance.childAttendances = [];
      state.attendance.isFetching = false;
      state.attendance.error = true;
    },

    // create child attendances
    [createAttendanceProcess]: (state) => {
      state.attendance.isCreating = true;
      state.attendance.error = false;
    },
    [createAttendanceSuccess]: (state, { payload }) => {
      state.attendance.isCreating = false;
      state.attendance.error = false;
      state.attendance.childAttendances = [...payload];
    },
    [createAttendanceError]: (state) => {
      state.attendance.isCreating = false;
      state.attendance.error = true;
    },

    // create multiple attendances
    [createMultipleAttendancesProcess]: (state) => {
      state.attendance.isCreating = true;
      state.attendance.error = false;
    },
    [createMultipleAttendancesSuccess]: (state, { payload }) => {
      state.attendance.isCreating = false;
      state.attendance.error = false;
      state.attendance.childAttendances = [...payload];
    },
    [createMultipleAttendancesError]: (state) => {
      state.attendance.isCreating = false;
      state.attendance.error = true;
    },

    // update child attendances
    [patchAttendanceProcess]: (state) => {
      state.attendance.isPatching = true;
      state.attendance.error = false;
    },
    [patchAttendanceSuccess]: (state, { payload }) => {
      state.attendance.isPatching = false;
      state.attendance.error = false;
      state.attendance.childAttendances = [...payload];
    },
    [patchAttendanceError]: (state) => {
      state.attendance.isPatching = false;
      state.attendance.error = true;
    },

    [fetchOpeningdaysProcess]: (state) => {
      state.attendance.fetchingOpeningDays = true;
      state.attendance.openingDaysError = false;
    },
    [fetchOpeningdaysError]: (state) => {
      state.attendance.fetchingOpeningDays = false;
      state.attendance.openingDaysError = true;
    },
    [fetchOpeningdaysSuccess]: (state, { payload }) => {
      state.attendance.fetchingOpeningDays = false;
      state.attendance.openingDaysError = false;
      state.attendance.openingDays = [...payload];
    },

    [fetchChildrengardeplanningdaysProcess]: (state) => {
      state.fetchingChildrengardeplanningdays = true
      state.childrengardeplanningdaysError = false
    },
    [fetchChildrengardeplanningdaysError]: (state) => {
      state.fetchingChildrengardeplanningdays = false
      state.childrengardeplanningdaysError = true
    },
    [fetchChildrengardeplanningdaysSuccess]: (state, { payload }) => {
      state.fetchingChildrengardeplanningdays = false
      state.childrengardeplanningdaysError = false
      state.childrengardeplanningdays = payload
    },

    [fetchAllOpeningdaysProcess]: (state) => {
      state.attendance.allfetchingOpeningDays = true;
      state.attendance.allopeningDaysError = false;
    },
    [fetchAllOpeningdaysError]: (state) => {
      state.attendance.allfetchingOpeningDays = false;
      state.attendance.allopeningDaysError = true;
    },
    [fetchAllOpeningdaysSuccess]: (state, { payload }) => {
      state.attendance.allfetchingOpeningDays = false;
      state.attendance.allopeningDaysError = false;
      state.attendance.allOpeningDays = [...payload];
    },

    // fetch attendance details
    [fetchAttendancesDetailsProcess]: (state) => {
      state.attendance.isFetching = true;
      state.attendance.error = false;
    },
    [fetchAttendancesDetailsSuccess]: (state, { payload }) => {
      state.attendance.isFetching = false;
      state.attendance.error = false;
      state.attendance.childAttendances = [...payload];
    },
    [fetchAttendancesDetailsError]: (state) => {
      state.attendance.isFetching = false;
      state.attendance.error = true;
    },

    // // Fetching childern attendances
    // [allChildrenAttendancesFetching]: (state) => {
    //   state.attendance.isFetching = true;
    //   state.attendance.error = false;
    // },
    // [allChildrenAttendancesFetched]: (state, { payload }) => {
    //   state.attendance.allChildrenAttendances = [...payload];
    //   state.attendance.isFetching = false;
    //   state.attendance.error = false;
    // },
    // [allChildrenAttendancesFetchError]: (state) => {
    //   // state.attendance.allChildrenAttendances = [];
    //   state.attendance.isFetching = false;
    //   state.attendance.error = true;
    // },

    // Creating child
    [childCreating]: (state) => {
      state.isCreating = true;
      state.creatingError = false;
    },
    [childCreated]: (state) => {
      state.isCreating = false;
      state.creatingError = false;
    },
    [childCreateError]: (state) => {
      state.isCreating = false;
      state.creatingError = true;
    },

    // Updating child photo
    [childPhotoUpdating]: (state) => {
      state.isUpdating = true;
      state.updatingError = false;
    },
    [childPhotoUpdated]: (state) => {
      state.isUpdating = false;
      state.updatingError = false;
    },
    [childPhotoUpdateError]: (state) => {
      state.isUpdating = false;
      state.updatingError = true;
    },

    // fetch user document
    [fetchDocumentSuccess]: (state, { payload }) => {
      state.childDetails.documentUrl = payload;
      state.childDetails.fetchDocumentPending = false;
      state.childDetails.fetchDocumentError = false;
      state.childDetails.fetchDocumentSuccess = true;
    },
    [fetchDocumentError]: (state) => {
      state.childDetails.documentUrl = null;
      state.childDetails.fetchDocumentPending = false;
      state.childDetails.fetchDocumentError = true;
      state.childDetails.fetchDocumentSuccess = false;
    },
    [fetchDocumentPending]: (state) => {
      state.childDetails.documentUrl = null;
      state.childDetails.fetchDocumentPending = true;
      state.childDetails.fetchDocumentError = false;
      state.childDetails.fetchDocumentSuccess = false;
    },

    // create reccurent attendances
    [createRecurrentAttendanceProcess]: (state) => {
      state.attendance.isCreating = true;
      state.attendance.error = false;
    },
    [createRecurrentAttendanceSuccess]: (state, { payload }) => {
      // state.attendance.isCreating = false
      // state.attendance.error = false
      console.log({ payload }, '**--**--**--**');
      // let newAttendances = state?.attendances?.filter(
      //   (attendance) => !payload?.deleted_attendances?.includes(attendance?.alias),
      // )

      // newAttendances = [...newAttendances, ...payload.attendances]

      let newAllAttendances = state?.attendance?.allChildrenAttendances?.filter(
        (attendance) => !payload?.deleted_attendances?.includes(attendance?.alias),
      );

      console.log({newAllAttendances, });

      newAllAttendances = [...newAllAttendances, ...payload.attendances];

      return {
        ...state,
        attendance: {
          ...state.attendance,
          isCreating: false,
          error: false,
          // attendances: newAttendances,
          allChildrenAttendances: newAllAttendances,
        }
      };
    },
    [createRecurrentAttendanceError]: (state) => {
      state.attendance.isCreating = false;
      state.attendance.error = true;
    },
    // create attendance by container
    [createAttendanceByContainerProcess]: (state) => {
        state.attendance.isCreating = true;
        state.attendance.error = false;
    },
    [createAttendanceByContainerSuccess]: (state, { payload }) => {
        // state.attendance.isCreating = false
        // state.attendance.error = false
        console.log({ payload }, '**--**--**--**');
        // let newAttendances = state?.attendances?.filter(
        //   (attendance) => !payload?.deleted_attendances?.includes(attendance?.alias),
        // )

        // newAttendances = [...newAttendances, ...payload.attendances]

        let newAllAttendances = state?.attendance?.allChildrenAttendances?.filter(
                (attendance) => !payload?.deleted_attendances?.includes(attendance?.alias),
                );

        console.log({newAllAttendances, });

        newAllAttendances = [...newAllAttendances, ...payload.attendances];

        return {
            ...state,
                attendance: {
                    ...state.attendance,
                    isCreating: false,
                    error: false,
                    // attendances: newAttendances,
                    allChildrenAttendances: newAllAttendances,
                }
        };
    },
    [createAttendanceByContainerError]: (state) => {
        state.attendance.isCreating = false;
        state.attendance.error = true;
    },

    // request credit
    [requestCreditProcess]: (state) => {
      state.attendance.isRequestingCredit = true;
      state.attendance.error = false;
    },
    [requestCreditSuccess]: (state, { payload }) => {
      state.attendance.isRequestingCredit = false;
      state.attendance.error = false;
    },
    [requestCreditError]: (state) => {
      state.attendance.isRequestingCredit = false;
      state.attendance.error = true;
    },

    // cancel credit request
    [cancelCreditRequestProcess]: (state) => {
      state.attendance.isRequestingCredit = true;
      state.attendance.error = false;
    },

    [cancelCreditRequestSuccess]: (state, { payload }) => {
      state.attendance.isRequestingCredit = false;
      state.attendance.error = false;
    },

    [cancelCreditRequestError]: (state) => {
      state.attendance.isRequestingCredit = false;
      state.attendance.error = true;
    },
  },
  defaultState
);
