import {
  Layout as AntLayout,
} from 'antd'
import styled from 'styled-components'
import config from '~/config'

const {
  Content,
} = AntLayout

export const ContentContainer = styled(Content)`
  display: flex;
  flex-direction: column;
  background-color: #EEF1FE;
  // padding-left: 200px;
  padding-top: 68px;
  overflow: auto;
  height: ${config.app.env && config.app.env === "prod" ? "100vh" : "95vh"};
`
