import {
  CheckCircleFilled,
  FileImageOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import Drawer from "~/components/common/Drawer";
import { Row, Col, Space } from 'antd';
import { Form, Select, Input, Radio, Switch, InputNumber, Divider, Button, Upload, message, DatePicker } from "antd";
import { addNewDocument, missingCommonDocumentsFetching } from '~/containers/Foyer/actions';

import useErrors from "~/hooks/useErrors";
import Checkbox from 'antd/lib/checkbox/Checkbox';

import dayjs from "dayjs";
import { getDocumentType } from "~/constants";
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { fetchRegistrationsDetails, linkDocumentToRegistration } from '~/containers/Registration/actions';
import { useCallback } from 'react';
import { useEffect } from 'react';
import apolloClient from '~/lib/apolloClient';
import { MY_FEEDS, MY_HOUSEHOLD } from '~/graphql/queries';

const { Option } = Select;
const { TextArea } = Input;

const SelectableDocument = ({ document, setDocumentToLink, selected }) => {
  const fileName = document?.name;
  const fileSize = document?.file_size_label;

  return (
    <Checkbox
      style={{ marginBottom: 16, display: "flex" }}
      checked={selected}
      onChange={e => setDocumentToLink(e.target.checked ? document.alias : null)}
    >
      <div>
        <p>
          {document.name}
        </p>
        <a href={document?.file_url + "?show_from_mobile=true"} target="_blank">
          <LinkOutlined /> {fileName} ({fileSize})
        </a>
      </div>
    </Checkbox>
  );
};

const TransmitDoc = ({
  child,
  targetedDocument,
  showDrawer,
  setShowDrawer,
  documentsOfType,
  setAddExistingDocument,
  addExistingDocument,
  organisationAlias,
  handleCloseDrawer,
}) => {
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [noDueDate, setNoDueDate] = useState(false);
  const [documentToLink, setDocumentToLink] = useState(null);

  const { ErrorComponent, setErrors } = useErrors();
  const [form] = Form.useForm();

  const {linkingDocumentToRegistration} = useSelector(state => state.registration);
  const {isUploading} = useSelector((state) => state.household.documents);
  const {
    children,
    isFetching: isFetchingChildren,
  } = useSelector((state) => state.children);

  let initialState = {};

  console.log({targetedDocument, child, children});

  const refetchQueries = useCallback(
    async () => {
      console.log('refetchQueries')
      await apolloClient.refetchQueries({
        include: [MY_FEEDS, MY_HOUSEHOLD],
      });
    },
    [apolloClient],
  );

  const handleLinkDocument = useCallback(() => {
    console.log({ documentToLink });

    dispatch(linkDocumentToRegistration({
      document_alias: documentToLink,
      registration_alias: targetedDocument?.registrationAlias,
      callback: () => {
        message.success('Document lié avec succès');

        dispatch(
          fetchRegistrationsDetails(
            targetedDocument?.organisationAlias,
            // children?.map(child => child.alias),
          )
        );
        setFile(null);
        setIsChecked(false);
        refetchQueries();

        handleCloseDrawer();
      }
    }));
  }, [documentToLink, handleCloseDrawer]);

  const onFinish = (values) => {
    console.log({values});

    const file = values.file.file;

    if (!isChecked || !file) {
      message.error('Veuillez accepter choisir un fichier et accepter les conditions');
      return;
    }

    console.log({values, file});
    if (!['image/jpeg', 'application/pdf'].includes(file.type)) {
      message.error(`${file.name} n'est pas un fichier jpg`);
      return ['image/jpeg', 'application/pdf'].includes(file.type);
    }
    
    const formData = new FormData();
    formData.append('merge', false);
    formData.append('file', file.originFileObj);
    formData.append('name', file.name);
    formData.append('type', targetedDocument?.number);
    formData.append('shared_with_other_users', true);
    // if (!noDueDate) {
    //   formData.append('due_date', dayjs(values?.due_date).format('YYYY-MM-DD'))
    // }
    formData.append('organisation_alias', targetedDocument?.organisationAlias);
    formData.append('registration_alias', targetedDocument?.registrationAlias);
    formData.append('child_alias', targetedDocument?.childrenData?.alias);
    setFile(file.originFileObj);

    if (['image/jpeg', 'application/pdf'].includes(file.type)) {
      dispatch(
        addNewDocument({
          formData,
          childAlias: child?.alias,
          onSuccess: () => {
            dispatch(
              fetchRegistrationsDetails(
                targetedDocument?.organisationAlias,
                // children?.map(child => child.alias),
              )
            );
            refetchQueries();
            handleCloseDrawer();
          },
        })
      );
    }
  };

  const uploadProps = {
    fileList: [],
    beforeUpload: file => {
      if (!['application/pdf', 'image/jpeg'].includes(file.type)) {
        message.error(`${file.name} n'est pas un fichier jpg`);
      }
      return ['application/pdf', 'image/jpeg'].includes(file.type);
    },
    customRequest: async (e) => {
      setFile(e.file);
    }
  };

  useEffect(() => {
    if (documentsOfType?.length === 0 || !documentsOfType) {
      setAddExistingDocument(false);
    } else {
      setAddExistingDocument(true);
    }
  }, [documentsOfType]);

  console.log({addExistingDocument, documentsOfType, targetedDocument, documentToLink});

  return (
    <Drawer
      open={showDrawer}
      handleCloseDrawer={handleCloseDrawer}
      width={636}
      title="Transmettez un document"
      destroyOnClose
    >
      {addExistingDocument ? (
        <div>
          {documentsOfType?.map(document => (
            <SelectableDocument
              key={document.alias}
              document={document}
              setDocumentToLink={setDocumentToLink}
              selected={documentToLink === document.alias}
            />
          ))}
          <Space
            direction="vertical"
            style={{ width: "100%", marginTop: 32 }}
          >
            <Button
              type="primary"
              onClick={handleLinkDocument}
              block
              size="large"
              disabled={!documentToLink}
              loading={linkingDocumentToRegistration}
            >
              Valider
            </Button>
            <Button
              // type="primary"
              onClick={() => setAddExistingDocument(false)}
              block
              size="large"
            >
              Transmettre un nouveau document
            </Button>
          </Space>
        </div>
      ) : (
        <Form
          layout="vertical"
          size="large"
          form={form}
          onFinish={onFinish}
          initialValues={initialState}
        >
          <ErrorComponent />
          <Form.Item
            label={`${targetedDocument?.description} (.jpg ou pdf maximum 1mb) *`}
            rules={[
              {
                required: true,
                message: "Ce champ ne peut être vide"
              }
            ]}
            name="file"
          >
            <Upload
              {...uploadProps}
            >
              <Space style={{ paddingTop: 5 }}>
                <Button
                  size="large"
                  icon={<FileImageOutlined />}
                >
                  Choisir un document
                </Button>
                {!!file && (
                  <CheckCircleFilled style={{ color: '#52C41A', fontSize: 24 }} />
                )}
              </Space>
            </Upload>
          </Form.Item>
          {/* <Space>
            <Form.Item
              label="Date d’échéance"
              name="due_date"
              rules={[
                {
                  required: !noDueDate,
                  message: "Veuillez renseigner une date d’échéance",
                },
              ]}
            >
              <DatePicker
                placeholder="Sélectionner"
                picker="date"
                // defaultPickerValue={defaultDate}
                format="DD/MM/YYYY"
                style={{ width: 280 }}
                disabled={noDueDate}
              />
            </Form.Item>
            <Button
              onClick={() => setNoDueDate(state => !state)}
              style={noDueDate ? {
                borderColor: '#40a9ff',
                color: '#40a9ff',
                marginTop: 11,
              } : {
                marginTop: 11,
              }}
            >
              Aucune
            </Button>
          </Space> */}
          <Form.Item
            rules={[
              {
                required: true,
                message: "Ce champ ne peut être vide"
              }
            ]}>
            <Checkbox onChange={e => setIsChecked(e.target.checked)}>
              J’accepte de communiquer ce document à {targetedDocument?.organisationName} *
            </Checkbox>
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: "100%", marginTop: 20, marginBottom: 50 }}
            disabled={!file || !isChecked}
            loading={isUploading}
          >
            Valider
          </Button>
        </Form>
      )}
    </Drawer >

  );
};

export default TransmitDoc;