import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Steps, Row, Col, Input, Checkbox, Avatar, Button } from "antd";
import { LeftCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
// import PropTypes from 'prop-types'
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  registrationDetailsFetching,
  registrationCreating,
  // registrationToOrganisationFetching,
  commentSubmitting,
  registrationSubmitting,
  organisationsByZipCodeFetching,
  clearOrganisationsByZipCode,
  registrationUpdating,
  childRegistrationFetching,
  fetchRegistrationsDetails,
} from "~/containers/Registration/actions";
import {
  fetchChildDetails,
  fetchChildren,
  fetchChildrenRegistrations,
  fetchSelectedChildrenDetails,
} from "~/containers/Children/actions";
import { registredOrganisationsByZipCode } from "~/containers/Registration/selectors";
import DocumentTypes from "~/utils/documentsType";
import { userOrganisationsFetching } from "~/containers/Home/actions";
import H3 from "~/components/common/H3";
import H2 from "~/components/common/H2";
import PageHeader from "~/components/common/PageHeader";
import Card from "~/components/common/Card";
import Loading from "~/components/common/Loading";
import DocumentUploader from "./components/DocumentUploader";
import MissingInformation from "./components/MissingInformation";
import Organisation from "./components/Organisation";
import RecapElement from "./components/RecapElement";
import { StyledSteps } from "./components/index";
import Child from "~/components/common/Child";
import Container from "~/components/common/Container";
import ContentHeader from "~/components/common/ContentHeader";
import H1 from "~/components/common/H1";
import Space from "~/components/common/Space";
import OrgCard from "~/components/common/OrgCard";
import Step1 from "./Step/step1";
import Step3 from "./Step/step3";
import Step4 from "./Step/step4";
import Step5 from "./Step/step5";

import {
  StepContainer,
  ChildContainer,
  StepButtonContainer,
  StepButton,
  ZipCodeInput,
  SelectChildTitle,
} from "./components/index";
import {
  documentsFetching,
  householdFetching,
} from "~/containers/Foyer/actions";
import mainAxios from "~/lib/axios";

const { Step } = Steps;
const nbrSteps = 4;

const Registration = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { registrationDetails, list: childRegistrationsList } = useSelector(
    (state) => state.registration
  );
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = (e) => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  const {
    children,
    childDetails: child,
    loading: fetchingChildren,
    childrenRegistrations,
    isFetching,
    selectedChildrenDetails,
  } = useSelector((state) => state.children);
  const { userOrganisationsList } = useSelector(
    (state) => state.home.organisation
  );
  const { email: userEmail } = useSelector((state) => state.user.auth.user);
  const { user: userData } = useSelector((state) => state.user.userInfo);
  const organisationsByZipCode = useSelector(registredOrganisationsByZipCode);

  const { loading: isLoadingRegistration } = useSelector(
    (state) => state.registration
  );
  const { loading: loadingByZipCode } = useSelector(
    (state) => state.registration.organisation
  );

  // let registrationFromScratch = !params.registration_alias && !params.child_alias
  const [currentStep, setCurrentStep] = useState(0);
  const [canContinue, setCanContinue] = useState(true);
  const [canGoBack, setCanGoBack] = useState(true);

  // Les villes ou l'enfant a déja été inscrit
  const [childRegistrationsAliasesList, setChildRegistrationsAliasesList] =
    useState([]);
  const { t } = useTranslation("registration");
  const [currentRegistrationAlias, setCurrentRegistrationAlias] = useState(
    params.registration_alias
  );
  const [chosenChildAlias, setChosenChildAlias] = useState([]);

  const [chosenOrganisationData, setChosenOrganisationData] = useState(
    registrationDetails
      ? userOrganisationsList.find(
          (element) => element.alias === registrationDetails.organisationAlias
        )
      : []
  );

  const [selectedOrganisation, setSelectedOrganisation] = useState(null);

  const [schooltripAuthorization, setSchooltripAuthorization] = useState(null);
  const [photoAuthorisation, setPhotoAutorisation] = useState(null);
  // const [filledDocuments, setFilledDocuments] = useState(null)
  const [missingDocuments, setMissingDocuments] = useState([]);
  const [preFilledDocuments, setPreFilledDocuments] = useState([]);
  const [preFilledDocumentsAliases, setPreFilledDocumentsAliases] = useState(
    []
  );
  const [toFillDocuments, setToFillDocuments] = useState([]);
  const [comment, setComment] = useState("");
  const [socialSecurity, setSocialSecurity] = useState(null);
  const [selectedAllergies, setSelectedAllergies] = useState(null);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const { data: documents } = useSelector((state) => state.household.documents);
  const { data: householdData } = useSelector(
    (state) => state.household.household
  );

  const isRenewRegistration = location?.search === "?renew_registration=1";

  const submitRegistration = () => {
    dispatch(
      registrationSubmitting(chosenChildAlias, currentRegistrationAlias, () =>
        navigate(`/children/${chosenChildAlias}`)
      )
    );
  };

  const onChooseChild = (ch) => {
    if (chosenChildAlias.find((child) => child === ch.alias)) {
      setChosenChildAlias(
        chosenChildAlias.filter((alias) => alias != ch.alias)
      );
    } else {
      setChosenChildAlias([...chosenChildAlias, ch.alias]);
    }
  };

  const searchOrganisationByZipCode = (value) => {
    if (value.length > 3) {
      dispatch(organisationsByZipCodeFetching(value, 1));
    } else dispatch(clearOrganisationsByZipCode());
  };

  const getOrganisationData = useCallback(async (orgAlias) => {
    const res = await mainAxios.get(`/organisations/${orgAlias}/`);

    setChosenOrganisationData(res.data);

    return;
  }, []);

  useEffect(() => {
    if (params.child_alias && params.registration_alias) {
      dispatch(
        registrationDetailsFetching(
          params.child_alias,
          params.registration_alias,
          (registration) => {
            setMissingDocuments(registration.missing_documents);
            // setChosenOrganisationData
            // setFilledDocuments(registration.list_of_documents)
            setCanContinue(registration.missing_documents.length === 0);
            if (registration.missing_information.length === 0) {
              setCurrentStep(3);
              setCanGoBack(false);
            }

            if (registration.missing_documents.length === 0 && !isRenewRegistration) {
              setCurrentStep(4);
              setCanGoBack(false);
            }
          }
        )
      );

      const orgAlias = childrenRegistrations?.[params.child_alias]?.find(
        (reg) => reg.alias === params.registration_alias
      )?.organisationAlias;

      getOrganisationData(orgAlias);
      setChosenChildAlias([params.child_alias]);
    }
    dispatch(userOrganisationsFetching());
  }, [params.registration_alias]);

  useEffect(() => {
    const registrationFromScratch =
      !params.registration_alias && !params.child_alias;
    const registrationFromChildDetails =
      !params.registration_alias && params.child_alias;

    let firstStep;
    if (registrationFromScratch) {
      firstStep = 0;
      // } else if (registrationFromChildDetails) {
      // firstStep = 1;
      // setChosenChildAlias([params.child_alias]);
    } else {
      firstStep = 3;
      setCanGoBack(false);
    }

    setCurrentStep(firstStep);

    if (params.child_alias) {
      dispatch(childRegistrationFetching(params.child_alias));
      const data = children.find((ch) => ch.alias === chosenChildAlias);
      dispatch(fetchChildDetails({ url: data?.url }));
    }
  }, [params.child_alias, params.registration_alias]);

  useEffect(() => {
    setChildRegistrationsAliasesList(
      childRegistrationsList.map((item) => item.organisationAlias)
    );
  }, [childRegistrationsList]);

  useEffect(() => {
    if (missingDocuments) {
      const toFill = [];
      const preFilled = [];
      const preFilledAliases = [];
      missingDocuments.forEach((item) => {
        const pref = documents.find(
          (doc) => doc.shared_with_other_users && doc.type === String(item)
        );
        if (pref) {
          preFilled.push(pref.type);
          preFilledAliases.push(pref.alias);
        } else {
          toFill.push(String(item));
        }
      });
      setToFillDocuments(toFill);
      setPreFilledDocuments(preFilled);
      setPreFilledDocumentsAliases(preFilledAliases);
    }
  }, [missingDocuments, registrationDetails]);

  useEffect(() => {
    setCanContinue(toFillDocuments.length === 0);
  }, [preFilledDocuments, toFillDocuments]);

  useEffect(() => {
    setChosenOrganisationData(
      userOrganisationsList.find(
        (item) => item.alias === registrationDetails?.organisationAlias
      )
    );
  }, [userOrganisationsList, registrationDetails]);

  useEffect(() => {
    dispatch(documentsFetching());
    dispatch(householdFetching());
    dispatch(fetchChildrenRegistrations());
    dispatch(fetchChildren());
  }, []);

  useEffect(() => {
    if (children.length > 0) {
      const childrenDetailsUrls = children.map((child) => child.url);

      dispatch(
        fetchSelectedChildrenDetails(
          childrenDetailsUrls
        )
      );

      children.forEach((child) => {
        setSelectedAllergies((state) => ({
          ...state,
          [child.alias]: [],
        }));
      });
    }
  }, [children.length]);

  const orgDetails = !!organisationsByZipCode.length
    ? organisationsByZipCode?.find(
        (org) => org.alias === registrationDetails?.organisationAlias
      )
    : userOrganisationsList?.find(
        (org) => org.alias === registrationDetails?.organisationAlias
      );

  console.log({registrationDetails}, "+++++++++++++")

  if (isFetching || fetchingChildren || isLoadingRegistration) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <>
      <ContentHeader
        breadcrumbItems={[
          {
            name: "Incription",
            path: "/registration/",
          },
        ]}
        buttons={() => (
          <>
            <LeftCircleOutlined
              onClick={() => {
                if (currentStep && canGoBack)
                  setCurrentStep((curr) => curr - 1);
              }}
              style={{ fontSize: 25, color: canGoBack ? "#2F4580" : "#969696" }}
            />
            <CloseCircleOutlined
              onClick={() => {
                navigate(-1);
              }}
              style={{ fontSize: 25, color: "#2F4580" }}
            />
          </>
        )}
      >
        <H1>
          {isRenewRegistration
            ? "Renouveller l'inscription"
            : "Inscription nouvelle organisation"}
        </H1>
      </ContentHeader>
      {/* <br /> */}
      <Container>
        <StyledSteps
          progressDot
          current={currentStep}
          direction={width > 1100 ? "horizontal" : "vertical"}
        >
          <Step title="Choix organisation" />
          <Step title="Préparez les éléments" />
          <Step title="Individus" />
          <Step title="Documents" />
          <Step title="Informations" />
          <Step title="Résumé et validation" />
        </StyledSteps>
        <br />
        <br />

        {fetchingChildren && <Loading />}

        {isLoadingRegistration && (
          <Col span={24}>
            <Loading />
          </Col>
        )}

        {currentStep === 0 && (
          <>
            <Row gutter={[24, 24]} justify="center">
              <Col span={24}>
                <ZipCodeInput
                  placeholder="Recherche par Code postal"
                  size="large"
                  onChange={(event) =>
                    searchOrganisationByZipCode(event.target.value)
                  }
                />
              </Col>
              {/* {organisationsByZipCode.length > 0 && (
                <Col span={24}>
                  <H2 as="h1">{t("search-by-postal-code")}</H2>
                </Col>
              )} */}
              {loadingByZipCode && (
                <Col span={24}>
                  <Loading />
                </Col>
              )}

              {organisationsByZipCode
                .filter(
                  (item) => !childRegistrationsAliasesList.includes(item.alias)
                )
                .map((organisation) => (
                  <div
                    onClick={() => {
                      setChosenOrganisationData(organisation);
                      setSelectedOrganisation(organisation);
                      setCurrentStep(1);
                    }}
                  >
                    <OrgCard
                      name={organisation.name}
                      address={organisation.address}
                      zip={`${organisation.zip_code} ${organisation.city}`}
                      email={organisation.email}
                      phone={organisation.phone}
                      photo={organisation.photo}
                    />
                  </div>
                ))}
            </Row>
            <Row gutter={[24, 24]} justify="center">
              {organisationsByZipCode.length === 0 &&
                userOrganisationsList
                  .filter(
                    (item) =>
                      !childRegistrationsAliasesList.includes(item.alias)
                  )
                  .map((organisation) => {
                    return (
                      <div
                        key={organisation.alias}
                        onClick={() => {
                          setChosenOrganisationData(organisation);
                          setSelectedOrganisation(organisation);
                          setCurrentStep(1);
                        }}
                      >
                        <OrgCard
                          name={organisation.name}
                          address={organisation.address}
                          zip={`${organisation.zip_code} ${organisation.city}`}
                          email={organisation.email}
                          phone={organisation.phone}
                          photo={organisation.photo}
                        ></OrgCard>
                      </div>
                    );
                  })}
            </Row>
          </>
        )}

        {currentStep === 1 && (
          <Step1
            setCurrentStep={setCurrentStep}
            organisation={chosenOrganisationData}
          ></Step1>
        )}

        {currentStep === 2 && (
          <>
            <SelectChildTitle>Qui souhaitez-vous inscrire ?</SelectChildTitle>
            <Row gutter={[24, 24]}>
              {!fetchingChildren &&
                children?.map((ch) => {
                  const isAlreadyRegistered = childrenRegistrations?.[
                    ch?.alias
                  ]?.find(
                    (reg) =>
                      reg?.organisationAlias === chosenOrganisationData?.alias
                  );
                  return (
                    <ChildContainer
                      onClick={() => {
                        if (!isAlreadyRegistered) {
                          onChooseChild(ch);
                        }
                      }}
                    >
                      <Child
                        child={ch}
                        selected={chosenChildAlias.find(
                          (alias) => alias === ch.alias
                        )}
                        isAlreadyRegistered={isAlreadyRegistered}
                      />
                    </ChildContainer>
                  );
                })}
            </Row>
            {chosenChildAlias.length > 0 && (
              <Button
                style={{ selfAlign: "center", width: 266, height: 40 }}
                size="large"
                type="primary"
                onClick={() => {
                  return dispatch(
                    registrationCreating(
                      chosenChildAlias,
                      chosenOrganisationData.alias,
                      null,
                      (registrations) => {
                        if (registrations.length === chosenChildAlias.length) {
                          // dispatch(fetchChildrenRegistrations());
                          dispatch(fetchRegistrationsDetails(chosenOrganisationData.alias, chosenChildAlias))
                          setCurrentStep(3);
                        } else {
                          return;
                        }
                      }
                    )
                  );
                }}
              >
                Etape suivante
              </Button>
            )}
          </>
        )}

        {currentStep === 3 && (
          <Step3
            children={children}
            childAliases={chosenChildAlias}
            setCurrentStep={setCurrentStep}
            selectedOrganisation={selectedOrganisation}
            documents={documents}
            registrationAlias={currentRegistrationAlias}
            childrenRegistrations={childrenRegistrations}
          />
        )}

        {currentStep === 4 && (
          <Step4
            children={children}
            childAliases={chosenChildAlias}
            setCurrentStep={setCurrentStep}
            orgRequiredInformation={selectedOrganisation?.required_information || registrationDetails?.organisation_list_of_information}
            organisationAlias={selectedOrganisation?.alias}
            householdData={householdData?.[0]}
            schooltripAuthorization={schooltripAuthorization}
            setSchooltripAuthorization={setSchooltripAuthorization}
            photoAuthorisation={photoAuthorisation}
            setPhotoAutorisation={setPhotoAutorisation}
            setSocialSecurity={setSocialSecurity}
            selectedChildrenDetails={selectedChildrenDetails}
            setSelectedAllergies={setSelectedAllergies}
            selectedAllergies={selectedAllergies}
            childrenRegistrations={childrenRegistrations}
          ></Step4>
        )}

        {currentStep === 5 && (
          <Step5
            children={children}
            childAliases={chosenChildAlias}
            setCurrentStep={setCurrentStep}
            organisation={selectedOrganisation || orgDetails}
            childrenRegistrations={childrenRegistrations}
            setRegistrationSuccess={setRegistrationSuccess}
            registrationSuccess={registrationSuccess}
          ></Step5>
        )}

      </Container>
    </>
  );
};

export default Registration;
