import React, { useCallback, useEffect, useState } from "react"
import { FileImageOutlined } from "@ant-design/icons"
import Drawer from "~/components/common/Drawer"
import { Row, Col, Space } from "antd"
import {
  Form,
  Select,
  Input,
  Radio,
  Switch,
  InputNumber,
  Divider,
  Button,
  Upload,
  message,
  DatePicker,
} from "antd"
import Gender from "~/components/common/Gender"
import CustomProfilAvatar from "~/components/common/CustomProfilAvatar"
import Search from "~/components/common/Search"
import { useNavigate, useLocation } from "react-router-dom"

import { childStatus, parentMaritalStatus, allergies } from "~/constants"
import { childCreating } from "~/containers/Children/actions"
import { useSelector, useDispatch } from "react-redux"

import useErrors from "~/hooks/useErrors"
import Checkbox from "antd/lib/checkbox/Checkbox"

import dayjs from "dayjs"
import apolloClient from "~/lib/apolloClient"
import { MY_FEEDS, MY_HOUSEHOLD } from "~/graphql/queries"

const { Option } = Select
const { TextArea } = Input

const AddChild = ({ child, showDrawer, setShowDrawer, title }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [photo, setPhoto] = useState()
  const [file, setFile] = useState(null)
  const { ErrorComponent, setErrors } = useErrors()
  const [form] = Form.useForm()
  const [selected, setSelected] = useState([])
  const [willAddNewChild, setWillAddNewChild] = useState(false)
  const { isCreating } = useSelector(state => state.children)

  let initialState = {}
  const onCreated = (alias) => navigate(`/children/${alias}`)

  const refetchQueries = useCallback(
    async () => {
      console.log('refetchQueries')
      await apolloClient.refetchQueries({
        include: [MY_FEEDS, MY_HOUSEHOLD],
      });
    },
    [apolloClient],
  );

  const onSubmit = (values) => {
    dispatch(
      childCreating(
        {
          first_name: values.first_name,
          last_name: values.last_name,
          date_of_birth: values.date_of_birth
            ? dayjs(values.date_of_birth).format("YYYY-MM-DD")
            : null,
          allergy: JSON.stringify(values.allergies),
          insurance: values.insurance,
          gender: values.gender,
          has_pai: values.has_pai,
          comment: values.comment,
          relationship_type: values.relationship_type,
          photo,
          temp_parents_marital_status: values?.parents_marital_status,
        },
        photo,
        (alias) => {
          refetchQueries();

          if (!willAddNewChild) {
            form.resetFields()
            setShowDrawer(false)
            // onCreated(alias)
          } else {
            initialState = {}
            form.resetFields()
          }
        }
      )
    )
  }

  const onChangePhoto = (event) => {
    const file = event.target.files[0]
    if (!file) return
    setPhoto(file)
  }

  // const defaultDate = dayjs().subtract(
  //   Number(process.env.REACT_APP_DEFAULT_CHILD_YEAR ?? 10),
  //   "years"
  // );

  const onFinish = (values) => {
    
  }

  const onFinishFailed = (errorInfo) => {
    
  }

  const uploadProps = {
    fileList: [],
    maxCount: 1,
    beforeUpload: (file) => {
      if (file.type !== "image/jpeg") {
        message.error(`${file.name} n'est pas un fichier jpg`)
      }
      return file.type === "image/jpeg"
    },
    customRequest: async (e) => {
      setFile(e.file)
      if (e.file) {
        
        setPhoto(e.file)
        // const dataImg = new FormData();
        // dataImg.append("photo", e.file);
        // const [error, response] = false;
        // await uploadPhoto(dataImg, {
        //   path: 'children.photoUpload',
        //   params: {
        //     alias: data ?.child ?.alias,
        //   }
        // })

        // if (error) {
        //   message.error(
        //     "Désolé. Un problème est survenu. Veuillez réessayer plus tard."
        //   );
        // }
        // if (response) {
        //   // registrationMutation(data)
        //   // update child data photo;
        //   message.success(
        //     `L'image du ${child?.child?.first_name}
        //     ${child?.child?.last_name} a été bien mise à jour`
        //   );
        // }
      }
    },
  }

  if (child) {
    initialState = {
      first_name: child?.first_name,
      last_name: child?.last_name,
      date_of_birth: dayjs(child?.date_of_birth),
      gender: child?.gender,
      has_pai: child?.has_pai,
      outdoor_activity_permission: child?.outdoor_activity_permission ?? 0,
      photo_permission: child?.photo_permission ?? 0,
      insurance: child?.insurance,
      allergies: selected,
    }
  }
  
  useEffect(() => {
    if (child && child.allergy) {
      let defaultAllergy = JSON.parse(child?.allergy)
      setSelected(defaultAllergy)
      
    }
  }, [])
  const all = allergies.map((x) => {
    return { label: x, value: x }
  })

  // 

  return (
    <Drawer
      open={showDrawer}
      handleCloseDrawer={() => setShowDrawer(false)}
      width={636}
      title={title}
      destroyOnClose
    >
      <Form
        layout="vertical"
        size="large"
        form={form}
        onFinish={onSubmit}
        initialValues={initialState}
      >
        <Form.Item
          label="J’interviens en qualité de"
          name="relationship_type"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner un prénom",
            },
          ]}
        >
          <Select placeholder="Sélectionner">
            <Option key={0}>Mère</Option>
            <Option key={1}>Père</Option>
            <Option key={2}>Tuteur</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Photo (.jpg maximum 1mb)">
          <Upload name="photo" {...uploadProps}>
            <Space style={{ paddingTop: 5 }}>
              <CustomProfilAvatar file={photo} />
              <Button size="large" icon={<FileImageOutlined />}>
                Choisir une image
              </Button>
            </Space>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Prénom"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner un prénom",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nom"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner un nom",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Date de naissance"
          name="date_of_birth"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner une date de naissance",
            },
          ]}
        >
          <DatePicker
            picker="date"
            format="DD-MM-YYYY"
            style={{ width: 280 }}
          />
        </Form.Item>
        <Form.Item
          label="Sexe"
          name="gender"
          rules={[
            {
              required: true,
              message:
                "Veuillez indiquer s'il s'agit d'une fille ou d'un garçon",
            },
          ]}
        >
          <Radio.Group>
            <Space>
              <Radio.Button value={1}>
                <Space size="small">
                  <Gender gender={1} $size={20} />
                  <span style={{ color: "#EB2F96" }}>Fille</span>
                </Space>
              </Radio.Button>
              <Radio.Button value={0}>
                <Space size="small">
                  <Gender gender={0} $size={20} />

                  <span style={{ color: "#1890FF" }}>Garçon</span>
                </Space>
              </Radio.Button>
            </Space>
          </Radio.Group>
        </Form.Item>

        <>
          <Form.Item
            label="Sécurité sociale"
            name={["registration", "social_security", "key"]}
            rules={[
              {
                required: true,
                message: "Veuillez renseigner le type d'assurance",
              },
            ]}
          >
            <Radio.Group>
              <Space>
                <Radio.Button value={0}>Régime général</Radio.Button>
                <Radio.Button value={1}>MSA</Radio.Button>
                <Radio.Button value={2}>Autre</Radio.Button>
              </Space>
            </Radio.Group>
          </Form.Item>
        </>
        <Form.Item
          label="Statut marital des parents"
          name="parents_marital_status"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le Statut marital",
            },
          ]}
        >
          <Radio.Group>
            <Space
              style={{
                flexWrap: 'wrap',
              }}
            >
              {parentMaritalStatus.map(({ label, value }, i) => (
                <Radio.Button value={value} key={i} style={{marginBottom: 10}}>
                  {label}
                </Radio.Button>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="insurance" label="Nom et numéro de police d’assurance">
          <Input />
        </Form.Item>
        <Form.Item
          label="Restriction(s) alimentaire(s)"
          name="allergies"
          rules={[
            {
              required: false,
              message: "Veuillez renseigner un foyer",
            },
          ]}
        >
          <Search
            placeholder="Rechercher..."
            options={all}
            mode="multiple"
            // defaultValue={selected}
            value={selected}
            onChange={setSelected}
          />
        </Form.Item>
        <Form.Item name="has_pai" valuePropName="checked">
          <Checkbox>Dispose d’un PAI (Projet d'Accueil Individualisé)</Checkbox>
        </Form.Item>
        {/* <Form.Item name="photo_permission" valuePropName="checked">
          <Space direction="horizontal">
            <Switch /> Autorisé(e) à être pris(e) en photo
          </Space>
        </Form.Item>
        <Form.Item name="outdoor_activity_permission" valuePropName="checked">
          <Space direction="horizontal">
            <Switch /> Autorisé(e) à aller en sorties scolaires
          </Space>
        </Form.Item> */}
        {/* <Form.Item label="Commentaire">
          <TextArea rows={4} />
        </Form.Item> */}
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          style={{ width: "100%", marginTop: 20 }}
          onClick={() => setWillAddNewChild(false)}
          loading={isCreating}
        >
          Enregistrer
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          style={{ width: "100%", marginTop: 20, marginBottom: 50 }}
          onClick={() => setWillAddNewChild(true)}
          loading={isCreating}
        >
          Enregistrer et ajouter un autre enfant
        </Button>
      </Form>
    </Drawer>
  )
}

export default AddChild
