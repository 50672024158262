import React from 'react'
import PropTypes from 'prop-types'

// Activity Icons
import Garderie from '~/assets/activities/garderie.svg'
import Cantine from '~/assets/activities/cantine.svg'
import School from '~/assets/activities/school.svg'
import Mairie from '~/assets/activities/mairie.svg'
import Etude from '~/assets/activities/etude.svg'
import Ramassage from '~/assets/activities/ramassage.svg'
import Centre from '~/assets/activities/centre.svg'
import Matin from '~/assets/activities/matin.svg'
import Stage from '~/assets/activities/stage.svg'
import Enfants from '~/assets/activities/enfants.svg'
import Jeunesse from '~/assets/activities/jeunesse.svg'

/**
 * <ActivityIcon />
 * @param {object} props 
 */
const ActivityIcon = (props) => {
  const ACTIVITY_TYPES = [
    {
      type: 0,
      label: 'cantine',
      icon:
        <img src={Cantine} width={props.width} height={props.height} style={{
          marginTop: -40,
          right: 20,
          position: 'absolute'
        }}></img>
      // <Cantine width={props.width} height={props.height} />,
    },
    {
      type: 1,
      label: 'accueil du matin',
      icon: <img src={Matin} width={props.width} height={props.height} style={{
        marginTop: -40,
        right: 20,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 2,
      label: 'garderie',
      icon: <img src={Garderie} width={props.width} height={props.height} style={{
        marginTop: -60,
        right: 10,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 3,
      label: 'etude',
      icon: <img src={Etude} width={props.width} height={props.height} style={{
        marginTop: -40,
        right: 20,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 4,
      label: 'stage',
      icon: <img src={Stage} width={props.width} height={props.height} style={{
        marginTop: -40,
        right: 20,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 5,
      label: 'centre de loisirs',
      icon: <img src={Centre} width={props.width} height={props.height} style={{
        marginTop: -40,
        right: 20,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 6,
      label: 'ramassage scolaire',
      icon: <img src={Ramassage} width={props.width} height={props.height} style={{
        marginTop: -40,
        right: 20,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 7,
      label: 'centre jeunesse',
      icon: <img src={Jeunesse} width={props.width} height={props.height} style={{
        marginTop: -40,
        right: 20,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 98,
      label: 'mairie',
      icon: <img src={Mairie} width={props.width} height={props.height} style={{
        marginTop: -40,
        right: 20,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 99,
      label: 'école',
      icon: <img src={School} width={props.width} height={props.height} style={{
        marginTop: -40,
        right: 20,
        position: 'absolute'
      }}></img>,
    },
    {
      type: 33,
      label: 'enfants',
      url: Enfants,
      icon: <img src={Enfants} width={props.width} height={props.height} style={{
        marginTop: -40,
        right: 20,
        position: 'absolute'
      }}></img>,
    },
  ]

  const _getActivityIcon = () => {
    const activity = ACTIVITY_TYPES.find((act) => act.type === props.activityType)
    
    if (activity) {
      return activity.icon
    }
    return null
  }

  return (
    <React.Fragment>
      {_getActivityIcon()}
    </React.Fragment>
  )
}

ActivityIcon.propTypes = {
  activityType: PropTypes.number,
  style: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number,
}

// ActivityIcon.defaultProps = {
//   activityType, style, width, height,
// }

export default ActivityIcon