import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import FilterContainer from '~/components/common/FilterContainer'
import Space from '~/components/common/Space'
import Table from '~/components/common/Table'
import { downloadPdf, fetchBills } from '~/containers/Bills/actions'
import { StatusCircles } from '~/containers/User/UserProfile/components/Styles/invoice'
import { useColumnsInvoices } from '~/containers/Bills/columns'

import { useQuery } from "@apollo/client";
import { BILLS } from "../../../graphql/queries";
import MainContainerComponent, { Container, MainContent } from '~/components/common/Container'

const sortFields = {
  issueDate: 'issue_date',
  totalAmount: 'total_amount',
  type: 'type',
  billNumber: 'bill_number',
  state: 'status',
  organisation: 'household__organisation__name',
}

const BillsTable = () => {
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState(['-'+sortFields.issueDate]) 
  const [showDrawer, setShowDrawer] = useState(false)

  const { t } = useTranslation(['bills', 'common'])
  // const { bills, isFetchingBills } = useSelector((state) => state.bills)
  // console.log("BILLS", bills.results); 
  const { accessToken } = useSelector((state) => state.user.auth)
  const dispatch = useDispatch()
  const {
    loading: loadingBills,
    errorBills,
    data: billsData,
    refetch,
  } = useQuery(BILLS, {
    fetchPolicy: "no-cache",
    variables: {
      search,
      first: pageSize,
      skip: (page - 1) * pageSize,
      sort,
    },
  });
  
  const bills = billsData?.bills?.data || []
  const billsCount = billsData?.bills?.count || 0

  console.log({bills, billsCount, page})

  const onStatusChange = () => {}

  const handleDownloadPDF = useCallback(
    (token, url, billName) => {
      dispatch(downloadPdf({ token, url, billName }))
    },
    [dispatch],
  )

  // @TODO Missing api info to show full tab;
  const columns = useColumnsInvoices(onStatusChange, setShowDrawer, handleDownloadPDF, search,accessToken)

  const onTableChange = useCallback((pagination, _, sorter, { action }) => {
    console.log({ pagination, sorter, action })
    if (action === 'paginate') {
      setPage(pagination.current)
    }
    if (action === 'sort') {
      const field = sortFields[sorter.columnKey]?.replace('-', '')

      setSort(sorter.order === 'descend' ? [`-${field}`] : [field])
    }
  }, [])

  return (
    <MainContainerComponent>
      <Space direction="vertical" size="large">
        <FilterContainer
          defaultPageSize={pageSize}
          onPageSizeChange={size => {
            setPageSize(size)
            setPage(1)
          }}
          onSearch={(value) => setSearch(value)}
          isBills
        />
        <Table
          columns={columns}
          dataSource={bills}
          isLoading={loadingBills}
          onChange={onTableChange}
          total={billsCount} // length
          currentPage={page}
          pageSize={pageSize}
        />
      </Space>

      <StatusCircles>Statut de paiement : {' '}
        ✅ Accepté {' '}
        ❌ Annulé {' '}
        🚫 Refusé
      </StatusCircles>
    </MainContainerComponent>
  )
}; 

export default BillsTable;
