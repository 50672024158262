import React from "react"
import styled from "styled-components"
import { PaperClipOutlined, CheckCircleOutlined, FileOutlined } from "@ant-design/icons"
import { Button } from "antd"

import { DocumentTypes, getDocumentType } from "~/constants/index"

import H2 from "~/components/common/H2"

const DocImg = styled.div`
  width: 112px;
  height: 112px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TextContainer = styled.div`
  padding-left: 16px;
  // padding-top: 14px;
  // vertical-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-item: center;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const LightText = styled.div`
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 5px;
`

const DocContainer = styled.div`
  display: flex;
  align-item: center;
  // flex-direction:row;
  height: 112px;
  margin-bottom: 16px;
`

const BoldText = styled.div`
  font-weight: bold;
`

const Status = styled.div`
  color: #52c41a;
`

const TransmettezButton = styled(Button)`
  padding: 10px;
`

const PaddingBot = styled.div`
  padding-bottom: 20px;
`

export const Doc = ({ data }) => {
  const documentType = getDocumentType(data.type)?.label

  return (
    <DocContainer>
      <DocImg>
        <FileOutlined
          style={{
            fontSize: 90,
            opacity: 0.5,
          }}
        />
      </DocImg>
      <TextContainer>
        <Title>
          {documentType}
        </Title>
        <LightText>
          <PaperClipOutlined /> {data?.name}
        </LightText>
        <Status>
          <CheckCircleOutlined /> validé: {data?.organisationsNames?.toString()}
        </Status>
      </TextContainer>
    </DocContainer>
  )
}

export const MissingDoc = ({ data, setShowDrawer, setTargetedDocument, setTargetedOrganisation }) => {
  return (
    <div style={{marginBottom: 30}}>
      <LightText>Pour : {data?.organisationsNames?.toString()}</LightText>
      <BoldText>{data.title}</BoldText>
      <Button
        onClick={() => {
          setShowDrawer(true)
          setTargetedDocument(data.code)
          setTargetedOrganisation(data.organisationsNames)
        }}
        size="large"
        style={{ marginTop: 7 }}
        type="primary"
      >
        Transmettez le document
       </Button>
    </div>
  )
}