import React, { useMemo } from "react"
import Text from "~/components/common/Text"
import { colors } from "~/utils/colors"

export const useColumns = (onStatusChange) =>
  useMemo(
    () => [
      {
        title: "Tranche de revenu",
        // dataIndex: "category",
        defaultSortOrder: "ascend",
        key: "category",
        sorter: true,
        width: "50%",
        render: (data) => (
          <Text>{data?.category?.name} (entre {data?.category?.minValue} € et {data?.category?.maxValue === 999999999 ? "plus" : `${data?.category?.maxValue} €`})</Text>
        ),
      },
      {
        title: <Text>Tarif<Text style={{color: colors.cvRed, fontWeight: 700}}>*</Text></Text>,
        // dataIndex: "pricing_model_details",
        key: "households",
        sorter: false,
        width: "50%",
        render: (data) => {
          const parsedPricingModelDetails = data?.pricingModelDetails && JSON.parse(data?.pricingModelDetails)
          const pricePerPresence = parsedPricingModelDetails?.price_per_presence
          console.log({parsedPricingModelDetails, data})

          if (data?.pricingModelType === 0) {
            return (
              <div>
                <Text>
                  <strong>
                    {data?.price} €
                  </strong>
                </Text>
              </div>
            )
          }

          if (data?.pricingModelType === 1) {
            return (
              <div>
                <div>
                  <Text>1 enfant : <strong>{parsedPricingModelDetails?.one} €</strong></Text>
                  <Text> / 2 enfants : <strong>{parsedPricingModelDetails?.two} €</strong></Text>
                  <Text> / 3 enfants : <strong>{parsedPricingModelDetails?.three} €</strong></Text>
                  <Text> / 4 enfants et plus : <strong>{parsedPricingModelDetails?.four} €</strong></Text>
                </div>                
                {!pricePerPresence && (
                  <div>
                    <Text style={{paddingTop: 10, fontStyle: "italic"}}>
                      👉 prix pour une base de 8h de l'activité
                    </Text>
                  </div>
                )}
              </div>
            )
          }

          if (data?.pricingModelType === 2) {
            return (
              <div>
                <div>
                  <Text>1 enfant : <strong>{parsedPricingModelDetails?.one}%</strong></Text>
                  <Text> / 2 enfants : <strong>{parsedPricingModelDetails?.two}%</strong></Text>
                  <Text> / 3 enfants : <strong>{parsedPricingModelDetails?.three}%</strong></Text>
                  <Text> / 4 enfants et plus : <strong>{parsedPricingModelDetails?.four}%</strong></Text>
                </div>
                <div>
                  <Text>👉 taux d'effort de votre revenu mensuel / jour pour une base de 8h de l'activité</Text>
                </div> 
              </div>
            )
          }
        },
      },
    ],
    [onStatusChange]
  )
