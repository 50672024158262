import { takeLatest, put, select, all } from "redux-saga/effects"

import axios from "~/lib/axios"
import { checkJwtExpiration } from "~/containers/User/sagas"
import { getAuthUserInfos } from "~/containers/User/selectors"

import {
  feedFetching,
  feedFetched,
  feedFetchError,
  postDetailsFetching,
  postDetailsFetched,
  postDetailsFetchError,
  userOrganisationsFetching,
  userOrganisationsFetched,
  userOrganisationsFetchError,
  userBillsFetching,
  userBillsFetched,
  userBillsFetchError,
} from "./actions"
import { logout } from "~/containers/User/actions"

function* fetchUserFeed({ payload: { page, search, page_size } }) {
  try {
    yield* checkJwtExpiration()
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(feedFetchError())
      yield put(logout())
    }
    const response = yield axios.get(`users/${userInfos.email}/feed/`, {
      params: { page, search, page_size },
    })
    
    yield put(feedFetched(response.data))
  } catch (err) {
    console.log(err)
    yield put(feedFetchError())
  }
}

function* fetchPostDetails({ payload: { organisationAlias, postAlias } }) {
  try {
    yield* checkJwtExpiration()
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(postDetailsFetchError())
      yield put(logout())
    }
    const response = yield axios.get(
      `organisations/${organisationAlias}/posts/${postAlias}/`
    )
    yield put(postDetailsFetched(response.data))
  } catch (err) {
    console.log(err)
    yield put(postDetailsFetchError())
  }
}

export function* fetchUserOrganisations() {

  try {
    yield* checkJwtExpiration()
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(userOrganisationsFetchError())
      yield put(logout())
    }
    const response = yield axios.get(`users/${userInfos.email}/organisations/?page_size=1000`)
    yield put(userOrganisationsFetched(response.data))
  } catch (err) {
    console.log(err)
    yield put(userOrganisationsFetchError())
  }
}

function* fetchUserBills() {
  try {
    yield* checkJwtExpiration()
    const userInfos = yield select(getAuthUserInfos)
    if (!userInfos.email) {
      yield put(userBillsFetchError())
      yield put(logout())
    }
    const response = yield axios.get(`users/${userInfos.email}/bills/?page_size=1000`)
    yield put(userBillsFetched(response.data.results))
  } catch (err) {
    console.log(err)
    yield put(userBillsFetchError())
  }
}

function* rootSaga() {
  yield all([
    takeLatest(feedFetching, fetchUserFeed),
    takeLatest(postDetailsFetching, fetchPostDetails),
    takeLatest(userOrganisationsFetching, fetchUserOrganisations),
    takeLatest(userBillsFetching, fetchUserBills),
  ])
}

export default rootSaga
