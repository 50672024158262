import React from 'react'
import PropTypes from 'prop-types'
import { Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import authToken from '~/utils/Auth'

const AuthRoute = ({ children, ...rest }) => {
  const { isLogged } = useSelector(state => state.user.auth)
  const token = authToken.getToken()
  
  return !isLogged && !token ? (
    children
  ) : (
    <Navigate
      replace
      to={{
        pathname: "/",
      }}
    />
  );
}

export default AuthRoute