import styled, {
  css,
} from 'styled-components'
import {
  Select,
} from 'antd'

const smallSelectTagSelector = css`
  font-size: @font-size-xs;
`

const smallSelectTagArrow = css`
  right: 4px;
  font-size: 9px;
  top: 14px;
`

const smallSelectTagItem = css`
`

const colors = {
  orange: css`
    color: @orange-base;
    background: @orange-1;
    border-color: @orange-3;
  `,
  green: css`
    color: @green-base;
    background: @polargreen-1;
    border-color: @polargreen-3;
  `,
  red: css`
    color: @red-base;
    background: @dustred-1;
    border-color: @dustred-3;
  `,
  /* TODO: not sure for the below colors */
  purple: css`
    color: #722ed1;
    background: #f9f0ff;
    border-color: #d3adf7;
  `,
  blue: css`
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
  `,
}

export const StatusSelect = styled(Select)`
  &:not(.ant-select-customize-input),
  &:not(.ant-select-disabled):hover {
    .ant-select-selector {
      ${props => colors[props.color]};
      ${props => props.size === 'small' ? smallSelectTagSelector : ''};
      .ant-select-selection-item{
        ${props => props.size === 'small' ? smallSelectTagItem : ''};
      }
    }
    .ant-select-arrow {
      ${props => props.size === 'small' ? smallSelectTagArrow : ''};
    }
  }
`
